import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  IconButton,
  LightColors,
  Typography,
  Modal,
} from "@thingsw/pitta-design-system";

import { Webviewer } from "../../contants/Breakpoints";
import clsx from "clsx";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DeleteIcon from "@material-ui/icons/Delete";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  CAMERA,
  IEmailNotiSettings,
  loadNotification,
  loadNotificationEmailList,
  OnOffType,
  updateNotification,
  invite,
  deleteEmail,
  IEmailList,
  fixMasterEmailNotification,
  statusType,
} from "../../features/Camera/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import _ from "lodash";
import { USER } from "../../features/User/slice";
import {
  DMC100_MODELS,
  DMC200_MODELS,
  LOW_VOLTAGE_MODELS,
} from "../../contants/Models";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    maxWidth: "100%",
    minHeight: 48,
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 144,
    },
  },

  bigBox: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
    },
  },

  boderBottomBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    justifyContent: "space-between",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: "100%",
    },
  },
  boderNoneBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { padding: "0 10px 0 2px" }
      : { padding: "0 2px 0 10px" }),
    whiteSpace: "nowrap",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
    },
  },

  mgNone: {
    margin: 0,
  },
  pdNone: {
    padding: 0,
  },
  account: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3px 3px 3px 6px",
    marginRight: 5,
  },
  pd3: {
    padding: "3px 3px 3px 6px",
    marginRight: 5,
    marginTop: -3,
    color: LightColors.primary["4"],
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  SpaceBtw: {
    padding: "0 16px 0 10px",
    display: "flex",
    justifyContent: "space-between",
  },
  iconBox: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
    align: "center",
    padding: "12px 13px 12px 10px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: "100%",
      minHeight: 48,
      padding: "0 13px 0 10px",
    },
  },
  boderNoneBox64: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 13px 12px 10px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: "100%",
    },
  },
  color3: {
    color: LightColors.primary["3"],
  },
  height40: {
    maxHeight: 40,
  },
  pd35: {
    paddingLeft: 35,
  },
  SpaceBtwPd12: {
    display: "flex",
    alignContent: "center",
  },
  jutColumn: {
    display: "flex",
    flexDirection: "column",
  },
  bigBoxNone: {
    display: "none",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  mgR5: {
    marginRight: 5,
  },
  com: {
    padding: "-8px 24px 5px",
  },
  title: {
    ...(theme.direction === "rtl" ? { paddingRight: 6 } : { paddingLeft: 6 }),
  },
}));

interface NotiEmailScreenProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
}

export const EmailNotificationPanel = ({
  open,
  onClose,
  onClickNegative,
}: NotiEmailScreenProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState<IEmailNotiSettings>();
  const [push, setPush] = useState<OnOffType>("off");
  const { camera, firmwareConfig, loading, type } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [disableInvite, setDisableInvite] = useState(true);

  const [guestEmail, setGuestEmail] = useState<string>("");

  const [mailStatus, setMailStatus] = useState<statusType>("D");

  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );

  const emailSettings = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.EmailSettings
  );
  const emailSend = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.EmailSend
  );
  const notificationEmail = useSelector(
    (state: RootState) => state[CAMERA].notificationEmail?.emails
  );
  const masterEmailStatus = useSelector(
    (state: RootState) => state[CAMERA].notificationEmail?.emails[0].mailStatus
  );

  const getBoolean = useCallback((onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  }, []);

  const getBooleanEmail = useCallback((onOff?: statusType) => {
    return onOff === "A" ? true : false;
  }, []); // A : 수신동의 / D : 수신거부

  useEffect(() => {
    if (emailSend) {
      setPush(emailSend);
    }
  }, [emailSend]);

  useEffect(() => {
    if (masterEmailStatus) {
      setMailStatus(masterEmailStatus);
    }
  }, [masterEmailStatus]);

  useEffect(() => {
    if (emailSettings) {
      setCurrent(emailSettings);
    }
  }, [emailSettings]);

  useEffect(() => {
    const predicts = _.keys(current).map((k) => {
      const key = k as keyof IEmailNotiSettings;
      return (emailSettings?.[key] ?? "off") === current?.[key];
    });
    setDisableSave(
      _.every(predicts) &&
        emailSend === push &&
        masterEmailStatus === mailStatus
    );
  }, [
    getBoolean,
    getBooleanEmail,
    emailSettings,
    current,
    emailSend,
    push,
    masterEmailStatus,
    mailStatus,
  ]);

  const handleChange = (key: keyof IEmailNotiSettings, checked: boolean) => {
    setCurrent(
      (c) => ({ ...c, [key]: checked ? "on" : "off" } as IEmailNotiSettings)
    );
  };

  const handleUpdateMail = useCallback(() => {
    if (camera && userProfile) {
      dispatch(
        fixMasterEmailNotification({
          psn: camera.psn,
          guestEmail: userProfile.email,
          mailStatus: mailStatus,
        })
      );
    }
  }, [camera, dispatch, mailStatus, userProfile]);

  const handleUpdate = useCallback(() => {
    if (current) {
      dispatch(
        updateNotification({
          setType: "email",
          settings: current,
          send: push,
        })
      );
    }
  }, [current, dispatch, push]);

  useEffect(() => {
    if (camera) {
      dispatch(loadNotification());
      dispatch(loadNotificationEmailList(camera.psn));
    }
  }, [camera, dispatch]);

  useEffect(() => {
    if (!loading) {
      setopenInviteModal(false);
      setopenDeleteModal(false);
    }
  }, [loading, type]);
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const [error, setError] = useState(false);

  const [openInviteModal, setopenInviteModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [deleteRecipients, setDeleteRecipients] = useState<string>("");

  const checkEmail = useCallback((value: string) => {
    let email = value;
    // eslint-disable-next-line no-useless-escape
    let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regExp.test(email) === false) {
      setError(true);
      setDisableInvite(true);
    } else {
      setError(false);
      setDisableInvite(false);
    }
  }, []);

  const renderGuestRecipient = useCallback(
    (gEmail: IEmailList) => {
      return gEmail.mailStatus === "A" ? (
        <div className={classes.alignCenter}>
          <div className={classes.account}>
            <AccountCircleIcon></AccountCircleIcon>
          </div>
          <Typography category="Default" variant="Body">
            {gEmail.guestEmail}
          </Typography>
        </div>
      ) : (
        <div
          className={classes.SpaceBtwPd12}
          key={`guest-recipient-${gEmail.guestEmail}`}
        >
          <div className={classes.pd3}>
            <AccessTimeIcon></AccessTimeIcon>
          </div>
          <div className={classes.jutColumn}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["3"]}
            >
              {gEmail.guestEmail}
            </Typography>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["4"]}
            >
              {gEmail.mailStatus === "E" ? t("Expired") : t("Pending")}
            </Typography>
          </div>
        </div>
      );
    },
    [
      classes.SpaceBtwPd12,
      classes.account,
      classes.alignCenter,
      classes.jutColumn,
      classes.pd3,
      t,
    ]
  );
  const recipientMe = useMemo(() => {
    const found = _.find(
      notificationEmail,
      (e) => e.guestEmail === userProfile?.email
    );
    if (found) {
      return (
        <div className={classes.boderNoneBox}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                color="primary"
                style={{ marginRight: 3 }}
                checked={getBooleanEmail(mailStatus)}
                onChange={(e) => {
                  setMailStatus(e.target.checked ? "A" : "D");
                }}
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("(You)", {
                  a: userProfile?.email,
                })}
              </Typography>
            }
          />
        </div>
      );
    }
  }, [
    notificationEmail,
    userProfile?.email,
    classes.boderNoneBox,
    classes.mgNone,
    getBooleanEmail,
    mailStatus,
    t,
  ]);

  return (
    <>
      <div>
        <div className={classes.box}>
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(push)}
                  onChange={(e) => setPush(e.target.checked ? "on" : "off")}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Email notifications")}
                </Typography>
              }
            />
          </div>
          <div className={clsx(classes.boderNoneBox, classes.SpaceBtw)}>
            <div>
              <Typography
                category="Default"
                variant="BodyBold"
                style={{ paddingLeft: 6, marginRight: 4 }}
              >
                {t("Recipients")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["3"]}
              >
                {notificationEmail?.length ?? 1}/3
              </Typography>
            </div>
            <div>
              <IconButton
                className={classes.pdNone}
                onClick={() => setopenInviteModal(true)}
                disabled={notificationEmail?.length === 3}
              >
                <PersonAddIcon></PersonAddIcon>
              </IconButton>
            </div>
          </div>
          {recipientMe}
          {_.chain(notificationEmail)
            .filter((e) => e.guestEmail !== userProfile?.email)
            .map((e) => (
              <div className={classes.boderNoneBox64}>
                {renderGuestRecipient(e)}

                <div>
                  <IconButton
                    onClick={() => {
                      setDeleteRecipients(e.guestEmail);
                      setopenDeleteModal(true);
                    }}
                    className={clsx(classes.pdNone, classes.color3)}
                  >
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </div>
              </div>
            ))
            .value()}
        </div>
        <div
          className={clsx(classes.bigBox, push === "off" && classes.bigBoxNone)}
        >
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_MANUAL)}
                  onChange={(e) => {
                    handleChange("ALARM_MANUAL", e.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Manual recording")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <Typography
              category="Default"
              variant="BodyBold"
              className={classes.title}
            >
              {t("Normal event recording")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_EVENT)}
                  onChange={(e) =>
                    handleChange("ALARM_EVENT", e.target.checked)
                  }
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Impact detection")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_SPEED)}
                  onChange={(e) =>
                    handleChange("ALARM_SPEED", e.target.checked)
                  }
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Overspeed")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_ACCELERATION)}
                  onChange={(e) => {
                    handleChange("ALARM_ACCELERATION", e.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {/* osd 키값 참고 */}
                  {t("Acceleration")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_HARSHBRAKING)}
                  onChange={(e) =>
                    handleChange("ALARM_HARSHBRAKING", e.target.checked)
                  }
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Hard braking")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_SHARPTURN)}
                  onChange={(e) =>
                    handleChange("ALARM_SHARPTURN", e.target.checked)
                  }
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {/* osd 키값 참고 */}
                  {t("Sharp turn")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <Typography
              category="Default"
              variant="BodyBold"
              className={classes.title}
            >
              {t("Parking event recording")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_PARK_EVENT)}
                  onChange={(e) =>
                    handleChange("ALARM_PARK_EVENT", e.target.checked)
                  }
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Impact detection")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_PARK_MOTION)}
                  onChange={(e) =>
                    handleChange("ALARM_PARK_MOTION", e.target.checked)
                  }
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Motion detection")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={
                    getBoolean(current?.ALARM_PARK_IN) ||
                    getBoolean(current?.ALARM_PARK_OUT)
                  }
                  onChange={(e) => {
                    handleChange("ALARM_PARK_IN", e.target.checked);
                    handleChange("ALARM_PARK_OUT", e.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Parking mode enter_")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <Typography
              category="Default"
              variant="BodyBold"
              className={classes.title}
            >
              {t("Geo Fencing")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_GEOFENCE_ENTER)}
                  onChange={(e) => {
                    handleChange("ALARM_GEOFENCE_ENTER", e.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Entering")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_GEOFENCE_EXIT)}
                  onChange={(e) => {
                    handleChange("ALARM_GEOFENCE_EXIT", e.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Exiting")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_GEOFENCE_PASS)}
                  onChange={(e) => {
                    handleChange("ALARM_GEOFENCE_PASS", e.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Passing")}
                </Typography>
              }
            />
          </div>
          {_.includes(DMC100_MODELS, camera?.model) &&
            firmwareConfig?.["System"]?.["DMS"] && (
              <>
                <div className={classes.boderNoneBox}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    style={{ paddingLeft: 6 }}
                  >
                    {t("Driver monitoring system (DMS)")}
                  </Typography>
                </div>
                <div className={classes.boderNoneBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_UNDETECTED)}
                        onChange={(e) => {
                          handleChange("ALARM_UNDETECTED", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Undetected")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.boderNoneBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_DETECTED)}
                        onChange={(e) => {
                          handleChange("ALARM_DETECTED", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Detected")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.boderNoneBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_DROWSY)}
                        onChange={(e) => {
                          handleChange("ALARM_DROWSY", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Drowsy")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.boderBottomBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_DISTRACTED)}
                        onChange={(e) => {
                          handleChange("ALARM_DISTRACTED", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Distracted")}
                      </Typography>
                    }
                  />
                </div>
              </>
            )}
          {_.includes(DMC200_MODELS, camera?.model) && firmwareConfig?.["DMS"] && (
            <>
              <div className={classes.boderNoneBox}>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ paddingLeft: 6 }}
                >
                  {t("Driver monitoring system (DMS)")}
                </Typography>
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.ALARM_UNDETECTED)}
                      onChange={(e) => {
                        handleChange("ALARM_UNDETECTED", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Undetected")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.ALARM_DETECTED)}
                      onChange={(e) => {
                        handleChange("ALARM_DETECTED", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Detected")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.ALARM_DROWSY)}
                      onChange={(e) => {
                        handleChange("ALARM_DROWSY", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Drowsy")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.ALARM_DISTRACTED)}
                      onChange={(e) => {
                        handleChange("ALARM_DISTRACTED", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Distracted")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={
                        getBoolean(current?.ALARM_CALLING) &&
                        getBoolean(current?.ALARM_SMOKING)
                      }
                      onChange={(e) => {
                        handleChange("ALARM_CALLING", e.target.checked);
                        handleChange("ALARM_SMOKING", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Hand distraction")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderBottomBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.ALARM_MASK_OFF)}
                      onChange={(e) => {
                        handleChange("ALARM_MASK_OFF", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Mask off")}
                    </Typography>
                  }
                />
              </div>
            </>
          )}

          {_.includes(LOW_VOLTAGE_MODELS, camera?.model) && (
            <div className={classes.boderBottomBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_LOW_VOLTAGE)}
                    onChange={(e) => {
                      handleChange("ALARM_LOW_VOLTAGE", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Low voltage warning")}
                  </Typography>
                }
              />
            </div>
          )}
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={
                    getBoolean(current?.DEVICE_CONNECT) ||
                    getBoolean(current?.DEVICE_DISCONNECT)
                  }
                  onChange={(e) => {
                    handleChange("DEVICE_CONNECT", e.target.checked);
                    handleChange("DEVICE_DISCONNECT", e.target.checked);
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Cloud connectivity")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  color="primary"
                  checked={getBoolean(current?.ALARM_CLOUDSTORAGE)}
                  onChange={(e) =>
                    handleChange("ALARM_CLOUDSTORAGE", e.target.checked)
                  }
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Cloud storage")}
                </Typography>
              }
            />
          </div>
        </div>
        <Button
          className={classes.buttonSize}
          variant="contained"
          color="primary"
          disabled={disableSave}
          loading={
            loading &&
            (type === loadNotification.type ||
              type === updateNotification.type ||
              type === loadNotificationEmailList.type ||
              type === fixMasterEmailNotification.type)
          }
          onClick={() => {
            if (masterEmailStatus !== mailStatus) {
              handleUpdateMail();
            } else {
              handleUpdate();
            }
          }}
        >
          {t("Save")}
        </Button>
        {/* inviteModal */}
        {openInviteModal && (
          <Modal
            loading={loading && type === invite.type}
            open={openInviteModal}
            mobile={mobile}
            onClose={() => {
              setopenInviteModal(false);
              setGuestEmail("");
            }}
            onClickNegative={() => {
              setopenInviteModal(false);
              setGuestEmail("");
            }}
            onClickPositive={() => {
              console.log(guestEmail);
              if (camera) {
                dispatch(
                  invite({
                    psn: camera?.psn,
                    guestEmail: guestEmail,
                    companyID: "",
                  })
                );
              }
            }}
            heading={t("Send invite")}
            close={true}
            content={
              <div style={{ paddingBottom: 19, marginTop: 8 }}>
                <Input
                  error={error}
                  label="Email"
                  value={guestEmail}
                  onChange={(e) => {
                    checkEmail(e.target.value);
                    setGuestEmail(e.target.value);
                  }}
                />
                {error ? (
                  <FormHelperText
                    style={{ color: LightColors.secondary["11"] }}
                  >
                    {t("Enter a valid email")}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </div>
            }
            RButtonDisabled={disableInvite}
            LButton={t("Cancel")}
            RButton={t("Send")}
          />
        )}
        {/* DeleteModal */}
        <Modal
          loading={loading && type === deleteEmail.type}
          open={openDeleteModal}
          mobile={mobile}
          onClose={() => setopenDeleteModal(false)}
          onClickNegative={() => setopenDeleteModal(false)}
          onClickPositive={() => {
            if (camera) {
              dispatch(
                deleteEmail({
                  psn: camera?.psn,
                  guestEmail: deleteRecipients,
                })
              );
            }
          }}
          heading={t("Delete")}
          close={true}
          content={
            <div className={classes.com}>
              <Typography variant="Body" category="Default">
                {t("Are you sure_recipient")}
              </Typography>
            </div>
          }
          LButton={t("Cancel")}
          RButton={t("Delete")}
          Secondary={true}
        />
      </div>
    </>
  );
};
