import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";

import { makeStyles, Theme } from "@material-ui/core";

import { ResetHeader } from "../components/ResetHeader";
import { Webviewer } from "../contants/Breakpoints";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 58px)",
    // paddingTop: 114,
    alignItems: "center",
    padding: theme.spacing(7, 4, 2, 4),

    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: 320,
      padding: theme.spacing(0, 2, 3, 2),
    },
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 555,
    flexGrow: 1,
    marginTop: theme.spacing(7) + 2,
    marginLeft: theme.spacing(3) + 2,
    [theme.breakpoints.down(Webviewer.mobile)]: {
      maxWidth: "288px !important",
      padding: 0,
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  marginB3: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
      fontSize: "28px !important",
    },
  },
}));

export const DeleteAccountConfirmScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ResetHeader />
      {/* Body */}
      <div className={classes.root}>
        <Container className={classes.body}>
          <div className={classes.formDiv}>
            <Typography
              category="Default"
              variant="H2"
              className={classes.marginB3}
            >
              {t("Your account has been deleted")}
            </Typography>
            <div>
              <Typography category="Default" variant="Body">
                {t(
                  `Thank you for using BlackVue Cloud. We hope to see you back.`
                )}
              </Typography>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
