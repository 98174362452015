import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alerts,
  Button,
  CheckBox,
  Container,
  Google,
  LightColors,
  Link,
  Typography,
} from "@thingsw/pitta-design-system";
import firebase from "firebase/app";
import "firebase/auth";

import AppleIcon from "@material-ui/icons/Apple";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionError, submit } from "redux-form";
import {
  FormControlLabel,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import crypto from "crypto";

import * as yup from "yup";
import moment from "moment";
import { detect } from "detect-browser";

import LoginForm from "../forms/LoginForm";
import { MinimalFooter } from "../components/MinimalFooter";
import { LoginHeader } from "../components/LoginHeader";
import { ILoginForm } from "../types";
import { USER, userLogin } from "../features/User/slice";
import { RootState } from "../features/store";
import { FirebaseAuth } from "../components/FirebaseAuth";
import clsx from "clsx";
import { Webviewer } from "../contants/Breakpoints";
import { clearError, ERROR } from "../features/Error/slice";
import _ from "lodash";
import { MobileLang } from "../contants/Languages";
import withSimpleBar from "../hoc/withSimpleBar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_GATEWAY_URI } from "../contants/Server";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 58px)",
    // paddingTop: 114,
    alignItems: "center",
    padding: theme.spacing(3, 2, 0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 2, 0, 2),
    },
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 438,
    flexGrow: 1,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: theme.spacing(12.25),
    },
  },
  dividerDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
    },
  },
  divider: {
    border: `1px solid ${LightColors.primary["6"]}`,
    position: "absolute",
    width: "100%",
    top: "50%",
    transform: "translate(0, -50%);",
  },
  orDiv: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    backgroundColor: LightColors.primary["0"],
    zIndex: 999,
  },
  btnPadding: {
    padding: "7px 28px 7px 5px",
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  appleBtnMargin: {
    marginBottom: theme.spacing(2) - 1,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
    },
  },
  formControlLabelRoot: {
    marginLeft: -5,
  },
  rememberDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 3,
    marginBottom: theme.spacing(2) + 4,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
      marginBottom: 21,
    },
  },
  forgetDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  errorDiv: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
}));

export const LoginScreen = withSimpleBar(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { loading, mobile_uuid, loginInfo, email } = useSelector(
    (state: RootState) => state[USER]
  );
  const { error } = useSelector((state: RootState) => state[ERROR]);

  const [remember, setRemember] = useState(false);
  const [intialValue, setIntialValue] = useState<{ email?: string }>({});

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(t("Please enter_email"))
          .required(t("Enter email")),
        password: yup.string().required(t("Enter password")),
      }),
    [t]
  );

  useEffect(() => {
    const checkLoginInfo = async (
      email: string,
      user_token: string,
      returnTo: string
    ) => {
      const resp = await axios.get(
        `${API_GATEWAY_URI}/Account/GetProfile?email=${email}&userToken=${user_token}&tokenType=web`
      );
      if (resp.data.resultcode === "BC_ERR_OK") {
        window.location.href = `${returnTo}?email=${email}&user_token=${user_token}&token_type=web`;
      }
    };
    const params = new URLSearchParams(location.search);
    const returnTo = params.get("returnTo");
    if (loginInfo && returnTo && email) {
      checkLoginInfo(email, loginInfo.user_token, returnTo);
    }
  }, [email, location.search, loginInfo]);

  useEffect(() => {
    const email = localStorage.getItem("pitta:email");
    if (email) {
      setRemember(true);
      setIntialValue({ email });
    }
  }, []);

  useEffect(() => {
    dispatch(clearError());
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const handleLogin = () => {
    dispatch(submit("LoginForm"));
  };

  const handleLoginSubmit = async (login: ILoginForm) => {
    const browser = detect();
    const params = new URLSearchParams(location.search);
    const prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
    const lang = prefLang || navigator.language.toLowerCase().substring(0, 2);
    try {
      await schema.validate(login, { abortEarly: false });
      let returnTo = params.get("returnTo");
      const from = params.get("from");
      if (returnTo !== null && from === "sim") {
        const url = new URL(returnTo);
        returnTo = url.origin;
      }
      dispatch(
        userLogin({
          email: login.email,
          passwd: crypto
            .createHash("sha256")
            .update(login.password)
            .digest("hex"),
          mobile_uuid,
          mobile_name: "web",
          mobile_os_type: browser?.os ?? "unknown",
          app_ver: "1.0.0",
          time_interval: moment().utcOffset(),
          tokenType: "web",
          mobileLang: MobileLang[lang],
          remember,
          returnTo: returnTo ?? undefined,
        })
      );
    } catch (err) {
      throw new SubmissionError(
        _.reduce(err.inner, (r, i) => ({ ...r, [i.path]: i.message }), {})
      );
    }
  };

  return (
    <React.Fragment>
      <LoginHeader />
      {/* Body */}
      <div className={classes.root}>
        <Container className={classes.body}>
          {error && (
            <div className={classes.errorDiv}>
              <Alerts mode="web" severity="error">
                {t(error)}
              </Alerts>
            </div>
          )}
          <div className={classes.formDiv}>
            <Typography
              category="Default"
              variant={mobile ? "H2" : "H1"}
              className={classes.marginB4}
            >
              {t("Log in")}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Google fontSize="small" />}
              className={clsx(classes.marginB2, classes.btnPadding)}
              fullWidth
              onClick={() => {
                const googleAuthProvider =
                  new firebase.auth.GoogleAuthProvider();
                firebase.auth().signInWithPopup(googleAuthProvider);
              }}
            >
              {t("Continue with Google")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AppleIcon fontSize="small" />}
              className={clsx(classes.appleBtnMargin, classes.btnPadding)}
              fullWidth
              onClick={() => {
                const appleAuthProvider = new firebase.auth.OAuthProvider(
                  "apple.com"
                );
                firebase.auth().signInWithPopup(appleAuthProvider);
              }}
            >
              {t("Continue with Apple")}
            </Button>
            <div className={classes.dividerDiv}>
              <div className={classes.orDiv}>
                <Typography category="Default" variant="Small">
                  {t("OR")}
                </Typography>
              </div>
              <div className={classes.divider}></div>
            </div>
            <div style={{ marginBottom: 13 }}>
              <LoginForm
                onSubmit={handleLoginSubmit}
                initialValues={intialValue}
              />
            </div>

            <div className={classes.rememberDiv}>
              <FormControlLabel
                classes={{ root: classes.formControlLabelRoot }}
                control={
                  <CheckBox
                    color="primary"
                    checked={remember}
                    onChange={(_e, checked) => {
                      if (!checked) {
                        localStorage.removeItem("pitta:email");
                      }
                      setRemember(checked);
                    }}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                  >
                    {t("Remember me")}
                  </Typography>
                }
              />

              <Hidden smDown>
                <Link category="Default" variant="Body" to="/reset">
                  {t("Forgot password?")}
                </Link>
              </Hidden>
            </div>
            <Button
              fullWidth
              color="primary"
              onClick={handleLogin}
              loading={loading}
            >
              {t("Log in")}
            </Button>

            <Hidden mdUp>
              <div className={classes.forgetDiv}>
                <Link
                  category="Default"
                  variant={mobile ? "Small" : "Body"}
                  to="/reset"
                >
                  {t("Forgot password?")}
                </Link>
              </div>
            </Hidden>
          </div>
          <MinimalFooter />
        </Container>
      </div>
      <FirebaseAuth />
    </React.Fragment>
  );
});
