import Drawer from "@material-ui/core/Drawer";
import {
  makeStyles,
  Theme,
  ThemeProvider,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  ActionChip,
  Button,
  CheckBox,
  DateSinglePicker,
  DMS_EVENTS,
  Fab,
  Fonts,
  IconButton,
  LightColors,
  LiveView,
  Modal,
  MsgType,
  NotificationListItem,
  RadioButton,
  THUMBNAIL_EVENTS,
  Tooltip,
  Typography,
  theme as TWTheme,
} from "@thingsw/pitta-design-system";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import { Webviewer } from "../contants/Breakpoints";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { EmptyItems } from "./EmptyItems";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../features/store";
import { CAMERA, ICameraInfo } from "../features/Camera/slice";
import _ from "lodash";
import { EVENT, ILatestEvent, loadEvent } from "../features/Event/slice";
import { LiveViewPanel } from "./cameras/LiveViewPanel";
import { VideoPlayerModal } from "./cameras/VideoPlayerModal";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CircularProgress, RadioGroup, useMediaQuery } from "@material-ui/core";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";

import BScroll from "@better-scroll/core";
import Indicators from "@better-scroll/indicators";
import { Index, AutoSizer } from "react-virtualized";
import { Scrollbars } from "react-custom-scrollbars";
import { downloadCSVFile } from "../utils/Report";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { NoResults } from "./NoResults";
import { getTextHeight } from "../utils/Text";
import { Direction, Front, Interior, Rear } from "../types";
import { MODELS_2CH } from "../contants/Models";
import { isFree100Check } from "../utils/isFree100Check";
import { ExceedModal } from "./modals/ExceedModal";
import { PAYMENT } from "../features/Payment/slice";
import { loadUsageInfo, USER } from "../features/User/slice";
import ReactDOMServer from "react-dom/server";
import { THEME } from "../features/Theme/slice";

BScroll.use(Indicators);
////////////////////////////////////////////////////////////////////////////////////////
// CSS 스타일
export const FormControlLabel = withStyles((theme: Theme) => ({
  label: {
    ...Fonts.Default.Body,
    color: LightColors.primary["1"],
    marginLeft: 2,
    marginRight: 2,
  },
  root: {
    marginLeft: -6,
    marginRight: -6,
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
}))(MuiFormControlLabel);

const useStyles = makeStyles((theme: Theme) => ({
  filterDiv: {
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 469,
    },
    overflow: "hidden",
    position: "relative",
  },
  filterTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  filterListDiv: {
    flex: 1,
    // padding: theme.spacing(0, 2),
    overflow: "auto",
    "& div.MuiPaper-root": {
      margin: theme.spacing(0, 3),
    },
  },
  filterBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(2),
  },
  filterChipBtnDiv: {
    display: "flex",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingLeft: theme.spacing(3),
    },
  },
  listContDiv: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    top: 60,
    right: 0,
    left: 0,
    bottom: 0,
    transition: theme.transitions.create("transform"),
  },
  listClose: {
    transform: "translateX(-100%)",
  },
  dayFilterClose: {
    transform: "translateX(100%)",
  },
  btn: {
    margin: theme.spacing(0, 2),
  },
  chipDiv: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
    "&:last-child": {
      ...(theme.direction === "rtl" ? { marginLeft: 0 } : { marginRight: 0 }),
    },
  },
  emptyDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  modalRoot: {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
  },
  modalImageRoot: {
    border: 0,
    borderRadius: 0,
  },
  modalContentDiv: {
    padding: theme.spacing(0, 0, 4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 14.625, 4),
    },
  },
  modalImageContenDiv: {
    position: "relative",
    padding: theme.spacing(2, 4),
    backgroundColor: LightColors.primary["1"],
    height: 0,
  },
  datePickerDiv: {
    padding: theme.spacing(0, 1, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  timeRadioDiv: {
    padding: theme.spacing(3),
  },
  filterSetChip: {
    backgroundColor: LightColors.primary["7"],
    color: LightColors.primary["0"],
    borderColor: LightColors.primary["7"],
  },
  dashcamFormLabel: {
    marginBottom: 0,
  },
  modalImageCont: {
    height: "100%",
    display: "flex",
    //@ts-ignore
    // eslint-disable-next-line no-dupe-keys
    display: "-webkit-flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  modalImageDiv: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  modalImageClose: {
    color: LightColors.primary["0"],
  },
  modalImageText: {
    color: LightColors.primary["0"],
    textShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
  },
  zoomInBtn: {
    borderBottom: 0,
    borderRadius: theme.spacing(1, 1, 0, 0),
    boxShadow: "none",
  },
  zoomOutBtn: {
    borderTop: 0,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: "none",
  },
  zoomControlBtn: {
    width: 40,
    height: 40,
    color: LightColors.primary["1"],
    minHeight: 40,
  },
  modalImageBtn: {
    borderRadius: 0,
    backgroundColor: "transparent",
    filter:
      "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2))",
  },
  scrollIndicator: {
    width: 168,
    height: 115,
    position: "relative",
    // backgroundColor: "rgba(19, 19, 28, 0.45)",
    overflow: "hidden",
  },
  scrollIndicatorBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  scrollIndicatorHandle: {
    position: "absolute",
    border: "1px solid white",
    // boxShadow: "0 0 5px white",
    width: 64,
    height: 36,
    zIndex: 1,
    boxShadow: "0 0 0 9999px rgba(19, 19, 28, 0.45)",
  },
  scrollIndicatorWrapper: {
    position: "absolute",
    bottom: 16,
    right: 80,
    border: `3px solid ${LightColors.primary["1"]}`,
    boxShadow:
      "0px 0px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.14)",
  },
  noCamText: {
    marginTop: theme.spacing(1.125),
  },
  camAllCheckDiv: {
    flex: 1,
  },
  camAllCheckHide: {
    width: 0,
    overflow: "hidden",
  },
  notificationTextDiv: {
    boxSizing: "border-box",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Roboto",
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 421,
      padding: 0,
    },
  },
  notificationThumbTextDiv: {
    boxSizing: "border-box",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Roboto",
    width: "100%",
    padding: theme.spacing(0, 8),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 326,
      padding: 0,
    },
  },
  modalTitle: {
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(2, 2, 2, 1.625) }
      : { padding: theme.spacing(2, 1.625, 2, 2) }),
    textOverflow: "ellipsis",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(2, 4, 2, 3.625) }
        : { padding: theme.spacing(2, 3.625, 2, 4) }),
    },
  },
  closeStyle: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      right: 28,
    },
  },
}));

const MSG_CODE_TYPE: MsgType[] =
  process.env.REACT_APP_ENV === "qa"
    ? [
        "ALARM_MANUAL",
        // Normal event recording
        "ALARM_EVENT",
        "ALARM_SPEED",
        "ALARM_HARSHBRAKING",
        "ALARM_ACCELERATION",
        "ALARM_SHARPTURN",

        // Parking event recording
        "ALARM_PARK_EVENT",
        "ALARM_PARK_MOTION",
        "ALARM_PARK_IN",
        "ALARM_PARK_OUT",

        // Geofencing
        "ALARM_GEOFENCE_ENTER",
        "ALARM_GEOFENCE_EXIT",
        "ALARM_GEOFENCE_PASS",

        // Driver monitoring system (DMS)
        "ALARM_CALLING",
        "ALARM_SMOKING",
        "ALARM_MASK_OFF",
        "ALARM_DROWSY",
        "ALARM_DISTRACTED",
        "ALARM_UNDETECTED",
        "ALARM_DETECTED",

        // Etc
        "DEVICE_CONNECT",
        "DEVICE_DISCONNECT",
        "ALARM_CLOUDSTORAGE",
        "ALARM_LOW_VOLTAGE",

        "ALARM_SETTING_SAVED",
      ]
    : [
        "ALARM_MANUAL",
        // Normal event recording
        "ALARM_EVENT",
        "ALARM_SPEED",
        "ALARM_HARSHBRAKING",
        "ALARM_ACCELERATION",
        "ALARM_SHARPTURN",

        // Parking event recording
        "ALARM_PARK_EVENT",
        "ALARM_PARK_MOTION",
        "ALARM_PARK_IN",
        "ALARM_PARK_OUT",

        // Geofencing
        "ALARM_GEOFENCE_ENTER",
        "ALARM_GEOFENCE_EXIT",
        "ALARM_GEOFENCE_PASS",

        // Driver monitoring system (DMS)
        "ALARM_DROWSY",
        "ALARM_DISTRACTED",
        "ALARM_UNDETECTED",
        "ALARM_DETECTED",

        // Etc
        "DEVICE_CONNECT",
        "DEVICE_DISCONNECT",
        "ALARM_CLOUDSTORAGE",
        "ALARM_LOW_VOLTAGE",

        "ALARM_SETTING_SAVED",
      ];

const GEOFENCE_EVENTS = [
  "ALARM_GEOFENCE_ENTER",
  "ALARM_GEOFENCE_EXIT",
  "ALARM_GEOFENCE_PASS",
];
const EXCLUDE_EVENT: string[] = [
  "ALARM_PARK_OUT",
  "DEVICE_DISCONNECT",
  "ALARM_SETTING_SAVED",
  "ALARM_CALLING",
];

export interface NotificationPanelProps {
  open: boolean;
  onClose: () => void;
}

export const NotificationPanel = ({
  open,
  onClose,
}: NotificationPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const filterDivRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List>(null);

  const { direction, fontFamily } = useSelector(
    (state: RootState) => state[THEME]
  );
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const { events: eventsOrig, loading } = useSelector(
    (state: RootState) => state[EVENT]
  );
  const [actives, setActives] = useState<{ psn: string; active: boolean }[]>(
    []
  );
  const [psns, setPsns] = useState<string[]>(
    _.map(cameraList?.DeviceListInfo, (dev) => dev.device.psn)
  );
  const [filterPsns, setFilterPsns] = useState<string[]>([]);
  const [msgCodes, setMsgCodes] = useState<string[]>(MSG_CODE_TYPE);
  const [date, setDate] = useState(moment().startOf("d"));
  const [dateOption, setDateOption] = useState(-1);
  const [filterDate, setFilterDate] = useState(moment().startOf("d"));
  const [filterDateOption, setFilterDateOption] = useState(-1);
  const [filterMsgCodes, setFilterMsgCodes] = useState<string[]>(MSG_CODE_TYPE);
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openDayFilter, setOpenDayFilter] = useState(false);
  const [openCamFilter, setOpenCamFilter] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openEventFilter, setOpenEventFilter] = useState(false);
  const [appliedDayFilter, setAppliedDayFilter] = useState(false);
  const [appliedCamFilter, setAppliedCamFilter] = useState(false);
  const [appliedEventFilter, setAppliedEventFilter] = useState(false);
  const [openExceedModal, setOpenExceedModal] = useState(false);
  const [camera, setCamera] = useState<ICameraInfo>();
  const [event, setEvent] = useState<ILatestEvent>();
  const [imgScale, setImgScale] = useState(1);
  const [modalImgRef, setModalImgRef] = useState<HTMLDivElement | null>(null);
  const [modalMinimapRef, setModalMinimapRef] = useState<HTMLDivElement | null>(
    null
  );
  const [showImgMinimap, setShowImgMinimap] = useState(false);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [camList, setCamList] = useState<ICameraInfo[]>([]);
  const [events, setEvents] = useState<ILatestEvent[]>([]);
  const bscroll = useRef<any>();
  const [now, setNow] = useState(moment.tz());

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const userUsage = useSelector((state: RootState) => state[USER].usageInfo);

  const exceptionFilterMsgCodes = useMemo(
    () => _.filter(filterMsgCodes, (f) => !_.includes(EXCLUDE_EVENT, f)),
    [filterMsgCodes]
  );

  const exceptionMSG_CODE_TYPE = useMemo(
    () => _.filter(MSG_CODE_TYPE, (f) => !_.includes(EXCLUDE_EVENT, f)),
    []
  );
  useEffect(() => {
    dispatch(loadUsageInfo());
  }, [dispatch]);

  useEffect(() => {
    const updateNow = () => {
      setNow(moment.tz());
    };
    const timer = setInterval(updateNow, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setEvents(eventsOrig);
  }, [eventsOrig]);

  useEffect(() => {
    setCamList((cams) => {
      const pp = _.map(cameraList?.DeviceListInfo, (dev) => dev.device.psn);
      const pp2 = _.map(cams, (cam) => cam.psn);
      if (!_.isEqual(pp, pp2)) {
        return _.map(cameraList?.DeviceListInfo, (dev) => dev.device);
      } else {
        return cams;
      }
    });
    setActives((active) =>
      _.map(cameraList?.DeviceListInfo, (dev) => {
        const found = _.find(
          active,
          (act) =>
            act.psn === dev.device.psn &&
            act.active === (dev.device.active === "on" ? true : false)
        );
        if (found) {
          return found;
        }
        return {
          psn: dev.device.psn,
          active: dev.device.active === "on" ? true : false,
        };
      })
    );
  }, [cameraList?.DeviceListInfo]);

  useEffect(() => {
    setAppliedEventFilter(msgCodes.length !== MSG_CODE_TYPE.length);
  }, [msgCodes]);

  useEffect(() => {
    setAppliedCamFilter(psns.length !== camList.length);
  }, [camList, psns.length]);

  useEffect(() => {
    setAppliedDayFilter(!date.isSame(moment(), "d") || dateOption !== -1);
  }, [date, dateOption]);

  useEffect(() => {
    if (!appliedCamFilter) {
      setPsns((p) => {
        const newPsns = _.map(camList, (dev) => dev.psn);
        if (
          _.difference(p, newPsns).length === 0 &&
          p.length === newPsns.length
        ) {
          return p;
        } else {
          return newPsns;
        }
      });
    }
    setActives((a) => {
      const newActives = _.map(camList, (dev) => ({
        psn: dev.psn,
        active: dev.active === "on" ? true : false,
      }));
      if (
        _.difference(a, newActives).length === 0 &&
        a.length === newActives.length
      ) {
        return a;
      } else {
        return newActives;
      }
    });
  }, [appliedCamFilter, camList]);

  const free100Check = useMemo(() => {
    if (subscriptionInfo && userUsage) {
      return isFree100Check(subscriptionInfo, userUsage);
    }
  }, [subscriptionInfo, userUsage]);

  useEffect(() => {
    // console.log("open", date, dateOption);
    if (psns.length > 0 && msgCodes.length > 0 && open) {
      let startDate: moment.Moment;
      let endDate: moment.Moment;
      if (dateOption === -1) {
        startDate = moment(date).startOf("d");
        endDate = moment(date).endOf("d");
      } else {
        startDate = moment(date).startOf("d").set("hour", dateOption);
        endDate = moment(startDate).endOf("h");
        // console.log(startDate, endDate);
      }
      const geofenceEvents = _.filter(msgCodes, (code) =>
        _.includes(GEOFENCE_EVENTS, code)
      );
      let evMsgCodes = _.filter(
        msgCodes,
        (code) => !_.includes(GEOFENCE_EVENTS, code)
      );
      if (geofenceEvents.length > 0) {
        evMsgCodes = [...evMsgCodes, "ALARM_GEOFENCE"];
      }
      dispatch(
        loadEvent({
          psn: psns,
          msg_code: evMsgCodes,
          startDate: startDate.utc(true).unix().toString(),
          endDate: endDate.utc(true).unix().toString(),
        })
      );
    }
  }, [date, dateOption, dispatch, msgCodes, open, psns]);

  const onLiveView = useCallback(
    (evt) => {
      const cam = _.find(
        cameraList?.DeviceListInfo,
        (dev) => dev.device.psn === evt.psn
      )?.device;
      if (cam) {
        setCamera(cam);
        setEvent(evt);
        setOpenLiveModal(true);
      }
    },
    [cameraList?.DeviceListInfo]
  );

  const onPlay = useCallback(
    (evt) => {
      const cam = _.find(
        cameraList?.DeviceListInfo,
        (dev) => dev.device.psn === evt.psn
      )?.device;
      if (cam) {
        if (free100Check) {
          setOpenExceedModal(true);
        } else {
          setCamera(cam);
          setEvent(evt);
          setOpenVideoModal(true);
        }
      }
    },
    [cameraList?.DeviceListInfo, free100Check]
  );

  const openFailModal = useCallback(() => {}, []);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const e = events[props.index];
      const cam = _.find(
        cameraList?.DeviceListInfo,
        (dev) => dev.device.psn === e.psn
      )?.device;
      const active = _.find(actives, (a) => a.psn === e.psn)?.active;
      const activePlay =
        now.diff(e.vdate, "s") > 90 &&
        !_.includes(["ALARM_DETECTED", "ALARM_MASK_OFF"], e.msg_code);
      return (
        <div key={props.key} style={props.style}>
          <NotificationListItem
            event={e}
            utcOffset={cam?.interval ?? 0}
            disableLiveview={!active}
            disablePlaback={!active || !activePlay}
            onLiveView={active ? onLiveView : openFailModal}
            onPlay={active ? onPlay : openFailModal}
            onImage={(ev) => {
              setEvent(ev);
              setOpenImageModal(true);
            }}
          />
        </div>
      );
    },
    [
      actives,
      cameraList?.DeviceListInfo,
      events,
      now,
      onLiveView,
      onPlay,
      openFailModal,
    ]
  );

  const rowHeight = useCallback(
    (indx: Index) => {
      const e = events[indx.index];
      let height = 0;
      const msgTypo = ReactDOMServer.renderToString(
        <ThemeProvider
          theme={TWTheme({
            fontFamily,
            dir: direction,
            Colors: LightColors,
            breakpoints: {
              values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
            },
          })}
        >
          <Typography category="Default" variant="Body">
            {e.msg}
          </Typography>
        </ThemeProvider>
      );
      const tmp = `<div>
                    <span style="font-weight: 400; font-style: normal; font-size: 0.875rem; line-height: 1.5;">Feb 22, 4:35:03 PM</span>
                    <div>
                      ${msgTypo}
                    </div>
                  </div>`;
      if (_.includes(THUMBNAIL_EVENTS, e.msg_code)) {
        height = 83 + getTextHeight(tmp, classes.notificationThumbTextDiv, 20);
      } else {
        height = 54 + getTextHeight(tmp, classes.notificationTextDiv, 20);
      }
      console.log("rowHeight", e, height);
      return height;
    },
    [
      classes.notificationTextDiv,
      classes.notificationThumbTextDiv,
      direction,
      events,
      fontFamily,
    ]
  );

  const handleScroll = useCallback((e) => {
    if (listRef.current) {
      const { scrollTop, scrollLeft } = e.target;
      const { Grid } = listRef.current;
      Grid?.handleScrollEvent({ scrollTop, scrollLeft });
    }
  }, []);

  const notificationsMarkup = useMemo(() => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    } else if (events.length > 0) {
      if (filterDivRef.current) {
        return (
          <AutoSizer>
            {({ height, width }) =>
              mobile ? (
                <List
                  width={width}
                  height={height}
                  rowHeight={rowHeight}
                  rowCount={events.length}
                  rowRenderer={rowRenderer}
                  ref={listRef}
                />
              ) : (
                <Scrollbars
                  onScroll={handleScroll}
                  style={{ height, width }}
                  autoHide
                >
                  <List
                    width={width}
                    height={height}
                    rowHeight={rowHeight}
                    rowCount={events.length}
                    rowRenderer={rowRenderer}
                    style={{ overflowY: "visible", overflowX: "visible" }}
                    ref={listRef}
                  />
                </Scrollbars>
              )
            }
          </AutoSizer>
        );
      } else {
        return <></>;
      }
    } else if (appliedDayFilter || appliedCamFilter || appliedEventFilter) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={LightColors.primary["2"]}
          >
            {t("No results")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
            style={{ marginBottom: 24 }}
          >
            {t("Try changing your_")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const allPsns = _.map(camList, (dev) => dev.psn);
              setDate(moment());
              setDateOption(-1);
              setPsns(allPsns);
              setMsgCodes(MSG_CODE_TYPE);

              setFilterDate(moment());
              setFilterDateOption(-1);
              setFilterPsns(allPsns);
              setFilterMsgCodes(MSG_CODE_TYPE);
            }}
          >
            {t("Remove all filters")}
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classes.emptyDiv}>
          <EmptyItems
            variant="small"
            mode="notifications"
            noCamTextClassName={clsx(mobile && classes.noCamText)}
          />
        </div>
      );
    }
  }, [
    appliedCamFilter,
    appliedDayFilter,
    appliedEventFilter,
    camList,
    classes.emptyDiv,
    classes.noCamText,
    events.length,
    handleScroll,
    loading,
    mobile,
    rowHeight,
    rowRenderer,
    t,
  ]);

  const handleEventCheck = useCallback(
    (msgNames: string[]) => (e: any, checked: boolean) => {
      if (checked) {
        setFilterMsgCodes(
          _.chain(filterMsgCodes).concat(msgNames).uniq().value()
        );
      } else {
        setFilterMsgCodes(
          _.filter(filterMsgCodes, (code) => !_.includes(msgNames, code))
        );
      }
    },
    [filterMsgCodes]
  );

  const renderEventsFilterPanel = useMemo(() => {
    return (
      <>
        <div style={{ padding: "13px 18px 0" }}>
          <FormControlLabel
            control={
              <CheckBox
                indeterminate={
                  exceptionFilterMsgCodes.length > 0 &&
                  exceptionFilterMsgCodes.length < exceptionMSG_CODE_TYPE.length
                }
                color="primary"
                checked={exceptionFilterMsgCodes.length > 0}
                onChange={(_e, checked) => {
                  if (!checked) {
                    setFilterMsgCodes([]);
                  } else {
                    setFilterMsgCodes(MSG_CODE_TYPE);
                  }
                }}
              />
            }
            label={
              <>
                <Typography category="Default" variant="BodyBold">
                  {t("Events")}
                </Typography>

                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {` ${exceptionFilterMsgCodes.length}/${
                    MSG_CODE_TYPE.length - 4
                  }`}
                </Typography>
              </>
            }
          />
        </div>
        <div style={{ flex: 1, overflow: "auto", padding: "13px 18px" }}>
          <div
            style={{
              paddingBottom: 16,
              borderBottom: `1px solid ${LightColors.primary["6"]}`,
            }}
          >
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_MANUAL")}
                  onChange={handleEventCheck(["ALARM_MANUAL"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Manual recording")}
                </Typography>
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px 0",
              borderBottom: `1px solid ${LightColors.primary["6"]}`,
            }}
          >
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ paddingBottom: 16 }}
            >
              {t("Normal event recording")}
            </Typography>
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_EVENT")}
                  onChange={handleEventCheck(["ALARM_EVENT"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Impact detection")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_SPEED")}
                  onChange={handleEventCheck(["ALARM_SPEED"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Overspeed")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_HARSHBRAKING")}
                  onChange={handleEventCheck(["ALARM_HARSHBRAKING"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Hard braking")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_ACCELERATION")}
                  onChange={handleEventCheck(["ALARM_ACCELERATION"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {/* osd 키값 참고 */}
                  {t("Acceleration")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_SHARPTURN")}
                  onChange={handleEventCheck(["ALARM_SHARPTURN"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {/* osd 키값 참고 */}
                  {t("Sharp turn")}
                </Typography>
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px 0",
              borderBottom: `1px solid ${LightColors.primary["6"]}`,
            }}
          >
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ paddingBottom: 16 }}
            >
              {t("Parking event recording")}
            </Typography>
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_PARK_EVENT")}
                  onChange={handleEventCheck(["ALARM_PARK_EVENT"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Impact detection")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_PARK_MOTION")}
                  onChange={handleEventCheck(["ALARM_PARK_MOTION"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Motion detection")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={
                    _.includes(filterMsgCodes, "ALARM_PARK_IN") ||
                    _.includes(filterMsgCodes, "ALARM_PARK_OUT")
                  }
                  onChange={(e, checked) => {
                    handleEventCheck(["ALARM_PARK_IN", "ALARM_PARK_OUT"])(
                      e,
                      checked
                    );
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Parking mode enter_")}
                </Typography>
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px 0",
              borderBottom: `1px solid ${LightColors.primary["6"]}`,
            }}
          >
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ paddingBottom: 16 }}
            >
              {t("Geo Fencing")}
            </Typography>
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_GEOFENCE_ENTER")}
                  onChange={handleEventCheck(["ALARM_GEOFENCE_ENTER"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Entering")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_GEOFENCE_EXIT")}
                  onChange={handleEventCheck(["ALARM_GEOFENCE_EXIT"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Exiting")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_GEOFENCE_PASS")}
                  onChange={handleEventCheck(["ALARM_GEOFENCE_PASS"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Passing")}
                </Typography>
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px 0",
              borderBottom: `1px solid ${LightColors.primary["6"]}`,
            }}
          >
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ paddingBottom: 16 }}
            >
              {t("Driver monitoring system (DMS)")}
            </Typography>
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_UNDETECTED")}
                  onChange={handleEventCheck(["ALARM_UNDETECTED"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Undetected")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_DETECTED")}
                  onChange={handleEventCheck(["ALARM_DETECTED"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Detected")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_DROWSY")}
                  onChange={handleEventCheck(["ALARM_DROWSY"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Drowsy")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_DISTRACTED")}
                  onChange={handleEventCheck(["ALARM_DISTRACTED"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Distracted")}
                </Typography>
              }
            />
            {process.env.REACT_APP_ENV === "qa" && (
              <>
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      checked={
                        _.includes(filterMsgCodes, "ALARM_CALLING") ||
                        _.includes(filterMsgCodes, "ALARM_SMOKING")
                      }
                      onChange={(e, checked) => {
                        handleEventCheck(["ALARM_CALLING", "ALARM_SMOKING"])(
                          e,
                          checked
                        );
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Hand distraction")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      checked={_.includes(filterMsgCodes, "ALARM_MASK_OFF")}
                      onChange={handleEventCheck(["ALARM_MASK_OFF"])}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Mask off")}
                    </Typography>
                  }
                />
              </>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px 0 0",
            }}
          >
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_LOW_VOLTAGE")}
                  onChange={handleEventCheck(["ALARM_LOW_VOLTAGE"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Low voltage warning")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={
                    _.includes(filterMsgCodes, "DEVICE_CONNECT") ||
                    _.includes(filterMsgCodes, "DEVICE_DISCONNECT")
                  }
                  onChange={(e, checked) => {
                    handleEventCheck(["DEVICE_CONNECT", "DEVICE_DISCONNECT"])(
                      e,
                      checked
                    );
                  }}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Cloud connectivity")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  checked={_.includes(filterMsgCodes, "ALARM_CLOUDSTORAGE")}
                  onChange={handleEventCheck(["ALARM_CLOUDSTORAGE"])}
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Cloud storage")}
                </Typography>
              }
            />
          </div>
        </div>
        <div className={classes.filterBtnDiv}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: 16 }}
            disabled={filterMsgCodes.length === MSG_CODE_TYPE.length}
            onClick={() => {
              setFilterMsgCodes(MSG_CODE_TYPE);
            }}
          >
            {t("Clear")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            // loading={loading}
            disabled={exceptionFilterMsgCodes.length === 0}
            onClick={() => {
              setOpenEventFilter(false);
              if (
                exceptionFilterMsgCodes.length === exceptionMSG_CODE_TYPE.length
              ) {
                if (_.includes(filterMsgCodes, "ALARM_SETTING_SAVED")) {
                  setMsgCodes(filterMsgCodes);
                } else {
                  setMsgCodes(_.concat(filterMsgCodes, "ALARM_SETTING_SAVED"));
                }
              } else {
                setMsgCodes(_.pull(filterMsgCodes, "ALARM_SETTING_SAVED"));
              }
            }}
          >
            {t("OK")}
          </Button>
        </div>
      </>
    );
  }, [
    classes.filterBtnDiv,
    exceptionFilterMsgCodes,
    exceptionMSG_CODE_TYPE,
    filterMsgCodes,
    handleEventCheck,
    t,
  ]);

  const renderDatePickerPanel = useMemo(() => {
    const nowD = moment().startOf("d");
    return (
      <>
        <div style={{ flex: 1, overflow: "auto" }}>
          <div className={classes.datePickerDiv}>
            <DateSinglePicker
              day={filterDate}
              onChangeDay={(day) => setFilterDate(day)}
              t={t}
            />
          </div>
          <div className={classes.timeRadioDiv}>
            <RadioGroup
              aria-label="gender"
              name="time"
              value={filterDateOption.toString()}
              onChange={(e) => setFilterDateOption(parseInt(e.target.value))}
            >
              <RadioButton
                key={`time-radio--1`}
                value="-1"
                label={t("All day")}
              />
              {_.range(0, 24).map((h) => {
                const time = moment(nowD).add(h, "h");
                return (
                  <RadioButton
                    key={`time-radio-${h}`}
                    value={`${h}`}
                    label={`${time.format("hh:mm A")} - ${moment(time)
                      .endOf("h")
                      .format("hh:mm A")}`}
                  />
                );
              })}
            </RadioGroup>
          </div>
        </div>

        <div className={classes.filterBtnDiv}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: 16 }}
            disabled={
              filterDate.isSame(moment(), "d") && filterDateOption === -1
            }
            onClick={() => {
              setFilterDate(moment());
              setFilterDateOption(-1);
            }}
          >
            {t("Clear")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            // loading={loading}
            onClick={() => {
              setDate(filterDate);
              setDateOption(filterDateOption);
              setOpenDayFilter(false);
            }}
          >
            {t("OK")}
          </Button>
        </div>
      </>
    );
  }, [
    classes.datePickerDiv,
    classes.filterBtnDiv,
    classes.timeRadioDiv,
    filterDate,
    filterDateOption,
    t,
  ]);

  const handleDashcamCheck = useCallback(
    (psn: string) => (e: any, checked: boolean) => {
      if (checked) {
        setFilterPsns(_.chain(filterPsns).concat(psn).uniq().value());
      } else {
        setFilterPsns(_.filter(filterPsns, (p) => p.indexOf(psn) === -1));
      }
    },
    [filterPsns]
  );
  const cameraFilterTextMarkup = useMemo(() => {
    if (appliedCamFilter) {
      if (psns.length === 1) {
        const targetPsn = psns[0];
        const cam = _.find(camList, (dev) => dev.psn === targetPsn);
        return cam?.dev_name;
      } else {
        return `${t("Camera")} · ${psns.length}`;
      }
    }
    //  else {
    //   setMsgCodes(MSG_CODE_TYPE);
    // }
    return t("All cameras");
  }, [appliedCamFilter, camList, psns, t]);

  useEffect(() => {
    setFilterPsns(
      _.chain(camList)
        .filter(
          (d) =>
            d.dev_name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
            d.model.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
        )
        .map((d) => d.psn)
        .value()
    );
  }, [camList, searchKey]);

  const renderCameraPicker = useMemo(() => {
    const cams = _.chain(camList)
      .filter(
        (d) =>
          d.dev_name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
          d.model.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
      )
      .value();

    return (
      <>
        <div style={{ padding: "13px 24px", display: "flex", maxHeight: 56 }}>
          <div
            className={clsx({
              [classes.camAllCheckDiv]: !openSearch,
              [classes.camAllCheckHide]: openSearch,
            })}
          >
            <FormControlLabel
              control={
                <CheckBox
                  color="primary"
                  indeterminate={
                    filterPsns.length > 0 && filterPsns.length < cams.length
                  }
                  checked={cams.length === filterPsns.length}
                  onChange={(_e, checked) => {
                    if (filterPsns.length === 0) {
                      setFilterPsns(_.map(cams, (c) => c.psn));
                    } else {
                      setFilterPsns([]);
                    }
                  }}
                />
              }
              label={
                <>
                  <Typography category="Default" variant="BodyBold">
                    {t("Cameras")}
                  </Typography>
                  <Typography category="Default" variant="Body">
                    {` ${filterPsns.length}/${cams.length}`}
                  </Typography>
                </>
              }
            />
          </div>
          <div
            className={clsx({
              [classes.camAllCheckDiv]: openSearch,
            })}
          >
            <Input
              SecondaryColor
              placeholder={t("Search cameras")}
              startIcon={<SearchIcon />}
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
              search
              dense
              onOpenSearch={() => setOpenSearch(true)}
              onCloseSearch={() => {
                setSearchKey("");
                setOpenSearch(false);
              }}
            />
          </div>
        </div>

        <div style={{ flex: 1, overflow: "auto", padding: "3px 24px" }}>
          {cams.length === 0 && (
            <div
              style={{ display: "flex", height: "100%", alignItems: "center" }}
            >
              <NoResults />
            </div>
          )}
          {cams.length > 0 &&
            _.map(cams, (cam) => {
              return (
                <div key={`dashcam-filter-form-${cam.psn}`}>
                  <FormControlLabel
                    classes={{ root: classes.dashcamFormLabel }}
                    control={
                      <CheckBox
                        color="primary"
                        checked={_.includes(filterPsns, cam.psn)}
                        onChange={handleDashcamCheck(cam.psn)}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {cam.dev_name}
                      </Typography>
                    }
                  />
                  <div style={{ padding: "0 26px" }}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {cam.model}
                    </Typography>
                  </div>
                </div>
              );
            })}
        </div>

        <div className={classes.filterBtnDiv}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: 16 }}
            disabled={filterPsns.length === cams.length}
            onClick={() => {
              setFilterPsns(_.map(cams, (c) => c.psn));
            }}
          >
            {t("Clear")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={filterPsns.length === 0}
            // loading={loading}
            onClick={() => {
              setOpenCamFilter(false);
              setPsns(filterPsns);
            }}
          >
            {t("OK")}
          </Button>
        </div>
      </>
    );
  }, [
    camList,
    classes.camAllCheckDiv,
    classes.camAllCheckHide,
    classes.dashcamFormLabel,
    classes.filterBtnDiv,
    filterPsns,
    handleDashcamCheck,
    openSearch,
    searchKey,
    t,
  ]);

  const dayChipText = useMemo(() => {
    let dayOpt = t("all day");
    if (dateOption >= 0) {
      const d1 = moment(dateOption, "H");
      const d2 = moment(dateOption, "H").add(1, "h");
      dayOpt = `${d1.format("ha")} - ${d2.format("ha")}`;
    }
    return `${date.format("MMM D; ")} ${t(dayOpt)}`;
  }, [date, dateOption, t]);

  useEffect(() => {
    if (modalImgRef && modalMinimapRef) {
      if (modalImgRef.clientWidth < imgScale * imgWidth) {
        setShowImgMinimap(true);
        if (!bscroll.current) {
          bscroll.current = new BScroll(modalImgRef, {
            // startX: -50,
            // startY: -50,
            freeScroll: true,

            indicators: [
              {
                //@ts-ignore
                relationElement: modalMinimapRef,
                relationElementHandleElementIndex: 1,
              },
            ],
          });
        } else {
          bscroll.current.refresh();
        }
      } else {
        setShowImgMinimap(false);
      }
    }
  }, [imgScale, imgWidth, modalImgRef, modalMinimapRef]);

  const imageModalContentMarkup = useMemo(() => {
    if (event) {
      const scaleX = Math.max((imgWidth * imgScale) / window.innerWidth, 1);
      const scaleY = Math.max((imgHeight * imgScale) / window.innerHeight, 1);
      // const scaleX = Math.max((imgWidth * imgScale) / 320, 1);
      // const scaleY = Math.max((imgHeight * imgScale) / 180, 1);
      const cam = _.find(camList, (dev) => dev.psn === event.psn);
      // eslint-disable-next-line no-control-regex
      const regex = new RegExp(/(\[[\u0000-\uFFFF]+\])?([\u0000-\uFFFF]+)/);
      const activePlay = now.diff(event.vdate, "s") > 90;

      const modalStyle = showImgMinimap
        ? {}
        : { display: "flex", justifyContent: "center", alignItems: "center" };

      return (
        <div className={classes.modalImageCont}>
          <div style={{ zIndex: 1009 }}>
            <div>
              <Typography
                category="Default"
                variant="BodyBold"
                className={classes.modalImageText}
              >
                {cam?.dev_name}
              </Typography>
            </div>
            <div>
              <Typography
                category="Default"
                variant="Body"
                className={classes.modalImageText}
              >
                {_.last(event.msg.match(regex))}
              </Typography>
            </div>
          </div>
          <div className={clsx(classes.modalImageDiv)}>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                ...modalStyle,
              }}
              ref={(ref) => setModalImgRef(ref)}
            >
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img
                src={event.img_url}
                style={{ width: imgWidth * imgScale }}
                onLoad={(e) => {
                  //@ts-ignore
                  setImgWidth(e.target.naturalWidth);
                  //@ts-ignore
                  setImgHeight(e.target.naturalHeight);
                }}
                // style={{ transform: `scale(${imgScale})` }}
                alt="Event Image"
              />
            </div>
            <div className={classes.scrollIndicatorWrapper}>
              <div
                className={classes.scrollIndicator}
                style={{ display: showImgMinimap ? "block" : "none" }}
                ref={(ref) => setModalMinimapRef(ref)}
                id="minimap"
              >
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img
                  className={classes.scrollIndicatorBg}
                  src={event.img_url}
                />
                <div
                  className={classes.scrollIndicatorHandle}
                  style={{ width: 168 / scaleX, height: 115 / scaleY }}
                ></div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
              alignItems: "flex-end",
            }}
          >
            <div style={{ height: 36, display: "flex", alignItems: "center" }}>
              <Typography
                category="Default"
                variant="Body"
                className={classes.modalImageText}
              >
                {event.vdate?.format("MMM D, h:mm:ss A")}
              </Typography>
            </div>
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <Button
                variant="text"
                color="primary"
                className={classes.modalImageBtn}
                startIcon={<LiveView />}
                disabled={cam?.active !== "on"}
                onClick={() => onLiveView(event)}
              >
                {t("Live view")}
              </Button>
              <Button
                variant="text"
                color="primary"
                className={classes.modalImageBtn}
                startIcon={<PlayArrowIcon />}
                disabled={cam?.active !== "on" || !activePlay}
                onClick={() => {
                  onPlay(event);
                  setOpenImageModal(false);
                }}
              >
                {t("Playback")}
              </Button>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 8,
                  boxShadow:
                    "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Tooltip
                  disableTouchListener={mobile}
                  title={t("Zoom in") ?? "Zoom in"}
                >
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.zoomControlBtn, classes.zoomInBtn)}
                    onClick={() => {
                      setImgScale((s) => Math.min(s + 0.1, 2.5));
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Fab>
                </Tooltip>
                <Tooltip
                  disableTouchListener={mobile}
                  title={t("Zoom out") ?? "Zoom out"}
                >
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.zoomControlBtn, classes.zoomOutBtn)}
                    onClick={() => {
                      setImgScale((s) => Math.max(s - 0.1, 0.3));
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div></div>;
  }, [
    camList,
    classes.modalImageBtn,
    classes.modalImageCont,
    classes.modalImageDiv,
    classes.modalImageText,
    classes.scrollIndicator,
    classes.scrollIndicatorBg,
    classes.scrollIndicatorHandle,
    classes.scrollIndicatorWrapper,
    classes.zoomControlBtn,
    classes.zoomInBtn,
    classes.zoomOutBtn,
    event,
    imgHeight,
    imgScale,
    imgWidth,
    mobile,
    now,
    onLiveView,
    onPlay,
    showImgMinimap,
    t,
  ]);
  const eventFilterTextMarkup = useMemo(() => {
    return appliedEventFilter
      ? `${t("Events")} · ${
          _.filter(msgCodes, (m) => !_.includes(EXCLUDE_EVENT, m)).length
        }`
      : t("All events");
  }, [appliedEventFilter, msgCodes, t]);

  const handleDownloadReport = useCallback(() => {
    let csvString = "";
    csvString += `${t("Date")},${date.format("YYYY.M.D")}\n`;

    if (dateOption !== -1) {
      const time = moment(date).startOf("d").add(dateOption, "h");
      const timeString = `${time.format("hh:mm A")} - ${moment(time)
        .endOf("h")
        .format("hh:mm A")}`;
      csvString += `${t("Time")},${timeString}\n`;
    } else {
      csvString += `${t("Time")},${t("All day")}\n`;
    }
    csvString += `${t("Camera")},${cameraFilterTextMarkup}\n`;
    csvString += `${t("Event")},${eventFilterTextMarkup}\n`;
    csvString += "\n";

    csvString += _.chain(events)
      .map((evt, indx) => {
        const cam = _.find(
          cameraList?.DeviceListInfo,
          (dev) => dev.device.psn === evt.psn
        )?.device;
        const date = moment(evt.vdate).utcOffset(cam?.interval ?? 0);
        return `${indx + 1},${date.format("h:mm:ss A")},${evt.msg}`;
      })
      .join("\n")
      .value();
    downloadCSVFile(csvString, "Notification.csv");
  }, [
    cameraFilterTextMarkup,
    cameraList?.DeviceListInfo,
    date,
    dateOption,
    eventFilterTextMarkup,
    events,
    t,
  ]);

  const liveModalMarkup = useMemo(() => {
    let dir: Direction = Front;
    if (_.includes(DMS_EVENTS, event?.msg_code)) {
      if (_.includes(MODELS_2CH, camera?.model)) {
        dir = Rear;
      } else {
        dir = Interior;
      }
    }
    return (
      openLiveModal &&
      camera && (
        <Modal
          className={classes.modalRoot}
          contentClassName={classes.modalContentDiv}
          open={openLiveModal}
          onClose={() => {
            setOpenLiveModal(false);
            setCamera(undefined);
          }}
          content={
            <LiveViewPanel isEvent notification camera={camera} dir={dir} />
          }
          close
          playVOD
          heading={`${camera.dev_name} (${camera.model})`}
          titleClassName={classes.modalTitle}
          closeStyle={classes.closeStyle}
        />
      )
    );
  }, [
    camera,
    classes.closeStyle,
    classes.modalContentDiv,
    classes.modalRoot,
    classes.modalTitle,
    event,
    openLiveModal,
  ]);

  return (
    <>
      <Drawer anchor={theme.direction === "rtl" ? "left" : "right"} open={open}>
        <div className={classes.filterDiv} dir={theme.direction}>
          <div className={classes.filterTitleDiv}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {(openDayFilter || openEventFilter || openCamFilter) && (
                <IconButton
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    setOpenDayFilter(false);
                    setOpenCamFilter(false);
                    setOpenEventFilter(false);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography category="Default" variant="H6">
                {t("Notifications")}
              </Typography>
            </div>

            <div>
              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Export CSV")}
                  </Typography>
                }
                placement="bottom"
              >
                <IconButton
                  className={classes.btn}
                  onClick={() => handleDownloadReport()}
                >
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Close")}
                  </Typography>
                }
                placement="bottom"
              >
                <IconButton
                  onClick={() => {
                    onClose();
                    setOpenSearch(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div
            className={clsx(classes.listContDiv, {
              [classes.listClose]:
                openDayFilter || openEventFilter || openCamFilter,
            })}
          >
            <div className={classes.filterChipBtnDiv}>
              <div className={classes.chipDiv}>
                <ActionChip
                  className={clsx({
                    [classes.filterSetChip]: appliedDayFilter,
                  })}
                  label={dayChipText}
                  onClick={() => setOpenDayFilter(true)}
                />
              </div>
              <div className={classes.chipDiv}>
                <ActionChip
                  className={clsx({
                    [classes.filterSetChip]: appliedCamFilter,
                  })}
                  label={cameraFilterTextMarkup}
                  onClick={() => {
                    setOpenCamFilter(true);
                    setFilterPsns(psns);
                  }}
                />
              </div>
              <div className={classes.chipDiv}>
                <ActionChip
                  className={clsx({
                    [classes.filterSetChip]: appliedEventFilter,
                  })}
                  label={eventFilterTextMarkup}
                  onClick={() => setOpenEventFilter(true)}
                />
              </div>
            </div>

            <div ref={filterDivRef} className={classes.filterListDiv}>
              {notificationsMarkup}
            </div>
          </div>
          <div
            className={clsx(classes.listContDiv, {
              [classes.dayFilterClose]: !openDayFilter,
            })}
          >
            {renderDatePickerPanel}
          </div>

          <div
            className={clsx(classes.listContDiv, {
              [classes.dayFilterClose]: !openEventFilter,
            })}
          >
            {renderEventsFilterPanel}
          </div>

          <div
            className={clsx(classes.listContDiv, {
              [classes.dayFilterClose]: !openCamFilter,
            })}
          >
            {renderCameraPicker}
          </div>
        </div>
      </Drawer>
      {openVideoModal && camera && event && (
        <VideoPlayerModal
          open={openVideoModal}
          camera={camera}
          mode={0}
          event={event}
          onClose={() => {
            setOpenVideoModal(false);
            setCamera(undefined);
            setEvent(undefined);
          }}
        />
      )}
      {liveModalMarkup}
      {openImageModal && event && (
        <Modal
          className={clsx(classes.modalRoot, classes.modalImageRoot)}
          contentClassName={classes.modalImageContenDiv}
          open={openImageModal}
          closeStyle={classes.modalImageClose}
          onClose={() => {
            setOpenImageModal(false);
            setEvent(undefined);
            setImgScale(1);
          }}
          content={imageModalContentMarkup}
          close
        />
      )}
      <ExceedModal
        open={openExceedModal}
        onClose={() => setOpenExceedModal(false)}
        onClickPositive={() => {
          setOpenExceedModal(false);
        }}
      />
    </>
  );
};
