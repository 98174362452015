import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  CheckBox,
  Fab,
  GeofenceAlert,
  IconButton,
  LightColors,
  RouteHeader,
  Tab,
  Tabs,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import _ from "lodash";
import moment from "moment";
import { Index } from "react-virtualized";
import EventItem from "@thingsw/pitta-design-system/dist/components/EventItem";
import { useTranslation } from "react-i18next";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DateRangeIcon from "@material-ui/icons/DateRange";
import NavigationIcon from "@material-ui/icons/Navigation";

import { Scrollbars } from "react-custom-scrollbars";
import { AutoSizer } from "react-virtualized";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { ICameraInfo } from "../../features/Camera/slice";
import {
  clearGPSTrackingData,
  GPS,
  IDriveInfo,
  ITrackData,
  ITrackInfo,
} from "../../features/GPS/slice";
import { EmptyItems } from "../EmptyItems";
import { RootState } from "../../features/store";
import { EventAbbrToEventFull, MCODE_TO_TEXT } from "../../types";
import { IInfoWindow } from "../maps/GoogleMap";
import { doDownload, getEventLink } from "../../utils/VOD";
import { loadUserSettings, USER } from "../../features/User/slice";
import { Webviewer } from "../../contants/Breakpoints";

const useStyles = makeStyles((theme: Theme) => ({
  listPanel: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "calc(100% - 8px)",
    margin: theme.spacing(1, 0, 0),
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(2),
      height: "calc(100% - 32px)",
      ...(theme.direction === "rtl" ? { left: "unset" } : { right: "unset" }),

      boxShadow: "unset",
    },
    display: "flex",
  },
  routesPanel: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create(["width", "height"]),
    backgroundColor: LightColors.primary["0"],
    borderRadius: theme.spacing(0.5),
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
  },
  routesPanelOpen: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  routesPanelClose: {
    width: 0,
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  backArrow: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  panelBodyDiv: {
    flex: 1,
    display: "flex",
    position: "relative",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  listDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    transition: theme.transitions.create("transform"),
  },
  listDivHide: {
    transform: "translateX(-100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-320px)",
    },
  },
  dateDiv: {
    padding: theme.spacing(3, 2, 2, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  routeListDiv: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 69px - 85px)",
  },
  emptyDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  routesListDiv: {
    height: "calc(100% - 54px)",
    width: "100%",
  },
  routeDateTextDiv: {
    padding: theme.spacing(0.75, 2),
  },
  btnDiv: {
    display: "flex",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  fabMargin: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.5) }
      : { marginLeft: theme.spacing(0.5) }),
  },
  detailDivHide: {
    transform: "translateX(100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(320px)",
    },
  },
  detailHeaderDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    "&:not(:first-child)": {
      paddingTop: 0,
    },
  },
  detailHeaderIcon: {
    color: LightColors.primary["3"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  detailTabDiv: {
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
    // minHeight: 36,
    maxHeight: 36,
  },
  logListWarpper: {
    position: "relative",
    flex: 1,
    overflowY: "auto",
  },
  scrollableDiv: {
    position: "absolute",
    top: 0,
    bottom: -96,
    left: 0,
    right: 0,
    display: "flex",
    paddingTop: 96,
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      bottom: 0,
      overflow: "hidden",
    },
  },
  logDiv: {
    display: "flex",
    flex: 1,
  },
  geofenceAlertDiv: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: undefined,
    },
  },
  noScrollDiv: {
    overflow: "hidden!important",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflow: "auto!important",
    },
  },
  flex1: {
    flex: 1,
  },
}));

interface GPSTrackingListPanelProps {
  mode?: "camera" | "fleet";
  mobile?: boolean;
  date: { date?: string };
  infoWindow?: IInfoWindow;
  selectedDrives: IDriveInfo[];
  showDetail: boolean;
  onChangeInfoWindow: (infoWindw: IInfoWindow) => void;
  onChangePlayTrack: (track: ITrackData) => void;
  onChangeSelectedDrives: (tracks: IDriveInfo[]) => void;
  onSelectTrack: (data: { track?: ITrackInfo; disableZoom?: boolean }) => void;
  onOpenDate: () => void;
  onOpenCamera: () => void;
  onShowDetail: (b: boolean) => void;
  cameraAnchorRef: React.RefObject<HTMLDivElement>;
  dateAnchorRef: React.RefObject<HTMLDivElement>;
  camera?: ICameraInfo;
  cameraName: string;
  onChangeCameraName: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  dates?: moment.Moment[];
  onCloseDetail?: () => void;
}

export const GPSTrackingListPanel = ({
  mode,
  mobile,
  date,
  dates,
  infoWindow,
  showDetail,
  onChangeInfoWindow,
  onChangePlayTrack,
  onOpenDate,
  onOpenCamera,
  onShowDetail,
  cameraAnchorRef,
  dateAnchorRef,
  camera,
  cameraName,
  fromDate,
  toDate,
  selectedDrives,
  onChangeCameraName,
  onChangeSelectedDrives,
  onSelectTrack,
  onCloseDetail,
}: GPSTrackingListPanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dateListRef = useRef<List>(null);
  const detailListRef = useRef<List>(null);
  const prevInfoWindow = useRef<IInfoWindow>();
  const detailScrollRef = useRef<Scrollbars>(null);

  // const showLoadingRef = useRef<boolean>(false);

  const { tracks, geofenceAlerts, driveList } = useSelector(
    (state: RootState) => state[GPS]
  );

  const { email, loginInfo, userSettings } = useSelector(
    (state: RootState) => state[USER]
  );

  const [openPanel, setOpenPanel] = useState(true);

  const [tabValue, setTabValue] = useState(0);

  const [dateText, setDateText] = useState("");

  const [listDiv, setListDiv] = useState<HTMLDivElement | null>(null);
  const [logListDiv, setLogListDiv] = useState<HTMLDivElement | null>(null);
  const [openDrives, setOpenDrives] = useState<boolean[]>([]);
  const [updateTrack, setUpdateTrack] = useState(true);
  const [visibleRow, setVisibleRow] = useState<number>();
  const [showLoading, setShowLoading] = useState(true);
  const [canScrollList, setCanScrollList] = useState(false);
  const [tabDivRef, setTabDivRef] = useState<HTMLDivElement | null>(null);
  const [calculatedLogHeight, setCalculatedLogHeight] = useState<number[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    dispatch(loadUserSettings());
  }, [dispatch]);

  useEffect(() => {
    if (tracks.length > 0 && openDrives.length > 0) {
      const cnt = _.chain(_.zip(selectedDrives, openDrives))
        .map((dr) => {
          if (dr[1]) {
            const track = _.find(
              tracks,
              (tr) => tr.drive_no === dr[0]?.drive_no
            );
            return [1, track?.data.length ?? 0];
          } else {
            return 1;
          }
        })
        .flattenDeep()
        .sum()
        .value();
      setRowCount(cnt);
      const heights = _.range(0, cnt).map((indx) => {
        const counts = _.chain(_.zip(selectedDrives, openDrives))
          .map((dr) => {
            if (dr[1]) {
              const track = _.find(
                tracks,
                (tr) => tr.drive_no === dr[0]?.drive_no
              );
              return [1, track?.data.length ?? 0];
            } else {
              return 1;
            }
          })
          .flattenDeep()
          .value();
        let isRoute = true;
        let driveIndx = 0;
        let prevSum = 0;
        let first = false;
        let last = false;
        let data: ITrackData | undefined = undefined;
        for (let i = 1; i <= counts.length; i++) {
          const c = _.chain(counts).slice(0, i).sum().value();
          const drive = selectedDrives[driveIndx];
          if (indx < c) {
            if (!isRoute) {
              first = indx - prevSum === 0;
              last = c - indx === 1;
              const track = _.find(
                tracks,
                (tr) => tr.drive_no === drive.drive_no
              );
              data = track?.data[indx - prevSum];
            }
            break;
          } else {
            if (!isRoute) {
              driveIndx++;
              isRoute = true;
            } else if (openDrives[driveIndx]) {
              isRoute = false;
              prevSum = c;
            } else {
              driveIndx++;
            }
          }
        }
        // console.log("logRowHeight", indx.index, isRoute);
        if (isRoute) {
          return 60;
        } else {
          let height = 86;
          if (
            data &&
            (data.mode === "E" ||
              (data.mode === "P" && data.pevents && data.pevents.length > 0))
          ) {
            height = 111;
          }
          if (first || last) {
            return height + 8 + 2;
          } else {
            return height + 4;
          }
        }
      });
      setCalculatedLogHeight(heights);

      setShowLoading(false);
    }
  }, [tracks, openDrives, selectedDrives]);

  useEffect(() => {
    const element = tabDivRef;
    const handleScroll = (e: Event) => {
      if (element?.scrollTop === 96) {
        setCanScrollList(true);
      } else if (element?.scrollTop === 0) {
        setCanScrollList(false);
      }
    };
    element?.addEventListener("scroll", handleScroll);
    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [tabDivRef]);

  useEffect(() => {
    if (tracks.length === 0) {
      setShowLoading(true);
    }
  }, [tracks]);

  useEffect(() => {
    if (infoWindow) {
      if (!_.isEqual(infoWindow, prevInfoWindow.current)) {
        const indx = _.findIndex(
          selectedDrives,
          (tr) => tr.drive_no === infoWindow.drive_no
        );
        if (indx > -1) {
          setOpenDrives((o) => {
            o[indx] = true;
            return [...o];
          });
        }

        let row = 0;
        for (let i = 0; i < indx; i++) {
          row++;
          if (openDrives[i]) {
            const dr = selectedDrives[i];
            row +=
              _.find(tracks, (tr) => tr.drive_no === dr.drive_no)?.data
                .length ?? 0;
          }
        }
        row++;
        const track = _.find(
          tracks,
          (tr) => tr.drive_no === selectedDrives[indx].drive_no
        );
        if (track) {
          for (let data of track.data) {
            if (data.sid === infoWindow.sid) {
              break;
            }
            row++;
          }
        }
        const scrollPos = _.chain(_.range(0, row))
          .map((ind) => calculatedLogHeight[ind])
          .sum()
          .value();
        setVisibleRow(scrollPos);
      }
    }
    prevInfoWindow.current = infoWindow;
  }, [calculatedLogHeight, infoWindow, openDrives, selectedDrives, tracks]);

  useEffect(() => {
    if (detailScrollRef.current && visibleRow) {
      detailScrollRef.current.scrollTop(visibleRow);
    }
  }, [visibleRow]);

  useEffect(() => {
    setDateText(date.date ?? "");
  }, [date]);

  useEffect(() => {
    if (tracks.length > 0 && updateTrack) {
      setOpenDrives(_.map(tracks, (t, indx) => indx === 0));
      onSelectTrack({ track: tracks[0] });
      setUpdateTrack(false);
    }
  }, [onSelectTrack, tracks, updateTrack]);

  const list = useMemo(() => {
    if (fromDate && toDate) {
      return _.chain(driveList)
        .filter(
          (d) =>
            // d.sdate.isSameOrAfter(fromDate) && d.edate.isSameOrBefore(toDate)
            (d.sdate.isSameOrAfter(fromDate) &&
              d.sdate.isSameOrBefore(toDate)) ||
            (d.edate.isSameOrAfter(fromDate) && d.edate.isSameOrBefore(toDate))
        )
        .groupBy((d) => {
          return `${d.sdate.format("YYYYMMDD")}-${d.edate.format("YYYYMMDD")}`;
        })
        .map((value, key) => [key, ...value])
        .flattenDeep()
        .value();
    } else if (dates) {
      return _.chain(driveList)
        .filter((d) => {
          const filtered = _.filter(dates, (dd) => {
            const sdate = moment(dd).startOf("D");
            const edate = moment(dd).endOf("D");
            return (
              (d.sdate.isSameOrAfter(sdate) && d.sdate.isSameOrBefore(edate)) ||
              (d.edate.isSameOrAfter(sdate) && d.edate.isSameOrBefore(edate))
            );
          });
          // console.log("filtered", filtered);
          return filtered.length > 0;
        })
        .groupBy((d) => {
          return `${d.sdate.format("YYYYMMDD")}-${d.edate.format("YYYYMMDD")}`;
        })
        .map((value, key) => [key, ...value])
        .flattenDeep()
        .value();
    }
    return [];
  }, [dates, driveList, fromDate, toDate]);

  const filteredDriveList = useMemo(() => {
    return _.filter(list, (l) => typeof l !== "string") as IDriveInfo[];
  }, [list]);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const indx = props.index;
      if (typeof list[indx] === "string") {
        const sdates = _.split(list[indx] as string, "-");
        const sdate = moment(sdates[0], "YYYYMMDD");
        const edate = moment(sdates[1], "YYYYMMDD");
        let dstring = "";
        if (sdate.isSame(edate)) {
          dstring = sdate.format("MMM DD, YYYY");
        } else {
          dstring = `${sdate.format("MMM DD, YYYY")} – ${edate.format(
            "MMM DD, YYYY"
          )}`;
        }
        return (
          <div
            key={`routes-list-date-${indx}`}
            style={props.style}
            className={classes.routeDateTextDiv}
            dir={theme.direction}
          >
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={LightColors.primary["2"]}
            >
              {dstring}
            </Typography>
          </div>
        );
      } else {
        const item = list[indx] as IDriveInfo;

        return (
          <div
            key={`routes-list-drive-${item.drive_no}`}
            style={props.style}
            dir={theme.direction}
          >
            <FormControlLabel
              style={{ margin: 0, padding: "3px 10px" }}
              control={<CheckBox color="primary" />}
              checked={_.includes(selectedDrives, item)}
              onChange={(_e, checked) => {
                if (checked) {
                  onChangeSelectedDrives(
                    _.chain([...selectedDrives, item])
                      .sortBy(["edate", "sdate"])
                      .reverse()
                      .value()
                  );
                } else {
                  onChangeSelectedDrives(
                    _.chain(selectedDrives)
                      .filter((track) => track.drive_no !== item.drive_no)
                      .sortBy(["edate", "sdate"])
                      .reverse()
                      .value()
                  );
                }
              }}
              label={
                <Typography category="Default" variant="Body">
                  {`${item.sdate.format("HH:mm:ss")} – ${item.edate.format(
                    "HH:mm:ss"
                  )}`}
                </Typography>
              }
            />
          </div>
        );
      }
    },
    [
      list,
      classes.routeDateTextDiv,
      theme.direction,
      selectedDrives,
      onChangeSelectedDrives,
    ]
  );

  const rowHeight = useCallback(
    (indx: Index) => {
      return typeof list[indx.index] === "string" ? 33 : 36;
    },
    [list]
  );

  const logRowHeight = useCallback(
    (indx: Index) => {
      return calculatedLogHeight[indx.index];
    },
    [calculatedLogHeight]
  );

  const logRowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const indx = props.index;
      const counts = _.chain(_.zip(selectedDrives, openDrives))
        .map((dr) => {
          if (dr[1]) {
            const track = _.find(
              tracks,
              (tr) => tr.drive_no === dr[0]?.drive_no
            );
            return [1, track?.data.length ?? 0];
          } else {
            return 1;
          }
        })
        .flattenDeep()
        .value();
      let route: ITrackInfo | undefined = undefined;
      let data: ITrackData | undefined = undefined;
      let isRoute = true;
      let driveIndx = 0;
      let prevSum = 0;
      let prevPIndx = -1;
      let first = false;
      let last = false;
      let prevPEvent: ITrackData | undefined = undefined;
      let drive: IDriveInfo | undefined = undefined;
      // console.log("logRowRenderer", counts, tracks, openTracks);
      for (let i = 1; i <= counts.length; i++) {
        const c = _.chain(counts).slice(0, i).sum().value();
        drive = selectedDrives[driveIndx];
        // console.log("\t", indx, c, trackIndx, "/", i);
        if (indx < c) {
          // eslint-disable-next-line no-loop-func
          route = _.find(tracks, (tr) => tr.drive_no === drive?.drive_no);
          first = indx - prevSum === 0;
          last = c - indx === 1;
          prevPIndx = _.chain(route?.data)
            .slice(0, indx - prevSum)
            .findLastIndex((d) => (d.pevents?.length ?? 0) > 0)
            .value();
          prevPEvent = _.chain(route?.data)
            .slice(0, indx - prevSum)
            .findLast((d) => (d.pevents?.length ?? 0) > 0)
            .value();
          data = route?.data[indx - prevSum];
          break;
        } else {
          if (!isRoute) {
            driveIndx++;
            isRoute = true;
          } else if (openDrives[driveIndx]) {
            isRoute = false;
            prevSum = c;
          } else {
            driveIndx++;
          }
        }
      }
      // console.log("logRowRenderer", `A${driveIndx}`, isRoute, drive, route);

      if (isRoute && drive) {
        // const route = selectedTrack[indx];
        let dateText = "";
        // console.log("logRowRenderer", indx, route);
        if (drive.sdate.isSame(drive.edate, "day")) {
          dateText = `${drive.sdate.format(
            "MMM DD YYYY, HH:mm:ss"
          )} - ${drive.edate.format("HH:mm:ss")}`;
        } else {
          dateText = `${drive.sdate.format(
            "YYYY-MM-DD HH:mm:ss"
          )} - ${drive.edate.format("YYYY-MM-DD HH:mm:ss")}`;
        }

        return (
          <div
            key={props.key}
            style={{
              backgroundColor: LightColors.primary["0"],
              ...(!openDrives[driveIndx] && {
                borderBottom: `1px solid ${LightColors.primary["6"]}`,
              }),
              ...props.style,
            }}
          >
            <RouteHeader
              heading={`A${driveIndx}`}
              content={dateText}
              open={openDrives[driveIndx]}
              onClick={() => {
                openDrives[driveIndx] = !openDrives[driveIndx];
                if (_.compact(openDrives).length === 0) {
                  onSelectTrack({ track: undefined });
                } else if (openDrives[driveIndx]) {
                  route && onSelectTrack({ track: route });
                }
                setOpenDrives([...openDrives]);
                detailListRef.current?.recomputeRowHeights();
                // detailListRef.current?.forceUpdateGrid();
                forceUpdate();
                // console.log("RouteHeader", "clicked", openTracks);
              }}
            />
          </div>
        );
      }
      if (!isRoute && data) {
        let parkingEvent = false;
        let eventString = "";
        if (data.mode === "E") {
          if (
            prevPEvent?.mode === "P" &&
            prevPEvent.loc[0] === data.loc[0] &&
            prevPEvent.loc[1] === data.loc[1]
          ) {
            parkingEvent = true;
            eventString = "Parking impact";
          } else {
            eventString = MCODE_TO_TEXT[data.mtype];
          }
        } else if (
          data.mode === "P" &&
          data.pevents &&
          data.pevents.length > 0
        ) {
          eventString = `${t("Parking events")} · ${data.pevents.length}`;
        }
        const isAI = data.mtype >= 6 && data.mtype <= 12;
        return (
          <div
            key={props.key}
            style={{
              backgroundColor: "rgba(233, 233, 234, 0.35)",
              paddingLeft: parkingEvent ? 26 : 16,
              paddingRight: 16,
              ...props.style,
            }}
          >
            <div
              style={{
                paddingLeft: parkingEvent ? 4 : 0,
                ...(first && { paddingBottom: 2, paddingTop: 8 }),
                ...(last && {
                  paddingBottom: 8,
                  paddingTop: 2,
                  borderBottom: `1px solid ${LightColors.primary["6"]}`,
                }),
                ...(!first && !last && { paddingBottom: 2, paddingTop: 2 }),
                borderLeft: parkingEvent
                  ? `2px solid ${LightColors.primary["6"]}`
                  : undefined,
              }}
            >
              <EventItem
                mobile
                t={t}
                heading={data.vdate.format("MMM DD YYYY, HH:mm:ss")}
                speed={
                  userSettings?.velocityUnit === "0"
                    ? `${Math.floor(data.avg_speed * 1.852)} km/h`
                    : `${Math.floor(data.avg_speed * 1.150779)} MPH`
                }
                fileType={EventAbbrToEventFull[data.mode]}
                content={t(eventString)}
                content2={`${data.loc[0]}, ${data.loc[1]}`}
                contentAlt=""
                isAI={isAI}
                play={!!data.rid_front || !!data.rid_rear || !!data.rid_3}
                download={!!data.rid_front || !!data.rid_rear || !!data.rid_3}
                hoverable
                hover={data.sid === infoWindow?.selectedSid}
                onClick={() => {
                  const d = data as ITrackData;
                  route && onSelectTrack({ track: route, disableZoom: true });
                  if (parkingEvent && prevPEvent) {
                    onChangeInfoWindow({
                      drive_no: tracks[driveIndx].drive_no,
                      data: prevPEvent,
                      index: prevPIndx,
                      sid: prevPEvent.sid,
                      selectedSid: d.sid,
                    });
                  } else {
                    onChangeInfoWindow({
                      drive_no: tracks[driveIndx].drive_no,
                      data: d,
                      index: indx - prevSum,
                      sid: d.sid,
                      selectedSid: d.sid,
                    });
                  }
                }}
                onPlay={() => {
                  data && onChangePlayTrack(data);
                }}
                onDownload={async () => {
                  console.log("onDownload", camera, loginInfo, email, data);
                  if (camera && loginInfo && email) {
                    const { user_token: userToken } = loginInfo;
                    const { psn } = camera;
                    let resp: any | undefined = undefined;
                    if (data?.rid_front) {
                      resp = await getEventLink(
                        email,
                        userToken,
                        psn,
                        data.rid_front
                      );
                    } else if (data?.rid_rear) {
                      resp = await getEventLink(
                        email,
                        userToken,
                        psn,
                        data.rid_rear
                      );
                    } else if (data?.rid_3) {
                      resp = await getEventLink(
                        email,
                        userToken,
                        psn,
                        data.rid_3
                      );
                    }

                    if (resp) {
                      doDownload(resp.data["fileInfo"]["url"]);
                    }
                  }
                }}
              />
            </div>
          </div>
        );
      }
    },
    [
      selectedDrives,
      openDrives,
      tracks,
      forceUpdate,
      onSelectTrack,
      userSettings?.velocityUnit,
      t,
      infoWindow?.selectedSid,
      onChangeInfoWindow,
      onChangePlayTrack,
      camera,
      loginInfo,
      email,
    ]
  );

  const geofenceAlertRenderer: ListRowRenderer = useCallback(
    (props) => {
      const indx = props.index;
      let cnt = 0;
      let aIndx = 0;

      for (let alerts of geofenceAlerts) {
        const count = cnt + alerts.data.length;
        if (indx < count) {
          break;
        }
        cnt = count;
        aIndx++;
      }

      const data = geofenceAlerts[aIndx].data[indx - cnt];

      return (
        <div
          className={classes.geofenceAlertDiv}
          key={props.key}
          style={{ ...props.style }}
        >
          <GeofenceAlert
            date={data.time.format("MMM DD YYYY, HH:mm:ss")}
            fenceName={data.fenceName}
            sendStatus={data.sendStatus}
            latLng={`${data.latLng.lat}, ${data.latLng.lng}`}
          />
        </div>
      );
    },
    [classes.geofenceAlertDiv, geofenceAlerts]
  );

  const handleScroll = useCallback(
    (ref: React.RefObject<List>) => (e: any) => {
      if (ref.current) {
        const { scrollTop, scrollLeft } = e.target;
        const { Grid } = ref.current;
        Grid?.handleScrollEvent({ scrollTop, scrollLeft });
      }
    },
    []
  );

  const renderListHelper = useCallback(
    (
      width: number,
      height: number,
      elem: React.ReactElement,
      ref: React.RefObject<List>,
      scrollRef?: React.RefObject<Scrollbars>
    ) => {
      return mobile ? (
        elem
      ) : (
        <Scrollbars
          ref={scrollRef}
          onScroll={handleScroll(ref)}
          style={{ height, width }}
          autoHide
          renderView={(props) => (
            <div
              {...props}
              style={{
                marginLeft: theme.direction === "rtl" ? -17 : 0,
                marginRight: theme.direction === "rtl" ? 0 : -17,
                overflowY: "auto",
              }}
            />
          )}
        >
          {elem}
        </Scrollbars>
      );
    },
    [handleScroll, mobile, theme.direction]
  );

  const detailPaneMarkup = useMemo(() => {
    const geofenceLength = _.chain(geofenceAlerts)
      .map((g) => g.data)
      .flattenDeep()
      .value().length;
    const sdrive = _.chain(selectedDrives)
      .sortBy((s) => s.sdate.unix())
      .first()
      .value();
    const edrive = _.chain(selectedDrives)
      .sortBy((s) => s.edate.unix())
      .last()
      .value();

    let dateText = "";
    // console.log("logRowRenderer", indx, route);
    if (sdrive && edrive) {
      dateText = `${sdrive.sdate.format(
        "MMM DD, YYYY"
      )} - ${edrive.edate.format("MMM DD, YYYY")}`;
    }
    return (
      <div
        className={clsx(classes.listDiv, {
          [classes.detailDivHide]: !showDetail,
        })}
      >
        {showDetail && (
          <div
            className={classes.logListWarpper}
            ref={(ref) => setTabDivRef(ref)}
          >
            <div className={classes.detailHeaderDiv}>
              <DateRangeIcon className={classes.detailHeaderIcon} />
              <Typography category="Default" variant="Body">
                {dateText}
              </Typography>
            </div>
            <div className={classes.detailHeaderDiv}>
              <NavigationIcon className={classes.detailHeaderIcon} />
              <Typography category="Default" variant="Body">
                {t("Routes selected_", {
                  n: selectedDrives.length,
                  total: filteredDriveList.length,
                })}
              </Typography>
            </div>
            <div className={classes.scrollableDiv}>
              <Tabs value={tabValue} className={classes.detailTabDiv} GPSTabs>
                <Tab
                  className={classes.flex1}
                  label={
                    <Typography
                      category="Default"
                      variant="BodyBold"
                      style={{ width: 128 }}
                    >
                      {t("Tracking logs")}
                    </Typography>
                  }
                  onClick={() => setTabValue(0)}
                />
                <Tab
                  className={classes.flex1}
                  label={
                    <Typography
                      category="Default"
                      variant="BodyBold"
                      style={{ width: 128 }}
                    >
                      {t("Geofence alerts")}
                    </Typography>
                  }
                  onClick={() => setTabValue(1)}
                />
              </Tabs>
              <div className={classes.logDiv} ref={(ref) => setLogListDiv(ref)}>
                {tabValue === 0 &&
                  logListDiv &&
                  tracks.length > 0 &&
                  !showLoading && (
                    <AutoSizer>
                      {({ height, width }) =>
                        renderListHelper(
                          width,
                          height,
                          <List
                            className={clsx({
                              [classes.noScrollDiv]: mobile && !canScrollList,
                            })}
                            width={width}
                            height={height}
                            rowCount={rowCount}
                            rowRenderer={logRowRenderer}
                            rowHeight={logRowHeight}
                            // overscanRowCount={30}
                            // scrollToIndex={visibleRow}
                            ref={detailListRef}
                            onScroll={(p: any) => setVisibleRow(undefined)}
                            style={
                              mobile
                                ? {}
                                : {
                                    overflowY: "visible",
                                    overflowX: "visible",
                                  }
                            }
                          />,
                          detailListRef,
                          detailScrollRef
                        )
                      }
                    </AutoSizer>
                  )}
                {tabValue === 0 && showLoading && (
                  <div className={classes.emptyDiv}>
                    <CircularProgress thickness={4} />
                  </div>
                )}
                {tabValue === 1 &&
                  logListDiv &&
                  (geofenceLength > 0 ? (
                    <AutoSizer>
                      {({ height, width }) =>
                        renderListHelper(
                          width,
                          height,
                          <List
                            className={clsx({
                              [classes.noScrollDiv]: mobile && !canScrollList,
                            })}
                            width={width}
                            height={height}
                            rowCount={geofenceLength}
                            rowRenderer={geofenceAlertRenderer}
                            rowHeight={137}
                            // overscanRowCount={10}
                            ref={detailListRef}
                            style={
                              mobile
                                ? {}
                                : {
                                    overflowY: "visible",
                                    overflowX: "visible",
                                  }
                            }
                          />,
                          detailListRef
                        )
                      }
                    </AutoSizer>
                  ) : (
                    <div style={{ width: "100%", display: "flex" }}>
                      <EmptyItems variant="small" mode="geofence-alert" />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    geofenceAlerts,
    selectedDrives,
    classes.listDiv,
    classes.detailDivHide,
    classes.logListWarpper,
    classes.detailHeaderDiv,
    classes.detailHeaderIcon,
    classes.scrollableDiv,
    classes.detailTabDiv,
    classes.flex1,
    classes.logDiv,
    classes.emptyDiv,
    classes.noScrollDiv,
    showDetail,
    t,
    filteredDriveList.length,
    tabValue,
    logListDiv,
    tracks.length,
    showLoading,
    renderListHelper,
    mobile,
    canScrollList,
    rowCount,
    logRowRenderer,
    logRowHeight,
    geofenceAlertRenderer,
  ]);

  const renderList = useMemo(() => {
    return (
      listDiv && (
        <AutoSizer>
          {({ height, width }) =>
            renderListHelper(
              width,
              height,
              <List
                width={listDiv.clientWidth}
                height={listDiv.clientHeight}
                rowCount={list.length}
                rowRenderer={rowRenderer}
                rowHeight={rowHeight}
                overscanRowCount={10}
                ref={dateListRef}
                style={
                  mobile
                    ? {}
                    : {
                        overflowY: "visible",
                        overflowX: "visible",
                      }
                }
              />,
              dateListRef
            )
          }
        </AutoSizer>
      )
    );
  }, [listDiv, renderListHelper, list.length, rowRenderer, rowHeight, mobile]);

  return (
    <div className={classes.listPanel}>
      <div
        className={clsx(classes.routesPanel, {
          [classes.routesPanelOpen]: openPanel,
          [classes.routesPanelClose]: !openPanel,
        })}
      >
        <div className={classes.titleDiv}>
          {showDetail && (
            <IconButton
              className={classes.backArrow}
              onClick={() => {
                setOpenDrives([]);
                onShowDetail(false);
                setUpdateTrack(false);
                onCloseDetail?.();
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          <Typography category="Default" variant="H6">
            {t("Routes")}
          </Typography>
        </div>
        <div className={classes.panelBodyDiv}>
          <div
            className={clsx(classes.listDiv, {
              [classes.listDivHide]: showDetail,
            })}
          >
            {mode === "fleet" && (
              <div className={classes.dateDiv}>
                <Input
                  readOnly={mobile}
                  variant="outlined"
                  placeholder={t("Camera")}
                  label={t("Camera")}
                  rootRef={cameraAnchorRef}
                  value={cameraName}
                  onChange={(e) => {
                    setDateText("");
                    dispatch(clearGPSTrackingData());
                    onChangeCameraName(e);
                  }}
                  onClick={() => {
                    setDateText("");
                    dispatch(clearGPSTrackingData());
                    // onChangeCameraName(e);
                    onOpenCamera();
                  }}
                />
              </div>
            )}
            <div className={classes.dateDiv}>
              <Input
                inputmode="none"
                readOnly
                variant="outlined"
                placeholder={t("Dates")}
                label={t("Dates")}
                value={dateText}
                onClick={() => onOpenDate()}
                rootRef={dateAnchorRef}
              />
            </div>
            <div className={classes.routeListDiv}>
              {list.length === 0 && (
                <div className={classes.emptyDiv}>
                  <EmptyItems variant="small" mode="routes" />
                </div>
              )}
              {list.length > 0 && (
                <>
                  <div>
                    <FormControlLabel
                      style={{ margin: 0, padding: "16px 10px 8px 10px" }}
                      control={
                        <CheckBox
                          indeterminate={
                            selectedDrives.length > 0 &&
                            selectedDrives.length !== filteredDriveList.length
                          }
                          checked={
                            selectedDrives.length === filteredDriveList.length
                          }
                          onChange={(e, checked) => {
                            e.stopPropagation();
                            // e.preventDefault();
                            if (checked) {
                              onChangeSelectedDrives(filteredDriveList);
                            } else {
                              onChangeSelectedDrives([]);
                            }
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography category="Default" variant="BodyBold">
                          {t("Routes")}
                        </Typography>
                      }
                    />
                  </div>
                  <div
                    className={classes.routesListDiv}
                    ref={(ref) => setListDiv(ref)}
                  >
                    {renderList}
                  </div>
                </>
              )}
            </div>
            <div className={classes.btnDiv}>
              <div>
                {selectedDrives.length}/{filteredDriveList.length}
              </div>
              <Button
                variant="contained"
                color="primary"
                disabled={selectedDrives.length === 0}
                onClick={() => {
                  onShowDetail(true);
                  setShowLoading(true);
                  setUpdateTrack(true);
                }}
              >
                {t("Show")}
              </Button>
            </div>
          </div>
          {detailPaneMarkup}
        </div>
      </div>

      {!mobile && (
        <Fab
          size="small"
          variant="rounded"
          className={clsx({ [classes.fabMargin]: openPanel })}
          onClick={() => setOpenPanel((o) => !o)}
        >
          {(theme.direction === "rtl") !== openPanel ? (
            <ArrowLeftIcon />
          ) : (
            <ArrowRightIcon />
          )}
        </Fab>
      )}
    </div>
  );
};
