import {
  DashcamPublic,
  Fab,
  LightColors,
  Tooltip,
} from "@thingsw/pitta-design-system";
import LayersIcon from "@material-ui/icons/Layers";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import React, { forwardRef } from "react";
import { Webviewer } from "../../contants/Breakpoints";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > button": {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(1) }
        : { marginRight: theme.spacing(1) }),
      "&:last-child": {
        ...(theme.direction === "rtl" ? { marginLeft: 0 } : { marginRight: 0 }),
      },
    },
  },
  iconColor: {
    "&:focus": {
      color: LightColors.primary["1"],
    },
    "&:hover": {
      color: LightColors.primary["7"],
    },
  },
  active: {
    color: LightColors.primary["7"],
  },
  noHover: {
    "&:hover": {
      fill: LightColors.primary["1"],
      color: LightColors.primary["1"],
    },
  },
  noFocus: {
    "&:focus": {
      fill: LightColors.primary["1"],
      color: LightColors.primary["1"],
    },
  },
}));

interface TopRightControlProps {
  onFullscreen?: () => void;
  onLayers: () => void;
  onDashcamPublic?: () => void;
  fullscreen?: boolean;
  activePublic?: boolean;
}

export const TopRightControl = forwardRef<
  HTMLButtonElement,
  TopRightControlProps
>(
  (
    { onFullscreen, onLayers, onDashcamPublic, fullscreen, activePublic },
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme() as Theme;
    const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

    return (
      <div className={classes.root}>
        {onDashcamPublic && (
          <Tooltip
            disableTouchListener={mobile}
            title={t("Public cameras") ?? "Public cameras"}
          >
            <Fab
              onClick={onDashcamPublic}
              size="small"
              variant="rounded"
              className={clsx(classes.noFocus, { [classes.noHover]: mobile })}
            >
              <DashcamPublic
                className={clsx({ [classes.active]: activePublic })}
              />
            </Fab>
          </Tooltip>
        )}
        <Tooltip
          disableTouchListener={mobile}
          title={t("Map type") ?? "Map type"}
        >
          <Fab size="small" variant="rounded" ref={ref} onClick={onLayers}>
            <LayersIcon />
          </Fab>
        </Tooltip>
        {onFullscreen && (
          <Tooltip
            disableTouchListener={mobile}
            title={
              fullscreen ? `${t("Exit fullscreen")}` : `${t("Fullscreen")}`
            }
          >
            <Fab
              size="small"
              variant="rounded"
              onClick={onFullscreen}
              className={classes.iconColor}
            >
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </Fab>
          </Tooltip>
        )}
      </div>
    );
  }
);
