import React from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Webviewer } from "../../contants/Breakpoints";

interface SaveSettingModalProps {
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 288,
    "&>.MuiDialogActions-root": {
      justifyContent: "center",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        justifyContent: "flex-end",
      },
    },
    "&>.buttonPadding": {
      padding: "0px 23px 24px",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 560,
    },
  },
}));

export const FirmwareFormatModal = ({
  open,
  loading,
  onClose,
  onClickNegative,
  onClickPositive,
}: SaveSettingModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  return (
    <Modal
      btnCenterMobile={mobile}
      className={classes.root}
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={onClickPositive}
      heading={t("Save settings")}
      close={false}
      content={
        <Typography
          variant="Body"
          category="Default"
          dangerouslySetInnerHTML={{ __html: t("Back up all_") }}
        ></Typography>
      }
      LButton={t("Cancel")}
      RButton={t("Save settings")}
      // RButtonDisabled={disabledChange}
      Secondary={false}
      loading={loading}
    />
  );
};
