import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  confirm,
  create,
  ILogin,
  ISignup,
  IUserConfirm,
  IUserLoginInfo,
  IUserProfile,
  loadUserProfile,
  logout,
  saveEmail,
  successLoadUserProfile,
  successLogin,
  USER,
  userLogin,
  ISendMail,
  sendResetMail,
  successSignup,
  IUploadImage,
  uploadImage,
  ILoadProfileImage,
  loadProfileImage,
  IChangeName,
  changeName,
  successChangeName,
  IChangePasswd,
  changePasswd,
  successChangePasswd,
  deleteAccountMail,
  failChangePasswd,
  loadUsageInfo,
  successLoadUsageInfo,
  IUserUsageInfo,
  IUserSettings,
  successLoadUserSettings,
  loadUserSettings,
  updateUserSettings,
  AuthType,
  linkSocialAccount,
  clearLoading,
  clearUser,
  setError as setUserError,
  successActivate,
  loadUserPermissions,
  successLoadUserPermissions,
  // saveOauthToken,
  // loadSubscription,
  // userSubscription,
  needToConfirm,
  IUserSettingsOrig,
  ISetNotification,
  setUserNotification,
  loadUserNotification,
  ILoadNotification,
  successLoadUserNotification,
  updateLiveviewUsage,
} from "./slice";
import * as Api from "../../apis";
import { RESULT_CODE } from "../../types";
import { RootState } from "../store";
import { APP_VERSION, MOBILE_NAME, TOKEN_TYPE } from "../../contants/Login";

import firebase from "firebase/app";
import "firebase/auth";
import { clearError, setError } from "../Error/slice";
import { resetCamera } from "../Camera/slice";
import { clearPayment } from "../Payment/slice";
import { clearVOD } from "../VOD/slice";
import { clearSocial } from "../Social/slice";
import { clearReport } from "../Report/slice";
import { clearGroup } from "../Group/slice";
import { clearGPS } from "../GPS/slice";
import { clearGeofence } from "../Geofence/slice";
import { resetEvent } from "../Event/slice";
import { IPermissions, resetMember } from "../Member/slice";
import { resetPushEvent } from "../PushEvent/slice";
import { closeToast, openToast } from "../Toast/slice";
import { MobileLang } from "../../contants/Languages";
import moment from "moment";
import { clearPermissions } from "../Permission/slice";

function* handleLogout({ payload }: PayloadAction<boolean | undefined>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(Api.logout, email, loginInfo.user_token);
    localStorage.setItem("PITTA_SESSION_CLEAR", Date.now().toString());
    localStorage.removeItem("PITTA_SESSION_CLEAR");
    yield firebase.auth().signOut();
    if (payload !== false) {
      yield put(clearPermissions());
      yield put(resetCamera());
      yield put(clearError());
      yield put(resetEvent());
      yield put(clearGPS());
      yield put(clearGeofence());
      yield put(clearGroup());
      //reset member
      yield put(resetMember());
      yield put(clearPayment());
      //pushevent
      yield put(resetPushEvent());
      yield put(clearReport());
      yield put(clearSocial());
      yield put(clearUser());
      yield put(clearVOD());
      yield put(closeToast());
      yield put(push("/"));
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleUserLogin({
  payload,
}: PayloadAction<
  ILogin & { goToCamera?: boolean; remember?: boolean; returnTo?: string }
>) {
  try {
    const { goToCamera, remember, returnTo, ...restPayload } = payload;
    const { email } = restPayload;
    const res = yield call(Api.userLogin, restPayload);
    const { resultcode, message, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserLoginInfo;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(saveEmail(email));
      yield put(successLogin(response));

      if (remember) {
        localStorage.setItem("pitta:email", email);
      }

      if (returnTo) {
        return (window.location.href = `${returnTo}?email=${email}&user_token=${response.user_token}&token_type=web`);
      }

      if (goToCamera !== false) {
        yield put(push("/cameras"));
      }
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(setError("Incorrect email or_"));
      yield put(clearLoading());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(setError(message));
      yield put(clearLoading());
    } else if (resultcode === "BC_ERR_NEED_TO_CONFIRM") {
      yield put(push("/signup"));
      yield put(needToConfirm());
    }
  } catch (err) {
    yield put(clearLoading());
    yield put(push("/Err500"));
  }
}

function* handleLoadProfileImg({
  payload,
}: PayloadAction<"signup" | undefined>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getProfile, email, loginInfo.user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserProfile;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadUserProfile(response));
      if (payload === "signup") {
        if (response.userType) {
          yield put(push("/billing"));
        } else {
          yield put(push("/cameras"));
        }
      }
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* handleSendPasswdResetMail({ payload }: PayloadAction<ISendMail>) {
  try {
    const email = payload.email;
    const res = yield call(Api.getMailAddress, email);
    const { resultcode, message } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(clearLoading());
      yield put(push("/reset", { sendMail: true }));
    } else if (resultcode === "BC_ERR_INTERNAL_ERROR") {
      yield put(push("/Err500"));
    } else if (resultcode === "BC_ERR_INVALID_DATA") {
      yield put(setError("There isn’t an_"));
      yield put(clearLoading());
    } else if (resultcode === "BC_ERR_NOTBLACKVUELINKAGE") {
      yield put(setError("Please return to_"));
      yield put(clearLoading());
    } else {
      yield put(clearLoading());
      yield put(setError(message));
    }
  } catch (err) {
    console.log(`handleSendPasswdResetMail: ${err}`);
  }
}

function* handleCreate({ payload }: PayloadAction<ISignup>) {
  try {
    const { mobile_uuid } = yield select((state: RootState) => state[USER]);
    const res = yield call(Api.createUser, payload);
    const prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
    const lang = prefLang || navigator.language.toLowerCase().substring(0, 2);

    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserLoginInfo;
    };
    if (resultcode === "BC_ERR_OK" || resultcode === "BC_ERR_NEED_TO_CONFIRM") {
      if (payload.inviteToken) {
        yield put(successActivate());
      } else {
        yield put(successSignup());
        if (payload.signupType === "apple" || payload.signupType === "google") {
          let goToCamera = true;
          if (payload.signupMode === "free") {
          } else if (payload.signupMode === "fleet") {
            goToCamera = false;
          }
          yield put(
            userLogin({
              email: payload.email,
              oauthToken: payload.oauthToken,
              mobile_uuid,
              mobile_name: MOBILE_NAME,
              mobile_os_type: "macos",
              mobileLang: MobileLang[lang],
              app_ver: APP_VERSION,
              time_interval: moment().utcOffset(),
              tokenType: TOKEN_TYPE,
              loginType: payload.signupType,
              goToCamera,
            })
          );
        }
      }
    } else if (resultcode === "BC_ERR_DUPLICATED") {
      yield put(setError("This e-mail address_"));
      yield put(clearLoading());
    } else if (resultcode === "BC_ERR_UNAVAILABLE") {
      yield put(setError("You have already_"));
      yield put(clearLoading());
    } else if (resultcode === "BC_ERR_INTERNAL_ERROR") {
      yield put(setError("Internal server error_"));
      yield put(clearLoading());
    }
  } catch (err) {
    // console.log("error", err.response.status);
    // const errorCode = err.response.status;
  } finally {
    yield put(clearLoading());
  }
}

function* handleConfirm({ payload }: PayloadAction<IUserConfirm>) {
  try {
    const res = yield call(Api.userConfirm, payload);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      response: IUserLoginInfo;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLogin(response));
      if (payload.signupMode === "fleet") {
        yield put(push("/billing", { mode: "fleet" }));
      } else if (payload.signupMode === "free") {
        yield put(push("/cameras"));
      } else {
        yield put(loadUserProfile("signup"));
      }
    } else if (resultcode === "BC_ERR_INVALID_DATA") {
      yield put(setUserError("Code you entered_"));
      yield put(clearLoading());
    }
  } catch (err) {
    // console.log("error", err.response.status);
    // const errorCode = err.response.status;
  }
}

function* handleUploadImage({ payload }: PayloadAction<IUploadImage>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    //console.log(email, loginInfo.user_token);
    //console.log(payload.file, payload.fileName);
    const res = yield call(
      Api.uploadImage,
      email,
      loginInfo.user_token,
      "web",
      payload
    );

    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ILoadProfileImage;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadProfileImage(response));
      //console.log(response.profilePath);
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else {
      yield put(setError("An error occurred_again"));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleChangeName({ payload }: PayloadAction<IChangeName>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.changeName, email, user_token, payload);
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successChangeName(payload));
      yield put(openToast({ message: "Change saved" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else {
      yield put(setError("An error occurred_again"));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleChangePasswd({ payload }: PayloadAction<IChangePasswd>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const res = yield call(
      Api.changePasswd,
      email,
      payload.old_passwd,
      payload.new_passwd
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { user_token: string };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successChangePasswd(response.user_token));
      yield put(openToast({ message: "Change saved" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(failChangePasswd());
    } else {
      yield put(setError("An error occurred_again"));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleDeleteAccountMail() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const res = yield call(Api.doDeleteUser, email, user_token);
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(logout(false));
      yield put(push("/delete-check"));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_NEED_TO_CONFIRM") {
      yield put(logout(false));
      yield put(push("/delete-check"));
    } else {
      yield put(setError("An error occurred_again"));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleUserUsage() {
  const email = yield select((state: RootState) => state[USER].email);
  const loginInfo = (yield select(
    (state: RootState) => state[USER].loginInfo
  )) as IUserLoginInfo;
  try {
    const res = yield call(Api.userUsage, email, loginInfo.user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserUsageInfo;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadUsageInfo(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_UNAVAILABLE") {
    } else {
      yield put(setError("An error occurred_again"));
      yield put(clearLoading());
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* handleLoadUserSettings() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getUserSetting, email, user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      response: IUserSettingsOrig;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadUserSettings({
          mapType: response.mapType.toString(),
          velocityUnit: response.velocityUnit,
        })
      );
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else {
      yield put(setError("An error occurred_again"));
      yield put(clearLoading());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateUserSettings({ payload }: PayloadAction<IUserSettings>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.updateUserSetting,
      email,
      user_token,
      payload.mapType,
      payload.velocityUnit
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Change saved" }));
      yield put(successLoadUserSettings(payload));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INTERNAL_ERROR") {
      yield put(push("/Err500"));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleSetUserNotification({
  payload,
}: PayloadAction<ISetNotification>) {
  try {
    const { email, mobile_uuid } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.setUserNotification,
      email,
      user_token,
      mobile_uuid,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadUserNotification());
      yield put(openToast({ message: "Change saved" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INTERNAL_ERROR") {
      yield put(push("/Err500"));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadUserNotification() {
  try {
    const { email, mobile_uuid } = yield select(
      (state: RootState) => state[USER]
    );
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getUserNotification,
      email,
      user_token,
      mobile_uuid
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      response: ILoadNotification;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadUserNotification(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLinkSocialAccount({
  payload: { email, passwd, oauthToken, signupType },
}: PayloadAction<{
  email: string;
  passwd: string;
  oauthToken: string;
  signupType: AuthType;
}>) {
  try {
    const { mobile_uuid } = yield select((state: RootState) => state[USER]);
    const resp = yield call(
      Api.linkSocialAccount,
      email,
      passwd,
      oauthToken,
      signupType
    );
    const prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
    const lang = prefLang || navigator.language.toLowerCase().substring(0, 2);

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      response: ILogin;
    };

    if (resultcode === "BC_ERR_OK" || resultcode === "BC_ERR_DUPLICATED") {
      yield put(
        userLogin({
          email,
          oauthToken,
          mobile_uuid,
          mobile_name: MOBILE_NAME,
          mobile_os_type: "macos",
          mobileLang: MobileLang[lang],
          app_ver: APP_VERSION,
          time_interval: moment().utcOffset(),
          tokenType: TOKEN_TYPE,
          loginType: signupType,
        })
      );
    } else if (resultcode === "BC_ERR_INVALID_DATA") {
      yield put(clearLoading());
      yield put(setError("Password is incorrect"));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadUserPermissions() {
  try {
    const { email, loginInfo } = yield select(
      (state: RootState) => state[USER]
    );
    const resp = yield call(
      Api.loadUserPermissions,
      email,
      loginInfo.user_token
    );

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      response: { permissions: IPermissions };
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadUserPermissions(response.permissions));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateLiveviewUsage({
  payload,
}: PayloadAction<{ psn: string; usage: number }>) {
  try {
    const email = (yield select(
      (state: RootState) => state[USER].email
    )) as string;
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    yield call(
      Api.updateLiveviewUsage,
      email,
      loginInfo.user_token,
      payload.psn,
      payload.usage
    );

    yield put(loadUsageInfo());
  } catch (err) {}
}

export function* watchUser() {
  yield takeLatest(logout, handleLogout);
  yield takeLatest(userLogin, handleUserLogin);
  yield takeLatest(create, handleCreate);
  yield takeLatest(confirm, handleConfirm);
  yield takeLatest(loadUserProfile, handleLoadProfileImg);
  yield takeLatest(sendResetMail, handleSendPasswdResetMail);
  yield takeLatest(uploadImage, handleUploadImage);
  yield takeLatest(changeName, handleChangeName);
  yield takeLatest(changePasswd, handleChangePasswd);
  yield takeLatest(deleteAccountMail, handleDeleteAccountMail);
  yield takeLatest(loadUsageInfo, handleUserUsage);
  yield takeLatest(loadUserSettings, handleLoadUserSettings);
  yield takeLatest(updateUserSettings, handleUpdateUserSettings);
  yield takeLatest(linkSocialAccount, handleLinkSocialAccount);
  yield takeLatest(loadUserPermissions, handleLoadUserPermissions);
  yield takeLatest(setUserNotification, handleSetUserNotification);
  yield takeLatest(loadUserNotification, handleLoadUserNotification);
  yield takeLatest(updateLiveviewUsage, handleUpdateLiveviewUsage);
  // yield takeLatest(loadSubscription, handleUserSubscription);
}
