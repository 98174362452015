import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { LightColors, Modal, Typography } from "@thingsw/pitta-design-system";
import _ from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Webviewer } from "../../contants/Breakpoints";

const useStyles = makeStyles((theme: Theme) => ({
  modalDiv: {
    maxWidth: 500,
  },
  modalContentDiv: {
    padding: "58px 16px 0",
    textAlign: "center",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      padding: "38px 24px 20px",
      alignSelf: "normal",
    },
  },

  contentCard: {
    backgroundColor: LightColors.primary["6"],
    width: "100%",
    maxWidth: 454,
    maxHeight: 205,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "24px 0 24px",
    marginBottom: 13,
    [theme.breakpoints.up("sm")]: {
      width: 454,
    },
  },

  contentTop: {
    margin: "16px 0px 24px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 0px 25px",
    },
  },

  contentBottom: {
    padding: "0px 26px 6px",

    [theme.breakpoints.up("sm")]: {
      padding: "0px 64px 6px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0px 80px 6px",
    },
  },

  link: {
    cursor: "pointer",
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:visited": {
      color: LightColors.primary["7"],
    },
  },

  modalBtn: {
    padding: "14px 14px 16px 0px",
    [theme.breakpoints.up("md")]: {
      alignSelf: "center",
      padding: "0px 22px 24px 26px",
    },
  },

  modalImg: {
    marginBottom: 12,
    [theme.breakpoints.up("md")]: {},
  },
}));

// start 점검 시작 시간, end 점검 끝나는 시간
interface MaintenanceProps {
  start: moment.Moment;
  end: moment.Moment;
  open: boolean;
  close?: boolean;
  onClose: () => void;
}

export const ServerMaintenanceModal = (props: MaintenanceProps) => {
  const { start, end, close, onClose, open } = props;
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const tz = useMemo(() => moment.tz.guess(), []);

  const dateMarkup = useMemo(() => {
    const s = moment(start).tz(tz);
    const e = moment(end).tz(tz);
    if (s.isSame(e, "D")) {
      return (
        <Typography category="Default" variant="BodyBold">
          {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
        </Typography>
      );
    } else {
      return (
        <>
          <Typography category="Default" variant="BodyBold">
            {moment(start).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
          <br />
          <Typography category="Default" variant="BodyBold">
            - {moment(end).tz(tz).format("dddd, MMMM D, YYYY")}
          </Typography>
        </>
      );
    }
  }, [end, start, tz]);

  return (
    <Modal
      className={classes.modalDiv}
      contentClassName={classes.modalContentDiv}
      open={open}
      onClose={onClose}
      content={
        <div>
          <div className={classes.modalImg}>
            <img srcSet="/images/Maintenance.png" alt="maintenance" />
          </div>
          <div>
            <Typography category="Default" variant="H3">
              {t("Cloud Server Maintenance")}
            </Typography>
          </div>
          <div className={classes.contentTop}>
            <div>
              <Typography category="Default" variant="Body">
                {t("BlackVue Cloud Service_")}
              </Typography>
            </div>
          </div>
          <div className={classes.contentCard}>
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["3"]}
              >
                {t("We are currently_")}
              </Typography>
            </div>
            <div style={{ margin: "12px 0 15px" }}>
              <div>
                <Typography category="Default" variant="BodyBold">
                  {dateMarkup}
                </Typography>
              </div>
              <div style={{ padding: "6px 0px 3px" }}>
                <Typography category="Default" variant="H3">
                  {moment(start).tz(tz).format("HH:mm A")} -{" "}
                  {moment(end).tz(tz).format("HH:mm A")}
                </Typography>
              </div>
              <div>
                <Typography category="Default" variant="Caption">
                  ({start.format("HH:mm A")} - {end.format("HH:mm A")} UTC)
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                category="Default"
                variant="Body"
                dangerouslySetInnerHTML={{
                  __html: t("Expected duration_", {
                    a: `<strong>${_.round(
                      moment.duration(end.diff(start)).asHours()
                    )} hours.</strong>`,
                  }),
                }}
              ></Typography>
            </div>
          </div>
          <div>
            <div className={classes.contentBottom}>
              <div>
                <Typography category="Default" variant="Body">
                  {t("We apologize for_")}
                </Typography>
              </div>
              <div>
                <Typography category="Default" variant="Body">
                  {t("Thank you for_2")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      }
      heading=""
      close={close !== false}
      fullSize={mobile}
      actionClassName={classes.modalBtn}
      onClickPositive={onClose}
      RButton={t("OK")}
    />
  );
};
