import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MsgType } from "../Event/slice";

export interface WebPushEvent {
  psn: string;
  msg_code: MsgType;
  alert: string;
  event_filename?: string;
  image_url: string;
}

interface IPushEventState {
  loading: boolean;
  mobileToken?: string;
  newEvent: boolean;
  webPushEvents: { [key: string]: WebPushEvent };
  webPushEvent?: WebPushEvent;
  openNotification: boolean;
}

let initialState: IPushEventState = {
  loading: false,
  newEvent: false,
  webPushEvents: {},
  openNotification: false,
};

const slice = createSlice({
  name: "push-event",
  initialState,
  reducers: {
    resetPushEvent: (state) => {
      state.loading = false;
      state.mobileToken = undefined;
      state.newEvent = false;
      state.webPushEvents = {};
      state.webPushEvent = undefined;
    },
    clearPushEvent: (state) => {
      state.mobileToken = undefined;
    },
    loadPushToken: (state) => {},
    successLoadPushToken: (state, { payload }: PayloadAction<string>) => {
      state.mobileToken = payload;
    },
    connectWebPush: (state) => {},
    disconnectWebPush: (state) => {},
    setNewEvent: (state) => {
      state.newEvent = true;
    },
    clearNewEvent: (state) => {
      state.newEvent = false;
    },
    updateEvent: (state, { payload }: PayloadAction<WebPushEvent>) => {
      state.webPushEvent = payload;
      state.webPushEvents = { ...state.webPushEvents, [payload.psn]: payload };
    },
    clearEvent: (state) => {
      state.webPushEvent = undefined;
    },
    setOpenNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.openNotification = payload;
    },
  },
});

export const {
  resetPushEvent,
  connectWebPush,
  disconnectWebPush,
  clearPushEvent,
  loadPushToken,
  successLoadPushToken,
  setNewEvent,
  clearNewEvent,
  updateEvent,
  clearEvent,
  setOpenNotification,
} = slice.actions;

export const PUSH_EVENT = slice.name;
export default slice.reducer;
