import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { LightColors, Modal, Typography } from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Webviewer } from "../contants/Breakpoints";
import {
  changeGroupName,
  DashCamList,
  deleteGroup,
  deleteUser,
  GROUP,
  IDashCamInfo,
  IGroupInfo,
  IUserInfo,
} from "../features/Group/slice";
import { RootState } from "../features/store";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { GroupsAddScreen } from "../screens/GroupsAddScreen";
import { GroupsScreen } from "../screens/GroupsScreen";
import _ from "lodash";
import * as yup from "yup";
import { GroupsDetailScreen } from "../screens/GroupsDetailScreen";
import { UpgradePlanPanel } from "../components/UpgradePlanPanel";

const useStyles = makeStyles((theme: Theme) => ({
  lengthText: {
    width: "87%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "94%",
    },
  },
  renameContent: {
    padding: theme.spacing(2.25, 3, 3.125),
  },
  deleteModal: {
    maxWidth: 443,
    minHeight: 180,
  },
  deleteSelectModal: {
    maxWidth: 438,
    minHeight: 171,
  },
  deleteSelectContent: {
    display: "flex",
    padding: theme.spacing(2.375, 3, 3),
  },
  deleteOneContent: {
    display: "flex",
    padding: theme.spacing(2.375, 3, 3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.375, 3, 1.125),
    },
  },
  deleteUserModal: {
    maxWidth: 448,
    minHeight: 243,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 219,
    },
  },
  deleteUserContent: {
    padding: theme.spacing(2.25, 3),
  },
}));

const schema = yup.object().shape({
  groupName: yup
    .string()
    .trim()
    .required("Enter group name")
    .min(1, "The name must_")
    .max(30, "The name must_")
    .test("unallowed-check", "Unallowed character detected", (val) => {
      return (
        (val?.indexOf('"') ?? -1) === -1 && (val?.indexOf("\\") ?? -1) === -1
      );
    }),
});

export const GroupRouter = (props: ScreenDefaultProps) => {
  const { plan } = props;
  const history = useHistory();
  const classes = useStyles();
  const { path } = useRouteMatch();
  const groupIDMatch = useRouteMatch<{ GroupID: string }>(`${path}/:GroupID`);
  const groupDetailMatch = useRouteMatch<{ groupName: string }>(
    `${path}/:groupName`
  );
  const addGroupMatch = useRouteMatch<{ groupName: string }>(
    `${path}/add-group`
  );
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { loading, type } = useSelector((state: RootState) => state[GROUP]);

  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSelectDeleteModal, setOpenSelectDeleteModal] = useState(false);
  const [openDelUserModal, setOpenDelUserModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [currentGroup, setCurrentGroup] = useState<
    IGroupInfo | DashCamList | IGroupInfo[] | DashCamList[]
  >();
  const [currentCam, setCurrentCam] = useState<IDashCamInfo>();
  const [currentUser, setCurrentUser] = useState<IUserInfo>();
  const [renameError, setRenameError] = useState<string>();

  const { groupsList } = useSelector((state: RootState) => state[GROUP]);
  useEffect(() => {
    if (!addGroupMatch) {
      if (groupsList) {
        const found = _.find(
          groupsList.DashCamList,
          (group) => group.GroupID === groupIDMatch?.params.GroupID
        );
        if (!found) {
          history.replace("/groups");
        }
      }
    }
  }, [addGroupMatch, groupIDMatch?.params.GroupID, groupsList, history, path]);

  const handleGroupNameChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setGroupName(value);
    let error: { [key: string]: string } = {};
    try {
      await schema.validate({ groupName: value }, { abortEarly: false });
    } catch (err) {
      error = _.reduce(
        err.inner,
        (r, i) => ({ ...r, [i.path]: i.message }),
        {} as { [key: string]: string }
      );
    } finally {
      setRenameError(error["groupName"]);
    }
  };

  const handleRenameModal = useCallback((group: IGroupInfo | DashCamList) => {
    setCurrentGroup(group);
    if (_.has(group, "groupName")) {
      const g = group as IGroupInfo;
      setGroupName(g.groupName);
    }
    if (_.has(group, "GroupName")) {
      const g = group as DashCamList;
      setGroupName(g.GroupName);
    }
    setOpenRenameModal(true);
  }, []);

  //rename group
  const handleRename = async () => {
    if (currentGroup) {
      let error: { [key: string]: string } = {};
      let groupID = "";

      if (_.has(currentGroup, "groupName")) {
        const g = currentGroup as IGroupInfo;
        groupID = g.groupManagementID;
      }
      if (_.has(currentGroup, "GroupName")) {
        const g = currentGroup as DashCamList;
        groupID = g.GroupID;
      }
      try {
        await schema.validate({ groupName: groupName }, { abortEarly: false });
        setRenameError(undefined);
        dispatch(
          changeGroupName({
            groupID,
            groupName: groupName,
          })
        );
      } catch (err) {
        error = _.reduce(
          err.inner,
          (r, i) => ({ ...r, [i.path]: i.message }),
          {} as { [key: string]: string }
        );
      } finally {
        setRenameError(error["groupName"]);
      }
    }
  };

  const handleDeleteModal = useCallback((group: IGroupInfo | DashCamList) => {
    setCurrentGroup(group);
    if (_.has(group, "groupName")) {
      const g = group as IGroupInfo;
      setGroupName(g.groupName);
    }
    setOpenDeleteModal(true);
  }, []);

  const handleSelectDeleteModal = useCallback(
    (group: IGroupInfo[] | DashCamList[]) => {
      setCurrentGroup(group);
      setOpenSelectDeleteModal(true);
    },
    []
  );

  const handleDelUserModal = useCallback(
    (group: IGroupInfo | DashCamList, cam, user) => {
      setCurrentGroup(group);
      setCurrentCam(cam);
      setCurrentUser(user);
      setOpenDelUserModal(true);
    },
    []
  );

  //delete group One
  const handleDelete = () => {
    if (currentGroup) {
      let groupID = "";

      if (_.has(currentGroup, "groupName")) {
        const g = currentGroup as IGroupInfo;
        groupID = g.groupManagementID;
      }
      if (_.has(currentGroup, "GroupName")) {
        const g = currentGroup as DashCamList;
        groupID = g.GroupID;
      }
      dispatch(deleteGroup([groupID]));
    }
  };

  // delete select group
  const handleSelectDelete = () => {
    if (currentGroup) {
      const selectGroupId = _.map(
        currentGroup,
        (g) => _.get(g, "groupManagementID") || _.get(g, "GroupID")
        // _.iteratee(["groupManagementID", "GroupID"])
      );
      // console.log(currentGroup, selectGroupId);
      dispatch(deleteGroup(selectGroupId));
    }
    // setOpenSelectDeleteModal(false);
  };

  //delete user from dashcam
  const handleDelUser = () => {
    if (currentGroup && currentCam && currentUser) {
      let groupID = "";

      if (_.has(currentGroup, "groupName")) {
        const g = currentGroup as IGroupInfo;
        groupID = g.groupManagementID;
      }
      if (_.has(currentGroup, "GroupName")) {
        const g = currentGroup as DashCamList;
        groupID = g.GroupID;
      }
      dispatch(
        deleteUser({
          PSN: currentCam.PSN,
          GroupID: groupID,
          UserEmail: currentUser.Email,
        })
      );
    }
  };

  const renderScreen = () => {
    if (groupDetailMatch) {
      return (
        <GroupsDetailScreen
          {...props}
          groupName={groupDetailMatch.params.groupName}
          onRename={handleRenameModal}
          onDelete={handleDeleteModal}
          onDelUser={handleDelUserModal}
        />
      );
    }
  };

  useEffect(() => {
    if (!loading) {
      setOpenRenameModal(false);
      setOpenDelUserModal(false);
      setOpenSelectDeleteModal(false);
      setOpenDeleteModal(false);
    }
  }, [loading]);

  const renderGroupsScreen = () => {
    return (
      <GroupsScreen
        {...props}
        onRename={handleRenameModal}
        onDelete={handleDeleteModal}
        onSelectDelete={handleSelectDeleteModal}
      />
    );
  };

  if (plan === "Fleet plan") {
    return (
      <>
        <Switch>
          <Route exact path={path} render={renderGroupsScreen} />
          <Route
            exact
            path={`${path}/add-group`}
            render={() => <GroupsAddScreen {...props} />}
          />
          <Route path={`${path}/:groupID`} render={renderScreen} />
        </Switch>

        {/* rename modal */}
        <Modal
          open={openRenameModal}
          mobile={mobile}
          onClose={() => {
            setRenameError(undefined);
            setOpenRenameModal(false);
          }}
          onClickNegative={() => {
            setRenameError(undefined);
            setOpenRenameModal(false);
          }}
          onClickPositive={handleRename}
          heading={t("Rename group")}
          close
          loading={loading && type === changeGroupName.type}
          content={
            <div>
              <Input
                name="groupName"
                style={{ paddingBottom: 3, paddingTop: 8 }}
                onChange={handleGroupNameChange}
                value={groupName}
                autoFocus
                error={!!renameError}
                helperText={renameError && t(renameError)}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.lengthText}>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {groupName.length}/30
                  </Typography>
                </div>
              </div>
            </div>
          }
          contentClassName={classes.renameContent}
          LButton={t("Cancel")}
          RButton={t("Rename")}
        />

        {/* delete group one */}
        <Modal
          className={classes.deleteModal}
          open={openDeleteModal}
          mobile={mobile}
          onClose={() => setOpenDeleteModal(false)}
          onClickNegative={() => setOpenDeleteModal(false)}
          onClickPositive={handleDelete}
          heading={t("Delete")}
          close
          loading={loading && type === deleteGroup.type}
          content={
            <Typography
              category="Default"
              variant="Body"
              dangerouslySetInnerHTML={{
                __html: t("Are you sure_groupName", {
                  a: `<strong>${groupName}</strong>`,
                }),
              }}
            ></Typography>
          }
          contentClassName={classes.deleteOneContent}
          LButton={t("Cancel")}
          RButton={t("Delete")}
          Secondary
        />

        <Modal
          className={classes.deleteSelectModal}
          open={openSelectDeleteModal}
          mobile={mobile}
          onClose={() => setOpenSelectDeleteModal(false)}
          onClickNegative={() => setOpenSelectDeleteModal(false)}
          onClickPositive={handleSelectDelete}
          heading={t("Delete")}
          close
          loading={loading && type === deleteGroup.type}
          content={
            <Typography category="Default" variant="Body">
              {t("Are you sure_groups")}
            </Typography>
          }
          contentClassName={classes.deleteSelectContent}
          LButton={t("Cancel")}
          RButton={t("Delete")}
          Secondary
        />
        {/* DashCam에서 user 삭제 modal */}
        <Modal
          className={classes.deleteUserModal}
          contentClassName={classes.deleteUserContent}
          open={openDelUserModal}
          mobile={mobile}
          onClose={() => setOpenDelUserModal(false)}
          onClickNegative={() => setOpenDelUserModal(false)}
          onClickPositive={handleDelUser}
          heading={t("Remove")}
          close
          loading={loading && type === deleteUser.type}
          content={
            <Typography
              category="Default"
              variant="Body"
              dangerouslySetInnerHTML={{
                __html: t("Are you sure_remove driver2", {
                  a: `<strong>${
                    currentUser && currentUser.FirstName && currentUser.LastName
                      ? `${currentUser.FirstName} ${currentUser.LastName}`
                      : ""
                  }</strong>`,
                  b: `<strong>${currentUser && currentUser.Email}</strong>`,
                }),
              }}
            ></Typography>
          }
          LButton={t("Cancel")}
          RButton={t("Remove")}
          Secondary
        />
      </>
    );
  }

  if (plan === "Free plan" || plan === "Basic plan" || plan === "Smart plan") {
    return <UpgradePlanPanel plan={plan} />;
  }

  return <div></div>;
};
