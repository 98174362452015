import { IFirmwareConfig } from ".";

export const DR750S_2CH = {
  default: {
    Time: {
      GpsSync: {},
      TimeSet: {},
    },
    Video: {
      ImageSetting: {
        options: [
          { key: "4", value: "FHD@60 + FHD@30 (Extreme)" },
          { key: "0", value: "FHD@60 + FHD@30 (Sports)" },
          { key: "1", value: "FHD@30 + FHD@30" },
          { key: "2", value: "FHD@30 + HD@30" },
          { key: "3", value: "HD@30 + HD@30" },
        ],
      },
      VideoQuality: {
        options: [
          { key: "0", value: "Highest" },
          { key: "1", value: "High" },
          { key: "2", value: "Normal" },
        ],
        options4k: [
          { key: "0", value: "Highest (Extreme)" },
          { key: "0", value: "Highest (Sports)" },
        ],
      },
      NightVision: {
        options: [
          { key: "1", value: "Always" },
          { key: "2", value: "Parking mode only" },
        ],
      },
      FrontBrightness: {},
      RearBrightness: {},
    },

    Recording: {
      NormalRecord: {},
      AutoParking: {},
      VoiceRecord: {},
      DateDisplay: {},
      SpeedUnit: {},
      UseGpsInfo: {},
      RecordTime: {
        options: [
          {
            key: "1",
            value: "1 min",
          },
          {
            key: "2",
            value: "2 min",
          },
          {
            key: "3",
            value: "3 min",
          },
        ],
        options4k: [
          {
            key: "1",
            value: "1 min (Extreme)",
          },
          {
            key: "1",
            value: "1 min (Sports)",
          },
        ],
      },
      LockEvent: {},
      FrontRotate: {},
      RearRotate: {
        options: [
          {
            key: "0",
            value: "Default",
          },
          {
            key: "1",
            value: "Rotate video 180°",
          },
          {
            key: "2",
            value: "Mirror video",
          },
        ],
      },
    },
    Gsensor: {
      Normal: {},
      Parking: {},
    },
    MotionDetection: {
      MOTIONSENSOR: {},
    },
    System: {
      LED: {
        RECLED: {},
        NORMALLED: {},
        PARKINGLED: {},
        RearLED: {},
        WifiLED: {},
      },
      PSENSOR: {},
      VoiceGuidance: {
        STARTVOICE: {},
        NORMALSTARTVOICE: {},
        EVENTSTARTVOICE: {},
        CHANGERECORDMODEVOICE: {},
        ENDVOICE: {},
        SPEEDALERTVOICE: {},
        PARKINGEVENTVOICE: {},
        CLOUDVOICE: {},
        CHANGECONFIGVOICE: {},
        SHARPTURNVOICE: {},
        HARSHBRAKINGVOICE: {},
        ACCELERATIONVOICE: {},
      },
      VOLUME: {},
      ScheduledReboot: {},
      SpeedAlert: {},
      //BatteryProtection: {},
      UserTextOverlay: {},
      // LowvoltageTime: {},
    },
    Wifi: {
      LoginCredentials: {},
      // WifiBand: {},
      WifiSleepMode: {},
      onstart: {},
      // onstart_LTE: {},
    },
    Cloud: { CloudService: {}, sta1: {}, sta2: {}, sta3: {} },
    FirmwareLanguage: [
      "English",
      "Japan",
      "China",
      "German",
      "French",
      "Thai",
      "Idn",
      "Vietnam",
      "Spain",
      "Italian",
      "Dutch",
      "Polish",
      "Norwegian",
      "Finnish",
    ],
  } as IFirmwareConfig,
  1.019: {
    System: {
      EventRev: {},
    },
  } as IFirmwareConfig,
};
