const LowvoltageVolt: { [key: string]: string } = {
  "1": "11.8V & 22.8V",
  "2": "12V &23.2V",
  "3": "12.5V &24V",
};

export const LowvoltageVolt_GPRO: { [key: string]: string } = {
  "1": "12V & 23.2V",
  "2": "12.2V &23.6V",
  "3": "12.5V &24V",
};

export default LowvoltageVolt;
