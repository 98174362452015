import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GoogleMapReact from "google-map-react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Fab,
  LightColors,
  Menu,
  MobileMenu,
  Tooltip,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";

import CheckIcon from "@material-ui/icons/Check";

import { TopRightControl } from "./TopRightControl";
import {
  convertGeofenceToGeometries,
  exitFullscreen,
  getIcon,
  getMarker,
  isFullscreen,
  renderGeofences,
  requestFullscreen,
} from "../../utils/GoogleMap";
import {
  clearGPSTrackingData,
  GPS,
  IDriveInfo,
  ITrackData,
  ITrackInfo,
  loadGPSTrackingData,
} from "../../features/GPS/slice";
import { RootState } from "../../features/store";
import {
  DrawingMode,
  EventAbbrToEventFull,
  IGeometry,
  IGPSLocation,
  IInfoWindow,
  ILatLngBounds,
  MCODE_TO_TEXT,
} from "../../types";

import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Webviewer } from "../../contants/Breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EventItem from "@thingsw/pitta-design-system/dist/components/EventItem";
import { GEOFENCE, loadGeofences } from "../../features/Geofence/slice";
import { USER } from "../../features/User/slice";
import { INITIAL_MAP_LOCATION } from "./GoogleMap";
import { GOOGLE_API_KEY } from "../../contants/GoogleMap";

const MOBILE_BOUNDS_PADDING = { bottom: 50, left: 50, right: 50, top: 50 };
const PC_BOUNDS_PADDING = { bottom: 250, left: 400, right: 100, top: 250 };
// const PC_TRACK_BOUNDS_PADDING = { bottom: 100, left: 100, right: 0, top: 100 };
const POLYLINE_COLOR = "#13131C";
const POLYLINE_SATELLITE_COLOR = "#B7E1FF";

interface GPSTrackingMapProps {
  onGoogleApiLoaded?: (maps: {
    map: any;
    maps: any;
    ref: Element | null;
  }) => void;
  publicIcon?: boolean;
  fullscreenIcon?: boolean;
  trackMarker?: boolean;
  location?: IGPSLocation;
  children?: React.ReactNode;
  // tracks?: (ITrackInfo & { label: string })[];
  drives?: IDriveInfo[];
  infoWindow?: IInfoWindow;
  onDownload?: (data: ITrackData) => void;
  onPlay?: (data: ITrackData) => void;
  onClickMap?: (e: any) => void;
  onSetInfoWindow?: (infoWindow?: IInfoWindow) => void;
  onUpdateGeometry?: (object: IGeometry) => void;
  onStartDrawing?: () => void;
  drawingMode?: DrawingMode;
  drawingColor?: string;
  drawingOpacity?: number;
  geometry?: IGeometry | null;
  psn?: string;
  selectedTrack?: ITrackInfo;
  onSelectTrack?: (data: { track: ITrackInfo; disableZoom?: boolean }) => void;
  clearMap: boolean;
  disableZoom: boolean;
  sMarker?: IInfoWindow;
}

const useStyles = makeStyles((theme: Theme) => ({
  topControlPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
  },
  zoomControlDiv: {
    position: "absolute",
    bottom: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
  },
  zoomControlBtn: {
    borderRadius: 8,
    width: 30,
    height: 30,
    color: LightColors.primary["3"],
    minHeight: 30,
  },
  gpsFixBtn: {
    marginBottom: theme.spacing(0.5),
  },
  zoomInBtn: {
    borderBottom: 0,
    borderRadius: theme.spacing(1, 1, 0, 0),
    boxShadow: "none",
  },
  zoomOutBtn: {
    borderTop: 0,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: "none",
  },
  tnpDiv: {
    ...(theme.direction === "rtl" ? { paddingRight: 44 } : { paddingLeft: 44 }),
  },
  appIcon: {
    fontSize: 15,
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  directionMakerIcon: {
    width: 20,
    height: 20,
    zIndex: 1,
  },
  markerIcon: {
    cursor: "pointer",
    transform: "translate(-17px, -32px)",
    width: 34,
    height: 38,
    zIndex: 2,
  },
  selectedMarkerIcon: {
    cursor: "pointer",
    transform: "translate(-19px, -38px)",
    width: 38,
    height: 42,
  },
  startMarkerIcon: {
    zIndex: 2,
    transform: "translate(-12px, -12px)",
    filter:
      "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.14)), drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12)), drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2))",
  },
  infoWindowDiv: {
    width: 300,
    maxWidth: 300,
    backgroundColor: LightColors.primary["0"],
    borderRadius: 4,
    border: `1px sloid ${LightColors.primary["6"]}`,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    boxSizing: "border-box",
  },
  parkingDiv: {
    maxHeight: 353,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  infoWindowCenter: {
    transform: "translate(-150px, calc(-100% - 40px))",
  },
  inforParkingTitle: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  textTransform: {
    textTransform: "none",
  },
}));

const LatLngPadding = 0.3;

export const GPSTrackingMap = (props: GPSTrackingMapProps) => {
  const {
    onGoogleApiLoaded,
    fullscreenIcon,

    // tracks,
    drives,
    infoWindow,
    onDownload,
    onPlay,
    onSetInfoWindow,
    psn,
    selectedTrack,
    onSelectTrack,
    clearMap,
    disableZoom,
    sMarker,
  } = props;
  // useTraceUpdate(props);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const anchorRef = useRef<HTMLButtonElement>(null);
  const mapDivRef = useRef<HTMLDivElement>(null);

  const { tracks, interval } = useSelector((state: RootState) => state[GPS]);
  const { geofences } = useSelector((state: RootState) => state[GEOFENCE]);
  const { userProfile, permissions, userSettings } = useSelector(
    (state: RootState) => state[USER]
  );

  const [shownTracks, setShownTracks] = useState<ITrackInfo[]>([]);
  const [bounds, setBounds] = useState<any>();

  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [openLayer, setOpenLayer] = useState(false);
  const [mapMode, setMapMode] = useState<"map" | "satellite">("map");
  const [selectedMarker, setSelectedMarker] = useState<IInfoWindow>();
  const onClickMarker1 = useRef(false);
  const onClickMarker2 = useRef(false);
  const fitBounds = useRef(true);
  const autoUpdate = useRef(false);
  const ignoreBounds = useRef(false);
  const gMarkers = useRef<any[]>([]);
  const prevTracks = useRef<ITrackInfo[]>([]);
  const prevShownTracks = useRef<ITrackInfo[]>([]);
  const prevBounds = useRef<any>({});
  const prevSelectedMarker = useRef<IInfoWindow>();
  const prevSelectedTrack = useRef<ITrackInfo>();
  const prevInfoWindow = useRef<any>();
  const tracksRef = useRef<ITrackInfo[]>([]);

  const [fullscreen, setfullscreen] = useState(false);

  useEffect(() => {
    setSelectedMarker(sMarker);
  }, [sMarker]);
  useEffect(() => {
    tracksRef.current = tracks;
  }, [tracks]);

  useEffect(() => {
    if (
      userProfile?.userType === "Master" ||
      (userProfile?.userType === "SubMaster" && permissions?.geoFence)
    ) {
      dispatch(loadGeofences());
    }
  }, [dispatch, permissions?.geoFence, userProfile?.userType]);

  const geometries = useMemo(() => {
    return convertGeofenceToGeometries(geofences);
  }, [geofences]);

  useEffect(() => {
    if (map && maps && geometries && geometries.length > 0) {
      const objects = renderGeofences(map, maps, geometries, mobile, true);
      return () => {
        _.forEach(objects, (o) => o.setMap(null));
      };
    }
  }, [geometries, map, maps, mobile]);

  useEffect(() => {
    function exitHandler() {
      if (
        !document.fullscreenElement &&
        //@ts-ignore
        !document.webkitIsFullScreen &&
        //@ts-ignore
        !document.mozFullScreen &&
        //@ts-ignore
        !document.msFullscreenElement
      ) {
        ///fire your event
        setfullscreen(false);
      }
    }
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  useEffect(() => {
    if (clearMap && map) {
      // console.log("clearMap");
      map.setZoom(4);
      setSelectedMarker(undefined);
      onSetInfoWindow?.(undefined);
      setShownTracks([]);
      fitBounds.current = true;
      autoUpdate.current = false;
      // prevTracks.current = [];
      // prevShownTracks.current = [];
      prevBounds.current = {};
      prevSelectedMarker.current = undefined;
      prevSelectedTrack.current = undefined;
      dispatch(clearGPSTrackingData());
      //@ts-ignore
      // _.map(gMarkers.current, (mk) => mk.setMap(null));
    }
  }, [clearMap, dispatch, map, onSetInfoWindow]);

  useEffect(() => {
    if (
      map &&
      maps &&
      bounds &&
      tracks.length > 0 &&
      !autoUpdate.current &&
      (!_.isEqual(bounds, prevBounds.current) ||
        !_.isEqual(tracks, prevTracks.current))
    ) {
      prevBounds.current = bounds;
      prevTracks.current = tracks;
      const padding = LatLngPadding / map.getZoom();
      let newBounds: any;
      if (!ignoreBounds.current) {
        const sw = bounds.getSouthWest().toJSON();
        const ne = bounds.getNorthEast().toJSON();
        newBounds = new maps.LatLngBounds(sw, ne)
          .extend({ lat: sw.lat - padding, lng: sw.lng - padding })
          .extend({ lat: ne.lat + padding, lng: ne.lng + padding });
      }
      ignoreBounds.current = false;

      const stracks = _.map(tracks, (track) => {
        const data = _.filter(track.data, (d) => {
          let show = true;
          if (newBounds) {
            show = newBounds.contains({
              lat: d.loc[1],
              lng: d.loc[0],
            }) as boolean;
          }
          if (show) {
            if (interval === 2) {
              show = _.has(d, "t30s");
            }
            if (interval === 3) {
              show = _.has(d, "t1m");
            }
            if (interval === 4) {
              show = _.has(d, "t2m");
            }
          }
          return show;
        });
        const last = _.findLast(data);
        const lastData: ITrackData[] = [];
        if (last) {
          const lastIndx = _.findIndex(track.data, (d) => d.sid === last.sid);
          if (lastIndx > -1 && lastIndx + 1 < track.data.length) {
            lastData.push(track.data[lastIndx + 1]);
          }
        }
        const first = data[0];
        const firstData: ITrackData[] = [];
        if (first) {
          const firstIndx = _.findIndex(track.data, (d) => d.sid === first.sid);
          if (firstIndx - 1 > -1) {
            firstData.push(track.data[firstIndx - 1]);
          }
        }
        return {
          ...track,
          data: [...firstData, ...data, ...lastData],
        };
      });

      setShownTracks(stracks);
    }
  }, [tracks, interval, bounds, map, maps]);

  // control auto zoom
  useEffect(() => {
    if (map) {
      let sMaxLng = -999,
        sMaxLat = -999,
        sMinLng = 999,
        sMinLat = 999;

      if (
        !fitBounds.current &&
        !onClickMarker1.current &&
        !disableZoom &&
        selectedTrack &&
        selectedTrack?.drive_no !== prevSelectedTrack.current?.drive_no
      ) {
        const track = _.find(
          tracksRef.current,
          (tr) => tr.drive_no === selectedTrack.drive_no
        );
        if (track) {
          _.forEach(track.data, (d) => {
            sMinLat = Math.min(sMinLat, d.loc[1]);
            sMinLng = Math.min(sMinLng, d.loc[0]);
            sMaxLat = Math.max(sMaxLat, d.loc[1]);
            sMaxLng = Math.max(sMaxLng, d.loc[0]);
          });
        }

        // autoUpdate.current = true;
        map.fitBounds(
          {
            east: sMaxLng,
            north: sMaxLat,
            west: sMinLng,
            south: sMinLat,
          },
          mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
        );

        prevSelectedTrack.current = selectedTrack;
      }
      onClickMarker1.current = false;
    }
  }, [disableZoom, map, mobile, selectedTrack]);

  // drawing path
  useEffect(() => {
    if (map && maps && shownTracks.length > 0) {
      let minLat = 999;
      let minLng = 999;
      let maxLat = -999;
      let maxLng = -999;
      const strokeColor =
        mapMode === "map" ? POLYLINE_COLOR : POLYLINE_SATELLITE_COLOR;
      const strokeOpacity = mapMode === "map" ? 0.75 : 1;
      //drawing line
      const polylines = _.chain(shownTracks)
        .map((track) => {
          let pathMinLat = 999;
          let pathMinLng = 999;
          let pathMaxLat = -999;
          let pathMaxLng = -999;
          const selected = track.drive_no === selectedTrack?.drive_no;
          const connected: boolean[] = [];
          for (let i = 0; i < track.data.length - 1; i++) {
            if (
              interval &&
              interval <= 2 &&
              track.data[i + 1].vdate.diff(track.data[i].vdate, "s") > 180
            ) {
              connected.push(false);
            } else {
              connected.push(true);
            }
          }
          connected.push(true);
          const indexList: {
            sindx: number;
            eindx: number;
            connected: boolean;
          }[] = [];
          let sindex = 0;
          for (let i = 0; i < connected.length - 1; i++) {
            if (connected[i] !== connected[i + 1]) {
              indexList.push({
                sindx: sindex,
                eindx: i + 1,
                connected: connected[i],
              });
              sindex = i + 1;
            }
          }
          indexList.push({
            sindx: sindex,
            eindx: connected.length - 1,
            connected: true,
          });

          return _.map(indexList, (info) => {
            const pathsCoords = _.chain(track.data)
              .slice(info.sindx, info.eindx + 1)
              .map((d) => {
                pathMinLat = Math.min(pathMinLat, d.loc[1]);
                pathMinLng = Math.min(pathMinLng, d.loc[0]);
                pathMaxLat = Math.max(pathMaxLat, d.loc[1]);
                pathMaxLng = Math.max(pathMaxLng, d.loc[0]);
                return {
                  lat: d.loc[1],
                  lng: d.loc[0],
                };
              })
              .value();

            minLat = Math.min(minLat, pathMinLat);
            minLng = Math.min(minLng, pathMinLng);
            maxLat = Math.max(maxLat, pathMaxLat);
            maxLng = Math.max(maxLng, pathMaxLng);

            if (info.connected) {
              const path1 = new maps.Polyline({
                path: pathsCoords,
                geodesic: true,
                strokeWeight: selected ? 6 : 3,
                strokeColor,
                strokeOpacity,
                zIndex: 2,
                clickable: true,
              });
              path1.setMap(map);
              path1.addListener("click", () => {
                onSelectTrack?.({ track });
              });
              // return [path1, path2];
              return [path1];
            } else {
              const path1 = new maps.Polyline({
                path: pathsCoords,
                geodesic: true,
                strokeColor,
                strokeOpacity: 0,
                icons: [
                  {
                    icon: {
                      path: "M 0,-1 0,1",
                      strokeOpacity: 0.85,
                      scale: selected ? 6 : 3,
                    },
                    offset: "0",
                    repeat: selected ? "30px" : "15px",
                  },
                ],
                zIndex: 2,
                clickable: true,
              });
              path1.setMap(map);
              path1.addListener("click", () => {
                onSelectTrack?.({ track });
              });
              // return [path1, path2];
              return [path1];
            }
          });
        })
        .flattenDeep()
        .value();

      if (fitBounds.current && !onClickMarker2.current) {
        fitBounds.current = false;
        autoUpdate.current = true;
        map.fitBounds(
          {
            east: maxLng,
            north: maxLat,
            west: minLng,
            south: minLat,
          },
          mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
        );

        prevSelectedTrack.current = selectedTrack;
      }

      onClickMarker2.current = false;

      return () => {
        _.forEach(polylines, (line) => line.setMap(null));
      };
    }
  }, [
    interval,
    map,
    mapMode,
    maps,
    mobile,
    onSelectTrack,
    selectedTrack,
    shownTracks,
  ]);

  useEffect(() => {
    if (map && maps && !_.isEqual(prevShownTracks.current, shownTracks)) {
      const current = gMarkers.current;
      const sids = _.chain(shownTracks)
        .map((track) => track.data)
        .flattenDeep()
        .map((d) => d.sid)
        .value();
      const removed = _.filter(current, (mk) => !_.includes(sids, mk.data.sid));
      _.forEach(removed, (mk) => mk.setMap(null));
      const updated = _.differenceWith(
        current,
        removed,
        (mk1, mk2) => _.isEqual(mk1.data, mk2.data) && mk1.type === mk2.type
      );
      //  _.filter(current, (mk) => _.includes(sids, mk.data.sid) && mk.type === arrow);
      // _.forEach(gMarkers.current, (mk) => mk.setMap(null));
      prevShownTracks.current = shownTracks;
      const ms = _.chain(shownTracks)
        .map((track, tindx) => {
          return _.map(track.data, (d, indx) => {
            const tr = _.find(tracks, (t) => t.drive_no === track.drive_no);
            // let trindx = 0;

            if (tr) {
              const trindx = _.findIndex(tr.data, (td) => td.sid === d.sid);
              if (trindx === 0) {
                const found = _.find(
                  updated,
                  (u) => u.data.sid === d.sid && u.type === "first"
                );
                if (found) {
                  return found;
                }
                const m = getMarker(d, map, maps, "first", tindx);
                m.addListener("click", () => {
                  const info = {
                    drive_no: track.drive_no,
                    index: indx,
                    data: d,
                    sid: d.sid,
                    selectedSid: d.sid,
                  };
                  onClickMarker1.current = true;
                  onClickMarker2.current = true;
                  fitBounds.current = false;
                  setSelectedMarker(undefined);
                  onSetInfoWindow?.(info);
                  onSelectTrack?.({ track: tr });
                });
                m.type = "first";
                m.tindx = tindx;
                m.data = d;
                return m;
              } else if (trindx === tr.data.length - 1) {
                const found = _.find(
                  updated,
                  (u) => u.data.sid === d.sid && u.type === "last"
                );
                if (found) {
                  return found;
                }
                const m = getMarker(d, map, maps, "last");
                m.data = d;
                m.addListener("click", () => {
                  const info = {
                    drive_no: track.drive_no,
                    sid: d.sid,
                    index: indx,
                    data: d,
                    last: true,
                    selectedSid: d.sid,
                  };
                  onClickMarker1.current = true;
                  onClickMarker2.current = true;
                  fitBounds.current = false;
                  setSelectedMarker(undefined);
                  onSetInfoWindow?.(info);
                  onSelectTrack?.({ track: tr });
                });
                m.type = "last";
                return m;
              } else {
                const nextD = tr.data[trindx + 1];
                const heading = maps.geometry.spherical.computeHeading(
                  new maps.LatLng(d.loc[1], d.loc[0]),
                  new maps.LatLng(nextD.loc[1], nextD.loc[0])
                );
                const mks: any[] = [];
                let m1 = _.find(
                  updated,
                  (u) => u.data.sid === d.sid && u.type === "arrow"
                );
                if (!m1) {
                  m1 = getMarker(d, map, maps, "arrow", heading);
                  m1.addListener("click", () => {
                    const info = {
                      drive_no: track.drive_no,
                      index: indx,
                      data: d,
                      sid: d.sid,
                      selectedSid: d.sid,
                    };
                    onClickMarker1.current = true;
                    onClickMarker2.current = true;
                    fitBounds.current = false;
                    setSelectedMarker(undefined);
                    onSetInfoWindow?.(info);
                    onSelectTrack?.({ track });
                  });
                  m1.type = "arrow";
                  m1.tindx = heading;
                  m1.data = d;
                }
                mks.push(m1);

                if (!d.hide && d.mode !== "N") {
                  let m2 = _.find(
                    updated,
                    (u) => u.data.sid === d.sid && u.type === "event"
                  );
                  if (!m2) {
                    m2 = getMarker(d, map, maps, "event");
                    m2.type = "event";
                    m2.data = d;
                    m2.addListener("click", () => {
                      const info = {
                        drive_no: track.drive_no,
                        sid: d.sid,
                        index: indx,
                        data: d,
                        selectedSid: d.sid,
                      };
                      onClickMarker1.current = true;
                      onClickMarker2.current = true;
                      fitBounds.current = false;
                      setSelectedMarker(undefined);
                      onSetInfoWindow?.(info);
                      onSelectTrack?.({ track });
                    });
                    m2.data = d;
                  }
                  mks.push(m2);
                }
                return mks;
              }
            }

            // console.log("d", indx, "/", track, trindx, "/", tr, d);
          });
        })
        .flattenDeep()
        // .compact()
        .value();

      _.differenceWith(updated, ms, (mk1, mk2) => _.isEqual(mk1, mk2)).forEach(
        (mk) => mk.setMap(null)
      );
      gMarkers.current = ms;
    }
  }, [onSetInfoWindow, map, maps, shownTracks, tracks, onSelectTrack]);

  useEffect(() => {
    if (map && maps) {
      if (prevSelectedMarker.current) {
        const prevM = _.find(
          gMarkers.current,
          (m) =>
            m.data?.sid === prevSelectedMarker.current?.sid &&
            m.type === "event"
        );

        if (prevM) {
          const marker = getIcon(prevM.data, prevM.type, maps, prevM.tindx);
          prevM.setIcon(marker);
        }
      }
      if (infoWindow) {
        const m = _.find(
          gMarkers.current,
          (mk) => mk.data?.sid === infoWindow?.sid && mk.type === "event"
        );
        if (m) {
          const d = m.data as ITrackData;
          const icon = getIcon(d, m.type, maps, m.tindx, true);
          m.setIcon(icon);
        }
      }

      prevSelectedMarker.current = infoWindow;
    }
  }, [map, maps, infoWindow]);

  const loadTracks = useCallback(
    (psn: string, drive_no_list: number[], bounds?: ILatLngBounds) => {
      const cancel = new AbortController();

      dispatch(
        loadGPSTrackingData({
          psn: psn,
          drive_no_list,
          cancel,
          bounds,
        })
      );

      return cancel;
    },
    [dispatch]
  );

  useEffect(() => {
    fitBounds.current = true;
    autoUpdate.current = false;
    ignoreBounds.current = true;

    setSelectedMarker(undefined);
    onSetInfoWindow?.(undefined);
    setShownTracks([]);
    // prevTracks.current = [];
    // prevShownTracks.current = [];
    prevBounds.current = {};
    prevSelectedMarker.current = undefined;
    prevSelectedTrack.current = undefined;
    dispatch(clearGPSTrackingData());
  }, [dispatch, drives, onSetInfoWindow]);

  useEffect(() => {
    if (psn && !autoUpdate.current) {
      const drive_no_list = _.map(drives, (d) => d.drive_no);
      if (drive_no_list.length > 0) {
        let cancel: AbortController;
        cancel = loadTracks(
          psn,
          drive_no_list,
          ignoreBounds.current ? undefined : bounds?.toJSON()
        );
        return () => {
          cancel.abort();
        };
      }
    }
    autoUpdate.current = false;
  }, [bounds, drives, loadTracks, psn]);

  // update current bounds
  useEffect(() => {
    if (map && maps) {
      const idleListener = map.addListener("idle", () => {
        const newBounds = map.getBounds();
        // console.log("idle", bounds, newBounds, bounds?.equals(newBounds));
        if (!bounds || !bounds.equals(newBounds)) {
          setBounds(newBounds);
        }
        // enableTrackLoad.current = true;
      });

      return () => {
        //@ts-ignore
        maps.event.removeListener(idleListener);
        // maps.event.removeListener(clickListener);
      };
    }
  }, [onSetInfoWindow, map, maps, bounds]);

  const handleFullscreen = useCallback(() => {
    if (mapDivRef.current) {
      const elementToSendFullscreen = mapDivRef.current;
      if (isFullscreen(elementToSendFullscreen)) {
        setfullscreen(false);
        exitFullscreen(elementToSendFullscreen);
      } else {
        setfullscreen(true);
        requestFullscreen(elementToSendFullscreen);
      }
    }
  }, []);

  useEffect(() => {
    if (map && infoWindow?.data) {
      const { data } = infoWindow;
      map.setCenter({ lat: data.loc[1], lng: data.loc[0] });
    }
  }, [infoWindow, map]);

  const handleZoom = useCallback(
    (data: ITrackData) => {
      if (map) {
        map.setCenter({ lat: data.loc[1], lng: data.loc[0] });
        if (map.getZoom() < 17) {
          map.setZoom(17);
          autoUpdate.current = true;
        }
      }
    },
    [map]
  );

  const makeEventItem = useCallback(
    (data: ITrackData, noBorder?: boolean, parkingMode?: boolean) => {
      let eventString = "";
      if (data.mode === "E") {
        if (parkingMode) {
          eventString = "Parking impact";
        } else {
          eventString = MCODE_TO_TEXT[data.mtype];
        }
      }
      const isAI = data.mtype >= 6 && data.mtype <= 12;

      const playable = !!data.rid_front || !!data.rid_rear || !!data.rid_3;
      let thumbnail: string | undefined = data.thm;

      if (_.isEmpty(thumbnail)) {
        thumbnail = undefined;
      } else if (!playable) {
        thumbnail = undefined;
      }

      return (
        <EventItem
          mobile
          className={clsx(classes.infoWindowDiv, {
            [classes.infoWindowCenter]: !noBorder,
          })}
          isAI={isAI}
          heading={data.vdate.format("MMM DD YYYY, HH:mm:ss")}
          speed={
            userSettings?.velocityUnit === "0"
              ? `${Math.floor(data.avg_speed * 1.852)} km/h`
              : `${Math.floor(data.avg_speed * 1.150779)} MPH`
          }
          fileType={EventAbbrToEventFull[data.mode]}
          content={t(eventString)}
          content2={`${data.loc[0]}, ${data.loc[1]}`}
          contentAlt={`${t("Altitude :")} ${data.alt}m`}
          hoverCard
          noBorder
          thumbnail={thumbnail ?? (data.mode === "E" ? null : undefined)}
          zoomBtn
          lat={data.loc[1]}
          lng={data.loc[0]}
          // play={data.mp4_playable === 1}
          download={playable}
          onPlay={
            playable
              ? () => {
                  if (data) {
                    onPlay?.(data);
                  }
                }
              : undefined
          }
          onZoom={() => {
            handleZoom(data);
          }}
          onDownload={async () => {
            if (data) {
              onDownload?.(data);
            }
          }}
        />
      );
    },
    [
      classes.infoWindowDiv,
      classes.infoWindowCenter,
      userSettings?.velocityUnit,
      t,
      onPlay,
      handleZoom,
      onDownload,
    ]
  );

  const makeEventItemHtmlString = useCallback(
    (event: ITrackData) => {
      let downloadBtn = "";
      let thumbMarkup = `<svg  
                          focusable="false"
                          viewBox="0 0 80 80"
                          aria-hidden="true"
                          fill="none"
                          preserveAspectRatio="none"
                          style="color: rgba(0, 0, 0, 0.87);
                          box-sizing: inherit;
                          fill: currentColor;
                          display: inline-block;
                          font-size: 1.5rem;
                          margin-right: 8px;
                          width: 80px;
                          height: 80px;"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="79"
                            height="79"
                            fill="white"
                            stroke="#D0EBF9"
                          ></rect>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M56.9259 21.4C56.4074 19.9455 55 19 53.3333 19H26.7037C25 19 23.6296 19.9455 23.0741 21.4L19.9688 30.1628H16.8519C15.8291 30.1628 15 30.9957 15 32.0233V32.9535C15 33.981 15.8291 34.814 16.8519 34.814H17.4816L15.9259 36.3455V56.2093C15.9259 57.5911 17.1111 59 18.5185 59H20.8148C22.2222 59 23.3333 57.6182 23.3333 56.2364V51.5581H56.6667V56.2364C56.6667 57.6182 57.8148 59 59.2222 59H61.4815C62.8889 59 64.0741 57.5911 64.0741 56.2093V36.3455L62.5184 34.814H63.1481C64.1709 34.814 65 33.981 65 32.9535V32.0233C65 30.9957 64.1709 30.1628 63.1481 30.1628H60.0312L56.9259 21.4ZM26.7037 22.6364H53.3333L57.1481 33.5455H22.8889L26.7037 22.6364ZM25.1852 44.4545C23.1481 44.4545 21.4815 42.8182 21.4815 40.8182C21.4815 38.8182 23.1481 37.1818 25.1852 37.1818C27.2222 37.1818 28.8889 38.8182 28.8889 40.8182C28.8889 42.8182 27.2222 44.4545 25.1852 44.4545ZM54.8148 44.4545C52.7778 44.4545 51.1111 42.8182 51.1111 40.8182C51.1111 38.8182 52.7778 37.1818 54.8148 37.1818C56.8519 37.1818 58.5185 38.8182 58.5185 40.8182C58.5185 42.8182 56.8519 44.4545 54.8148 44.4545Z"
                            fill="#D0EBF9"
                          ></path>
                          <path d="M14 59L0 74" stroke="#D0EBF9" stroke-linejoin="round"></path>
                          <path d="M66 59L80 74" stroke="#D0EBF9" stroke-linejoin="round"></path>
                        </svg>`;

      if (event.rid_front || event.rid_rear || event.rid_3) {
        let thumbImg = `<div style="width: 80px; height: 80px; background-color:black;"></div>`;
        if (event.thm) {
          thumbImg = `<img src="${event.thm}" alt="Thumbnail Image" style="width: 100%; height: 100%;">`;
        }
        thumbMarkup = `
      <div style="
      font: 400 11px Roboto, Arial, sans-serif;
      cursor: url('https://maps.gstatic.com/mapfiles/openhand_8_8.cur'), default;
      color: rgba(0, 0, 0, 0.87);
      box-sizing: inherit;
      width: 80px;
      height: 80px;
      display: flex;
      position: relative;
      align-items: center;
      margin-right: 8px;
      justify-content: center;
      background-color: #13131C;
      ">
        ${thumbImg}
        <div style="
        font: 400 11px Roboto, Arial, sans-serif;
        cursor: url('https://maps.gstatic.com/mapfiles/openhand_8_8.cur'), default;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: inherit;
        width: 36px;
        height: 36px;
        display: flex;
        opacity: 0.45;
        z-index: 2;
        position: absolute;
        border-radius: 50px;
        background-color: #13131C;
        ">
        </div>
        <button 
          id="gps-tracking-event-play-${event.sid}"
          class="gps-tracking-event-btn"
          style="
          box-sizing: inherit;
          border: 0;
          cursor: pointer;
          margin: 0;
          outline: 0;
          align-items: center;
          user-select: none;
          vertical-align: middle;
          justify-content: center;
          text-decoration: none;
          background-color: transparent;
          -webkit-appearance: none;
          -webkit-tap-highlight-color: transparent;
          flex: 0 0 auto;
          overflow: visible;
          font-size: 1.5rem;
          text-align: center;
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          border-radius: 50%;
          color: #FFFFFF;
          display: flex;
          padding: 0;
          z-index: 3;
          position: absolute;
          "
          tabindex="0" type="button">
          <span style="
            cursor: pointer;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            font-size: 1.5rem;
            text-align: center;
            box-sizing: inherit;
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: inherit;
            ">
            <svg style="
              cursor: pointer;
              -webkit-tap-highlight-color: transparent;
              text-align: center;
              box-sizing: inherit;
              fill: currentColor;
              width: 1em;
              height: 1em;
              display: inline-block;
              font-size: 1.5rem;
              transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              flex-shrink: 0;
              user-select: none;
              " 
              focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M8 5v14l11-7z"></path>
            </svg>
          </span>
        </button>
      </div>
      `;
      }
      if (event.rid_front || event.rid_rear || event.rid_3) {
        downloadBtn = `
      <button 
      id="gps-tracking-event-download-${event.sid}"
      class="gps-tracking-event-btn"
      style="
      box-sizing: inherit;
      border: 0;
      cursor: pointer;
      margin: 0;
      display: inline-flex;
      outline: 0;
      position: relative;
      align-items: center;
      user-select: none;
      vertical-align: middle;
      justify-content: center;
      text-decoration: none;
      background-color: transparent;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      flex: 0 0 auto;
      overflow: visible;
      font-size: 1.5rem;
      text-align: center;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-radius: 50%;
      color: #7E7E83;
      padding: 0;" tabindex="0" type="button">

      <span style="
      cursor: pointer;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      font-size: 1.5rem;
      text-align: center;
      box-sizing: inherit;
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: inherit;">
      <svg style="
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      text-align: center;
      box-sizing: inherit;
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;" 
      focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z">
      </path>
      </svg>
      </span>
      <span class="tooltiptext">${t("Download")}</span>
      </button>`;
      }

      return `
    <div
      style="
        font: 400 11px Roboto, Arial, sans-serif;
        cursor: default;
        font-weight: 300;
        font-size: 13px;
        direction: ltr;
        unicode-bidi: isolate;
        box-sizing: inherit;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 8px 16px;
        background-color: #ffffff;
        border-bottom: 1px solid #e9e9ea;
      "
    >
      <div
        style="
          font: 400 11px Roboto, Arial, sans-serif;
          cursor: default;
          font-weight: 300;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.87);
          box-sizing: inherit;
          display: flex;
          justify-content: space-between;
          padding-bottom: 8px;
        "
      >
        <span
          style="
            font-weight: 500;
            font-style: normal;
            font-size: 0.75rem;
            line-height: 1.333;
            font-family: Roboto;
          "
          >${event.vdate.format("MMM DD YYYY, HH:mm:ss")}</span
        ><span
          style="
            font-weight: 500;
            font-style: normal;
            font-size: 0.75rem;
            line-height: 1.333;
            font-family: Roboto;
            text-align: right;
          "
          >${
            userSettings?.velocityUnit === "0"
              ? `${Math.floor(event.avg_speed * 1.852)} km/h`
              : `${Math.floor(event.avg_speed * 1.150779)} MPH`
          }</span
        >
      </div>
      <div
        style="
          font: 400 11px Roboto, Arial, sans-serif;
          cursor: default;
          font-weight: 300;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.87);
          box-sizing: inherit;
          display: flex;
          justify-content: space-between;
        "
      >
      ${thumbMarkup}
        <div
          style="
            font: 400 11px Roboto, Arial, sans-serif;
            cursor: default;
            font-weight: 300;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.87);
            box-sizing: inherit;
            flex: 1;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div
            style="
              font: 400 11px Roboto, Arial, sans-serif;
              cursor: default;
              font-weight: 300;
              font-size: 13px;
              color: rgba(0, 0, 0, 0.87);
              box-sizing: inherit;
              width: 100%;
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              style="
                font: 400 11px Roboto, Arial, sans-serif;
                cursor: default;
                font-weight: 300;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.87);
                unicode-bidi: isolate;
                box-sizing: inherit;
                display: flex;
                align-items: center;
                padding: 0;
              "
            >
              <div
                style="
                  font: 400 11px Roboto, Arial, sans-serif;
                  cursor: default;
                  font-weight: 300;
                  font-size: 13px;
                  color: rgba(0, 0, 0, 0.87);
                  box-sizing: inherit;
                  display: flex;
                  padding: 2px 8px 1px 8px;
                  position: relative;
                  border-radius: 20px;
                  text-transform: none;
                  justify-content: center;
                  background-color: #ffffff;
                  border: 1px solid rgba(216, 26, 38, 0.353);
                "
              >
                <span
                  class=""
                  style="
                    font-weight: 500;
                    font-style: normal;
                    font-size: 0.75rem;
                    line-height: 1.333;
                    font-family: Roboto;
                    color: #d81a26;
                  "
                  >Event</span
                >
              </div>
              <div
                style="
                  font: 400 11px Roboto, Arial, sans-serif;
                  cursor: default;
                  font-weight: 300;
                  font-size: 13px;
                  color: rgba(0, 0, 0, 0.87);
                  box-sizing: inherit;
                  display: flex;
                  margin-left: 4px;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    font-style: normal;
                    font-size: 0.75rem;
                    line-height: 1.333;
                    font-family: Roboto;
                    color: #7e7e83;
                  "
                ></span>
              </div>
            </div>
            <div
              style="
                font: 400 11px Roboto, Arial, sans-serif;
                cursor: default;
                font-weight: 300;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.87);
                box-sizing: inherit;
                padding: 0;
                text-align: right;
              "
            >
            ${downloadBtn}
              <button
                id="gps-tracking-event-zoom-${event.sid}"
                class="gps-tracking-event-btn"
                style="
                  box-sizing: inherit;
                  border: 0;
                  cursor: pointer;
                  margin: 0;
                  display: inline-flex;
                  outline: 0;
                  position: relative;
                  align-items: center;
                  user-select: none;
                  vertical-align: middle;
                  justify-content: center;
                  text-decoration: none;
                  background-color: transparent;
                  -webkit-appearance: none;
                  -webkit-tap-highlight-color: transparent;
                  flex: 0 0 auto;
                  overflow: visible;
                  font-size: 1.5rem;
                  text-align: center;
                  transition: background-color 150ms
                    cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                  border-radius: 50%;
                  color: #7e7e83;
                  padding: 0;
                  margin-left: 16px;
                "
                tabindex="0"
                type="button"
              >
                <span
                  style="
                    cursor: pointer;
                    user-select: none;
                    -webkit-tap-highlight-color: transparent;
                    font-size: 1.5rem;
                    text-align: center;
                    box-sizing: inherit;
                    width: 100%;
                    display: flex;
                    align-items: inherit;
                    justify-content: inherit;
                  "
                >
                  <svg
                    style="
                      cursor: pointer;
                      -webkit-tap-highlight-color: transparent;
                      text-align: center;
                      box-sizing: inherit;
                      fill: currentColor;
                      width: 1em;
                      height: 1em;
                      display: inline-block;
                      font-size: 1.5rem;
                      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                      flex-shrink: 0;
                      user-select: none;
                    "
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10h8c1.1 0 2-.9 2-2v-8c0-5.51-4.49-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                    ></path>
                  </svg>
                </span>
                <span class="tooltiptext">${t("Zoom")}</span>
              </button>
            </div>
          </div>
          <span
            style="
              font: 400 11px Roboto, Arial, sans-serif;
              cursor: default;
              color: rgba(0, 0, 0, 0.87);
              box-sizing: inherit;
              padding-bottom: 4px;
              font-weight: 400;
              font-style: normal;
              font-size: 0.75rem;
              line-height: 1.333;
              font-family: Roboto;
            "
            >Parking impact</span
          ><span
            style="
              font: 400 11px Roboto, Arial, sans-serif;
              cursor: default;
              box-sizing: inherit;
              padding-bottom: 4px;
              font-weight: 400;
              font-style: normal;
              font-size: 0.75rem;
              line-height: 1.333;
              font-family: Roboto;
              color: #68686e;
            "
            >Altitude : ${event.alt}m</span
          ><span
            style="
              font-weight: 400;
              font-style: normal;
              font-size: 0.75rem;
              line-height: 1.333;
              font-family: Roboto;
              color: #7e7e83;
            "
            >${event.loc[0]}, ${event.loc[1]}</span
          >
        </div>
      </div>
    </div>
`;
    },
    [t, userSettings?.velocityUnit]
  );

  const renderInfoWindow = useCallback(() => {
    const data = infoWindow?.data;
    if (data && map && maps) {
      if (!data.pevents || data.pevents.length === 0) {
        if (prevInfoWindow.current) {
          prevInfoWindow.current.close();
          prevInfoWindow.current = undefined;
        }
        return makeEventItem(data);
      } else {
        const marker = _.find(
          gMarkers.current,
          (m) => m.data?.sid === data.sid
        );

        if (marker && _.isEqual(marker.data, data) && prevInfoWindow.current) {
          return undefined;
        }
        const htmlString = `
        <div
          id="gps-tracking-info-window"
          style="
            color: rgba(0, 0, 0, 0.87);
            font: 400 11px Roboto, Arial, sans-serif;
            cursor: default;
            font-weight: 300;
            font-size: 13px;
            box-sizing: inherit;
            width: 300px;
            border-radius: 4px;
            background-color: #ffffff;
            display: flex;
            overflow: hidden;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05),
              0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);
            max-height: 353px;
            flex-direction: column;
          "
        >
          <div
            style="
              color: rgba(0, 0, 0, 0.87);
              font: 400 11px Roboto, Arial, sans-serif;
              cursor: default;
              font-weight: 300;
              font-size: 13px;
              box-sizing: inherit;
              padding: 8px 16px;
              border-bottom: 1px solid #e9e9ea;
            "
          >
            <span
              style="
                font-weight: 500;
                font-style: normal;
                font-size: 0.875rem;
                line-height: 1.5;
                font-family: Roboto;
              "
              >Parking events · ${data.pevents.length}</span>
          </div>
          <div style="overflow-y: visible; overflow-x: hidden">
          ${_.map(data.pevents, (event) => makeEventItemHtmlString(event)).join(
            ""
          )}
          </div>
        </div>
        `;

        if (prevInfoWindow.current) {
          prevInfoWindow.current.close();
          prevInfoWindow.current = undefined;
        }

        const infowindowObj = new maps.InfoWindow({
          content: htmlString,
          pixelOffset: new maps.Size(0, -25),
        });
        infowindowObj.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });

        prevInfoWindow.current = infowindowObj;
        return undefined;
      }
    }
    return undefined;
  }, [infoWindow?.data, map, maps, makeEventItem, makeEventItemHtmlString]);

  const renderMapTopRightMenu = useCallback(() => {
    return (
      <div className={classes.topControlPane}>
        <TopRightControl
          ref={anchorRef}
          onFullscreen={fullscreenIcon ? handleFullscreen : undefined}
          onLayers={() => setOpenLayer((o) => !o)}
          fullscreen={fullscreen}
        />
        {!mobile && (
          <Menu
            open={openLayer}
            onClickAway={() => setOpenLayer(false)}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            modifiers={{
              offset: { enabled: true, offset: "0, 4px" },
              preventOverflow: {
                enabled: false,
              },
            }}
          >
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "satellite",
              })}
              startIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "map",
              })}
              startIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </Menu>
        )}
        {mobile && (
          <MobileMenu
            open={openLayer}
            onClose={() => setOpenLayer(false)}
            container={anchorRef.current}
            classes={{ root: classes.textTransform }}
          >
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </MobileMenu>
        )}
      </div>
    );
  }, [
    classes.topControlPane,
    classes.tnpDiv,
    classes.appIcon,
    classes.textTransform,
    classes.mobileMemuItem,
    fullscreenIcon,
    handleFullscreen,
    fullscreen,
    mobile,
    openLayer,
    mapMode,
    t,
    map,
  ]);

  const renderZoomPanel = useCallback(() => {
    return (
      <div className={classes.zoomControlDiv}>
        <Tooltip placement="left" title={t("My location") ?? "My location"}>
          <Fab
            size="small"
            variant="rounded"
            className={clsx(classes.zoomControlBtn, classes.gpsFixBtn)}
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position: GeolocationPosition) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };
                    map.setCenter(pos);
                  },
                  () => {}
                );
              }
            }}
          >
            <GpsFixedIcon fontSize="small" />
          </Fab>
        </Tooltip>
        {!mobile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 8,
              boxShadow:
                "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Tooltip title={t("Zoom in") ?? "Zoom in"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomInBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() + 1);
                }}
              >
                <AddIcon fontSize="small" />
              </Fab>
            </Tooltip>
            <Tooltip title={t("Zoom out") ?? "Zoom out"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomOutBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() - 1);
                }}
              >
                <RemoveIcon fontSize="small" />
              </Fab>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }, [
    classes.zoomControlDiv,
    classes.zoomControlBtn,
    classes.gpsFixBtn,
    classes.zoomInBtn,
    classes.zoomOutBtn,
    t,
    mobile,
    map,
  ]);

  return (
    <div ref={mapDivRef} style={{ width: "100%", height: "100%" }}>
      <GoogleMapReact
        style={{
          width: "100%",
          height: "100%",
          //@ts-ignore
          position: "relative",
          touchAction: "none",
        }}
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
          libraries: ["geometry", "drawing"],
        }}
        defaultCenter={INITIAL_MAP_LOCATION}
        defaultZoom={4}
        options={{ disableDefaultUI: true }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ maps, map, ref }) => {
          setMap(map);
          setMaps(maps);
          onGoogleApiLoaded?.({ maps, map, ref });
        }}
        onClick={(value) => {
          const path =
            value.event?.nativeEvent?.path ||
            value.event?.nativeEvent?.composedPath();
          const elemIds = _.map(path, (p) => p.id);
          const ignore = _.includes(elemIds, "gps-tracking-info-window");
          const zoomBtn = _.find(elemIds, (id) =>
            _.startsWith(id, "gps-tracking-event-zoom-")
          );
          const downloadBtn = _.find(elemIds, (id) =>
            _.startsWith(id, "gps-tracking-event-download-")
          );
          const playBtn = _.find(elemIds, (id) =>
            _.startsWith(id, "gps-tracking-event-play-")
          );

          if (!ignore) {
            if (prevInfoWindow.current) {
              prevInfoWindow.current.close();
              prevInfoWindow.current = undefined;
            }
            if (selectedMarker && infoWindow) {
              setSelectedMarker(undefined);
              onSetInfoWindow?.(undefined);
            } else {
              setSelectedMarker(infoWindow);
            }
          } else {
            if (zoomBtn) {
              const sid = _.chain(zoomBtn).split("-").last().value();
              const data = selectedMarker?.data;
              if (sid && data) {
                const found = _.find(
                  data.pevents,
                  (evt) => evt.sid === parseInt(sid)
                );
                if (found) {
                  handleZoom(found);
                }
              }
            }

            if (downloadBtn) {
              const sid = _.chain(downloadBtn).split("-").last().value();
              const data = selectedMarker?.data;
              if (sid && data) {
                const found = _.find(
                  data.pevents,
                  (evt) => evt.sid === parseInt(sid)
                );
                if (found) {
                  onDownload?.(found);
                }
              }
            }

            if (playBtn) {
              const sid = _.chain(playBtn).split("-").last().value();
              const data = selectedMarker?.data;
              if (sid && data) {
                const found = _.find(
                  data.pevents,
                  (evt) => evt.sid === parseInt(sid)
                );
                if (found) {
                  onPlay?.(found);
                }
              }
            }
          }
        }}
        // onChange={(value) => console.log(value)}
      >
        {infoWindow && renderInfoWindow()}
      </GoogleMapReact>
      {renderMapTopRightMenu()}
      {renderZoomPanel()}
    </div>
  );
};
