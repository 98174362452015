import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CheckBox,
  DateRangePicker,
  FileTypeName,
  Fonts,
  ICameraInfo,
  IconButton,
  LightColors,
  Menu,
  MobileMenu,
  Modal,
  RadioButton,
  RangeModifier,
  Tab,
  Tabs,
  Typography,
  WebMenuItem,
  Tooltip,
  LocationType,
  AI_EVENTS,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import axios from "axios";
import moment from "moment";
import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import _ from "lodash";

import {
  CAMERA,
  loadMyCemrasLocation,
  TabNameInfo1,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import {
  deleteCloudVODFile,
  deleteEventVODFile,
  IVOD,
  IVODToken,
  loadCloudVODList,
  loadCloudVODThm,
  loadEventThumbnail,
  loadEventVODList,
  loadThumbnail,
  loadUrgentVODToken,
  loadVODList,
  moveEventVODFiles,
  successLoadVODToken,
  updateThmRequest,
  uploadVODFile,
  VOD,
  clearVOD as sliceClearVOD,
  ICameraVOD,
  successloadVODList,
} from "../../features/VOD/slice";

import FilterListIcon from "@material-ui/icons/FilterList";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { loadUsageInfo, USER } from "../../features/User/slice";
import {
  API_GATEWAY_URI,
  API_SERVER_URI,
  getLbURI,
} from "../../contants/Server";
import {
  IAccel,
  IGPSLocation,
  RESULT_CODE,
  VideoQualityType,
} from "../../types";
import { Webviewer } from "../../contants/Breakpoints";
import { VideoPlayer } from "./VideoPlayer";
import { GSensorPlayer } from "../GSensorPlayer";
import FileListItem from "@thingsw/pitta-design-system/dist/components/FileListItem";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { doDownload } from "../../utils/VOD";
import { LocationMap } from "../maps/LocationMap";
import {
  PermissionProps,
  ScreenDefaultProps,
} from "../../hoc/withViewerTemplate";
import { getVODToken } from "../../apis";
import { PAYMENT } from "../../features/Payment/slice";
import { getPlanFromServiceID } from "../../utils/Service";
import RadioGroup from "@material-ui/core/RadioGroup";
import { PSN650 } from "../../contants/Models";
import { ExceedModal } from "../modals/ExceedModal";
import { isFree100Check } from "../../utils/isFree100Check";

export interface PlaybackPanelProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: theme.spacing(3),
      margin: theme.spacing(0, 4, 2, 4),
    },
    position: "relative",
  },
  topContainerDiv: {
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  topContainerDivMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
  topContainerDivMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
  playDiv: {
    flex: "1 1 50%",
    transition: theme.transitions.create("width"),
    marginBottom: theme.spacing(1),
  },
  playDivMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      marginBottom: theme.spacing(0),
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(3) }
        : { marginRight: theme.spacing(3) }),
    },
  },
  playDivMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(0),
    },
  },
  playDivTheater: {
    width: "100%",
    marginRight: 0,
    flex: "none",
  },
  listDiv: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minWidth: 330,
  },
  listDivTheater: {
    order: 2,
    marginTop: theme.spacing(3),
    height: 188,
    flexBasis: 321,
  },
  listBorder: {
    border: `1px solid ${LightColors.primary["6"]}`,
  },
  tabDiv: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    minHeight: theme.spacing(6),
  },
  filterDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0.9735, 1.375),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  filterBtn: {
    padding: 3,
    color: LightColors.primary["2"],
    marginRight: theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
  },
  fileListDiv: {
    marginTop: 4,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: LightColors.primary["6"],
    },
  },
  playList: {
    // width: "100%!important",
    "&>div": {
      maxWidth: "100%!important",
    },
  },
  mapDiv: {
    height: 428,
    marginTop: theme.spacing(1),
    position: "relative",
  },
  mapDivMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      height: "max(188px, calc(100vh - 920px))",
      flex: "1 1 50%",
      marginTop: theme.spacing(3),
    },
  },
  mapDivMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      height: "max(188px, calc(100vh - 920px))",
      flex: "1 1 50%",
      marginTop: theme.spacing(3),
    },
  },
  mapDivWideMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      flex: "unset",
      width: "calc(50% - 12px)",
      height: 205,
      marginTop: theme.spacing(3),
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(1.5) }
        : { marginRight: theme.spacing(1.5) }),
    },
  },
  mapDivWideMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      flex: "unset",
      width: "calc(50% - 12px)",
      height: 205,
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(1.5),
    },
  },
  mapDivTheater: {
    order: 1,
    width: "calc(100% - 321px - 24px)",
    minWidth: 1,
    marginRight: theme.spacing(3),
  },
  theaterBlankDiv: {
    flexBasis: 321,
    order: 4,
  },
  delete: {
    color: LightColors.secondary["11"],
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  actionDiv: {
    borderTop: `1px solid ${LightColors.primary["5"]}`,
  },
  actionRoot: {
    "&.Mui-disabled > span": {
      opacity: 0.25,
    },
  },
  actionWrapper: {
    color: LightColors.primary["1"],
    "&:hover": {
      color: LightColors.primary["7"],
    },
    "&:active": {
      color: LightColors.primary["8"],
    },
  },
  actionWrapperRed: {
    color: LightColors.secondary["11"],
    "&:hover": {
      color: LightColors.secondary["12"],
    },
    "&:active": {
      color: LightColors.secondary["11"],
    },
  },
  actionLabel: {
    ...Fonts.Default.Caption,
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },
  circularLoading: {
    color: LightColors.primary["0"],
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  gSensorDiv: {
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2),
    overflow: "auto",
  },
  gSensorDivMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
  },
  gSensorDivMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
  },
  gSensorDivWideMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      width: "calc(50% - 12px)",
      order: 4,
      marginTop: theme.spacing(3),
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(1.5) }
        : { marginLeft: theme.spacing(1.5) }),
    },
  },
  gSensorDivWideMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      width: "calc(50% - 12px)",
      order: 4,
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1.5),
    },
  },
  gSensorDivTheaterMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      width: "calc(100% - 321px - 24px)",
      order: 2,
      marginLeft: 0,
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  gSensorDivTheaterMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      width: "calc(100% - 321px - 24px)",
      order: 2,
      marginLeft: 0,
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  filterDrawerDiv: {
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 469,
    },
  },
  filterTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  filterListDiv: {
    flex: 1,
    margin: theme.spacing(1, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(1, 3),
    },
  },
  filterItemDiv: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: 0,
    },
  },
  radioBtn: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
  },
  radioFormControl: {
    marginLeft: 8,
  },
  filterBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(2),
  },
  listEmptyDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    width: "100%",
  },
  listEmptyDivMenuOpen: {
    [theme.breakpoints.up(Webviewer.playbackMobile)]: {
      width: 328,
    },
  },
  listEmptyDivMenuClose: {
    [theme.breakpoints.up(Webviewer.playbackMobileClose)]: {
      width: 328,
    },
  },
  usedProgDiv: {
    position: "relative",
    width: 112,
    height: 3,
    marginTop: 3,
    backgroundColor: LightColors.primary["5"],
  },
  usedBg: {
    height: "100%",
  },
  selectCloseBtn: {
    marginRight: theme.spacing(1.5),
    padding: theme.spacing(0.5),
  },
  modalContent: (props: ScreenDefaultProps) => ({
    padding: theme.spacing(2.25, 3, 3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.25, 3, 6),
    },
  }),
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
  modalTitle: {
    minHeight: 16,
    padding: `18px 24px 0 24px`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: `13px 24px 0 13px` }
        : { padding: `13px 13px 0 24px` }),
    },
  },
  downloadModalTitle: {
    minHeight: 16,
    padding: `18px 24px 0 24px`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: `13px 22px 0 13px` }
        : { padding: `13px 13px 0 22px` }),
    },
  },
  closeIconStyle: { marginTop: 6 },

  exceedModalRoot: {
    minWidth: 288,
  },
  downloadModalRoot: {
    margin: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 437,
      boxSizing: "border-box",
    },
  },
  exceedModalContentDiv: {
    padding: theme.spacing(3),
  },
  downloadModalContentDiv: {
    padding: theme.spacing(2.125, 2.25, 2.75),
  },
  downloadMobileModal: {
    padding: theme.spacing(3, 2.375),
  },
  downloadModalbottom: {
    padding: "0px 24px 24px",
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0,
  },
  subTitle: {
    marginBottom: theme.spacing(2) - 3,
    ...(theme.direction === "rtl" ? { marginRight: 4 } : { marginLeft: 4 }),
  },
  typeTitleDiv: {
    margin: theme.spacing(3.625, 0, 1.75),
    ...(theme.direction === "rtl" ? { marginRight: 4 } : { marginLeft: 4 }),
  },
  checkMargin: {
    marginBottom: theme.spacing(1.25),
  },
  checkBoxSpacing: {
    ...(theme.direction === "rtl" ? { marginLeft: 3 } : { marginRight: 3 }),
  },
}));

const CLOUD_NATIVE = true;

export const PlaybackPanel = ({
  className,
  uploadVodPerm,
  downloadVodPerm,
  deleteVodPerm,
  moveToCloudVodPerm,
  cameraVodPerm,
  cloudVodPerm,
  openMenu,
}: PlaybackPanelProps & PermissionProps & ScreenDefaultProps) => {
  const classes = useStyles({ openMenu });
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const buttonsRef = useRef<RefObject<HTMLButtonElement>[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLButtonElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);
  const rootDivRef = useRef<HTMLDivElement>(null);

  const { cameraList, camera: cam } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const {
    loading,
    vodList,
    thmRequests,
    cloudVodList,
    eventVodList,
    thm,
    type,
  } = useSelector((state: RootState) => state[VOD]);

  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);

  const firmware = useSelector((state: RootState) => state[CAMERA].firmware);
  const userUsage = useSelector((state: RootState) => state[USER].usageInfo);
  const videoSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const [onScroll, setOnScroll] = useState(false);
  const [requestPause, setRequestPause] = useState<any>();
  const [value, setValue] = useState(0);
  const [preValue, setPreValue] = useState(0);
  // vod 재생 유지를 위해서 추가
  const [vodValue, setVodValue] = useState(0);
  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [currentVOD, setCurrentVOD] = useState<ICameraVOD>();
  const [playVOD, setPlayVOD] = useState<ICameraVOD>();
  const [playVODIndx, setPlayVODIndx] = useState<number>();
  const [listHeight, setListHeight] = useState<number>();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openExceedModal, setOpenExceedModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedVODs, setSelectedVODs] = useState<ICameraVOD[]>([]);
  const [selectMode, setSelectMode] = useState(false);
  const [allSelect, setAllSelect] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [theater, setTheater] = useState(false);
  const [location, setLocation] = useState<IGPSLocation>();
  const [locations, setLocations] = useState<IGPSLocation[]>([]);
  const [accels, setAccels] = useState<IAccel[]>([]);
  const [updatedTimestamp, setUpdatedTimestamp] = useState<number>();
  const [timestamp, setTimestamp] = useState<number>();
  const [totalTimestamp, setTotalTimestamp] = useState<number>();
  const [quality, setQuality] = useState<VideoQualityType>("low");
  const [downloadQuality, setDownloadQuality] =
    useState<VideoQualityType>("low");
  const [downloadDirections, setDownloadDirections] = useState<LocationType[]>([
    "Front",
  ]);
  const [uploadDirection, setUploadDirection] = useState<LocationType>("Front");
  const [videoLoading, setVideoLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterEventN, setFilterEventN] = useState(true);
  const [filterEventE, setFilterEventE] = useState(true);
  const [filterEventP, setFilterEventP] = useState(true);
  const [filterEventM, setFilterEventM] = useState(true);
  const [appliedEventN, setAppliedEventN] = useState(true);
  const [appliedEventE, setAppliedEventE] = useState(true);
  const [appliedEventP, setAppliedEventP] = useState(true);
  const [appliedEventM, setAppliedEventM] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [openWarnModal, setOpenWarnModal] = useState(false);
  const [screenMode, setScreenMode] = useState<"wide" | "default">("default");
  const [planName, setPlanName] = useState<string>();
  const [filterDate, setFilterDate] = useState<RangeModifier>({
    from: null,
    to: null,
  });
  const [appliedDate, setAppliedDate] = useState<RangeModifier>({
    from: null,
    to: null,
  });
  const [downloadMode, setDownloadMode] = useState<"single" | "multiple">(
    "single"
  );
  const [camera, setCamera] = useState<ICameraInfo>();
  const init = useRef(false);
  const [anchorRef, setAnchorRef] = useState<
    RefObject<HTMLButtonElement> | undefined
  >();

  const ExceptionPSN = useMemo(
    () => _.includes(PSN650, camera?.psn.substr(0, 4)),
    [camera?.psn]
  );

  const handleDownload = useCallback(async (url: string, filename?: string) => {
    setRequestPause({});
    return doDownload(url, filename);
  }, []);

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const free100Check = useMemo(() => {
    if (subscriptionInfo && userUsage) {
      return isFree100Check(subscriptionInfo, userUsage);
    }
  }, [subscriptionInfo, userUsage]);

  useEffect(() => {
    if (camera && camera.latitude && camera.longitude) {
      setLocation({
        lat: parseFloat(camera.latitude),
        lng: parseFloat(camera.longitude),
        name: camera.dev_name,
        mode: "0",
      } as IGPSLocation);
    } else {
      const cancel = new AbortController();
      dispatch(loadMyCemrasLocation({ cancel }));
      return () => {
        cancel.abort();
      };
    }
  }, [camera, dispatch]);

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    return () => {
      dispatch(sliceClearVOD());
    };
  }, [dispatch]);

  useEffect(() => {
    const cm = _.chain(cameraList?.DeviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === cam?.psn)
      .value();
    setCamera((c) => {
      if (c?.psn !== cm?.psn) {
        return cm;
      } else if (c?.active !== cm?.active) {
        return cm;
      } else if (
        (!c?.latitude && cm?.latitude) ||
        (!c?.longitude && cm?.latitude)
      ) {
        return cm;
      }
      return c;
    });
  }, [cam, cameraList?.DeviceListInfo]);

  useEffect(() => {
    if (requestPause) {
      setRequestPause(undefined);
    }
  }, [requestPause]);

  useEffect(() => {
    setFilterApplied(
      !(appliedEventN && appliedEventE && appliedEventP && appliedEventM) ||
        (!!appliedDate.from && !!appliedDate.to)
    );
  }, [
    appliedEventN,
    appliedEventE,
    appliedEventP,
    appliedEventM,
    appliedDate.from,
    appliedDate.to,
  ]);

  const currentVODs = useMemo(() => {
    let vods: ICameraVOD[] = [];
    if (value === 0) {
      vods = vodList;
    } else if (value === 1) {
      vods = cloudVodList?.filelist ?? [];
    } else if (value === 2) {
      vods = eventVodList;
    }
    const eventList: FileTypeName[] = [];
    if (appliedEventN) {
      eventList.push("Normal");
    }
    if (appliedEventE) {
      eventList.push("Event");
    }
    if (appliedEventP) {
      eventList.push("Parking");
    }
    if (appliedEventM) {
      eventList.push("Manual");
    }

    const filtered = _.filter(vods, (v) => _.includes(eventList, v.event));
    if (appliedDate.from && appliedDate.to) {
      return _.filter(
        filtered,
        (vod) =>
          vod.time.isSameOrAfter(appliedDate.from, "D") &&
          vod.time.isSameOrBefore(appliedDate.to, "D")
      );
    }

    return filtered;
  }, [
    appliedDate.from,
    appliedDate.to,
    appliedEventE,
    appliedEventM,
    appliedEventN,
    appliedEventP,
    cloudVodList?.filelist,
    eventVodList,
    value,
    vodList,
  ]);

  const handleAllSelect = useCallback(() => {
    setAllSelect((s) => !s);
    if (allSelect) {
      setSelectedVODs(currentVODs);
    }
    setSelectedVODs([]);
  }, [allSelect, currentVODs]);

  const getLink = useCallback(
    (vod: IVOD, token: IVODToken, vodQuality: VideoQualityType) => {
      if (camera && loginInfo) {
        const { lb_server_name, lb_http_port, psn } = camera;
        const { user_token: userToken } = loginInfo;
        const { filename, lowFilename } = vod;
        const { vod_token } = token;
        let fname = filename;
        if (vodQuality === "low" && lowFilename) {
          fname = lowFilename;
        }
        return `${getLbURI(
          lb_server_name,
          lb_http_port
        )}/proc/vod_file?email=${email}&user_token=${userToken}&psn=${psn}&filename=${fname}&vod_token=${vod_token}&tokenType=web`;
      }
    },
    [camera, loginInfo, email]
  );

  const getCloudLink = useCallback(
    async (vod, vodQuality: VideoQualityType) => {
      if (camera && loginInfo) {
        const { user_token: userToken } = loginInfo;
        const { filename, lowFilename } = vod;
        const { psn } = camera;
        let fname = filename;
        if (vodQuality === "low" && lowFilename) {
          fname = lowFilename;
        }
        return axios.get(
          `${API_SERVER_URI}/BCS/userS3PresignedUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&filename=${fname}&tokenType=web`
        );
      }
    },
    [email, camera, loginInfo]
  );

  const getEventLink = useCallback(
    async (vod) => {
      if (camera && loginInfo) {
        const { user_token: userToken } = loginInfo;
        const { rid } = vod;
        const { psn } = camera;
        return axios.get(
          `${API_SERVER_URI}/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
        );
      }
    },
    [email, camera, loginInfo]
  );

  useEffect(() => {
    if (camera && camera.active === "on" && cameraVodPerm) {
      setValue(0);
    } else if (cloudVodPerm) {
      setValue(1);
    } else {
      setValue(2);
    }
  }, [camera, cameraVodPerm, cloudVodPerm]);

  useEffect(() => {
    if (timestamp) {
      const indx = Math.min(Math.floor(timestamp), locations.length);
      const loc = _.find(locations, (loc) => loc.time === indx);
      if (loc) {
        setLocation(loc);
      }
    }
  }, [timestamp, locations]);

  useEffect(() => {
    if (!loading) {
      setOpenDeleteModal(false);
      setSelectedVODs([]);
    }
  }, [loading]);

  // console.log(videoProps);

  useEffect(() => {
    if (camera) {
      if (loginInfo?.userType === "Master" || loginInfo?.userType === "Etc") {
        dispatch(loadUsageInfo());
      }
      dispatch(loadCloudVODList(camera.psn));
    }
  }, [camera, dispatch, loginInfo?.userType]);

  useEffect(() => {
    const requestCNVod = async (camera: ICameraInfo) => {
      const resp = await axios.post(`${API_GATEWAY_URI}/IoT/devicecommand`, {
        command: "VODList",
        email,
        user_token: loginInfo?.user_token,
        tokenType: "web",
        psn: camera?.psn,
      });
      const data = JSON.parse(resp.data);
      dispatch(successloadVODList(data["filelist"]));
    };
    if (camera) {
      setSelectedVODs([]);
      if (value === 0 && camera.active === "on") {
        if (CLOUD_NATIVE) {
          requestCNVod(camera);
        } else {
          dispatch(
            loadVODList({
              lb_server_name: camera.lb_server_name,
              lb_http_port: camera.lb_http_port,
              psn: camera.psn,
            })
          );
        }
      } else if (value === 1) {
        dispatch(loadCloudVODList(camera.psn));
      } else if (value === 2) {
        dispatch(loadEventVODList(camera.psn));
      }
      init.current = true;
    }
  }, [dispatch, camera, value, email, loginInfo?.user_token]);

  useEffect(() => {
    let vodLength = 0;
    if (value === 0) {
      vodLength = vodList.length;
    } else if (value === 1) {
      vodLength = cloudVodList?.filelist.length ?? 0;
    } else if (value === 2) {
      vodLength = eventVodList.length;
    }
    if (vodLength !== buttonsRef.current.length) {
      buttonsRef.current = _.range(0, vodLength).map(() =>
        createRef<HTMLButtonElement>()
      );
    }
  }, [cloudVodList?.filelist, eventVodList, value, vodList]);

  // for test
  // const cloudUsage = useMemo(() => 70, []);
  // for production
  const cloudUsage = useMemo(
    () =>
      cloudVodList
        ? (cloudVodList.s3_usage / cloudVodList.s3_usage_limit) * 100
        : 0,
    [cloudVodList]
  );

  useEffect(() => {
    if ((value === 1 || value === 2) && cloudVodList) {
      const json = localStorage.getItem("pitta-storage-alert");
      let storageAlert = json
        ? JSON.parse(json)
        : { alert70: false, alert90: false, alert100: false };
      if (cloudUsage >= 100) {
        setOpenWarnModal(true);

        storageAlert = { alert70: false, alert90: false, alert100: true };
      } else if (cloudUsage >= 90) {
        if (!storageAlert.alert90) {
          setOpenWarnModal(true);
        }
        storageAlert = { alert70: false, alert90: true, alert100: false };
      } else if (cloudUsage >= 70) {
        if (!storageAlert.alert70) {
          setOpenWarnModal(true);
        }
        storageAlert = { alert70: true, alert90: false, alert100: false };
      } else {
        storageAlert = { alert70: false, alert90: false, alert100: false };
      }
      localStorage.setItem("pitta-storage-alert", JSON.stringify(storageAlert));
    }
  }, [cloudUsage, cloudVodList, preValue, value]);

  const getHeaderCount = () => {
    if (loading && !type) {
      return "";
    }
    if (value === 0) {
      return (
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {getLength()}
        </Typography>
      );
    } else if ((value === 1 || value === 2) && cloudVodList) {
      const per = (cloudVodList.s3_usage / cloudVodList.s3_usage_limit) * 100;
      let bgColor = LightColors.primary["7"];
      if (per >= 90) {
        bgColor = LightColors.secondary["11"];
      } else if (per >= 70) {
        bgColor = LightColors.secondary["17"];
      }
      return (
        <div>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >{`${cloudVodList.s3_usage}/${cloudVodList.s3_usage_limit} GB used`}</Typography>
          <div className={classes.usedProgDiv}>
            <div
              className={classes.usedBg}
              style={{
                width: `${per}%`,
                backgroundColor: bgColor,
              }}
            />
          </div>
        </div>
      );
    }
  };

  const getLength = useCallback(() => {
    if (loading && !type) {
      return 10;
    }
    return currentVODs.length;
  }, [loading, type, currentVODs.length]);

  const clearVOD = useCallback(() => {
    setPlayVOD(undefined);
    setAccels([]);
    setTimestamp(undefined);
    setLocation(undefined);
    setLocations([]);
    setSelectedVODs([]);
    setSelectMode(false);
    setAllSelect(false);
  }, []);

  const changePlayVOD = useCallback(
    (cam: ICameraInfo, vod: IVOD, rowCount: number, indx: number) => {
      if (!_.isEqual(vod, playVOD)) {
        clearVOD();
        // console.log("changePlayVOD", cam, vod, quality);
        if (value === 0) {
          if (quality === "low" && vod.lowFilename) {
            dispatch(
              loadUrgentVODToken({
                psn: cam.psn,
                filename: vod.lowFilename,
              })
            );
          } else {
            dispatch(
              loadUrgentVODToken({
                psn: cam.psn,
                filename: vod.filename,
              })
            );
          }
        }
        setPlayVODIndx(indx);
        setPlayVOD(vod);
        setVodValue(value);
        if (rowCount === indx + 1) {
          setDisableNext(false);
          setDisablePrev(true);
        } else if (indx === 0) {
          setDisableNext(true);
          setDisablePrev(false);
        } else {
          setDisableNext(false);
          setDisablePrev(false);
        }
      }
    },
    [playVOD, clearVOD, value, quality, dispatch]
  );

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const rowCount = props.parent.props.rowCount;
      const rowIndx = rowCount - props.index - 1;
      let vod = currentVODs[rowCount - props.index - 1];
      if ((loading || !init.current) && !type) {
        return (
          <div
            key={props.key}
            className={classes.fileListDiv}
            style={props.style}
          >
            <FileListItem loading />
          </div>
        );
      } else if (vod) {
        // console.log("rowRenderer", vod);
        let filename = vod.filename;
        if (_.includes(AI_EVENTS, vod.eventCode) && vod.interior) {
          filename = vod.interior.filename;
        }
        if (!thmRequests[filename] && !thm[filename] && camera) {
          if (value === 0) {
            dispatch(updateThmRequest({ filename, requested: true }));
            dispatch(
              loadThumbnail({
                lb_server_name: camera.lb_server_name,
                lb_http_port: camera.lb_http_port,
                psn: camera.psn,
                filename,
              })
            );
          } else if (value === 1) {
            if (vod.thmName) {
              dispatch(
                loadCloudVODThm({
                  psn: camera.psn,
                  filename,
                  thmName: vod.thmName,
                })
              );
            }
          } else if (value === 2 && vod.thmRid) {
            dispatch(updateThmRequest({ filename, requested: true }));
            dispatch(
              loadEventThumbnail({
                psn: camera.psn,
                rid: vod.thmRid,
                filename,
              })
            );
          }
        }
        return (
          <div
            key={props.key}
            className={classes.fileListDiv}
            style={props.style}
            onClick={() => {
              if (free100Check) {
                setOpenExceedModal(true);
              } else {
                if (!ExceptionPSN && camera && vod) {
                  if (value === 0 && vod.front) {
                    changePlayVOD(camera, vod.front, rowCount, rowIndx);
                  } else {
                    changePlayVOD(camera, vod, rowCount, rowIndx);
                  }
                }
              }
            }}
          >
            <FileListItem
              t={t}
              img={thm[filename]}
              time={vod.time.format("HH:mm:ss YYYY-MM-DD")}
              fileType={vod.event}
              eventCode={vod.eventCode}
              locationType={value === 0 ? undefined : vod.direction}
              exp={vod.exp && moment(vod.exp).format("YYYY-MM-DD HH:mm:ss")}
              ref={buttonsRef.current[props.index]}
              selectMode={selectMode}
              checked={_.includes(selectedVODs, vod)}
              playing={playVOD?.filename === vod.filename}
              onChecked={(e) => {
                if (e.target.checked) {
                  setSelectedVODs((vods) => _.compact([...vods, vod]));
                } else {
                  setSelectedVODs((vods) => _.filter(vods, (v) => v !== vod));
                }
              }}
              onMore={(e) => {
                // console.log(buttonsRef.current);
                e.preventDefault();
                e.stopPropagation();
                setOnScroll(false);
                setAnchorRef(buttonsRef.current[props.index]);
                setCurrentVOD(vod);
                if (currentVOD === vod) {
                  setOpenCamMenu((o) => !o);
                } else {
                  setOpenCamMenu(true);
                }
              }}
            />
          </div>
        );
      }
      // console.log(props);
    },
    [
      currentVODs,
      loading,
      type,
      classes.fileListDiv,
      thmRequests,
      thm,
      camera,
      t,
      selectMode,
      selectedVODs,
      playVOD?.filename,
      value,
      dispatch,
      free100Check,
      ExceptionPSN,
      changePlayVOD,
      currentVOD,
    ]
  );

  const handleUpdateAccel = useCallback((acc: IAccel[], totalT: number) => {
    setAccels(acc);
    setTotalTimestamp(totalT);
  }, []);
  const handleUpdateGPS = useCallback((loc) => {
    setLocations(loc);
  }, []);

  const videoPlayer = useMemo(() => {
    return (
      <VideoPlayer
        mode={vodValue}
        quality={quality}
        camera={camera}
        vod={playVOD}
        requestPause={requestPause}
        updatedTimestamp={updatedTimestamp}
        // screenMode={theater ? "wide" : screenMode}
        onResize={(width, height) => setListHeight(height)}
        disablePrev={disablePrev}
        disableNext={disableNext}
        showQuality={vodValue !== 2}
        disableQuality={vodValue === 0}
        disableUndefined={vodValue === 1 && playVOD === undefined}
        // theater={theater}
        noTheater
        onLoading={(load) => setVideoLoading(load)}
        onTheater={() => {
          setTheater((t) => !t);
        }}
        onPrev={() => {
          if (camera && playVODIndx !== undefined) {
            let list = currentVODs;
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const newIndx = playVODIndx + 1;
            const rowCount = list.length;
            if (newIndx < rowCount) {
              const vod = list[newIndx];
              changePlayVOD(camera, vod, rowCount, newIndx);
            }
          }
        }}
        onNext={() => {
          if (camera && playVODIndx !== undefined) {
            let list = currentVODs;
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const newIndx = playVODIndx - 1;
            const rowCount = list.length;
            if (newIndx > -1) {
              const vod = list[newIndx];
              changePlayVOD(camera, vod, rowCount, newIndx);
            }
          }
        }}
        onUpdateGPS={handleUpdateGPS}
        onUpdateAccel={handleUpdateAccel}
        onUpdateTime={(time) => setTimestamp(time)}
        onRequestFront={(fname) => {
          if (camera) {
            const filename = fname.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const frontFname = `${names[0]}_${names[1]}_${ev[0]}F`;
            let list = currentVODs;
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const rowCount = list.length;

            if (vodValue === 0 && playVODIndx !== undefined) {
              const front = list[playVODIndx].front;
              if (front) {
                changePlayVOD(camera, front, rowCount, playVODIndx);
              }
            } else {
              const frontVODIndx = _.findIndex(
                list,
                (v) => v.filename.indexOf(frontFname) > -1
              );
              if (frontVODIndx > -1) {
                const vod = list[frontVODIndx];
                changePlayVOD(camera, vod, rowCount, frontVODIndx);
              }
            }
          }
        }}
        onRequestRear={(fname) => {
          if (camera) {
            const filename = fname.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const rearFname = `${names[0]}_${names[1]}_${ev[0]}R`;
            let list = currentVODs;
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const rowCount = list.length;
            console.log("onRequestRear", fname, list, playVODIndx);
            if (vodValue === 0 && playVODIndx !== undefined) {
              const rear = list[playVODIndx].rear;
              if (rear) {
                changePlayVOD(camera, rear, rowCount, playVODIndx);
              }
            } else {
              const rearVODIndx = _.findIndex(
                list,
                (v) => v.filename.indexOf(rearFname) > -1
              );
              if (rearVODIndx > -1) {
                const vod = list[rearVODIndx];
                changePlayVOD(camera, vod, rowCount, rearVODIndx);
              }
            }
          }
        }}
        onRequestInterior={(fname) => {
          if (camera) {
            const filename = fname.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const interiorFname = `${names[0]}_${names[1]}_${ev[0]}I`;
            let list = currentVODs;
            // if (vodValue === 1) {
            //   list = cloudVodList?.filelist ?? [];
            // } else if (vodValue === 2) {
            //   list = eventVodList;
            // }
            const rowCount = list.length;
            if (vodValue === 0 && playVODIndx !== undefined) {
              const interior = list[playVODIndx].interior;
              if (interior) {
                changePlayVOD(camera, interior, rowCount, playVODIndx);
              }
            } else {
              const interiorVODIndx = _.findIndex(
                list,
                (v) => v.filename.indexOf(interiorFname) > -1
              );
              if (interiorVODIndx > -1) {
                const vod = list[interiorVODIndx];
                changePlayVOD(camera, vod, rowCount, interiorVODIndx);
              }
            }
          }
        }}
        onChangeQuality={(q) => setQuality(q)}
      />
    );
  }, [
    vodValue,
    quality,
    camera,
    playVOD,
    requestPause,
    updatedTimestamp,
    disablePrev,
    disableNext,
    handleUpdateGPS,
    handleUpdateAccel,
    playVODIndx,
    changePlayVOD,
    currentVODs,
  ]);

  const handleFilterClose = useCallback(() => {
    setFilterEventN(appliedEventN);
    setFilterEventE(appliedEventE);
    setFilterEventP(appliedEventP);
    setFilterEventM(appliedEventM);
    setOpenFilter(false);
  }, [appliedEventN, appliedEventE, appliedEventP, appliedEventM]);

  const enabledDays = useMemo(() => {
    let vods: IVOD[] = [];
    if (value === 0) {
      vods = vodList;
    } else if (value === 1) {
      vods = cloudVodList?.filelist ?? [];
    } else if (value === 2) {
      vods = eventVodList;
    }
    return _.chain(vods)
      .map((vod) => vod.time.format("YYYYMMDD"))
      .uniq()
      .value();
  }, [cloudVodList?.filelist, eventVodList, value, vodList]);

  const renderFilterDrawer = useCallback(() => {
    return (
      <Drawer anchor="right" open={openFilter}>
        <div className={classes.filterDrawerDiv}>
          <div className={classes.filterTitleDiv}>
            <Typography category="Default" variant="H6">
              {t("Filter")}
            </Typography>
            <IconButton onClick={handleFilterClose} style={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </div>

          <div
            className={classes.filterListDiv}
            style={{ flexGrow: 1, overflow: "auto" }}
          >
            <div className={classes.filterItemDiv}>
              <Typography category="Default" variant="BodyBold">
                {t("Video types")}
              </Typography>
              <div
                className={classes.radioFormControl}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventN}
                      onChange={(_e, checked) => setFilterEventN(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Normal")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventE}
                      onChange={(_e, checked) => setFilterEventE(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Event")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventP}
                      onChange={(_e, checked) => setFilterEventP(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Parking")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.radioBtn}
                  control={
                    <CheckBox
                      checked={filterEventM}
                      onChange={(_e, checked) => setFilterEventM(checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Manual")}
                    </Typography>
                  }
                />
              </div>
            </div>
            <DateRangePicker
              variant="range"
              range={filterDate}
              enabledDays={enabledDays}
              onChangeDay={(range) =>
                setFilterDate(range.range ?? { from: null, to: null })
              }
            />
          </div>

          <div className={classes.filterBtnDiv}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: theme.spacing(2) }}
              disabled={
                filterEventN &&
                filterEventE &&
                filterEventP &&
                filterEventM &&
                !filterDate.from &&
                !filterDate.to
              }
              onClick={() => {
                setFilterEventN(true);
                setFilterEventE(true);
                setFilterEventP(true);
                setFilterEventM(true);
                setFilterDate({ from: null, to: null });
              }}
            >
              {t("Clear")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAppliedEventN(filterEventN);
                setAppliedEventE(filterEventE);
                setAppliedEventP(filterEventP);
                setAppliedEventM(filterEventM);
                setAppliedDate(filterDate);
                setOpenFilter(false);
              }}
              disabled={
                filterEventN === appliedEventN &&
                filterEventE === appliedEventE &&
                filterEventP === appliedEventP &&
                filterEventM === appliedEventM &&
                filterDate.from === appliedDate.from &&
                filterDate.to === appliedDate.to
              }
            >
              {t("OK")}
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }, [
    openFilter,
    classes.filterDrawerDiv,
    classes.filterTitleDiv,
    classes.filterListDiv,
    classes.filterItemDiv,
    classes.radioFormControl,
    classes.radioBtn,
    classes.filterBtnDiv,
    t,
    handleFilterClose,
    filterEventN,
    filterEventE,
    filterEventP,
    filterEventM,
    filterDate,
    enabledDays,
    theme,
    appliedEventN,
    appliedEventE,
    appliedEventP,
    appliedEventM,
    appliedDate.from,
    appliedDate.to,
  ]);

  const downloadModalContentMarkup = useMemo(() => {
    let hasFront = false;
    let hasRear = false;
    let hasInterior = false;
    if (downloadMode === "single") {
      hasFront = !!currentVOD?.hasFront;
      hasRear = !!currentVOD?.hasRear;
      hasInterior = !!currentVOD?.hasInterior;
    } else {
      hasFront = _.chain(selectedVODs)
        .map((v) => v.hasFront)
        .some()
        .value();
      hasRear = _.chain(selectedVODs)
        .map((v) => v.hasRear)
        .some()
        .value();
      hasInterior = _.chain(selectedVODs)
        .map((v) => v.hasInterior)
        .some()
        .value();
    }
    return (
      <>
        {value === 0 && (
          <div className={classes.subTitle}>
            <Typography category="Default" variant="Small">
              {t("Video quality")}
            </Typography>
          </div>
        )}
        <RadioGroup
          aria-label="quality"
          name="quality"
          value={downloadQuality}
          onChange={(e) =>
            setDownloadQuality(e.target.value as VideoQualityType)
          }
        >
          <RadioButton
            value="low"
            label={t("Quick play file_")}
            disabled={downloadMode === "single" && !currentVOD?.hasLow}
          />
          <RadioButton
            value="original"
            label={t("Original file")}
            disabled={
              value === 2 ||
              (downloadMode === "single" && !currentVOD?.hasOriginal) ||
              (downloadMode === "multiple" &&
                _.chain(selectedVODs)
                  .map((vod) => vod.hasOriginal)
                  .compact()
                  .value().length !== selectedVODs.length)
            }
          />
        </RadioGroup>
        {value === 0 && (
          <>
            <div className={classes.typeTitleDiv}>
              <Typography category="Default" variant="Small">
                {t("Video type")}
              </Typography>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                classes={{
                  root: clsx(classes.formControlLabel, classes.checkMargin),
                }}
                control={
                  <CheckBox
                    className={classes.checkBoxSpacing}
                    color="primary"
                    checked={!!_.includes(downloadDirections, "Front")}
                    disabled={!hasFront}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setDownloadDirections(
                          _.uniq([...downloadDirections, "Front"])
                        );
                      } else {
                        setDownloadDirections(
                          _.filter(downloadDirections, (d) => d !== "Front")
                        );
                      }
                    }}
                  />
                }
                label={t("Front")}
              />
              <FormControlLabel
                classes={{
                  root: clsx(classes.formControlLabel, classes.checkMargin),
                }}
                control={
                  <CheckBox
                    className={classes.checkBoxSpacing}
                    color="primary"
                    checked={!!_.includes(downloadDirections, "Rear")}
                    disabled={!hasRear}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setDownloadDirections(
                          _.uniq([...downloadDirections, "Rear"])
                        );
                      } else {
                        setDownloadDirections(
                          _.filter(downloadDirections, (d) => d !== "Rear")
                        );
                      }
                    }}
                  />
                }
                label={t("Rear")}
              />
              <FormControlLabel
                classes={{ root: classes.formControlLabel }}
                control={
                  <CheckBox
                    className={classes.checkBoxSpacing}
                    color="primary"
                    checked={!!_.includes(downloadDirections, "Interior")}
                    disabled={!hasInterior}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setDownloadDirections(
                          _.uniq([...downloadDirections, "Interior"])
                        );
                      } else {
                        setDownloadDirections(
                          _.filter(downloadDirections, (d) => d !== "Interior")
                        );
                      }
                    }}
                  />
                }
                label={t("Interior")}
              />
            </div>
          </>
        )}
      </>
    );
  }, [
    classes.checkBoxSpacing,
    classes.checkMargin,
    classes.formControlLabel,
    classes.subTitle,
    classes.typeTitleDiv,
    currentVOD?.hasFront,
    currentVOD?.hasInterior,
    currentVOD?.hasLow,
    currentVOD?.hasOriginal,
    currentVOD?.hasRear,
    downloadDirections,
    downloadMode,
    downloadQuality,
    selectedVODs,
    t,
    value,
  ]);

  useEffect(() => {
    const thres = 560;
    const handleResize = (e: UIEvent) => {
      if (window.innerHeight - 2 * 188 - 64 > thres) {
        setScreenMode("default");
      } else {
        setScreenMode("wide");
      }
    };
    window.addEventListener("resize", handleResize);
    if (window.innerHeight - 2 * 188 - 64 > thres) {
      setScreenMode("default");
    } else {
      setScreenMode("wide");
    }
    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderList = useCallback(() => {
    if (listHeight && tabRef.current && filterRef.current && listRef.current) {
      let height = 387;
      if (!mobile) {
        height = theater
          ? 400 -
            tabRef.current.clientHeight -
            filterRef.current.clientHeight -
            5
          : listHeight -
            tabRef.current.clientHeight -
            filterRef.current.clientHeight -
            (selectMode ? 60 : 4);
      }
      if (getLength() > 0 || loading || !init.current) {
        return (
          <List
            className={classes.playList}
            width={listRef.current.clientWidth}
            height={height}
            rowHeight={72}
            rowCount={getLength()}
            rowRenderer={rowRenderer}
            overscanRowCount={5}
            onScroll={() => {
              setOnScroll(true);
              setOpenCamMenu(false);
            }}
          />
        );
      } else {
        return (
          <div
            className={clsx(classes.listEmptyDiv, {
              [classes.listEmptyDivMenuOpen]: openMenu,
              [classes.listEmptyDivMenuClose]: !openMenu,
            })}
            style={{ height }}
          >
            <Typography category="Default" variant="BodyBold">
              {t("No videos yet")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              style={{ textAlign: "center" }}
            >
              {t("Your videos will_")}
            </Typography>
          </div>
        );
      }
    }
  }, [
    classes.listEmptyDiv,
    classes.listEmptyDivMenuClose,
    classes.listEmptyDivMenuOpen,
    classes.playList,
    getLength,
    listHeight,
    loading,
    mobile,
    openMenu,
    rowRenderer,
    selectMode,
    t,
    theater,
  ]);

  const uploadCloud = useCallback(
    (vod: IVOD) => {
      setOpenCamMenu(false);
      if (camera) {
        const cam = _.chain(cameraList?.DeviceListInfo)
          .map((c) => c.device)
          .find((c) => c.psn === camera.psn)
          .value();
        if (cam) {
          dispatch(
            uploadVODFile({
              camera: cam,
              filename: vod.filename,
            })
          );
        }
      }
    },
    [camera, cameraList?.DeviceListInfo, dispatch]
  );

  const handleCamDownloadVOD = useCallback(
    async (psn: string, vod: IVOD) => {
      if (email && loginInfo) {
        let filename = vod.filename;
        if (downloadQuality === "low" && vod.lowFilename) {
          filename = vod.lowFilename;
        }
        // const token = tokens[filename];
        const res = await getVODToken(
          email,
          loginInfo.user_token,
          psn,
          filename
        );
        const { response } = res.data as {
          resultcode: RESULT_CODE;
          response: IVODToken;
        };

        dispatch(successLoadVODToken({ filename, token: response }));
        let linkUrl = getLink(vod, response, downloadQuality);
        if (linkUrl) return handleDownload(linkUrl, filename);
      }
    },
    [dispatch, email, getLink, handleDownload, loginInfo, downloadQuality]
  );

  const downloadMultipleVOD = useCallback(
    async (vods: ICameraVOD[]) => {
      if (
        planName === "Free plan" &&
        userUsage &&
        userUsage.remoteVideoPlayBackAndDownLoad + vods.length >
          userUsage.LimitVOD
      ) {
        setOpenExceedModal(true);
      } else {
        await _.reduce(
          vods,
          async (prevPromise, vod) => {
            await prevPromise;
            if (value === 0 && camera) {
              return handleCamDownloadVOD(camera.psn, vod);
            } else if (value === 1) {
              const resp = await getCloudLink(vod, downloadQuality);
              if (resp) {
                const {
                  resultcode,
                  response: { presignedURL },
                } = resp.data as {
                  resultcode: RESULT_CODE;
                  response: { presignedURL: string };
                };
                if (resultcode === "BC_ERR_OK") {
                  handleDownload(presignedURL);
                }
              }
            } else if (value === 2) {
              const resp = await getEventLink(vod);
              if (resp) {
                handleDownload(resp.data["fileInfo"]["url"]);
              }
            }
            return Promise.resolve();
          },
          Promise.resolve()
        );

        dispatch(loadUsageInfo());
      }
    },
    [
      camera,
      dispatch,
      downloadQuality,
      getCloudLink,
      getEventLink,
      handleCamDownloadVOD,
      handleDownload,
      planName,
      userUsage,
      value,
    ]
  );

  const downloadSingleVOD = useCallback(async () => {
    if (
      planName === "Free plan" &&
      userUsage &&
      userUsage.remoteVideoPlayBackAndDownLoad >= userUsage.LimitVOD
    ) {
      setOpenExceedModal(true);
    } else {
      if (camera && currentVOD) {
        if (value === 0) {
          if (downloadDirections[0] === "Front" && currentVOD.front) {
            await handleCamDownloadVOD(camera.psn, currentVOD.front);
          }
          if (downloadDirections[0] === "Rear" && currentVOD.rear) {
            await handleCamDownloadVOD(camera.psn, currentVOD.rear);
          }
          if (downloadDirections[0] === "Interior" && currentVOD.interior) {
            await handleCamDownloadVOD(camera.psn, currentVOD.interior);
          }
        } else if (value === 1) {
          const resp = await getCloudLink(currentVOD, downloadQuality);
          if (resp) {
            const {
              resultcode,
              response: { presignedURL },
            } = resp.data as {
              resultcode: RESULT_CODE;
              response: { presignedURL: string };
            };
            if (resultcode === "BC_ERR_OK") {
              await handleDownload(presignedURL);
            }
          }
        } else if (value === 2) {
          const resp = await getEventLink(currentVOD);
          if (resp) {
            await handleDownload(resp.data["fileInfo"]["url"]);
          }
        }
        dispatch(loadUsageInfo());
      }
    }
  }, [
    camera,
    currentVOD,
    dispatch,
    downloadDirections,
    downloadQuality,
    getCloudLink,
    getEventLink,
    handleCamDownloadVOD,
    handleDownload,
    planName,
    userUsage,
    value,
  ]);

  const moveToCloud = useCallback(
    (vods: IVOD[]) => {
      if (camera) {
        dispatch(
          moveEventVODFiles({
            psn: camera.psn,
            rids: _.chain(vods)
              .map((vod) => vod.rid)
              .compact()
              .value(),
          })
        );
      }
    },
    [camera, dispatch]
  );

  return (
    <div className={clsx(classes.root, className)} ref={rootDivRef}>
      <div
        className={clsx(classes.topContainerDiv, {
          [classes.topContainerDivMenuOpen]: openMenu,
          [classes.topContainerDivMenuClose]: !openMenu,
        })}
      >
        <div
          className={clsx(classes.playDiv, {
            [classes.playDivMenuOpen]: openMenu,
            [classes.playDivMenuClose]: !openMenu,
            [classes.playDivTheater]: theater,
          })}
        >
          {videoPlayer}
        </div>
        <div
          className={clsx(classes.listDiv, {
            [classes.listDivTheater]: theater,
          })}
        >
          <div className={classes.listBorder}>
            <Tabs value={value} className={classes.tabDiv} ref={tabRef}>
              <Tab
                label={t("Camera")}
                disabled={camera?.active === "off" || !cameraVodPerm}
                onClick={() => {
                  // clearVOD();
                  setValue(0);
                  setPreValue(value);
                  setRequestPause({});
                  // setQuality("low");
                }}
              />
              <Tab
                label={t("Cloud")}
                disabled={!cloudVodPerm}
                onClick={() => {
                  // clearVOD();
                  setValue(1);
                  setPreValue(value);
                  setRequestPause({});
                  // setQuality("low");
                }}
              />
              <Tab
                disabled={ExceptionPSN}
                label={t("Live event upload")}
                onClick={() => {
                  // clearVOD();
                  setValue(2);
                  setPreValue(value);
                  setRequestPause({});
                  // setQuality("low");
                }}
              />
            </Tabs>
            <div className={classes.filterDiv} ref={filterRef}>
              <div style={{ display: "flex" }}>
                {selectMode && (
                  <IconButton
                    className={classes.selectCloseBtn}
                    onClick={() => {
                      setSelectedVODs([]);
                      setSelectMode(false);
                      setAllSelect(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    style={{ marginLeft: selectMode ? 0 : 3 }}
                  >
                    {selectMode
                      ? `${selectedVODs.length}/${getLength()}`
                      : getHeaderCount()}
                  </Typography>
                </div>
              </div>
              <div>
                {!selectMode && (
                  <Tooltip
                    disableTouchListener={mobile}
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("Filter")}
                      </Typography>
                    }
                  >
                    <IconButton
                      className={classes.filterBtn}
                      onClick={() => setOpenFilter(true)}
                    >
                      <div style={{ position: "relative" }}>
                        <FilterListIcon style={{ display: "block" }} />
                        {filterApplied && (
                          <div
                            style={{
                              width: 6,
                              height: 6,
                              borderRadius: 3,
                              backgroundColor: LightColors.secondary["11"],
                              position: "absolute",
                              top: -3,
                              right: -3,
                            }}
                          />
                        )}
                      </div>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  disableTouchListener={mobile}
                  title={
                    <Typography category="Default" variant="Caption">
                      {t("Multi")}
                    </Typography>
                  }
                >
                  <IconButton
                    className={classes.filterBtn}
                    onClick={() => {
                      setSelectMode(true);
                      handleAllSelect();
                    }}
                  >
                    <DoneAllIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div ref={listRef} style={{ flexGrow: 1 }}>
              {renderList()}
            </div>
            {selectMode && (
              <BottomNavigation className={classes.actionDiv} showLabels>
                {value === 0 && (
                  <BottomNavigationAction
                    classes={{
                      root: classes.actionRoot,
                      wrapper: classes.actionWrapper,
                      label: classes.actionLabel,
                    }}
                    disabled={
                      selectedVODs.length !== 1 ||
                      !uploadVodPerm ||
                      ExceptionPSN
                    }
                    label={t("Upload")}
                    icon={<CloudUploadIcon />}
                    disableRipple
                    onClick={() => {
                      if (cloudUsage >= 100) {
                        setOpenWarnModal(true);
                      } else {
                        if (selectedVODs[0]) {
                          // uploadCloud(selectedVODs[0]);
                          setCurrentVOD(selectedVODs[0]);
                          setUploadDirection("Front");
                          setOpenUploadModal(true);
                        }
                      }
                    }}
                  />
                )}
                <BottomNavigationAction
                  classes={{
                    root: classes.actionRoot,
                    wrapper: classes.actionWrapper,
                    label: classes.actionLabel,
                  }}
                  disabled={
                    selectedVODs.length === 0 ||
                    !downloadVodPerm ||
                    ExceptionPSN
                  }
                  label={t("Download")}
                  icon={<SaveAltIcon />}
                  disableRipple
                  onClick={async () => {
                    setDownloadDirections(["Front"]);
                    setDownloadQuality("low");
                    setDownloadMode("multiple");
                    setOpenDownloadModal(true);
                  }}
                />
                {value === 2 && (
                  <BottomNavigationAction
                    classes={{
                      root: classes.actionRoot,
                      wrapper: classes.actionWrapper,
                      label: classes.actionLabel,
                    }}
                    disabled={selectedVODs.length === 0 || !moveToCloudVodPerm}
                    label={t("Move to Cloud")}
                    icon={<ArrowForwardIcon />}
                    disableRipple
                    onClick={() => {
                      moveToCloud(selectedVODs);
                    }}
                  />
                )}
                {value !== 0 && (
                  <BottomNavigationAction
                    classes={{
                      root: classes.actionRoot,
                      wrapper: classes.actionWrapperRed,
                      label: classes.actionLabel,
                    }}
                    disabled={selectedVODs.length === 0 || !deleteVodPerm}
                    label={t("Delete")}
                    icon={<DeleteIcon />}
                    disableRipple
                    onClick={() => setOpenDeleteModal(true)}
                  />
                )}
              </BottomNavigation>
            )}
          </div>
        </div>
        <div
          className={clsx(classes.mapDiv, {
            [classes.mapDivMenuOpen]: openMenu,
            [classes.mapDivMenuClose]: !openMenu,
            [classes.mapDivWideMenuOpen]: screenMode === "wide" && openMenu,
            [classes.mapDivWideMenuClose]: screenMode === "wide" && !openMenu,
            [classes.mapDivTheater]: theater,
          })}
        >
          <LocationMap
            showHeading
            location={
              videoSettings?.["UseGpsInfo"] === "0" ? undefined : location
            }
            trackMarker={!!location}
            fullscreenIcon
            noMarker={!playVOD}
          />
          {playVOD && videoSettings?.["UseGpsInfo"] === "0" && (
            <div className={classes.loadingDiv}>
              <Typography
                category="Default"
                variant="BodyBold"
                htmlColor={LightColors.primary["0"]}
              >
                {t("GPS Location recording off")}
              </Typography>
            </div>
          )}
          {videoLoading && (
            <div className={classes.loadingDiv}>
              <CircularProgress
                className={classes.circularLoading}
                size={48}
                thickness={5}
              />
            </div>
          )}
        </div>

        {theater && <div className={classes.theaterBlankDiv} />}

        <div
          className={clsx(classes.gSensorDiv, {
            [classes.gSensorDivMenuOpen]: openMenu,
            [classes.gSensorDivMenuClose]: !openMenu,
            [classes.gSensorDivWideMenuOpen]: screenMode === "wide" && openMenu,
            [classes.gSensorDivWideMenuClose]:
              screenMode === "wide" && !openMenu,
            [classes.gSensorDivTheaterMenuOpen]: theater && openMenu,
            [classes.gSensorDivTheaterMenuClose]: theater && !openMenu,
          })}
        >
          <GSensorPlayer
            vod={playVOD}
            accels={accels}
            timestamp={timestamp}
            totalTimestamp={totalTimestamp}
            loading={videoLoading}
            onChangeTime={(t) => setUpdatedTimestamp(t)}
          />
        </div>
      </div>

      {mobile && (
        <MobileMenu open={openCamMenu} onClose={() => setOpenCamMenu(false)}>
          <WebMenuItem
            disabled={!downloadVodPerm || ExceptionPSN}
            className={classes.mobileMemuItem}
            startIcon={<SaveAltIcon fontSize="small" />}
            onClick={async (e) => {
              setOpenCamMenu(false);
              setDownloadDirections(["Front"]);
              setDownloadQuality("low");
              setDownloadMode("single");
              if (downloadQuality === "low" && !currentVOD?.hasLow) {
                setDownloadQuality("original");
              }
              if (downloadQuality === "original" && !currentVOD?.hasOriginal) {
                setDownloadQuality("low");
              }
              setOpenDownloadModal(true);
            }}
          >
            {t("Download")}
          </WebMenuItem>
          {value === 0 && (
            <WebMenuItem
              disabled={!uploadVodPerm || ExceptionPSN}
              className={classes.mobileMemuItem}
              startIcon={<CloudUploadIcon fontSize="small" />}
              onClick={() => {
                setOpenCamMenu(false);
                if (cloudUsage >= 100) {
                  setOpenWarnModal(true);
                } else {
                  setUploadDirection("Front");
                  setOpenUploadModal(true);
                }
              }}
            >
              {t("Upload")}
            </WebMenuItem>
          )}
          {value === 2 && (
            <WebMenuItem
              disabled={!moveToCloudVodPerm}
              className={classes.mobileMemuItem}
              startIcon={<ArrowForwardIcon fontSize="small" />}
              onClick={() => {
                setOpenCamMenu(false);
                if (currentVOD) {
                  moveToCloud([currentVOD]);
                }
              }}
            >
              {t("Move to Cloud")}
            </WebMenuItem>
          )}
          {value !== 0 && <Divider className={classes.divider} />}
          {value !== 0 && (
            <WebMenuItem
              disabled={!deleteVodPerm}
              className={clsx(classes.mobileMemuItem, classes.delete)}
              startIcon={
                <DeleteIcon
                  fontSize="small"
                  htmlColor={LightColors.secondary["11"]}
                />
              }
              onClick={() => {
                setOpenCamMenu(false);
                setOpenDeleteModal(true);
              }}
            >
              {t("Delete")}
            </WebMenuItem>
          )}
        </MobileMenu>
      )}

      {!mobile && !onScroll && (
        <Menu
          open={openCamMenu}
          anchorEl={anchorRef?.current}
          onClickAway={() => setOpenCamMenu(false)}
          // modifiers={{ preventOverflow: { enabled: false } }}
          placement="bottom-end"
        >
          <WebMenuItem
            disabled={!downloadVodPerm || ExceptionPSN}
            startIcon={<SaveAltIcon fontSize="small" />}
            onClick={async (e) => {
              setOpenCamMenu(false);
              setDownloadDirections(["Front"]);
              setDownloadQuality("low");
              setDownloadMode("single");
              if (downloadQuality === "low" && !currentVOD?.hasLow) {
                setDownloadQuality("original");
              }
              if (downloadQuality === "original" && !currentVOD?.hasOriginal) {
                setDownloadQuality("low");
              }
              setOpenDownloadModal(true);
            }}
          >
            {t("Download")}
          </WebMenuItem>
          {value === 0 && (
            <WebMenuItem
              disabled={!uploadVodPerm || ExceptionPSN}
              startIcon={<CloudUploadIcon fontSize="small" />}
              onClick={() => {
                setOpenCamMenu(false);
                if (cloudUsage >= 100) {
                  setOpenWarnModal(true);
                } else {
                  setUploadDirection("Front");
                  setOpenUploadModal(true);
                }
              }}
            >
              {t("Upload")}
            </WebMenuItem>
          )}
          {value === 2 && (
            <WebMenuItem
              disabled={!moveToCloudVodPerm}
              startIcon={<ArrowForwardIcon fontSize="small" />}
              onClick={() => {
                setOpenCamMenu(false);
                if (currentVOD) {
                  moveToCloud([currentVOD]);
                }
              }}
            >
              {t("Move to Cloud")}
            </WebMenuItem>
          )}
          {value !== 0 && <Divider className={classes.divider} />}
          {value !== 0 && (
            <WebMenuItem
              disabled={!deleteVodPerm}
              className={classes.delete}
              startIcon={
                <DeleteIcon
                  fontSize="small"
                  htmlColor={LightColors.secondary["11"]}
                />
              }
              onClick={() => {
                setOpenCamMenu(false);
                setOpenDeleteModal(true);
              }}
            >
              {t("Delete")}
            </WebMenuItem>
          )}
        </Menu>
      )}

      <Modal
        open={openWarnModal}
        onClickPositive={() => {
          setOpenWarnModal(false);
        }}
        heading={
          cloudUsage >= 100
            ? t("Cloud Storage Full")
            : t("Cloud storage warning")
        }
        content={
          cloudUsage >= 100 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: t("Cloud storage usage_100").replace(
                  // eslint-disable-next-line no-control-regex
                  new RegExp("\n", "g"),
                  "<br/>"
                ),
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: t("Cloud storage usage_", {
                  a: cloudUsage.toFixed(0),
                  // eslint-disable-next-line no-control-regex
                }).replace(new RegExp("\n", "g"), "<br/>"),
              }}
            />
          )
        }
        RButton={t("OK")}
        actionClassName={classes.modalBottom}
        className={classes.modalWrap}
      />

      <Modal
        contentClassName={classes.modalContent}
        open={openDeleteModal}
        mobile={mobile}
        onClose={() => setOpenDeleteModal(false)}
        onClickNegative={() => setOpenDeleteModal(false)}
        onClickPositive={() => {
          // console.log(selectedVODs, currentVOD);
          if (camera) {
            if (selectedVODs.length > 0) {
              if (value === 1) {
                dispatch(
                  deleteCloudVODFile({
                    psn: camera.psn,
                    filenames: _.flattenDeep([
                      _.map(selectedVODs, (vod) => {
                        if (vod.hasOriginal && vod.hasLow) {
                          return [vod.filename, vod.lowFilename, vod.thmName];
                        } else if (vod.hasOriginal) {
                          return [vod.filename, vod.thmName];
                        } else {
                          return [vod.lowFilename ?? "", vod.thmName];
                        }
                      }),
                    ]),
                    vodCount: selectedVODs.length,
                  })
                );
              } else if (value === 2) {
                dispatch(
                  deleteEventVODFile({
                    psn: camera.psn,
                    rids: _.chain(selectedVODs)
                      .map((vod) => [vod.rid, vod.thmRid])
                      .flattenDeep()
                      .compact()
                      .value(),
                    fileLength: selectedVODs.length,
                  })
                );
              }
            } else if (currentVOD) {
              if (value === 1) {
                dispatch(
                  deleteCloudVODFile({
                    psn: camera.psn,
                    // filenames: [currentVOD.filename ],
                    filenames:
                      currentVOD.hasLow && currentVOD.hasOriginal
                        ? [
                            currentVOD.filename,
                            currentVOD.lowFilename ?? "",
                            currentVOD.thmName ?? "",
                          ]
                        : currentVOD.hasOriginal
                        ? [currentVOD.filename, currentVOD.thmName ?? ""]
                        : [
                            currentVOD.lowFilename ?? "",
                            currentVOD.thmName ?? "",
                          ],
                    vodCount: 1,
                  })
                );
              } else if (value === 2 && currentVOD.rid && currentVOD.thmRid) {
                dispatch(
                  deleteEventVODFile({
                    psn: camera.psn,
                    rids: [currentVOD.rid, currentVOD.thmRid],
                    fileLength: 1,
                  })
                );
              }
            }
          }
        }}
        heading={t("Delete")}
        close
        loading={
          loading &&
          (type === deleteEventVODFile.type || type === deleteCloudVODFile.type)
        }
        content={
          selectedVODs.length > 1
            ? `${t("Are you sure_videos")}`
            : `${t("Are you sure_video")}`
        }
        LButton={t("Cancel")}
        RButton={t("Delete")}
        Secondary
      />

      <ExceedModal
        open={openExceedModal}
        onClose={() => setOpenExceedModal(false)}
        onClickPositive={() => {
          setOpenExceedModal(false);
        }}
      />
      <Modal
        open={openUploadModal}
        close
        onClose={() => setOpenUploadModal(false)}
        onClickNegative={() => setOpenUploadModal(false)}
        onClickPositive={() => {
          if (uploadDirection === "Front" && currentVOD?.front) {
            uploadCloud(currentVOD.front);
          }
          if (uploadDirection === "Rear" && currentVOD?.rear) {
            uploadCloud(currentVOD.rear);
          }
          if (uploadDirection === "Interior" && currentVOD?.interior) {
            uploadCloud(currentVOD.interior);
          }
          setOpenUploadModal(false);
        }}
        heading={t("Videos to upload")}
        content={
          <RadioGroup
            aria-label="uploadDirection"
            name="uploadDirection"
            value={uploadDirection}
            onChange={(e) => setUploadDirection(e.target.value as LocationType)}
          >
            <RadioButton
              value="Front"
              label={t("Front")}
              disabled={!currentVOD?.hasFront}
            />
            <RadioButton
              value="Rear"
              label={t("Rear")}
              disabled={!currentVOD?.hasRear}
            />
            <RadioButton
              value="Interior"
              label={t("Interior")}
              disabled={!currentVOD?.hasInterior}
            />
          </RadioGroup>
        }
        RButton={t("Upload")}
        LButton={t("Cancel")}
        titleClassName={classes.modalTitle}
        className={classes.downloadModalRoot}
        actionClassName={classes.downloadModalbottom}
        contentClassName={clsx(
          classes.exceedModalContentDiv,
          mobile && classes.downloadMobileModal
        )}
        closeStyle={mobile ? classes.closeIconStyle : ""}
      />
      <Modal
        open={openDownloadModal}
        close
        onClose={() => setOpenDownloadModal(false)}
        onClickNegative={() => {
          setOpenDownloadModal(false);
        }}
        onClickPositive={() => {
          setOpenDownloadModal(false);
          if (value === 0 && downloadDirections.length > 1) {
            let s: ICameraVOD[] = [];
            if (downloadMode === "single") {
              if (
                _.includes(downloadDirections, "Front") &&
                currentVOD?.front
              ) {
                s.push(currentVOD.front);
              }
              if (_.includes(downloadDirections, "Rear") && currentVOD?.rear) {
                s.push(currentVOD.rear);
              }
              if (
                _.includes(downloadDirections, "Interior") &&
                currentVOD?.interior
              ) {
                s.push(currentVOD.interior);
              }
            } else {
              if (_.includes(downloadDirections, "Front")) {
                s = _.chain(selectedVODs)
                  .map((v) => v.front)
                  .compact()
                  .value();
              }
              if (_.includes(downloadDirections, "Rear")) {
                s = _.concat(
                  s,
                  _.chain(selectedVODs)
                    .map((v) => v.rear)
                    .compact()
                    .value()
                );
              }
              if (_.includes(downloadDirections, "Interior")) {
                s = _.concat(
                  s,
                  _.chain(selectedVODs)
                    .map((v) => v.interior)
                    .compact()
                    .value()
                );
              }
            }

            downloadMultipleVOD(s);
          } else if (downloadMode === "single") {
            downloadSingleVOD();
          } else {
            downloadMultipleVOD(selectedVODs);
          }
        }}
        heading={t("Set download options")}
        content={downloadModalContentMarkup}
        RButton={t("Download")}
        LButton={t("Cancel")}
        RButtonDisabled={value === 0 && downloadDirections.length === 0}
        titleClassName={classes.downloadModalTitle}
        className={classes.downloadModalRoot}
        actionClassName={classes.downloadModalbottom}
        contentClassName={clsx(
          classes.downloadModalContentDiv,
          mobile && classes.downloadMobileModal
        )}
        closeStyle={mobile ? classes.closeIconStyle : ""}
      />
      {renderFilterDrawer()}
    </div>
  );
};
