import { PortalProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Modal } from "@thingsw/pitta-design-system";
import React from "react";
import { Webviewer } from "../../contants/Breakpoints";
import { ICameraInfo } from "../../features/Camera/slice";
import { LiveViewPanel } from "../cameras/LiveViewPanel";

const useStyles = makeStyles((theme: Theme) => ({
  modalRoot: {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
  },
  modalContentDiv: {
    padding: theme.spacing(0, 0, 4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 14.625, 4),
    },
  },
  modalTitle: {
    boxShadow: "border-box",
    minHeight: 48,
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(2, 2, 2, 1.625) }
      : { padding: theme.spacing(2, 1.625, 2, 2) }),
    textOverflow: "ellipsis",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(2, 4, 2, 3.625) }
        : { padding: theme.spacing(2, 3.625, 2, 4) }),
    },
  },
  closeStyle: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      right: 28,
    },
  },
}));

interface LiveViewModalProps {
  open: boolean;
  camera?: ICameraInfo;
  isPublic?: boolean;
  onClose: () => void;
  container?: PortalProps["container"];
}

export const LiveViewModal = ({
  open,
  camera,
  isPublic,
  onClose,
  container,
}: LiveViewModalProps) => {
  const classes = useStyles();
  if (open && camera) {
    console.log("open modal");
    return (
      <Modal
        className={classes.modalRoot}
        contentClassName={classes.modalContentDiv}
        open={open}
        onClose={onClose}
        content={<LiveViewPanel isEvent camera={camera} isPublic={isPublic} />}
        heading="   "
        titleClassName={classes.modalTitle}
        closeStyle={classes.closeStyle}
        container={container}
        close
      />
    );
  }
  return <></>;
};
