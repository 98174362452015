import { Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { Webviewer } from "../contants/Breakpoints";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";

interface ScrollBarProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const ScrollBar = ({ children, style }: ScrollBarProps) => {
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  return mobile ? (
    <>{children}</>
  ) : (
    <SimpleBarReact style={{ height: "100%", overflowX: "hidden", ...style }}>
      {children}
    </SimpleBarReact>
  );
};
