import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  CheckBox,
  Fab,
  IconButton,
  LightColors,
  Modal,
  Polygon,
  Sliders,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import clsx from "clsx";
import { Webviewer } from "../../contants/Breakpoints";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";

import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import ClearIcon from "@material-ui/icons/Clear";
import RemoveIcon from "@material-ui/icons/Remove";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, ICameraInfo } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import _ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary } from "../Accordion";
import {
  addGeofence,
  deleteGeofence,
  GEOFENCE,
  IGeofence,
  loadGeofences,
  updateGeofence,
} from "../../features/Geofence/slice";
import { DrawingMode, FenceTypeCode, FenceTypeName } from "../../types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EmptyItems } from "../EmptyItems";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import { GeofenceListItem } from "./GeofenceListItem";
import { CameraMenu } from "../cameras/CameraMenu";
import { MobileCameraMenu } from "../MobileCameraMenu";
import { NoResults } from "../NoResults";
import { AutoSizer } from "react-virtualized";
import { Scrollbars } from "react-custom-scrollbars";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import { PSN650 } from "../../contants/Models";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  panelRootDiv: {
    /* Light mode/#FFFFFF ‧ 0 */
    background: LightColors.primary["0"],
    /* Shadow 2 ‧ Small UI elements */
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      transition: theme.transitions.create(["width", "height"]),
    },
  },
  panelRootDivOpen: {
    width: "100%",

    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: 320,
    },
  },
  rootClose: {
    width: 0,
  },
  panelDiv: {
    width: "100%",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: 320,
    },
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
    bottom: 0,
    transition: theme.transitions.create(["left", "right"]),
    outline: "none",
  },
  panelBodyDiv: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    overflow: "auto",
  },
  mainPanelDivClose: {
    ...(theme.direction === "rtl" ? { right: "-100%" } : { left: "-100%" }),
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      ...(theme.direction === "rtl" ? { right: -320 } : { left: -320 }),
    },
  },
  subPanelDivClose: {
    ...(theme.direction === "rtl" ? { right: "100%" } : { left: "100%" }),
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      ...(theme.direction === "rtl" ? { right: 320 } : { left: 320 }),
    },
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    justifyContent: "flex-start",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: "auto",
      justifyContent: "flex-end",
    },
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 57,
  },
  searchBtnOpen: {
    width: "100%",
    marginRight: 0,
    zIndex: 100,
  },
  fabMargin: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.5) }
      : { marginLeft: theme.spacing(0.5) }),
  },
  inputName: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 84,
    padding: theme.spacing(3, 2, 2, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  expandIcon: {
    padding: 0,
  },
  coDiv: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? {
          padding: theme.spacing(0, 0, 2, 2),
          marginRight: theme.spacing(7),
        }
      : {
          padding: theme.spacing(0, 2, 2, 0),
          marginLeft: theme.spacing(7),
        }),
    "&:last-child": {
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
  },
  menuTitle: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
    color: LightColors.primary["3"],
  },
  colorSelectDiv: {
    display: "flex",
    alignItems: "center",
  },
  colorSelected: {
    backgroundColor: LightColors.primary["7"],
  },
  colorSelector2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 28,
    height: 28,
    margin: theme.spacing(0.25, 0.5, 0.25, 0.25),
    borderRadius: "50%",
  },
  colorSelector1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: LightColors.primary["0"],
    borderRadius: "50%",
    width: 22,
    height: 22,
  },
  colorSelector: {
    width: 18,
    height: 18,
    borderRadius: 9,
    cursor: "pointer",
    margin: theme.spacing(0.25),
  },
  checkBoxLabel: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(-0.75) }
      : { marginLeft: theme.spacing(-0.75) }),
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  cameraListDiv: {
    padding: theme.spacing(2),
  },
  cameraListTitleDiv: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  cameraListTitle: {
    marginLeft: theme.spacing(1.75),
  },
  cameraCheckBoxLabel: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(-0.75) }
      : { marginLeft: theme.spacing(-0.75) }),
  },
  cameraDiv: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? {
          padding: theme.spacing(0.75, 4.75, 0.75, 0.75),
        }
      : {
          padding: theme.spacing(0.75, 0.75, 0.75, 4.75),
        }),
  },
  cameraModelText: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(3.125) }
      : { marginLeft: theme.spacing(3.125) }),
  },
  btnDiv: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    display: "flex",
    justifyContent: "flex-end",
    "&>button": {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(2) }
        : { marginLeft: theme.spacing(2) }),
    },
  },
  drawToolDiv: {
    display: "flex",
    backgroundColor: `${LightColors.primary["1"]}73`,
    borderRadius: 4,
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(0.5) }
        : { marginLeft: theme.spacing(0.5) }),
    },
  },
  drawToolFab: {
    margin: theme.spacing(0.5),
  },
  disabledAddDiv: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: LightColors.primary["0"],
    opacity: 1 - 0.35,
  },
  disabledIcon: {
    opacity: 0.33,
  },
  polygonIcon: {
    "& path": {
      fill: "none",
      stroke: LightColors.primary["1"],
    },
    "&:hover  path": {
      fill: "none",
      stroke: LightColors.primary["7"],
    },
  },
  toolSelected: {
    backgroundColor: LightColors.primary["7"],

    "& path": {
      fill: LightColors.primary["0"],
    },
    "&:hover path": {
      fill: LightColors.primary["0"],
    },
    "&:hover,&:focus": {
      backgroundColor: LightColors.primary["7"],
    },
  },
  polygonSelected: {
    backgroundColor: LightColors.primary["7"],

    "& path": {
      fill: "none",
      stroke: LightColors.primary["0"],
    },
    "&:hover path": {
      fill: "none",
      stroke: LightColors.primary["0"],
    },
    "&:hover,&:focus": {
      backgroundColor: LightColors.primary["7"],
    },
  },
  disabledPolygonToolDiv: {
    "& path": {
      fill: "none",
      stroke: `${LightColors.primary["1"]}43`,
    },
  },
  disabledSpan: {
    width: 36,
    height: 36,
  },
  floatingBtnDiv: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: -40 } : { right: -40 }),

    zIndex: 99,
  },
  floatingDrawingDiv: {
    position: "absolute",
    top: -48,
    right: 0,
    left: 0,
    zIndex: 99,
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      top: 0,
      ...(theme.direction === "rtl"
        ? { right: "unset", left: -312 }
        : { left: "unset", right: -312 }),
    },
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  listDiv: {
    display: "flex",
    height: "100%",
  },
  moreIcon: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(1) }
      : { marginLeft: theme.spacing(1) }),
  },
  camAllCheckDiv: {
    flex: 1,
  },
  camAllCheckHide: {
    width: 0,
    overflow: "hidden",
  },
  pdT: {
    paddingTop: theme.spacing(2),
  },
  disabledText: {
    opacity: 0.35,
    cursor: "default",
  },
}));

interface GeofencePanelProps {
  mode?: "view" | "add";
  disabledAdd?: boolean;
  drawingMode: DrawingMode;
  disableRedo: boolean;
  disableUndo: boolean;
  disableCancel: boolean;
  mobile?: boolean;
  selectedFence?: IGeofence;
  onChangeColor: (color: string) => void;
  onChangeOpacity: (opacity: number) => void;
  onUndo: () => void;
  onRedo: () => void;
  onCancel: () => void;
  onChangeDrawingMode: (mode: DrawingMode, geofence?: IGeofence) => void;
  onSave: (data: { mode: "add" | "update"; geofence: IGeofence }) => void;
  onFocus: (focused: boolean) => void;
}

export const GeofencePanel = ({
  mode,
  disabledAdd,
  drawingMode,
  disableRedo,
  disableUndo,
  disableCancel,
  mobile,
  selectedFence,
  onChangeColor,
  onChangeOpacity,
  onUndo,
  onRedo,
  onCancel,
  onChangeDrawingMode,
  onSave,
  onFocus,
}: GeofencePanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const cameraList = useSelector(
    (state: RootState) => state[CAMERA].cameraList
  );

  const {
    loading,
    type,
    geofences: origGeofence,
  } = useSelector((state: RootState) => state[GEOFENCE]);

  const bodyRef = useRef<any>(null);
  const viewMoreBtnRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<List>(null);

  const [listDiv, setListDiv] = useState<HTMLDivElement | null>(null);
  const [moreBtnRef, setMoreBtnRef] =
    useState<React.RefObject<HTMLButtonElement> | null>(null);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openGeofenceSearch, setGeofenceOpenSearch] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openPanel, setOpenPanel] = useState(true);
  const [openColorPanel, setOpenColorPanel] = useState(true);
  const [openNotiPanel, setOpenNotiPanel] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [opacity, setOpacity] = useState(50);
  const [selectedCamera, setSelectedCamera] = useState<ICameraInfo[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [color, setColor] = useState("#F21212");
  const [checkEnter, setCheckEnter] = useState(false);
  const [checkExit, setCheckExit] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [name, setName] = useState("");
  const [moreZone, setMoreZone] = useState<IGeofence>();
  const [currentZone, setCurrentZone] = useState<IGeofence>();
  const [showDetail, setShowDetail] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [geofenceSearchKey, setGeofenceSearchKey] = useState("");
  const [cameras, setCameras] = useState<ICameraInfo[]>([]);
  const [requestSearchClose, setRequestSearchClose] = useState({});
  const [geofences, setGeofences] = useState<IGeofence[]>([]);
  const [unallowed, setUnallowed] = useState(false);

  useEffect(() => {
    if (mobile) {
      const body = document.getElementById("body-container");
      body?.setAttribute("style", "overflow:hidden;");

      return () => {
        body?.setAttribute("style", "overflow:auto;");
      };
    }
  }, [mobile]);

  useEffect(() => {
    setGeofences(
      _.filter(origGeofence, (geo) =>
        geofenceSearchKey
          ? geo.fenceName
              .toLowerCase()
              .indexOf(geofenceSearchKey.toLowerCase()) > -1
          : true
      )
    );
  }, [geofenceSearchKey, origGeofence]);

  useEffect(() => {
    if (cameraList?.DeviceListInfo) {
      setCameras(
        _.chain(cameraList.DeviceListInfo)
          .filter((dev) =>
            searchKey
              ? dev.device.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.toLowerCase()) > -1 ||
                dev.device.model
                  .toLowerCase()
                  .indexOf(searchKey.toLowerCase()) > -1
              : true
          )
          .map((dev) => dev.device)
          .value()
      );
    }
  }, [cameraList, searchKey]);

  const reset = useCallback(() => {
    setCheckEnter(false);
    setCheckExit(false);
    setCheckPass(false);
    setSelectedCamera([]);
    setColor("#F21212");
    setName("");
    setOpacity(50);
    onCancel();
    setShowDetail(false);
    setSearchKey("");
    setOpenSearch(false);
    setRequestSearchClose({});
  }, [onCancel]);

  const initGeofence = useCallback(
    (fence: IGeofence) => {
      setCurrentZone(fence);
      setShowDetail(true);

      setCheckEnter(_.includes(fence.alarm, "Enter"));
      setCheckExit(_.includes(fence.alarm, "Exit"));
      setCheckPass(_.includes(fence.alarm, "Pass"));
      setSelectedCamera(
        _.filter(cameras, (dev) => _.includes(fence.psn, dev.psn))
      );
      setColor(fence.setShape.Color);
      setName(fence.fenceName);
      setOpacity(Math.round(fence.setShape.Transparency * 100));
      onChangeDrawingMode(FenceTypeName[fence.fenceType], fence);
    },
    [cameras, onChangeDrawingMode]
  );

  useEffect(() => {
    if (mode === "add") {
      setShowDetail(true);
      setOpenPanel(true);
    }
  }, [mode]);

  useEffect(() => {
    if (selectedFence) {
      initGeofence(selectedFence);
      setShowDetail(true);
      setOpenPanel(true);
    }
  }, [initGeofence, selectedFence]);

  useEffect(() => {
    if (mode === "add") {
      setCurrentZone(undefined);
      reset();
    }
  }, [mode, reset]);

  useEffect(() => {
    if (!loading && type === deleteGeofence.type) {
      setOpenDeleteModal(false);
      reset();
    }
    if (!loading && type === updateGeofence.type) {
      reset();
    }
  }, [reset, loading, type]);

  useEffect(() => {
    dispatch(loadGeofences());
  }, [dispatch]);

  useEffect(() => {
    if (type === addGeofence.type && !loading) {
      reset();
    }
  }, [reset, loading, type]);

  useEffect(() => {
    setIndeterminate(
      selectedCamera.length > 0 && cameras.length !== selectedCamera.length
    );
    setAllChecked(cameras.length === selectedCamera.length);
  }, [cameras.length, selectedCamera]);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const { index, style, key } = props;
      const fence = geofences[index];
      return (
        <div style={{ ...style }} key={key}>
          <GeofenceListItem
            geofence={fence}
            onClick={() => {
              initGeofence(fence);
            }}
            onClickMore={(ref) => {
              setMoreBtnRef(ref);
              setOpenMenu((o) => !o);
              setMoreZone(fence);
            }}
          />
        </div>
      );
    },
    [geofences, initGeofence]
  );

  const handleCloseCameraSearch = useCallback(() => {
    setSearchKey("");
    setOpenSearch(false);
  }, []);

  const cameraListMarkup = useMemo(() => {
    if (cameras.length === 0 && searchKey) {
      return <NoResults />;
    }
    return (
      <div>
        {_.map(cameras, (dev) => (
          <div key={dev.psn} className={classes.cameraDiv}>
            <FormControlLabel
              className={classes.cameraCheckBoxLabel}
              control={
                <CheckBox
                  disabled={_.includes(PSN650, dev.psn.substr(0, 4))}
                  color="primary"
                  checked={_.includes(
                    _.map(selectedCamera, (c) => c.psn),
                    dev.psn
                  )}
                  onChange={(e, checked) => {
                    if (checked) {
                      setSelectedCamera((c) => [...c, dev]);
                    } else {
                      setSelectedCamera((cams) =>
                        _.filter(cams, (c) => c.psn !== dev.psn)
                      );
                    }
                  }}
                />
              }
              label={
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ overflowWrap: "anywhere" }}
                >
                  {dev.dev_name}
                </Typography>
              }
            />
            <Typography
              category="Default"
              variant="Small"
              className={clsx(
                classes.cameraModelText,
                _.includes(PSN650, dev.psn.substr(0, 4)) && classes.disabledText
              )}
              htmlColor={LightColors.primary["2"]}
            >
              {dev.model}
            </Typography>
          </div>
        ))}
      </div>
    );
  }, [
    cameras,
    classes.cameraCheckBoxLabel,
    classes.cameraDiv,
    classes.cameraModelText,
    classes.disabledText,
    searchKey,
    selectedCamera,
  ]);

  useEffect(() => {
    console.log("clientHeight", listDiv?.clientHeight);
    listRef.current?.forceUpdateGrid();
  }, [listDiv?.clientHeight]);

  const handleScroll = useCallback((e) => {
    if (listRef.current) {
      const { scrollTop, scrollLeft } = e.target;
      const { Grid } = listRef.current;
      Grid?.handleScrollEvent({ scrollTop, scrollLeft });
    }
  }, []);

  const geofenceListMarkup = useMemo(() => {
    if (loading && type === loadGeofences.type) {
      return (
        <div className={classes.loadingDiv}>
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    }

    if (geofences.length === 0) {
      if (geofenceSearchKey) {
        return (
          <div className={classes.loadingDiv}>
            <NoResults />
          </div>
        );
      } else {
        return (
          <div className={classes.loadingDiv}>
            <EmptyItems mode="geofences" variant="small" />
          </div>
        );
      }
    }

    return (
      <div className={classes.listDiv} ref={(ref) => setListDiv(ref)}>
        <AutoSizer>
          {({ height, width }) => (
            <Scrollbars
              onScroll={handleScroll}
              style={{ height, width }}
              autoHide
              renderView={(props) => (
                <div
                  {...props}
                  style={{
                    marginLeft: theme.direction === "rtl" ? -17 : 0,
                    marginRight: theme.direction === "rtl" ? 0 : -17,
                    overflowY: "auto",
                  }}
                />
              )}
            >
              <List
                ref={listRef}
                width={width}
                height={height}
                rowCount={geofences.length}
                rowHeight={60}
                // overscanRowCount={5}
                rowRenderer={rowRenderer}
                style={
                  mobile ? {} : { overflowY: "visible", overflowX: "visible" }
                }
              />
            </Scrollbars>
          )}
        </AutoSizer>
      </div>
    );
  }, [
    classes.listDiv,
    classes.loadingDiv,
    geofenceSearchKey,
    geofences.length,
    handleScroll,
    loading,
    mobile,
    rowRenderer,
    theme.direction,
    type,
  ]);

  return (
    <div className={clsx(classes.root)} dir={theme.direction}>
      <div
        className={clsx(classes.panelRootDiv, {
          [classes.panelRootDivOpen]: openPanel,
          [classes.rootClose]: !openPanel,
        })}
      >
        <div
          className={clsx(classes.panelDiv, {
            [classes.mainPanelDivClose]: mode === "add" || showDetail,
          })}
        >
          <div className={clsx(classes.titleDiv)}>
            {!openGeofenceSearch && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <Typography category="Default" variant="H6">
                  {t("Geofences")}
                </Typography>
              </div>
            )}

            <Input
              inputID="geofencesSearch"
              placeholder={t("Search geofences")}
              startIcon={
                <Tooltip
                  disableTouchListener={mobile}
                  placement="top"
                  PopperProps={{
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "0, -12px",
                      },
                      flip: {
                        enabled: false,
                      },
                    },
                  }}
                  title={
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["0"]}
                    >
                      {t("search")}
                    </Typography>
                  }
                >
                  <SearchIcon />
                </Tooltip>
              }
              className={clsx(classes.searchBtn, {
                [classes.searchBtnOpen]: openGeofenceSearch,
              })}
              search
              dense
              variant="standard"
              onChange={(e) => setGeofenceSearchKey(e.target.value)}
              onOpenSearch={() => {
                const openEvent = new CustomEvent("drawer:open");
                document.dispatchEvent(openEvent);
                setGeofenceOpenSearch(true);
              }}
              onCloseSearch={() => {
                setGeofenceSearchKey("");
                setGeofenceOpenSearch(false);
              }}
            />
          </div>

          {geofenceListMarkup}
        </div>
        <div
          className={clsx(classes.panelDiv, {
            [classes.subPanelDivClose]: mode !== "add" && !showDetail,
          })}
        >
          <div className={classes.titleDiv}>
            <div
              style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
              }}
            >
              {currentZone && (
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    reset();
                    setShowDetail(false);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography
                category="Default"
                variant="H6"
                style={currentZone ? { margin: "0 16px" } : {}}
              >
                {currentZone ? t("Geofences") : t("Add geofence")}
              </Typography>
            </div>
          </div>
          <SimpleBarReact
            ref={bodyRef}
            className={classes.panelBodyDiv}
            style={disabledAdd ? { overflow: "hidden" } : {}}
          >
            <div className={classes.inputName}>
              <Input
                placeholder={t("Name")}
                label={t("Name")}
                value={name}
                onFocus={() => onFocus(true)}
                onBlur={() => onFocus(false)}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.value.length <= 16) {
                    if (e.target.value.indexOf("%") > -1) {
                      setUnallowed(true);
                    } else {
                      setUnallowed(false);
                    }
                    setName(e.target.value);
                  }
                }}
                error={unallowed}
                helperText={
                  unallowed ? t("Unallowed character detected") : undefined
                }
              />
              {mode !== "add" && (
                <IconButton
                  ref={viewMoreBtnRef}
                  style={{ padding: 0 }}
                  onClick={() => {
                    setMoreBtnRef(viewMoreBtnRef);
                    setOpenMenu(true);
                    setMoreZone(currentZone);
                  }}
                >
                  <MoreVertIcon className={classes.moreIcon} />
                </IconButton>
              )}
            </div>
            <Accordion
              elevation={0}
              expanded={openColorPanel}
              onChange={() => setOpenColorPanel((o) => !o)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className={classes.menuTitle}>
                  <FormatPaintIcon className={classes.menuIcon} />
                  <Typography category="Default" variant="BodyBold">
                    {t("Color and opacity")}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.coDiv}>
                  <Typography category="Default" variant="Body">
                    {t("Color")}
                  </Typography>
                  <div className={classes.colorSelectDiv}>
                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: color === "#F21212",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#F21212" }}
                          onClick={() => {
                            setColor("#F21212");
                            onChangeColor("#F21212");
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: color === "#F3890D",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#F3890D" }}
                          onClick={() => {
                            setColor("#F3890D");
                            onChangeColor("#F3890D");
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: color === "#FDF300",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#FDF300" }}
                          onClick={() => {
                            setColor("#FDF300");
                            onChangeColor("#FDF300");
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: color === "#12E812",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#12E812" }}
                          onClick={() => {
                            setColor("#12E812");
                            onChangeColor("#12E812");
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: color === "#0E0EF1",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#0E0EF1" }}
                          onClick={() => {
                            setColor("#0E0EF1");
                            onChangeColor("#0E0EF1");
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: color === "#201359",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#201359" }}
                          onClick={() => {
                            setColor("#201359");
                            onChangeColor("#201359");
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx(classes.colorSelector2, {
                        [classes.colorSelected]: color === "#E314E3",
                      })}
                    >
                      <div className={classes.colorSelector1}>
                        <div
                          className={classes.colorSelector}
                          style={{ backgroundColor: "#E314E3" }}
                          onClick={() => {
                            setColor("#E314E3");
                            onChangeColor("#E314E3");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.coDiv}>
                  <Typography category="Default" variant="Body">
                    {t("Opacity")}
                  </Typography>
                  <div className={classes.colorSelectDiv}>
                    <Sliders
                      min={0}
                      max={100}
                      value={opacity}
                      onChangeCommitted={(e, value) => {
                        onChangeOpacity((value as number) / 100);
                      }}
                      onChange={(e, value) => {
                        setOpacity(value as number);
                      }}
                    />
                    <Typography
                      category="Default"
                      variant="Body"
                      style={
                        theme.direction === "rtl"
                          ? { marginRight: 8 }
                          : { marginLeft: 8 }
                      }
                    >
                      {Math.round(opacity)}%
                    </Typography>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              expanded={openNotiPanel}
              onChange={() => setOpenNotiPanel((o) => !o)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className={classes.menuTitle}>
                  <NotificationImportantIcon className={classes.menuIcon} />
                  <Typography category="Default" variant="BodyBold">
                    {t("Notifications")}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.coDiv}>
                  {drawingMode !== "polyline" && (
                    <FormControlLabel
                      className={classes.checkBoxLabel}
                      control={
                        <CheckBox
                          color="primary"
                          checked={checkEnter}
                          onChange={(e, checked) => setCheckEnter(checked)}
                        />
                      }
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Entry")}
                        </Typography>
                      }
                    />
                  )}
                  {drawingMode !== "polyline" && (
                    <FormControlLabel
                      className={classes.checkBoxLabel}
                      control={
                        <CheckBox
                          color="primary"
                          checked={checkExit}
                          onChange={(e, checked) => setCheckExit(checked)}
                        />
                      }
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Exit")}
                        </Typography>
                      }
                    />
                  )}
                  {drawingMode === "polyline" && (
                    <FormControlLabel
                      className={classes.checkBoxLabel}
                      control={
                        <CheckBox
                          color="primary"
                          checked={checkPass}
                          onChange={(e, checked) => setCheckPass(checked)}
                        />
                      }
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Pass")}
                        </Typography>
                      }
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
            <div className={classes.cameraListDiv}>
              <div className={classes.cameraListTitleDiv}>
                <div
                  className={clsx({
                    [classes.camAllCheckDiv]: !openSearch,
                    [classes.camAllCheckHide]: openSearch,
                  })}
                >
                  <FormControlLabel
                    className={classes.cameraCheckBoxLabel}
                    control={
                      <CheckBox
                        color="primary"
                        indeterminate={indeterminate}
                        checked={allChecked}
                        onChange={(e, checked) => {
                          if (selectedCamera.length === 0) {
                            // setSelectedCamera(cameras);
                            setSelectedCamera(
                              _.filter(
                                cameras,
                                (dev) =>
                                  !_.includes(PSN650, dev.psn.substr(0, 4))
                              )
                            );
                          } else {
                            setSelectedCamera([]);
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        category="Default"
                        variant="Body"
                        className={classes.cameraListTitle}
                      >
                        {t("Cameras")}
                      </Typography>
                    }
                  />
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {selectedCamera.length}/{cameras.length}
                  </Typography>
                </div>
                <div
                  className={clsx({
                    [classes.camAllCheckDiv]: openSearch,
                  })}
                >
                  <Input
                    SecondaryColor
                    placeholder={t("search")}
                    startIcon={
                      <Tooltip
                        disableTouchListener={mobile}
                        placement="top"
                        PopperProps={{
                          modifiers: {
                            offset: {
                              enabled: true,
                              offset: "0, -12px",
                            },
                            flip: {
                              enabled: false,
                            },
                          },
                        }}
                        title={
                          <Typography
                            category="Default"
                            variant="Caption"
                            htmlColor={LightColors.primary["0"]}
                          >
                            {t("search")}
                          </Typography>
                        }
                      >
                        <SearchIcon />
                      </Tooltip>
                    }
                    onChange={(e) => setSearchKey(e.target.value)}
                    value={searchKey}
                    variant="standard"
                    search
                    dense
                    requestSearchClose={requestSearchClose}
                    onOpenSearch={() => setOpenSearch(true)}
                    onCloseSearch={handleCloseCameraSearch}
                  />
                </div>
              </div>
              {cameraListMarkup}
            </div>
            {disabledAdd && (
              <div
                className={classes.disabledAddDiv}
                style={
                  bodyRef.current
                    ? {
                        bottom: Math.min(bodyRef.current.scrollHeight, 0),
                      }
                    : {}
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            )}
          </SimpleBarReact>
          <div className={classes.btnDiv}>
            <Button
              variant="outlined"
              color="primary"
              disabled={
                loading &&
                (type === addGeofence.type || type === updateGeofence.type)
              }
              onClick={() => {
                reset();
                if (mode === "add") {
                  history.goBack();
                }
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                disabledAdd ||
                !name ||
                !(checkEnter || checkExit || checkPass) ||
                selectedCamera.length === 0 ||
                unallowed
              }
              loading={
                loading &&
                (type === addGeofence.type || type === updateGeofence.type)
              }
              onClick={() => {
                onSave({
                  mode: currentZone?.fenceId ? "update" : "add",
                  geofence: {
                    fenceId:
                      currentZone?.fenceId ?? `fenceId_${moment().unix()}`,
                    fenceName: name,
                    fenceType: FenceTypeCode[drawingMode],
                    psn: _.map(selectedCamera, (c) => c.psn),
                    setShape: {
                      Color: color,
                      Transparency: opacity / 100,
                    },
                    alarm: _.compact([
                      checkEnter && "Enter",
                      checkExit && "Exit",
                      checkPass && "Pass",
                    ]),
                  },
                });
              }}
            >
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>
      {mode !== "add" && !showDetail && !mobile && (
        <div className={classes.floatingBtnDiv}>
          <Fab
            size="small"
            variant="rounded"
            className={clsx({ [classes.fabMargin]: openPanel })}
            onClick={() => setOpenPanel((o) => !o)}
          >
            {(theme.direction === "rtl") !== openPanel ? (
              <ArrowLeftIcon />
            ) : (
              <ArrowRightIcon />
            )}
          </Fab>
        </div>
      )}
      {(mode === "add" || showDetail) && (
        <div className={classes.floatingDrawingDiv}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={clsx(classes.drawToolDiv)}>
              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Undo")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    disabled={disableUndo}
                    className={clsx(classes.drawToolFab)}
                    onClick={() => onUndo()}
                  >
                    <UndoIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Redo")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    disabled={disableRedo}
                    className={clsx(classes.drawToolFab)}
                    onClick={() => onRedo()}
                  >
                    <RedoIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Cancel")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    disabled={disableCancel || showDetail}
                    className={clsx(classes.drawToolFab)}
                    onClick={() => reset()}
                  >
                    <ClearIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Polygon")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, classes.polygonIcon, {
                      [classes.polygonSelected]: drawingMode === "polygon",
                      [classes.disabledPolygonToolDiv]:
                        showDetail && drawingMode !== "polygon",
                    })}
                    disabled={showDetail && drawingMode !== "polygon"}
                    onClick={() => {
                      if (drawingMode !== "polygon") {
                        reset();
                        onChangeDrawingMode("polygon");
                      }
                    }}
                  >
                    <Polygon style={{ width: 20, height: 20 }} />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Polyline")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, {
                      [classes.toolSelected]: drawingMode === "polyline",
                    })}
                    disabled={showDetail && drawingMode !== "polyline"}
                    onClick={() => {
                      if (drawingMode !== "polyline") {
                        reset();
                        onChangeDrawingMode("polyline");
                      }
                    }}
                  >
                    <RemoveIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Ellipse")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, {
                      [classes.toolSelected]: drawingMode === "circle",
                    })}
                    disabled={showDetail && drawingMode !== "circle"}
                    onClick={() => {
                      if (drawingMode !== "circle") {
                        reset();
                        onChangeDrawingMode("circle");
                      }
                    }}
                  >
                    <PanoramaFishEyeIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Rectangle")}
                  </Typography>
                }
                placement="bottom"
              >
                <div>
                  <Fab
                    size="small"
                    variant="rounded"
                    className={clsx(classes.drawToolFab, {
                      [classes.toolSelected]: drawingMode === "rectangle",
                    })}
                    disabled={showDetail && drawingMode !== "rectangle"}
                    onClick={() => {
                      if (drawingMode !== "rectangle") {
                        reset();
                        onChangeDrawingMode("rectangle");
                      }
                    }}
                  >
                    <CropSquareIcon />
                  </Fab>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      {!mobile && (
        <CameraMenu
          open={openMenu}
          onClickAway={() => setOpenMenu(false)}
          anchorRef={moreBtnRef === null ? undefined : moreBtnRef}
          onDelete={() => {
            setOpenMenu(false);
            setOpenDeleteModal(true);
          }}
          hideDivider
          placement="bottom-end"
        />
      )}
      {mobile && (
        <MobileCameraMenu
          open={openMenu}
          onClose={() => {
            setOpenMenu(false);
          }}
          onDelete={() => {
            setOpenMenu(false);
            setOpenDeleteModal(true);
          }}
        />
      )}
      <Modal
        open={openDeleteModal}
        close
        onClose={() => setOpenDeleteModal(false)}
        onClickNegative={() => setOpenDeleteModal(false)}
        onClickPositive={() => {
          if (moreZone) {
            dispatch(
              deleteGeofence({
                fenceId: moreZone.fenceId,
                fenceName: moreZone.fenceName,
              })
            );
          }
        }}
        heading={t("Delete")}
        content={t("Are you sure_delete zone", {
          zone: moreZone?.fenceName,
        })}
        LButton={t("Cancel")}
        RButton={t("Delete")}
        Secondary
        loading={loading && type === deleteGeofence.type}
        mobile={mobile}
        actionClassName={classes.pdT}
      />
    </div>
  );
};
