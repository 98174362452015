import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Theme,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import {
  Container,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import CheckIcon from "@material-ui/icons/Check";
import { Webviewer } from "../contants/Breakpoints";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 56,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    padding: ` 0 ${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(4)}px`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bodyText: {
    maxWidth: 906,
    textAlign: "center",
  },
  compareDiv: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    width: "100%",
  },

  tableRoot: {
    borderRadius: 4,
    minWidth: 1370,
  },
  compareCheckDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    margin: "0 auto",
  },
  compareNoCheckDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    margin: "0 auto",
    verticalAlign: "center",
  },
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(8) + 1,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  },
  tableTh: {
    borderBottom: `1px solid ${LightColors.primary["6"]}!important`,
    "&:hover": {
      backgroundColor: `${LightColors.primary["0"]}!important`,
    },
  },
  thDevice: {
    width: 142,
    [theme.breakpoints.up("xl")]: {
      width: 211,
    },
  },
}));

const TableCell = withStyles((theme: Theme) => ({
  body: {
    border: 0,
    verticalAlign: "top",
    padding: `${theme.spacing(1.125) + 1}px ${theme.spacing(
      1
    )}px ${theme.spacing(1.125)}px`,
    "&:first-child": {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
      ...(theme.direction === "rtl"
        ? { textAlign: "right" }
        : { textAlign: "left" }),
    },
  },
  head: {
    border: 0,
    padding: theme.spacing(1),
    whiteSpace: "nowrap",
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: "none",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      "&:hover ": {
        backgroundColor: LightColors.primary["10"],
      },
    },
  },
}))(MuiTableRow);

export const CompatibleDashcams = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const category = matches ? "Large" : "Default";

  return (
    <div className={classes.root} dir={theme.direction}>
      <Container className={classes.body}>
        <div className={classes.titleDiv}>
          <Typography
            category={category}
            variant="H1"
            className={classes.bodyText}
          >
            {t("Compatible dashcams")}
          </Typography>
        </div>

        <div className={classes.compareDiv}>
          <Table classes={{ root: classes.tableRoot }}>
            <TableHead>
              <TableRow className={classes.tableTh}>
                <TableCell style={{ minWidth: 365 + 16 }}>
                  <Typography category="Default" variant="BodyBold">
                    {t("Camera features")}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.thDevice}>
                  <Typography category="Default" variant="BodyBold">
                    {t("DR750-2CH LTE")}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.thDevice}>
                  <Typography category="Default" variant="BodyBold">
                    {t("DR900X Series")}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.thDevice}>
                  <Typography category="Default" variant="BodyBold">
                    {t("DR750X Series")}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.thDevice}>
                  <Typography category="Default" variant="BodyBold">
                    {t("DR900S Series")}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.thDevice}>
                  <Typography category="Default" variant="BodyBold">
                    {t("DR750S Series")}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.thDevice}>
                  <Typography category="Default" variant="BodyBold">
                    {t("DR650S Series")}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classes.thDevice}>
                  <Typography category="Default" variant="BodyBold">
                    {t("DR650GW Series")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Remote Live View")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Remote video playback_")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Push Notifications")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Push Notifications with preview thumbnail")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Two-way Voice Communication")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Live GPS")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Email notifications")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Multi Live View (Viewer)")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("GPS Tracking")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t(
                      "Geofence Setup (Windows/Web Viewer) & Alerts (Viewer/App)"
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Event Live Auto Upload")} ({t("Storage period")}:{" "}
                    {t("days", { a: 7 })})<br />
                    {t("Fleet Plan Only (except DR650GW and DR650S Series)")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Event File Auto Upload")} ({t("Storage period")}:{" "}
                    {t("days", { a: 7 })}){" "}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography
                    category="Default"
                    variant="Body"
                    dangerouslySetInnerHTML={{
                      __html: t("Live Event Upload_")?.replace(
                        // eslint-disable-next-line no-control-regex
                        new RegExp("\n", "g"),
                        "<br/>"
                      ),
                    }}
                  ></Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Reports")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography category="Default" variant="Body">
                    {t("Video On Map")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareCheckDiv}>
                    <CheckIcon color="primary" />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={classes.compareNoCheckDiv}>
                    <Typography category="Default" variant="Body">
                      –
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Container>
    </div>
  );
};
