import { call, put, select, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failLoad,
  ICameraInfo,
  ICameraList,
  ICamRegInfo,
  loadCameras,
  loadCamReg,
  loadMyCemrasLocation,
  successLoadCamera,
  successLoadCameras,
  successLoadCameraLocation,
  successLoadCamReg,
  loadCamera,
  AddCameraRequest,
  addCamera,
  renameCamera,
  deleteCamera,
  OnOffType,
  updatePrivacy,
  successUpdatePrivacy,
  IFwUpdateInfo,
  successFwUpdateInfo,
  loadFwUpdateInfo,
  successUpdateNotification,
  loadNotification,
  CAMERA,
  INotificationSettingsOrig,
  loadLiveEventSettings,
  ILiveEventSettings,
  successLoadLiveEventSettings,
  updateLiveEventSettings,
  successLoadFirmwareInfos,
  loadFirmwareInfos,
  updateNotification,
  IEmailNotiSettings,
  loadMultiCamLocation,
  successLoadFirmwareSettings,
  IFirmwareSettings,
  loadFirmwareSettings,
  updateFirmwareSettings,
  INotificationEmailList,
  loadNotificationEmailList,
  successLoadNotificationEmailList,
  IEmailInvite,
  invite,
  successInvite,
  clearLoading,
  IDeleteEmail,
  deleteEmail,
  successDelete,
  requestFota,
  IFotaIDPayload,
  loadPublicCameraLocation,
  IPublicCameraOrig,
  successLoadPublicCameraLocation,
  updateCameras,
  successUpdateCameras,
  loadPublicCamera,
  successLoadPublicCamera,
  ICameraCluster,
  successLoadCameraCluster,
  INotificationSettings,
  setFirmwareConfig,
  rebootCamera,
  setCameraError,
  IMasterEmailNotification,
  fixMasterEmailNotification,
  loadFirmwareConfig,
} from "./slice";
import * as Api from "../../apis";
import { RootState } from "../store";
import { IUserLoginInfo, logout, USER } from "../User/slice";
import { IFirmware, ILatLngBounds, RESULT_CODE } from "../../types";
import { closeToast, openToast } from "../Toast/slice";
import { goBack, push } from "connected-react-router";
import { ignoreError, setError } from "../Error/slice";
import { loadSubscription } from "../Payment/slice";
import { getFirmwareConfig } from "../../utils/Firmware";
import moment from "moment";
import { loadGroups } from "../Group/slice";

function* handleAddCamera({ payload }: PayloadAction<AddCameraRequest>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.addCamera,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode, message } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      // response: { "device info": ICameraInfo };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadSubscription());
      yield put(loadCameras());
      yield put(openToast({ message: "Camera added" }));
      yield put(push("/cameras/choose-connection"));
    } else if (
      resultcode === "BC_ERR_DUPLICATED" ||
      resultcode === "BC_ERR_INVALID_DATA"
    ) {
      yield put(failLoad());
      yield put(setCameraError(resultcode));
    } else if (resultcode === "BC_ERR_SERVER") {
      yield put(failLoad());
      yield put(setCameraError("An error occurred_again"));
      yield put(goBack());
    } else {
      yield put(failLoad());
      yield put(setCameraError(message));
    }
    // console.log(resp.data);
  } catch (err) {
    // console.error(err);
  }
}

function* handleRenameCamera({
  payload,
}: PayloadAction<{ psn: string; devName: string }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.renameCamera,
      email,
      loginInfo.user_token,
      payload.psn,
      payload.devName
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadCameras());
      yield put(loadCamera(payload.psn));
      yield put(openToast({ message: "Camera renamed" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleDeleteCamera({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.deleteCamera,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadCameras());
      yield put(loadGroups());
      yield put(openToast({ message: "Camera deleted" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadCamera({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getCamInfo,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { "device info": ICameraInfo };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadCamera(response["device info"]));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadPublicCamera({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getCamInfo,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { "device info": ICameraInfo };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadPublicCamera(response["device info"]));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadCameras() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getCamList, email, loginInfo.user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ICameraList;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadCameras(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleUpdateCameras() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getCamList, email, loginInfo.user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ICameraList;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successUpdateCameras(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadCamReg() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.checkCamReg, email, loginInfo.user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ICamRegInfo;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadCamReg(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadMyCamerasLocation({
  payload,
}: PayloadAction<{ bounds?: ILatLngBounds; cancel: AbortController }>) {
  try {
    const { gps_server, gps_port, user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const email = yield select((state: RootState) => state[USER].email);
    if (email) {
      const resp = yield call(
        Api.getMyCameraLocations,
        gps_server,
        gps_port,
        email,
        user_token,
        payload.cancel,
        payload.bounds
      );
      const reader = resp.body.getReader();
      let data: any;
      const decoder = new TextDecoder("utf-8");
      do {
        data = yield reader.read();
        const body = _.split(decoder.decode(data.value), "\r\n");
        try {
          if (body.length > 1) {
            const content = JSON.parse(body[1]) as {
              resultcode: RESULT_CODE;
              message: string;
              response: { "device list": { info: ICameraInfo[] } };
            };
            console.log(content);

            const camInfos = content.response["device list"].info;
            yield put(successLoadCameraCluster(undefined));
            yield put(successLoadCameraLocation(camInfos));
          } else {
            const content = JSON.parse(body[0]) as {
              resultcode: RESULT_CODE;
              message: string;
              response: ICameraCluster;
            };
            console.log(content);
            yield put(successLoadCameraCluster(content.response));
          }
        } catch (err) {}
      } while (!data.done);
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadPublicCamerasLocation({
  payload,
}: PayloadAction<{ bounds: ILatLngBounds; cancel: AbortController }>) {
  try {
    const { gps_server, gps_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getPublicCameraLocations,
      gps_server,
      gps_port,
      payload.bounds,
      payload.cancel
    );
    const reader = resp.body.getReader();
    let data: any;
    const decoder = new TextDecoder("utf-8");
    do {
      data = yield reader.read();
      const body = _.split(decoder.decode(data.value), "\r\n");
      if (body.length > 1) {
        const content = JSON.parse(body[1]) as {
          resultcode: RESULT_CODE;
          message: string;
          response: { "device list": { info: IPublicCameraOrig[] } };
        };
        // console.log(content);

        const camInfos = content.response["device list"].info;
        yield put(
          successLoadPublicCameraLocation(
            _.map(camInfos, (info) => {
              const { latitude, longitude, ...rest } = info;
              return {
                ...rest,
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              };
            })
          )
        );
      }
    } while (!data.done);
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadMultiCamLocation({
  payload,
}: PayloadAction<{ psns: string[]; cancel: AbortController }>) {
  try {
    const { gps_server, gps_port, user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const email = yield select((state: RootState) => state[USER].email);
    if (email) {
      const resp = yield call(
        Api.getMultiCamLocations,
        gps_server,
        gps_port,
        email,
        payload.psns,
        user_token,
        payload.cancel
      );
      const reader = resp.body.getReader();
      let data: any;
      const decoder = new TextDecoder("utf-8");
      do {
        data = yield reader.read();
        const body = _.split(decoder.decode(data.value), "\r\n");
        if (body.length > 1) {
          const content = JSON.parse(body[1]) as {
            resultcode: RESULT_CODE;
            message: string;
            response: { "device list": { info: ICameraInfo[] } };
          };

          const camInfos = content.response["device list"].info;
          yield put(successLoadCameraLocation(camInfos));
        }
      } while (!data.done);
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdatePrivacy({
  payload,
}: PayloadAction<{
  psn: string;
  agree_gps: OnOffType;
  share_gps: OnOffType;
  share_video: OnOffType;
  share_audio: OnOffType;
  share_dev_name: OnOffType;
}>) {
  try {
    const {
      psn,
      agree_gps,
      share_gps,
      share_video,
      share_audio,
      share_dev_name,
    } = payload;
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.updatePrivacy,
      email,
      user_token,
      psn,
      agree_gps,
      share_gps,
      share_audio,
      share_video,
      share_dev_name
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successUpdatePrivacy(payload));
      yield put(openToast({ message: "Camera settings saved_" }));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadFwUpdateInfo({
  payload,
}: PayloadAction<string | undefined>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(Api.getFotaStatus, email, user_token, payload);

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: { fota_info: IFwUpdateInfo[] };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successFwUpdateInfo(response.fota_info));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadNotification() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const cameraInfo = (yield select(
      (state: RootState) => state[CAMERA].camera
    )) as ICameraInfo;
    const res = yield call(
      Api.getNotification,
      email,
      loginInfo.user_token,
      cameraInfo.psn
    );
    const { resultcode, message, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: INotificationSettingsOrig;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successUpdateNotification({
          ...response,
          EmailSettings: JSON.parse(response.EmailSettings),
          PhoneSettings: JSON.parse(response.PhoneSettings),
        })
      );
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(failLoad());
      yield put(setError(message));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleUpdateNotification({
  payload,
}: PayloadAction<{
  setType: "email" | "phone";
  settings: IEmailNotiSettings;
  send: OnOffType;
}>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { psn } = (yield select(
      (state: RootState) => state[CAMERA].camera
    )) as ICameraInfo;
    const resp = yield call(
      Api.updateNotification,
      email,
      user_token,
      psn,
      payload.setType,
      payload.settings,
      payload.send
    );

    const { resultcode, message } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: INotificationSettings;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(loadNotification());
      yield put(openToast({ message: "Camera settings saved_" }));
      // yield put(clearLoading());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(failLoad());
      yield put(setError(message));
    } else {
      yield put(failLoad());
      yield put(setError(message));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadLiveEventSettings({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getLiveEventSettings,
      email,
      user_token,
      payload
    );

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ILiveEventSettings;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadLiveEventSettings(response));
    }
  } catch (err) {
    console.error(err);
  }
}
function* handleLoadFirmwareSettings() {
  try {
    const { camera, cameraList } = yield select(
      (state: RootState) => state[CAMERA]
    );
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    const dev = _.find(
      cameraList.DeviceListInfo,
      (dev) => dev.device.psn === camera?.psn
    )?.device;

    if (dev) {
      yield put(loadFirmwareConfig(dev));

      const resp = yield call(
        Api.getFirmwareSettings,
        email,
        user_token,
        camera.psn
      );

      const { resultcode, response } = resp.data as {
        resultcode: RESULT_CODE;
        message: string;
        response: IFirmwareSettings;
      };

      if (resultcode === "BC_ERR_OK") {
        yield put(successLoadFirmwareSettings(response));
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadNotificationEmailList({ payload }: PayloadAction<string>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getNotificationEmail,
      email,
      user_token,
      payload
    );

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: INotificationEmailList;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadNotificationEmailList(response));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleInviteEmail({ payload }: PayloadAction<IEmailInvite>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.inviteEmail,
      email,
      user_token,
      payload.psn,
      payload.guestEmail,
      payload.companyID
    );
    const { resultcode, message } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successInvite());
      yield put(openToast({ message: "Invite sent" }));
      yield put(loadNotificationEmailList(payload.psn));
    } else if (
      resultcode === "BC_ERR_DUPLICATED" &&
      message === "email is already exist"
    ) {
      yield put(setError("An account is_"));
      yield put(clearLoading());
    } else if (
      resultcode === "BC_ERR_LIMIT_EXCEEDED" &&
      message === "limit exceeded"
    ) {
      yield put(setError("An account is_"));
      yield put(clearLoading());
    }
  } catch (err) {}
}

function* handleFixMasterEmailNotification({
  payload,
}: PayloadAction<IMasterEmailNotification>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    yield put(closeToast());
    const resp = yield call(
      Api.FixMasterEmailNotification,
      email,
      user_token,
      payload.psn,
      payload.guestEmail,
      payload.mailStatus
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadNotificationEmailList(payload.psn));
      yield put(openToast({ message: "Camera settings saved_" }));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleDeleteEmail({ payload }: PayloadAction<IDeleteEmail>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.deleteEmail,
      email,
      user_token,
      payload.psn,
      payload.guestEmail
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadNotificationEmailList(payload.psn));
      yield put(successDelete());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateLiveEventSettings({
  payload,
}: PayloadAction<{ psn: string; setting: ILiveEventSettings }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;

    yield put(closeToast());
    const resp = yield call(
      Api.updateLiveEventSettings,
      email,
      user_token,
      payload.psn,
      payload.setting
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };

    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadLiveEventSettings(payload.setting));
      yield put(openToast({ message: "Camera settings saved_" }));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadFirmwareInfos() {
  try {
    const resp = yield call(Api.getFwInfos);
    const { firmware } = resp.data as { firmware: IFirmware[] };
    yield put(successLoadFirmwareInfos(firmware));
  } catch (err) {
    console.error(err);
  }
}

function* handleUpdateFirmwareSettings({
  payload,
}: PayloadAction<{ firmware: IFirmwareSettings }>) {
  try {
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const { psn } = payload.firmware;

    const cameraInfo = (yield select(
      (state: RootState) =>
        _.find(
          state[CAMERA].cameraList?.DeviceListInfo,
          (cam) => cam.device.psn === psn
        )?.device
    )) as ICameraInfo;

    yield put(closeToast());
    const tab1 = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Tab1"
    );
    const tab2 = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Tab2"
    );
    const tab3 = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Tab3"
    );
    const wifi = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Wifi"
    );
    const cloud = _.find(
      payload.firmware.cloud_settings,
      (c) => c.section_name === "Cloud"
    );
    const resp = yield call(
      Api.updateFirmwareSettings,
      {
        ...payload.firmware,
        cloud_settings: [tab1, tab2, tab3, wifi, cloud],
        setting_version: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      } as IFirmwareSettings,
      user_token
    );

    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
      message: string;
    };

    if (cameraInfo.active === "on" && resultcode === "BC_ERR_OK") {
      yield put(successLoadFirmwareSettings(payload.firmware));
      yield put(openToast({ message: "Camera settings saved_" }));
    } else if (cameraInfo.active === "off" && resultcode === "BC_ERR_OK") {
      yield put(successLoadFirmwareSettings(payload.firmware));
      yield put(openToast({ message: "After Blackvue is_" }));
    } else {
      yield put(openToast({ message: "Failed to save_" }));
      yield put(failLoad());
    }
  } catch (err) {
    console.error(err);
  }
}

function* handlRequestFota({ payload }: PayloadAction<IFotaIDPayload>) {
  try {
    const { email, loginInfo } = yield select(
      (state: RootState) => state[USER]
    );
    const { cameraList } = yield select((state: RootState) => state[CAMERA]);
    const resp = yield call(
      Api.getFotaID,
      email,
      loginInfo.user_token,
      payload
    );
    const camera = _.find(
      cameraList?.DeviceListInfo,
      (dev) => dev.device.psn === payload.psn
    )?.device;

    if (camera) {
      const {
        resultcode,
        message,
        response: { fotaId },
      } = resp.data as {
        resultcode: RESULT_CODE;
        message: string;
        response: { fotaId: string };
      };

      if (resultcode === "BC_ERR_OK") {
        console.log("handleGetFotaID", fotaId, payload);
        yield call(
          Api.getFotaStart,
          email,
          loginInfo.user_token,
          camera.lb_server_name,
          camera.lb_http_port,
          fotaId,
          payload
        );
      } else {
        yield put(setError(message));
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleRebootCamera({ payload }: PayloadAction<ICameraInfo>) {
  try {
    const email = (yield select(
      (state: RootState) => state[USER].email
    )) as string;
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield put(ignoreError());
    const res = yield call(
      Api.rebootCamera,
      payload.lb_server_name,
      payload.lb_http_port,
      email,
      loginInfo.user_token,
      payload.psn
    );

    if (res.data === "success") {
      yield put(clearLoading());
      yield put(openToast({ message: "Restarting the BlackVue_" }));
    } else {
      yield put(clearLoading());
      yield put(openToast({ message: "Failed to reboot_" }));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(openToast({ message: "Failed to reboot_" }));
  }
}

function* handleLoadFirmwareConfig({ payload }: PayloadAction<ICameraInfo>) {
  try {
    const firmwareConfig = getFirmwareConfig(payload);
    yield put(setFirmwareConfig(firmwareConfig));
  } catch (err) {
    console.error(err);
  }
}
export function* watchCamera() {
  yield takeLatest(addCamera, handleAddCamera);
  yield takeLatest(renameCamera, handleRenameCamera);
  yield takeLatest(deleteCamera, handleDeleteCamera);
  yield takeLatest(loadCamera, handleLoadCamera);
  yield takeLatest(loadPublicCamera, handleLoadPublicCamera);
  yield takeLatest(loadCameras, handleLoadCameras);
  yield takeLatest(updateCameras, handleUpdateCameras);
  yield takeLatest(loadCamReg, handleLoadCamReg);
  yield takeLatest(loadFirmwareConfig, handleLoadFirmwareConfig);
  yield takeLatest(loadMyCemrasLocation, handleLoadMyCamerasLocation);
  yield takeLatest(loadMultiCamLocation, handleLoadMultiCamLocation);
  yield takeLatest(updatePrivacy, handleUpdatePrivacy);
  yield takeLatest(loadFwUpdateInfo, handleLoadFwUpdateInfo);
  yield takeLatest(loadNotification, handleLoadNotification);
  yield takeLatest(updateNotification, handleUpdateNotification);
  yield takeLatest(loadLiveEventSettings, handleLoadLiveEventSettings);
  yield takeLatest(updateLiveEventSettings, handleUpdateLiveEventSettings);
  yield takeLatest(loadFirmwareInfos, handleLoadFirmwareInfos);
  yield takeLatest(loadFirmwareSettings, handleLoadFirmwareSettings);
  yield takeLatest(updateFirmwareSettings, handleUpdateFirmwareSettings);
  yield takeLatest(loadNotificationEmailList, handleLoadNotificationEmailList);
  yield takeLatest(invite, handleInviteEmail);
  yield takeLatest(deleteEmail, handleDeleteEmail);
  yield takeLatest(requestFota, handlRequestFota);
  yield takeLatest(loadPublicCameraLocation, handleLoadPublicCamerasLocation);
  yield takeLatest(rebootCamera, handleRebootCamera);
  yield takeLatest(
    fixMasterEmailNotification,
    handleFixMasterEmailNotification
  );
}
