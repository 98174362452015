import React, { useCallback, useEffect, useState } from "react";
import { FormControlLabel } from "@material-ui/core";
import {
  Button,
  CheckBox,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Webviewer } from "../../contants/Breakpoints";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  CAMERA,
  ICameraInfo,
  loadCameras,
  updatePrivacy,
} from "../../features/Camera/slice";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  //////////
  mgNone: {
    margin: 0,
  },

  box: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 48,
    marginTop: 14,
    display: "flex",
    borderRadius: 4,
    ...(theme.direction === "rtl"
      ? { padding: "8px 10px 12px 0" }
      : { padding: "8px 0 12px 10px" }),
    marginBottom: 32,
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      border: `1px solid ${LightColors.primary["6"]}`,
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      marginBottom: 32,
      flexDirection: "column",
    },
  },

  bigBox: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    marginTop: 14,
    borderRadius: 4,
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 16,
      width: "100%",
      maxWidth: 672,
      maxHeight: 192,
      marginBottom: 16,
      borderRadius: 4,
    },
  },
  boderBottomBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
  },
  boderNoneBox: {
    maxWidth: 288,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
    },
  },
  pdL36: {
    paddingLeft: 36,
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
  },
  mgBAs: {
    marginBottom: 32,
    maxWidth: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  pdUsed: {
    ...(theme.direction === "rtl" ? { paddingRight: 30 } : { paddingLeft: 30 }),
  },
  all: {
    marginTop: 18,
    marginBottom: 18,
  },
}));

export const PrivacyPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { camera, cameraList } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const { loading, type } = useSelector((state: RootState) => state[CAMERA]);
  const [cameraInfo, setCameraInfo] = useState<ICameraInfo>();

  const [agree_gps, setAgreeGPS] = useState(false);
  const [share_gps, setShareGPS] = useState(false);
  const [share_video, setShareVideo] = useState(false);
  const [share_audio, setShareAudio] = useState(false);
  const [share_dev_name, setShareDevName] = useState(false);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    const cam = _.chain(cameraList?.DeviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === camera?.psn)
      .value();
    setCameraInfo((c) => {
      if (c?.psn !== cam?.psn) {
        return cam;
      } else if (c?.active !== cam?.active) {
        return cam;
      }
      return c;
    });
  }, [camera?.psn, cameraList?.DeviceListInfo]);

  const getBoolean = useCallback((val: "on" | "off" | undefined) => {
    return val === "on" ? true : false;
  }, []);

  const getOnOff = (val: boolean) => {
    return val ? "on" : "off";
  };

  useEffect(() => {
    setAgreeGPS(getBoolean(cameraInfo?.agree_gps));
    setShareGPS(getBoolean(cameraInfo?.share_gps));
    setShareVideo(getBoolean(cameraInfo?.share_video));
    setShareAudio(getBoolean(cameraInfo?.share_audio));
    setShareDevName(getBoolean(cameraInfo?.share_dev_name));
  }, [getBoolean, cameraInfo]);

  useEffect(() => {
    if (
      getBoolean(cameraInfo?.agree_gps) !== agree_gps ||
      getBoolean(cameraInfo?.share_gps) !== share_gps ||
      getBoolean(cameraInfo?.share_video) !== share_video ||
      getBoolean(cameraInfo?.share_audio) !== share_audio ||
      getBoolean(cameraInfo?.share_dev_name) !== share_dev_name
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [
    getBoolean,
    cameraInfo,
    agree_gps,
    share_gps,
    share_video,
    share_audio,
    share_dev_name,
  ]);

  const handleUpdate = useCallback(() => {
    if (camera) {
      setDisableSave(true);
      dispatch(
        updatePrivacy({
          psn: camera.psn,
          agree_gps: getOnOff(agree_gps),
          share_gps: getOnOff(share_gps),
          share_video: getOnOff(share_video),
          share_audio: getOnOff(share_audio),
          share_dev_name: getOnOff(share_dev_name),
        })
      );
    }
  }, [
    dispatch,
    camera,
    agree_gps,
    share_gps,
    share_video,
    share_audio,
    share_dev_name,
  ]);

  return (
    <div className={classes.all}>
      <Typography category="Default" variant="H6">
        {t("Private")}
      </Typography>
      <div className={classes.box}>
        <div>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={agree_gps}
                onChange={(_e, checked) => {
                  setAgreeGPS(checked);
                  if (agree_gps === !checked) {
                    setShareGPS(false);
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Allow GPS access")}
              </Typography>
            }
          />
        </div>
        <div className={classes.pdUsed}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("If not allowed_")}
            {/* {t("Used to access_2")} */}
          </Typography>
        </div>
      </div>
      <Typography category="Default" variant="H6">
        {t("Public")}
      </Typography>
      <div className={classes.bigBox}>
        <div className={classes.boderBottomBox}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={share_gps}
                onChange={(_e, checked) => {
                  if (agree_gps === !checked) {
                    setShareGPS(false);
                  } else {
                    setShareGPS(checked);
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Share location")}
              </Typography>
            }
          />
        </div>

        <div
          className={clsx({
            [classes.boderNoneBox]: share_video,
            [classes.boderBottomBox]: !share_video,
          })}
        >
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={share_video}
                disabled={!share_gps}
                onChange={(_e, checked) => {
                  setShareVideo(checked);
                  if (!checked) {
                    setShareAudio(false);
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Share live view")}
              </Typography>
            }
          />
        </div>
        {share_video && (
          <div className={clsx(classes.boderBottomBox, classes.pdL36)}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={share_audio}
                  disabled={!share_gps}
                  onChange={(_e, checked) => setShareAudio(checked)}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Share live audio")}
                </Typography>
              }
            />
          </div>
        )}
        <div className={classes.boderNoneBox}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={share_dev_name}
                disabled={!share_gps}
                onChange={(_e, checked) => setShareDevName(checked)}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Share camera’s name")}
              </Typography>
            }
          />
        </div>
      </div>{" "}
      <div className={classes.mgBAs}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {t("By sharing your_")}
          {/* {t("As personal video_")} */}
        </Typography>
      </div>
      {mobile && (
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonSize}
            disabled={disableSave}
            loading={
              loading &&
              (type === updatePrivacy.type || type === loadCameras.type)
            }
            onClick={handleUpdate}
          >
            {t("Save")}
          </Button>
        </div>
      )}
      {!mobile && (
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={disableSave}
            loading={
              loading &&
              (type === updatePrivacy.type || type === loadCameras.type)
            }
            onClick={handleUpdate}
          >
            {t("Save")}
          </Button>
        </div>
      )}
    </div>
  );
};
