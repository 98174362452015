import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IFirmwareConfig } from "../../contants/Firmwares";
import { IFirmware, ILatLngBounds } from "../../types";

export type OnOffType = "on" | "off";
export type TrueFalsetype = "1" | "0";
export type FWUpdateStatus =
  | "init"
  | "ready"
  | "progress"
  | "complete"
  | "success"
  | "fail";

export type TabName = "Tab1" | "Tab2" | "Tab3" | "Wifi" | "Cloud";
export type statusType = "A" | "D" | "P" | "E";

export interface IClusterZone {
  lat: string;
  lng: string;
  count: string;
  share_video?: "on" | "off";
}

export interface ICameraCluster {
  "zone count"?: {
    info: IClusterZone[];
  };
  myzone?: {
    info: IClusterZone[];
  };
}

export interface IFotaIDPayload {
  psn: string;
  fw_model: string;
  now_fw_version: string;
  new_fw_version: string;
  fota_url: string;
  file_size: number;
  lang: string;
  checksum: string;
}

export interface IFotaStartPayload {
  psn: string;
  fw_model: string;
  fw_version: string;
  fota_url: string;
  file_size: number;
  fota_lang: string;
  checksum: string;
  fotaid: string;
}

export interface ILiveEventSettings {
  GEntering: OnOffType;
  GExiting: OnOffType;
  GPassing: OnOffType;
  LiveEventUpload: OnOffType;
  ManualRecording: OnOffType;
  NerAcceleration: OnOffType;
  NerHarshBraking: OnOffType;
  NerImpactDetection: OnOffType;
  NerOverSpeed: OnOffType;
  NerSharpTurn: OnOffType;
  PerImpactDetection: OnOffType;
  Vs1Chan: OnOffType;
  Vs2Chan: OnOffType;
  Vs3Chan: OnOffType;
  DmsDistracted: OnOffType;
  DmsDrowsy: OnOffType;
  DmsUndetected: OnOffType;
  DmsCalling: OnOffType;
  DmsSmoking: OnOffType;
}

export interface AddCameraRequest {
  psn: string;
  cldn: string;
  agree_gps: OnOffType;
}

export interface IPublicCameraOrig {
  active: OnOffType;
  dev_name: string;
  latitude: string;
  longitude: string;
  mode: string;
  psn: string;
  share_video: OnOffType;
  speed: string;
}

export interface IPublicCamera {
  active: OnOffType;
  dev_name: string;
  lat: number;
  lng: number;
  mode: string;
  psn: string;
  share_video: OnOffType;
  speed: string;
}

export interface ICameraInfo {
  valid: "valid" | "invalid";
  model: string;
  mode?: string;
  psn: string;
  active: OnOffType;
  dev_name: string;
  lb_server_name: string;
  lb_http_port: string;
  lb_rtmp_port: string;
  fw_ver: string;
  communication_identifier?: "Wifi" | "Cat4" | "Cat-M1";
  sensitivity_level?: 0 | 1 | 2;
  agree_gps?: OnOffType;
  dev_shared_cnt?: number;
  eld_conn_availability?: string;
  eld_conn_history?: string;
  eld_conn_status?: string;
  lang?: string;
  share_audio?: OnOffType;
  share_dev_name?: OnOffType;
  share_gps?: OnOffType;
  share_video?: OnOffType;
  upload_server: string;
  upload_port: string;
  interval: number;
  temperature_level?: number;
  latitude?: string;
  longitude?: string;
  speed?: string;
  dir_angle?: string;
  UseGpsInfo?: string;
  dms_type?: string;
  dms_version?: string;
  cur_ssid?: string;
  reg_date?: string;
  login_date?: string;
}

export interface ICamRegInfo {
  cur_cnt: number;
  max_cnt: number;
}

export interface ICameraList {
  deviceCount?: number;
  DeviceListInfo?: {
    device: ICameraInfo;
    groupManagementID?: string;
    groupName?: string;
  }[];
}

export interface INotificationSettingsOrig {
  CreateDT: string;
  EmailSend: OnOffType;
  EmailSettings: string;
  PSN: string;
  PhoneSend: OnOffType;
  PhoneSettings: string;
  UpdateDT: string;
}

export interface IEmailNotiSettings {
  ALARM_ACCELERATION: OnOffType; //
  ALARM_CLOUDSTORAGE: OnOffType; //
  ALARM_EVENT: OnOffType; //
  ALARM_GEOFENCE_ENTER: OnOffType; //
  ALARM_GEOFENCE_EXIT: OnOffType; //
  ALARM_GEOFENCE_PASS: OnOffType; //
  ALARM_HARSHBRAKING: OnOffType; //
  ALARM_MANUAL: OnOffType; //
  ALARM_PARK_EVENT: OnOffType; //
  ALARM_PARK_IN: OnOffType; //같이 있음
  ALARM_PARK_MOTION: OnOffType; //
  ALARM_PARK_OUT: OnOffType; // 같이 있음
  ALARM_SHARPTURN: OnOffType; //
  ALARM_SPEED: OnOffType; //없음
  DEVICE_CONNECT: OnOffType; //없음
  DEVICE_DISCONNECT: OnOffType; //없음
  ALARM_TEMPERATURE_OVER: OnOffType; //없음
  ALARM_SETTING_SAVED: OnOffType; //없음
  ALARM_UNDETECTED: OnOffType;
  ALARM_DETECTED: OnOffType;
  ALARM_DISTRACTED: OnOffType;
  ALARM_MASK_OFF: OnOffType;
  ALARM_SMOKING: OnOffType;
  ALARM_CALLING: OnOffType;
  ALARM_DROWSY: OnOffType;
  ALARM_LOW_VOLTAGE: OnOffType;
}

export interface INotificationSettings {
  CreateDT: string;
  EmailSend: OnOffType;
  EmailSettings: IEmailNotiSettings;
  PSN: string;
  PhoneSend: OnOffType;
  PhoneSettings: IEmailNotiSettings;
  UpdateDT: string;
}

export interface INotificationEmailList {
  emails: IEmailList[];
}
export interface IEmailList {
  psn: string;
  hostEmail: string;
  guestEmail: string;
  mailStatus: statusType;
  confirmToken: string;
  createDT: string;
  updateDT: string;
}
export interface IEmailInvite {
  psn: string;
  guestEmail: string;
  companyID: string;
}
export interface IMasterEmailNotification {
  psn: string;
  guestEmail: string;
  mailStatus: statusType;
}
export interface IDeleteEmail {
  psn: string;
  guestEmail: string;
}

export interface IFirmwareSettings {
  cloud_settings: IFirmwareCloudSettings[];
  firmware_language: string;
  firmware_version: string;
  model: string;
  email: string;
  setting_version?: string;
  update_date?: string;
  psn: string;
}

export interface IFirmwareCloudSettings {
  section_info:
    | TabNameInfo1
    | TabNameInfo2
    | TabNameInfo3
    | TabNameInfo4
    | TabNameInfo5;
  section_name: TabName;
}

export interface TabNameInfo1 {
  //tab1
  ACodecType: TrueFalsetype;
  AutoParking: string;
  DateDisplay: TrueFalsetype;
  Daylight: TrueFalsetype;
  FrontBrightness: string;
  FrontRotate: TrueFalsetype;
  GpsSync: TrueFalsetype;
  ImageSetting: string;
  LockEvent: TrueFalsetype;
  NightVision: string;
  NormalRecord: TrueFalsetype;
  OverwriteLock: TrueFalsetype;
  RearBrightness: string;
  RearParkingMode: TrueFalsetype;
  InteriorParkingMode?: TrueFalsetype;
  RearRotate: string;
  InteriorRotate?: TrueFalsetype;
  RecordTime: string;
  SetTime: string;
  SpeedUnit: string;
  TimeSet: TrueFalsetype;
  TimeZone: string;
  UseGpsInfo: TrueFalsetype;
  VCodecType: TrueFalsetype;
  VideoQuality: string;
  VoiceRecord: TrueFalsetype;
}
export interface TabNameInfo2 {
  //tab2
  FrontMotionRegion: string; // ??
  MOTIONSENSOR: string;
  NORMALSENSOR1: string;
  NORMALSENSOR2: string;
  NORMALSENSOR3: string;
  PARKINGSENSOR1: string;
  PARKINGSENSOR2: string;
  PARKINGSENSOR3: string;
  RearMotionRegion?: string; // ??
  InteriorMotionRegion?: string; // ??
}
export interface TabNameInfo3 {
  //tab3 system
  ADASFVSA: TrueFalsetype;
  ADASLDWS: string;
  ADASSetting: TrueFalsetype;
  ADASTLDS: TrueFalsetype;
  ACCELERATIONVOICE: TrueFalsetype;
  Battery: TrueFalsetype;
  CHANGECONFIGVOICE: TrueFalsetype;
  CHANGERECORDMODEVOICE: TrueFalsetype;
  CLOUDVOICE: TrueFalsetype;
  ENDVOICE: TrueFalsetype;
  EVENTSTARTVOICE: TrueFalsetype;
  HARSHBRAKINGVOICE: TrueFalsetype;
  LTELED: TrueFalsetype;
  LowvoltageTime: string;
  LowvoltageVolt: string;
  LowvoltageVoltHeavy?: string;
  NORMALLED: TrueFalsetype;
  NORMALSTARTVOICE: TrueFalsetype;
  PARKINGEVENTVOICE: TrueFalsetype;
  PARKINGLED: TrueFalsetype;
  PSENSOR: string;
  BTLED?: TrueFalsetype;
  InteriorLED?: TrueFalsetype;
  RECLED: TrueFalsetype;
  RearLED: TrueFalsetype;
  SHARPTURNVOICE: TrueFalsetype;
  SPEEDALERTVOICE: TrueFalsetype;
  STARTVOICE: TrueFalsetype;
  ScheduledReboot: TrueFalsetype;
  ScheduledRebootTime: string;
  SpeedAlert: string;
  VOLUME: string;
  WifiLED: TrueFalsetype;
  kmLimit?: string;
  mileLimit?: string;
  userString: string;
  AccelEvent?: TrueFalsetype;
  HarshEvent?: TrueFalsetype;
  SharpEvent?: TrueFalsetype;
  DsmUndetected?: TrueFalsetype;
  DsmSensingSpeed?: string;
  DsmLed?: TrueFalsetype;
  DsmRotate?: TrueFalsetype;
  DsmDetectBox?: TrueFalsetype;
  DsmVolume?: string;
  DsmSensitivity?: string;
  DsmDrowsy?: TrueFalsetype;
  DsmDistracted?: TrueFalsetype;
  DsmCalling?: TrueFalsetype;
  DsmMaskOff?: TrueFalsetype;
  DsmSmoking?: TrueFalsetype;
  DsmParkingMode?: TrueFalsetype;
  BTPair?: TrueFalsetype;
}
export interface TabNameInfo4 {
  //Wifi
  WiFiBand: TrueFalsetype;
  WifiSleepMode: TrueFalsetype;
  ap_pw: string;
  ap_ssid: string;
  onstart: TrueFalsetype;
  onstart_LTE: TrueFalsetype;
}
export interface TabNameInfo5 {
  //Cloud
  AlarmEvent: TrueFalsetype; //없음//
  AlarmLowVoltage: TrueFalsetype; //없음//
  AlarmManual: TrueFalsetype; //없음//
  AlarmParkEvent: TrueFalsetype; //없음//
  AlarmParkInOut: TrueFalsetype; //없음//
  AlarmParking: TrueFalsetype; //없음//
  AlarmSpeed: TrueFalsetype; //없음//
  CloudService: TrueFalsetype; //없음//
  sta2_pw: string;
  sta2_ssid: string;
  sta3_pw: string;
  sta3_ssid: string;
  sta_pw: string;
  sta_ssid: string;
}

export interface IFwUpdateInfo {
  psn: string;
  fota_id: string;
  update_percentage: number;
  last_update_time: string;
  update_status: FWUpdateStatus;
  visible: 0 | 1;
}

interface IState {
  loading: boolean;
  type?: string;
  camera?: ICameraInfo;
  publicCamera?: ICameraInfo;
  cameraList?: ICameraList;
  camReg?: ICamRegInfo;
  fwUpdateInfos: IFwUpdateInfo[];
  notificationSettings?: INotificationSettings;
  EmailNotiSettings?: IEmailNotiSettings;
  liveEventSettings?: ILiveEventSettings;
  firmwares: IFirmware[];
  firmware?: IFirmwareSettings;
  notificationEmail?: INotificationEmailList;
  emailList?: IEmailList;
  deleteEmail?: IDeleteEmail;
  publicCams: IPublicCamera[];
  isLocationLoaded: boolean;
  clusters?: ICameraCluster;
  firmwareConfig?: IFirmwareConfig;
  cameraError?: string;
  masterStatus?: IMasterEmailNotification;
}

let initialState: IState = {
  loading: false,
  camera: undefined,
  publicCamera: undefined,
  cameraList: undefined,
  fwUpdateInfos: [],
  firmwares: [],
  emailList: undefined,
  publicCams: [],
  isLocationLoaded: false,
  cameraError: undefined,
};

const slice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    setCameraError: (state, { payload }: PayloadAction<string>) => {
      state.cameraError = payload;
    },
    clearCameraError: (state) => {
      state.cameraError = undefined;
    },
    clearCamera: (state) => {
      state.camera = undefined;
    },
    resetCamera: (state) => {
      state.loading = false;
      state.type = undefined;
      state.camera = undefined;
      state.cameraList = undefined;
      state.camReg = undefined;
      state.fwUpdateInfos = [];
      state.notificationSettings = undefined;
      state.EmailNotiSettings = undefined;
      state.liveEventSettings = undefined;
      state.firmwares = [];
      state.firmware = undefined;
      state.publicCamera = undefined;
      state.publicCams = [];
      // state.cloud = undefined;
    },
    addCamera: (state, action: PayloadAction<AddCameraRequest>) => {
      state.loading = true;
      state.type = action.type;
    },
    renameCamera: (
      state,
      action: PayloadAction<{ psn: string; devName: string }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    deleteCamera: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    loadCamera: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadCamera: (state, { payload }: PayloadAction<ICameraInfo>) => {
      state.loading = false;
      state.camera = payload;
    },
    loadCameras: (state, action: PayloadAction<undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    updateCameras: (state, action: PayloadAction<undefined>) => {
      state.type = action.type;
    },
    successLoadCameras: (state, { payload }: PayloadAction<ICameraList>) => {
      state.loading = false;
      state.cameraList = {
        ...payload,
        DeviceListInfo: _.map(payload.DeviceListInfo, (devInfo) => {
          return {
            ...devInfo,
            device: {
              ...devInfo.device,
              mode: devInfo.device.mode?.toString(),
            },
          };
        }),
      };
    },
    successUpdateCameras: (state, { payload }: PayloadAction<ICameraList>) => {
      state.loading = false;
      if (state.cameraList) {
        _.map(state.cameraList.DeviceListInfo, (dev) => dev.device.psn);
        state.cameraList = {
          ...state.cameraList,
          DeviceListInfo: _.map(state.cameraList.DeviceListInfo, (devInfo) => {
            const dev = _.find(
              payload.DeviceListInfo,
              (pd) => pd.device.psn === devInfo.device.psn
            )?.device;
            return {
              ...devInfo,
              device: {
                ...devInfo.device,
                ...dev,
                mode: dev?.mode?.toString() || devInfo.device.mode,
              },
            };
          }),
        };
      }
    },
    loadCamReg: (state) => {},
    successLoadCamReg: (state, { payload }: PayloadAction<ICamRegInfo>) => {
      state.camReg = payload;
    },
    failLoad: (state) => {
      state.loading = false;
    },
    loadMultiCamLocation: (
      state,
      { payload }: PayloadAction<{ psns: string[]; cancel: AbortController }>
    ) => {},
    loadMyCemrasLocation: (
      state,
      _action: PayloadAction<{
        bounds?: ILatLngBounds;
        cancel: AbortController;
      }>
    ) => {},
    successLoadCameraCluster: (
      state,
      { payload }: PayloadAction<ICameraCluster | undefined>
    ) => {
      state.clusters = payload;
    },
    successLoadCameraLocation: (
      state,
      { payload }: PayloadAction<ICameraInfo[]>
    ) => {
      console.log("successLoadCameraLocation", payload);
      if (state.cameraList) {
        const psns = _.map(
          state.cameraList.DeviceListInfo,
          (dev) => dev.device.psn
        );
        state.cameraList = {
          ...state.cameraList,
          DeviceListInfo: _.map(state.cameraList.DeviceListInfo, (devInfo) => {
            let dev = _.find(payload, (pd) => pd.psn === devInfo.device.psn);
            if (dev && dev.latitude && dev.longitude) {
              const lat = parseFloat(dev.latitude);
              const lng = parseFloat(dev.longitude);
              if (lat === 0 && lng === 0) {
                dev = undefined;
              }
            }
            return {
              ...devInfo,
              device: {
                ...devInfo.device,
                ...dev,
              },
            };
          }),
        };
        const pubs = _.filter(payload, (p) => !_.includes(psns, p.psn)).map(
          (p) => {
            const info = p as IPublicCameraOrig;
            const { latitude, longitude, ...rest } = info;
            return {
              ...rest,
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            };
          }
        );
        state.publicCams = _.unionBy(pubs, state.publicCams, "psn");
        state.isLocationLoaded = true;
      }
    },
    loadPublicCameraLocation: (
      state,
      _action: PayloadAction<{ bounds: ILatLngBounds; cancel: AbortController }>
    ) => {},
    successLoadPublicCameraLocation: (
      state,
      { payload }: PayloadAction<IPublicCamera[]>
    ) => {
      console.log("successLoadPublicCameraLocation", payload);
      state.publicCams = payload;
    },
    updatePrivacy: (
      state,
      action: PayloadAction<{
        psn: string;
        agree_gps: OnOffType;
        share_gps: OnOffType;
        share_video: OnOffType;
        share_audio: OnOffType;
        share_dev_name: OnOffType;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successUpdatePrivacy: (
      state,
      {
        payload: {
          psn,
          agree_gps,
          share_audio,
          share_dev_name,
          share_gps,
          share_video,
        },
      }: PayloadAction<{
        psn: string;
        agree_gps: OnOffType;
        share_gps: OnOffType;
        share_video: OnOffType;
        share_audio: OnOffType;
        share_dev_name: OnOffType;
      }>
    ) => {
      state.cameraList = {
        ...state.cameraList,
        DeviceListInfo: _.map(state.cameraList?.DeviceListInfo, (dev) => {
          if (psn === dev.device.psn) {
            return {
              device: {
                ...dev.device,
                agree_gps,
                share_audio,
                share_dev_name,
                share_gps,
                share_video,
              },
            };
          }
          return dev;
        }),
      };
      state.loading = false;
      state.type = undefined;
    },
    loadFwUpdateInfo: (state, action: PayloadAction<string | undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    clearFwUpdateInfo: (state) => {
      state.fwUpdateInfos = [];
    },
    loadNotification: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successUpdateNotification: (
      state,
      { payload }: PayloadAction<INotificationSettings>
    ) => {
      state.loading = false;
      state.notificationSettings = payload;
    },
    updateNotification: (
      state,
      action: PayloadAction<{
        setType: "email" | "phone";
        settings: IEmailNotiSettings;
        send: OnOffType;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },

    successFwUpdateInfo: (
      state,
      { payload }: PayloadAction<IFwUpdateInfo[]>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.fwUpdateInfos = payload;
    },
    loadLiveEventSettings: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadLiveEventSettings: (
      state,
      { payload }: PayloadAction<ILiveEventSettings>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.liveEventSettings = payload;
    },
    updateLiveEventSettings: (
      state,
      action: PayloadAction<{ psn: string; setting: ILiveEventSettings }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },

    loadFirmwareInfos: () => {},
    successLoadFirmwareInfos: (
      state,
      { payload }: PayloadAction<IFirmware[]>
    ) => {
      state.firmwares = payload;
    },
    loadFirmwareConfig: (state, _action: PayloadAction<ICameraInfo>) => {},
    setFirmwareConfig: (state, { payload }: PayloadAction<IFirmwareConfig>) => {
      state.firmwareConfig = payload;
    },
    loadFirmwareSettings: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadFirmwareSettings: (
      state,
      { payload }: PayloadAction<IFirmwareSettings>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.firmware = payload;
    },
    // EmailList 조회
    loadNotificationEmailList: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadNotificationEmailList: (
      state,
      { payload }: PayloadAction<INotificationEmailList>
    ) => {
      state.loading = false;
      state.notificationEmail = payload;
    },
    // confirm mail 발송
    invite: (state, action: PayloadAction<IEmailInvite>) => {
      state.loading = true;
      state.type = action.type;
    },
    successInvite: (state) => {
      state.loading = false;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    // 본인 알림 유무 수정
    fixMasterEmailNotification: (
      state,
      action: PayloadAction<IMasterEmailNotification>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    // email 삭제
    deleteEmail: (state, action: PayloadAction<IDeleteEmail>) => {
      state.loading = true;
      state.type = action.type;
    },
    successDelete: (state) => {
      state.loading = false;
    },
    updateFirmwareSettings: (
      state,
      action: PayloadAction<{
        firmware: IFirmwareSettings;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    requestFota: (state, action: PayloadAction<IFotaIDPayload>) => {
      state.loading = true;
      state.type = action.type;
    },
    resetLocationLoaded: (state) => {
      state.isLocationLoaded = false;
    },
    loadPublicCamera: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadPublicCamera: (
      state,
      { payload }: PayloadAction<ICameraInfo>
    ) => {
      state.loading = false;
      state.publicCamera = payload;
    },
    clearPublicCamera: (state) => {
      state.publicCamera = undefined;
    },
    rebootCamera: (state, action: PayloadAction<ICameraInfo>) => {
      state.loading = true;
      state.type = action.type;
    },
  },
});

export const {
  clearCamera,
  resetCamera,
  addCamera,
  renameCamera,
  deleteCamera,
  loadCamera,
  successLoadCamera,
  loadCameras,
  successLoadCameras,
  loadCamReg,
  successLoadCamReg,
  failLoad,
  loadMyCemrasLocation,
  successLoadCameraLocation,
  updatePrivacy,
  successUpdatePrivacy,
  loadFwUpdateInfo,
  successFwUpdateInfo,
  loadNotification,
  updateNotification,
  successUpdateNotification,
  loadLiveEventSettings,
  successLoadLiveEventSettings,
  updateLiveEventSettings,
  loadFirmwareInfos,
  successLoadFirmwareInfos,
  loadMultiCamLocation,
  loadFirmwareSettings,
  successLoadFirmwareSettings,
  updateFirmwareSettings,
  loadNotificationEmailList,
  successLoadNotificationEmailList,
  invite,
  successInvite,
  clearLoading,
  fixMasterEmailNotification,
  deleteEmail,
  successDelete,
  requestFota,
  loadPublicCameraLocation,
  successLoadPublicCameraLocation,
  updateCameras,
  successUpdateCameras,
  resetLocationLoaded,
  loadPublicCamera,
  successLoadPublicCamera,
  clearPublicCamera,
  successLoadCameraCluster,
  loadFirmwareConfig,
  setFirmwareConfig,
  rebootCamera,
  clearFwUpdateInfo,
  setCameraError,
  clearCameraError,
} = slice.actions;
export const CAMERA = slice.name;
export default slice.reducer;
