import React, { useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import { Paper } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import SimpleBarReact from "simplebar-react";

const useListItemStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      "& *": {
        color: LightColors.primary["7"],
        fill: LightColors.primary["7"],
      },
    },
    textAlign: theme.direction === "rtl" ? "right" : "left",
    "&.Mui-disabled": {
      "&:hover *": {
        color: LightColors.primary["1"],
        fill: LightColors.primary["1"],
      },
    },
  },
  selected: {
    backgroundColor: "transparent!important",
    "& *": {
      color: LightColors.primary["7"],
      fill: LightColors.primary["7"],
      fontWeight: "500!important",
    },
  },
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "#13131C73",
    zIndex: 10000,
  },
  hidden: {
    display: "none",
  },
  modal: {
    position: "absolute",
    top: 39,
    left: 252,
    width: 696,
    height: 577,
    "&::after": {
      position: "absolute",
      content: "''",
      top: 38,
      bottom: 0,
      left: -23,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderRight: "24px solid white",
      borderTop: "14px solid transparent",
      borderBottom: "14px solid transparent",
    },
  },
  paper: {
    width: "100%",
    border: `1px solid ${LightColors.primary["5"]}`,
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    position: "absolute",
    paddingLeft: 8,
    top: 69,
    left: 8,
    width: 218,
    height: 42,
    display: "flex",
    justifyContent: "center",
    background: "white",
  },
  listItemRootOpen: {
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  listItemIcon: {
    ...(theme.direction === "rtl" ? { marginRight: 12 } : { marginLeft: 12 }),
    minWidth: 34,
  },
  listItemTextRoot: {
    overflow: "hidden",
  },
  menuIcon: {
    fontSize: "1.125rem",
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        }
      : {}),
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  webTitleDiv: {
    padding: theme.spacing(1.875, 3, 0, 3),
    alignItems: "center",
  },
  contentBtnWrap: {
    marginTop: theme.spacing(2.5) + 1,
    overflowY: "auto",
    padding: theme.spacing(0, 3, 3, 3),
  },
  webImgDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
  buttonWrap: {
    paddingTop: theme.spacing(6),
    alignItems: "center",
  },
  scrollbarStyle: {
    height: "100%",
    overflowX: "hidden",
    flex: 1,
    maxHeight: "calc(100vh - 120px)",
  },
}));

interface OnboardingStep2Props {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
}

export const OnboardingStep2 = ({
  open,
  onNext,
  onClose,
}: OnboardingStep2Props) => {
  const classes = useStyles();
  const listItemClasses = useListItemStyles();
  const { t, i18n } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div className={clsx(classes.root, !open && classes.hidden)}>
      <div ref={anchorRef} className={classes.btn}>
        <ListItem
          selected
          classes={{
            ...listItemClasses,
            root: clsx(listItemClasses.root, classes.listItemRootOpen),
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <VideocamIcon className={classes.menuIcon} />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.listItemTextRoot }}
            primary={
              <Typography category="Default" variant="Body">
                {i18n.language === "ja" ? t("Cameras") : "Cameras"}
              </Typography>
            }
          />
        </ListItem>
      </div>
      <div className={classes.modal}>
        <Paper elevation={3} className={classes.paper}>
          <div className={clsx(classes.spaceBetween, classes.webTitleDiv)}>
            <Typography category="Default" variant="H6">
              {t("Camera’s features")}
            </Typography>
            <IconButton onClick={onClose} style={{ marginRight: -8 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <SimpleBarReact className={classes.scrollbarStyle}>
            <div className={classes.contentBtnWrap}>
              <Typography category="Default" variant="Body">
                {t("Take a look_")}
              </Typography>
              <div className={classes.webImgDiv}>
                <img
                  srcSet="/images/onboarding2.png 1x"
                  alt="onboarding2"
                  style={{
                    width: 586,
                    height: 336,
                  }}
                />
              </div>
              <div className={clsx(classes.spaceBetween, classes.buttonWrap)}>
                <Typography category="Default" variant="Body">
                  2 / 6
                </Typography>
                <Button variant="contained" color="primary" onClick={onNext}>
                  {t("Got it")}
                </Button>
              </div>
            </div>
          </SimpleBarReact>
        </Paper>
      </div>
    </div>
  );
};
