import { Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { Webviewer } from "../contants/Breakpoints";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";

const withSimpleBar = (WrappedComponent: React.FC<any>) => (props: any) => {
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  return mobile ? (
    <WrappedComponent {...props} />
  ) : (
    <SimpleBarReact style={{ height: "calc(var(--vh, 1vh) * 100)" }}>
      <WrappedComponent {...props} />
    </SimpleBarReact>
  );
};

export default withSimpleBar;
