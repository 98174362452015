////////////////////////////////////////////////////////////////////////////////////////
// 리액트 기능 임포트
import React, { useEffect, useState } from "react";
import { makeStyles, Theme, FormControlLabel } from "@material-ui/core";
// -- 다국어 지원 텍스트 컴포넌트
import { useTranslation } from "react-i18next";
// -- 타이포
import { Typography } from "@thingsw/pitta-design-system";
// -- 체크박스, 라디오 버튼, 탭 메뉴
import RadioGroup from "@material-ui/core/RadioGroup";
import { CheckBox, RadioButton } from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUserNotification,
  loadUserSettings,
  setUserNotification,
  updateUserSettings,
  USER,
} from "../../features/User/slice";
import { RootState } from "../../features/store";

////////////////////////////////////////////////////////////////////////////////////////
// CSS 스타일
const useStyles = makeStyles((theme: Theme) => ({
  // 1. 루트
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  // 1-1. 박스 그룹
  boxGroupDiv: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(1.125),
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1.125),
    },
  },
  // 1-1-1. 타이포
  typoDiv: {
    display: "flex",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      padding: 0,
    },
  },
  // 1-1-2. 라디오 박스 그룹
  boxRadioDiv: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(0.875),
      "&>.MuiFormControlLabel-root": {
        margin: "8px 0px 8px -1px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.875),
      "&>.MuiFormControlLabel-root": {
        margin: "8px 0px 8px -1px",
      },
    },
  },
  // 1-1-3. 폼
  formDiv: {
    display: "flex",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      margin: "14px 0px 3px -3px",
      "&>.MuiButtonBase-root": {
        padding: "0px !important",
        marginRight: theme.spacing(0.5),
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "14px 0px 3px -3px",
      "&>.MuiButtonBase-root": {
        padding: "0px !important",
        marginRight: theme.spacing(0.75),
        marginLeft: theme.spacing(0.125),
      },
    },
  },
}));

////////////////////////////////////////////////////////////////////////////////////////
// 실제 컴포넌트
export const SettingsPanel = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userSettings, userNotification } = useSelector(
    (state: RootState) => state[USER]
  );

  // 라디오 버튼용 스테이트
  const [mapType, setMapType] = React.useState("0");
  const [velocityUnit, setVelocityUnit] = React.useState("0");
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    dispatch(loadUserNotification());
    dispatch(loadUserSettings());
  }, [dispatch]);

  useEffect(() => {
    if (userSettings) {
      setMapType(userSettings.mapType);
      setVelocityUnit(userSettings.velocityUnit);
    }

    if (userNotification) {
      if (
        userNotification.device_alarm === "on" &&
        userNotification.notice_alarm === "on" &&
        userNotification.system_alarm === "on"
      ) {
        setNotification(true);
      } else if (
        userNotification.device_alarm === "off" &&
        userNotification.notice_alarm === "off" &&
        userNotification.system_alarm === "off"
      ) {
        setNotification(false);
      }
    }
  }, [userNotification, userSettings]);

  // 라디오 버튼용 핸들체인지 1: mapType 2: velocityUnit

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setVelocityUnit((event.target as HTMLInputElement).value);
    dispatch(
      updateUserSettings({
        mapType: mapType,
        velocityUnit: value,
      })
    );
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setMapType(value);
    dispatch(
      updateUserSettings({
        mapType: value,
        velocityUnit: velocityUnit,
      })
    );
  };

  const handleNotification = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const checkNotification = event.target.checked;
    setNotification(checked);
    dispatch(
      setUserNotification({
        device_alarm: checkNotification ? "on" : "off",
        notice_alarm: checkNotification ? "on" : "off",
        system_alarm: checkNotification ? "on" : "off",
      })
    );
  };

  // 출력
  return (
    <div className={classes.root}>
      {/* 1. 박스그룹 */}
      <div className={classes.boxGroupDiv}>
        {/* 1-1. 타이포 */}
        <Typography className={classes.typoDiv} category="Default" variant="H6">
          {t("Speed units")}
        </Typography>
        {/* 1-2. 라디오 그룹 */}
        <RadioGroup
          className={classes.boxRadioDiv}
          aria-label="Speed units"
          name="Units1"
          value={velocityUnit}
          onChange={handleChange1}
        >
          <RadioButton value="0" label={t("km/h")} />
          <RadioButton value="1" label={t("MPH")} />
        </RadioGroup>
      </div>

      {/* 2. 박스그룹 */}
      <div className={classes.boxGroupDiv}>
        {/* 2-1. 타이포 */}
        <Typography className={classes.typoDiv} category="Default" variant="H6">
          {t("Map service")}
        </Typography>
        {/* 2-2. 라디오 그룹 */}
        <RadioGroup
          className={classes.boxRadioDiv}
          aria-label="Map service"
          name="Units2"
          value={mapType}
          onChange={handleChange2}
        >
          <RadioButton value="0" label={t("Google Maps")} />
        </RadioGroup>
      </div>

      {/* 3. 박스그룹 */}
      <div className={classes.boxGroupDiv}>
        {/* 3-1. 타이포 */}
        <Typography className={classes.typoDiv} category="Default" variant="H6">
          {t("Notifications")}
        </Typography>
        {/* 3-2. 체크박스 1 */}
        <FormControlLabel
          className={classes.formDiv}
          control={
            <CheckBox
              color="primary"
              checked={notification}
              onChange={handleNotification}
            />
          }
          label={
            <Typography category="Default" variant="Body">
              {t("Push Notifications")}
            </Typography>
          }
        />
      </div>
    </div>
  );
};
