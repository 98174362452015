import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  makeStyles,
  RadioGroup,
  Theme,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  LightColors,
  RadioButton,
  Typography,
} from "@thingsw/pitta-design-system";

import { Webviewer } from "../../contants/Breakpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  IFirmwareCloudSettings,
  TabNameInfo1,
  TrueFalsetype,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import _ from "lodash";
import { IGroupUserList } from "../../features/Group/slice";
import { FirmwareLanguages } from "../../contants/Languages";
import { FirmwareSettingModal } from "./FirmwareSettingModal";
import { FirmwareFormatModal } from "./FirmwareFormatModal";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    flexWrap: "wrap",
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 4,
    minWidth: 288,
    padding: "9px 10px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxHeight: 300,
      border: `1px solid ${LightColors.primary["0"]}`,
    },
  },

  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  mgR64: {
    ...(theme.direction === "rtl" ? { marginLeft: 64 } : { marginRight: 64 }),
  },
  boxMg: {
    margin: "11.5px 0 32px 0",
  },
  radioMg: {
    padding: 3,
    paddingLeft: 10,
    marginRight: 3,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingLeft: 3,
    },
  },
}));

interface LanguageFirmwarePanelProps {
  openMenu?: boolean;
  user?: IGroupUserList;
}

export const LanguageFirmwarePanel = ({
  openMenu,
  user,
}: LanguageFirmwarePanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { camera, firmware, firmwareConfig, loading } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [current, setCurrent] = useState<string>("English");
  const [openFormatModal, setOpenFormatModal] = useState(false);
  const [disabledChange, setDisabledChange] = useState(true);

  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);

  const timeSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const settings = useMemo(() => {
    return firmwareConfig?.FirmwareLanguage;
  }, [firmwareConfig?.FirmwareLanguage]);

  useEffect(() => {
    if (!loading) {
      setOpenFormatModal(false);
    }
  }, [loading]);

  useEffect(() => {
    if (firmware) {
      setCurrent(firmware.firmware_language);
    }
  }, [firmware]);

  useEffect(() => {
    if (firmware) {
      setDisabledChange(firmware.firmware_language === current);
    }
  }, [current, firmware]);

  const LockEventModel = useMemo(
    () =>
      _.includes(
        [
          "DR750X-3CH Plus",
          "DR750X LTE Plus",
          "DR750-2CH LTE",
          "DR900X Plus",
          "DR750X Plus",
          "BlackVue 7",
          "BlackVue 9",
        ],
        camera?.model
      ),
    [camera?.model]
  );
  const handleUpdate = () => {
    if (firmware) {
      if (current === "Japan" /*|| current === "Korea"*/) {
        const updated = {
          ...firmware,
          cloud_settings: _.filter(
            firmware.cloud_settings,
            (c) => c.section_name !== "Tab1" && c.section_name !== "Tab3"
          ),
          update_date: undefined,
        };
        let basic = _.find(
          firmware.cloud_settings,
          (c) => c.section_name === "Tab1"
        ) as IFirmwareCloudSettings;

        let system = _.find(
          firmware.cloud_settings,
          (c) => c.section_name === "Tab3"
        ) as IFirmwareCloudSettings;

        if (current === "Japan") {
          system = {
            ...system,
            section_info: {
              ...system?.section_info,
              NORMALLED: "0" as TrueFalsetype,
              PARKINGLED: "0" as TrueFalsetype,
              RearLED: "1" as TrueFalsetype,
              WifiLED: "1" as TrueFalsetype,
            },
          };
          if (LockEventModel) {
            basic = {
              ...basic,
              section_info: {
                ...basic?.section_info,
                LockEvent: "1" as TrueFalsetype,
                OverwriteLock: "1" as TrueFalsetype,
              },
            };
          }
        }
        if (
          timeSettings?.["TimeZone"] === "900" &&
          timeSettings?.GpsSync === "1"
        ) {
          updated.cloud_settings = [...updated.cloud_settings, basic, system];
          dispatch(
            updateFirmwareSettings({
              firmware: { ...updated, firmware_language: current },
            })
          );
        } else {
          updated.cloud_settings = [
            ...updated.cloud_settings,
            {
              ...basic,
              section_info: {
                ...basic.section_info,
                TimeSet: "1",
                TimeZone: "900",
                GpsSync: "1",
              },
            },
            system,
          ];
          dispatch(
            updateFirmwareSettings({
              firmware: { ...updated, firmware_language: current },
            })
          );
        }
      } else {
        dispatch(
          updateFirmwareSettings({
            firmware: { ...firmware, firmware_language: current },
          })
        );
      }
    }
  };

  return (
    <>
      <div className={classes.boxMg} dir={theme.direction}>
        <FormControl>
          <RadioGroup
            className={classes.box}
            value={current}
            onChange={(e) => {
              setCurrent(e.target.value);
            }}
          >
            {_.chain(settings)
              .map((language, index) => (
                <RadioButton
                  key={`language${index}`}
                  className={classes.radioMg}
                  labelClassName={classes.mgR64}
                  value={language}
                  label={
                    <Typography category="Default" variant="Body">
                      {FirmwareLanguages[language]}
                    </Typography>
                  }
                />
              ))
              .value()}
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <Button
          className={classes.buttonSize}
          variant="contained"
          color="primary"
          disabled={disabledChange}
          onClick={() => {
            if (
              firmware?.firmware_language !== current &&
              current === "Japan"
            ) {
              if (
                timeSettings?.["TimeZone"] === "900" &&
                timeSettings?.GpsSync === "1"
              ) {
                setOpenFirmwareModal(true);
              } else {
                setOpenFormatModal(true);
              }
            } else {
              handleUpdate();
            }
          }}
        >
          {t("Save")}
        </Button>
        {openFormatModal && (
          <FirmwareFormatModal
            open={openFormatModal}
            loading={loading}
            onClose={() => setOpenFormatModal(false)}
            onClickNegative={() => setOpenFormatModal(false)}
            onClickPositive={() => {
              handleUpdate();
            }}
          />
        )}
      </div>
      <FirmwareSettingModal
        open={openFirmwareModal}
        loading={loading}
        onClose={() => setOpenFirmwareModal(false)}
        onClickNegative={() => setOpenFirmwareModal(false)}
        onClickPositive={() => {
          handleUpdate();
          setOpenFirmwareModal(false);
        }}
      />
    </>
  );
};
