import { LightColors, Tooltip, Typography } from "@thingsw/pitta-design-system";
import React from "react";

export const genTooltip = (
  element: React.ReactElement,
  title: string,
  open?: boolean,
  modifiers?: object,
  open2?: boolean
) => {
  return open || open2 ? (
    element
  ) : (
    <Tooltip
      title={
        <Typography
          category="Default"
          variant="Caption"
          htmlColor={LightColors.primary["5"]}
        >
          {title}
        </Typography>
      }
      aria-label={title}
      placement="right"
      PopperProps={{ modifiers }}
    >
      {element}
    </Tooltip>
  );
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
