import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { UpgradePlanPanel } from "../components/UpgradePlanPanel";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { GeofenceScreen } from "../screens/GeofenceScreen";

export const GeofenceRouter = (props: ScreenDefaultProps) => {
  const { plan } = props;
  const { path } = useRouteMatch();

  if (plan === "Fleet plan") {
    return (
      <Switch>
        <Route exact path={path} render={() => <GeofenceScreen {...props} />} />
        <Route
          exact
          path={`${path}/add-geofence`}
          render={() => <GeofenceScreen {...props} mode="add" />}
        />
      </Switch>
    );
  }

  if (plan === "Free plan" || plan === "Basic plan" || plan === "Smart plan") {
    return <UpgradePlanPanel plan={plan} />;
  }

  return <div></div>;
};
