import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ROWS_PER_PAGE } from "../../contants/List";
import { IGroupNameForm, ListPaging, StatusCode, UserType } from "../../types";

export interface DashCamList {
  GroupID: string;
  GroupName: string;
  CreateDT: string;
  SubMasterEmail: string;
  SubMasterFirstName: string;
  SubMasterLastName: string;
  SubMasterStatus: StatusCode;
  SubMasterRole: UserType;
  SubMasterProfilePath?: string;
  DashCamUser?: IDashCamInfo[];
}

export interface IDashCamInfo {
  PSN: string;
  DashCamName: string;
  Users: IUserInfo[];
}

export interface IUserInfo {
  Email: string;
  FirstName: string;
  LastName: string;
  Status: StatusCode;
  profilePath?: string;
}

export interface IDashCamList {
  DashCamList?: DashCamList[];
}

// dashcam 내 user 조회
export interface IGroupUserList {
  Users: IUserInfo[];
}

// group rename
export interface IChangeGroupName {
  groupID: string;
  groupName: string;
}

// group에 dashCam 추가 또는 삭제
export interface IGroupAddDelCamera {
  PSN: string;
  GroupName: string;
  GroupID: string;
}

//Cam에 user 할당
export interface IAddUserFromCam {
  GroupID: string;
  PSN: string;
  UserEmail: string[];
}

//Cam에 user 삭제
export interface IDelUserFromCam {
  GroupID: string;
  PSN: string;
  UserEmail: string;
}

//group에서 admin 추가 또는 삭제
export interface IAddDelAdmin {
  guestEmail: string;
  groupManagementID: string;
}

export interface IGroupNameList {
  groupNameList: string;
}

export interface IGroupInfo {
  createDT: string;
  createUT: number;
  groupManagementID: string;
  groupName: string;
  subMasterEmail?: string;
  subMasterFirstName?: string;
  subMasterLastName?: string;
  userCount?: number;
  deviceCount?: number;
}

export interface IGroupList {
  groupCount?: number;
  groupListInfo?: IGroupInfo[];
}

interface IState {
  loading: boolean;
  groupsList?: IDashCamList;
  usersList?: IGroupUserList;
  groupsListPaging?: IGroupList;
  type?: string;
  selected?: string[];
  allChecked?: string;
  listPaging: ListPaging;
}

let initialState: IState = {
  loading: false,
  groupsList: undefined,
  usersList: undefined,
  groupsListPaging: undefined,
  selected: [],
  allChecked: "none",
  listPaging: {
    startIndex: 1,
    endIndex: ROWS_PER_PAGE,
    ordering: 0,
  },
};

const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    clearGroup: (state) => {
      state.loading = false;
      state.groupsList = undefined;
      state.usersList = undefined;
      state.groupsListPaging = undefined;
      state.type = undefined;
      state.selected = undefined;
      state.allChecked = undefined;
      state.listPaging = {
        startIndex: 1,
        endIndex: ROWS_PER_PAGE,
        ordering: 0,
      };
    },
    loadGroups: (state) => {
      state.loading = true;
    },
    successLoadGroups: (state, { payload }: PayloadAction<IDashCamList>) => {
      state.loading = false;
      state.groupsList = payload;
    },
    loadGroupsPaging: (state, { payload }: PayloadAction<ListPaging>) => {
      state.loading = true;
      state.listPaging = payload;
    },
    successLoadGroupsPaging: (
      state,
      { payload }: PayloadAction<IGroupList>
    ) => {
      state.loading = false;
      state.groupsListPaging = payload;
    },
    failLoad: (state) => {
      state.loading = false;
    },
    addGroup: (state, _action: PayloadAction<IGroupNameForm>) => {
      state.loading = true;
    },
    successAdd: (state) => {
      state.loading = false;
    },
    changeGroupName: (state, action: PayloadAction<IChangeGroupName>) => {
      state.loading = true;
      state.type = action.type;
    },
    successChange: (state) => {
      state.loading = false;
      state.type = undefined;
    },
    deleteGroup: (state, action: PayloadAction<string[]>) => {
      state.loading = true;
      state.type = action.type;
    },
    loadGroupUser: (state, action: PayloadAction<undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGroupsUser: (
      state,
      { payload }: PayloadAction<IGroupUserList>
    ) => {
      state.loading = false;
      state.usersList = payload;
    },
    groupAddCameras: (state, action: PayloadAction<IGroupAddDelCamera[]>) => {
      state.loading = true;
      state.type = action.type;
    },
    deleteCamera: (state, action: PayloadAction<IGroupAddDelCamera[]>) => {
      state.loading = true;
      state.type = action.type;
    },
    addUser: (state, action: PayloadAction<IAddUserFromCam>) => {
      state.loading = true;
      state.type = action.type;
    },
    deleteUser: (state, action: PayloadAction<IDelUserFromCam>) => {
      state.loading = true;
      state.type = action.type;
    },
    success: (state) => {
      state.loading = false;
    },
    deleteAdmin: (state, action: PayloadAction<IAddDelAdmin>) => {
      state.loading = true;
      state.type = action.type;
    },
    addAdmin: (state, action: PayloadAction<IAddDelAdmin>) => {
      state.loading = true;
      state.type = action.type;
    },
    clearCheckBox: (state) => {
      state.selected = [];
      state.allChecked = "none";
    },
  },
});

export const {
  clearGroup,
  addGroup,
  successAdd,
  loadGroups,
  successLoadGroups,
  loadGroupsPaging,
  successLoadGroupsPaging,
  failLoad,
  changeGroupName,
  successChange,
  deleteGroup,
  groupAddCameras,
  loadGroupUser,
  deleteCamera,
  addUser,
  successLoadGroupsUser,
  deleteUser,
  success,
  deleteAdmin,
  addAdmin,
  clearCheckBox,
} = slice.actions;

export const GROUP = slice.name;
export default slice.reducer;
