import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { CameraInfo, LightColors } from "@thingsw/pitta-design-system";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { PSN650 } from "../contants/Models";
import { CAMERA, ICameraInfo } from "../features/Camera/slice";
import { RootState } from "../features/store";
import { ReferenceObject } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  cameraPaper: {
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    border: `1px solid ${LightColors.primary["5"]}`,
  },
  cameraListDiv: {
    width: 286,
    maxHeight: "50vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

export interface CameraListPopperProps {
  open: boolean;
  disabled?: boolean;
  /**
   * A HTML element, [referenceObject](https://popper.js.org/docs/v1/#referenceObject),
   * or a function that returns either.
   * It's used to set the position of the popper.
   * The return value will passed as the reference object of the Popper instance.
   */
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
  /**
   * Callback fired when a "click away" event is detected.
   */
  onClickAway: (event: React.MouseEvent<Document>) => void;
  cameraName: string;
  onClick: (cam: ICameraInfo) => void;
  width?: number;
}

export const CameraListPopper = ({
  open,
  anchorEl,
  cameraName,
  width,
  onClickAway,
  onClick,
  disabled,
}: CameraListPopperProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

  return (
    <Popper
      dir={theme.direction}
      open={open}
      anchorEl={anchorEl}
      modifiers={{
        offset: {
          enabled: true,
          offset: "0, 4",
        },
      }}
      style={{
        zIndex: 99,
      }}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: "center top",
          }}
        >
          <Paper classes={{ root: classes.cameraPaper }} elevation={0}>
            <ClickAwayListener onClickAway={onClickAway}>
              <div className={classes.cameraListDiv} style={{ width }}>
                {_.chain(cameraList?.DeviceListInfo)
                  .filter(
                    (dev) =>
                      dev.device.dev_name
                        .toLowerCase()
                        .indexOf(cameraName.toLowerCase()) > -1 ||
                      dev.device.model
                        .toLowerCase()
                        .indexOf(cameraName.toLowerCase()) > -1
                  )
                  .map((dev) => (
                    <CameraInfo
                      disabled={_.includes(PSN650, dev.device.psn.substr(0, 4))}
                      key={dev.device.psn}
                      camera={dev.device}
                      onClick={() => {
                        onClick(dev.device);
                      }}
                      hover
                    />
                  ))
                  .value()}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
