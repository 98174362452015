import React, { useEffect } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { LightColors } from "@thingsw/pitta-design-system";

import { Webviewer } from "../contants/Breakpoints";

import { AddCameraHeader } from "../components/AddCameraHeader";
import ChooseConnectForm from "../components/cameras/ChooseConnectForm";
import { useMediaQuery } from "@material-ui/core";
import { MiniAddCameraHeader } from "../components/MiniAddCameraHeader";
import { MinimalFooter } from "../components/MinimalFooter";
import { useDispatch } from "react-redux";
import { loadUserProfile } from "../features/User/slice";
import { loadSubscription } from "../features/Payment/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(7),
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    height: "calc(var(--vh, 1vh) * 100 - 56px -52px)",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2.125, 2),
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
      height: "calc(var(--vh, 1vh) * 100 - 56px)",
    },
  },
}));
export const CameraChooseConnectScreen = () => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProfile());
    dispatch(loadSubscription());
  }, [dispatch]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <AddCameraHeader />
      {mobile && <MiniAddCameraHeader />}
      <div className={classes.body}>
        <ChooseConnectForm />
      </div>
      {mobile && (
        <div style={{ paddingTop: 8 }}>
          <MinimalFooter />
        </div>
      )}
    </div>
  );
};
