import { PayloadAction } from "@reduxjs/toolkit";
import { goBack } from "connected-react-router";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IGroupNameForm, ListPaging, RESULT_CODE } from "../../types";
import { RootState } from "../store";
import { IUserLoginInfo, logout, USER } from "../User/slice";
import {
  addGroup,
  loadGroups,
  successAdd,
  successLoadGroups,
  failLoad,
  IDashCamList,
  IChangeGroupName,
  successChange,
  changeGroupName,
  deleteGroup,
  groupAddCameras,
  IGroupAddDelCamera,
  loadGroupUser,
  deleteCamera,
  addUser,
  IAddUserFromCam,
  successLoadGroupsUser,
  IGroupUserList,
  deleteUser,
  IDelUserFromCam,
  loadGroupsPaging,
  successLoadGroupsPaging,
  IGroupList,
  deleteAdmin,
  IAddDelAdmin,
  addAdmin,
  GROUP,
} from "./slice";
import * as Api from "../../apis";
import { openToast } from "../Toast/slice";
import { AxiosResponse } from "axios";
import { setError } from "../Error/slice";
import { loadCameras } from "../Camera/slice";

function* handleAddGroup({ payload }: PayloadAction<IGroupNameForm>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.addGroup, email, user_token, payload);
    const { resultcode, message } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Group added" }));
      yield put(successAdd());
      yield put(goBack());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(failLoad());
      yield put(setError(message));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadGroupPaging({ payload }: PayloadAction<ListPaging>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getGroupsListPaging,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode, message, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IGroupList;
    };
    if (response.groupCount) {
      if (resultcode === "BC_ERR_OK") {
        yield put(successLoadGroupsPaging(response));
      } else if (resultcode === "BC_ERR_AUTHENTICATION") {
        yield put(logout());
      } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
        yield put(failLoad());
        yield put(setError(message));
      }
    } else {
      yield put(failLoad());
    }
  
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleLoadGroup() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getGroupsList, email, loginInfo.user_token);
    const { resultcode, message, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IDashCamList;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadGroups(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(failLoad());
      yield put(setError(message));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleChangeGroupName({ payload }: PayloadAction<IChangeGroupName>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const listPaging = yield select(
      (state: RootState) => state[GROUP].listPaging
    );
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.changeGroupName,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode, message } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successChange());
      yield put(loadGroupsPaging(listPaging));
      yield put(loadGroups());
      yield put(openToast({ message: "Group renamed" }));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(failLoad());
      yield put(setError(message));
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleDeleteGroup({ payload }: PayloadAction<string[]>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const listPaging = yield select(
      (state: RootState) => state[GROUP].listPaging
    );
    const res = yield call(
      Api.deleteGroup,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Group deleted" }));
      yield put(loadGroups());
      yield put(loadGroupsPaging(listPaging));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleGroupAddCamera({
  payload,
}: PayloadAction<IGroupAddDelCamera[]>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = (yield call(
      Api.addCam,
      email,
      loginInfo.user_token,
      payload
    )) as AxiosResponse;
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Camera added" }));
      yield put(loadGroups());
      yield put(loadCameras());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleDeleteCamera({ payload }: PayloadAction<IGroupAddDelCamera[]>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = (yield call(
      Api.deleteCam,
      email,
      loginInfo.user_token,
      payload
    )) as AxiosResponse;
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Camera removed from_" }));
      yield put(loadGroups());
      yield put(loadCameras());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleGroupUserList() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = (yield call(
      Api.getUserList,
      email,
      loginInfo.user_token
    )) as AxiosResponse;
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IGroupUserList;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadGroupsUser(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleAddUserFromCam({ payload }: PayloadAction<IAddUserFromCam>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.addUserFromCam,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadGroups());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleDelUserFromCam({ payload }: PayloadAction<IDelUserFromCam>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.deleteUser,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Driver removed from_" }));
      yield put(loadGroups());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleDelAdmin({ payload }: PayloadAction<IAddDelAdmin>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.deleteAdmin,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Admin removed from_" }));
      yield put(loadGroups());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

function* handleAddAdminFromGroup({ payload }: PayloadAction<IAddDelAdmin>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.addAdmin, email, loginInfo.user_token, payload);
    const { resultcode } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(loadGroups());
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    }
  } catch (err) {
    yield put(failLoad());
    yield put(setError(err.message));
  }
}

export function* watchGroup() {
  yield takeLatest(addGroup, handleAddGroup);
  yield takeLatest(loadGroups, handleLoadGroup);
  yield takeLatest(loadGroupsPaging, handleLoadGroupPaging);
  yield takeLatest(changeGroupName, handleChangeGroupName);
  yield takeLatest(deleteGroup, handleDeleteGroup);
  yield takeLatest(groupAddCameras, handleGroupAddCamera);
  yield takeLatest(loadGroupUser, handleGroupUserList);
  yield takeLatest(deleteCamera, handleDeleteCamera);
  yield takeLatest(addUser, handleAddUserFromCam);
  yield takeLatest(deleteUser, handleDelUserFromCam);
  yield takeLatest(deleteAdmin, handleDelAdmin);
  yield takeLatest(addAdmin, handleAddAdminFromGroup);
}
