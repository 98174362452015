import _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Languages from "../contants/Languages";
import { changeDirection, setFontFamily } from "../features/Theme/slice";
import { useQuery } from "../hooks/useQuery";
import { MaintenancePreScreen } from "../screens/app/MaintenancePreScreen";
import { MaintenancingScreen } from "../screens/app/MaintenancingScreen";

export const MaintenanceRouter = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const query = useQuery();
  const { i18n } = useTranslation();

  useEffect(() => {
    let lang = query.get("lang");
    if (!_.includes(_.keys(Languages), lang)) {
      lang = "en";
    }
    switch (lang) {
      case "ar":
        i18n.changeLanguage("ar");
        dispatch(changeDirection("rtl"));
        dispatch(setFontFamily("Noto Naskh Arabic UI"));
        break;
      case "de":
      case "es":
      case "fi":
      case "fr":
      case "id":
      case "it":
      case "nb":
      case "nl":
      case "pl":
      case "ru":
      case "vi":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Roboto"));
        break;
      case "th":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans Thai UI"));
        break;
      case "ja":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans JP"));
        break;
      case "ko":
        i18n.changeLanguage(lang);
        dispatch(setFontFamily("Noto Sans KR"));
        break;
      case "zh":
      case "sc":
        i18n.changeLanguage("sc");
        dispatch(setFontFamily("Noto Sans SC"));
        break;
      default:
        i18n.changeLanguage("en");
        dispatch(setFontFamily("Roboto"));
    }
  }, [dispatch, i18n, query]);

  return (
    <Switch>
      <Route path={`${path}/pre`} component={MaintenancePreScreen} />
      <Route path={`${path}/ing`} component={MaintenancingScreen} />
    </Switch>
  );
};
