import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { loadNotification } from "../../features/Camera/slice";
import { PushNotificationPanel } from "./PushNotificationPanel";
import { EmailNotificationPanel } from "./EmailNotificationPanel";
import { Webviewer } from "../../contants/Breakpoints";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPlanFromServiceID } from "../../utils/Service";
import { RootState } from "../../features/store";
import { PAYMENT } from "../../features/Payment/slice";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    minHeight: 48,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      maxHeight: 96,
      marginTop: 16,
    },
  },
  boderBottomBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    padding: "0 13px 0 15px",
    justifyContent: "space-between",
    // borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      cursor: "pointer",
    },
  },
  boderNoneBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    padding: "0 13px 0 15px",
    justifyContent: "space-between",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      cursor: "pointer",
    },
  },
  pd0: {
    padding: 0,
  },
  ArrowBack: {
    maxWidth: "100%",
    maxHeight: 21,
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  ArrowBackPd: {
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 7 }
      : { paddingLeft: 0, paddingRight: 7 }),
  },
}));

export const NotificationSettingPanel = () => {
  const classes = useStyles();
  const [notiMenu, setNotiMenu] = useState<number>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNotification());
  }, [dispatch]);

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  return (
    <div>
      {notiMenu === undefined && (
        <div className={classes.bigBox}>
          <div
            className={classes.boderBottomBox}
            onClick={() => setNotiMenu(0)}
          >
            <Typography category="Default" variant="Body">
              {t("Push Notifications")}
            </Typography>
            <IconButton className={classes.pd0}>
              <ChevronRightIcon></ChevronRightIcon>
            </IconButton>
          </div>
          {subscriptionInfo &&
            getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
              "Fleet plan" && (
              <div
                className={classes.boderNoneBox}
                onClick={() => setNotiMenu(1)}
              >
                <Typography category="Default" variant="Body">
                  {t("Email notifications")}
                </Typography>
                <IconButton className={classes.pd0}>
                  <ChevronRightIcon></ChevronRightIcon>
                </IconButton>
              </div>
            )}
        </div>
      )}

      {notiMenu === 0 && (
        <div>
          <div
            className={classes.ArrowBack}
            onClick={() => setNotiMenu(undefined)}
          >
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("Push Notifications")}
            </Typography>
          </div>
          <PushNotificationPanel />
        </div>
      )}
      <>
        {notiMenu === 1 && (
          <div>
            <div
              className={classes.ArrowBack}
              onClick={() => setNotiMenu(undefined)}
            >
              <IconButton className={classes.ArrowBackPd}>
                <ArrowBackIcon></ArrowBackIcon>
              </IconButton>
              <Typography category="Default" variant="H6">
                {t("Email notifications")}
              </Typography>
            </div>
            <EmailNotificationPanel open={true} />
            {/* <EmailPanel onBack={() => setNotiMenu(undefined)} /> */}
          </div>
        )}
      </>
    </div>
  );
};
