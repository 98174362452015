import axios from "axios";
import { API_GATEWAY_URI, STRIPE_OS_TYPE } from "../contants/Server";

export const getSubscription = (email: string, userToken: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetSubscrInfo?email=${email}&user_token=${userToken}&tokenType=web`
  );
};

export const getWebSubscription = (email: string, user_token: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetInWebSubscrCustomer?email=${email}&user_token=${user_token}&ostype=${STRIPE_OS_TYPE}`
  );
};

export const getTransactionHistory = (email: string, user_token: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetInWebSubscrTransactions?email=${email}&user_token=${user_token}&tokenType=web&ostype=${STRIPE_OS_TYPE}`
  );
};

export const getFreetrialState = (email: string, user_token: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetCheckSubscrTrial?email=${email}&user_token=${user_token}&tokenType=web`
  );
};

export const requestCancelSubscription = (
  email: string,
  user_token: string,
  ostype: string,
  reason?: string
) => {
  return axios.post(`${API_GATEWAY_URI}/Payment/WebSubscribeCancelUpdate`, {
    email,
    user_token,
    ostype,
    reason,
    tokenType: "web",
  });
};

// export const doWebSubscribe = (
//   email: string,
//   user_token: string,
//   body: Payment
// ) => {
//   return axios.post(`${API_GATEWAY_URI}//Payment/WebSubscribe`, {
//     email,
//     user_token,
//     ...body,
//     osType: STRIPE_OS_TYPE,
//   });
// };
