import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../store";
import { IUserLoginInfo, logout, USER } from "../User/slice";
import {
  addGeofence,
  IGeofence,
  loadGeofences,
  successRequestGeofence,
  successLoadGeofences,
  deleteGeofence,
  updateGeofence,
  clearLoading,
} from "./slice";
import * as API from "../../apis";
import { goBack, push } from "connected-react-router";
import { openToast } from "../Toast/slice";
import { setError } from "../Error/slice";

function* handleLoadGeofences() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      API.getGeofences,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token
    );

    const { fenceData } = resp.data as { fenceData: IGeofence[] };
    // console.log(resp.data);
    if (fenceData !== undefined) {
      yield put(successLoadGeofences(fenceData));
    } else {
      yield put(successLoadGeofences([]));
    }
  } catch (err) {
    const { response } = err;
    console.error("handleLoadGeofences", err);
    if (response?.status === 401) {
      yield put(logout());
    } else if (response?.status === 500) {
      yield put(push("/Err500"));
    } else {
      yield put(setError(err.message));
    }
  }
}

function* handleAddGeofence({ payload }: PayloadAction<IGeofence>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      API.addGeofence,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token,
      payload
    );
    yield put(successRequestGeofence());
    yield put(loadGeofences());
    yield put(openToast({ message: "Zone added" }));
    yield put(goBack());
  } catch (err) {
    yield put(clearLoading());
    console.error(err);
  }
}

function* handleUpdateGeofence({ payload }: PayloadAction<IGeofence>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      API.addGeofence,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token,
      payload
    );
    yield put(successRequestGeofence());
    yield put(loadGeofences());
    yield put(openToast({ message: "Changes saved" }));
    yield put(loadGeofences());
  } catch (err) {
    yield put(clearLoading());
    console.error(err);
  }
}

function* handleDeleteGeofence({
  payload,
}: PayloadAction<{ fenceId: string; fenceName: string }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token, geo_fence_server, geo_fence_port } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    yield call(
      API.deleteGeofence,
      geo_fence_server,
      geo_fence_port,
      email,
      user_token,
      payload.fenceId,
      payload.fenceName
    );
    yield put(successRequestGeofence());
    yield put(openToast({ message: "Zone deleted" }));
    yield put(loadGeofences());
  } catch (err) {
    yield put(clearLoading());
    console.error(err);
  }
}

export function* watchGeofence() {
  yield takeLatest(addGeofence, handleAddGeofence);
  yield takeLatest(updateGeofence, handleUpdateGeofence);
  yield takeLatest(loadGeofences, handleLoadGeofences);
  yield takeLatest(deleteGeofence, handleDeleteGeofence);
}
