import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  open: boolean;
  message?: string;
  option?: any;
}

const initialState: IState = {
  open: false,
  message: undefined,
};

const slice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    openToast: (
      state,
      { payload }: PayloadAction<{ message: string; option?: any }>
    ) => {
      state.open = true;
      state.message = payload.message;
      state.option = payload.option;
    },
    closeToast: (state) => {
      state.open = false;
      state.message = undefined;
      state.option = undefined;
    },
  },
});

export const { openToast, closeToast } = slice.actions;
export const TOAST = slice.name;
export default slice.reducer;
