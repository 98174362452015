import React, { useCallback, useMemo, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

import { FirebaseAuthConsumer } from "@react-firebase/auth";
import axios from "axios";
import { API_GATEWAY_URI } from "../contants/Server";
import { RESULT_CODE, SNS_LINKAGE } from "../types";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { APP_VERSION, MOBILE_NAME, TOKEN_TYPE } from "../contants/Login";
import { SignupMode, USER, userLogin } from "../features/User/slice";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Webviewer } from "../contants/Breakpoints";
import { RootState } from "../features/store";
import { MobileLang } from "../contants/Languages";
import moment from "moment";

export interface FirebaseAuthProps {}

interface IState {
  mode?: SignupMode;
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    maxWidth: 448,
  },
}));

export const FirebaseAuth = () => {
  const location = useLocation();
  const mode = (location.state as IState)?.mode;

  const classes = useStyles();
  const history = useHistory();
  const { pathname } = history.location;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mobile_uuid } = useSelector((state: RootState) => state[USER]);
  const [currentUserIn, setCurrentUserIn] = useState<firebase.User>();
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const [openAnotherPlatformModal, setOpenAnotherPlatformModal] =
    useState(false);
  const [signupType, setSignupType] = useState<string>("blackvue");
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  // console.log("FirebaseAuth", pathname);

  const handleFirebaseAuth = useCallback(
    async ({ isSignedIn, user, providerId }) => {
      // console.log("handleFirebaseAuth", isSignedIn, user);

      if (pathname.startsWith("/signup") || pathname.startsWith("/login")) {
        const signupType = providerId === "google.com" ? "google" : "apple";
        setSignupType(signupType);
        const currentUser = firebase.auth().currentUser;
        // console.log("currentUser", currentUser);
        if (currentUser) {
          setCurrentUserIn(currentUser);
          const { email, displayName, photoURL } = currentUser;
          const idToken = await firebase.auth().currentUser?.getIdToken();
          if (idToken && email) {
            const prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
            const lang =
              prefLang || navigator.language.toLowerCase().substring(0, 2);
            const resp = await axios.get(
              `${API_GATEWAY_URI}/Account/GetConfirmLinkage?email=${email}&oauthToken=${idToken}&signupType=${signupType}&mobileLang=${MobileLang[lang]}`
            );

            const {
              resultcode,
              response: { code },
            } = resp.data as {
              resultcode: RESULT_CODE;
              response: {
                code: SNS_LINKAGE;
              };
            };

            if (resultcode === "BC_ERR_OK") {
              if (code === "BLACKVUE_LINKAGE") {
                history.push("/verify-account", {
                  email: currentUser.email,
                  provider: signupType,
                });
              } else if (code === "LINKAGE") {
                firebase.auth().signOut();
                const params = new URLSearchParams(location.search);

                dispatch(
                  userLogin({
                    email,
                    oauthToken: idToken,
                    mobile_uuid,
                    mobile_name: MOBILE_NAME,
                    mobile_os_type: "macos",
                    app_ver: APP_VERSION,
                    time_interval: moment().utcOffset(),
                    tokenType: TOKEN_TYPE,
                    loginType: signupType,
                    mobileLang: MobileLang[lang],
                    returnTo: params.get("returnTo") ?? undefined,
                  })
                );
              } else if (code === "NOTHING_LINKAGE") {
                if (pathname.startsWith("/login")) {
                  setOpenAgreementModal(true);
                } else if (pathname.startsWith("/signup")) {
                  history.push("/socialSignin", {
                    email: currentUser.email,
                    provider: signupType,
                    name: displayName,
                    photoURL: photoURL,
                    mode: mode,
                  });
                }
              } else if (code === "ANOTHER_LINKAGE") {
                setOpenAnotherPlatformModal(true);
              }
            }
          }
        }
      }
    },
    [dispatch, history, location.search, mobile_uuid, mode, pathname]
  );

  const firebaseAuth = useMemo(() => {
    return (
      <FirebaseAuthConsumer>
        {(data) => {
          handleFirebaseAuth(data);
        }}
      </FirebaseAuthConsumer>
    );
  }, [handleFirebaseAuth]);

  return (
    <>
      {firebaseAuth}
      <Modal
        className={classes.modal}
        open={openAgreementModal}
        mobile={mobile}
        onClickNegative={() => {
          setOpenAgreementModal(false);
          firebase.auth().signOut();
        }}
        onClickPositive={() => {
          history.push("/socialSignin", {
            email: currentUserIn && currentUserIn.email,
            provider: signupType,
            name: currentUserIn && currentUserIn.displayName,
            photoURL: currentUserIn && currentUserIn.photoURL,
          });
        }}
        content={
          <div>
            <Typography category="Default" variant="Body">
              {t("This is an_")}
            </Typography>
          </div>
        }
        LButton={t("Cancel")}
        RButton={t("Sign up")}
      />
      <Modal
        className={classes.modal}
        open={openAnotherPlatformModal}
        mobile={mobile}
        onClickPositive={() => {
          setOpenAnotherPlatformModal(false);
        }}
        content={
          <div>
            <Typography category="Default" variant="Body">
              {t("Please return to_")}
            </Typography>
          </div>
        }
        RButton={t("OK")}
      />
    </>
  );
};
