import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  LightColors,
  LogoOnly,
  Typography,
} from "@thingsw/pitta-design-system";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { HELPCENTER } from "../contants/Links";

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: "100%",
  },
  root: {
    height: "100%",
    // padding: "0 16px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: LightColors.primary["0"],
    overflow: "auto",
  },
  header: {
    height: theme.spacing(7),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    backgroundColor: LightColors.primary["0"],
    zIndex: 1000,
  },
  body: {
    marginTop: theme.spacing(7),
    padding: theme.spacing(0, 2),
  },
  menuItem: {
    height: 36,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  anchor: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    color: "inherit",
  },
  btnDiv: {
    marginTop: 8,
    padding: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  buttonRoot: {
    padding: 6,
    marginRight: -6,
  },
  accordionRoot: {
    "&:before": {
      height: 0,
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryRoot: {
    minHeight: 36,
    padding: 0,
    paddingLeft: theme.spacing(1),
    justifyContent: "flex-start",
    "&:hover > *": {
      color: LightColors.primary["8"],
    },
    "&.Mui-expanded": {
      minHeight: 36,
    },
  },
  accordionSummaryContent: {
    margin: 0,
    flexGrow: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryexpandIcon: {
    padding: 6,
  },
  accordionSummaryExpanded: {
    minHeight: 36,
    margin: 0,
  },
  accordionDetailRoot: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 3, 0),
  },
}));

interface NavigationDrawerProps {
  open: boolean;
  onClose: React.ReactEventHandler<{}>;
  onOpen: React.ReactEventHandler<{}>;
  onClickCloud: React.ReactEventHandler<{}>;
  onClickFleet: React.ReactEventHandler<{}>;
  onClickPricing: React.ReactEventHandler<{}>;
}

export const NavigationDrawer = ({
  open,
  onOpen,
  onClose,
  onClickCloud,
  onClickFleet,
  onClickPricing,
}: NavigationDrawerProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const openHelpCenter = () => {
    window.open(HELPCENTER, "_blank");
  };

  const openTutorial = () => {
    window.open("https://cloudmanual.blackvue.com/docs/blackvueweb/", "_blank");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      // onOpen={onOpen}
      classes={{ paper: classes.drawerPaper }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} timeout={{ enter: 150, exit: 250 }}>
        <div className={classes.root}>
          <div className={classes.header}>
            <Link to="/">
              <LogoOnly fontSize={36} style={{ display: "block" }} />
            </Link>
            <IconButton onClick={onClose} className={classes.buttonRoot}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.body}>
            <div className={classes.menuItem} onClick={onClickCloud}>
              <a href="/#cloud" className={classes.anchor}>
                <Typography category="Default" variant="Body">
                  {t("Cloud")}
                </Typography>
              </a>
            </div>
            <div className={classes.menuItem} onClick={onClickFleet}>
              <a href="/#fleet" className={classes.anchor}>
                <Typography category="Default" variant="Body">
                  {t("Fleet")}
                </Typography>
              </a>
            </div>
            <div className={classes.menuItem} onClick={onClickPricing}>
              <Typography category="Default" variant="Body">
                {t("Pricing")}
              </Typography>
            </div>
            <Accordion
              square
              elevation={0}
              classes={{
                root: classes.accordionRoot,
              }}
            >
              <AccordionSummary
                classes={{
                  root: classes.accordionSummaryRoot,
                  content: classes.accordionSummaryContent,
                  expandIcon: classes.accordionSummaryexpandIcon,
                }}
                expandIcon={<ExpandMoreIcon fontSize="small" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography category="Default" variant="Body">
                  {t("Support")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetailRoot }}>
                <div
                  className={classes.menuItem}
                  onClick={() => history.push("/compatible-dashcams")}
                >
                  <Typography category="Default" variant="Body">
                    {t("Compatible dashcams")}
                  </Typography>
                </div>
                <div
                  className={classes.menuItem}
                  onClick={() => history.push("/downloads")}
                >
                  <Typography category="Default" variant="Body">
                    {t("Downloads")}
                  </Typography>
                </div>
                <div className={classes.menuItem} onClick={openHelpCenter}>
                  <Typography category="Default" variant="Body">
                    {t("Help center")}
                  </Typography>
                  <OpenInNewIcon fontSize="small" style={{ marginLeft: 1 }} />
                </div>
                <div className={classes.menuItem} onClick={openTutorial}>
                  <Typography category="Default" variant="Body">
                    {t("Tutorials")}
                  </Typography>
                  <OpenInNewIcon fontSize="small" style={{ marginLeft: 1 }} />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={classes.btnDiv}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/login")}
            >
              {t("Start Web viewer")}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
