import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  DatePicker,
  LightColors,
  Modal,
  CameraInfo,
  Typography,
} from "@thingsw/pitta-design-system";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { Grow, Paper, Popper } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useDispatch, useSelector } from "react-redux";
import PopperJs from "popper.js";

import {
  clearGPSDriveData,
  clearGPSTrackingData,
  GPS,
  IDriveInfo,
  ITrackData,
  ITrackInfo,
  loadGeofenceAlert,
  loadGPSDriveData,
  loadGPSTrackingData,
} from "../../features/GPS/slice";
import { RootState } from "../../features/store";
import { CAMERA, ICameraInfo } from "../../features/Camera/slice";
import { USER } from "../../features/User/slice";
import { doDownload, getEventLink } from "../../utils/VOD";
import { VideoPlayerModal } from "../cameras/VideoPlayerModal";
import { GPSTrackingListPanel } from "./GPSTrackingListPanel";
import { MobileDrawer } from "../MobileDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Webviewer } from "../../contants/Breakpoints";
import { GPSTrackingMap } from "../maps/GPSTrackingMap";
import { IInfoWindow } from "../../types";
import { CameraListPopper } from "../CameraListPopper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PERMISSION } from "../../features/Permission/slice";
import { useHistory } from "react-router-dom";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import SearchIcon from "@material-ui/icons/Search";
import { NoResults } from "../NoResults";
import { ScreenDefaultProps } from "../../hoc/withViewerTemplate";

const useStyles = makeStyles((theme: Theme) => ({
  mapDiv: {
    height: "100%",
    position: "relative",
    backgroundColor: LightColors.primary["6"],
  },
  rootMobile: {
    width: "100%",
  },
  datePickerDiv: {
    background: LightColors.primary["0"],
    /* Light mode/#D4D4D5 ‧ 5 */

    border: `1px solid ${LightColors.primary["5"]}`,
    /* Shadow 1 ‧ Modals, menus */

    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: 4,
  },
  cameraListDiv: {
    width: "100%",
    maxHeight: "50vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  cameraPaper: {
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    border: `1px solid ${LightColors.primary["5"]}`,
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: `${LightColors.primary["1"]}5A`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circularLoading: {
    color: LightColors.primary["7"],
  },
  searchIcon: {
    "& svg": {
      fontSize: "1.125rem!important",
    },
  },
  modalContentWrap: {
    padding: theme.spacing(2.5, 3, 3.25),
    display: "flex",
    flexDirection: "column",
  },
  searchInputDiv: {
    width: "-webkit-fill-available",
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(0, 0.75, 0, 1.875) }
      : { padding: theme.spacing(0, 1.875, 0, 0.75) }),
    display: "flex",
    alignItems: "center",
    height: 44,
    position: "fixed",
    top: 62,
  },
  camListtDiv: {
    marginTop: 65,
    overflowY: "auto",
    display: "flex",
    height: "calc(100% - 120px)",
    justifyContent: "center",
  },
  modalTitle: {
    minHeight: 16,
  },
}));

interface GPSTrackingPanelProps {
  mode?: "camera" | "fleet";
}

export const GPSTrackingPanel = ({
  mode,
  plan,
}: GPSTrackingPanelProps & ScreenDefaultProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as Theme;

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const cameraAnchorRef = useRef<HTMLDivElement>(null);
  const dateAnchorRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<PopperJs>(null);

  const { permission } = useSelector((state: RootState) => state[PERMISSION]);
  const currentCam = useSelector((state: RootState) => state[CAMERA].camera);
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const { dates, driveList, type, loading } = useSelector(
    (state: RootState) => state[GPS]
  );

  const { email, loginInfo, userProfile } = useSelector(
    (state: RootState) => state[USER]
  );

  const [openNotSupport, setOpenNotSupport] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [datePickerType, setDatePickerType] = useState<"range" | "multi">(
    "range"
  );
  const [dateText, setDateText] = useState<{ date?: string }>({});
  const [previewDrive, setPreviewDrive] = useState<IDriveInfo[]>([]);
  const [selectedDrive, setSelectedDrive] = useState<IDriveInfo[]>([]);
  const [showPrevTrack, setShowPrevTrack] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [playTrack, setPlayTrack] = useState<ITrackData>();
  const [camera, setCamera] = useState<ICameraInfo>();
  const [cameraName, setCameraName] = useState("");
  const [infoWindow, setInfoWindow] = useState<IInfoWindow>();
  const [sMarker, setSMarker] = useState<IInfoWindow>();
  const [fromDate, setFromDate] = useState<moment.Moment>();
  const [toDate, setToDate] = useState<moment.Moment>();
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const [selectedTrack, setSelectedTrack] = useState<ITrackInfo>();
  const [clearMap, setClearMap] = useState(true);
  const [disableZoom, setDisableZoom] = useState(false);

  useEffect(() => {
    const orientationHandler = (e: any) => {
      setOpenDate(false);
      setOpenCamera(false);
    };
    if ("onorientationchange" in window) {
      window.addEventListener("orientationchange", orientationHandler, false);
    }
    if (!mobile) {
      window.addEventListener("resize", orientationHandler, false);
    }
    return () => {
      if ("onorientationchange" in window) {
        window.removeEventListener("orientationchange", orientationHandler);
      }
      if (!mobile) {
        window.removeEventListener("resize", orientationHandler);
      }
    };
  }, [mobile]);

  useEffect(() => {
    if (mobile) {
      const body = document.getElementById("body-container");
      body?.setAttribute("style", "overflow:hidden;");
      return () => {
        body?.removeAttribute("style");
      };
    }
  }, [mobile]);

  useEffect(() => {
    if (!permission.gpsTrackingPerm) {
      history.goBack();
    }
  }, [history, permission.gpsTrackingPerm]);

  useEffect(() => {
    return () => {
      setDateText({});
    };
  }, []);
  useEffect(() => {
    if (showDetail && camera) {
      dispatch(
        loadGeofenceAlert({
          psn: camera?.psn,
          drive_no_list: _.map(selectedDrive, (t) => t.drive_no),
        })
      );
    }
  }, [dispatch, camera, selectedDrive, showDetail, userProfile?.userType]);

  useEffect(() => {
    return () => {
      dispatch(clearGPSTrackingData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (mode !== "fleet" && currentCam?.psn !== camera?.psn) {
      setCamera(currentCam);
    }
  }, [currentCam, camera, mode]);

  useEffect(() => {
    if (mode !== "fleet" && camera && driveList.length > 0) {
      const dr = _.first(driveList);
      if (dr) {
        console.log(dr);
        setPreviewDrive([dr]);
        setShowPrevTrack(true);
        setSelectedDrive([dr]);
        if (dr.sdate.isSame(dr.edate, "D")) {
          setDateText({ date: `${dr.sdate.format("MMM DD, YYYY")}` });
        } else {
          setDateText({
            date: `${dr.sdate.format("MMM DD, YYYY")}-${dr.edate.format(
              "MMM DD, YYYY"
            )}`,
          });
        }
        setClearMap(false);
      }
    }
  }, [camera, driveList, driveList.length, mode]);

  useEffect(() => {
    if (camera) {
      setCameraName(camera.dev_name);
      dispatch(loadGPSDriveData(camera.psn));
    } else {
      dispatch(clearGPSDriveData());
    }
  }, [dispatch, camera]);

  const handleDownload = useCallback(
    async (data: ITrackData) => {
      if (camera && loginInfo && email) {
        const { user_token: userToken } = loginInfo;
        const { psn } = camera;

        let resp: any | undefined = undefined;
        if (data?.rid_front) {
          resp = await getEventLink(email, userToken, psn, data.rid_front);
        } else if (data?.rid_rear) {
          resp = await getEventLink(email, userToken, psn, data.rid_rear);
        } else if (data?.rid_3) {
          resp = await getEventLink(email, userToken, psn, data.rid_3);
        }
        if (resp) {
          doDownload(resp.data["fileInfo"]["url"]);
        }
      }
    },
    [camera, email, loginInfo]
  );

  const handlePlay = useCallback(
    (data: ITrackData) => {
      if (plan === "Fleet plan") {
        setOpenPlayerModal(true);
        setPlayTrack(data);
      } else {
        setOpenNotSupport(true);
      }
    },
    [plan]
  );

  const handleSetInfoWindow = useCallback((info: any) => {
    setInfoWindow(info);
  }, []);

  const handleSelectTrack = useCallback(
    (data: { track?: ITrackInfo; disableZoom?: boolean }) => {
      setSelectedTrack(data.track);
      if (data.track && showPrevTrack) {
        setShowDetail(true);
      }
      if (data.disableZoom) {
        setDisableZoom(true);
      } else {
        setDisableZoom(false);
      }
    },
    [showPrevTrack]
  );

  const renderListPanel = useCallback(() => {
    return (
      <GPSTrackingListPanel
        mode={mode}
        mobile={mobile}
        date={dateText}
        fromDate={fromDate}
        toDate={toDate}
        dates={selectedDates}
        infoWindow={infoWindow}
        selectedDrives={selectedDrive}
        showDetail={showDetail}
        cameraAnchorRef={cameraAnchorRef}
        dateAnchorRef={dateAnchorRef}
        camera={camera}
        cameraName={cameraName}
        // onCloseDetail={() => {
        //   setShowPrevTrack(false);
        //   setPreviewDrive([]);
        //   setSelectedDrive([]);
        //   setSelectedTrack(undefined);
        // }}
        onChangeInfoWindow={(i) => {
          setInfoWindow(i);
          setSMarker(i);
        }}
        onChangePlayTrack={handlePlay}
        onChangeSelectedDrives={(t) => {
          setSelectedDrive(t);
        }}
        onOpenCamera={() => {
          setCamera(undefined);
          setCameraName("");
          setOpenCamera(true);
        }}
        onOpenDate={() => setOpenDate(true)}
        onShowDetail={(b) => {
          setClearMap(!b);
          setShowDetail(b);
          if (!b) {
            showPrevTrack && setDateText({});
            setShowPrevTrack(false);
            setPreviewDrive([]);
            setSelectedDrive([]);
            setSelectedTrack(undefined);
          }
        }}
        onChangeCameraName={(e) => {
          setCameraName(e.target.value);
          setSelectedDrive([]);
          setToDate(undefined);
          setFromDate(undefined);
        }}
        onSelectTrack={handleSelectTrack}
      />
    );
  }, [
    mode,
    mobile,
    dateText,
    fromDate,
    toDate,
    selectedDates,
    infoWindow,
    selectedDrive,
    showDetail,
    camera,
    cameraName,
    handlePlay,
    handleSelectTrack,
    showPrevTrack,
  ]);

  const datePickerMarkup = useMemo(() => {
    return (
      <DatePicker
        variant={datePickerType}
        enabledDays={dates}
        onChangeVariant={(variant) => setDatePickerType(variant)}
        total={31}
        t={t}
        loading={loading && type === loadGPSDriveData.type}
        mobile={mobile}
        onClickOK={(item) => {
          if (camera) {
            if (item.range) {
              const { from, to } = item.range;
              if (from && to) {
                const sdate = moment(from).utc(false).startOf("d");
                const edate = moment(to).utc(false).endOf("d");
                setDateText({
                  date: `${sdate.format("MMM DD, YYYY")}-${edate.format(
                    "MMM DD, YYYY"
                  )}`,
                });
                setFromDate(sdate);
                setToDate(edate);
                setSelectedDates([]);

                setSelectedDrive([]);
              }
            } else if (item.dates && item.dates.length > 0) {
              // const ddates = _.map(item.dates, (d) =>
              //   moment(d).utc(false).startOf("d")
              // );
              const ddates = _.sortBy(
                _.map(item.dates, (d) => moment(d).utc(false).startOf("d")),
                (s) => moment(s)
              );
              const first = _.first(ddates);
              const last = _.last(ddates);

              setDateText({
                date: `${first?.format("MMM DD, YYYY")}-${last?.format(
                  "MMM DD, YYYY"
                )}`,
              });
              setSelectedDates(ddates);
              setFromDate(undefined);
              setToDate(undefined);

              setSelectedDrive([]);
            }
          }
          setOpenDate(false);
        }}
      />
    );
  }, [camera, datePickerType, dates, loading, mobile, t, type]);

  const filteredCams = useMemo(
    () =>
      _.chain(cameraList?.DeviceListInfo)
        .filter(
          (dev) =>
            dev.device.dev_name
              .toLowerCase()
              .indexOf(cameraName.toLowerCase()) > -1 ||
            dev.device.model.toLowerCase().indexOf(cameraName.toLowerCase()) >
              -1
        )
        .map((dev) => (
          <CameraInfo
            key={dev.device.psn}
            camera={dev.device}
            onClick={(cam) => {
              setOpenCamera(false);
              setCamera(cam);

              setSelectedDrive([]);
              setToDate(undefined);
              setFromDate(undefined);
              dispatch(clearGPSDriveData());
            }}
            hover
            gpsMobile
          />
        ))
        .value(),
    [cameraList?.DeviceListInfo, cameraName, dispatch]
  );

  const cameraListMarkup = useMemo(() => {
    return (
      <>
        {filteredCams.length > 0 ? (
          <div className={classes.cameraListDiv}>{filteredCams}</div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <NoResults />
          </div>
        )}
      </>
    );
  }, [classes.cameraListDiv, filteredCams]);

  const drives = useMemo(() => {
    // return [];
    if (showDetail) {
      return selectedDrive;
    } else if (showPrevTrack) {
      return previewDrive;
    }
    return undefined;
  }, [previewDrive, selectedDrive, showDetail, showPrevTrack]);

  return (
    <div className={classes.mapDiv}>
      <GPSTrackingMap
        fullscreenIcon
        psn={camera?.psn}
        infoWindow={infoWindow}
        onSetInfoWindow={handleSetInfoWindow}
        onDownload={handleDownload}
        onPlay={handlePlay}
        drives={drives}
        selectedTrack={selectedTrack}
        onSelectTrack={handleSelectTrack}
        clearMap={clearMap}
        disableZoom={disableZoom}
        sMarker={sMarker}
      />

      {!mobile && renderListPanel()}
      {mobile && (
        <MobileDrawer mode="gps-tracking">{renderListPanel()}</MobileDrawer>
      )}

      {!mobile && openCamera && (
        <CameraListPopper
          open={openCamera}
          anchorEl={cameraAnchorRef.current}
          onClickAway={() => setOpenCamera(false)}
          cameraName={cameraName}
          onClick={(cam) => {
            setOpenCamera(false);
            setCamera(cam);

            setSelectedDrive([]);
            setToDate(undefined);
            setFromDate(undefined);
            dispatch(clearGPSDriveData());
          }}
        />
      )}
      {!mobile && openDate && (
        <Popper
          popperRef={popperRef}
          open={openDate}
          anchorEl={dateAnchorRef.current}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 2",
            },
            preventOverflow: {
              enabled: true,
              priority: ["right", "bottom", "top", "left"],
              boundariesElement: "viewport",
            },
            flip: {
              enabled: false,
            },
          }}
          placement="bottom-start"
          transition
          style={{ zIndex: 2 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "center top",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenDate(false);
                  }}
                >
                  <div className={classes.datePickerDiv}>
                    {datePickerMarkup}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {mobile && openDate && (
        <Modal
          open={openDate}
          fullSize
          noPadding
          mobile
          heading={t("Dates")}
          close
          noScroll
          content={datePickerMarkup}
          onClose={() => setOpenDate(false)}
        />
      )}
      {mobile && openCamera && (
        <Modal
          open={openCamera}
          fullSize
          noPadding
          mobile
          heading={t("Camera")}
          close
          noScroll
          contentClassName={classes.modalContentWrap}
          content={
            <>
              <div className={classes.searchInputDiv}>
                <Input
                  inputID="camerasSearch"
                  placeholder={t("Search cameras")}
                  startIcon={
                    <SearchIcon style={{ color: LightColors.primary["3"] }} />
                  }
                  value={cameraName}
                  onChange={(e) => {
                    setCameraName(e.target.value);
                    setSelectedDrive([]);
                    setToDate(undefined);
                    setFromDate(undefined);
                  }}
                  onCloseSearch={() => {
                    setCameraName("");
                  }}
                  variant="standard"
                  search
                />
              </div>
              <div className={classes.camListtDiv}>{cameraListMarkup}</div>
            </>
          }
          onClose={() => setOpenCamera(false)}
        />
      )}
      {camera && openPlayerModal && (
        <VideoPlayerModal
          mode={
            playTrack?.rid_front || playTrack?.rid_rear || playTrack?.rid_3
              ? 2
              : 1
          }
          open={openPlayerModal}
          camera={camera}
          data={playTrack}
          onClose={() => setOpenPlayerModal(false)}
        />
      )}
      {openNotSupport && (
        <Modal
          open={openNotSupport}
          close
          heading=" "
          titleClassName={classes.modalTitle}
          onClose={() => setOpenNotSupport(false)}
          RButton={t("OK")}
          onClickPositive={() => setOpenNotSupport(false)}
          content={
            <Typography
              category="Default"
              variant="Body"
              dangerouslySetInnerHTML={{
                __html: t("This feature is only_vom"),
              }}
            />
          }
        />
      )}
      {loading &&
        (type === loadGPSDriveData.type || type === loadGPSTrackingData.type) &&
        !showDetail && (
          <div className={classes.loadingDiv}>
            <CircularProgress
              className={classes.circularLoading}
              size={64}
              thickness={5}
            />
          </div>
        )}
    </div>
  );
};
