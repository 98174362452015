import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  BlackvueLogoOutlined,
  IconButton,
  LightColors,
  Menu,
  MobileMenu,
  PlayerNextFrame,
  PlayerPreviousFrame,
  Tooltip,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { detect } from "detect-browser";

import videojs from "video.js";

// Styles
import "video.js/dist/video-js.css";

import { ICameraInfo } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { loadUsageInfo, USER } from "../../features/User/slice";
import { IVOD, VOD } from "../../features/VOD/slice";

import {
  DMStoDegree,
  exitFullscreen,
  isFullscreen,
  requestFullscreen,
} from "../../utils/GoogleMap";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FlipIcon from "@material-ui/icons/Flip";
import SettingsIcon from "@material-ui/icons/Settings";
import Crop54Icon from "@material-ui/icons/Crop54";
import Crop75Icon from "@material-ui/icons/Crop75";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import PauseIcon from "@material-ui/icons/Pause";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import clsx from "clsx";
import {
  IAccel,
  IGPSLocation,
  RESULT_CODE,
  VideoQualityType,
} from "../../types";
import {
  API_GATEWAY_URI,
  API_SERVER_URI,
  getLbURI,
  getS3URI,
} from "../../contants/Server";
import { CircularProgress } from "@material-ui/core";
import { ProgressSlider } from "../ProgressSlider";
import { VolumeSlider } from "./VolumeSlider";
import { Webviewer } from "../../contants/Breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { pushArray } from "../../utils/Accel";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  disabled: {
    opacity: 0.25,
  },
  menuDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: LightColors.primary["1"],
    color: LightColors.primary["0"],
    padding: theme.spacing(1.5, 1),
    "& svg": {
      fill: LightColors.primary["0"],
    },
    borderBottom: `1px solid ${LightColors.primary["0"]}`,
    minHeight: 49,
  },
  dirDiv: {
    display: "flex",
    alignItems: "center",
    // width: 62,
    marginRight: 5.5,
    cursor: "pointer",
  },
  uncheckedMenu: {
    paddingLeft: 44,
  },
  videoDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  videoWideDiv: {
    paddingTop: 181,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop:
        "min(max(350px, calc(100vh - 108px - 188px - 24px - 24px - 49px - 16px)), 480px)",
    },
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: LightColors.primary["1"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalFullScreen: {
    paddingTop: "55vh",
  },
  videoFullScreen: {
    paddingTop: "calc((var(--vh, 1vh) * 100) - 49px)",
  },
  videoHFlip: {
    "-webkit-transform": "scale(-1, 1)",
    transform: "scale(-1, 1)",
  },
  videoVFlip: {
    "-webkit-transform": "scale(1, -1)",
    transform: "scale(1, -1)",
  },
  videoFlip: {
    "-webkit-transform": "scale(-1, -1)",
    transform: "scale(-1, -1)",
  },
  ctrlContDiv: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    padding: theme.spacing(0, 1),
    zIndex: 1,
  },
  ctrlDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
  },
  blackvueIcon: {
    width: 160,
    height: 29,
    fill: LightColors.primary["0"],
  },
  ctrlIcon: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
    color: LightColors.primary["0"],
    fill: LightColors.primary["0"],
    "&>svg": {
      color: LightColors.primary["0"],
      fill: LightColors.primary["0"],
    },
    "&.Mui-disabled": {
      color: `${LightColors.primary["0"]}40`,
      fill: `${LightColors.primary["0"]}40`,
    },
    "&:hover,&:active,&:focus": {
      color: LightColors.primary["0"],
    },
  },
  disabledCtrlIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    "&>svg": {
      color: `${LightColors.primary["0"]}40`,
      fill: `${LightColors.primary["0"]}40`,
    },
    display: "flex",
  },
  overlayDiv: {
    background:
      "linear-gradient(180deg, rgba(19, 19, 28, 0) 66.22%, rgba(19, 19, 28, 0.75) 95.5%)",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  playerMenuDiv: {
    backgroundColor: LightColors.primary["1"],
    color: LightColors.primary["0"],
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",

    "& svg": {
      color: LightColors.primary["0"],
      fill: LightColors.primary["0"],
    },
  },
  webMenuDiv: {
    "&:hover": {
      backgroundColor: LightColors.primary["2"],
    },
  },
  volumeCtrlDiv: {
    width: 52,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  circularLoading: {
    color: LightColors.primary["0"],
  },
  videoTitleDiv: {
    // maxWidth: 189,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  mobilePlayCtrlDiv: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${LightColors.primary["1"]}73`,
  },
  mobilePlayCtrl: {
    display: "flex",
    minWidth: 216,
    justifyContent: "space-between",
    alignItems: "center",
  },
  rootFullscreen: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1200,
    height: "calc(var(--vh, 1vh) * 100)",
  },
}));

interface VideoPlayerProps {
  camera?: ICameraInfo;
  vod?: IVOD;
  quality: VideoQualityType;
  mode: number; // 0: playback, 1: cloud, 2: live event upload
  fullHeight?: boolean;
  onResize?: (width: number, height: number) => void;
  disablePrev?: boolean;
  disableNext?: boolean;
  theater?: boolean;
  noTheater?: boolean;
  singleVideo?: boolean;
  screenMode?: "wide" | "default";
  requestPause?: any;
  showQuality?: boolean;
  disableQuality?: boolean;
  disableUndefined?: boolean;
  updatedTimestamp?: number;
  onPrev?: () => void;
  onNext?: () => void;
  onTheater?: () => void;
  onUpdateAccel?: (accels: IAccel[], totalTimestamp: number) => void;
  onUpdateGPS?: (locations: IGPSLocation[]) => void;
  onUpdateTime?: (timestamp: number) => void;
  onRequestFront?: (fname: string) => void;
  onRequestRear?: (fname: string) => void;
  onRequestInterior?: (fname: string) => void;
  onChangeQuality?: (qual: VideoQualityType) => void;
  onLoading?: (load: boolean) => void;
  onError?: () => void;
}

const CLOUD_NATIVE = true;

export const VideoPlayer = ({
  mode,
  camera,
  vod,
  disablePrev,
  disableNext,
  theater,
  noTheater,
  singleVideo,
  quality,
  screenMode,
  fullHeight,
  requestPause,
  showQuality,
  disableQuality,
  disableUndefined,
  updatedTimestamp,
  onResize,
  onPrev,
  onNext,
  onTheater,
  onUpdateAccel,
  onUpdateGPS,
  onUpdateTime,
  onRequestFront,
  onRequestRear,
  onRequestInterior,
  onChangeQuality,
  onLoading,
  onError,
}: VideoPlayerProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const ref = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLDivElement>(null);
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const qualityAnchorRef = useRef<HTMLButtonElement>(null);
  const flipAnchorRef = useRef<HTMLButtonElement>(null);
  const speedAnchorRef = useRef<HTMLButtonElement>(null);
  const volumeAnchorRef = useRef<HTMLButtonElement>(null);
  const metaAbortRef = useRef<AbortController>();

  const prevFile = useRef<string>();

  const email = useSelector((state: RootState) => state[USER].email);
  const loginInfo = useSelector((state: RootState) => state[USER].loginInfo);
  const vod_token = useSelector(
    (state: RootState) => vod && state[VOD].tokens[vod.filename]
  );
  const low_vod_token = useSelector((state: RootState) => {
    if (vod && vod.lowFilename !== undefined) {
      return state[VOD].tokens[vod.lowFilename];
    }
  });

  const [openMenu, setOpenMenu] = useState(false);
  const [openQualityMenu, setOpenQualityMenu] = useState(false);
  const [openFlipMenu, setOpenFlipMenu] = useState(false);
  const [openVolumeMenu, setOpenVolumeMenu] = useState(false);
  const [openPlaySpeedMenu, setOpenPlaySpeedMenu] = useState(false);
  const [hFlip, setHFlip] = useState(false);
  const [vFlip, setVFlip] = useState(false);
  const [paused, setPaused] = useState(true);
  const [rootFullscreen, setRootFullscreen] = useState(false);
  const [fullscreen, setfullscreen] = useState(false);
  const [volume, setVolume] = useState(50);
  const [currentPlayTime, setCurrentPlayTime] = useState(0);
  const [player, setPlayer] = useState<videojs.Player>();
  const [videoRatio /*, setVideoRatio*/] = useState<string>("56.25%");
  const [playSpeed, setPlaySpeed] = useState(1);
  const [duration, setDuration] = useState<moment.Duration>(
    moment.duration(0, "s")
  );
  const [current, setCurrent] = useState<moment.Duration>(
    moment.duration(0, "s")
  );
  const [loading, setLoading] = useState(false);
  const [hideCtrl, setHideCtrl] = useState(false);
  const [forceViewCtrl, setForceViewCtrl] = useState(false);
  const [preVolume, setPreVolume] = useState(volume);
  const [iosBrowser, setIosBrowser] = useState(false);
  const [fps /*, setFps*/] = useState(10);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [srcUrl, setSrcUrl] = useState<string>();

  useEffect(() => {
    const browser = detect();
    setIosBrowser(browser?.name === "ios" || browser?.name === "crios");
  }, []);

  useEffect(() => {
    if (requestPause && player) {
      player.pause();
    }
  }, [requestPause, player]);

  useEffect(() => {
    function exitHandler() {
      if (
        !document.fullscreenElement &&
        //@ts-ignore
        !document.webkitIsFullScreen &&
        //@ts-ignore
        !document.mozFullScreen &&
        //@ts-ignore
        !document.msFullscreenElement
      ) {
        ///fire your event
        setfullscreen(false);
        setOpenTooltip(false);
      }
    }
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  useEffect(() => {
    if (player && updatedTimestamp) {
      player.currentTime(updatedTimestamp);
    }
  }, [updatedTimestamp, player]);

  useEffect(() => {
    if (!vod) {
      setDuration(moment.duration(0, "s"));
      setCurrent(moment.duration(0, "s"));
      setCurrentPlayTime(0);
    }
    setPlaySpeed(1);
  }, [vod]);

  useEffect(() => {
    if (paused) {
      setForceViewCtrl(true);
    } else {
      if (!openPlaySpeedMenu && !openFlipMenu && !openQualityMenu) {
        setForceViewCtrl(false);
      }
    }
  }, [openFlipMenu, openPlaySpeedMenu, openQualityMenu, paused]);

  useEffect(() => {
    if (!loading) {
      if (!paused && !hideCtrl && !forceViewCtrl) {
        const timerId = setTimeout(() => setHideCtrl(true), 2000);
        return () => {
          clearTimeout(timerId);
        };
      }
    } else {
      setHideCtrl(false);
    }
  }, [loading, paused, hideCtrl, forceViewCtrl]);

  const getLink = useCallback(
    async (camera: ICameraInfo, vod: IVOD) => {
      if (camera && vod && email && loginInfo) {
        const { user_token } = loginInfo;
        const { lb_server_name, lb_http_port, psn } = camera;
        const { filename, rid, lowFilename } = vod;
        console.log("getLink", filename, lowFilename, vod_token, low_vod_token);
        let fname = filename;
        if (quality === "low" && lowFilename) {
          fname = lowFilename;
        }
        if (mode === 0 && fname) {
          // const res = await getVODToken(email, user_token, psn, fname);
          // const { resultcode, response } = res.data as {
          //   resultcode: RESULT_CODE;
          //   response: IVODToken;
          // };

          // dispatch(successLoadVODToken({ filename: fname, token: response }));

          // return [
          //   fname,
          //   `${getLbURI(
          //     lb_server_name,
          //     lb_http_port
          //   )}/proc/vod_file?email=${email}&user_token=${user_token}&psn=${psn}&filename=${fname}&vod_token=${
          //     response.vod_token
          //   }&tokenType=web`,
          // ];

          if (quality === "low" && low_vod_token) {
            return [
              fname,
              `${getLbURI(
                lb_server_name,
                lb_http_port
              )}/proc/vod_file?email=${email}&user_token=${user_token}&psn=${psn}&filename=${fname}&vod_token=${
                low_vod_token.vod_token
              }&tokenType=web`,
            ];
          }
          if (quality === "original" && vod_token) {
            return [
              fname,
              `${getLbURI(
                lb_server_name,
                lb_http_port
              )}/proc/vod_file?email=${email}&user_token=${user_token}&psn=${psn}&filename=${fname}&vod_token=${
                vod_token.vod_token
              }&tokenType=web`,
            ];
          }
        } else if (mode === 1) {
          metaAbortRef.current = new AbortController();
          const resp = await fetch(
            `${API_SERVER_URI}/BCS/userS3PresignedUrl.php?email=${email}&user_token=${user_token}&psn=${psn}&filename=${fname}&tokenType=web`,
            {
              signal: metaAbortRef.current?.signal,
            }
          );

          const data = await resp.json();
          const { resultcode, response } = data as {
            resultcode: RESULT_CODE;
            response: {
              presignedURL: string;
            };
          };

          if (resultcode === "BC_ERR_OK") {
            return [fname, getS3URI(response.presignedURL)];
          }
        } else if (mode === 2 && rid) {
          try {
            metaAbortRef.current = new AbortController();
            const resp = await fetch(
              `${API_SERVER_URI}/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${user_token}&psn=${psn}&rid=${rid}&token_type=web`,
              {
                signal: metaAbortRef.current?.signal,
              }
            );

            const data = await resp.json();
            const { fileInfo } = data as {
              fileInfo: { fileName: string; rid: string; url: string };
            };
            return [fileInfo.fileName, getS3URI(fileInfo.url)];
          } catch (e) {
            setLoading(false);
            onError?.();
          }
        }
      }
      return [];
    },
    [email, loginInfo, vod_token, low_vod_token, quality, mode, onError]
  );

  useEffect(() => {
    onLoading?.(loading);
  }, [onLoading, loading]);

  useEffect(() => {
    if (ref.current) {
      videojs(ref.current, {
        bigPlayButton: false,
        controls: false,
        // fluid: true,
        autoplay: true,
        //@ts-ignore
        errorDisplay: false,
      }).ready(function () {
        const self = this;
        setPlayer(this);
        this.on("error", () => {
          const error = self.error();
          console.log("this", "error", self.error());
          if (error) {
            if (error.code === 4) {
            }
          }
        });
      });
    }
  }, [dispatch, ref]);

  useEffect(() => {
    player?.playbackRate(playSpeed);
  }, [player, playSpeed]);

  useEffect(() => {
    player?.volume(volume / 100);
  }, [player, volume]);

  useEffect(() => {
    player?.currentTime(currentPlayTime);
  }, [player, currentPlayTime]);

  useEffect(() => {
    if (player) {
      player.on("waiting", () => {
        console.log("waiting");
      });
      player.on("play", (e) => {
        setPaused(false);
      });
      player.on("pause", (e) => {
        setPaused(true);
      });
      player.on("durationchange", (e) => {
        let dur = moment.duration(_.round(player.duration(), 0), "s");
        setDuration(dur);
      });
      player.on("timeupdate", (e) => {
        if (vod) {
          const time = player.currentTime();
          onUpdateTime?.(time);
          // console.log("timeupdate", time, vod);
          setCurrent(moment.duration(_.round(time, 0), "s"));
        }
      });
      player.on("loadeddata", (e) => {
        player.play();
        setLoading(false);
        dispatch(loadUsageInfo());
      });
      return () => {
        player.off([
          "waiting",
          "play",
          "pause",
          "durationchange",
          "timeupdate",
          "loadeddata",
        ]);
      };
    }
  }, [dispatch, onUpdateTime, player, quality, vod]);

  useEffect(() => {
    if (CLOUD_NATIVE) {
      const getVODMeta = async (
        camera: ICameraInfo,
        vod: IVOD,
        email: string,
        token: string
      ) => {
        const resp = await axios.post(`${API_GATEWAY_URI}/IoT/devicecommand`, {
          command: "Metadata",
          email,
          user_token: token,
          tokenType: "web",
          psn: camera.psn,
          param1: vod.lowFilename,
        });
        console.log("VOD", "Meta", resp.data);
      };
      if (camera && vod && loginInfo && email) {
        getVODMeta(camera, vod, email, loginInfo.user_token);
      }
    }
  }, [camera, email, loginInfo, vod]);

  useEffect(() => {
    if (!CLOUD_NATIVE) {
      const playVOD = async () => {
        // console.log("getLink", "playVOD", resp, link);
        if (camera && vod) {
          setLoading(true);
          setPaused(true);
          player?.pause();
          const [filename, link] = await getLink(camera, vod);
          if (player && link) {
            setSrcUrl(link);
            const curr = player.currentTime();
            player.src({
              src: link,
              type: "video/mp4",
            });
            player.load();
            if (prevFile.current === filename) {
              player.currentTime(curr);
            }
            prevFile.current = filename;
          } else {
            setLoading(false);
          }
        }
      };
      playVOD();
    }
  }, [camera, getLink, player, vod]);

  useEffect(() => {
    if (videoRef.current) {
      const element = videoRef.current;
      //@ts-ignore
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0]) {
          onResize?.(
            entries[0].contentRect.width,
            entries[0].contentRect.height
          );
        }
      });
      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
        // element.removeEventListener("resize", handler);
      };
    }
  }, [onResize, videoRef]);

  useEffect(() => {
    if (!CLOUD_NATIVE) {
      const cancel = new AbortController();

      const link = srcUrl;
      const getVOD = async () => {
        console.log("getVOD", link);
        if (link) {
          // mp4boxfile.flush();
          try {
            const maxGSensorCount = 600;
            const decoder = new TextDecoder();
            const resp = await fetch(link, {
              signal: cancel.signal,
            });
            const reader = resp.body?.getReader();
            // console.log("reader", reader);
            if (reader) {
              let accProcessed = false;
              let gpsProcessed = false;
              let stream: ReadableStreamReadResult<Uint8Array>;
              // let fileStart = 0;
              let locations: IGPSLocation[] = [];
              const accels: IAccel[] = [];
              let accels2: IAccel[] = [];
              const prevX: number[] = [];
              const prevY: number[] = [];
              const prevZ: number[] = [];
              const gpsBuffer: Buffer[] = [];
              do {
                stream = await reader.read();
                const buffer = stream.value?.buffer;
                if (buffer) {
                  const data = Buffer.from(buffer);

                  const gpsStartIdx = decoder.decode(data).indexOf("gps ");
                  if (gpsStartIdx > 0) {
                    gpsProcessed = true;
                    gpsBuffer.push(data);
                  }
                  if (gpsProcessed) {
                    gpsBuffer.push(data);
                  }

                  const startIdx = decoder.decode(data).indexOf("3gf ");
                  if (startIdx > 0) {
                    accProcessed = true;
                    for (let i = startIdx + 4; ; ) {
                      const timestamp =
                        Math.floor(data.readUInt32BE(i) / 100) / 10;
                      if (timestamp > maxGSensorCount) {
                        break;
                      }
                      i += 4;
                      const x = Math.max(
                        Math.min(data.readInt16BE(i), 250),
                        -250
                      );
                      i += 2;
                      const y = Math.max(
                        Math.min(data.readInt16BE(i), 250),
                        -250
                      );
                      i += 2;
                      const z = Math.max(
                        Math.min(data.readInt16BE(i), 250),
                        -250
                      );
                      i += 2;

                      const doc = { timestamp, x: x, y: y, z: z };
                      accels2.push(doc);
                    }

                    const avgX =
                      _.sumBy(accels2, (acc) => acc.x) / accels2.length;
                    const avgY =
                      _.sumBy(accels2, (acc) => acc.y) / accels2.length;
                    const avgZ =
                      _.sumBy(accels2, (acc) => acc.z) / accels2.length;

                    accels2 = _.map(accels2, (acc) => ({
                      timestamp: acc.timestamp,
                      x: acc.x - avgX,
                      y: acc.y - avgY,
                      z: acc.z - avgZ,
                    }));

                    for (let acc of accels2) {
                      const indx = Math.floor(acc.timestamp);
                      const { x, y, z } = acc;
                      if (accels[indx]) {
                        accels[indx] = {
                          timestamp: indx,
                          x:
                            Math.abs(accels[indx].x) < Math.abs(x)
                              ? x
                              : accels[indx].x,
                          y:
                            Math.abs(accels[indx].y) < Math.abs(y)
                              ? y
                              : accels[indx].y,
                          z:
                            Math.abs(accels[indx].z) < Math.abs(z)
                              ? z
                              : accels[indx].z,
                        };
                      } else {
                        accels[indx] = {
                          timestamp: indx,
                          x,
                          y,
                          z,
                        };
                      }
                    }

                    for (let acc of accels2) {
                      const indx = Math.floor(acc.timestamp);
                      const { x, y, z } = acc;
                      const newX = x - _.sum(prevX) / prevX.length;
                      const newY = y - _.sum(prevY) / prevY.length;
                      const newZ = z - _.sum(prevZ) / prevZ.length;

                      if (accels[indx]) {
                        accels[indx] = {
                          timestamp: indx,
                          x:
                            Math.abs(accels[indx].x) < Math.abs(newX)
                              ? newX
                              : accels[indx].x,
                          y:
                            Math.abs(accels[indx].y) < Math.abs(newY)
                              ? newY
                              : accels[indx].y,
                          z:
                            Math.abs(accels[indx].z) < Math.abs(newZ)
                              ? newZ
                              : accels[indx].z,
                        };
                      } else {
                        accels[indx] = {
                          timestamp: indx,
                          x,
                          y,
                          z,
                        };
                      }

                      pushArray(prevX, x);
                      pushArray(prevY, y);
                      pushArray(prevZ, z);
                    }
                  }
                }

                if (accProcessed) {
                  cancel.abort();
                  break;
                }

                // if (buffer) {
                //   //@ts-ignore
                //   buffer.fileStart = fileStart;
                //   mp4boxfile.appendBuffer(buffer);
                //   fileStart += buffer.byteLength;
                // }
              } while (!stream.done);

              const data = Buffer.concat(gpsBuffer);
              const gpsStartIdx = decoder.decode(data).indexOf("gps ");
              const gpsData = data.slice(gpsStartIdx + 4);
              const nmeaStrings = _.compact(
                new TextDecoder().decode(gpsData).split("\n")
              );

              // console.log("nmeaStrings", nmeaStrings);

              locations = _.chain(nmeaStrings)
                .filter(
                  (s) =>
                    s.indexOf("$GNRMC") !== -1 || s.indexOf("$GPRMC") !== -1
                )
                .map((s) => {
                  const nmeas = s.split(",");
                  // console.log("nmeas", nmeas, nmeas.length);
                  if (nmeas.length === 13) {
                    const dur = moment.duration(
                      `${nmeas[1].slice(0, 2)}:${nmeas[1].slice(
                        2,
                        4
                      )}:${nmeas[1].slice(4)}`
                    );
                    const time = dur.asSeconds();
                    // 미국/중국 gps location 잘못 나오는 문제 수정
                    const lat =
                      (nmeas[4] === "N" ? 1 : -1) *
                      DMStoDegree(parseFloat(nmeas[3]));
                    const lng =
                      (nmeas[6] === "E" ? 1 : -1) *
                      DMStoDegree(parseFloat(nmeas[5]));
                    const speed = nmeas[7];
                    const heading = parseFloat(nmeas[8]);
                    if (!(isNaN(time) || isNaN(lat) || isNaN(lng))) {
                      return {
                        time,
                        lat,
                        lng,
                        heading,
                        name: camera?.dev_name,
                        mode: "0",
                        speed,
                      } as IGPSLocation;
                    }
                  }
                  return undefined;
                })
                .compact()
                .sortBy("time")
                .value();

              const baseTime = _.first(locations)?.time ?? 0;
              locations = _.map(locations, (loc) => ({
                ...loc,
                time: (loc.time ?? baseTime) - baseTime,
              }));

              // console.log(
              //   "accels",
              //   accels,
              //   accels2,
              //   _.filter(accels2, (acc) => acc.timestamp === 0).length
              // );

              if (
                accels.length > 0 &&
                _.filter(accels2, (acc) => acc.timestamp === 0).length <= 1
              ) {
                onUpdateAccel?.(accels, _.last(accels2)?.timestamp ?? 0);
              }

              // console.log("locations", locations);
              onUpdateGPS?.(locations);
            }
          } catch (err) {
            console.log("fetch error");

            console.error(err);
          }
        }
      };

      getVOD();
    }
  }, [
    onUpdateAccel,
    onUpdateGPS,
    getLink,
    player,
    dispatch,
    camera?.dev_name,
    srcUrl,
  ]);

  const getTime = useCallback(() => {
    return `${current.minutes().toString().padStart(2, "0")}:${current
      .seconds()
      .toString()
      .padStart(2, "0")} / ${duration
      .minutes()
      .toString()
      .padStart(2, "0")}:${duration.seconds().toString().padStart(2, "0")}`;
  }, [current, duration]);

  const handleFullscreen = useCallback(
    (e: { stopPropagation: () => void }) => {
      e.stopPropagation();
      if (iosBrowser) {
        if (rootFullscreen) {
          setRootFullscreen(false);
          setOpenTooltip(false);
        } else {
          setRootFullscreen(true);
          setOpenTooltip(false);
        }
      } else {
        if (videoRef.current) {
          if (isFullscreen(videoRef.current)) {
            setfullscreen(false);
            setOpenTooltip(false);
            exitFullscreen(videoRef.current);
          } else {
            setfullscreen(true);
            setOpenTooltip(true);
            requestFullscreen(videoRef.current);
          }
        }
      }
    },
    [iosBrowser, rootFullscreen]
  );

  useEffect(() => {
    setfullscreen(rootFullscreen);
  }, [rootFullscreen]);
  const renderCtrlIcon = useCallback(
    (icon: React.ReactNode, onClick?: () => void, disabled?: boolean) => {
      return disabled ? (
        <span className={classes.disabledCtrlIcon}>{icon}</span>
      ) : (
        <IconButton
          className={classes.ctrlIcon}
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
          }}
        >
          {icon}
        </IconButton>
      );
    },
    [classes.ctrlIcon, classes.disabledCtrlIcon]
  );

  const handlePrevFrame = useCallback(() => {
    player?.pause();
    const curr = player?.currentTime();
    if (curr) {
      player?.currentTime(curr - 1 / fps);
    }
  }, [fps, player]);

  const handlePlayPause = useCallback(() => {
    if (player?.paused()) {
      setHideCtrl(true);
      player?.play();
    } else {
      player?.pause();
    }
  }, [player]);

  const handleNextFrame = useCallback(() => {
    player?.pause();
    const curr = player?.currentTime();
    if (curr) {
      player?.currentTime(curr + 1 / fps);
    }
  }, [fps, player]);

  const handleScreenPlay = useCallback(() => {
    console.log(forceViewCtrl);
    handlePlayPause();
    if (mobile && !paused && !forceViewCtrl) {
      setHideCtrl(false);
    }
    if (paused) {
    }
  }, [forceViewCtrl, handlePlayPause, mobile, paused]);

  const videoPlayerMarkup = useMemo(() => {
    return (
      <div
        className={clsx(classes.videoDiv, {
          [classes.videoWideDiv]: screenMode === "wide",
          [classes.modalFullScreen]: fullHeight,
          [classes.videoFullScreen]: fullscreen,
        })}
        style={
          screenMode !== "wide" && !fullscreen && !fullHeight
            ? { paddingTop: `min(480px, ${videoRatio})` }
            : undefined
        }
        onClick={handleScreenPlay}
        onMouseEnter={() => !paused && setHideCtrl(false)}
        onMouseMove={() => !paused && setHideCtrl(false)}
        onMouseLeave={() => !paused && !forceViewCtrl && setHideCtrl(true)}
      >
        <div
          className={clsx(classes.video, {
            [classes.videoHFlip]: !vFlip && hFlip,
            [classes.videoVFlip]: vFlip && !hFlip,
            [classes.videoFlip]: vFlip && hFlip,
          })}
        >
          <video
            style={{ width: "100%", height: "100%" }}
            ref={ref}
            className="video-js"
            playsInline
            webkit-playsinline
          />
        </div>
        {(!camera || !vod) && (
          <div className={classes.video}>
            <BlackvueLogoOutlined className={classes.blackvueIcon} />
          </div>
        )}
        <div className={classes.overlayDiv} />
        {loading && (
          <div className={classes.video}>
            <CircularProgress
              className={classes.circularLoading}
              size={48}
              thickness={5}
            />
          </div>
        )}
        {(!hideCtrl || forceViewCtrl) && (
          <>
            {mobile && !loading && vod && (
              <div className={classes.mobilePlayCtrlDiv}>
                <div className={classes.mobilePlayCtrl}>
                  {renderCtrlIcon(
                    <PlayerPreviousFrame style={{ fontSize: "1.8125rem" }} />,
                    handlePrevFrame,
                    !vod
                  )}
                  {renderCtrlIcon(
                    paused ? (
                      <PlayArrowIcon style={{ fontSize: "3rem" }} />
                    ) : (
                      <PauseIcon style={{ fontSize: "3rem" }} />
                    ),
                    handlePlayPause,
                    !vod
                  )}
                  {renderCtrlIcon(
                    <PlayerNextFrame style={{ fontSize: "1.8125rem" }} />,
                    handleNextFrame,
                    !vod
                  )}
                </div>
              </div>
            )}
            <div
              className={classes.ctrlContDiv}
              onClick={(e) => e.stopPropagation()}
            >
              <ProgressSlider
                value={
                  0.5 +
                  (current.asSeconds() / Math.max(1, duration.asSeconds())) * 99
                }
                onMouseDown={() => {
                  setForceViewCtrl(true);
                }}
                onMouseUp={() => {
                  setForceViewCtrl(false);
                }}
                onChange={(_e, newVal) => {
                  const val = ((newVal as number) - 0.5) / 99;
                  setCurrentPlayTime(val * duration.asSeconds());
                }}
                aria-labelledby="continuous-slider"
              />

              <div className={classes.ctrlDiv}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {!singleVideo && (
                    <Tooltip
                      disableTouchListener={mobile}
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("Previous")}
                        </Typography>
                      }
                      placement="top"
                    >
                      {renderCtrlIcon(
                        <SkipPreviousIcon />,
                        onPrev,
                        disablePrev || !vod
                      )}
                    </Tooltip>
                  )}

                  {!mobile && (
                    <>
                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("Previous frame")}
                          </Typography>
                        }
                        placement="top"
                      >
                        {renderCtrlIcon(
                          <PlayerPreviousFrame />,
                          handlePrevFrame,
                          !vod
                        )}
                      </Tooltip>

                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {paused ? t("Play") : t("Pause")}
                          </Typography>
                        }
                        placement="top"
                      >
                        {renderCtrlIcon(
                          paused ? <PlayArrowIcon /> : <PauseIcon />,
                          handlePlayPause,
                          !vod
                        )}
                      </Tooltip>

                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("Next frame")}
                          </Typography>
                        }
                        placement="top"
                      >
                        {renderCtrlIcon(
                          <PlayerNextFrame />,
                          handleNextFrame,
                          !vod
                        )}
                      </Tooltip>
                    </>
                  )}

                  {!singleVideo && (
                    <Tooltip
                      disableTouchListener={mobile}
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("Next")}
                        </Typography>
                      }
                      placement="top"
                    >
                      {renderCtrlIcon(
                        <SkipNextIcon />,
                        onNext,
                        disableNext || !vod
                      )}
                    </Tooltip>
                  )}
                  <div
                    style={{ display: "flex" }}
                    onMouseEnter={() => setOpenVolumeMenu(true)}
                    onMouseLeave={() => setOpenVolumeMenu(false)}
                  >
                    {!mobile && volume === 0 ? (
                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("UnMute")}
                          </Typography>
                        }
                        placement="top"
                      >
                        <IconButton
                          className={classes.ctrlIcon}
                          ref={volumeAnchorRef}
                          onClick={(e) => {
                            e.stopPropagation();
                            setVolume(preVolume);
                          }}
                        >
                          <VolumeOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      !mobile && (
                        <Tooltip
                          title={
                            <Typography category="Default" variant="Caption">
                              {t("Mute")}
                            </Typography>
                          }
                          placement="top"
                        >
                          <IconButton
                            className={classes.ctrlIcon}
                            ref={volumeAnchorRef}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPreVolume(volume);
                              setVolume(0);
                            }}
                          >
                            <VolumeUpIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                    {openVolumeMenu && (
                      <div className={classes.volumeCtrlDiv}>
                        <VolumeSlider
                          value={volume}
                          onChange={(e, newVal) => setVolume(newVal as number)}
                        />
                      </div>
                    )}
                  </div>

                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["0"]}
                  >
                    {getTime()}
                  </Typography>
                </div>
                <div>
                  <Tooltip
                    disableTouchListener={mobile}
                    disableHoverListener={mobile}
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("Playback speed")}
                      </Typography>
                    }
                    placement="top"
                  >
                    <IconButton
                      className={classes.ctrlIcon}
                      ref={speedAnchorRef}
                      onClick={() => {
                        setForceViewCtrl(true);
                        setOpenPlaySpeedMenu((o) => !o);
                        setOpenFlipMenu(false);
                        setOpenQualityMenu(false);
                      }}
                    >
                      <Typography category="Default" variant="Small">
                        {playSpeed}x
                      </Typography>
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    disableTouchListener={mobile}
                    disableHoverListener={mobile}
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("Flip")}
                      </Typography>
                    }
                    placement="top"
                  >
                    <IconButton
                      className={classes.ctrlIcon}
                      ref={flipAnchorRef}
                      onClick={() => {
                        setForceViewCtrl(true);
                        setOpenFlipMenu((f) => !f);
                        setOpenPlaySpeedMenu(false);
                        setOpenQualityMenu(false);
                      }}
                    >
                      <FlipIcon />
                    </IconButton>
                  </Tooltip>

                  {showQuality && (
                    <Tooltip
                      disableTouchListener={mobile}
                      disableHoverListener={mobile}
                      title={
                        <Typography category="Default" variant="Caption">
                          {disableQuality
                            ? t("Original file play_")
                            : t("Resolution")}
                        </Typography>
                      }
                      placement="top"
                    >
                      <span>
                        <IconButton
                          className={classes.ctrlIcon}
                          ref={qualityAnchorRef}
                          disabled={disableQuality || disableUndefined}
                          onClick={() => {
                            setForceViewCtrl(true);
                            setOpenQualityMenu((o) => !o);
                            setOpenFlipMenu(false);
                            setOpenPlaySpeedMenu(false);
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                  {!mobile && !noTheater && (
                    <Tooltip
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("Theater mode")}
                        </Typography>
                      }
                      placement="top"
                    >
                      <IconButton
                        className={classes.ctrlIcon}
                        onClick={onTheater}
                      >
                        {theater ? <Crop75Icon /> : <Crop54Icon />}
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip
                    disableTouchListener={mobile}
                    disableHoverListener={mobile}
                    open={openTooltip}
                    onOpen={() => setOpenTooltip(true)}
                    onClose={() => setOpenTooltip(false)}
                    title={
                      <Typography category="Default" variant="Caption">
                        {fullscreen ? t("Exit fullscreen") : t("Fullscreen")}
                      </Typography>
                    }
                    placement="top"
                  >
                    <IconButton
                      className={classes.ctrlIcon}
                      onClick={handleFullscreen}
                    >
                      {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }, [
    camera,
    classes.blackvueIcon,
    classes.circularLoading,
    classes.ctrlContDiv,
    classes.ctrlDiv,
    classes.ctrlIcon,
    classes.mobilePlayCtrl,
    classes.mobilePlayCtrlDiv,
    classes.modalFullScreen,
    classes.overlayDiv,
    classes.video,
    classes.videoDiv,
    classes.videoFlip,
    classes.videoFullScreen,
    classes.videoHFlip,
    classes.videoVFlip,
    classes.videoWideDiv,
    classes.volumeCtrlDiv,
    current,
    disableNext,
    disablePrev,
    disableQuality,
    disableUndefined,
    duration,
    forceViewCtrl,
    fullHeight,
    fullscreen,
    getTime,
    hFlip,
    handleFullscreen,
    handleNextFrame,
    handlePlayPause,
    handlePrevFrame,
    handleScreenPlay,
    hideCtrl,
    loading,
    mobile,
    noTheater,
    onNext,
    onPrev,
    onTheater,
    openTooltip,
    openVolumeMenu,
    paused,
    playSpeed,
    preVolume,
    renderCtrlIcon,
    screenMode,
    showQuality,
    singleVideo,
    t,
    theater,
    vFlip,
    videoRatio,
    vod,
    volume,
  ]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootFullscreen]: iosBrowser && rootFullscreen,
      })}
      style={{ width: "100%" }}
      ref={videoRef}
    >
      <div className={clsx(classes.menuDiv)}>
        <Typography
          category="Default"
          variant={mobile ? "Small" : "Body"}
          className={clsx({ [classes.videoTitleDiv]: mobile })}
        >
          {vod &&
            `${vod?.time.format("HH:mm:ss YYYY-MM-DD")} · ${vod?.event} ${
              vod?.direction
            }`}
        </Typography>
        {_.compact([vod?.hasFront, vod?.hasRear, vod?.hasInterior]).length >=
          2 && (
          <div
            className={clsx(classes.dirDiv, {
              [classes.disabled]: !vod,
            })}
            ref={menuAnchorRef}
            onClick={() => vod && setOpenMenu(true)}
          >
            <Typography category="Default" variant="BodyBold">
              {t(vod ? vod.direction : "Front")}
            </Typography>
            <ExpandMoreIcon fontSize="small" />
          </div>
        )}
      </div>
      {videoPlayerMarkup}
      {mobile && (
        <MobileMenu
          open={openQualityMenu}
          onClose={() => setOpenQualityMenu(false)}
          container={videoRef.current}
        >
          {vod?.hasLow && (
            <WebMenuItem
              endIcon={quality === "low" && <CheckIcon fontSize="small" />}
              className={clsx(classes.mobileMemuItem)}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("low");
              }}
            >
              {t("Low resolution")}
            </WebMenuItem>
          )}

          {vod?.hasOriginal && (
            <WebMenuItem
              endIcon={quality === "original" && <CheckIcon fontSize="small" />}
              className={clsx(classes.mobileMemuItem)}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("original");
              }}
            >
              {t("Original")}
            </WebMenuItem>
          )}
        </MobileMenu>
      )}
      {!mobile && (
        <Menu
          style={{ zIndex: 2147483647 }}
          paperClasses={{ root: classes.playerMenuDiv }}
          open={openQualityMenu}
          anchorEl={qualityAnchorRef.current}
          onClickAway={() => setOpenQualityMenu(false)}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 10px",
            },
          }}
          placement="top"
        >
          {vod?.hasLow && (
            <WebMenuItem
              startIcon={quality === "low" && <CheckIcon fontSize="small" />}
              className={clsx(classes.webMenuDiv, {
                [classes.uncheckedMenu]: quality !== "low",
              })}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("low");
              }}
            >
              {t("Low resolution")}
            </WebMenuItem>
          )}

          {vod?.hasOriginal && (
            <WebMenuItem
              startIcon={
                quality === "original" && <CheckIcon fontSize="small" />
              }
              className={clsx(classes.webMenuDiv, {
                [classes.uncheckedMenu]: quality !== "original",
              })}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("original");
              }}
            >
              {t("Original")}
            </WebMenuItem>
          )}
        </Menu>
      )}

      {mobile && (
        <MobileMenu
          open={openPlaySpeedMenu}
          onClose={() => setOpenPlaySpeedMenu(false)}
          container={videoRef.current}
        >
          <WebMenuItem
            endIcon={playSpeed === 0.5 && <CheckIcon fontSize="small" />}
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setForceViewCtrl(false);
              setPlaySpeed(0.5);
            }}
          >
            {t("0.5x")}
          </WebMenuItem>

          <WebMenuItem
            endIcon={playSpeed === 1 && <CheckIcon fontSize="small" />}
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setForceViewCtrl(false);
              setPlaySpeed(1);
            }}
          >
            {t("1x")}
          </WebMenuItem>
        </MobileMenu>
      )}

      {!mobile && (
        <Menu
          style={{ zIndex: 2147483647 }}
          paperClasses={{ root: classes.playerMenuDiv }}
          open={openPlaySpeedMenu}
          anchorEl={speedAnchorRef.current}
          onClickAway={() => setOpenPlaySpeedMenu(false)}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 10px",
            },
          }}
          placement="top"
        >
          <WebMenuItem
            startIcon={playSpeed === 0.5 && <CheckIcon fontSize="small" />}
            className={clsx(classes.webMenuDiv, {
              [classes.uncheckedMenu]: playSpeed !== 0.5,
            })}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setPlaySpeed(0.5);
            }}
          >
            {t("0.5x")}
          </WebMenuItem>

          <WebMenuItem
            startIcon={playSpeed === 1 && <CheckIcon fontSize="small" />}
            className={clsx(classes.webMenuDiv, {
              [classes.uncheckedMenu]: playSpeed !== 1,
            })}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setPlaySpeed(1);
            }}
          >
            {t("1x")}
          </WebMenuItem>
        </Menu>
      )}

      {mobile && (
        <MobileMenu
          open={openFlipMenu}
          onClose={() => setOpenFlipMenu(false)}
          container={videoRef.current}
        >
          <WebMenuItem
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenFlipMenu(false);
              setHFlip((f) => !f);
            }}
          >
            {t("Flip horizontally")}
          </WebMenuItem>

          <WebMenuItem
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenFlipMenu(false);
              setVFlip((f) => !f);
            }}
          >
            {t("Flip vertically")}
          </WebMenuItem>
        </MobileMenu>
      )}
      {!mobile && (
        <Menu
          style={{ zIndex: 2147483647 }}
          paperClasses={{ root: classes.playerMenuDiv }}
          open={openFlipMenu}
          anchorEl={flipAnchorRef.current}
          onClickAway={() => setOpenFlipMenu(false)}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 10px",
            },
          }}
          placement="top"
        >
          <WebMenuItem
            className={clsx(classes.webMenuDiv)}
            onClick={() => {
              setOpenFlipMenu(false);
              setForceViewCtrl(false);
              setHFlip((f) => !f);
            }}
          >
            {t("Flip horizontally")}
          </WebMenuItem>

          <WebMenuItem
            className={clsx(classes.webMenuDiv)}
            onClick={() => {
              setOpenFlipMenu(false);
              setForceViewCtrl(false);
              setVFlip((f) => !f);
            }}
          >
            {t("Flip vertically")}
          </WebMenuItem>
        </Menu>
      )}

      {mobile && (
        <MobileMenu
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          container={videoRef.current}
        >
          {vod?.hasFront && (
            <WebMenuItem
              endIcon={
                vod?.direction === "Front" && <CheckIcon fontSize="small" />
              }
              className={classes.mobileMemuItem}
              onClick={() => {
                setOpenMenu(false);
                onRequestFront?.(vod.filename);
              }}
            >
              {t("Front")}
            </WebMenuItem>
          )}
          {vod?.hasRear && (
            <WebMenuItem
              endIcon={
                vod?.direction === "Rear" && <CheckIcon fontSize="small" />
              }
              className={classes.mobileMemuItem}
              onClick={() => {
                setOpenMenu(false);
                onRequestRear?.(vod.filename);
              }}
            >
              {t("Rear")}
            </WebMenuItem>
          )}
          {vod?.hasInterior && (
            <WebMenuItem
              endIcon={
                vod?.direction === "Interior" && <CheckIcon fontSize="small" />
              }
              className={classes.mobileMemuItem}
              onClick={() => {
                setOpenMenu(false);
                onRequestInterior?.(vod.filename);
              }}
            >
              {t("Interior")}
            </WebMenuItem>
          )}
        </MobileMenu>
      )}

      {!mobile && (
        <Menu
          open={openMenu}
          anchorEl={menuAnchorRef.current}
          onClickAway={() => setOpenMenu(false)}
          modifiers={{
            preventOverflow: { enabled: false },
            offset: {
              enabled: true,
              offset: "0, 12px",
            },
          }}
          placement="bottom-end"
        >
          {vod?.hasFront && (
            <WebMenuItem
              startIcon={
                vod?.direction === "Front" && <CheckIcon fontSize="small" />
              }
              className={clsx({
                [classes.uncheckedMenu]: vod?.direction !== "Front",
              })}
              onClick={() => {
                setOpenMenu(false);
                onRequestFront?.(vod.filename);
              }}
            >
              {t("Front")}
            </WebMenuItem>
          )}
          {vod?.hasRear && (
            <WebMenuItem
              startIcon={
                vod?.direction === "Rear" && <CheckIcon fontSize="small" />
              }
              className={clsx({
                [classes.uncheckedMenu]: vod?.direction !== "Rear",
              })}
              onClick={() => {
                setOpenMenu(false);
                onRequestRear?.(vod.filename);
              }}
            >
              {t("Rear")}
            </WebMenuItem>
          )}
          {vod?.hasInterior && (
            <WebMenuItem
              startIcon={
                vod?.direction === "Interior" && <CheckIcon fontSize="small" />
              }
              className={clsx({
                [classes.uncheckedMenu]: vod?.direction !== "Interior",
              })}
              onClick={() => {
                setOpenMenu(false);
                onRequestInterior?.(vod.filename);
              }}
            >
              {t("Interior")}
            </WebMenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};
