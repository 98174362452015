import ar from "./translations/ar.json";
import de from "./translations/de.json";
import en from "./translations/en.json";
import es from "./translations/es.json";
import fi from "./translations/fi.json";
import fr from "./translations/fr.json";
import id from "./translations/id.json";
import it from "./translations/it.json";
import ja from "./translations/ja.json";
import ko from "./translations/ko.json";
import nb from "./translations/nb.json";
import nl from "./translations/nl.json";
import pl from "./translations/pl.json";
import ru from "./translations/ru.json";
import sc from "./translations/sc.json";
import th from "./translations/th.json";
import vi from "./translations/vi.json";

export const resources = {
  ar: {
    translation: ar,
  },
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  fi: {
    translation: fi,
  },
  fr: {
    translation: fr,
  },
  id: {
    translation: id,
  },
  it: {
    translation: it,
  },
  ja: {
    translation: ja,
  },
  ko: {
    translation: ko,
  },
  nb: {
    translation: nb,
  },
  nl: {
    translation: nl,
  },
  pl: {
    translation: pl,
  },
  ru: {
    translation: ru,
  },
  sc: {
    translation: sc,
  },
  th: {
    translation: th,
  },
  vi: {
    translation: vi,
  },
} as const;
