import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  LightColors,
  Menu,
  MobileMenu,
  Modal,
  RadioButton,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";

import {
  CAMERA,
  ICameraInfo,
  loadCameras,
  loadMultiCamLocation,
  loadMyCemrasLocation,
  TabNameInfo1,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { LiveViewPlayer } from "./LiveViewPlayer";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import update from "immutability-helper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Webviewer } from "../../contants/Breakpoints";
import { Direction, Front, Rear, Interior, IGPSLocation } from "../../types";
import { getDirection } from "../../utils/VOD";
import { LocationMap } from "../maps/LocationMap";
import { MODELS_2CH, MODELS_3CH } from "../../contants/Models";
import { PERMISSION } from "../../features/Permission/slice";
import { useHistory, useLocation } from "react-router-dom";
import { loadUsageInfo, USER } from "../../features/User/slice";
import { PAYMENT } from "../../features/Payment/slice";
import { EmptyItems } from "../EmptyItems";

const StyledSwitch = withStyles(() => ({
  root: {
    width: 48,
    height: 30,
    padding: 4,
  },
  switchBase: {
    padding: 6,
    "&.Mui-checked": {
      transform: "translateX(18px)",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    backgroundColor: LightColors.primary["4"],
    borderRadius: 11,
  },
  colorPrimary: {
    "&.Mui-checked": {
      color: LightColors.primary["0"],
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: LightColors.primary["7"],
      opacity: 1,
    },
  },
}))(Switch);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // padding: theme.spacing(3, 0, 0),
    padding: (props: LiveViewPanelProps) =>
      props.notification ? theme.spacing(2, 0, 0) : theme.spacing(3, 0, 0),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(0, 4, 2, 4),
    },
  },
  menuDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: LightColors.primary["1"],
    color: LightColors.primary["0"],
    padding: theme.spacing(1.125, 1),
    "& svg": {
      fill: LightColors.primary["0"],
    },
    borderBottom: `1px solid ${LightColors.primary["0"]}`,
  },
  dirDiv: {
    display: "flex",
    alignItems: "center",
    // width: 62,
    marginRight: 5.5,
    cursor: "pointer",
  },
  mapDiv: {
    height: 188,
    position: "relative",
    marginTop: theme.spacing(3),
  },
  uncheckedMenu: {
    paddingLeft: 44,
  },
  multiViewTextDiv: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  liveViewDiv: {
    cursor: "pointer",
    width: "calc(50% - 0.5px)",
    "&:nth-child(odd)": {
      marginRight: 1,
    },
    marginBottom: 1,
  },
  selectedLiveviewDiv: {
    border: `3px solid ${LightColors.secondary["17"]}`,
  },
  sLiveViewDiv: {
    width: "100%",
    margin: "0!important",
  },
  multiViewDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  cameraDrawerDiv: {
    width: 469,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  iconBtn: {
    padding: 0,
  },
  drawerHeaderDiv: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  cameraDiv: {
    flex: 1,
    padding: theme.spacing(2, 3),
  },
  drawerBtnDiv: {
    height: 68,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(2),
  },
  cancelBtn: {
    marginRight: theme.spacing(2),
  },
  searchIcon: {
    "& svg": {
      fontSize: "1rem!important",
    },
  },
  camListDiv: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1.375),
  },
  camListItem: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  camName: {
    marginLeft: theme.spacing(0.5),
  },
  modelName: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(-1),
  },
  videoTitleDiv: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  noGpsDiv: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(19, 19, 28, 0.45)",
  },
  modalTitle: {
    minHeight: 16,
  },
  emptyDiv: {
    marginTop: "6.75vh",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: "27.7vh",
    },
  },
  noCamText: {
    marginTop: theme.spacing(1.125),
  },
}));

export interface LiveViewPanelProps {
  camera: ICameraInfo;
  isEvent?: boolean;
  isPublic?: boolean;
  dir?: Direction;
  notification?: boolean;
}

export const LiveViewPanel = (props: LiveViewPanelProps) => {
  const { camera, isEvent, dir, isPublic } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ clicked?: boolean }>();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const updateCenter = useRef<boolean>(false);

  const menusRef = useRef<RefObject<HTMLDivElement>[]>([
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
  ]);
  const [menuAnchorRef, setMenuAnchorRef] = useState<RefObject<HTMLDivElement>>(
    menusRef.current[4]
  );

  const { permission } = useSelector((state: RootState) => state[PERMISSION]);
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const { cameraList, publicCams, firmware } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const { usageInfo } = useSelector((state: RootState) => state[USER]);

  const videoSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const [openMenu, setOpenMenu] = useState(false);
  const [openCameraMenu, setOpenCameraMenu] = useState(false);
  const [multiview, setMultiview] = useState(false);
  const [cameras, setCameras] = useState<(ICameraInfo | undefined)[]>([
    undefined,
    undefined,
    undefined,
  ]);
  const [directions, setDirections] = useState<Direction[]>([
    dir ?? Front,
    Front,
    Front,
    Front,
  ]);
  const [viewIndx, setViewIndx] = useState<number>();
  const [addedCam, setAddedCam] = useState<string>();
  const [, setMap] = useState<any>();
  const [locations, setLocations] = useState<IGPSLocation[]>([]);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [selectedPSN, setSelectedPSN] = useState<string>();
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const [disableLiveview, setDisableLiveview] = useState(true);
  const [openLimitLiveview, setOpenLimitLiveview] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const loginInfo = useSelector((state: RootState) => state[USER].loginInfo);

  useEffect(() => {
    // refresh될 경우 clicked flag 삭제
    window.onbeforeunload = (e: any) => {
      history.replace(location.pathname);
    };
  }, [history, location.pathname]);

  useEffect(() => {
    if (subscriptionInfo?.servicePlanName === "free") {
      if (usageInfo && usageInfo.liveViewDayUsage < usageInfo.LimitLiveView) {
        setDisableLiveview(false);
      } else if (
        usageInfo &&
        usageInfo.liveViewDayUsage >= usageInfo.LimitLiveView
      ) {
        setOpenLimitLiveview(true);
      }
    } else if (subscriptionInfo) {
      setDisableLiveview(false);
    }
  }, [usageInfo, subscriptionInfo]);

  useEffect(() => {
    if (loginInfo?.userType === "Master" || loginInfo?.userType === "Etc") {
      dispatch(loadUsageInfo());
    }
    dispatch(loadCameras());
  }, [dispatch, loginInfo?.userType]);

  useEffect(() => {
    if (!permission.liveviewPerm) {
      history.goBack();
    }
  }, [history, permission.liveviewPerm]);

  useEffect(() => {
    setCurrentCam(camera);
    setSelectedPSN(camera.psn);
  }, [camera]);

  useEffect(() => {
    if (mobile) {
      setMultiview(false);
    }
  }, [mobile]);

  useEffect(() => {
    const cancel = new AbortController();
    if (permission.multiLiveviewPerm) {
      dispatch(
        loadMultiCamLocation({
          psns: _.compact([
            camera?.psn,
            ..._.chain(cameras)
              .map((cam) => cam?.psn)
              .value(),
          ]),
          cancel,
        })
      );
    } else {
      dispatch(loadMyCemrasLocation({ cancel }));
    }
    return () => {
      cancel.abort();
    };
  }, [dispatch, camera, cameras, permission.multiLiveviewPerm]);

  useEffect(() => {
    const locs: IGPSLocation[] = [];
    if (cameraList) {
      const psns = [camera?.psn, ..._.map(cameras, (c) => c?.psn)];
      const cams = _.filter(cameraList.DeviceListInfo, (c) =>
        _.includes(psns, c.device.psn)
      );
      for (const cam of cams) {
        const device = cam.device;
        if (device.latitude && device.longitude && device.UseGpsInfo === "1") {
          const lat = parseFloat(device.latitude);
          const lng = parseFloat(device.longitude);
          locs.push({
            lat,
            lng,
            heading: parseFloat(device.dir_angle ?? "0"),
            psn: device.psn,
            name: device.dev_name,
            speed: device.speed,
            mode: device.mode,
            model: device.model,
            active: device.active,
          });
          if (device.psn === selectedPSN && updateCenter.current) {
            updateCenter.current = false;
            setCenter({ lat, lng });
          }
        }
      }
    }

    const found = _.find(publicCams, (cam) => cam.psn === camera.psn);
    if (found) {
      const { lat, lng } = found;
      locs.push({
        lat: found.lat,
        lng: found.lng,
        heading: 0,
        psn: found.psn,
        name: camera.share_dev_name === "on" ? found.dev_name : undefined,
        speed: found.speed,
        mode: found.mode,
        active: found.active,
        share_video: found.share_video,
      });
      setCenter({ lat, lng });
    }

    setLocations(locs);
  }, [
    camera.psn,
    camera.share_dev_name,
    cameraList,
    cameras,
    multiview,
    publicCams,
    selectedPSN,
  ]);

  const setCamDirection = useCallback(
    (dir: Direction) => {
      let indx = -1;
      if (camera.psn === currentCam?.psn) {
        indx = 0;
      }
      const camIndx = _.findIndex(
        cameras,
        (dev) => dev?.psn === currentCam?.psn
      );
      if (camIndx > -1) {
        indx = camIndx + 1;
      }
      setOpenMenu(false);
      if (indx > -1) {
        setDirections((d) => {
          d[indx] = dir;
          return [...d];
        });
      }
    },
    [camera.psn, cameras, currentCam?.psn]
  );

  const directionMenuMarkup = useMemo(() => {
    let indx = -1;
    if (camera.psn === currentCam?.psn) {
      indx = 0;
    }
    const camIndx = _.findIndex(cameras, (dev) => dev?.psn === currentCam?.psn);
    if (camIndx > -1) {
      indx = camIndx + 1;
    }

    const dir = directions[indx];

    return _.compact([
      <WebMenuItem
        key="direction-front"
        startIcon={!mobile && dir === Front && <CheckIcon fontSize="small" />}
        endIcon={mobile && dir === Front && <CheckIcon fontSize="small" />}
        className={clsx({
          [classes.uncheckedMenu]: dir !== Front && !mobile,
          [classes.mobileMemuItem]: mobile,
        })}
        onClick={() => setCamDirection(Front)}
      >
        {t("Front")}
      </WebMenuItem>,
      _.includes([...MODELS_2CH, ...MODELS_3CH], currentCam?.model) && (
        <WebMenuItem
          key="direction-rear"
          startIcon={!mobile && dir === Rear && <CheckIcon fontSize="small" />}
          endIcon={mobile && dir === Rear && <CheckIcon fontSize="small" />}
          className={clsx({
            [classes.uncheckedMenu]: dir !== Rear && !mobile,
            [classes.mobileMemuItem]: mobile,
          })}
          onClick={() => setCamDirection(Rear)}
        >
          {t("Rear")}
        </WebMenuItem>
      ),
      _.includes(MODELS_3CH, currentCam?.model) && (
        <WebMenuItem
          key="direction-interior"
          startIcon={
            !mobile && dir === Interior && <CheckIcon fontSize="small" />
          }
          endIcon={mobile && dir === Interior && <CheckIcon fontSize="small" />}
          className={clsx({
            [classes.uncheckedMenu]: dir !== Interior && !mobile,
            [classes.mobileMemuItem]: mobile,
          })}
          onClick={() => setCamDirection(Interior)}
        >
          {t("Interior")}
        </WebMenuItem>
      ),
    ]);
  }, [
    camera.psn,
    cameras,
    classes.mobileMemuItem,
    classes.uncheckedMenu,
    currentCam?.model,
    currentCam?.psn,
    directions,
    mobile,
    setCamDirection,
    t,
  ]);

  const handleChannelChange = useCallback(
    (indx: number) => (dir: Direction) => {
      setDirections((d) => {
        d[indx] = dir;
        return [...d];
      });
    },
    []
  );

  const playerMarkup1 = useMemo(() => {
    return (
      <LiveViewPlayer
        camera={camera}
        isPublic={isPublic}
        clicked={location.state?.clicked}
        main
        disabled={disableLiveview}
        multiView={multiview}
        selected={multiview && selectedPSN === camera.psn}
        direction={directions[0]}
        menuAnchorRef={menusRef.current[0]}
        onMenuOpen={() => {
          setOpenMenu((o) => !o);
          setMenuAnchorRef(menusRef.current[0]);
          setCurrentCam(camera);
        }}
        onChannelChange={handleChannelChange(0)}
      />
    );
  }, [
    camera,
    directions,
    disableLiveview,
    handleChannelChange,
    isPublic,
    location.state,
    multiview,
    selectedPSN,
  ]);

  const playerMarkup2 = useMemo(() => {
    return (
      <LiveViewPlayer
        camera={cameras[0]}
        disabled={disableLiveview}
        selected={selectedPSN === cameras[0]?.psn}
        onAddCamera={() => {
          setViewIndx(0);
          setOpenCameraMenu(true);
        }}
        onMenuOpen={() => {
          setMenuAnchorRef(menusRef.current[1]);
          setOpenMenu((o) => !o);
          setCurrentCam(cameras[0]);
        }}
        menuAnchorRef={menusRef.current[1]}
        multiView={multiview}
        direction={directions[1]}
        onClose={() => {
          cameras[0] = undefined;
          setCameras([...cameras]);
        }}
        onChannelChange={handleChannelChange(1)}
      />
    );
  }, [
    cameras,
    directions,
    disableLiveview,
    handleChannelChange,
    multiview,
    selectedPSN,
  ]);

  const playerMarkup3 = useMemo(() => {
    return (
      <LiveViewPlayer
        camera={cameras[1]}
        disabled={disableLiveview}
        selected={selectedPSN === cameras[1]?.psn}
        onAddCamera={() => {
          setViewIndx(1);
          setOpenCameraMenu(true);
        }}
        onMenuOpen={() => {
          setMenuAnchorRef(menusRef.current[2]);
          setOpenMenu((o) => !o);
          setCurrentCam(cameras[1]);
        }}
        menuAnchorRef={menusRef.current[2]}
        multiView={multiview}
        direction={directions[2]}
        onClose={() => {
          cameras[1] = undefined;
          setCameras([...cameras]);
        }}
        onChannelChange={handleChannelChange(2)}
      />
    );
  }, [
    cameras,
    directions,
    disableLiveview,
    handleChannelChange,
    multiview,
    selectedPSN,
  ]);

  const playerMarkup4 = useMemo(() => {
    return (
      <LiveViewPlayer
        camera={cameras[2]}
        disabled={disableLiveview}
        selected={selectedPSN === cameras[2]?.psn}
        onAddCamera={() => {
          setViewIndx(2);
          setOpenCameraMenu(true);
        }}
        onMenuOpen={() => {
          setMenuAnchorRef(menusRef.current[3]);
          setOpenMenu((o) => !o);
          setCurrentCam(cameras[2]);
        }}
        menuAnchorRef={menusRef.current[3]}
        multiView={multiview}
        direction={directions[3]}
        onClose={() => {
          cameras[2] = undefined;
          setCameras([...cameras]);
        }}
        onChannelChange={handleChannelChange(3)}
      />
    );
  }, [
    cameras,
    directions,
    disableLiveview,
    handleChannelChange,
    multiview,
    selectedPSN,
  ]);

  // const candidateCams = useMemo(() => {
  //   return _.chain(cameraList?.DeviceListInfo)
  //     .filter((cam) => cam.device.psn !== camera?.psn)
  //     .map((dev) => dev.device)
  //     .value();
  // }, [camera?.psn, cameraList?.DeviceListInfo]);

  const candidateCams = useMemo(() => {
    return _.chain(cameraList?.DeviceListInfo)
      .filter(
        (cam) =>
          cam.device.psn !== camera?.psn &&
          !_.chain(cameras)
            .map((c) => c?.psn)
            .includes(cam.device.psn)
            .value()
      )
      .value();
  }, [camera?.psn, cameraList?.DeviceListInfo, cameras]);
  const filteredCameras = useMemo(() => {
    return _.filter(
      candidateCams,
      (camera) =>
        camera.device.dev_name.toLowerCase().indexOf(searchKey.toLowerCase()) >
          -1 ||
        camera.device.model.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
    );
  }, [candidateCams, searchKey]);
  // console.log(
  //   "locations111",
  //   publicCams,
  //   videoSettings?.["UseGpsInfo"],
  //   locations,
  //   center
  // );

  return (
    <div className={classes.root}>
      <div style={{ flex: 1 }}>
        <div className={clsx(classes.menuDiv)}>
          {!multiview ? (
            <Typography
              category="Default"
              variant="Body"
              className={clsx({ [classes.videoTitleDiv]: mobile })}
            >
              {camera &&
                (camera?.share_dev_name === "off"
                  ? "BLACKVUE"
                  : `${camera.dev_name} (${camera.model})`)}
            </Typography>
          ) : (
            <div></div>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {!multiview && !isPublic && (
              <div
                className={clsx(classes.dirDiv)}
                ref={menusRef.current[4]}
                onClick={() => setOpenMenu((o) => !o)}
              >
                <Typography category="Default" variant="BodyBold">
                  {t(getDirection(directions[0]))}
                </Typography>
                <ExpandMoreIcon fontSize="small" />
              </div>
            )}
            {!mobile && !isEvent && permission.multiLiveviewPerm && (
              <>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  className={classes.multiViewTextDiv}
                >
                  {t("Multi View")}
                </Typography>
                <StyledSwitch
                  color="primary"
                  checked={multiview}
                  onChange={(e, checked) => {
                    if (!checked) {
                      setMenuAnchorRef(menusRef.current[4]);
                    }
                    setMultiview(checked);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={classes.multiViewDiv}>
          <div
            className={clsx(classes.liveViewDiv, {
              [classes.sLiveViewDiv]: !multiview,
            })}
            onClick={() => {
              updateCenter.current = true;
              setSelectedPSN(camera.psn);
            }}
          >
            {playerMarkup1}
          </div>
          {multiview && (
            <>
              <div
                className={clsx(classes.liveViewDiv)}
                onClick={() => {
                  updateCenter.current = true;
                  setSelectedPSN(cameras[0]?.psn);
                }}
              >
                {playerMarkup2}
              </div>
              <div
                className={clsx(classes.liveViewDiv)}
                onClick={() => {
                  updateCenter.current = true;
                  setSelectedPSN(cameras[1]?.psn);
                }}
              >
                {playerMarkup3}
              </div>
              <div
                className={clsx(classes.liveViewDiv)}
                onClick={() => {
                  updateCenter.current = true;
                  setSelectedPSN(cameras[2]?.psn);
                }}
              >
                {playerMarkup4}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classes.mapDiv}>
        <LocationMap
          disableAutoZoom
          onGoogleApiLoaded={({ map }) => {
            setMap(map);
          }}
          publicCams={publicCams}
          locations={videoSettings?.["UseGpsInfo"] === "0" ? [] : locations}
          center={center}
          fullscreenIcon
          mode="liveview"
        />
        {videoSettings?.["UseGpsInfo"] === "0" && (
          <div className={classes.noGpsDiv}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["1"]}
            >
              {t("GPS Location recording off")}
            </Typography>
          </div>
        )}
      </div>

      {mobile && !isPublic && (
        <MobileMenu open={openMenu} onClose={() => setOpenMenu(false)}>
          {directionMenuMarkup}
        </MobileMenu>
      )}
      {!mobile && !isPublic && (
        <Menu
          open={openMenu}
          anchorEl={menuAnchorRef.current}
          onClickAway={() => setOpenMenu(false)}
          placement="bottom-end"
        >
          {directionMenuMarkup}
        </Menu>
      )}
      <Drawer anchor={"right"} open={openCameraMenu}>
        <div className={classes.cameraDrawerDiv}>
          <div className={classes.drawerHeaderDiv}>
            <Typography category="Default" variant="H6">
              {t("Add camera to_")}
            </Typography>
            <IconButton
              className={classes.iconBtn}
              onClick={() => {
                setOpenCameraMenu(false);
                setSearchKey("");
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.cameraDiv}>
            <Input
              dense
              placeholder={t("search")}
              startIcon={<SearchIcon className={classes.searchIcon} />}
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
              disabled={candidateCams.length === 0}
            />
            {filteredCameras.length === 0 && (
              <div className={classes.emptyDiv}>
                <EmptyItems
                  variant="small"
                  mode="liveView"
                  noCamTextClassName={clsx(mobile && classes.noCamText)}
                />
              </div>
            )}
            {filteredCameras.length > 0 && (
              <FormControl className={classes.camListDiv}>
                <RadioGroup
                  value={addedCam}
                  onChange={(e) => setAddedCam(e.target.value)}
                >
                  {_.chain(cameraList?.DeviceListInfo)
                    .filter(
                      (cam) =>
                        cam.device.psn !== camera?.psn &&
                        !_.chain(cameras)
                          .map((c) => c?.psn)
                          .includes(cam.device.psn)
                          .value()
                    )
                    .filter((cam) =>
                      _.includes(
                        _.map(filteredCameras, (c) => c?.device.psn),
                        cam.device.psn
                      )
                    )
                    .map((cam) => (
                      <div className={classes.camListItem} key={cam.device.psn}>
                        <RadioButton
                          disabled={cam.device.active === "off"}
                          value={cam.device.psn}
                          label={
                            <Typography
                              className={classes.camName}
                              category="Default"
                              variant="Body"
                            >
                              {cam.device.dev_name}
                            </Typography>
                          }
                        />
                        <Typography
                          className={classes.modelName}
                          category="Default"
                          variant="Small"
                          htmlColor={LightColors.primary["2"]}
                        >
                          {cam.device.model}
                        </Typography>
                      </div>
                    ))
                    .value()}
                </RadioGroup>
              </FormControl>
            )}
          </div>
          <div className={classes.drawerBtnDiv}>
            <div>
              <Button
                variant="outlined"
                color="primary"
                className={classes.cancelBtn}
                onClick={() => {
                  setOpenCameraMenu(false);
                  setSearchKey("");
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                disabled={addedCam === undefined}
                variant="contained"
                color="primary"
                onClick={() => {
                  const cam = _.find(
                    cameraList?.DeviceListInfo,
                    (c) => c.device.psn === addedCam
                  );
                  console.log("add", viewIndx, cam, addedCam);
                  if (viewIndx !== undefined && cam) {
                    updateCenter.current = true;
                    setSelectedPSN(cam.device.psn);
                    setCameras((c) =>
                      update(c, { [viewIndx]: () => cam.device })
                    );
                    setViewIndx(undefined);
                    setOpenCameraMenu(false);
                    setAddedCam(undefined);
                  }
                }}
              >
                {t("Add")}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Modal
        open={openLimitLiveview}
        close
        onClose={() => setOpenLimitLiveview(false)}
        onClickPositive={() => {
          setOpenLimitLiveview(false);
        }}
        heading={"  "}
        titleClassName={classes.modalTitle}
        content={t("Exceeded your Live_")}
        RButton={t("OK")}
      />
    </div>
  );
};
