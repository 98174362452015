import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getPlanFromServiceID } from "../../utils/Service";
import { IPermissions } from "../Member/slice";

export type UserType = "Etc" | "Master" | "SubMaster" | "User";
export type AuthType = "blackvue" | "google" | "apple";
export type SignupMode = "free" | "fleet";
export type OnOffType = "on" | "off";

// const getPlanName = (serviceId: number) => {
//   if(serviceId === 1) {
//     return "Free plan"
//   }

// }

export interface ILogin {
  email: string;
  passwd?: string;
  mobile_uuid: string;
  mobile_name: string;
  mobile_os_type: string;
  app_ver: string;
  time_interval: number;
  tokenType: string;
  loginType?: AuthType;
  oauthToken?: string;
  mobileLang?: string;
}

export interface IUserProfile {
  email: string;
  firstName: string;
  lastName: string;
  userType?: UserType;
  subMaster?: string;
  user?: string;
  pwLastChangedDT: string;
  profilePath?: string;
}

export interface IUserLoginInfo {
  user_token: string;
  was_server: string;
  was_port: number;
  gps_server: string;
  gps_port: number;
  gps_tracking_server: string;
  gps_tracking_port: number;
  geo_fence_server: string;
  geo_fence_port: number;
  userType: UserType;
  loginType: string;
}

export interface ISendMail {
  email: string;
}

export interface ISignup {
  email: string;
  passwd?: string;
  firstName?: string;
  lastName?: string;
  mobileLang?: string;
  newsRecv?: number;
  tokenType?: string;
  signupType?: AuthType;
  oauthToken?: string;
  profileImg?: string;
  newsGroup?: string;
  signupMode?: SignupMode;
  inviteToken?: string;
}

export interface IUserConfirm {
  email: string;
  confirmNumber: string;
  mobileUUID: string;
  mobileName: string;
  mobileOSType: string;
  mobileVersion?: number;
  mobileLang?: string;
  mobileTimeInterval: string;
  tokenType?: string;
  signupMode?: SignupMode;
  cameraCnt?: number;
}

export interface IUploadImage {
  file: File;
  fileName: string;
}

export interface ILoadProfileImage {
  profilePath?: string;
}

export interface IUserName {
  firstName: string;
  lastName: string;
}

export interface IChangeName {
  firstName: string;
  lastName: string;
}

export interface IUserPasswd {
  password: string;
}

export interface IChangePasswd {
  old_passwd: string;
  new_passwd: string;
}

export interface IDeleteAccountMail {
  email?: string;
  passwd: string;
  tokenType: string;
}

export interface IUserUsageInfo {
  LimitCloudStroage: number;
  LimitLiveView: number;
  LimitVOD: number;
  cloudStorageUsage: number;
  joinDate: string;
  liveViewDayUsage: number;
  remoteVideoPlayBackAndDownLoad: number;
  resetLiveViewDate: string;
  resetRemoteVideoDate: string;
}

export interface IUserSettingsOrig {
  mapType: number; // 0: google map, 1: Baidu
  velocityUnit: string; // 0: km, 1: mile
}

export interface IUserSettings {
  mapType: string; // 0: google map, 1: Baidu
  velocityUnit: string; // 0: km, 1: mile
}

export interface ILoadNotification {
  email: string;
  uuid: string;
  device_alarm: OnOffType;
  notice_alarm: OnOffType;
  system_alarm: OnOffType;
}

export interface ISetNotification {
  device_alarm: string;
  notice_alarm: string;
  system_alarm: string;
}

export interface IAccountSubscription {
  cameraLimit: number;
  cancelled: number;
  cancelDT: number;
  endOfMonthDT: string;
  masterEmail: string;
  paymentMethod: string;
  servicePlanID: number;
  servicePlanName: typeof getPlanFromServiceID;
  startOfMonthDT: string;
  userEmail: string;
  tradeType: string;
  registered: boolean;
}

interface IState {
  loading: boolean;
  email?: string;
  oauthToken?: string;
  loginInfo?: IUserLoginInfo;
  userProfile?: IUserProfile;
  needToConfirmEmail?: boolean;
  type?: string;
  pwChangeError?: boolean;
  profileImage?: ILoadProfileImage;
  name?: IUserName;
  usageInfo?: IUserUsageInfo;
  userSettings?: IUserSettings;
  userNotification?: ILoadNotification;
  userSubscription?: IAccountSubscription;
  mobile_uuid: string;
  error?: string;
  cameraCnt?: number;
  activated?: boolean;
  permissions?: IPermissions;
}

let initialState: IState = {
  loading: false,
  //@ts-ignore
  mobile_uuid: localStorage.getItem("pitta-webviewer:mobile-uuid"),
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    logout: (state, _action: PayloadAction<boolean | undefined>) => {
      state.loading = false;
    },
    clearUser: (state) => {
      state.loading = false;
      state.loginInfo = undefined;
      state.userProfile = undefined;
      state.email = undefined;
      state.oauthToken = undefined;
      state.needToConfirmEmail = undefined;
      state.name = undefined;
      state.usageInfo = undefined;
      state.error = undefined;
      state.userNotification = undefined;
      state.activated = false;
    },
    userLogin: (
      state,
      _action: PayloadAction<
        ILogin & { goToCamera?: boolean; remember?: boolean; returnTo?: string }
      >
    ) => {
      state.loading = true;
    },
    successLogin: (state, { payload }: PayloadAction<IUserLoginInfo>) => {
      state.loading = false;
      state.loginInfo = payload;
    },
    successRequest: (state) => {
      state.loading = false;
    },
    successSignup: (state) => {
      state.loading = false;
      state.needToConfirmEmail = true;
    },
    needToConfirm: (state) => {
      state.needToConfirmEmail = true;
    },
    clearNeedToConfirmEmail: (state) => {
      state.needToConfirmEmail = false;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    loadUserProfile: (state, _action: PayloadAction<"signup" | undefined>) => {
      state.loading = true;
    },
    successLoadUserProfile: (
      state,
      { payload }: PayloadAction<IUserProfile>
    ) => {
      state.loading = false;
      state.userProfile = payload;
    },
    saveEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    saveOauthToken: (state, { payload }: PayloadAction<string>) => {
      state.oauthToken = payload;
    },
    sendResetMail: (state, _action: PayloadAction<ISendMail>) => {
      state.loading = true;
    },
    create: (state, action: PayloadAction<ISignup>) => {
      state.loading = true;
      state.email = action.payload.email;
    },
    confirm: (state, action: PayloadAction<IUserConfirm>) => {
      state.loading = true;
      state.cameraCnt = action.payload.cameraCnt;
    },
    uploadImage: (state, action: PayloadAction<IUploadImage>) => {
      state.loading = true;
    },
    loadProfileImage: (
      state,
      { payload }: PayloadAction<ILoadProfileImage>
    ) => {
      state.loading = false;
      state.userProfile = { ...state.userProfile, ...payload } as IUserProfile;
    },
    successChangeName: (state, { payload }: PayloadAction<IUserName>) => {
      state.loading = false;
      state.userProfile = { ...state.userProfile, ...payload } as IUserProfile;
    },
    changeName: (state, action: PayloadAction<IChangeName>) => {
      state.loading = true;
      state.type = action.type;
    },
    changePasswd: (state, action: PayloadAction<IChangePasswd>) => {
      state.loading = true;
      state.type = action.type;
      state.pwChangeError = undefined;
    },
    successChangePasswd: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.loginInfo = {
        ...state.loginInfo,
        user_token: payload,
      } as IUserLoginInfo;
    },

    failChangePasswd: (state) => {
      state.loading = false;
      state.pwChangeError = true;
    },
    loadUserToken: (state) => {
      state.loading = true;
    },
    deleteAccountMail: (state) => {
      state.loading = true;
    },
    clearActionType: (state) => {
      state.type = undefined;
    },
    loadUsageInfo: (state) => {
      state.loading = true;
    },
    successLoadUsageInfo: (
      state,
      { payload }: PayloadAction<IUserUsageInfo>
    ) => {
      state.loading = false;
      state.usageInfo = payload;
    },
    loadUserSettings: (state) => {
      state.loading = true;
    },
    successLoadUserSettings: (
      state,
      { payload }: PayloadAction<IUserSettings>
    ) => {
      state.loading = false;
      state.userSettings = payload;
    },
    updateUserSettings: (state, _action: PayloadAction<IUserSettings>) => {
      state.loading = true;
    },
    setUserNotification: (state, _action: PayloadAction<ISetNotification>) => {
      state.loading = true;
    },
    loadUserNotification: (state) => {
      state.loading = true;
    },
    successLoadUserNotification: (
      state,
      { payload }: PayloadAction<ILoadNotification>
    ) => {
      state.loading = false;
      state.userNotification = payload;
    },
    linkSocialAccount: (
      state,
      _action: PayloadAction<{
        email: string;
        passwd: string;
        oauthToken: string;
        signupType: AuthType;
      }>
    ) => {
      state.loading = true;
    },
    successActivate: (state) => {
      state.loading = false;
      state.activated = true;
    },
    loadUserPermissions: (state) => {},
    successLoadUserPermissions: (
      state,
      { payload }: PayloadAction<IPermissions>
    ) => {
      state.permissions = payload;
    },
    updateLiveviewUsage: (
      state,
      action: PayloadAction<{ psn: string; usage: number }>
    ) => {},
  },
});

export const {
  setError,
  clearError,
  userLogin,
  successLogin,
  create,
  confirm,
  logout,
  clearUser,
  loadUserProfile,
  successLoadUserProfile,
  saveEmail,
  saveOauthToken,
  sendResetMail,
  successRequest,
  successSignup,
  clearNeedToConfirmEmail,
  clearLoading,
  uploadImage,
  loadProfileImage,
  changeName,
  successChangeName,
  changePasswd,
  successChangePasswd,
  loadUserToken,
  deleteAccountMail,
  loadUsageInfo,
  successLoadUsageInfo,
  clearActionType,
  failChangePasswd,
  loadUserSettings,
  successLoadUserSettings,
  updateUserSettings,
  setUserNotification,
  loadUserNotification,
  successLoadUserNotification,
  linkSocialAccount,
  successActivate,
  loadUserPermissions,
  successLoadUserPermissions,
  // userSubscription,
  // loadSubscription,
  needToConfirm,
  updateLiveviewUsage,
} = slice.actions;
export const USER = slice.name;
export default slice.reducer;
