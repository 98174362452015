import axios from "axios";
import {
  API_SERVER_URI,
  API_GATEWAY_URI,
  FIRMWARE_INFO_URI,
  getLbURI,
} from "../contants/Server";
import {
  AddCameraRequest,
  IEmailNotiSettings,
  IFirmwareSettings,
  IFotaIDPayload,
  ILiveEventSettings,
  OnOffType,
} from "../features/Camera/slice";

export const getCamList = (
  email: string,
  userToken: string,
  keyword?: string,
  startIndex?: number,
  endIndex?: number
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetDeviceList?email=${email}&userToken=${userToken}&keyword=${
      keyword ?? ""
    }&startIndex=${startIndex ?? ""}&endIndex=${endIndex ?? ""}&tokenType=web`
  );
};

export const checkCamReg = (email: string, userToken: string) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/chkCamReg.php?email=${email}&user_token=${userToken}&tokenType=web`
  );
};

export const getCamInfo = (email: string, userToken: string, psn: string) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/deviceInfo.php?email=${email}&user_token=${userToken}&psn=${psn}&token_type=web`
  );
};

export const addCamera = (
  email: string,
  userToken: string,
  payload: AddCameraRequest
) => {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("user_token", userToken);
  params.append("tokenType", "web");
  params.append("psn", payload.psn);
  params.append("cldn", payload.cldn);
  params.append("agree_gps", payload.agree_gps);
  return axios.post(`${API_SERVER_URI}/BCS/deviceRegister.php`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const renameCamera = (
  email: string,
  userToken: string,
  psn: string,
  devName: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/deviceChangeName.php?email=${email}&user_token=${userToken}&psn=${psn}&dev_name=${encodeURIComponent(
      devName
    )}&token_type=web`
  );
};

export const deleteCamera = (email: string, userToken: string, psn: string) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/deviceDelete.php?email=${email}&user_token=${userToken}&psn=${psn}&token_type=web`
  );
};

export const updatePrivacy = (
  email: string,
  userToken: string,
  psn: string,
  agree_gps: OnOffType,
  share_gps: OnOffType,
  share_audio: OnOffType,
  share_video: OnOffType,
  share_dev_name: OnOffType
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/openGpsSet.php?email=${email}&user_token=${userToken}&psn=${psn}&share_video=${share_video}&share_audio=${share_audio}&share_dev_name=${share_dev_name}&share_gps=${share_gps}&agree_gps=${agree_gps}&tokenType=web`
  );
};

export const getFotaStatus = (
  email: string,
  userToken: string,
  psn: string | undefined
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/fotaAstatusGet.php?email=${email}&user_token=${userToken}&token_type=web${
      psn ? `&psn=${psn}` : ""
    }`
  );
};

export const getNotification = (
  email: string,
  userToken: string,
  psn: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetNotificationSettings?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const updateNotification = (
  email: string,
  userToken: string,
  psn: string,
  setType: "email" | "phone",
  settings: IEmailNotiSettings,
  send: OnOffType
) => {
  return axios.post(`${API_GATEWAY_URI}/DashCam/SetNotificationSettings`, {
    email,
    user_token: userToken,
    psn,
    tokenType: "web",
    setType,
    // ...(setType === "email"
    //   ? { emailSettings: JSON.stringify(settings) }
    //   : { phoneSettings: JSON.stringify(settings) }),
    ...(setType === "email"
      ? { emailSettings: settings }
      : { phoneSettings: settings }),
    ...(setType === "email"
      ? {
          emailSend: send,
        }
      : { phoneSend: send }),
  });
};

export const getLiveEventSettings = (
  email: string,
  userToken: string,
  psn: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetLiveEventUploadSettings?email=${email}&userToken=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const updateLiveEventSettings = (
  email: string,
  userToken: string,
  psn: string,
  setting: ILiveEventSettings
) => {
  return axios.put(`${API_GATEWAY_URI}/DashCam/SetLiveEventUploadSettings`, {
    email,
    userToken,
    tokenType: "web",
    psn,
    setting,
  });
};

export const getFwInfos = () => {
  return axios.get(`${FIRMWARE_INFO_URI}`);
};

export const getFirmwareSettings = (
  email: string,
  user_token: string,
  psn: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/CloudSetting/GetCloudHTTPFirmwareSetting?email=${email}&user_token=${user_token}&psn=${psn}&tokenType=web`
  );
};

export const updateFirmwareSettings = (
  firmware: IFirmwareSettings,
  user_token: string
) => {
  return axios.put(
    `${API_GATEWAY_URI}/CloudSetting/UpdateCloudHTTPFirmwareSetting`,
    {
      user_token,
      tokenType: "web",
      ...firmware,
    }
  );
};
export const getNotificationEmail = (
  email: string,
  user_token: string,
  psn: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/DashCam/GetNotificationEmail?email=${email}&user_token=${user_token}&psn=${psn}&tokenType=web`
  );
};

export const inviteEmail = (
  email: string,
  user_token: string,
  psn: string,
  guestEmail: string,
  companyID: string
) => {
  return axios.post(`${API_GATEWAY_URI}/DashCam/PostNotificationEmail`, {
    email,
    user_token,
    tokenType: "web",
    psn,
    guestEmail,
    companyID,
  });
};
export const FixMasterEmailNotification = (
  email: string,
  user_token: string,
  psn: string,
  guestEmail: string,
  mailStatus: string
) => {
  return axios.put(`${API_GATEWAY_URI}/DashCam/PutNotificationEmail`, {
    email,
    user_token,
    tokenType: "web",
    psn,
    guestEmail,
    mailStatus,
  });
};

export const deleteEmail = (
  email: string,
  user_token: string,
  psn: string,
  guestEmail: string
) => {
  return axios.delete(`${API_GATEWAY_URI}/DashCam/DeleteNotificationEmail`, {
    data: {
      email,
      user_token,
      tokenType: "web",
      psn,
      guestEmail,
    },
  });
};

export const getFotaID = (
  email: string,
  user_token: string,
  payload: IFotaIDPayload
) => {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("user_token", user_token);
  params.append("token_type", "web");
  params.append("psn", payload.psn);
  params.append("fw_model", payload.fw_model);
  params.append("now_fw_version", payload.now_fw_version);
  params.append("new_fw_version", payload.new_fw_version);
  params.append(
    "fota_url",
    encodeURIComponent(Buffer.from(payload.fota_url).toString("base64"))
  );
  params.append("file_size", payload.file_size.toString());
  params.append("lang", payload.lang);
  params.append("checksum", payload.checksum);

  return axios.post(`${API_SERVER_URI}/BCS/fotaAidGet.php`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const getFotaStart = (
  email: string,
  user_token: string,
  lb_server_name: string,
  lb_http_port: string,
  fotaid: string,
  payload: IFotaIDPayload
) => {
  const url = getLbURI(lb_server_name, lb_http_port);

  const params = new URLSearchParams();
  params.append("email", email);
  params.append("user_token", user_token);
  params.append("tokenType", "web");
  params.append("psn", payload.psn);
  params.append("fw_model", payload.fw_model);
  params.append("fw_version", payload.now_fw_version);
  params.append("fota_url", Buffer.from(payload.fota_url).toString("base64"));
  params.append("file_size", payload.file_size.toString());
  params.append("fota_lang", payload.lang);
  params.append("checksum", payload.checksum);

  return axios.get(
    `${url}/proc/fota_start?${params.toString()}&fotaid=${fotaid}`
  );
};

export const rebootCamera = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  user_token: string,
  psn: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/device_reboot?email=${email}&psn=${psn}&user_token=${user_token}&tokenType=web`
  );
};

export const updateLiveviewUsage = (
  email: string,
  user_token: string,
  psn: string,
  usage: number
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/PostUserInfo`, {
    email,
    user_token,
    psn,
    usage,
    tokenType: "web",
    div: 1,
  });
};
