export const FwAbbr: { [key: string]: string } = {
  "DR900X Plus": "DR900X Plus",
  "DR750X LTE Plus": "DR750X LTE Plus",
  "DR750X-3CH Plus": "DR750X-3CH Plus",
  "DR750X Plus": "DR750X Plus",
  "DR900X-2CH": "900X2",
  "DR900X-1CH": "900X1",
  "DR750X-2CH": "750X2",
  "DR750X-1CH": "750X1",
  "DR590X-2CH": "590X2",
  "DR590X-1CH": "590X1",
  "DR750G Pro": "DR750G Pro",
  "DR750G-1CH Pro": "750GPRO1",
  "DR750G-2CH Pro": "750GPRO2",
  "DR750-2CH LTE": "750LTE2",
  "DR900S-2CH": "900S2",
  "DR900S-1CH": "900S1",
  "DR750S-2CH": "750S2",
  "DR750S-1CH": "750S1",
  "DR750G-2CH": "750G2",
  "DR750G-1CH": "750G1",
  // "":"570W2",
  // "":"570W1",
  // "":"650S2",
  // "":"650S1",
  // "":"650GW",
  // "":"650GW1CH",
  // "":"590W2",
  // "":"590W1",
  // "":"550GWII2",
  // "":"550GWII1",
  // "":"550GW",
  // "":"550GW1CH",
  // "":"750LW",
  // "":"600GW",
  // "":"500GW"
};
