import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../features/store";
import { deleteVODUploadList, uploadVODFile, VOD } from "../features/VOD/slice";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { CAMERA } from "../features/Camera/slice";
import { Webviewer } from "../contants/Breakpoints";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: LightColors.primary["0"],
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    position: "fixed",
    width: "100%",
    right: 0,
    bottom: 0,
    overflowY: "hidden",
    transition: theme.transitions.create(["height", "width"]),
    zIndex: 3,
    maxHeight: 436,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 437,
      right: 32,
    },
  },
  rootClose: {
    height: 40,
    width: 266,
  },
  header: {
    width: "100%",
    height: 40,
    backgroundColor: LightColors.primary["1"],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  iconBtn: {
    padding: 0,
    marginRight: theme.spacing(2),
    color: LightColors.primary["0"],
  },

  cancelDiv: {
    width: "100%",
    height: 36,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  cancelBtn: {
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    cursor: "pointer",
  },
  listDiv: {
    height: "calc(100% - 36px - 40px)",
    overflowY: "auto",
  },
  itemDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 36,
    padding: theme.spacing(0, 2),
  },
  circleRoot: {
    position: "relative",
  },
  circleBottom: {
    color: LightColors.primary["6"],
  },
  circleTop: {
    position: "absolute",
    left: 0,
  },
  link: {
    cursor: "pointer",
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["8"],
    },
  },
  marginLeft3: {
    marginLeft: 3,
  },
}));

export const FileUploadModal = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadList = useSelector((state: RootState) => state[VOD].uploadList);

  const [open, setOpen] = useState(true);
  const [forceClose, setForceClose] = useState(false);

  const { loading, type } = useSelector((state: RootState) => state[VOD]);

  const cameraList = useSelector(
    (state: RootState) => state[CAMERA].cameraList
  );
  const camera = useSelector((state: RootState) => state[CAMERA].camera);

  //close modal
  useEffect(() => {
    if (!loading && type === deleteVODUploadList.type) {
      setForceClose(true);
    }
  }, [loading, type]);

  // remove uploadList
  const handleDelete = () => {
    if (camera) {
      const cam = _.chain(cameraList?.DeviceListInfo)
        .map((c) => c.device)
        .find((c) => c.psn === camera.psn)
        .value();
      if (cam && uploadList) {
        dispatch(
          deleteVODUploadList({
            camera: cam,
            removelist: _.map(uploadList, (list) => ({
              filename: list.filename,
              type: list.type,
            })),
          })
        );
      }
    }
  };

  const renderItem = () => {
    return _.map(uploadList, (f) => {
      const value = (f.current / f.total) * 100;
      let icon = (
        <>
          <CircularProgress
            variant="determinate"
            className={classes.circleBottom}
            value={100}
            size={20}
            thickness={6}
          />
          <CircularProgress
            variant="determinate"
            className={classes.circleTop}
            value={value}
            size={20}
            thickness={6}
          />
        </>
      );

      if (f.error) {
        icon = (
          <Typography
            category="Default"
            variant="Body"
            className={classes.link}
            onClick={() => {
              if (camera) {
                const cam = _.chain(cameraList?.DeviceListInfo)
                  .map((c) => c.device)
                  .find((c) => c.psn === camera.psn)
                  .value();
                if (cam) {
                  dispatch(
                    uploadVODFile({
                      camera: cam,
                      filename: f.filename,
                    })
                  );
                }
              }
            }}
          >
            {t("Retry")}
          </Typography>
        );
      } else if (value === 100) {
        icon = <CloudDoneIcon htmlColor={LightColors.secondary["15"]} />;
      }
      return (
        <div className={classes.itemDiv} key={f.filename}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={
              f.error ? LightColors.secondary["11"] : LightColors.primary["1"]
            }
          >
            {f.filename}
          </Typography>
          <div className={classes.circleRoot}>{icon}</div>
        </div>
      );
    });
  };

  return uploadList.length > 0 && !forceClose ? (
    <div
      className={clsx(classes.root, {
        [classes.rootClose]: !open,
      })}
      style={open ? { height: 76 + uploadList.length * 36 } : {}}
    >
      <div className={classes.header}>
        <Typography
          category="Default"
          variant="Body"
          htmlColor={LightColors.primary["0"]}
        >
          {_.filter(uploadList, (up) => up.current === up.total).length ===
          uploadList.length
            ? t("Finished uploading videos:")
            : t("Uploading videos:")}{" "}
          {_.filter(uploadList, (up) => up.current > 0).length}/
          {uploadList.length}
        </Typography>
        <div>
          <IconButton
            className={classes.iconBtn}
            onClick={() => {
              setOpen((o) => !o);
            }}
          >
            {open ? (
              <ExpandMoreIcon />
            ) : (
              <ExpandLessIcon className={classes.marginLeft3} />
            )}
          </IconButton>
          <IconButton
            className={classes.iconBtn}
            onClick={() => setForceClose(true)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.cancelDiv} onClick={handleDelete}>
        <Typography
          category="Default"
          variant="Body"
          className={classes.cancelBtn}
        >
          {t("Cancel")}
        </Typography>
      </div>
      <div className={classes.listDiv}>{renderItem()}</div>
    </div>
  ) : (
    <></>
  );
};
