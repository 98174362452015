import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Webviewer } from "../contants/Breakpoints";
import { CAMERA, ICameraInfo } from "../features/Camera/slice";
import {
  PUSH_EVENT,
  clearEvent,
  WebPushEvent,
} from "../features/PushEvent/slice";
import { RootState } from "../features/store";
import { VideoPlayerModal } from "./cameras/VideoPlayerModal";
import { LiveViewModal } from "./modals/LiveViewModal";
import { Notification } from "@thingsw/pitta-design-system";

const useStyles = makeStyles((theme: Theme) => ({
  notificationModal: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(4),
    transition: theme.transitions.create("opacity"),
  },
  modalHide: {
    opacity: 0,
    display: "none",
  },
}));

const WebPushNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { webPushEvent } = useSelector((state: RootState) => state[PUSH_EVENT]);
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [disablePlayback, setDisablePlayback] = useState(true);
  const [disableLiveview, setDisableLiveview] = useState(true);
  const [eventCam, setEventCam] = useState<ICameraInfo>();
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();

  useEffect(() => {
    if (webPushEvent) {
      setOpenNotification(true);
    }
  }, [webPushEvent]);

  useEffect(() => {
    if (webPushEvent) {
      const found = _.find(
        cameraList?.DeviceListInfo,
        (dev) => dev.device.psn === webPushEvent.psn
      )?.device;
      setEventCam((c) => (c?.psn === found?.psn ? c : found));
    }
  }, [cameraList?.DeviceListInfo, webPushEvent]);

  useEffect(() => {
    if (webPushEvent) {
      let remaining = 90;
      if (eventCam) {
        const timeStrings = webPushEvent.event_filename?.split("_");
        let playable = true;
        if (timeStrings) {
          const time = moment
            .utc(`${timeStrings[0]} ${timeStrings[1]}`, "YYYYMMDD HH:mm:ss")
            .subtract(eventCam.interval, "minute")
            .utcOffset(eventCam.interval);
          if (time.isValid()) {
            const now = moment.tz();
            remaining -= now.diff(time, "s");
          } else {
            playable = false;
          }
        }

        if (webPushEvent.msg_code === "DEVICE_DISCONNECT") {
          setDisableLiveview(true);
        } else {
          setDisableLiveview(false);
        }

        if (eventCam.active === "off" || !playable) {
          setDisablePlayback(true);
        } else if (remaining > 0) {
          setDisablePlayback(true);
        } else {
          setDisablePlayback(false);
        }
      } else {
        setDisablePlayback(true);
      }

      const timerId = setTimeout(() => dispatch(clearEvent()), 8 * 1000);
      const timerId2 = setTimeout(() => setOpenNotification(false), 180 * 1000);
      let timerId3: NodeJS.Timeout;
      if (remaining > 0) {
        timerId3 = setTimeout(
          () => setDisablePlayback(false),
          remaining * 1000
        );
      }
      return () => {
        clearTimeout(timerId);
        clearTimeout(timerId2);
        if (timerId3) {
          clearTimeout(timerId3);
        }
      };
    }
  }, [dispatch, eventCam, webPushEvent]);

  const convertWebPushEventToLatestEvent = useCallback(
    (webpush: WebPushEvent) => {
      const filenames = webpush.event_filename?.split("_");
      const vdate = filenames
        ? moment(`${filenames[0]} ${filenames[1]}`, "YYYYMMDD HHmmss")
        : undefined;
      return {
        psn: webpush.psn,
        msg_code: webpush.msg_code,
        msg: webpush.alert,
        img_url: webpush.image_url,
        event_file: webpush.event_filename,
        vdate,
      };
    },
    []
  );

  const videoPlayerModal = useMemo(() => {
    if (currentCam && webPushEvent && openVideoModal) {
      return (
        <VideoPlayerModal
          open={openVideoModal}
          camera={currentCam}
          mode={0}
          event={convertWebPushEventToLatestEvent(webPushEvent)}
          onClose={() => {
            setOpenVideoModal(false);
            setCurrentCam(undefined);
          }}
        />
      );
    }
    return undefined;
  }, [
    convertWebPushEventToLatestEvent,
    currentCam,
    openVideoModal,
    webPushEvent,
  ]);

  return (
    <>
      {!mobile && (
        <div
          className={clsx(classes.notificationModal, {
            [classes.modalHide]: !openNotification,
          })}
        >
          {webPushEvent && (
            <Notification
              event={webPushEvent}
              disablePlayback={disablePlayback}
              disableLiveview={disableLiveview}
              onClose={() => {
                setOpenNotification(false);
              }}
              onLiveView={(evt) => {
                const cam = _.find(
                  cameraList?.DeviceListInfo,
                  (dev) => dev.device.psn === evt.psn
                )?.device;
                if (cam) {
                  setCurrentCam(cam);
                  setOpenLiveModal(true);
                }
              }}
              onPlayback={(evt) => {
                const cam = _.find(
                  cameraList?.DeviceListInfo,
                  (dev) => dev.device.psn === evt.psn
                )?.device;
                console.log("onPlayback", evt, cam);
                if (cam) {
                  setCurrentCam(cam);
                  setOpenVideoModal(true);
                }
              }}
            />
          )}
        </div>
      )}

      {videoPlayerModal}
      <LiveViewModal
        open={openLiveModal}
        camera={currentCam}
        onClose={() => {
          setOpenLiveModal(false);
          setCurrentCam(undefined);
        }}
      />
    </>
  );
};

export default WebPushNotification;
