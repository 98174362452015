import { PopperPlacementType } from "@material-ui/core/Popper";
import {
  LightColors,
  Menu,
  Modal,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReferenceObject } from "../types";

import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { logout, USER } from "../features/User/slice";
import { PAYMENT } from "../features/Payment/slice";
import { getPlanFromServiceID } from "../utils/Service";
import { useHistory } from "react-router-dom";
import { UserNames } from "../features/Member/slice";
import { Pricing } from "./Pricing";

interface ProfileMenuProps {
  open: boolean;
  onCloseMenu: () => void;

  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
  onClickAway: React.MouseEventHandler<Document>;

  /**
   * Popper placement.
   */
  placement?: PopperPlacementType;
}

const useStyles = makeStyles((theme: Theme) => ({
  appIcon: {
    fontSize: 15,
    color: LightColors.secondary["17"],
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  userInfoDiv: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0.75, 2),
  },
  webModalContent: {
    justifyContent: "center",
  },
}));

export const ProfileMenu = (props: ProfileMenuProps) => {
  const { onCloseMenu, ...restProps } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );
  const subscriptionInfo = useSelector(
    (state: RootState) => state[PAYMENT].subscriptionInfo
  );

  const [planName, setPlanName] = useState<string>();
  const [openPricing, setOpenPricing] = useState(false);

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  return (
    <>
      <Menu
        {...restProps}
        modifiers={{
          preventOverflow: { enabled: false },
          flip: { enabled: false },
        }}
        style={{ zIndex: 101 }}
      >
        <div className={classes.userInfoDiv}>
          <Typography category="Default" variant="Body">
            {userProfile?.firstName} {userProfile?.lastName}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {userProfile?.email}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t(planName ?? "")}
            {planName === "Fleet plan" &&
              userProfile?.userType &&
              ` · ${t(UserNames[userProfile.userType])}`}
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <WebMenuItem
          onClick={() => {
            history.push("/account");
            onCloseMenu();
          }}
        >
          {t("Account")}
        </WebMenuItem>
        <WebMenuItem
          onClick={() => {
            history.push("/account/settings");
            onCloseMenu();
          }}
        >
          {t("Settings")}
        </WebMenuItem>

        <WebMenuItem
          endIcon={<CardGiftcardIcon className={classes.appIcon} />}
          onClick={() => {
            setOpenPricing(true);
            onCloseMenu();
          }}
          disabled={planName === "Fleet plan"}
        >
          {t("Upgrade")}
        </WebMenuItem>

        <WebMenuItem onClick={() => dispatch(logout())}>
          {t("Log out")}
        </WebMenuItem>
      </Menu>
      <Modal
        open={openPricing}
        content={<Pricing modal onClose={() => setOpenPricing(false)} />}
        close
        onClose={() => setOpenPricing(false)}
        contentClassName={classes.webModalContent}
        fullSize
      />
    </>
  );
};
