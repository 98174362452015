import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Google,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { config } from "../contants/Firebase";

import AppleIcon from "@material-ui/icons/Apple";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import SignupForm from "../forms/SignupForm";
import { MinimalFooter } from "../components/MinimalFooter";
import { LoginHeader } from "../components/LoginHeader";
import { IConfirmForm, ISignupForm } from "../types";
import crypto from "crypto";

import {
  create,
  confirm,
  USER,
  clearNeedToConfirmEmail,
  SignupMode,
  clearUser,
} from "../features/User/slice";
import EmailConfirmForm from "../forms/EmailConfirmForm";
import { isBrowserCheck } from "../utils/isBrowserCheck";
import { isMobileOSCheck } from "../utils/isMobileOSCheck";
import { RootState } from "../features/store";
import _ from "lodash";
import { FirebaseAuth } from "../components/FirebaseAuth";
import { Webviewer } from "../contants/Breakpoints";
import { useHistory, useLocation } from "react-router-dom";
import { clearError, ERROR } from "../features/Error/slice";
import clsx from "clsx";

import * as yup from "yup";
import { SubmissionError } from "redux-form";
import withSimpleBar from "../hoc/withSimpleBar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 57,
    backgroundColor: LightColors.primary["0"],
    minHeight: "calc(var(--vh, 1vh) * 100 - 58px)",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "calc(var(--vh, 1vh) * 100 - 58px)",
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.25, 2, 0, 2),
    },
  },

  errorBody: {
    paddingTop: 69,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: theme.spacing(14.25),
    },
  },
  title: {
    display: "flex",
    width: "42%",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: "100%",
    },
  },
  formWrap: {
    display: "flex",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  button: {
    padding: "7px 38px 7px 16px",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 437,
    flexGrow: 1,
  },
  dividerDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
    },
  },
  divider: {
    backgroundColor: LightColors.primary["6"],
    width: "100%",
    top: "50%",
    transform: "translate(0, -50%);",
  },
  orDiv: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    backgroundColor: LightColors.primary["0"],
    color: LightColors.primary["2"],
    zIndex: 2,
  },
  marginB1: {
    marginBottom: theme.spacing(1),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
  marginR2: {
    marginRight: theme.spacing(2),
  },
  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    padding: theme.spacing(2),
    backgroundColor: `${LightColors.primary["6"]}73`,
    borderRadius: 4,
    maxWidth: 437,
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
      marginLeft: theme.spacing(4),
      maxWidth: 320,
    },
  },
  errorDiv: {
    width: "100%",
    marginBottom: theme.spacing(4),
    position: "fixed",
    zIndex: 3,
  },
  googleBtn: {
    color: LightColors.primary["1"],
    "&:hover": {
      color: LightColors.primary["7"],
    },
    "&:active": {
      color: LightColors.primary["7"],
    },
  },
  appleBtn: {
    marginBottom: theme.spacing(2),
    padding: "7px 38px 7px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
    },
  },
  headerTextDiv: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  activeContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.25, 2, 0, 2),
    },
  },
  activateDiv: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 438,
  },
}));

export const SignupScreen = withSimpleBar(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  interface IState {
    mode?: SignupMode;
    cameraCnt?: number;
  }
  const location = useLocation();

  const locationMode = (location.state as IState)?.mode;
  const locationCameraCnt = (location.state as IState)?.cameraCnt;

  const { needToConfirmEmail, activated } = useSelector(
    (state: RootState) => state[USER]
  );

  // const mode = "success";
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { email, mobile_uuid } = useSelector((state: RootState) => state[USER]);
  const { error } = useSelector((state: RootState) => state[ERROR]);

  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().trim().required("Enter first name"),
        lastName: yup.string().trim().required("Enter last name"),
        email: yup
          .string()
          .trim()
          .email("Please enter_email")
          .required("Enter email"),
        tos: yup
          .boolean()
          .oneOf([true], "Please agree to_")
          .required("Please agree to_"),
      }),
    []
  );

  useEffect(() => {
    dispatch(clearNeedToConfirmEmail());
    dispatch(clearError());
    return () => {
      dispatch(clearError());
      dispatch(clearNeedToConfirmEmail());
    };
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (data: ISignupForm) => {
      try {
        await schema.validate(data, { abortEarly: false });
        dispatch(
          create({
            email: data.email,
            passwd: crypto
              .createHash("sha256")
              .update(data.passwd)
              .digest("hex"),
            firstName: data.firstName,
            lastName: data.lastName,
            tokenType: "web",
            newsRecv: data.subscribe ? 1 : 0,
            inviteToken: query.get("inviteToken") ?? undefined,
          })
        );
      } catch (err) {
        console.log("validate", err.errors, err.path, err.inner);
        throw new SubmissionError(
          _.reduce(
            err.inner,
            (result, error) => {
              return { ...result, [error.path]: error.errors };
            },
            {}
          )
        );
      }
    },
    [dispatch, query, schema]
  );
  const handleSubmitConfirm = useCallback(
    (data: IConfirmForm) => {
      const mobileName = isBrowserCheck();
      const mobileOSType = isMobileOSCheck();

      if (email) {
        dispatch(
          confirm({
            email,
            confirmNumber: _.join(data.inputs, ""),
            mobileUUID: mobile_uuid,
            mobileName,
            mobileOSType,
            mobileVersion: 1.0,
            mobileLang: "eng",
            mobileTimeInterval: "540",
            tokenType: "web",
            signupMode: locationMode,
            cameraCnt: locationCameraCnt,
          })
        );
      }
    },
    [dispatch, email, locationCameraCnt, locationMode, mobile_uuid]
  );

  const planExplainMarkup = useMemo(() => {
    if (locationMode === "free") {
      return (
        <>
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["1"]}
            className={classes.marginB1}
          >
            {t("Free Plan")}{" "}
          </Typography>

          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("Get started for_")}
          </Typography>
        </>
      );
    }

    if (query.get("inviteToken")) {
      return (
        <>
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["1"]}
            className={classes.marginB1}
          >
            {t("Fleet Plan")} · {t("Admin")}
          </Typography>

          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("It is the account that_Admin")}
          </Typography>
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["1"]}
            className={classes.marginB1}
            style={{ marginTop: 16 }}
          >
            {t("Fleet Plan")} · {t("Driver")}
          </Typography>

          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("It is the account that_Driver")}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          category="Default"
          variant="H6"
          htmlColor={LightColors.primary["1"]}
          className={classes.marginB1}
        >
          {t("Fleet Plan")} · {t("1 month free trial_")}
        </Typography>

        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {t("BlackVue Fleet Tracking_")}.{" "}
          <Typography category="Default" variant="SmallBold">
            {t("Web subscription only")}
          </Typography>
          .
        </Typography>
      </>
    );
  }, [classes.marginB1, locationMode, query, t]);

  const contentMarkup = useMemo(() => {
    if (needToConfirmEmail) {
      return (
        <EmailConfirmForm
          onSubmit={handleSubmitConfirm}
          initialValues={{ inputs: ["", "", "", "", "", ""] }}
        />
      );
    }
    if (activated) {
      return (
        <div className={classes.activeContainer}>
          <div className={classes.activateDiv}>
            <Typography
              category="Default"
              variant="H1"
              style={{ marginBottom: 32 }}
            >
              {t("Your account is_")}
            </Typography>
            <Typography
              category="Default"
              variant="Body"
              style={{ marginBottom: 24 }}
            >
              {t("You can now_")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.replace("/login");
                dispatch(clearUser());
              }}
            >
              {t("Back to login")}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Container className={clsx(classes.body, error && classes.errorBody)}>
        <div>
          <div className={classes.title}>
            <Typography
              category="Default"
              variant={mobile ? "H2" : "H1"}
              className={mobile ? "" : classes.marginB4}
            >
              {t("Sign up")}
            </Typography>
          </div>
          <div className={classes.formWrap}>
            <div className={classes.formDiv}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Google fontSize="small" />}
                className={`${classes.marginB2} ${classes.button}`}
                fullWidth
                onClick={() => {
                  const googleAuthProvider =
                    new firebase.auth.GoogleAuthProvider();
                  firebase.auth().signInWithPopup(googleAuthProvider);
                }}
              >
                {t("Continue with Google")}
              </Button>

              <Button
                variant="outlined"
                color="primary"
                startIcon={<AppleIcon fontSize="small" />}
                className={classes.appleBtn}
                fullWidth
                onClick={() => {
                  const appleAuthProvider = new firebase.auth.OAuthProvider(
                    "apple.com"
                  );
                  firebase.auth().signInWithPopup(appleAuthProvider);
                }}
              >
                {t("Continue with Apple")}
              </Button>
              <div className={classes.dividerDiv}>
                <div className={classes.orDiv}>
                  <Typography category="Default" variant="Small">
                    {t("OR")}
                  </Typography>
                </div>
                <Divider absolute className={classes.divider} />
              </div>
              <div>
                <SignupForm
                  onSubmit={handleSubmit}
                  initialValues={{
                    tos: true,
                    email: query.get("email") ?? undefined,
                  }}
                />
              </div>
            </div>
            <div className={classes.fleetPlan}>{planExplainMarkup}</div>
          </div>
        </div>
        <MinimalFooter />
      </Container>
    );
  }, [
    activated,
    classes.activateDiv,
    classes.activeContainer,
    classes.appleBtn,
    classes.body,
    classes.button,
    classes.divider,
    classes.dividerDiv,
    classes.errorBody,
    classes.fleetPlan,
    classes.formDiv,
    classes.formWrap,
    classes.marginB2,
    classes.marginB4,
    classes.orDiv,
    classes.title,
    dispatch,
    error,
    handleSubmit,
    handleSubmitConfirm,
    history,
    mobile,
    needToConfirmEmail,
    planExplainMarkup,
    query,
    t,
  ]);

  return (
    <React.Fragment>
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <LoginHeader />
        <div className={classes.root}>
          {mobile && locationMode === "fleet" && (
            <div className={classes.headerTextDiv}>
              <Typography
                category="Default"
                variant="CaptionBold"
                htmlColor={LightColors.primary["1"]}
                className={classes.marginR2}
              >
                1. {t("Sign up")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["1"]}
              >
                2. {t("Billing information")}
              </Typography>
            </div>
          )}
          {contentMarkup}
        </div>
        <FirebaseAuth />
      </FirebaseAuthProvider>
    </React.Fragment>
  );
});
