export const config = {
  apiKey: "AIzaSyA9tY6Kd0eWg953zK1qNo-GRVXhEWoS8zs",
  authDomain: "comb-totalone.firebaseapp.com",
  databaseURL: "https://comb-totalone.firebaseio.com",
  projectId: "comb-totalone",
  storageBucket: "comb-totalone.appspot.com",
  messagingSenderId: "948301488227",
  appId: "1:948301488227:web:867eb9e52da9f963c61856",
  measurementId: "G-GSDGVQM0X8",
};
