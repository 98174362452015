import { Theme, withStyles } from "@material-ui/core/styles";
import { LightColors } from "@thingsw/pitta-design-system";
import Slider from "@material-ui/core/Slider";

export const ProgressSlider = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    height: 4,
  },
  rail: {
    color: "rgba(255, 255,255, 0.35)",
    height: 4,
  },
  track: {
    color: LightColors.primary["8"],
    height: 4,
  },
  thumb: {
    color: LightColors.primary["8"],
    marginTop: -4,
  },
}))(Slider);
