////////////////////////////////////////////////////////////////////////////////////////
// 리액트 기능 임포트
import React, { useEffect, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { addGroup, GROUP } from "../features/Group/slice";
import { IGroupNameForm } from "../types";
import { Webviewer } from "../contants/Breakpoints";
import AddGroupForm from "../forms/AddGroupForm";
import { RootState } from "../features/store";
import _ from "lodash";

////////////////////////////////////////////////////////////////////////////////////////
// 인터페이스

interface GroupsAddScreenProps {
  openMenu?: boolean;
  error?: string;
}

////////////////////////////////////////////////////////////////////////////////////////
// CSS 스타일
const useStyles = makeStyles((theme: Theme) => ({
  // 1. 루트
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    height: "calc(var(--vh, 1vh) * 100 - 56px - 165px)",
    marginTop: 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: GroupsAddScreenProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 49px)"
          : "calc(var(--vh, 1vh) * 100 - 56px - 49px)",
      margin: theme.spacing(5, 4, 2, 4),
      marginTop: (props: GroupsAddScreenProps) => (props.error ? 85 : 56),
    },
  },
}));

////////////////////////////////////////////////////////////////////////////////////////
// 실제 컴포넌트
export const GroupsAddScreen = (props: GroupsAddScreenProps) => {
  // 변수 영역
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;

  const groupsList = useSelector((state: RootState) => state[GROUP].groupsList);

  const [initialValues, setInitialValues] = useState<IGroupNameForm>({
    groupNameList: "Untitled group",
  });

  useEffect(() => {
    if (groupsList) {
      const untitled = _.filter(
        groupsList.DashCamList,
        (group) => group.GroupName.indexOf("Untitled group") > -1
      );
      if (untitled.length > 0) {
        setInitialValues({
          groupNameList: `Untitled group(${untitled.length})`,
        });
      }
    }
  }, [groupsList]);

  const handleSubmit = (group: IGroupNameForm) => {
    dispatch(
      addGroup({
        groupNameList: group.groupNameList.trim(),
      })
    );
  };

  console.log(initialValues);

  // 리턴 영역
  return (
    // 루트 css
    <div className={classes.root} dir={theme.direction}>
      <AddGroupForm onSubmit={handleSubmit} initialValues={initialValues} />
    </div>
  );
};
