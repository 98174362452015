import React, { useEffect } from "react";
import { RouteProps, Route, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeDirection, setFontFamily } from "../features/Theme/slice";
import { useQuery } from "../hooks/useQuery";

const DefaultRoute = (props: RouteProps) => {
  const dispatch = useDispatch();
  const { i18n: i18next } = useTranslation();
  const { path } = useRouteMatch();
  const query = useQuery();

  useEffect(() => {
    let queryLang = query.get("lang");
    if (queryLang) return;
    const prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
    let lang = prefLang || navigator.language.toLowerCase().substring(0, 2);
    if (process.env.REACT_APP_ENV !== "qa") {
      lang = "en";
    }
    document.documentElement.lang = lang;
    if (path === "/") {
      dispatch(changeDirection("ltr"));
      switch (lang) {
        case "ko":
          i18next.changeLanguage("ko");
          dispatch(setFontFamily("Noto Sans KR"));
          break;
        case "ja":
          i18next.changeLanguage("ja");
          dispatch(setFontFamily("Noto Sans JP"));
          break;
        case "zh":
        case "sc":
          i18next.changeLanguage("sc");
          dispatch(setFontFamily("Noto Sans SC"));
          break;
        default:
          i18next.changeLanguage("en");
          dispatch(setFontFamily("Roboto"));
      }
    }
  }, [dispatch, i18next, path, query]);

  return <Route {...props} />;
};

export default DefaultRoute;
