import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

import cameraReducer, { CAMERA } from "./Camera/slice";
import memberReducer, { MEMBER } from "./Member/slice";
import eventReducer, { EVENT } from "./Event/slice";
import gpsReducer, { GPS } from "./GPS/slice";
import paymentReducer, { PAYMENT } from "./Payment/slice";
import toastReducer, { TOAST } from "./Toast/slice";
import userReducer, { USER } from "./User/slice";
import vodReducer, { VOD } from "./VOD/slice";
import groupReducer, { GROUP } from "./Group/slice";
import geofenceReducer, { GEOFENCE } from "./Geofence/slice";
import themeReducer, { THEME } from "./Theme/slice";
import socialReducer, { SOCIAL } from "./Social/slice";
import errorReducer, { ERROR } from "./Error/slice";
import reportReducer, { REPORT } from "./Report/slice";
import pushEventReducer, { PUSH_EVENT } from "./PushEvent/slice";
import permissionReducer, { PERMISSION } from "./Permission/slice";

const createRootReducer = (history: any) =>
  combineReducers({
    [USER]: userReducer,
    [CAMERA]: cameraReducer,
    [PAYMENT]: paymentReducer,
    [MEMBER]: memberReducer,
    [GPS]: gpsReducer,
    [EVENT]: eventReducer,
    [VOD]: vodReducer,
    [TOAST]: toastReducer,
    [GROUP]: groupReducer,
    [GEOFENCE]: geofenceReducer,
    [THEME]: themeReducer,
    [SOCIAL]: socialReducer,
    [ERROR]: errorReducer,
    [REPORT]: reportReducer,
    [PUSH_EVENT]: pushEventReducer,
    [PERMISSION]: permissionReducer,
    router: connectRouter(history),
    form: formReducer,
  });

export default createRootReducer;
