import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Fab,
  LightColors,
  Menu,
  MobileMenu,
  Tooltip,
  WebMenuItem,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";

import CheckIcon from "@material-ui/icons/Check";

import { TopRightControl } from "./TopRightControl";
import {
  exitFullscreen,
  getElementByXpath,
  isFullscreen,
  renderGeofences,
  requestFullscreen,
} from "../../utils/GoogleMap";
import { DrawingMode, IGeometry } from "../../types";

import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Webviewer } from "../../contants/Breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  GOOGLE_API_KEY,
  MOBILE_BOUNDS_PADDING,
  PC_BOUNDS_PADDING,
} from "../../contants/GoogleMap";
import { INITIAL_MAP_LOCATION } from "./GoogleMap";

// const PC_TRACK_BOUNDS_PADDING = { bottom: 100, left: 100, right: 0, top: 100 };

interface GeofenceMapProps {
  onGoogleApiLoaded?: (maps: {
    map: any;
    maps: any;
    ref: Element | null;
  }) => void;
  fullscreenIcon?: boolean;
  children?: React.ReactNode;
  onUpdateGeometry?: (object: IGeometry) => void;
  onStartDrawing?: () => void;
  drawingMode?: DrawingMode;
  drawingColor?: string;
  drawingOpacity?: number;
  geometry?: IGeometry | null;
  geometries?: IGeometry[];
  onSelectGeofence?: (fenceId: string) => void;
  mode?: "view" | "add";
}

const useStyles = makeStyles((theme: Theme) => ({
  topControlPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
  },
  zoomControlDiv: {
    position: "absolute",
    bottom: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
  },
  zoomControlBtn: {
    borderRadius: 8,
    width: 30,
    height: 30,
    color: LightColors.primary["3"],
    minHeight: 30,
  },
  gpsFixBtn: {
    marginBottom: theme.spacing(0.5),
  },
  zoomInBtn: {
    borderBottom: 0,
    borderRadius: theme.spacing(1, 1, 0, 0),
    boxShadow: "none",
  },
  zoomOutBtn: {
    borderTop: 0,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: "none",
  },
  tnpDiv: {
    ...(theme.direction === "rtl" ? { paddingRight: 44 } : { paddingLeft: 44 }),
  },
  appIcon: {
    fontSize: 15,
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  directionMakerIcon: {
    width: 20,
    height: 20,
    zIndex: 1,
  },
  markerIcon: {
    cursor: "pointer",
    transform: "translate(-17px, -32px)",
    width: 34,
    height: 38,
    zIndex: 2,
  },
  selectedMarkerIcon: {
    cursor: "pointer",
    transform: "translate(-19px, -38px)",
    width: 38,
    height: 42,
  },
  startMarkerIcon: {
    zIndex: 2,
    transform: "translate(-12px, -12px)",
    filter:
      "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.14)), drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12)), drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2))",
  },
  infoWindowDiv: {
    width: 300,
    backgroundColor: LightColors.primary["0"],
    borderRadius: 4,
    border: `1px sloid ${LightColors.primary["6"]}`,
  },
  parkingDiv: {
    maxHeight: 421,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  infoWindowCenter: {
    transform: "translate(-150px, calc(-100% - 40px))",
  },
  inforParkingTitle: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  addInfoDiv: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(1, 2),
    backgroundColor: `${LightColors.primary[1]}C0`,
    borderRadius: 4,
  },
  drawingModeDiv: {
    "& div": { cursor: "crosshair" },
  },
}));

export const GeofenceMap = (props: GeofenceMapProps) => {
  const {
    onGoogleApiLoaded,
    fullscreenIcon,
    children,
    drawingMode,
    drawingColor,
    drawingOpacity,
    geometry,
    geometries,
    onUpdateGeometry,
    onStartDrawing,
    onSelectGeofence,
    mode,
  } = props;
  // useTraceUpdate(props);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const anchorRef = useRef<HTMLButtonElement>(null);
  const mapDivRef = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [openLayer, setOpenLayer] = useState(false);
  const [mapMode, setMapMode] = useState<"map" | "satellite">("map");
  // const [gMarkers, setGMarkers] = useState<any[]>([]);
  const [drawingManager, setDrawingManager] = useState<any>();
  const [drawingObject, setDrawingObject] = useState<any>();

  const [fullscreen, setfullscreen] = useState(false);
  const [drawingStarted, setDrawingStarted] = useState(false);

  // console.log("GeofenceMap", drawingObject, geometry);

  useEffect(() => {
    function exitHandler() {
      if (
        !document.fullscreenElement &&
        //@ts-ignore
        !document.webkitIsFullScreen &&
        //@ts-ignore
        !document.mozFullScreen &&
        //@ts-ignore
        !document.msFullscreenElement
      ) {
        ///fire your event
        setfullscreen(false);
      }
    }
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  useEffect(() => {
    if (drawingManager) {
      drawingManager.setOptions({
        drawingControl: false,
        polygonOptions: {
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        },
        polylineOptions: {
          geodesic: true,
          strokeColor: drawingColor,
          strokeWeight: 3,
          strokeOpacity: 1.0,
          editable: true,
          suppressUndo: true,
        },
        circleOptions: {
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        },
        rectangleOptions: {
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        },
      });
    }
  }, [drawingColor, drawingOpacity, drawingManager]);

  useEffect(() => {
    if (drawingObject) {
      if (
        drawingMode === "polygon" ||
        drawingMode === "circle" ||
        drawingMode === "rectangle"
      ) {
        drawingObject.setOptions({
          // paths: coords,
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        });
      } else if (drawingMode === "polyline") {
        drawingObject.setOptions({
          geodesic: true,
          strokeColor: drawingColor,
          strokeWeight: 3,
          strokeOpacity: 1.0,
          editable: true,
          suppressUndo: true,
        });
      }
    }
  }, [drawingMode, drawingObject, drawingColor, drawingOpacity]);

  const updateEventPoly = useCallback(
    (polygon: any) => {
      if (maps) {
        return [
          maps.event.addListener(polygon.getPath(), "insert_at", (e: any) => {
            drawingMode &&
              onUpdateGeometry?.({
                type: drawingMode,
                coords: _.map(polygon.getPath().getArray(), (p) => ({
                  lat: p.lat(),
                  lng: p.lng(),
                })),
              });
          }),
          maps.event.addListener(polygon.getPath(), "set_at", (e: any) => {
            drawingMode &&
              onUpdateGeometry?.({
                type: drawingMode,
                coords: _.map(polygon.getPath().getArray(), (p) => ({
                  lat: p.lat(),
                  lng: p.lng(),
                })),
              });
          }),
          maps.event.addListener(polygon.getPath(), "remove_at", (e: any) => {
            drawingMode &&
              onUpdateGeometry?.({
                type: drawingMode,
                coords: _.map(polygon.getPath().getArray(), (p) => ({
                  lat: p.lat(),
                  lng: p.lng(),
                })),
              });
          }),
        ];
      }
    },
    [maps, onUpdateGeometry, drawingMode]
  );

  const updateEventCircle = useCallback(
    (circle: any) => {
      if (maps) {
        return [
          maps.event.addListener(circle, "center_changed", () => {
            drawingMode &&
              onUpdateGeometry?.({
                type: drawingMode,
                center: {
                  lat: circle.getCenter().lat(),
                  lng: circle.getCenter().lng(),
                },
                radius: circle.getRadius(),
              });
          }),
          maps.event.addListener(circle, "radius_changed", () => {
            drawingMode &&
              onUpdateGeometry?.({
                type: drawingMode,
                center: {
                  lat: circle.getCenter().lat(),
                  lng: circle.getCenter().lng(),
                },
                radius: circle.getRadius(),
              });
          }),
        ];
      }
    },
    [maps, onUpdateGeometry, drawingMode]
  );

  const updateEventRect = useCallback(
    (rect: any) => {
      if (maps) {
        return [
          maps.event.addListener(rect, "bounds_changed", () => {
            const ne = rect.getBounds().getNorthEast();
            const sw = rect.getBounds().getSouthWest();
            drawingMode &&
              onUpdateGeometry?.({
                type: drawingMode,
                bounds: {
                  north: ne.lat(),
                  east: ne.lng(),
                  south: sw.lat(),
                  west: sw.lng(),
                },
              });
          }),
        ];
      }
    },
    [maps, onUpdateGeometry, drawingMode]
  );

  useEffect(() => {
    if (drawingObject) {
      if (geometry === null) {
        drawingObject.setMap(null);
        setDrawingObject(undefined);
        return;
      }
      if (drawingMode === "polygon" || drawingMode === "polyline") {
        if (geometry && geometry.coords && geometry.coords.length > 0) {
          drawingObject.setPath(geometry.coords);
        }
        const events = updateEventPoly(drawingObject);
        return () => {
          _.forEach(events, (e) => e.remove());
        };
      } else if (drawingMode === "circle") {
        if (geometry && geometry.center && geometry.radius) {
          drawingObject.setCenter(geometry.center);
          drawingObject.setRadius(geometry.radius);
        }
        const events = updateEventCircle(drawingObject);
        return () => {
          _.forEach(events, (e) => e.remove());
        };
      } else if (drawingMode === "rectangle") {
        if (geometry && geometry.bounds) {
          drawingObject.setBounds(geometry.bounds);
        }
        const events = updateEventRect(drawingObject);
        return () => {
          _.forEach(events, (e) => e.remove());
        };
      }
    }
  }, [
    updateEventPoly,
    updateEventCircle,
    updateEventRect,
    drawingMode,
    drawingObject,
    geometry,
  ]);

  useEffect(() => {
    if (map && maps && geometries && geometries.length > 0) {
      const objects = renderGeofences(
        map,
        maps,
        geometries,
        mobile,
        true,
        onSelectGeofence
      );
      return () => {
        _.forEach(objects, (o) => o.setMap(null));
      };
    }
  }, [geometries, map, maps, mobile, onSelectGeofence]);

  useEffect(() => {
    let minLat = 999,
      minLng = 999,
      maxLat = -999,
      maxLng = -999;

    if (geometry && !drawingObject) {
      if (drawingMode === "polygon") {
        if (geometry && geometry.coords && geometry.coords.length > 0) {
          _.forEach(geometry.coords, (coord) => {
            minLat = Math.min(minLat, coord.lat);
            minLng = Math.min(minLng, coord.lng);
            maxLat = Math.max(maxLat, coord.lat);
            maxLng = Math.max(maxLng, coord.lng);
          });
          const obj = new maps.Polygon({
            paths: geometry.coords,
            strokeColor: drawingColor,
            strokeWeight: 3,
            fillColor: drawingColor,
            fillOpacity: drawingOpacity,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventPoly(obj);
          setDrawingObject(obj);
          map.fitBounds(
            {
              east: maxLng,
              north: maxLat,
              west: minLng,
              south: minLat,
            },
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      } else if (drawingMode === "polyline") {
        if (geometry && geometry.coords && geometry.coords.length > 0) {
          _.forEach(geometry.coords, (coord) => {
            minLat = Math.min(minLat, coord.lat);
            minLng = Math.min(minLng, coord.lng);
            maxLat = Math.max(maxLat, coord.lat);
            maxLng = Math.max(maxLng, coord.lng);
          });
          const obj = new maps.Polyline({
            path: geometry.coords,
            geodesic: true,
            strokeColor: drawingColor,
            strokeWeight: 3,
            strokeOpacity: 1.0,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventPoly(obj);
          setDrawingObject(obj);
          map.fitBounds(
            {
              east: maxLng,
              north: maxLat,
              west: minLng,
              south: minLat,
            },
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      } else if (drawingMode === "circle") {
        if (geometry && geometry.center && geometry.radius) {
          const obj = new maps.Circle({
            center: geometry.center,
            radius: geometry.radius,
            strokeColor: drawingColor,
            strokeWeight: 3,
            fillColor: drawingColor,
            fillOpacity: drawingOpacity,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventCircle(obj);
          setDrawingObject(obj);

          map.fitBounds(
            obj.getBounds(),
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      } else if (drawingMode === "rectangle") {
        if (geometry && geometry.bounds) {
          const obj = new maps.Rectangle({
            bounds: geometry.bounds,
            strokeColor: drawingColor,
            strokeWeight: 3,
            fillColor: drawingColor,
            fillOpacity: drawingOpacity,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventCircle(obj);
          setDrawingObject(obj);

          map.fitBounds(
            geometry.bounds,
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      }
    }
  }, [
    updateEventPoly,
    updateEventCircle,
    updateEventRect,
    drawingMode,
    drawingObject,
    geometry,
    drawingColor,
    drawingOpacity,
    maps,
    map,
    mobile,
  ]);

  useEffect(() => {
    if (drawingObject) {
      if (drawingMode === "polygon" || drawingMode === "polyline") {
        drawingMode &&
          onUpdateGeometry?.({
            type: drawingMode,
            coords: _.map(drawingObject.getPath().getArray(), (p) => ({
              lat: p.lat(),
              lng: p.lng(),
            })),
          });
      } else if (drawingMode === "circle") {
        drawingMode &&
          onUpdateGeometry?.({
            type: drawingMode,
            center: {
              lat: drawingObject.getCenter().lat(),
              lng: drawingObject.getCenter().lng(),
            },
            radius: drawingObject.getRadius(),
          });
      } else if (drawingMode === "rectangle") {
        const ne = drawingObject.getBounds().getNorthEast();
        const sw = drawingObject.getBounds().getSouthWest();
        drawingMode &&
          onUpdateGeometry?.({
            type: drawingMode,
            bounds: {
              north: ne.lat(),
              east: ne.lng(),
              south: sw.lat(),
              west: sw.lng(),
            },
          });
      }
    }
  }, [onUpdateGeometry, drawingObject, drawingMode]);

  useEffect(() => {
    if (drawingMode && map && maps && geometry === null) {
      const dm = new maps.drawing.DrawingManager({
        drawingControl: false,
        polygonOptions: {
          editable: true,
        },
      });
      dm.setMap(map);
      dm.setDrawingMode(drawingMode);

      const event = maps.event.addListener(dm, "overlaycomplete", (e: any) => {
        console.log("overlaycomplete", e.overlay);
        onStartDrawing?.();
        setDrawingObject(e.overlay);
        dm.setMap(null);
      });

      setDrawingManager(dm);

      return () => {
        maps.event.removeListener(event);
        dm.setMap(null);
        setDrawingManager(undefined);
      };
    }
  }, [
    map,
    maps,
    onStartDrawing,
    drawingMode,
    geometry,
    drawingColor,
    drawingOpacity,
  ]);

  const handleFullscreen = useCallback(() => {
    if (mapDivRef.current) {
      const elementToSendFullscreen = mapDivRef.current;
      if (isFullscreen(elementToSendFullscreen)) {
        setfullscreen(false);
        exitFullscreen(elementToSendFullscreen);
      } else {
        setfullscreen(true);
        requestFullscreen(elementToSendFullscreen);
      }
    }
  }, []);

  const renderMapTopRightMenu = useCallback(() => {
    return (
      <div className={classes.topControlPane}>
        <TopRightControl
          ref={anchorRef}
          onFullscreen={fullscreenIcon ? handleFullscreen : undefined}
          onLayers={() => setOpenLayer((o) => !o)}
          fullscreen={fullscreen}
        />
        {!mobile && (
          <Menu
            open={openLayer}
            onClickAway={() => setOpenLayer(false)}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            modifiers={{
              offset: { enabled: true, offset: "0, 4px" },
              preventOverflow: {
                enabled: false,
              },
            }}
          >
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "satellite",
              })}
              startIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "map",
              })}
              startIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </Menu>
        )}
        {mobile && (
          <MobileMenu open={openLayer} onClose={() => setOpenLayer(false)}>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </MobileMenu>
        )}
      </div>
    );
  }, [
    classes.topControlPane,
    classes.tnpDiv,
    classes.appIcon,
    classes.mobileMemuItem,
    fullscreenIcon,
    handleFullscreen,
    fullscreen,
    mobile,
    openLayer,
    mapMode,
    t,
    map,
  ]);

  const renderZoomPanel = useCallback(() => {
    return (
      <div className={classes.zoomControlDiv}>
        <Tooltip placement="left" title={t("My location") ?? "My location"}>
          <Fab
            size="small"
            variant="rounded"
            className={clsx(classes.zoomControlBtn, classes.gpsFixBtn)}
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position: GeolocationPosition) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };
                    map.setCenter(pos);
                  },
                  () => {}
                );
              }
            }}
          >
            <GpsFixedIcon fontSize="small" />
          </Fab>
        </Tooltip>
        {!mobile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 8,
              boxShadow:
                "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Tooltip title={t("Zoom in") ?? "Zoom in"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomInBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() + 1);
                }}
              >
                <AddIcon fontSize="small" />
              </Fab>
            </Tooltip>
            <Tooltip title={t("Zoom out") ?? "Zoom out"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomOutBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() - 1);
                }}
              >
                <RemoveIcon fontSize="small" />
              </Fab>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }, [
    classes.zoomControlDiv,
    classes.zoomControlBtn,
    classes.gpsFixBtn,
    classes.zoomInBtn,
    classes.zoomOutBtn,
    t,
    mobile,
    map,
  ]);

  return (
    <div
      ref={mapDivRef}
      className={clsx({
        [classes.drawingModeDiv]: drawingMode && mode === "add",
      })}
      style={{ width: "100%", height: "100%" }}
      onClick={(e) => {
        if (drawingMode) {
          setDrawingStarted(true);
        }
      }}
    >
      <GoogleMapReact
        style={{
          width: "100%",
          height: "100%",
          //@ts-ignore
          position: "relative",
          touchAction: "none",
        }}
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
          v: "weekly",
          libraries: ["geometry", "drawing"],
        }}
        defaultCenter={INITIAL_MAP_LOCATION}
        defaultZoom={4}
        options={{ disableDefaultUI: true }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ maps, map, ref }) => {
          setMap(map);
          setMaps(maps);
          onGoogleApiLoaded?.({ maps, map, ref });
          const div = getElementByXpath(
            "/html/body/div[1]/div/div[2]/div[2]/div[2]/div[1]/div/div[1]/div/div/div/div[1]/div[3]/div/div[3]/div[1]"
          );
          div?.parentNode?.removeChild(div);
        }}
        // onChange={(value) => console.log(value)}
      >
        {children}
      </GoogleMapReact>
      {renderMapTopRightMenu()}
      {renderZoomPanel()}
      {mode === "add" && !drawingStarted && (
        <div className={classes.addInfoDiv}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["0"]}
          >
            {t("Start drawing a shape_")}
          </Typography>
        </div>
      )}
    </div>
  );
};
