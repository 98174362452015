import React, { useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  LightColors,
  Typography,
  Button,
} from "@thingsw/pitta-design-system";
import { Paper } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import SimpleBarReact from "simplebar-react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "#13131C73",
    zIndex: 10000,
  },
  hidden: {
    display: "none",
  },
  modal: {
    position: "absolute",
    top: 80,
    right: 33,
    width: 696,
    height: 577,
    "&::after": {
      position: "absolute",
      content: "''",
      right: 58,
      top: -23,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderBottom: "24px solid white",
      borderRight: "14px solid transparent",
      borderLeft: "14px solid transparent",
    },
  },
  paper: {
    width: "100%",
    border: `1px solid ${LightColors.primary["5"]}`,
    display: "flex",
    flexDirection: "column",
  },

  btn: {
    position: "absolute",
    top: 4,
    right: 80,
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    background: "white",
  },
  listItemRootOpen: {
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  listItemIcon: {
    ...(theme.direction === "rtl" ? { marginRight: 12 } : { marginLeft: 12 }),
    minWidth: 34,
  },
  listItemTextRoot: {
    overflow: "hidden",
  },
  menuIcon: {
    fontSize: "1.125rem",
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? {
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        }
      : {}),
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  webTitleDiv: {
    padding: theme.spacing(1.5, 3, 0, 3),
    alignItems: "center",
  },
  contentBtnWrap: {
    marginTop: theme.spacing(3),
    overflowY: "auto",
    padding: theme.spacing(0, 3, 2.875, 3),
  },
  webImgDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(6) - 1,
  },
  buttonWrap: {
    paddingTop: theme.spacing(6),
    alignItems: "center",
  },
  scrollbarStyle: {
    height: "100%",
    overflowX: "hidden",
    flex: 1,
    maxHeight: "calc(100vh - 123px)",
  },
}));

interface OnboardingStep6Props {
  open: boolean;
  onClose: () => void;
}

export const OnboardingStep6 = ({ open, onClose }: OnboardingStep6Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div className={clsx(classes.root, !open && classes.hidden)}>
      <div ref={anchorRef} className={classes.btn}>
        <IconButton onClick={() => {}}>
          <div style={{ position: "relative" }}>
            <NotificationsIcon style={{ display: "block" }} />
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: 3,
                backgroundColor: LightColors.secondary["11"],
                position: "absolute",
                top: -3,
                right: -3,
              }}
            />
          </div>
        </IconButton>
      </div>
      <div className={classes.modal}>
        <Paper elevation={3} className={classes.paper}>
          <div className={clsx(classes.spaceBetween, classes.webTitleDiv)}>
            <Typography category="Default" variant="H6">
              {t("Push notifications")}
            </Typography>
            <IconButton onClick={onClose} style={{ marginRight: -8 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <SimpleBarReact className={classes.scrollbarStyle}>
            <div className={classes.contentBtnWrap}>
              <Typography category="Default" variant="Body">
                {t("The moment the_")}
              </Typography>
              <div className={classes.webImgDiv}>
                <img
                  srcSet="/images/onboarding6.png 1x"
                  alt="onboarding6"
                  style={{ width: 540, height: 336 }}
                />
              </div>
              <div className={clsx(classes.spaceBetween, classes.buttonWrap)}>
                <Typography category="Default" variant="Body">
                  6 / 6
                </Typography>
                <Button variant="contained" color="primary" onClick={onClose}>
                  {t("Done")}
                </Button>
              </div>
            </div>
          </SimpleBarReact>
        </Paper>
      </div>
    </div>
  );
};
