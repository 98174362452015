import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment-timezone";
import { ILatLng, ILatLngBounds } from "../../components/maps/GoogleMap";

export interface IGeofenceAlertOrig {
  Lastloc: string;
  fenceName: string;
  sendStatus: string;
  seq: number;
  time: string;
}

export interface IGeofenceAlert {
  latLng: ILatLng;
  fenceName: string;
  sendStatus: "Enter" | "Exit" | "Pass";
  seq: number;
  time: moment.Moment;
}

export interface IGeofenceAlertList {
  drive_no: number;
  data: IGeofenceAlert[];
}

export interface IClusterZone {
  lat: string;
  lon: string;
  count: string;
  share_video: "on" | "off";
}

export interface IDriveInfoOrig {
  drive_no: number;
  sdate: number;
  edate?: number;
  cnt?: number;
}

export interface IDriveInfo {
  drive_no: number;
  sdate: moment.Moment;
  edate: moment.Moment;
  cnt: number;
}

export interface ITrackDataOrig {
  sid: number;
  vdate: number;
  loc: number[];
  speed: number;
  alt: number;
  mode: "N" | "E" | "P" | "M";
  hide?: boolean;
  mtype: number;
  rid_front?: string;
  rid_rear?: string;
  sub_front?: string;
  sub_rear?: string;
  thm?: string;
  avg_speed: number;
  mp4_playable: 0 | 1;
  t30s: number;
  t1m: number;
  t2m: number;
  t4m: number;
  t8m: number;
  t16m: number;
}

export interface ITrackData {
  sid: number;
  vdate: moment.Moment;
  loc: number[];
  speed: number;
  alt: number;
  mode: "N" | "E" | "P" | "M";
  pevents?: ITrackData[];
  hide?: boolean;
  mtype: number;
  rid_front?: string;
  rid_rear?: string;
  rid_3?: string;
  sub_front?: string;
  sub_rear?: string;
  sub_3?: string;
  thm?: string;
  avg_speed: number;
  mp4_playable: 0 | 1;
  t30s: number;
  t1m: number;
  t2m: number;
  t4m: number;
  t8m: number;
  t16m: number;
}

export interface ITrackInfo {
  drive_no: number;
  sdate: moment.Moment;
  edate: moment.Moment;
  data: ITrackData[];
}

export interface ITrackInfoOrig {
  drive_no: number;
  data: ITrackDataOrig[];
}

export interface ICameraCluster {
  "zone count": {
    info: IClusterZone[];
  };
  myzone: {
    info: IClusterZone[];
  };
}

interface IState {
  loading: boolean;
  clusters?: ICameraCluster;
  driveList: IDriveInfo[];
  dates: string[];
  interval?: number;
  tracks: ITrackInfo[];
  type?: string;
  geofenceAlerts: IGeofenceAlertList[];
}

let initialState: IState = {
  loading: false,
  clusters: undefined,
  driveList: [],
  dates: [],
  tracks: [],
  geofenceAlerts: [],
};

const slice = createSlice({
  name: "gps",
  initialState,
  reducers: {
    clearGPS: (state) => {
      state.loading = false;
      state.clusters = undefined;
      state.driveList = [];
      state.dates = [];
      state.interval = undefined;
      state.tracks = [];
      state.type = undefined;
      state.geofenceAlerts = [];
    },
    clearGPSDriveData: (state) => {
      state.driveList = [];
      state.dates = [];
    },
    loadCluster: (state) => {
      state.loading = true;
    },
    successLoadCluster: (state, { payload }: PayloadAction<ICameraCluster>) => {
      state.loading = false;
      state.clusters = payload;
    },
    loadGPSDriveData: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGPSDriveData: (
      state,
      { payload }: PayloadAction<IDriveInfo[]>
    ) => {
      state.loading = false;
      state.driveList = payload;
      state.dates = _.chain(state.driveList)
        .map((track) => [
          track.sdate.format("YYYYMMDD"),
          track.edate?.format("YYYYMMDD"),
        ])
        .flattenDeep()
        .compact()
        .uniq()
        .value();
    },
    loadGPSTrackingData: (
      state,
      action: PayloadAction<{
        psn: string;
        drive_no_list: number[];
        cancel: AbortController;
        bounds?: ILatLngBounds;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGPSTrackingData: (
      state,
      { payload }: PayloadAction<{ interval: number; tracks: ITrackInfo[] }>
    ) => {
      state.loading = false;
      state.interval = payload.interval;
      // state.tracks = payload.tracks;
      const drive_no = _.union(
        _.map(state.tracks, (t) => t.drive_no),
        _.map(payload.tracks, (t) => t.drive_no)
      );
      state.tracks = _.chain(drive_no)
        .map((dn) => {
          const track = _.find(state.tracks, (t) => t.drive_no === dn);
          const aTrack = _.find(payload.tracks, (t) => t.drive_no === dn);
          if (aTrack && track) {
            return {
              drive_no: track.drive_no,
              sdate: track.sdate.isBefore(aTrack.sdate)
                ? track.sdate
                : aTrack.sdate,
              edate: track.edate.isAfter(aTrack.edate)
                ? track.edate
                : aTrack.edate,
              data: _.unionWith(
                track.data,
                aTrack.data,
                (a, b) => a.sid === b.sid
              ).sort((a, b) => a.sid - b.sid),
            };
          }
          if (track) {
            return track;
          }

          return aTrack;
        })
        .compact()
        .value();
    },
    clearGPSTrackingData: (state) => {
      state.tracks = [];
      state.interval = undefined;
    },
    loadGeofenceAlert: (
      state,
      action: PayloadAction<{ psn: string; drive_no_list: number[] }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGeofenceAlert: (
      state,
      { payload }: PayloadAction<IGeofenceAlertList[]>
    ) => {
      state.loading = false;
      state.type = undefined;
      state.geofenceAlerts = payload;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
  },
});

export const {
  clearGPSDriveData,
  clearGPS,
  loadCluster,
  successLoadCluster,
  loadGPSDriveData,
  successLoadGPSDriveData,
  loadGPSTrackingData,
  successLoadGPSTrackingData,
  clearGPSTrackingData,
  loadGeofenceAlert,
  successLoadGeofenceAlert,
  clearLoading,
} = slice.actions;

export const GPS = slice.name;
export default slice.reducer;
