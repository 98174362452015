import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  clearLoading,
  ICameraCluster,
  IDriveInfo,
  IDriveInfoOrig,
  IGeofenceAlert,
  IGeofenceAlertList,
  IGeofenceAlertOrig,
  ITrackData,
  ITrackInfo,
  ITrackInfoOrig,
  loadCluster,
  loadGeofenceAlert,
  loadGPSDriveData,
  loadGPSTrackingData,
  successLoadCluster,
  successLoadGeofenceAlert,
  successLoadGPSDriveData,
  successLoadGPSTrackingData,
} from "./slice";
import { RootState } from "../store";
import { IUserLoginInfo, logout, USER } from "../User/slice";
import * as API from "../../apis";
import { PayloadAction } from "@reduxjs/toolkit";
import { RESULT_CODE } from "../../types";
import moment from "moment-timezone";
import _ from "lodash";
import { ILatLngBounds } from "../../components/maps/GoogleMap";

const sample: ICameraCluster = {
  "zone count": {
    info: [
      {
        lat: "37.288157",
        lon: "127.005378",
        count: "100",
        share_video: "off",
      },
      {
        lat: "37.404511",
        lon: "127.099034",
        count: "20",
        share_video: "on",
      },
    ],
  },
  myzone: {
    info: [
      {
        lat: "37.741349",
        lon: "127.064630",
        count: "100",
        share_video: "on",
      },
      {
        lat: "37.470197",
        lon: "126.624838",
        count: "10",
        share_video: "off",
      },
    ],
  },
};

function* handleLoadCluster() {
  try {
    yield put(successLoadCluster(sample));
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadGPSDriveData({ payload }: PayloadAction<string>) {
  try {
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const email = yield select((state: RootState) => state[USER].email);
    const resp = yield call(API.getGPSDriveData, email, user_token, payload);

    const {
      resultcode,
      response: { drive_list },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: { drive_list: IDriveInfoOrig[] };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadGPSDriveData(
          _.chain(drive_list)
            .filter((track) => !!(track.edate && track.cnt))
            .map((track) => {
              let sdate = moment.unix(track.sdate).utc(false);
              let edate: moment.Moment | undefined = undefined;

              if (track.edate !== undefined) {
                edate = moment.unix(track.edate).utc(false);
                if (sdate.isAfter(edate)) {
                  let tmp = edate;
                  edate = sdate;
                  sdate = tmp;
                }
              }
              return {
                ...track,
                sdate,
                edate,
              } as IDriveInfo;
            })
            .sortBy(["edate", "sdate"])
            .reverse()
            .value()
        )
      );
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadGPSTrackingData({
  payload,
}: PayloadAction<{
  psn: string;
  drive_no_list: number[];
  cancel: AbortController;
  bounds?: ILatLngBounds;
}>) {
  try {
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const email = yield select((state: RootState) => state[USER].email);
    const resp = yield call(
      API.getGPSTrackingData,
      email,
      user_token,
      payload.psn,
      payload.drive_no_list,
      payload.cancel,
      payload.bounds
    );

    const data = yield resp.json();
    const { resultcode, response } = data as {
      resultcode: RESULT_CODE;
      response?: { interval: number; count: number; coords: ITrackInfoOrig[] };
    };
    if (resultcode === "BC_ERR_OK" && response) {
      const { interval, coords } = response;
      const tracks = _.chain(coords)
        .map((coord) => {
          const sdate = _.minBy(coord.data, (d) => d.vdate)?.vdate ?? 0;
          const edate = _.maxBy(coord.data, (d) => d.vdate)?.vdate ?? 0;
          const data = _.chain(coord.data)
            .map((d, i) => {
              let pevents: ITrackData[] | undefined = undefined;
              if (d.mode === "P") {
                const exists = _.chain(coord.data)
                  .slice(0, i)
                  .find(
                    (item) =>
                      item.loc[0] === d.loc[0] &&
                      item.loc[1] === d.loc[1] &&
                      item.mode === "P"
                  )
                  .value();
                if (exists) {
                  return undefined;
                }

                pevents = _.chain(coord.data)
                  .slice(i + 1)
                  .filter(
                    (item) =>
                      item.loc[0] === d.loc[0] &&
                      item.loc[1] === d.loc[1] &&
                      item.mode === "E"
                  )
                  .map((item) => {
                    item.hide = true;
                    return {
                      ...item,
                      vdate: moment.unix(item.vdate).utc(false),
                    };
                  })
                  .value();
              }

              return {
                ...d,
                pevents,
                vdate: moment.unix(d.vdate).utc(false),
              } as ITrackData;
            })
            .compact()
            .value();

          return {
            drive_no: coord.drive_no,
            sdate: moment.unix(sdate).utc(false),
            edate: moment.unix(edate).utc(false),
            data,
          } as ITrackInfo;
        })
        .value();
      yield put(
        successLoadGPSTrackingData({
          interval,
          tracks,
        })
      );
    } else if (resultcode === "401") {
      yield put(logout());
    }
  } catch (err) {
    yield put(clearLoading());
    // console.error(err);
  }
}

function* handleLoadGeofenceAlert({
  payload,
}: PayloadAction<{ psn: string; drive_no_list: number[] }>) {
  try {
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const email = yield select((state: RootState) => state[USER].email);
    const resp = yield call(
      API.getGeofenceAlerts,
      email,
      user_token,
      payload.psn,
      payload.drive_no_list
    );

    const { resultcode, coords } = resp.data as {
      resultcode: RESULT_CODE;
      coords: { drive_no: number; data: IGeofenceAlertOrig[] }[];
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(
        successLoadGeofenceAlert(
          _.chain(coords)
            .map((coord) => {
              return {
                drive_no: coord.drive_no,
                data: _.map(coord.data, (d) => {
                  const latLng = d.Lastloc.split(",");
                  return {
                    latLng: {
                      lat: parseFloat(latLng[0]),
                      lng: parseFloat(latLng[1]),
                    },
                    fenceName: d.fenceName,
                    sendStatus: d.sendStatus,
                    seq: d.seq,
                    time: moment(d.time),
                  } as IGeofenceAlert;
                }),
              } as IGeofenceAlertList;
            })
            .filter((g) => g.data.length > 0)
            .value()
        )
      );
    }
  } catch (err) {
    console.error(err);
  }
}

export function* watchGPS() {
  yield takeLatest(loadCluster, handleLoadCluster);
  yield takeLatest(loadGPSDriveData, handleLoadGPSDriveData);
  yield takeLatest(loadGPSTrackingData, handleLoadGPSTrackingData);
  yield takeLatest(loadGeofenceAlert, handleLoadGeofenceAlert);
}
