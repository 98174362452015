import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  LightColors,
  Link,
  Typography,
} from "@thingsw/pitta-design-system";
import firebase from "firebase/app";
import "firebase/auth";

import { useDispatch, useSelector } from "react-redux";
import { Hidden, makeStyles, Theme } from "@material-ui/core";
import crypto from "crypto";

import LoginForm from "../forms/LoginForm";
import { MinimalFooter } from "../components/MinimalFooter";
import { LoginHeader } from "../components/LoginHeader";
import { ILoginForm } from "../types";
import { RootState } from "../features/store";
import { useHistory, useLocation } from "react-router-dom";
import { AuthType, linkSocialAccount, USER } from "../features/User/slice";
import { SubmissionError, submit } from "redux-form";
import { Webviewer } from "../contants/Breakpoints";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 58px)",
    alignItems: "center",
    padding: theme.spacing(3, 2, 0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.25, 2, 0, 2),
    },
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 438,
    flexGrow: 1,
  },
  dividerDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  divider: {
    border: `1px solid ${LightColors.primary["6"]}`,
    position: "absolute",
    width: "100%",
    top: "50%",
    transform: "translate(0, -50%);",
  },
  orDiv: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    backgroundColor: LightColors.primary["0"],
    zIndex: 999,
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  formControlLabelRoot: {
    marginLeft: -5,
  },
  rememberDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 21,
  },
  forgetDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  errorDiv: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
}));

export const VerifyAccountScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation<{ email: string; provider: AuthType }>();
  const history = useHistory();
  const { email, provider } = location.state ?? {};

  const { loading } = useSelector((state: RootState) => state[USER]);
  const [idToken, setIdToken] = useState<string>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup.string().required(t("Enter password")),
      }),
    [t]
  );

  const handleLogin = () => {
    dispatch(submit("LoginForm"));
  };

  useEffect(() => {
    const getIdToken = async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        // console.log(currentUser.toJSON());
        const token = await currentUser.getIdToken();
        setIdToken(token);
        firebase.auth().signOut();
      } else {
        history.goBack();
      }
    };
    getIdToken();
  }, [history]);

  // console.log("idToken", idToken);

  const handleLoginSubmit = async (login: ILoginForm) => {
    try {
      await schema.validate(login);
      if (idToken) {
        dispatch(
          linkSocialAccount({
            email,
            passwd: crypto
              .createHash("sha256")
              .update(login.password)
              .digest("hex"),
            oauthToken: idToken,
            signupType: provider,
          })
        );
      }
    } catch (err) {
      throw new SubmissionError({
        [err.path]: err.errors,
      });
    }
  };

  // console.log(idToken, provider);
  // console.log("error", error);
  return (
    <React.Fragment>
      <LoginHeader />
      {/* Body */}
      <div className={classes.root}>
        <Container className={classes.body}>
          <div className={classes.formDiv}>
            <Typography
              category="Default"
              variant="H1"
              className={classes.marginB4}
            >
              {t("Verify your account")}
            </Typography>
            <div className={classes.marginB4}>
              <Typography
                category="Default"
                variant="Body"
                className={classes.marginB4}
              >
                {t("An account with_")}
              </Typography>
            </div>
            <div style={{ marginBottom: 13 }}>
              <LoginForm
                onSubmit={handleLoginSubmit}
                initialValues={{ email }}
                disabled
                autoFocus
              />
            </div>

            <div className={classes.rememberDiv}>
              <Hidden smDown>
                <Link category="Default" variant="Body" to="/reset">
                  {t("Forgot password?")}
                </Link>
              </Hidden>
            </div>
            <Button
              fullWidth
              color="primary"
              onClick={handleLogin}
              loading={loading}
            >
              {t("Log in")}
            </Button>

            <Hidden mdUp>
              <div className={classes.forgetDiv}>
                <Link category="Default" variant="Body" to="/reset">
                  {t("Forgot password?")}
                </Link>
              </div>
            </Hidden>
          </div>
          <MinimalFooter />
        </Container>
      </div>
    </React.Fragment>
  );
};
