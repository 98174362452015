import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EventCode,
  FileTypeName,
  LocationType,
} from "@thingsw/pitta-design-system";
import _ from "lodash";
import moment from "moment";
import { ICameraInfo } from "../Camera/slice";

// dashCam업로드 리스트에서 삭제
export interface IDelUploadList {
  filename: string;
  type: string;
}

export interface VODListRequest {
  lb_server_name: string;
  lb_http_port: string;
  psn: string;
}

export interface VODTokenRequest {
  psn: string;
  filename: string;
}

export interface ICloudVODList {
  s3_usage: number;
  s3_usage_limit: number;
  filelist: IVOD[];
}

export interface IFileUploadInfo {
  filename: string;
  current: number;
  total: number;
  type: "auto" | "manual";
  error?: boolean;
}

export type ICameraVOD = IVOD & {
  front?: IVOD;
  rear?: IVOD;
  interior?: IVOD;
};

export interface IVOD {
  rid?: string;
  filename: string;
  direction: LocationType;
  event: FileTypeName;
  eventCode?: EventCode;
  time: moment.Moment;
  exp?: moment.Moment;
  thmName?: string;
  thmRid?: string;
  lowFilename?: string;
  lowRid?: string;
  hasFront?: boolean;
  hasRear?: boolean;
  hasInterior?: boolean;
  hasLow?: boolean;
  hasOriginal?: boolean;
}

export interface IVODToken {
  vod_token: string;
  vod_limit: number;
  vod_usage: number;
}

export const getDirection: (c: string) => LocationType = (c) => {
  if (c === "R") {
    return "Rear";
  } else if (c === "I") {
    return "Interior";
  }
  return "Front";
};

export const getEvent: (c: string) => FileTypeName = (c) => {
  if (
    _.includes(
      ["E", "I", "O", "A", "T", "B", "R", "X", "G", "F", "D", "L", "C", "S"],
      c
    )
  ) {
    return "Event";
  } else if (c === "M") {
    return "Manual";
  } else if (c === "P") {
    return "Parking";
  }
  return "Normal";
};

interface IState {
  loading: boolean;
  // deleteLoading?: boolean;
  vodList: ICameraVOD[];
  type?: string;
  cloudVodList?: ICloudVODList;
  eventVodList: IVOD[];
  tokens: { [key: string]: IVODToken };
  thm: { [key: string]: string };
  tokenRequests: { [key: string]: boolean };
  thmRequests: { [key: string]: boolean };
  uploadList: IFileUploadInfo[];
  removeUploadList?: IFileUploadInfo[];
}

let initialState: IState = {
  loading: false,
  // deleteLoading: false,
  vodList: [],
  eventVodList: [],
  tokens: {},
  thm: {},
  tokenRequests: {},
  thmRequests: {},
  uploadList: [],
  removeUploadList: [],
};

const slice = createSlice({
  name: "vod",
  initialState,
  reducers: {
    clearVOD: (state) => {
      state.loading = false;
      state.vodList = [];
      state.type = undefined;
      state.cloudVodList = undefined;
      state.eventVodList = [];
      state.tokens = {};
      state.thm = {};
      state.tokenRequests = {};
      state.thmRequests = {};
      state.uploadList = [];
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    loadVODList: (state, _action: PayloadAction<VODListRequest>) => {
      state.loading = true;
      state.type = undefined;
    },
    successloadVODList: (state, { payload }: PayloadAction<string[]>) => {
      const getVODInfo = (val: string[], vod: string | undefined) => {
        if (!vod) {
          return;
        }
        const filename = vod.split(".")[0];
        const names = filename.split("_");
        const time = moment(`${names[0]} ${names[1]}`);
        const ev = names[2].split("");

        const front = _.find(val, (v) => {
          const filename = v.split(".")[0];
          const names = filename.split("_");
          const ev = names[2].split("");
          const dir = getDirection(ev[1]);
          return dir === "Front";
        });

        const rear = _.find(val, (v) => {
          const filename = v.split(".")[0];
          const names = filename.split("_");
          const ev = names[2].split("");
          const dir = getDirection(ev[1]);
          return dir === "Rear";
        });

        const interior = _.find(val, (v) => {
          const filename = v.split(".")[0];
          const names = filename.split("_");
          const ev = names[2].split("");
          const dir = getDirection(ev[1]);
          return dir === "Interior";
        });
        return {
          filename: vod,
          direction: getDirection(ev[1]),
          event: getEvent(ev[0]),
          eventCode: ev[0] as EventCode,
          lowFilename: `${names[0]}_${names[1]}_${ev[0]}${ev[1]}S.mp4`,
          time,
          hasLow: true,
          hasOriginal: true,
          hasFront: !!front,
          hasRear: !!rear,
          hasInterior: !!interior,
        } as IVOD;
      };
      state.vodList = _.chain(payload)
        .groupBy((vod) => {
          const filename = vod.split(".")[0];
          const names = filename.split("_");
          return `${names[0]}_${names[1]}`;
        })
        .map((val, key) => {
          const vod = val[0];
          const result = getVODInfo(val, vod);

          const front = _.find(val, (v) => {
            const filename = v.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const dir = getDirection(ev[1]);
            return dir === "Front";
          });

          const rear = _.find(val, (v) => {
            const filename = v.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const dir = getDirection(ev[1]);
            return dir === "Rear";
          });

          const interior = _.find(val, (v) => {
            const filename = v.split(".")[0];
            const names = filename.split("_");
            const ev = names[2].split("");
            const dir = getDirection(ev[1]);
            return dir === "Interior";
          });

          return {
            ...result,
            front: getVODInfo(val, front),
            rear: getVODInfo(val, rear),
            interior: getVODInfo(val, interior),
          } as IVOD;
        })
        .sortBy("time")
        .value();
      state.loading = false;
    },
    loadCloudVODList: (state, _action: PayloadAction<string>) => {
      state.loading = true;
      state.type = undefined;
    },
    successloadCloudVODList: (
      state,
      { payload }: PayloadAction<ICloudVODList>
    ) => {
      state.loading = false;
      state.cloudVodList = {
        ...payload,
        filelist: _.sortBy(payload.filelist, "time"),
      };
    },
    loadCloudVODFile: (state, _action: PayloadAction<VODTokenRequest>) => {},
    loadCloudVODThm: (
      state,
      _action: PayloadAction<{ psn: string; filename: string; thmName: string }>
    ) => {},
    deleteCloudVODFile: (
      state,
      action: PayloadAction<{
        psn: string;
        filenames: string[];
        vodCount?: number;
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successDeleteCloudVODFile: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.loading = false;
      state.type = undefined;
      if (state.cloudVodList) {
        state.cloudVodList = {
          ...state.cloudVodList,
          filelist: _.filter(
            state.cloudVodList.filelist,
            (f) => !_.includes(payload, f.filename)
          ),
        };
      }
    },
    loadVODToken: (state, _action: PayloadAction<VODTokenRequest>) => {},
    resetTokenRequest: (state) => {
      state.tokenRequests = {};
    },
    updateTokenRequest: (
      state,
      { payload }: PayloadAction<{ filename: string; requested: boolean }>
    ) => {
      state.tokenRequests = {
        ...state.tokenRequests,
        [payload.filename]: payload.requested,
      };
    },
    loadUrgentVODToken: (
      state,
      { payload }: PayloadAction<VODTokenRequest>
    ) => {
      state.tokenRequests = {
        ...state.tokenRequests,
        [payload.filename]: true,
      };
    },
    successLoadVODToken: (
      state,
      { payload }: PayloadAction<{ filename: string; token: IVODToken }>
    ) => {
      state.tokens = { ...state.tokens, [payload.filename]: payload.token };
    },
    loadThumbnail: (
      state,
      _action: PayloadAction<VODTokenRequest & VODListRequest>
    ) => {},
    resetThmRequest: (state) => {
      state.thmRequests = {};
    },
    updateThmRequest: (
      state,
      { payload }: PayloadAction<{ filename: string; requested: boolean }>
    ) => {
      state.thmRequests = {
        ...state.thmRequests,
        [payload.filename]: payload.requested,
      };
    },
    successLoadThumbnail: (
      state,
      { payload }: PayloadAction<{ filename: string; thm: string }>
    ) => {
      state.thm = { ...state.thm, [payload.filename]: payload.thm };
    },
    loadEventVODList: (state, _action: PayloadAction<string>) => {
      state.loading = true;
      state.type = undefined;
    },
    successLoadEventVODList: (state, { payload }: PayloadAction<IVOD[]>) => {
      state.loading = false;
      state.eventVodList = payload;
    },
    loadEventThumbnail: (
      state,
      _action: PayloadAction<{ psn: string; rid: string; filename: string }>
    ) => {},
    deleteEventVODFile: (
      state,
      action: PayloadAction<{ psn: string; rids: string[]; fileLength: number }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successDeleteEventVODFile: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.eventVodList = _.filter(
        state.eventVodList,
        (vod) => !_.includes(payload, vod.rid)
      );
    },
    doneDeleteEventVODFiles: (state) => {
      state.loading = false;
      state.type = undefined;
    },
    moveEventVODFiles: (
      state,
      action: PayloadAction<{ psn: string; rids: string[] }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    uploadVODFile: (
      state,
      action: PayloadAction<{ camera: ICameraInfo; filename: string }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    loadVODUploadList: (state, action: PayloadAction<ICameraInfo>) => {},
    successLoadVODUploadList: (
      state,
      { payload }: PayloadAction<IFileUploadInfo[]>
    ) => {
      state.loading = false;
      const uploadList = _.map(state.uploadList, (up) => ({
        ...up,
        error: undefined,
      }));
      const done = _.differenceBy(uploadList, payload, "filename")
        .filter((f) => f.current !== f.total)
        .map((f) => ({
          ...f,
          current: f.total,
        }));

      const merged = _.merge(
        _.keyBy(uploadList, "filename"),
        _.keyBy(payload, "filename")
      );
      const merged2 = _.merge(
        _.keyBy(merged, "filename"),
        _.keyBy(done, "filename")
      );

      // console.log("done", uploadList, payload, done, merged, merged2);

      state.uploadList = _.values(merged2);
    },
    failLoadVODUploadList: (state) => {
      state.loading = false;
      state.uploadList = _.map(state.uploadList, (up) => {
        if (up.current !== up.total) {
          up.error = true;
        }
        return up;
      });
    },
    deleteVODUploadList: (
      state,
      action: PayloadAction<{
        camera: ICameraInfo;
        removelist: IDelUploadList[];
      }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },

    successDelUploadList: (state) => {
      state.loading = false;
    },
  },
});

export const {
  clearVOD,
  loadCloudVODFile,
  loadCloudVODThm,
  loadCloudVODList,
  successloadCloudVODList,
  deleteCloudVODFile,
  successDeleteCloudVODFile,
  loadVODList,
  successloadVODList,
  resetTokenRequest,
  updateTokenRequest,
  resetThmRequest,
  updateThmRequest,
  loadUrgentVODToken,
  loadVODToken,
  successLoadVODToken,
  loadThumbnail,
  successLoadThumbnail,
  loadEventVODList,
  successLoadEventVODList,
  loadEventThumbnail,
  deleteEventVODFile,
  successDeleteEventVODFile,
  doneDeleteEventVODFiles,
  moveEventVODFiles,
  uploadVODFile,
  loadVODUploadList,
  successLoadVODUploadList,
  deleteVODUploadList,
  successDelUploadList,
  failLoadVODUploadList,
  clearLoading,
} = slice.actions;
export const VOD = slice.name;
export default slice.reducer;
