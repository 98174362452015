import { makeStyles, Theme } from "@material-ui/core/styles";
import { LightColors } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

const HANDLE_HEIGHT = 56;
const HANDLE_MARGIN_HEIGHT = 64;
export type DRAWER_MODE = "open" | "close" | "half" | "camera";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    height: "calc(var(--vh, 1vh) * 100)",
    width: "100%",
    backgroundColor: "transparent",
    bottom: 0,
    zIndex: 98,
  },
  rootOpen: {
    backgroundColor: `${LightColors.primary["1"]}73`,
  },
  rootTransition: {
    transition: theme.transitions.create("transform"),
  },
  panel: {
    width: "100%",
    height: "100%",
    backgroundColor: LightColors.primary["0"],
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  panelCamera: {
    height: 130,
  },
  panelHalf: {
    height: "calc(var(--vh, 1vh) * 30 + 64px)",
  },
  handleDiv: {
    position: "absolute",
    width: "60%",
    height: HANDLE_HEIGHT,
    display: "flex",
    padding: theme.spacing(1, 0, 0, 0),
    justifyContent: "center",
    zIndex: 99,
    margin: "0 20%",
  },
  handle: {
    width: theme.spacing(3),
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: LightColors.primary["5"],
  },
}));

interface MobileDrawerProps {
  children: React.ReactElement;
  initMode?: DRAWER_MODE;
  mode: "camera" | "gps-tracking" | "geofence";
}

export const MobileDrawer = (props: MobileDrawerProps) => {
  const { mode } = props;
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState<DRAWER_MODE>("close");
  const handlerRef = useRef<HTMLDivElement>(null);
  const [curPosY, setCurPosY] = useState(0);
  const [onSwipe, setOnSwipe] = useState(false);
  const [onTransition, setOnTransition] = useState(false);
  const [onTap, setOnTap] = useState(false);
  const windowHeight = useRef(0);
  const handlers = useSwipeable({
    trackMouse: true,
    onTap: ({ event }) => {
      event.stopPropagation();
      event.preventDefault();
      setOnSwipe(false);
      setOnTransition(false);
      setOnTap(true);
      if (drawerMode === "open") {
        setCurPosY(windowHeight.current - HANDLE_MARGIN_HEIGHT);
        setDrawerMode("close");
      } else {
        setCurPosY(0);
        setDrawerMode("open");
      }
    },
    onSwiped: (e) => {
      setOnSwipe(false);
    },
    onSwipedUp: (e) => {
      if (e.event.target === handlerRef.current && !onTap) {
        setDrawerMode("open");
        setOnTransition(true);
      }
    },
    onSwipedDown: (e) => {
      if (e.event.target === handlerRef.current && !onTap) {
        // setOpen(false);
        if (mode === "gps-tracking") {
          setDrawerMode("close");
        } else {
          if (curPosY >= windowHeight.current / 2) {
            setDrawerMode("close");
          } else {
            setDrawerMode("half");
          }
        }

        setOnTransition(true);
      }
    },
    onSwiping: (e) => {
      if (e.event.target === handlerRef.current && !onTap) {
        setOnSwipe(true);
        const newPosY = e.initial[1] + e.deltaY;
        const diff = Math.abs(curPosY - newPosY);
        if (diff > 1) {
          setCurPosY(
            Math.min(newPosY, windowHeight.current - HANDLE_MARGIN_HEIGHT)
          );
        }
      }
    },
  });

  useEffect(() => {
    if (props.initMode) {
      setDrawerMode(props.initMode);
    }
  }, [props.initMode]);

  useEffect(() => {
    const onResize = (ev: any) => {
      const height = window.innerHeight;
      setCurPosY(height - HANDLE_MARGIN_HEIGHT);
      windowHeight.current = height;
    };
    const onOpen = () => {
      setDrawerMode("open");
    };
    onResize({});
    window.addEventListener("resize", onResize);
    document.addEventListener("drawer:open", onOpen);
    return () => {
      window.removeEventListener("resize", onResize);
      document.removeEventListener("drawer:open", onOpen);
    };
  }, []);

  useEffect(() => {
    if (handlerRef.current) {
      handlers.ref(handlerRef.current);
    }
  }, [handlerRef, handlers]);

  useEffect(() => {
    if (drawerMode === "open") {
      setCurPosY(0);
    } else if (drawerMode === "half") {
      setCurPosY(windowHeight.current / 2);
    } else {
      setCurPosY(windowHeight.current - HANDLE_MARGIN_HEIGHT);
    }
  }, [drawerMode]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootOpen]: drawerMode === "open" && !onSwipe,
        [classes.rootTransition]: !onSwipe,
      })}
      onTransitionEnd={() => {
        setOnTransition(false);
        setOnTap(false);
        console.log("onTransitionEnd");
      }}
      style={
        onSwipe
          ? {
              transform: `translateY(${curPosY}px)`,
              //@ts-ignore
              // transform: "translateY(calc(var(--vh, 1vh) * 100 - 64px))",
            }
          : {
              // ...(mode === "open" ? {} : {}),
              ...(drawerMode === "half"
                ? {
                    transform: "translateY(calc(var(--vh, 1vh) * 70 - 64px))",
                  }
                : {}),
              ...(drawerMode === "camera"
                ? {
                    transform: "translateY(calc(var(--vh, 1vh) * 100 - 130px))",
                  }
                : {}),
              ...(drawerMode === "close"
                ? {
                    transform: "translateY(calc(var(--vh, 1vh) * 100 - 64px))",
                  }
                : {}),
            }
      }
    >
      <div
        className={clsx(classes.panel, {
          [classes.panelHalf]:
            drawerMode === "half" && !onSwipe && !onTransition,
          [classes.panelCamera]:
            drawerMode === "camera" && !onSwipe && !onTransition,
        })}
      >
        <div
          ref={handlerRef}
          className={classes.handleDiv}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!onTap) {
              setOnSwipe(false);
              setOnTransition(false);
              setOnTap(true);
              switch (drawerMode) {
                case "open":
                  if (mode === "gps-tracking") {
                    setDrawerMode("close");
                  } else {
                    setDrawerMode("half");
                  }

                  break;
                case "half":
                  setCurPosY(windowHeight.current - HANDLE_MARGIN_HEIGHT);
                  setDrawerMode("close");
                  break;
                default:
                  setCurPosY(0);
                  setDrawerMode("open");
                  break;
              }
            }
          }}
        >
          <div className={classes.handle} />
        </div>
        {/* <CameraListPanel open mobile {...props} /> */}
        {React.cloneElement(props.children)}
      </div>
    </div>
  );
};
