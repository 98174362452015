import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  LightColors,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useCallback, useEffect, useState } from "react";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Webviewer } from "../../contants/Breakpoints";
import { useTranslation } from "react-i18next";
import { RecordingFirmwarePanel } from "./RecordingFirmwarePanel";
import { TimeFirmwarePanel } from "./TimeFirmwarePanel";
import { VideoFirmwarePanel } from "./VideoFirmwarePanel";
import { GsensorFirmwarePanel } from "./GsensorFirmwarePanel";
import { MotionFirmwarePanel } from "./MotionFirmwarePanel";
import { CloudFirmwarePanel } from "./CloudFirmwarePanel";
import { LanguageFirmwarePanel } from "./LanguageFirmwarePanel";
import { SystemFirmwarePanel } from "./SystemFirmwarePanel";
import { WifiFirmwarePanel } from "./WifiFirmwarePanel";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadFirmwareSettings } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import moment from "moment";
import _ from "lodash";
import { FirmwareDMSPanel } from "./FirmwareDMSPanel";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 144,
    borderRadius: 4,
    marginBottom: 32,
    marginTop: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      maxHeight: 144,
    },
  },
  bigBox2: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 96,
    borderRadius: 4,
    marginBottom: 32,
    marginTop: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      maxHeight: 96,
      marginTop: 16,
    },
  },
  bigBox3: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 192,
    borderRadius: 4,
    marginBottom: 32,
    marginTop: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      // maxHeight: 192,
      marginTop: 16,
    },
  },
  boderBottomBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    padding: "0 13px 0 15px",
    justifyContent: "space-between",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      cursor: "pointer",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  },
  boderNoneBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    padding: "0 13px 0 15px",
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      cursor: "pointer",
    },
  },
  pd0: {
    padding: 0,
  },
  ArrowBack: {
    maxHeight: 21,
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    maxWidth: 672,
  },
  ArrowBackPd: {
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 7 }
      : { paddingLeft: 0, paddingRight: 7 }),
  },
  mgT16: {
    marginTop: 16,
  },
  mg0: {
    margin: 0,
  },
  infoIcon: {
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },
}));

export const FirmwarePanel = () => {
  const classes = useStyles();
  const [Menu, setMenu] = useState<number>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    camera,
    firmwareConfig: settings,
    cameraList,
    firmware,
  } = useSelector((state: RootState) => state[CAMERA]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings());
    }
  }, [dispatch, camera]);

  const updateDate = useSelector(
    (state: RootState) => state[CAMERA].firmware?.update_date
  );
  const getDateTime = useCallback(
    (Update_date: string) => {
      const cam = _.find(
        cameraList?.DeviceListInfo,
        (dev) => dev.device.psn === camera?.psn
      )?.device;
      return moment(Update_date)
        .add(cam?.interval ?? 0, "m")
        .format("YYYY.MM.DD HH:mm:ss");
    },
    [camera?.psn, cameraList?.DeviceListInfo]
  );

  return (
    <>
      {Menu === undefined && (
        <>
          <div className={classes.mgT16}>
            <Typography variant="H6" category="Default">
              {t("Basic")}
            </Typography>
            <div className={classes.bigBox}>
              {settings?.["Time"] && (
                <div
                  className={classes.boderBottomBox}
                  onClick={() => setMenu(0)}
                >
                  <Typography variant="Body" category="Default">
                    {t("Time")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
              {settings?.["Video"] && (
                <div
                  className={classes.boderBottomBox}
                  onClick={() => setMenu(1)}
                >
                  <Typography variant="Body" category="Default">
                    {t("Video")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
              {settings?.["Recording"] && (
                <div
                  className={classes.boderNoneBox}
                  onClick={() => setMenu(2)}
                >
                  <Typography variant="Body" category="Default">
                    {t("Recording")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          <div className={classes.mg0}>
            <Typography variant="H6" category="Default">
              {t("Sensitivity")}
            </Typography>
            <div className={classes.bigBox2}>
              {settings?.["Gsensor"] && (
                <div
                  className={classes.boderBottomBox}
                  onClick={() => setMenu(3)}
                >
                  <Typography variant="Body" category="Default">
                    {t("G-sensor")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}

              {settings?.["MotionDetection"] && (
                <div
                  className={classes.boderNoneBox}
                  onClick={() => setMenu(4)}
                >
                  <Typography variant="Body" category="Default">
                    {t("Motion detection")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          <div className={classes.mg0}>
            <Typography variant="H6" category="Default">
              {t("Advanced")}
            </Typography>
            <div className={classes.bigBox3}>
              {settings?.["System"] && (
                <div
                  className={classes.boderBottomBox}
                  onClick={() => setMenu(5)}
                >
                  <Typography variant="Body" category="Default">
                    {t("System")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
              {settings?.["DMS"] && (
                <div
                  className={classes.boderBottomBox}
                  onClick={() => setMenu(9)}
                >
                  <Typography variant="Body" category="Default">
                    {t("DMS")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
              {settings?.["Wifi"] && (
                <div
                  className={classes.boderBottomBox}
                  onClick={() => setMenu(6)}
                >
                  <Typography variant="Body" category="Default">
                    {t("Wi-Fi")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
              {settings?.["Cloud"] && (
                <div
                  className={classes.boderBottomBox}
                  onClick={() => setMenu(7)}
                >
                  <Typography variant="Body" category="Default">
                    {t("Cloud")}
                  </Typography>
                  <IconButton className={classes.pd0}>
                    <ChevronRightIcon></ChevronRightIcon>
                  </IconButton>
                </div>
              )}
              {settings?.["FirmwareLanguage"] &&
                firmware?.firmware_language !== "Russian" && (
                  <div
                    className={classes.boderNoneBox}
                    onClick={() => setMenu(8)}
                  >
                    <Typography variant="Body" category="Default">
                      {t("Firmware language")}
                    </Typography>
                    <IconButton className={classes.pd0}>
                      <ChevronRightIcon></ChevronRightIcon>
                    </IconButton>
                  </div>
                )}
            </div>
          </div>
          <div style={{ paddingBottom: 16 }}>
            <Typography
              variant="Small"
              category="Default"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Last updated", {
                date: updateDate && getDateTime(updateDate),
              })}
            </Typography>
          </div>
        </>
      )}
      {Menu === 0 && (
        <div>
          <TimeFirmwarePanel onClose={() => setMenu(undefined)} />
        </div>
      )}
      {Menu === 1 && (
        <div>
          <VideoFirmwarePanel onClose={() => setMenu(undefined)} />
        </div>
      )}
      {Menu === 2 && (
        <div>
          <div className={classes.ArrowBack} onClick={() => setMenu(undefined)}>
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("Recording")}
            </Typography>
          </div>
          <RecordingFirmwarePanel />
        </div>
      )}
      {Menu === 3 && (
        <div>
          <div className={classes.ArrowBack} onClick={() => setMenu(undefined)}>
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("G-sensor")}
            </Typography>
          </div>
          <GsensorFirmwarePanel />
        </div>
      )}
      {Menu === 4 && (
        <div>
          <div className={classes.ArrowBack} onClick={() => setMenu(undefined)}>
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("Motion detection")}
            </Typography>
          </div>
          <MotionFirmwarePanel />
        </div>
      )}
      {Menu === 5 && (
        <div>
          <div className={classes.ArrowBack} onClick={() => setMenu(undefined)}>
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("System")}
            </Typography>
          </div>
          <SystemFirmwarePanel />
        </div>
      )}
      {Menu === 6 && (
        <div>
          <div className={classes.ArrowBack} onClick={() => setMenu(undefined)}>
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("Wi-Fi")}
            </Typography>
          </div>
          <WifiFirmwarePanel />
        </div>
      )}
      {Menu === 7 && (
        <div>
          <div className={classes.ArrowBack} onClick={() => setMenu(undefined)}>
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("Cloud")}
            </Typography>
          </div>
          <CloudFirmwarePanel />
        </div>
      )}
      {Menu === 8 && (
        <div>
          <div className={classes.ArrowBack} onClick={() => setMenu(undefined)}>
            <IconButton className={classes.ArrowBackPd}>
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
            <Typography category="Default" variant="H6">
              {t("Firmware language")}
            </Typography>
          </div>
          <LanguageFirmwarePanel />
        </div>
      )}
      {Menu === 9 && (
        <div>
          <div
            className={classes.ArrowBack}
            style={{ justifyContent: "space-between" }}
            onClick={() => setMenu(undefined)}
          >
            <div style={{ alignItems: "center", display: "flex" }}>
              <IconButton className={classes.ArrowBackPd}>
                <ArrowBackIcon></ArrowBackIcon>
              </IconButton>
              <Typography category="Default" variant="H6">
                {t("DMS")}
              </Typography>
            </div>

            <Tooltip
              widthLarge
              title={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography category="Default" variant="CaptionBold">
                    {t("DMS Setting")}
                  </Typography>
                  <br />

                  <Typography
                    category="Default"
                    variant="CaptionBold"
                    dangerouslySetInnerHTML={{
                      __html: t("Every events of DMC200_"),
                    }}
                  />
                  <br />

                  <Typography category="Default" variant="CaptionBold">
                    {t("Driver detection box")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    dangerouslySetInnerHTML={{
                      __html: t("The DMS system has_"),
                    }}
                  />
                  <br />

                  <Typography category="Default" variant="CaptionBold">
                    {t("DMS detection")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    dangerouslySetInnerHTML={{
                      __html: t("There are detection criteria for_"),
                    }}
                  />
                  <br />

                  <Typography category="Default" variant="CaptionBold">
                    {t("DMS camera recording_")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    dangerouslySetInnerHTML={{
                      __html: t("DMS camera recording_onoff"),
                    }}
                  />
                  <br />

                  <Typography category="Default" variant="CaptionBold">
                    {t("LED")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    dangerouslySetInnerHTML={{
                      __html: t("LED lighting for each event_"),
                    }}
                  />
                  <br />

                  <Typography category="Default" variant="CaptionBold">
                    {t("Sensitivity")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    dangerouslySetInnerHTML={{
                      __html: t("Drowsy and Distracted each have_"),
                    }}
                  />
                </div>
              }
              placement="right"
            >
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </div>
          <FirmwareDMSPanel />
        </div>
      )}
    </>
  );
};
