const Languages: { [key: string]: string } = {
  en: "English",
  id: "Bahasa Indonesia",
  de: "Deutsch",
  es: "Español",
  fr: "Français",
  it: "Italiano",
  nl: "Nederlands",
  nb: "Norsk",
  pl: "Polski",
  fi: "Suomi",
  vi: "Tiếng Việt",
  ru: "Русский",
  ar: "اﻟﻌﺮﺑﻴﺔ",
  th: "ไทย",
  ko: "한국어",
  sc: "中文 (简体)",
  ja: "日本語",
};

export const MobileLang: { [key: string]: string } = {
  en: "eng",
  id: "ind",
  de: "deu",
  es: "lad",
  fr: "fra",
  it: "ita",
  nl: "nld",
  nb: "nor",
  pl: "pol",
  fi: "fin",
  vi: "vol",
  ru: "rus",
  ar: "ara",
  th: "tha",
  ko: "kor",
  sc: "zho",
  ja: "jpn",
};

export const cardLanguages: { [key: string]: string } = {
  en: "en",
  id: "id",
  de: "de",
  es: "es",
  fr: "fr",
  it: "it",
  nl: "nl",
  nb: "nb",
  pl: "pl",
  fi: "fi",
  vi: "vi",
  ru: "ru",
  ar: "ar",
  th: "th",
  ko: "ko",
  sc: "zh",
  ja: "ja",
};

export const FirmwareLanguages: { [key: string]: string } = {
  English: "English",
  Idn: "Bahasa Indonesia",
  German: "Deutsch",
  Spain: "Español",
  French: "Français",
  Italian: "Italiano",
  Dutch: "Nederlands",
  Norwegian: "Norsk",
  Polish: "Polski",
  Finnish: "Suomi",
  Vietnam: "Tiếng Việt",
  Russian: "Русский",
  Arabic: "اﻟﻌﺮﺑﻴﺔ",
  Thai: "ไทย",
  Korea: "한국어",
  China: "中文 (简体)",
  Japan: "日本語",
};

export default Languages;
