import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { LightColors, Modal, Typography } from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Webviewer } from "../contants/Breakpoints";
import {
  CAMERA,
  deleteCamera,
  ICameraInfo,
  loadFirmwareInfos,
  rebootCamera,
  renameCamera,
  updateCameras,
} from "../features/Camera/slice";
import { loadSubscription } from "../features/Payment/slice";
import { RootState } from "../features/store";
import { CameraAddScreen } from "../screens/CameraAddScreen";
import { CameraPlaybackScreen } from "../screens/CameraPlaybackScreen";
import { CameraFirmwareUpdateScreen } from "../screens/CameraRemoteFirmwareUpdateScreen";
import { CameraScreen } from "../screens/CameraScreen";
import { DMC200FirmwareUpdateScreen } from "../screens/DMC200FirmwareUpdateScreen";
export const CameraRouter = (props: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { path } = useRouteMatch();
  const psnMatch = useRouteMatch<{ psn: string }>(`${path}/:psn`);
  const liveViewMatch = useRouteMatch<{ psn: string }>(
    `${path}/:psn/live-view`
  );
  const gpsTrackingMatch = useRouteMatch<{ psn: string }>(
    `${path}/:psn/gps-tracking`
  );
  const settingsMatch = useRouteMatch<{ psn: string }>(`${path}/:psn/settings`);
  const dmsReportMatch = useRouteMatch<{ psn: string }>(`${path}/:psn/report`);

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { loading, type, cameraList } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRebootCamera, setOpenRebootCamera] = useState(false);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [camera, setCamera] = useState<ICameraInfo>();
  const [camName, setCamName] = useState("");
  const [camNameError, setCamNameError] = useState(false);
  const [camNameLengthErr, setCamNameLengthErr] = useState(false);

  useEffect(() => {
    function tick() {
      dispatch(updateCameras());
    }

    let id = setInterval(tick, 30 * 1000);
    return () => clearInterval(id);
  }, [dispatch]);

  useEffect(() => {
    if (cameraList && psnMatch) {
      const found = _.find(
        cameraList?.DeviceListInfo,
        (dev) => dev.device.psn === psnMatch.params.psn
      )?.device;
      setCamera(found);
      if (psnMatch.params.psn !== "add-camera") {
        if (!found) {
          history.replace("/cameras");
        }
      }
    }
  }, [cameraList, history, psnMatch, psnMatch?.params.psn]);

  useEffect(() => {
    dispatch(loadFirmwareInfos());
    dispatch(loadSubscription());
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      setOpenDeleteModal(false);
      setOpenRenameModal(false);
      setOpenRebootCamera(false);
    }
  }, [loading]);

  const handleCamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // if (value.length <= 30) {
    setCamName(value);
    // }
  };

  const handleRename = useCallback(
    (cam: ICameraInfo) => {
      setCurrentCam(cam);
      if (location.pathname === "/cameras") {
        setCamName(cam.dev_name);
      } else {
        camera && setCamName(camera?.dev_name);
      }
      setOpenRenameModal(true);
    },
    [camera, location.pathname]
  );

  const handleDelete = useCallback((cam: ICameraInfo) => {
    setCurrentCam(cam);
    setOpenDeleteModal(true);
  }, []);

  const handleRebootCamera = useCallback((cam: ICameraInfo) => {
    setCurrentCam(cam);
    setOpenRebootCamera(true);
  }, []);

  const renderScreen = () => {
    if (liveViewMatch && camera?.active === "on") {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="live-view"
          psn={liveViewMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (gpsTrackingMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="gps-tracking"
          psn={gpsTrackingMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (settingsMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="settings"
          psn={settingsMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (dmsReportMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="report"
          psn={dmsReportMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
    if (psnMatch) {
      return (
        <CameraPlaybackScreen
          {...props}
          mode="playback"
          psn={psnMatch.params.psn}
          onRename={handleRename}
          onDelete={handleDelete}
          onRebootCamera={handleRebootCamera}
        />
      );
    }
  };

  const renderCameraScreen = useCallback(() => {
    return (
      <CameraScreen
        {...props}
        onRename={handleRename}
        onDelete={handleDelete}
        onRebootCamera={handleRebootCamera}
      />
    );
  }, [props, handleRename, handleDelete, handleRebootCamera]);

  return (
    <>
      <Switch>
        <Route exact path={path} render={renderCameraScreen} />
        <Route path={`${path}/add-camera`} component={CameraAddScreen} />
        <Route path={`${path}/:psn/playback`} render={renderScreen} />
        <Route path={`${path}/:psn/live-view`} render={renderScreen} />
        <Route path={`${path}/:psn/gps-tracking`} render={renderScreen} />
        <Route path={`${path}/:psn/settings`} render={renderScreen} />
        <Route path={`${path}/:psn/report`} render={renderScreen} />
        <Route
          path={`${path}/:psn/fota`}
          render={() => (
            <CameraFirmwareUpdateScreen {...props} psn={psnMatch?.params.psn} />
          )}
        />
        <Route
          path={`${path}/:psn/fota-dmc200`}
          render={() => (
            <DMC200FirmwareUpdateScreen {...props} psn={psnMatch?.params.psn} />
          )}
        />
      </Switch>
      <Modal
        open={openRenameModal}
        mobile={mobile}
        onClose={() => {
          setCamNameError(false);
          setOpenRenameModal(false);
          setCamNameLengthErr(false);
        }}
        onClickNegative={() => {
          setCamNameError(false);
          setOpenRenameModal(false);
          setCamNameLengthErr(false);
        }}
        onClickPositive={() => {
          if (camName.indexOf("\\") > -1 || camName.indexOf('"') > -1) {
            setCamNameError(true);
            setCamNameLengthErr(false);
          } else if (camName.match(/\s/g)?.length === camName.length) {
            setCamNameError(false);
            setCamNameLengthErr(true);
          } else if (camName.trim().length < 5 || camName.trim().length > 32) {
            setCamNameError(false);
            setCamNameLengthErr(true);
          } else {
            setCamNameError(false);
            setCamNameLengthErr(false);
            currentCam &&
              dispatch(
                renameCamera({ psn: currentCam.psn, devName: camName.trim() })
              );
          }
        }}
        heading="Rename"
        close
        loading={loading && type === renameCamera.type}
        content={
          <div style={{ paddingTop: 8 }}>
            <Input
              name="camName"
              style={{ paddingBottom: 3 }}
              onChange={handleCamNameChange}
              value={camName}
              autoFocus
              error={camNameError || camNameLengthErr}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "94%",
                  display: "flex",
                  justifyContent:
                    camNameError || camNameLengthErr
                      ? "space-between"
                      : "flex-end",
                }}
              >
                {camNameError && (
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.secondary["11"]}
                  >
                    {t("Unallowed character detected")}
                  </Typography>
                )}
                {camNameLengthErr && (
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.secondary["11"]}
                  >
                    {t("Camera name must_")}
                  </Typography>
                )}
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["2"]}
                >
                  {camName.length}/32
                </Typography>
              </div>
            </div>
          </div>
        }
        LButton="Cancel"
        RButton="Rename"
        RButtonDisabled={camName.length === 0}
      />
      <Modal
        open={openDeleteModal}
        mobile={mobile}
        onClose={() => setOpenDeleteModal(false)}
        onClickNegative={() => setOpenDeleteModal(false)}
        onClickPositive={() =>
          currentCam && dispatch(deleteCamera(currentCam.psn))
        }
        heading={t("Delete")}
        close
        loading={loading && type === deleteCamera.type}
        content={`Are you sure you want to delete this camera?<br/>
        <b>Warning: You can’t undo this action. All your cloud files will be lost.<b>`}
        LButton="Cancel"
        RButton="Delete"
        Secondary
      />
      <Modal
        open={openRebootCamera}
        mobile={mobile}
        onClose={() => setOpenRebootCamera(false)}
        onClickNegative={() => setOpenRebootCamera(false)}
        onClickPositive={() => currentCam && dispatch(rebootCamera(currentCam))}
        heading={t("Reboot Camera")}
        close
        loading={loading && type === rebootCamera.type}
        content={t("Camera will be rebooted_")}
        LButton={t("No")}
        RButton={t("Yes")}
      />
    </>
  );
};
