import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Theme,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import {
  Button,
  Container,
  LightColors,
  Modal,
  Sliders,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import _ from "lodash";

import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import * as Price from "../contants/Price";
import { APPSTORE, GOOGLEPLAY } from "../contants/Links";
import { useHistory } from "react-router-dom";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { getFreetrialState, PAYMENT } from "../features/Payment/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { getPlanFromServiceID } from "../utils/Service";
import { MaintenanceHandler } from "./MaintenanceHandler";

// const breakpoint = 834;
const breakpoint = 944;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 56,
    [theme.breakpoints.up(breakpoint)]: {
      marginTop: 58,
    },
    backgroundColor: LightColors.primary["0"],
  },
  rootModal: {
    marginTop: 54,
  },
  rootModalMobile: {
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    marginTop: 54,
  },
  body: {
    padding: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(7.625)}px ${theme.spacing(4)}px`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalBody: {
    padding: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.up("md")]: {
      padding: `0px 9px`,
    },
  },
  bodyText: {
    maxWidth: 906,
    textAlign: "center",
  },
  marginT2: {
    marginTop: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginB26: {
    marginBottom: theme.spacing(3) + 2,
  },
  marginB5: {
    marginBottom: theme.spacing(5),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  pricingAcc: {
    display: "flex",
    width: "100%",
    marginTop: theme.spacing(5),
    flexDirection: "column",
    [theme.breakpoints.up(breakpoint)]: {
      flexDirection: "row",
    },
  },
  modalPricingAcc: {
    marginTop: theme.spacing(4),
  },
  accordionRoot: {
    margin: 0,
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      marginRight: 0,
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
    "&.Mui-expanded": {
      marginTop: 0,
      marginBottom: 0,
      "&:last-child": {
        marginRight: 0,
      },
    },
    "&:before": {
      height: 0,
    },
    [theme.breakpoints.up(breakpoint)]: {
      width: "33.33%",
      borderRadius: 4,
      marginRight: theme.spacing(4),
      boxShadow:
        "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",

      "&.Mui-expanded": {
        marginRight: theme.spacing(4),
      },
    },
  },
  accordionSummaryRoot: {
    padding: "24px 16px",
    minHeight: 0,
    "&.Mui-expanded": {
      padding: "23px 16px 16px 16px",
      minHeight: 0,
    },
    [theme.breakpoints.up(breakpoint)]: {
      padding: "24px 24px 16px 24px",
      "&.Mui-expanded": {
        padding: "24px 24px 16px 24px",
      },
    },
  },
  accordionSummaryContent: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryexpandIcon: {
    padding: 3,
    paddingRight: 12,
    "&.Mui-expanded": {
      padding: 3,
      paddingLeft: 12,
    },
  },
  accordionDetailsRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "0 16px 16px 16px",
    [theme.breakpoints.up(breakpoint)]: {
      padding: "0 24px 24px 24px",
    },
  },
  featureDiv: {
    marginTop: 14,
  },
  feature: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  featureTline: {
    display: "flex",
    justifyContent: "space-between",
  },
  descDiv: {
    marginLeft: theme.spacing(4),
  },
  checkIcon: {
    marginRight: theme.spacing(1),
  },
  appAnchor: { display: "flex" },
  appStoreDiv: {
    display: "flex",
  },
  compareDiv: {
    width: "100%",
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up(breakpoint)]: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(0),
    },
  },
  tableRoot: {
    borderRadius: 4,
    border: `1px solid ${LightColors.primary["6"]}`,
    borderCollapse: "separate",
  },
  compareTline: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  compareTitleTline: {
    verticalAlign: "top",
  },
  compareFeatureTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  compareCheckDiv: {
    display: "flex",
    justifyContent: "center",
  },
  compareCheckIcon: {
    display: "block",
  },
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  infoIcon: {
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },
  calcDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "column",

    [theme.breakpoints.up(breakpoint)]: {
      flexDirection: "row",
    },
  },
  calculator: {
    flex: 1.43,
    padding: theme.spacing(3, 2),
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    [theme.breakpoints.up(breakpoint)]: {
      padding: theme.spacing(13.25, 8),
      borderRadius: theme.spacing(0.5, 0, 0, 0.5),
    },
  },
  camNumDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(breakpoint)]: {
      flexDirection: "row",
      marginBottom: theme.spacing(4),
    },
  },
  camNumTitle: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(breakpoint)]: {
      marginBottom: theme.spacing(0),
    },
  },
  camNumInput: {
    [theme.breakpoints.up(breakpoint)]: {
      minWidth: 173,
      maxWidth: 173,
    },
  },
  calcResultDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: LightColors.primary["10"],
    border: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(3, 2),
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    [theme.breakpoints.up(breakpoint)]: {
      padding: theme.spacing(4, 8),
      borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    },
  },
  calcArrowDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: LightColors.primary["7"],
    marginTop: theme.spacing(3),
    "&:hover": {
      color: LightColors.primary["8"],
    },
    cursor: "pointer",
  },
  modal: {
    marginTop: theme.spacing(4),
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
}));

const TableCell = withStyles((theme: Theme) => ({
  body: {
    border: 0,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    borderRight: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      backgroundColor: LightColors.primary["10"],
    },
  },
  head: {
    padding: theme.spacing(2),
    paddingBottom: 13,
    border: 0,
  },
}))(MuiTableCell);

const TableRow = withStyles((theme: Theme) => ({
  root: {
    "&:first-child > td": {
      paddingTop: theme.spacing(3),
    },
    "&:last-child > td": {
      paddingBottom: theme.spacing(3),
    },
  },
}))(MuiTableRow);

interface PricingProps {
  modal?: boolean;
  onClose: () => void;
}

export const Pricing = ({ modal, onClose }: PricingProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const calcDivRef = useRef<HTMLDivElement>(null);

  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const mobile = useMediaQuery(theme.breakpoints.down(breakpoint));
  const category = matches ? "Large" : "Default";

  const [expanded, setExpanded] = React.useState<string | false>("all");
  const [camCnt, setCamCnt] = useState<number>(1);
  const [planName, setPlanName] = useState<string>();
  const [maintenancing, setMaintenancing] = useState(false);
  const [showMaintModal, setShowMaintModal] = useState(false);

  const { subscriptionInfo, freetrial } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);
  const [openExceptionModal, setOpenExceptionModal] = useState(false);

  useEffect(() => {
    if (mobile) {
      setExpanded("free");
    } else {
      setExpanded("all");
    }
  }, [mobile]);

  useEffect(() => {
    dispatch(getFreetrialState());
  }, [dispatch]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleFleetBtn = useCallback(() => {
    if (maintenancing) {
      setShowMaintModal(true);
      return;
    }
    if (modal && (planName === "Smart plan" || planName === "Basic plan")) {
      setOpenExceptionModal(true);
    } else if (modal) {
      onClose();
      history.push("/billing");
    } else {
      history.push("/signup", { mode: "fleet" });
    }
  }, [history, maintenancing, modal, onClose, planName]);

  const handleMaintenance = useCallback(() => {
    setMaintenancing(true);
  }, []);

  const maintHandler = useMemo(() => {
    return (
      <MaintenanceHandler
        disableModal
        forceShow={showMaintModal}
        onCloseModal={() => {
          console.log("onCloseModal");
          setShowMaintModal(false);
        }}
        onMaintenance={handleMaintenance}
      />
    );
  }, [handleMaintenance, showMaintModal]);

  const handleTrial = useCallback(
    (mode: string, cameraCnt?: number) => {
      if (maintenancing) {
        setShowMaintModal(true);
      } else {
        history.push("/signup", { mode, cameraCnt });
      }
    },
    [history, maintenancing]
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootModal]: modal,
        [classes.rootModalMobile]: modal && mobile,
      })}
      dir={theme.direction}
    >
      <Container className={clsx(classes.body, modal && classes.modalBody)}>
        <div className={classes.titleDiv}>
          <Typography
            category={modal ? "Default" : category}
            variant="H1"
            className={clsx(
              classes.bodyText,
              modal && classes.marginB2,
              !mobile && classes.marginB26,
              mobile && classes.marginB2
            )}
          >
            {t("Pricing")}
          </Typography>

          {modal ? (
            <>
              <Typography
                category="Default"
                variant="BodyBold"
                className={classes.bodyText}
              >
                {subscriptionInfo &&
                  getPlanFromServiceID(subscriptionInfo?.servicePlanID) ===
                    "Smart plan" &&
                  t("You are subscribed_free_Smart")}
                {subscriptionInfo &&
                  getPlanFromServiceID(subscriptionInfo?.servicePlanID) !==
                    "Smart plan" &&
                  t("You are subscribed_free")}
                {/* {t("You are subscribed_free")} */}
              </Typography>
              <Typography
                category="Default"
                variant="Body"
                className={classes.bodyText}
              >
                {t("With Alert Push_")}
              </Typography>
            </>
          ) : (
            <Typography
              category="Default"
              variant="Body"
              className={classes.bodyText}
            >
              {t("Get started now_")}
            </Typography>
          )}
          {!modal && (
            <div
              className={classes.calcArrowDiv}
              onClick={() => {
                //@ts-ignore
                window.location = "/pricing#calculator";
                if (calcDivRef.current) {
                  window.scrollTo(0, calcDivRef.current.offsetTop);
                }
              }}
            >
              <Typography category="Default" variant="BodyBold">
                {t("Calculate your Fleet_")}
              </Typography>
              <ArrowDownwardIcon />
            </div>
          )}
        </div>

        <div className={clsx(classes.pricingAcc, { [classes.modal]: modal })}>
          <Accordion
            classes={{
              root: classes.accordionRoot,
            }}
            expanded={_.includes(["all", "free"], expanded)}
            elevation={0}
            square={mobile}
            onChange={mobile ? handleChange("free") : undefined}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryexpandIcon,
              }}
              expandIcon={mobile && <ExpandMoreIcon />}
              aria-controls="free-content"
              id="free-header"
            >
              <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                {t("Free")}
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              classes={{
                root: clsx(classes.accordionDetailsRoot),
              }}
            >
              <Typography category="Default" variant="Small">
                {t("Get started for_")}
              </Typography>
              <Typography
                category="Default"
                variant={mobile ? "H2" : "H1"}
                style={{ marginTop: mobile ? 24 : 45 }}
              >
                0 USD
              </Typography>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Free forever")}
              </Typography>
              {modal ? (
                <Button
                  fullWidth
                  color="primary"
                  style={{ marginTop: theme.spacing(2) }}
                  onClick={() => history.push("/signup", { mode: "free" })}
                  disabled={modal}
                >
                  {/* {t("Subscribed")} */}
                  {subscriptionInfo &&
                  getPlanFromServiceID(subscriptionInfo?.servicePlanID) ===
                    "Smart plan"
                    ? t("subscribe")
                    : t("Subscribed")}
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="primary"
                  style={{ marginTop: theme.spacing(2) }}
                  onClick={() => handleTrial("free")}
                  // disabled={modal}
                >
                  {t("Get started")}
                </Button>
              )}
              <Typography
                category="Default"
                variant="Body"
                style={{
                  marginTop: mobile ? theme.spacing(3) : theme.spacing(6),
                }}
              >
                {t("Features included:")}
              </Typography>

              <div className={classes.featureDiv}>
                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Camera registration")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Live view")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Remote video playback_")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Cloud Storage")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Push Notifications")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Two-way voice_")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Live GPS")}
                  </Typography>
                </div>

                <div style={{ marginBottom: theme.spacing(2) - 2 }}>
                  <div className={classes.featureTline}>
                    <div
                      className={classes.feature}
                      style={{ marginBottom: 0 }}
                    >
                      <CheckIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <Typography category="Default" variant="Body">
                        {t("Event live auto_")}
                      </Typography>
                    </div>
                    <Tooltip
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("When an Event_")}
                        </Typography>
                      }
                      placement="right"
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={classes.descDiv}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Compatible with_")}
                    </Typography>
                  </div>
                </div>

                <div style={{ marginBottom: theme.spacing(2) - 2 }}>
                  <div className={classes.featureTline}>
                    <div
                      className={classes.feature}
                      style={{ marginBottom: 0 }}
                    >
                      <CheckIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <Typography category="Default" variant="Body">
                        {t("Event file auto_")}
                      </Typography>
                    </div>
                    <Tooltip
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("Event File Auto-upload_")}
                        </Typography>
                      }
                      placement="right"
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={classes.descDiv}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Not compatible with_")}
                    </Typography>
                  </div>
                </div>

                <div style={{ marginBottom: theme.spacing(2) - 6 }}>
                  <div className={classes.featureTline}>
                    <div
                      className={classes.feature}
                      style={{ marginBottom: 0 }}
                    >
                      <CheckIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <Typography category="Default" variant="Body">
                        {t("Live event upload")}
                      </Typography>
                    </div>
                    <Tooltip
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("When an event_")}
                        </Typography>
                      }
                      placement="right"
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={classes.descDiv}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Requires latest firmware_")}
                    </Typography>
                  </div>
                </div>

                <div className={classes.featureTline}>
                  <div className={classes.feature} style={{ marginBottom: 0 }}>
                    <CheckIcon color="primary" className={classes.checkIcon} />
                    <Typography category="Default" variant="Body">
                      {t("Reports")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.descDiv}>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {t("Not compatible with_")}
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            classes={{
              root: classes.accordionRoot,
            }}
            elevation={0}
            expanded={_.includes(["all", "smart"], expanded)}
            square={mobile}
            onChange={mobile ? handleChange("smart") : undefined}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryexpandIcon,
              }}
              expandIcon={mobile && <ExpandMoreIcon />}
              aria-controls="smart-content"
              id="smart-header"
            >
              <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                {t("Smart")}
              </Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
              <Typography category="Default" variant="Small">
                {t("Get unlimited Remote_")}
              </Typography>
              <Typography category="Default" variant="SmallBold">
                {t("In-app subscription only")}
              </Typography>
              <div style={{ marginTop: mobile ? 24 : 45 }}>
                <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                  {Price.SMART} USD
                </Typography>
                <Typography category="Default" variant="Small">
                  /mo
                </Typography>
              </div>

              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {/* +{Price.SMART_EXTRA} USD for extra camera */}
                {t("+9 USD for extra camera")}
              </Typography>
              <div className={clsx(classes.appStoreDiv, classes.marginT2)}>
                <a
                  href={APPSTORE}
                  className={classes.appAnchor}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: theme.spacing(2) }}
                >
                  <img
                    srcSet="/images/appstore-badge.png 1x, /images/appstore-badge@2x.png 2x"
                    alt="download on the app store"
                  />
                </a>
                <a
                  href={GOOGLEPLAY}
                  className={classes.appAnchor}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    srcSet="/images/google-play-badge.png 1x, /images/google-play-badge@2x.png 2x"
                    alt="get it on google play"
                  />
                </a>
              </div>
              <Typography
                category="Default"
                variant="Body"
                style={{
                  marginTop: mobile ? theme.spacing(3) : theme.spacing(6),
                }}
                dangerouslySetInnerHTML={{ __html: t("All the Free_") }}
              ></Typography>

              <div className={classes.featureDiv}>
                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("More cameras")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Unlimited Live view")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Unlimited remote video_")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("More cloud storage")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("GPS tracking")}
                  </Typography>
                </div>

                <div style={{ marginBottom: theme.spacing(2) - 1 }}>
                  <div className={classes.featureTline}>
                    <div
                      className={classes.feature}
                      style={{ marginBottom: 0 }}
                    >
                      <CheckIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <Typography category="Default" variant="Body">
                        {t("Longer storage period_live")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.descDiv}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Compatible with_")}
                    </Typography>
                  </div>
                </div>

                <div style={{ marginBottom: theme.spacing(2) - 2 }}>
                  <div className={classes.featureTline}>
                    <div
                      className={classes.feature}
                      style={{ marginBottom: 0 }}
                    >
                      <CheckIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <Typography category="Default" variant="Body">
                        {t("Longer storage period_file")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.descDiv}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Not compatible with_")}
                    </Typography>
                  </div>
                </div>
                <div style={{ marginBottom: theme.spacing(1) }}>
                  <div className={classes.featureTline}>
                    <div
                      className={classes.feature}
                      style={{ marginBottom: 0 }}
                    >
                      <CheckIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <Typography category="Default" variant="Body">
                        {t("Longer storage period_event")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.descDiv}>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Requires latest firmware_")}
                    </Typography>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            classes={{
              root: classes.accordionRoot,
            }}
            expanded={_.includes(["all", "fleet"], expanded)}
            elevation={0}
            square={mobile}
            onChange={mobile ? handleChange("fleet") : undefined}
            style={{ backgroundColor: LightColors.primary["10"] }}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionSummaryexpandIcon,
              }}
              expandIcon={mobile && <ExpandMoreIcon />}
              aria-controls="fleet-content"
              id="fleet-header"
            >
              <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                {t("Fleet")}
              </Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
              <Typography category="Default" variant="Small">
                {t("BlackVue Fleet Tracking_")}
              </Typography>
              <Typography category="Default" variant="SmallBold">
                {t("Web subscription only")}
              </Typography>
              <div style={{ marginTop: 24 }}>
                <Typography category="Default" variant={mobile ? "H2" : "H1"}>
                  {Price.FLEET_PRICE} USD
                </Typography>
                <Typography category="Default" variant="Small">
                  /mo
                </Typography>
              </div>

              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {/* +{Price.FLEET_EXTRA} USD for extra camera */}
                {t("+15 USD for extra camera")}
              </Typography>
              <Button
                fullWidth
                color="primary"
                style={{ marginTop: theme.spacing(2) }}
                onClick={handleFleetBtn}
              >
                {(subscriptionInfo?.servicePlanID === 1 ||
                  planName === "Smart plan" ||
                  planName === "Basic plan") &&
                freetrial?.trialUsed
                  ? t("subscribe")
                  : t("Try 1 month free")}
              </Button>
              <Typography
                category="Default"
                variant="Body"
                style={{
                  marginTop: mobile ? theme.spacing(3) : theme.spacing(6),
                }}
                dangerouslySetInnerHTML={{ __html: t("All the Smart_") }}
              ></Typography>

              <div className={classes.featureDiv}>
                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("More cameras")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("More users")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Roles and permissions")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("More cloud storage")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Email notifications")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Multi Live view (web/desktop)")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Longer GPS Tracking")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Video on a map")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Event live auto upload_")}
                  </Typography>
                </div>

                <div className={classes.feature}>
                  <CheckIcon color="primary" className={classes.checkIcon} />
                  <Typography category="Default" variant="Body">
                    {t("Geofence setup (web/desktop)_")}
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {!mobile && (
          <div className={classes.compareDiv}>
            <Typography
              category="Default"
              variant="H1"
              className={clsx(classes.bodyText, classes.marginB3)}
            >
              {t("How our plans compare")}
            </Typography>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "24.42%" }}></TableCell>
                  <TableCell align="center" style={{ width: "25.58%" }}>
                    <Typography category="Default" variant="H6">
                      {t("Free")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "25.58%" }}>
                    <Typography category="Default" variant="H6">
                      {t("Smart")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "24.35%" }}>
                    <Typography category="Default" variant="H6">
                      {t("Fleet")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table classes={{ root: classes.tableRoot }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: "24.42%" }}>
                    <Typography category="Default" variant="Body">
                      {t("Cameras per account")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "25.58%" }}>
                    <Typography category="Default" variant="Body">
                      1
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "25.58%" }}>
                    <Typography category="Default" variant="Body">
                      3
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ width: "24.35%" }}>
                    <Typography category="Default" variant="Body">
                      999
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Users per account")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      1
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      1
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("Owner")}: 1
                    </Typography>
                    <br />
                    <Typography category="Default" variant="Body">
                      {t("Admin")}: 10
                    </Typography>
                    <br />
                    <Typography category="Default" variant="Body">
                      {t("Driver")}: 3 {t("per camera")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Live view per day")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      10 {t("minutes")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Remote video playback and download per month")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <Typography category="Default" variant="Body">
                      100 {t("times")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <Typography category="Default" variant="Body">
                      {t("Unlimited")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Cloud storage")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("GB", { a: 5 })}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("GB", { a: 5 })} {t("per camera")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("GB", { a: 15 })} {t("per camera")}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Push Notifications")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Two-way voice_")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Live GPS")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Email notifications")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Multi Live view (web/desktop)")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("GPS tracking")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("days", { a: 7 })}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography category="Default" variant="Body">
                      {t("days", { a: 90 })}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Video on a map")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography category="Default" variant="Body">
                      {t("Geofence setup (web/desktop) and alerts")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell
                    align="center"
                    className={classes.compareTitleTline}
                  >
                    <div className={classes.compareCheckDiv}>
                      <CheckIcon
                        color="primary"
                        className={classes.compareCheckIcon}
                      />
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div
                      className={classes.compareFeatureTitle}
                      style={{ marginBottom: 16 }}
                    >
                      <Typography category="Default" variant="Body">
                        {t("Event live auto_")}
                      </Typography>
                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("When an Event_")}
                          </Typography>
                        }
                        placement="right"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 7 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("DR650S Series only")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 30 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("DR650S Series only")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 30 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("All Cloud dashcams except DR650GW Series")}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div
                      className={classes.compareFeatureTitle}
                      style={{ marginBottom: 16 }}
                    >
                      <Typography category="Default" variant="Body">
                        {t("Event file auto_")}
                      </Typography>
                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("Event File Auto-upload_")}
                          </Typography>
                        }
                        placement="right"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 7 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                        style={{ whiteSpace: mobile ? "nowrap" : "normal" }}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 30 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 30 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <div className={classes.featureTline}>
                      <div
                        className={classes.feature}
                        style={{ marginBottom: 0 }}
                      >
                        <Typography category="Default" variant="Body">
                          {t("Live event upload")}
                        </Typography>
                      </div>
                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("When an event_")}
                          </Typography>
                        }
                        placement="right"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </div>
                    <div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Requires latest firmware")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 7 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 30 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <Typography
                        category="Default"
                        variant="Body"
                        style={{ marginBottom: 2 }}
                      >
                        {t("days", { a: 30 })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.compareTitleTline}>
                    <Typography category="Default" variant="Body">
                      {t("Reports")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{ paddingLeft: 6 }}>
                    <div className={classes.compareTline}>
                      <div className={classes.compareCheckDiv}>
                        <CheckIcon
                          color="primary"
                          className={classes.compareCheckIcon}
                        />
                      </div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                        style={{ marginTop: 4 }}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center" style={{ paddingLeft: 6 }}>
                    <div className={classes.compareTline}>
                      <div className={classes.compareCheckDiv}>
                        <CheckIcon
                          color="primary"
                          className={classes.compareCheckIcon}
                        />
                      </div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                        style={{ marginTop: 4 }}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.compareTline}>
                      <div className={classes.compareCheckDiv}>
                        <CheckIcon
                          color="primary"
                          className={classes.compareCheckIcon}
                        />
                      </div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                        style={{ marginTop: 4 }}
                      >
                        {t("Not compatible with_")}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <Button
                      fullWidth
                      color="primary"
                      onClick={() => handleTrial("free")}
                      disabled={modal}
                    >
                      {modal
                        ? subscriptionInfo &&
                          getPlanFromServiceID(
                            subscriptionInfo?.servicePlanID
                          ) === "Smart plan"
                          ? t("subscribe")
                          : t("Subscribed")
                        : t("Get started")}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <div
                      className={clsx(
                        classes.appStoreDiv,
                        classes.compareCheckDiv
                      )}
                    >
                      <a
                        href={APPSTORE}
                        className={classes.appAnchor}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginRight: theme.spacing(2) }}
                      >
                        <img
                          srcSet="/images/appstore-badge.png 1x, /images/appstore-badge@2x.png 2x"
                          alt="download on the app store"
                        />
                      </a>
                      <a
                        href={GOOGLEPLAY}
                        className={classes.appAnchor}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          srcSet="/images/google-play-badge.png 1x, /images/google-play-badge@2x.png 2x"
                          alt="get it on google play"
                        />
                      </a>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Button fullWidth color="primary" onClick={handleFleetBtn}>
                      {(subscriptionInfo?.servicePlanID === 1 ||
                        planName === "Smart plan" ||
                        planName === "Basic plan") &&
                      freetrial?.trialUsed
                        ? t("subscribe")
                        : t("Try 1 month free")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
        {!modal && (
          <div className={classes.compareDiv} ref={calcDivRef} id="calculator">
            <Typography
              category="Default"
              variant="H1"
              className={clsx(classes.bodyText, {
                [classes.marginB5]: !mobile,
                [classes.marginB2]: mobile,
              })}
            >
              {t("Calculate your Fleet_")}
            </Typography>
            <div className={classes.calcDiv}>
              <div className={classes.calculator}>
                <div className={classes.camNumDiv}>
                  <Typography
                    category="Default"
                    variant={mobile ? "H2" : "H1"}
                    className={classes.camNumTitle}
                  >
                    {t("Number of cameras")}
                  </Typography>
                  <div className={classes.camNumInput}>
                    <Input
                      type="numeric"
                      count
                      pattern="[0-9]*"
                      value={camCnt}
                      inputmode="numeric"
                      onPlus={() =>
                        setCamCnt((c) => Math.min(c + 1, Price.MAX_CAM_COUNT))
                      }
                      onMinus={() => setCamCnt((c) => Math.max(c - 1, 1))}
                      onChange={(event) => {
                        if (event.target.value === "") {
                          setCamCnt(1);
                        } else {
                          const value = Number.parseInt(event.target.value);
                          if (value <= Price.MAX_CAM_COUNT && value > 0) {
                            setCamCnt(value);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Sliders
                    value={camCnt}
                    variant="large"
                    step={1}
                    min={1}
                    max={Price.MAX_CAM_COUNT}
                    onChange={(event, value) => setCamCnt(value as number)}
                    marks={[
                      {
                        value: 1,
                        label: "1",
                      },
                      {
                        value: 999,
                        label: "999",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={classes.calcResultDiv}>
                <Typography
                  category="Default"
                  variant={mobile ? "H2" : "H1"}
                  style={{
                    marginBottom: mobile ? theme.spacing(3) : theme.spacing(4),
                  }}
                >
                  {t("Fleet")}
                </Typography>
                <Typography
                  category="Default"
                  variant="H6"
                  style={{ marginBottom: theme.spacing(0.5) }}
                >
                  {t("Monthly price")}
                </Typography>
                <div
                  style={{
                    marginBottom: mobile ? theme.spacing(3) : theme.spacing(4),
                  }}
                >
                  <Typography category="Default" variant="H1">
                    {(
                      Price.FLEET_PRICE +
                      (camCnt - 1) * Price.FLEET_EXTRA
                    ).toFixed(2)}{" "}
                    USD
                  </Typography>
                  <Typography category="Default" variant="Small">
                    /mo
                  </Typography>
                </div>

                <Typography
                  category="Default"
                  variant="H6"
                  style={{
                    marginBottom: mobile ? theme.spacing(3) : theme.spacing(4),
                  }}
                >
                  {t("Available cloud storage: 15 GB per camera")}
                </Typography>

                <Button
                  fullWidth
                  color="primary"
                  style={{ marginBottom: theme.spacing(2) }}
                  onClick={() => handleTrial("fleet", camCnt)}
                >
                  {t("Try 1 month free")}
                </Button>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["2"]}
                  style={{ textAlign: "center" }}
                >
                  {t(
                    "Includes unlimited Live View, Remote video playback, and download."
                  )}
                </Typography>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={openExceptionModal}
          close
          onClose={() => setOpenExceptionModal(false)}
          onClickPositive={() => {
            setOpenExceptionModal(false);
          }}
          heading={t("Cancel your inapp_")}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: t("You have cancel_").replace(
                  // eslint-disable-next-line no-control-regex
                  new RegExp("\n", "g"),
                  "<br/>"
                ),
              }}
            />
          }
          RButton={t("OK")}
          actionClassName={classes.modalBottom}
        />
      </Container>
      {maintHandler}
    </div>
  );
};
