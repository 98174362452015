import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alerts,
  LightColors,
  LocationType,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Webviewer } from "../../contants/Breakpoints";
import {
  CAMERA,
  ICameraInfo,
  loadMyCemrasLocation,
  TabNameInfo1,
} from "../../features/Camera/slice";
import { ILatestEvent } from "../../features/Event/slice";
import { ITrackData } from "../../features/GPS/slice";
import { RootState } from "../../features/store";
import { IVOD, loadUrgentVODToken } from "../../features/VOD/slice";
import { IGPSLocation } from "../../types";
import { LocationMap } from "../maps/LocationMap";
import { VideoPlayer } from "./VideoPlayer";

interface VideoModalProps {
  open: boolean;
  camera: ICameraInfo;
  mode: number; // 0: playback, 1: cloud, 2: live event upload
  data?: ITrackData;
  event?: ILatestEvent;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
  },
  mapDiv: {
    height: 259,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: 444,
    },
    position: "relative",
    marginTop: theme.spacing(3),
  },
  contentDiv: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 18.75),
    },
  },
  modalTitle: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 3.75),
    },
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },
  alert: {
    transition: theme.transitions.create("height"),
    margin: theme.spacing(0, 0, 1, 0),
  },
  errorClose: {
    height: 0,
  },
}));

export const VideoPlayerModal = (props: VideoModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, camera, mode, data, event, onClose } = props;

  const dispatch = useDispatch();

  const { firmware, cameraList } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const videoSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const [cam, setCam] = useState<ICameraInfo>();
  const [locations, setLocations] = useState<IGPSLocation[]>([]);
  const [location, setLocation] = useState<IGPSLocation>();
  const [timestamp, setTimestamp] = useState<number>();
  const [playVOD, setPlayVOD] = useState<IVOD>();
  const [error, setError] = useState(false);

  const changeVOD = useCallback(
    (
      filename: string,
      direction: LocationType,
      rid_front?: string,
      rid_rear?: string,
      rid_3?: string
    ) => {
      const timeStrings = filename.split("_");
      const time = moment(
        `${timeStrings[0]} ${timeStrings[1]}`,
        "YYYYMMDD hhmmss"
      );

      dispatch(
        loadUrgentVODToken({
          psn: camera.psn,
          filename: filename,
        })
      );
      let rid = rid_front;
      if (direction === "Rear") {
        rid = rid_rear;
      } else if (direction === "Interior") {
        rid = rid_3;
      }

      setPlayVOD({
        filename: filename,
        lowFilename: filename,
        rid,
        lowRid: rid,
        direction,
        event: "Event",
        time,
        hasLow: true,
        hasFront: !!rid_front,
        hasRear: !!rid_rear,
        hasInterior: !!rid_3,
      });
    },
    [dispatch, camera]
  );

  useEffect(() => {
    if (event) {
      const { event_file } = event;
      if (event_file) {
        const fnames = _.split(event_file, ".");
        const names = _.split(fnames[0], "_");
        let evt = _.last(names);
        if (evt && evt.length === 2) {
          evt = evt + "S";
        }
        const fname =
          _.join([..._.slice(names, 0, names.length - 1), evt], "_") +
          "." +
          fnames[1];
        changeVOD(fname, "Front");
      }
    } else if (data) {
      const { sub_front, rid_front, rid_rear, rid_3 } = data;
      const isAI = data.mtype >= 6 && data.mtype <= 12;
      if (isAI && sub_front && rid_3) {
        changeVOD(sub_front, "Interior", rid_front, rid_rear, rid_3);
      } else if (sub_front) {
        if (rid_front) {
          changeVOD(sub_front, "Front", rid_front, rid_rear, rid_3);
        } else if (rid_rear) {
          changeVOD(sub_front, "Rear", rid_front, rid_rear, rid_3);
        } else if (rid_3) {
          changeVOD(sub_front, "Interior", rid_front, rid_rear, rid_3);
        }
      }
    } else {
      setPlayVOD(undefined);
    }
  }, [changeVOD, camera, data, event]);

  useEffect(() => {
    if (cam && cam.latitude && cam.longitude) {
      setLocation({
        lat: parseFloat(cam.latitude),
        lng: parseFloat(cam.longitude),
        name: cam.dev_name,
        mode: "0",
      } as IGPSLocation);
    } else {
      const cancel = new AbortController();
      dispatch(loadMyCemrasLocation({ cancel }));
      return () => {
        cancel.abort();
      };
    }
  }, [cam, dispatch]);

  useEffect(() => {
    const cm = _.chain(cameraList?.DeviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === camera?.psn)
      .value();
    setCam((c) => {
      if (c?.psn !== cm?.psn) {
        return cm;
      } else if (c?.active !== cm?.active) {
        return cm;
      } else if (
        (!c?.latitude && cm?.latitude) ||
        (!c?.longitude && cm?.latitude)
      ) {
        return cm;
      }
      return c;
    });
  }, [camera, cameraList?.DeviceListInfo]);

  useEffect(() => {
    if (timestamp) {
      const indx = Math.min(Math.floor(timestamp), locations.length);
      if (locations[indx]) {
        setLocation(locations[indx]);
      }
    }
  }, [timestamp, locations]);

  const playFrontVOD = useCallback(() => {
    if (data) {
      const { sub_front, rid_front, rid_rear, rid_3 } = data;
      if (sub_front) {
        changeVOD(sub_front, "Front", rid_front, rid_rear, rid_3);
      }
    }
  }, [changeVOD, data]);

  const playRearVOD = useCallback(() => {
    if (data) {
      const { sub_rear, rid_front, rid_rear, rid_3 } = data;
      if (sub_rear) {
        changeVOD(sub_rear, "Rear", rid_front, rid_rear, rid_3);
      }
    }
  }, [changeVOD, data]);

  const playInteriorVOD = useCallback(() => {
    if (data) {
      const { sub_front, rid_front, rid_rear, rid_3 } = data;
      if (sub_front) {
        changeVOD(sub_front, "Interior", rid_front, rid_rear, rid_3);
      }
    }
  }, [changeVOD, data]);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  const videoPlayer = useMemo(() => {
    return (
      <VideoPlayer
        mode={mode}
        quality="low"
        camera={camera}
        vod={playVOD}
        singleVideo
        noTheater
        fullHeight
        onUpdateGPS={(loc) => {
          setLocations(loc);
        }}
        onUpdateTime={(time) => setTimestamp(time)}
        onRequestFront={() => playFrontVOD()}
        onRequestRear={() => playRearVOD()}
        onRequestInterior={() => playInteriorVOD()}
        onError={handleError}
      />
    );
  }, [
    mode,
    camera,
    playVOD,
    handleError,
    playFrontVOD,
    playRearVOD,
    playInteriorVOD,
  ]);

  return (
    <Modal
      className={classes.root}
      contentClassName={classes.contentDiv}
      heading={`${camera.dev_name} (${camera.model})`}
      titleClassName={classes.modalTitle}
      open={open}
      onClose={onClose}
      content={
        <div>
          {error && (
            <Alerts
              mode="web"
              severity="error"
              className={clsx(classes.alert, {
                [classes.errorClose]: !error,
              })}
            >
              {t("An error occurred_again")}
            </Alerts>
          )}
          {videoPlayer}
          <div className={classes.mapDiv}>
            <LocationMap
              location={
                videoSettings?.["UseGpsInfo"] === "0" ? undefined : location
              }
              showHeading
              trackMarker={!!location}
              fullscreenIcon
              noMarker={!timestamp}
            />
            {playVOD && videoSettings?.["UseGpsInfo"] === "0" && (
              <div className={classes.loadingDiv}>
                <Typography
                  category="Default"
                  variant="BodyBold"
                  htmlColor={LightColors.primary["0"]}
                >
                  {t("GPS Location recording off")}
                </Typography>
              </div>
            )}
          </div>
        </div>
      }
      playVOD
      close
    />
  );
};
