import React, { useCallback, useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  CheckBox,
  LightColors,
  Modal,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { Trans, useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addCamera, CAMERA, clearCameraError } from "../features/Camera/slice";
import { RootState } from "../features/store";
import { useHistory, useLocation } from "react-router-dom";
import { Webviewer } from "../contants/Breakpoints";
import { Divider, FormHelperText, useMediaQuery } from "@material-ui/core";
import { HELPCENTER } from "../contants/Links";
import { APPSTORE, GOOGLEPLAY } from "../contants/Links";
import clsx from "clsx";
import _ from "lodash";
import { UnableAddCameraPSN4 } from "../contants/Models";
import { AddCameraHeader } from "../components/AddCameraHeader";
import { MiniAddCameraHeader } from "../components/MiniAddCameraHeader";
import { MinimalFooter } from "../components/MinimalFooter";

const schema = yup.object().shape({
  psn: yup.string().min(1).required(),
  cldn: yup.string().length(14).required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(7),
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2.125, 2, 3),
    maxWidth: 437,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 437,
      marginTop: theme.spacing(19.25),
      marginBottom: theme.spacing(19.25),
      padding: 0,
    },
  },
  topTitle: {
    marginBottom: theme.spacing(3) - 1,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3) - 2,
    },
  },
  topSmallTitle: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(1) - 2,
    },
  },
  allow: {
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.75) }
      : { marginLeft: theme.spacing(0.75) }),
    marginBottom: theme.spacing(2.625),
  },
  checkBoxWrap: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1), marginRight: theme.spacing(-1.375) }
      : { marginRight: theme.spacing(1.25) }),
  },
  checkBox: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(0.375) }
      : { marginLeft: 0 }),
  },
  btnDiv: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
  },
  addBtn: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(2) }
        : { marginRight: theme.spacing(2) }),
    },
  },
  modalContent: {
    paddingTop: theme.spacing(2.5) - 1,
    paddingBottom: theme.spacing(3),
  },
  box: {
    padding: theme.spacing(2, 2, 3),
    backgroundColor: `${LightColors.primary["1"]}08`,
    display: "flex",
    flexDirection: "column",
    // opacity: 0.03,
  },
  dividerDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(4, 0, 4.25),
  },
  orDiv: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    backgroundColor: LightColors.primary["0"],
    color: LightColors.primary["2"],
    zIndex: 2,
  },
  divider: {
    backgroundColor: LightColors.primary["6"],
    width: "100%",
    top: "50%",
    transform: "translate(0, -50%);",
  },
  appStoreDiv: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: -5,
    },
  },
  appAnchor: { display: "flex" },
  MR16: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  MR8: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(1) }
        : { marginRight: theme.spacing(1) }),
    },
  },
  cntDiv: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "3px 16px 26px 16px",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginTop: "4px",
    },
  },
  mobileCntDiv: {
    margin: "3px 16px 33px 16px",
  },
  cloudCodeInput: {
    margin: "3px 16px 13px 16px",
  },
  errStyle: {
    display: "flex",
    alignItems: "center",
  },
}));
export const CameraSimAddScreen = () => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const location = useLocation<{ mode?: "map" | "list" }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobile = !useMediaQuery(theme.breakpoints.up(Webviewer.mobile));

  const { loading, type, cameraError } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const [psn, setPsn] = useState<string>("");
  const [cldn, setCldn] = useState<string>("");
  const [allowGPS, setAllowGPS] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [psnError, setPsnError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      const { mode } = location.state;
      if (history.action === "POP") {
        history.replace(history.location.pathname, { mode });
      }
    };
  }, [history, location.state]);

  useEffect(() => {
    setDisableBtn(!schema.isValidSync({ psn, cldn }));
  }, [psn, cldn]);

  useEffect(() => {
    dispatch(clearCameraError());
  }, [dispatch]);

  useEffect(() => {
    if (type === addCamera.type && !loading) {
      setOpenModal(false);
    }
  }, [loading, type]);

  const getErrorStringPsn = () => {
    if (cameraError === "BC_ERR_DUPLICATED") {
      return t("This camera is already_");
    } else if (cameraError === "BC_ERR_INVALID_DATA") {
      return t("Incorrect serial number");
    }
  };

  const getErrorStringCldn = () => {
    if (cameraError === "BC_ERR_INVALID_DATA") {
      return t("Incorrect cloud code");
    }
  };

  const checkPsn = useCallback(() => {
    if (psn.startsWith("DR7L")) {
      setPsnError(true);
    }
  }, [psn]);

  const handleAddCamera = useCallback(() => {
    if (psn.startsWith("DR7L")) {
      setOpenModal(true);
      return;
    }
    dispatch(addCamera({ psn, cldn, agree_gps: allowGPS ? "on" : "off" }));
  }, [allowGPS, cldn, dispatch, psn]);

  const handleBtnDisabled = useCallback(() => {
    if (
      disableBtn ||
      psn.length !== 14 ||
      cldn.length !== 14 ||
      _.includes(UnableAddCameraPSN4, psn.substr(0, 4))
    ) {
      return true;
    } else {
      return false;
    }
  }, [cldn.length, disableBtn, psn]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <AddCameraHeader />
      {mobile && <MiniAddCameraHeader />}
      <div className={classes.body}>
        <div>
          <div className={classes.topTitle}>
            <Typography category="Default" variant="H4">
              {t("Add camera in the app")}{" "}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("(Recommended)")}
            </Typography>
          </div>
          <div className={classes.box}>
            <Typography
              category="Default"
              variant="BodyBold"
              className={classes.topSmallTitle}
            >
              {t("Seamless pairing")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              style={{ marginBottom: theme.spacing(4) }}
            >
              {t("To get most_")}
            </Typography>
            <div className={classes.appStoreDiv}>
              <a
                href={APPSTORE}
                className={clsx(classes.appAnchor, classes.MR16)}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  srcSet="/images/appstore-badge.png 1x, /images/appstore-badge@2x.png 2x"
                  alt="download on the app store"
                />
              </a>
              <a
                href={GOOGLEPLAY}
                className={clsx(classes.appAnchor, classes.MR8)}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  srcSet="/images/google-play-badge.png 1x, /images/google-play-badge@2x.png 2x"
                  alt="get it on google play"
                />
              </a>
            </div>
          </div>
          <div className={classes.dividerDiv}>
            <div className={classes.orDiv}>
              <Typography category="Default" variant="Small">
                {t("OR")}
              </Typography>
            </div>
            <Divider absolute className={classes.divider} />
          </div>
        </div>

        <Typography category="Default" variant="H4">
          {t("Add camera manually")}
        </Typography>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
          style={{ marginBottom: theme.spacing(3) }}
        >
          ({t("Recommended for BlackVue_")})
        </Typography>

        <Typography
          category="Default"
          variant="Small"
          style={{ marginBottom: theme.spacing(4) }}
        >
          {t("Serial number and_")}
        </Typography>
        <Input
          label={`${t("Serial number")} (S/N)`}
          value={psn}
          onChange={(e) => setPsn(e.target.value)}
          error={
            cameraError === "BC_ERR_DUPLICATED" ||
            cameraError === "BC_ERR_INVALID_DATA" ||
            psn.length > 14
          }
          // helperText={getErrorStringPsn()}
          autoFocus
        />
        <div
          className={clsx(classes.cntDiv, mobile && classes.mobileCntDiv)}
          style={{ justifyContent: cameraError && "space-between" }}
        >
          {cameraError && (
            <FormHelperText
              style={{
                color: LightColors.secondary["11"],
                marginTop: 0,
              }}
            >
              {getErrorStringPsn()}
            </FormHelperText>
          )}

          <Typography
            category="Default"
            variant="Caption"
            htmlColor={
              psn.length > 14
                ? LightColors.secondary["11"]
                : LightColors.primary["2"]
            }
            className={classes.errStyle}
          >
            {psn.length}/14
          </Typography>
        </div>

        <Input
          label={t("Cloud code")}
          value={cldn}
          onChange={(e) => setCldn(e.target.value)}
          error={cameraError === "BC_ERR_INVALID_DATA" || cldn.length > 14}
          // helperText={getErrorStringCldn()}
        />
        <div
          className={clsx(classes.cntDiv, classes.cloudCodeInput)}
          style={{ justifyContent: cameraError && "space-between" }}
        >
          {cameraError && (
            <FormHelperText
              style={{
                color: LightColors.secondary["11"],
                marginTop: 0,
              }}
            >
              {getErrorStringCldn()}
            </FormHelperText>
          )}

          <Typography
            category="Default"
            variant="Caption"
            htmlColor={
              cldn.length > 14
                ? LightColors.secondary["11"]
                : LightColors.primary["2"]
            }
            className={classes.errStyle}
          >
            {cldn.length}/14
          </Typography>
        </div>

        <div className={classes.allow}>
          <FormControlLabel
            className={classes.checkBoxWrap}
            control={
              <CheckBox
                className={classes.checkBox}
                color="primary"
                checked={allowGPS}
                onChange={(e) => setAllowGPS(e.target.checked)}
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Allow GPS access")}
              </Typography>
            }
          />
          <Tooltip
            title={
              <Typography category="Default" variant="Caption">
                {t("Used to access_")}
              </Typography>
            }
          >
            <InfoIcon />
          </Tooltip>
        </div>
        <div className={classes.btnDiv}>
          <Button
            variant="contained"
            color="primary"
            className={classes.addBtn}
            disabled={handleBtnDisabled()}
            loading={psnError ? false : loading}
            fullWidth={mobile}
            onClick={() => {
              checkPsn();
              handleAddCamera();
            }}
          >
            {t("Add camera")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth={mobile}
            onClick={() => history.goBack()}
          >
            {t("Cancel")}
          </Button>
        </div>
        <Modal
          btnCenterMobile={mobile}
          contentClassName={classes.modalContent}
          open={openModal}
          mobile={mobile}
          loading={loading}
          onClickPositive={() => {
            dispatch(
              addCamera({ psn, cldn, agree_gps: allowGPS ? "on" : "off" })
            );
          }}
          heading={t("Did you insert_")}
          content={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="Body" category="Default">
                {t("To use the_")}
              </Typography>
              <Typography variant="Body" category="Default">
                <Trans
                  t={t}
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={HELPCENTER}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: LightColors.primary["7"] }}
                      />
                    ),
                  }}
                >
                  Click here for_
                </Trans>
              </Typography>
            </div>
          }
          RButton={t("OK")}
        />
      </div>
      {mobile && <MinimalFooter />}
    </div>
  );
};
