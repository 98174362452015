export const TOP_LEFT = 1;
export const TOP_RIGHT = 3;
export const BOTTOM_RIGHT = 12;

export const GOOGLE_API_KEY = "AIzaSyDcwxzVw9djOpNbFz0FEKGqwWPL7YsNLTE";

export const MOBILE_BOUNDS_PADDING = {
  bottom: 50,
  left: 50,
  right: 50,
  top: 50,
};
export const PC_BOUNDS_PADDING = {
  bottom: 250,
  left: 400,
  right: 100,
  top: 250,
};

export const LocationMarker = `data:image/svg+xml;utf-8,<svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(%23filter0_ddd)">
<circle cx="15" cy="21" r="11.5" fill="%230095E0" stroke="white"/>
<g clip-path="url(%23clip0)">
<path d="M11.4688 23.0938C12.6979 22.5938 13.875 22.3438 15 22.3438C16.125 22.3438 17.2917 22.5938 18.5 23.0938C19.7292 23.5729 20.3438 24.2083 20.3438 25V26.3438H9.65625V25C9.65625 24.2083 10.2604 23.5729 11.4688 23.0938ZM16.875 20.2188C16.3542 20.7396 15.7292 21 15 21C14.2708 21 13.6458 20.7396 13.125 20.2188C12.6042 19.6979 12.3438 19.0729 12.3438 18.3438C12.3438 17.6146 12.6042 16.9896 13.125 16.4688C13.6458 15.9271 14.2708 15.6562 15 15.6562C15.7292 15.6562 16.3542 15.9271 16.875 16.4688C17.3958 16.9896 17.6562 17.6146 17.6562 18.3438C17.6562 19.0729 17.3958 19.6979 16.875 20.2188Z" fill="white"/>
</g>
<rect x="12.5" y="4.5" width="15" height="9" rx="2.5" fill="%2313131C" stroke="white"/>
<circle cx="18" cy="9" r="1.5" fill="%2313131C" stroke="white"/>
<circle cx="26" cy="6" r="2.5" fill="%23D81A26" stroke="white"/>
</g>
<defs>
<filter id="filter0_ddd" x="0" y="0" width="32" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="1.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
<feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
<feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
</filter>
<clipPath id="clip0">
<rect width="24" height="24" fill="white" transform="translate(3 9)"/>
</clipPath>
</defs>
</svg>
`;
