import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  LightColors,
  Sliders,
  Typography,
} from "@thingsw/pitta-design-system";

import { Webviewer } from "../../contants/Breakpoints";
import {
  CAMERA,
  loadFirmwareSettings,
  TabNameInfo2,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    borderRadius: 4,
    marginBottom: 32,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 384,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  boderNoneBoxSpace: {
    width: "100%",
    minWidth: 288,
    minHeight: 82,
    display: "flex",
    padding: "0 16px",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 48,
      maxWidth: 672,
    },
  },
  boderNoneBox: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { padding: "0 15px 0 2px" }
      : { padding: "0 2px 0 15px" }),
  },

  mgNone: {
    margin: 0,
  },
  buttonMargin: {
    marginBottom: 16,
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  wid224: {
    minWidth: 250,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 280,
      ...(theme.direction === "rtl"
        ? { paddingRight: 22 }
        : { paddingLeft: 22 }),
    },
  },
  sliderText: {
    ...(theme.direction === "rtl" ? { marginRight: 25 } : { marginLeft: 25 }),
  },
}));

export const GsensorFirmwarePanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { camera, firmware, firmwareConfig } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [current, setCurrent] = useState<TabNameInfo2>();
  const [disabledChange, setDisabledChange] = useState(true);

  const settings = useMemo(() => {
    return firmwareConfig?.Gsensor;
  }, [firmwareConfig?.Gsensor]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings());
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab2"
      );
      if (currentTab) {
        setCurrent(currentTab.section_info as TabNameInfo2);
      }
    }
  }, [firmware]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab2"
      );

      const predicts = _.keys(currentTab?.section_info as TabNameInfo2).map(
        (k) => {
          const key = k as keyof TabNameInfo2;
          return (
            (currentTab?.section_info as TabNameInfo2)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [current, firmware]);

  const handleUpdate = () => {
    if (current && camera && firmware) {
      console.log(firmware);
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab2"
        ),
      };
      updated.cloud_settings = [
        ...updated.cloud_settings,
        { section_info: current, section_name: "Tab2" },
      ];
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  return (
    <>
      <div>
        <div className={classes.bigBox}>
          {settings?.["Normal"] && (
            <>
              <div className={classes.boderNoneBox}>
                <Typography category="Default" variant="BodyBold">
                  {t("Normal")}
                </Typography>
              </div>
              <div className={classes.boderNoneBoxSpace}>
                <div style={{ minWidth: 110 }}>
                  <Typography category="Default" variant="Body">
                    {t("Up and down")}
                  </Typography>
                </div>
                <div className={classes.wid224} style={{ paddingLeft: -10 }}>
                  <Sliders
                    style={{ width: 224 }}
                    max={10}
                    value={parseInt(current?.NORMALSENSOR1 ?? "0")}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            NORMALSENSOR1: v.toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.NORMALSENSOR1)}
                  </Typography>
                </div>
              </div>
              <div className={classes.boderNoneBoxSpace}>
                <div>
                  <Typography category="Default" variant="Body">
                    {t("Side to side")}
                  </Typography>
                </div>
                <div className={classes.wid224}>
                  <Sliders
                    max={10}
                    value={parseInt(current?.NORMALSENSOR2 ?? "0")}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            NORMALSENSOR2: v.toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.NORMALSENSOR2)}
                  </Typography>
                </div>
              </div>
              <div
                className={classes.boderNoneBoxSpace}
                style={{
                  borderBottom: `1px solid ${LightColors.primary["6"]}`,
                }}
              >
                <div>
                  <Typography category="Default" variant="Body">
                    {t("Front and back")}
                  </Typography>
                </div>
                <div className={classes.wid224}>
                  <Sliders
                    max={10}
                    value={parseInt(current?.NORMALSENSOR3 ?? "0")}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            NORMALSENSOR3: v.toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.NORMALSENSOR3)}
                  </Typography>
                </div>
              </div>
            </>
          )}

          {settings?.["Parking"] && (
            <>
              <div className={classes.boderNoneBox}>
                <Typography category="Default" variant="BodyBold">
                  {t("Parking")}
                </Typography>
              </div>
              <div className={classes.boderNoneBoxSpace}>
                <div>
                  <Typography category="Default" variant="Body">
                    {t("Up and down")}
                  </Typography>
                </div>
                <div className={classes.wid224}>
                  <Sliders
                    max={10}
                    value={parseInt(current?.PARKINGSENSOR1 ?? "0")}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            PARKINGSENSOR1: v.toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.PARKINGSENSOR1)}
                  </Typography>
                </div>
              </div>
              <div className={classes.boderNoneBoxSpace}>
                <div>
                  <Typography category="Default" variant="Body">
                    {t("Side to side")}
                  </Typography>
                </div>
                <div className={classes.wid224}>
                  <Sliders
                    max={10}
                    value={parseInt(current?.PARKINGSENSOR2 ?? "0")}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            PARKINGSENSOR2: v.toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.PARKINGSENSOR2)}
                  </Typography>
                </div>
              </div>
              <div className={classes.boderNoneBoxSpace}>
                <div>
                  <Typography category="Default" variant="Body">
                    {t("Front and back")}
                  </Typography>
                </div>
                <div className={classes.wid224}>
                  <Sliders
                    max={10}
                    value={parseInt(current?.PARKINGSENSOR3 ?? "0")}
                    onChange={(e, value) => {
                      const v = value as number;
                      setCurrent((c) => {
                        return (
                          c && {
                            ...c,
                            PARKINGSENSOR3: v.toString(),
                          }
                        );
                      });
                    }}
                  ></Sliders>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.sliderText}
                  >
                    {current && parseInt(current.PARKINGSENSOR3)}
                  </Typography>
                </div>
              </div>
            </>
          )}
        </div>
        <div>
          <Button
            disabled={disabledChange}
            variant="contained"
            color="primary"
            className={classes.buttonSize}
            onClick={handleUpdate}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </>
  );
};
