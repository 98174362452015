import React, { ChangeEvent, useCallback, useState } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
} from "redux-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ISignupForm } from "../types";
import validator from "./validator";
import { FormControlLabel, FormHelperText } from "@material-ui/core";
import {
  Button,
  CheckBox,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { USER } from "../features/User/slice";
import { TFunction } from "i18next";
import { RootState } from "../features/store";
import { Webviewer } from "../contants/Breakpoints";
import {
  InformationWe,
  Privacy_Policy,
  TermsConditions,
  TermsOfLocation,
} from "../contants/Links";
import { ERROR } from "../features/Error/slice";

interface FieldProps {
  label?: string;
  helperText?: string;
  value?: string;
  t: TFunction;
  error?: string;
}

const schema = yup.object().shape({
  passwd: yup.string().required("Enter password").min(6, "more than 6_"),
});

const renderEmailField = ({
  label,
  input,
  helperText,
  error,
  meta: { touched, invalid, error: metaError },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && (!!error || !!metaError)}
      helperText={(touched ? t(error ?? metaError) : undefined) || helperText}
      {...input}
      {...custom}
    />
  );
};
const CheckboxFormControlLabel = withStyles({
  root: {
    marginLeft: -5,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    padding: "3px 0 0 2px",
  },
})(FormControlLabel);

const renderCheckField = ({
  label,
  input,
  meta: { touched, invalid, error },
  t,
}: WrappedFieldProps & FieldProps) => {
  return (
    <div>
      <CheckboxFormControlLabel
        control={
          <CheckBox
            name={input.name}
            color="primary"
            value={input.value}
            onChange={input.onChange}
            checked={input.value ? true : false}
          />
        }
        label={label}
      />
      {touched && error && (
        <FormHelperText
          style={{ color: LightColors.secondary["11"], marginLeft: 28 }}
        >
          {touched && t(error)}
        </FormHelperText>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inputDiv: {
    marginBottom: theme.spacing(3),
  },
  pwDiv: {
    marginBottom: theme.spacing(2.375),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  formControlLabelRoot: {
    marginLeft: -5,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  formControlLabel: {
    padding: "3px 0 0 2px",
  },
  agreeDiv: {
    display: "flex",
    flexDirection: "column",
  },
  signUpBtn: {
    marginBottom: theme.spacing(2.25),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  tosDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(0.75),
    },
  },
}));

const Signup = (props: InjectedFormProps<ISignupForm, {}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, initialValues } = props;
  const { t } = useTranslation();
  const { loading } = useSelector((state: RootState) => state[USER]);
  const { error } = useSelector((state: RootState) => state[ERROR]);

  const handleSignup = () => {
    dispatch(submit("SignupForm"));
  };

  const [input, setInput] = useState({
    passwd: "",
  });

  const { passwd } = input;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleCheckLength = useCallback(() => {
    const checkSym = /[!@#$%^&*]/;
    const checkLetter = /[a-zA-Z]/;
    const checkNum = /[0-9]/;
    if (passwd.length === 0) {
      return "";
    } else if (
      passwd.length >= 6 &&
      ((checkSym.test(passwd) && checkLetter.test(passwd)) ||
        (checkSym.test(passwd) && checkNum.test(passwd)) ||
        (checkLetter.test(passwd) && checkNum.test(passwd)))
    ) {
      return "success";
    } else if (passwd.length <= 5) {
      return "fail";
    } else {
      return "warning";
    }
  }, [passwd]);

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("SignupForm"));
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleEnterkey}>
      <div className={classes.inputDiv}>
        <Field
          name="firstName"
          label={t("First name")}
          component={renderEmailField}
          autoFocus
          t={t}
        />
      </div>
      <div className={classes.inputDiv}>
        <Field
          name="lastName"
          label={t("Last name")}
          component={renderEmailField}
          t={t}
        />
      </div>
      <div className={classes.inputDiv}>
        <Field
          type="email"
          name="email"
          label={t("Email")}
          component={renderEmailField}
          readOnly={initialValues.email}
          t={t}
          error={error && t(error)}
        />
      </div>
      <div className={classes.pwDiv}>
        <Field
          name="passwd"
          onChange={handleChange}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 32);
          }}
          value={passwd}
          label={t("Create a password")}
          password={true}
          createPW={true}
          mode={handleCheckLength()}
          helperText={t("6 characters with_")}
          component={renderEmailField}
          t={t}
        />
      </div>
      <Button
        fullWidth
        color="primary"
        onClick={handleSignup}
        loading={loading}
        className={classes.signUpBtn}
      >
        {t("Sign up with email")}
      </Button>

      <div className={classes.agreeDiv}>
        <div className={classes.tosDiv}>
          <Field
            name="tos"
            value="terms"
            label={
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("I agree to the_")}{" "}
                <a
                  href={TermsConditions}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Terms of service")}
                </a>
                ,{" "}
                <a
                  href={Privacy_Policy}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Privacy policy")}
                </a>
                ,{" "}
                <a
                  href={InformationWe}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Information We Collect_")}
                </a>{" "}
                {t("And")}{" "}
                <a
                  href={TermsOfLocation}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: LightColors.primary["7"] }}
                >
                  {t("Terms of Location-Based_")}
                </a>
              </Typography>
            }
            component={renderCheckField}
            t={t}
          />
        </div>

        <Field
          name="subscribe"
          value="Subscribe"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Subscribe to newsletter")}
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />
      </div>
    </form>
  );
};

const SignupForm = reduxForm<ISignupForm, {}>({
  form: "SignupForm",
  asyncValidate: validator(schema),
  persistentSubmitErrors: true,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Signup);

export default SignupForm;
