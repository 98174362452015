import React, { useEffect } from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@thingsw/pitta-design-system";
import InviteForm from "../forms/InviteForm";
import { IMemberForm } from "../types";
import { invite } from "../features/Member/slice";
import { RootState } from "../features/store";
import { loadUserProfile, USER } from "../features/User/slice";
import { Webviewer } from "../contants/Breakpoints";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import * as yup from "yup";
import { SubmissionError } from "redux-form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "calc(100vh - 56px)",
    marginTop: 56,
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 437,
    flexGrow: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: theme.spacing(16.25),
      padding: 0,
    },
  },
}));

const schema = yup.object().shape({
  guestEmail: yup.string().email("Please enter_email").required("Enter email"),
});

export const InviteMembersScreen = (props: ScreenDefaultProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const email = useSelector((state: RootState) => state[USER].email);
  const user_token = useSelector(
    (state: RootState) => state[USER].loginInfo?.user_token
  );

  useEffect(() => {
    dispatch(loadUserProfile());
  }, [dispatch]);

  const handleInviteSubmit = async (member: IMemberForm) => {
    try {
      await schema.validate(member);
      if (email && user_token) {
        dispatch(
          invite({
            email,
            user_token,
            guestEmail: member.guestEmail,
            userType: member.userType,
          })
        );
      }
    } catch (err) {
      console.log("validate", err.errors, err.path, err.inner);
      throw new SubmissionError({
        [err.path]: err.errors,
      });
    }
  };

  return (
    <div className={classes.root} dir={theme.direction}>
      <div className={classes.formDiv}>
        <Typography category="Default" variant={matches ? "H1" : "H2"}>
          {t("Invite member")}
        </Typography>
        <InviteForm onSubmit={handleInviteSubmit} />
      </div>
    </div>
  );
};
