import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Button,
  LightColors,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Webviewer } from "../contants/Breakpoints";

import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeofencePanel } from "../components/geofence/GeofencePanel";
import { DrawingMode, IGeometry } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeofence,
  GEOFENCE,
  IGeofence,
  updateGeofence,
} from "../features/Geofence/slice";
import _ from "lodash";
import { RootState } from "../features/store";
import { MobileDrawer } from "../components/MobileDrawer";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { GeofenceMap } from "../components/maps/GeofenceMap";
import {
  convertGeofenceToGeometries,
  simulateDblClick,
} from "../utils/GoogleMap";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: (props: ScreenDefaultProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
        : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
    marginTop: (props: ScreenDefaultProps) =>
      props.error ? 68 + 127 : 68 + 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) =>
        props.error ? 68 + 85 : 68 + 56,
    },
  },
  subHeaderDiv: {
    backgroundColor: LightColors.primary["0"],
    top: (props: ScreenDefaultProps) => (props.error ? 127 : 56),
    margin: theme.spacing(0, 2),
    ...(theme.direction === "rtl"
      ? { left: 0, right: 0 }
      : { left: 235, right: 0 }),
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      top: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
      margin: theme.spacing(0, 4),
      ...(theme.direction === "rtl"
        ? { left: 0, right: 235 }
        : { left: 235, right: 0 }),
    },
    // right: 0,
    position: "fixed",
    zIndex: 90,
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  subHeaderDivClosed: {
    left: 73,
  },
  subHeaderDivMobile: {
    left: 0,
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    height: 68,
    backgroundColor: LightColors.primary["0"],
  },
  btnDiv: {
    transition: theme.transitions.create("width"),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: "auto",
    },
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(3) }
      : { marginRight: theme.spacing(3) }),
  },
  addBtn: {
    minWidth: 163,
    margin: theme.spacing(2, 0),
  },
  body: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    overflow: "auto",
  },
  mapDiv: {
    width: "100%",
    height: (props: ScreenDefaultProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
        : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
  },
  searchDiv: {
    width: 244,
    position: "absolute",
    backgroundColor: LightColors.primary["0"],
    top: 16,
    right: 60,
    borderRadius: 4,
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.up(Webviewer.geofenceMobile)]: {
      width: 320,
      right: 76,
    },
  },
  searchIcon: {
    "& svg": {
      fontSize: "18px!important",
      color: LightColors.primary["3"],
    },
  },
  panelDiv: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
    height: "calc(100% - 32px)",
    margin: theme.spacing(2),
  },
}));

interface GeofenceScreenProps {
  mode?: "view" | "add";
}

export const GeofenceScreen = (
  props: GeofenceScreenProps & ScreenDefaultProps
) => {
  const { openMenu, mode } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(
    theme.breakpoints.down(Webviewer.geofenceMobile)
  );

  const { loading, type, geofences } = useSelector(
    (state: RootState) => state[GEOFENCE]
  );

  const mapDivRef = useRef<HTMLDivElement>(null);

  const [drawingMode, setDrawingMode] = useState<DrawingMode>("polygon");
  const [clearMode, setClearMode] = useState(false);
  const [geometry, setGeometry] = useState<IGeometry | null>();
  const [color, setColor] = useState<string>("#F21212");
  const [opacity, setOpacity] = useState(0.5);
  const [undoList, setUndoList] = useState<IGeometry[]>([]);
  const [redoList, setRedoList] = useState<IGeometry[]>([]);
  const [disabledAdd, setDisabledAdd] = useState(true);
  const [selectedFence, setSelectedFence] = useState<IGeofence>();
  const [openDelete, setOpenDelete] = useState(false);
  const [focusedName, setFocusedName] = useState(false);

  const reset = useCallback(() => {
    setGeometry(null);
    setClearMode(true);
    // setCoords(undefined);
    setUndoList([]);
    setRedoList([]);
    setColor("#F21212");
    setOpacity(0.5);
    setDisabledAdd(true);
    setSelectedFence(undefined);
  }, []);

  useEffect(() => {
    if (mode !== "add") {
      reset();
    }
  }, [reset, mode]);

  useEffect(() => {
    if (mode === "add" && geofences.length >= 20) {
      history.goBack();
    }
  }, [geofences.length, history, mode]);

  useEffect(() => {
    if (!loading && type === updateGeofence.type) {
      reset();
    }
  }, [reset, loading, type]);

  useEffect(() => {
    if (clearMode) {
      setClearMode(false);
    }
  }, [clearMode]);

  const handleUndo = useCallback(() => {
    if (undoList.length > 1) {
      const current = undoList.pop();
      const prev = _.last(undoList);
      if (current) {
        setRedoList((r) => [...r, current]);
      }
      if (prev) {
        setGeometry({ ...prev });
      }
    }
  }, [undoList]);

  const handleRedo = useCallback(() => {
    const next = redoList.pop();
    if (next) {
      setGeometry({ ...next });
      setUndoList((u) => [...u, next]);
    }
  }, [redoList]);

  const handleGeometryUpdate = useCallback((object: IGeometry) => {
    setUndoList((u) => [...u, object]);
    setDisabledAdd(false);
    setRedoList([]);
  }, []);

  const handleStartDrawing = useCallback(() => {
    setGeometry(undefined);
  }, []);

  const handleCancel = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (focusedName) {
        return;
      }
      const key = e.key || e.keyCode;
      switch (key) {
        case "Enter":
          if (mode === "add") {
            const { offsetLeft, offsetTop } = mapDivRef.current ?? {
              offsetLeft: 634,
              offsetTop: 348,
            };
            const { clientWidth, clientHeight } = mapDivRef.current ?? {
              clientWidth: 0,
              clientHeight: 0,
            };

            simulateDblClick(
              offsetLeft + clientWidth / 2,
              offsetTop + clientHeight / 2
            );
          }
          break;
        case "Escape":
          handleCancel();
          break;
        case "Backspace":
          handleUndo();
          break;
        case "Delete":
          setOpenDelete(true);
          break;
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [focusedName, handleCancel, handleUndo, mode]);

  const handleChangeDrawingMode = useCallback(
    (m, geofence) => {
      reset();
      setDrawingMode(m);
      if (geofence) {
        if (geofence.polygon) {
          const geom = {
            type: "polygon",
            coords: geofence.polygon,
          } as IGeometry;
          setGeometry(geom);
        } else if (geofence.polyline) {
          const geom = {
            type: "polyline",
            coords: geofence.polyline,
          } as IGeometry;
          setGeometry(geom);
        } else if (geofence.circle) {
          const geom = {
            type: "circle",
            center: {
              lat: geofence.circle[0].lat,
              lng: geofence.circle[0].lng,
            },
            radius: geofence.circle[0].radius,
          } as IGeometry;
          setGeometry(geom);
        } else if (geofence.rectangle) {
          const east = _.chain(geofence.rectangle)
            .map((r) => r.lng)
            .max()
            .value();
          const west = _.chain(geofence.rectangle)
            .map((r) => r.lng)
            .min()
            .value();
          const north = _.chain(geofence.rectangle)
            .map((r) => r.lat)
            .max()
            .value();
          const south = _.chain(geofence.rectangle)
            .map((r) => r.lat)
            .min()
            .value();
          const geom = {
            type: "rectangle",
            bounds: { east, west, north, south },
          } as IGeometry;
          setGeometry(geom);
        }
        setUndoList([]);
        setColor(geofence.setShape.Color);
        setOpacity(geofence.setShape.Transparency);
        // setDisabledAdd(true);
      }
    },
    [reset]
  );

  const geofencePanelMarkup = useMemo(() => {
    return (
      <GeofencePanel
        mode={mode}
        mobile={mobile}
        disabledAdd={disabledAdd}
        drawingMode={drawingMode}
        disableUndo={undoList.length < 2}
        disableRedo={redoList.length === 0}
        disableCancel={disabledAdd}
        selectedFence={selectedFence}
        onChangeColor={(c) => setColor(c)}
        onChangeOpacity={(o) => setOpacity(o)}
        onFocus={(b) => setFocusedName(b)}
        onUndo={handleUndo}
        onRedo={handleRedo}
        onCancel={handleCancel}
        onChangeDrawingMode={handleChangeDrawingMode}
        onSave={({ mode, geofence }) => {
          if (drawingMode === "polygon") {
            const g = _.last(undoList);
            const data = { ...geofence, polygon: g?.coords };
            if (mode === "update") {
              dispatch(updateGeofence(data));
            } else {
              dispatch(addGeofence(data));
            }
          } else if (drawingMode === "polyline") {
            const g = _.last(undoList);
            const data = { ...geofence, polyline: g?.coords };
            if (mode === "update") {
              dispatch(updateGeofence(data));
            } else {
              dispatch(addGeofence(data));
            }
          } else if (drawingMode === "circle") {
            const g = _.last(undoList);
            if (g?.center && g?.radius) {
              const data = {
                ...geofence,
                circle: [
                  {
                    lat: g.center.lat,
                    lng: g.center.lng,
                    radius: g.radius,
                  },
                ],
              } as IGeofence;
              if (mode === "update") {
                dispatch(updateGeofence(data));
              } else {
                dispatch(addGeofence(data));
              }
            }
          } else if (drawingMode === "rectangle") {
            const g = _.last(undoList);
            if (g?.bounds) {
              const data = {
                ...geofence,
                rectangle: [
                  { lat: g.bounds.north, lng: g.bounds.east },
                  { lat: g.bounds.north, lng: g.bounds.west },
                  { lat: g.bounds.south, lng: g.bounds.east },
                  { lat: g.bounds.south, lng: g.bounds.west },
                ],
              } as IGeofence;

              if (mode === "update") {
                dispatch(updateGeofence(data));
              } else {
                dispatch(addGeofence(data));
              }
            }
          }
        }}
      />
    );
  }, [
    disabledAdd,
    dispatch,
    drawingMode,
    handleCancel,
    handleChangeDrawingMode,
    handleRedo,
    handleUndo,
    mobile,
    mode,
    redoList.length,
    selectedFence,
    undoList,
  ]);

  const geometries = useMemo(() => {
    return convertGeofenceToGeometries(geofences);
  }, [geofences]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <div
        className={clsx(classes.subHeaderDiv, {
          [classes.subHeaderDivClosed]: !openMenu,
          [classes.subHeaderDivMobile]: mobile,
        })}
      >
        <div className={classes.subHeader}>
          <div className={clsx(classes.btnDiv)}>
            <Button
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => history.push("/geofences/add-geofence")}
              className={clsx(classes.addBtn)}
              disabled={mode === "add" || geofences.length >= 20}
            >
              {t("Add geofence")}
            </Button>
          </div>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            {geofences.length} / 20
          </Typography>
        </div>
      </div>

      <div className={classes.body}>
        <div className={classes.mapDiv} ref={mapDivRef}>
          <GeofenceMap
            drawingMode={
              (mode === "add" || geometry) && !clearMode
                ? drawingMode
                : undefined
            }
            mode={mode}
            drawingColor={color}
            drawingOpacity={opacity}
            geometry={geometry}
            onUpdateGeometry={handleGeometryUpdate}
            onStartDrawing={handleStartDrawing}
            onSelectGeofence={(fenceId) => {
              setSelectedFence(
                _.find(geofences, (fence) => fence.fenceId === fenceId)
              );
            }}
            geometries={geometry || mode === "add" ? undefined : geometries}
          />
        </div>
        {!mobile && (
          <div className={classes.panelDiv} dir={theme.direction}>
            {geofencePanelMarkup}
          </div>
        )}
        {mobile && (
          <MobileDrawer mode="geofence">{geofencePanelMarkup}</MobileDrawer>
        )}
      </div>
      <Modal
        open={openDelete}
        content={t("Are you sure_delete_zone")}
        heading={t("Delete")}
        onClickAway={() => setOpenDelete(false)}
        onClickNegative={() => setOpenDelete(false)}
        onClickPositive={() => {
          handleCancel();
          setOpenDelete(false);
        }}
        RButton={t("OK")}
        LButton={t("Cancel")}
      />
    </div>
  );
};
