import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Modal } from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React from "react";
import { Pricing } from "../Pricing";

const breakpoint = 834;

const useStyles = makeStyles((theme: Theme) => ({
  modalClose: {
    right: 32,
  },
  modalContent: { padding: 0 },
  webModalContent: { justifyContent: "center" },
}));

interface PricingModalProps {
  open: boolean;
  onClose: () => void;
}

export const PricingModal = ({ open, onClose }: PricingModalProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;

  const mobile = useMediaQuery(theme.breakpoints.down(breakpoint));

  return (
    <Modal
      open={open}
      content={<Pricing modal onClose={onClose} />}
      close
      onClose={onClose}
      fullSize
      closeStyle={classes.modalClose}
      contentClassName={clsx(
        classes.webModalContent,
        mobile && classes.modalContent
      )}
    />
  );
};
