import React, { useState } from "react";
import { LightColors, Modal } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { Webviewer } from "../../contants/Breakpoints";

import {
  CreateTokenCardData,
  loadStripe,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BillingForm from "../../forms/BillingForm";
import { SubmissionError, submit } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import axios from "axios";
import {
  API_GATEWAY_URI,
  STRIPE_API_KEY,
  STRIPE_OS_TYPE,
} from "../../contants/Server";
import { USER } from "../../features/User/slice";
import { RESULT_CODE } from "../../types";
import { openToast } from "../../features/Toast/slice";
import * as yup from "yup";
import _ from "lodash";

interface SubChangeModalProps {
  open: boolean;
  onClose?: () => void;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 439,
    },
  },
  flex: {
    display: "flex",
    paddingBottom: 16,
  },
  cardIcon: {
    padding: 0,
    color: LightColors.primary["3"],
  },
  cardIput: { "&>.MuiOutlinedInput-outlinedInput": { paddingRight: 16 } },
  formControl: {
    width: "100%",
    paddingBottom: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 391,
    },
    "&>.MuiSelect-outlined": {
      padding: "10px 16px",
    },
  },
  maxH: {
    maxHeight: 44,
  },
  state: {
    paddingRight: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingRight: 14.19,
    },
  },
  pdB24: {
    paddingBottom: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 0,
    },
  },
}));
//billInfo
const stripePromise = loadStripe(STRIPE_API_KEY);

const schema = yup.object().shape({
  name: yup.string().trim().required("Enter card name"),
  address_line1: yup.string().trim().required("Enter address"),
  address_city: yup.string().trim().required("Enter the city"),
  address_country: yup
    .object()
    .shape({
      key: yup.string(),
      value: yup.string(),
    })
    .required("Select country")
    .test(
      "notSelect",
      "Select country",
      (item: { key?: string; value?: string }) => {
        return item.key && item.key !== "co";
      }
    ),
});

export const SubChangeModal = ({
  open,
  onClose,
  onClickNegative,
  onClickPositive,
}: SubChangeModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);

  const [loading, setLoading] = useState(false);
  const [stripe, setStripe] = useState<Stripe>();
  const [cardElement, setCardElement] = useState<StripeCardElement>();

  const handleSubmit = async (data: CreateTokenCardData) => {
    try {
      await schema.validate(data, { abortEarly: false });
      if (!stripe || !cardElement) {
        return;
      }

      setLoading(true);

      const { token, error } = await stripe.createToken(cardElement, {
        name: data.name,
        //country
        address_line1: data.address_line1,
        address_city: data.address_city,
        address_state: data.address_state,
        address_zip: data.address_zip,
        //@ts-ignore
        address_country: data.address_country.key,
      });

      if (error) {
        console.log("[error]", error);
        // error.message && handleError(error.message);
      } else if (token) {
        const resp = await axios.post(
          `${API_GATEWAY_URI}/Payment/WebSubscribeCardChange`,
          {
            email,
            user_token: loginInfo?.user_token,
            ostype: STRIPE_OS_TYPE,
            tokenType: "web",
            stripeToken: token.id,
          }
        );

        const { resultcode } = resp.data as {
          resultcode: RESULT_CODE;
          message: string;
        };

        if (resultcode === "BC_ERR_OK") {
        }
      }

      setLoading(false);
      onClickPositive?.();
      dispatch(openToast({ message: "Change saved" }));
    } catch (err) {
      let submssionError = _.reduce(
        err.inner,
        (result, error) => {
          return { ...result, [error.path]: error.errors };
        },
        {}
      );

      //@ts-ignore
      if (!cardElement["_complete"]) {
        submssionError = {
          ...submssionError,
          cardNumber: "incomplete_number",
        };
      }
      throw new SubmissionError({
        ...submssionError,
      });
    }
  };

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={() => {
        console.log("dispatch");
        dispatch(submit("BillInfoForm"));
      }}
      className={classes.root}
      heading={t("Change payment method")}
      close={true}
      fullSizeSub={mobile}
      content={
        <div>
          <Elements stripe={stripePromise}>
            <BillingForm
              mode="change"
              onSubmit={handleSubmit}
              loading={loading}
              onUpdateCardElement={(stripe, cardElement) => {
                setStripe(stripe);
                setCardElement(cardElement);
              }}
            />
          </Elements>
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Change")}
      Secondary={false}
      loading={loading}
    />
  );
};
