import React, { useCallback, useEffect, useState } from "react";
import { Typography, LightColors } from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { SubChangeModal } from "./SubChangeModal";
import { SubCancelModal } from "./SubCancelModal";
import { HistoryTable } from "@thingsw/pitta-design-system/dist/components/HistoryTable";
import { Webviewer } from "../../contants/Breakpoints";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { SubMgrModal } from "./SubMgrModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  loadTransactions,
  loadWebSubscription,
  PAYMENT,
  cancelSubscription,
  loadSubscription,
} from "../../features/Payment/slice";
import { getPlanFromServiceID } from "../../utils/Service";
import { CAMERA } from "../../features/Camera/slice";
import { USER } from "../../features/User/slice";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  pdTB24: {
    padding: "16px 0",
    overflowX: "scroll",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 672,
      overflowX: "hidden",
    },
  },
  pdT16: {
    paddingTop: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  pdT32: {
    paddingTop: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  pdLR16: {
    padding: "0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  cardInfo: {
    paddingTop: 8,
    display: "flex",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "center",
    },
  },
  title: {
    padding: "32px 0 8px 0",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
  },
  Link: {
    color: LightColors.primary["7"],
    cursor: "pointer",
    "&:hover": { color: LightColors.primary["8"] },
  },
  cardSvg: {
    paddingTop: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: { paddingTop: 0 },
  },
  cardText: {
    ...(theme.direction === "rtl"
      ? { paddingRight: theme.spacing(1) }
      : { paddingLeft: theme.spacing(1) }),
  },
  hidden: {
    display: "none",
  },
  noTransTo: {
    maxWidth: 672,
    padding: "0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
    },
  },
  padding4: {
    ...(theme.direction === "rtl"
      ? { paddingLeft: theme.spacing(4) }
      : { paddingRight: theme.spacing(4) }),
  },
}));

export interface SubscriptionPanelProps {
  // cardInfo?: boolean;
  // inApp?: boolean;
  // freePlan?: boolean;
  // mode?: "cardInfo"|"inApp"|"freePlan"
}

export const SubscriptionPanel = () => {
  const classes = useStyles();

  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    subscriptionInfo,
    webSubscriptionInfo,
    transactionHistory,
    loading,
    type,
  } = useSelector((state: RootState) => state[PAYMENT]);
  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );
  const currentCamAmount = useSelector(
    (state: RootState) => state[CAMERA].cameraList?.deviceCount
  );

  const [openManageModal, setOpenManageModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const loadSubscriptionInfo = useCallback(() => {
    dispatch(loadSubscription());
    dispatch(loadWebSubscription());
    dispatch(loadTransactions());
  }, [dispatch]);

  const [userType, setUserType] = useState<"Owner" | "Admin" | undefined>(
    undefined
  );
  const [, /*rols*/ setRoles] = useState<
    "Master" | "SubMaster" | "User" | "Etc"
  >("Etc");

  useEffect(() => {
    loadSubscriptionInfo();
  }, [loadSubscriptionInfo]);

  const getDateTime = useCallback((endOfMonthDT: moment.Moment) => {
    return moment(endOfMonthDT).format("MMM D, YYYY, h:m A");
  }, []);

  const inApp =
    subscriptionInfo &&
    (subscriptionInfo?.servicePlanID === 1004 ||
      subscriptionInfo?.servicePlanID === 1005 ||
      (subscriptionInfo?.servicePlanID >= 4001 &&
        subscriptionInfo?.servicePlanID <= 4029) ||
      (subscriptionInfo.servicePlanID >= 10001 &&
        subscriptionInfo.servicePlanID <= 10003));

  useEffect(() => {
    if (userProfile?.userType === "Master") {
      setRoles("Master");
      setUserType("Owner");
    } else if (userProfile?.userType === "SubMaster") {
      setRoles("Master");
      setUserType("Admin");
    } else if (userProfile?.userType === "User") {
      setRoles("User");
    } else {
      setRoles("Etc");
    }
  }, [
    t,
    userProfile?.profilePath,
    userProfile?.pwLastChangedDT,
    userProfile?.userType,
    userType,
  ]);

  return (
    <div className={classes.pdTB24}>
      <div className={classes.pdLR16}>
        {/* Payment method */}
        <div style={{ paddingBottom: 8 }}>
          <Typography category="Default" variant="H6">
            {t("Payment method")}
          </Typography>
        </div>
        <div>
          {/* Payment method - Fleet plan */}
          {subscriptionInfo &&
            getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
              "Fleet plan" &&
            !inApp && (
              <>
                <div className={classes.cardInfo}>
                  <div className={classes.cardSvg}>
                    <ReactSVG src="/images/payment-cards.svg" />
                  </div>
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.cardText}
                  >
                    {webSubscriptionInfo?.customer.brand} ***
                    {webSubscriptionInfo?.customer.last4} (valid thru:{" "}
                    {webSubscriptionInfo?.customer.expMonth
                      .toString()
                      .padStart(2, "0")}
                    /
                    {webSubscriptionInfo?.customer.expYear
                      .toString()
                      .padStart(2, "0")}
                    )
                  </Typography>
                </div>
                {webSubscriptionInfo &&
                  !webSubscriptionInfo.upcomming.canceled && (
                    <div style={{ paddingTop: 8 }}>
                      {mobile && (
                        <Typography
                          category="Default"
                          variant="Body"
                          className={classes.Link}
                          onClick={() => setOpenChangeModal(true)}
                        >
                          {t("Change payment method")}
                        </Typography>
                      )}
                      {!mobile && (
                        <Typography
                          category="Default"
                          variant="Small"
                          className={classes.Link}
                          onClick={() => setOpenChangeModal(true)}
                        >
                          {t("Change payment method")}
                        </Typography>
                      )}
                    </div>
                  )}
              </>
            )}

          {/* Payment method - inApp : ios */}
          {subscriptionInfo &&
            inApp &&
            _.includes(
              ["ios", "ios_sandbox"],
              subscriptionInfo.paymentMethod
            ) && (
              <div>
                <Typography category="Default" variant="Body">
                  {t("iOS in-app purchase")}
                </Typography>
              </div>
            )}
          {/* Payment method -inApp : android */}
          {subscriptionInfo &&
            inApp &&
            subscriptionInfo.paymentMethod === "android" && (
              <div>
                <Typography category="Default" variant="Body">
                  {t("Android in-app_")}
                </Typography>
              </div>
            )}
          {/* Payment method - Free plan */}
          {subscriptionInfo &&
            getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
              "Free plan" && (
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("You are subscribed_")}
                </Typography>
              </div>
            )}
        </div>
        {/* Subscription */}
        <div className={classes.title}>
          <Typography
            category="Default"
            variant="H6"
            style={{ padding: "32px 0 8px 0" }}
          >
            {t("Subscription")}
          </Typography>
        </div>
        {/* Subscription - Fleet plan / Basic plan */}
        {((subscriptionInfo &&
          getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
            "Fleet plan") ||
          (subscriptionInfo &&
            getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
              "Basic plan")) && (
          <div className={classes.flex}>
            <div className={clsx(classes.column, classes.padding4)}>
              {subscriptionInfo &&
                getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
                  "Fleet plan" &&
                !inApp && (
                  <Typography
                    category="Default"
                    variant="Body"
                    style={{ paddingBottom: 8 }}
                  >
                    {`${
                      subscriptionInfo &&
                      getPlanFromServiceID(subscriptionInfo.servicePlanID)
                    } · ${userType ? t(userType) : userType}`}
                  </Typography>
                )}
              {((subscriptionInfo &&
                getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
                  "Basic plan") ||
                inApp ||
                (inApp &&
                  subscriptionInfo &&
                  getPlanFromServiceID(subscriptionInfo.servicePlanID) !==
                    "Fleet plan")) && (
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {subscriptionInfo &&
                    getPlanFromServiceID(subscriptionInfo.servicePlanID)}
                </Typography>
              )}
              {/* cancel */}
              {!inApp && (
                <>
                  {subscriptionInfo?.cancelled === 0 && (
                    <>
                      <Typography
                        category="Default"
                        variant="Small"
                        style={{ paddingBottom: 8 }}
                      >
                        {t("Next billing_", {
                          a: moment(
                            subscriptionInfo?.endOfMonthDT,
                            "YYYY-MM-DD hh:mm:ss"
                          ).format("MMM D, YYYY"),
                          b: `${
                            (webSubscriptionInfo?.upcomming.total ?? 0) / 100
                          } USD`,
                        })}
                      </Typography>
                      <Typography
                        category="Default"
                        variant={mobile ? "Body" : "Small"}
                        className={classes.Link}
                        onClick={() => setOpenCancelModal(true)}
                      >
                        {t("Cancel Fleet Plan")}
                      </Typography>
                    </>
                  )}
                  {subscriptionInfo?.cancelled === 1 && (
                    <div
                      className={classes.column}
                      style={{ paddingRight: 32, minWidth: 320 }}
                    >
                      <Typography
                        category="Default"
                        variant="SmallBold"
                        htmlColor={LightColors.secondary["11"]}
                      >
                        {t("Canceled Fleet Plan_", {
                          a: moment(
                            subscriptionInfo.cancelDT,
                            "YYYY-MM-DD hh:mm:ss"
                          ).format("MMM D, YYYY"),
                        })}
                      </Typography>
                      <div style={{ maxWidth: 320 }}>
                        <Typography
                          category="Default"
                          variant="Small"
                          htmlColor={LightColors.secondary["11"]}
                        >
                          {t("The new plan_2", {
                            a: moment(
                              subscriptionInfo.endOfMonthDT,
                              "YYYY-MM-DD hh:mm:ss"
                            ).format("MMM D, YYYY"),
                          })}
                        </Typography>
                      </div>
                    </div>
                  )}{" "}
                </>
              )}
            </div>

            {/* Cameras */}
            <div className={clsx(classes.column, classes.pdT16)}>
              <Typography
                category="Default"
                variant="Body"
                style={{ paddingBottom: 8 }}
              >
                {t("Cameras")}
              </Typography>
              {mobile && (
                <>
                  <Typography
                    category="Default"
                    variant="Small"
                    style={{ paddingBottom: 8 }}
                  >
                    {t("available on your_2", {
                      a: subscriptionInfo.cameraLimit ?? 0,
                      b: 999,
                    })}
                  </Typography>
                  {webSubscriptionInfo &&
                    !webSubscriptionInfo.upcomming.canceled && (
                      <Typography
                        category="Default"
                        variant="Body"
                        className={classes.Link}
                        onClick={() => setOpenManageModal(true)}
                      >
                        {t("Manage cameras")}
                      </Typography>
                    )}
                </>
              )}
              {!mobile && (
                <>
                  <Typography
                    category="Default"
                    variant="Small"
                    style={{ paddingBottom: 8 }}
                  >
                    {t("available on your_2", {
                      a: subscriptionInfo.cameraLimit ?? 0,
                      b: 999,
                    })}
                  </Typography>

                  {webSubscriptionInfo &&
                    !webSubscriptionInfo.upcomming.canceled && (
                      <Typography
                        category="Default"
                        variant="Small"
                        className={classes.Link}
                        onClick={() => setOpenManageModal(true)}
                      >
                        {t("Manage cameras")}
                      </Typography>
                    )}
                </>
              )}
            </div>
          </div>
        )}
        {/* Subscription - Free plan */}
        {subscriptionInfo &&
          getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
            "Free plan" && (
            <div className={classes.flex}>
              <div className={clsx(classes.column, classes.padding4)}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {t("Free Plan")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  className={classes.Link}
                  onClick={() =>
                    history.push("/billing", {
                      origin: "/account/subscription",
                    })
                  }
                >
                  {t("Upgrade to Fleet_")}
                </Typography>
              </div>
              <div className={clsx(classes.column, classes.pdT32)}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {t("Cameras")}
                </Typography>
                <Typography category="Default" variant="Small">
                  {t("available on your_2", {
                    a: webSubscriptionInfo?.customer.quantity ?? 0,
                    b: 1,
                  })}
                </Typography>
              </div>
            </div>
          )}
        {/* Subscription - Smart plan */}
        {subscriptionInfo &&
          getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
            "Smart plan" && (
            <div className={classes.flex}>
              <div style={{ paddingRight: 32 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {t("Smart Plan")}
                </Typography>
              </div>
              <div className={clsx(classes.column, classes.pdT32)}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ paddingBottom: 8 }}
                >
                  {t("Cameras")}
                </Typography>
                <Typography category="Default" variant="Small">
                  {t("available on your_2", {
                    a: currentCamAmount ?? 0,
                    b: 3,
                  })}
                </Typography>
              </div>
            </div>
          )}
        {/* Transaction history */}
        <div className={classes.title}>
          <Typography category="Default" variant="H6">
            {t("Transaction history")}
          </Typography>{" "}
        </div>
      </div>

      {/* HistoryTable */}
      {subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Fleet plan" &&
        !inApp && (
          <div className={classes.pdLR16}>
            <HistoryTable
              t={t}
              history={_.map(transactionHistory, (t) => ({
                date: getDateTime(moment.utc(t.createdDT * 1000)),
                amount: t.amount,
                method: t.brand && t.last4 ? `${t.brand} ***${t.last4}` : "",
                details: t.detail,
              }))}
            />
          </div>
        )}
      {/* Transaction history - FreePlan */}
      {/* {subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
          "Free plan" && (
          <>
            {mobile && (
              <div style={{ paddingLeft: 16 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
            {!mobile && (
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
          </>
        )} */}
      {subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Free plan" &&
        !inApp &&
        (transactionHistory.length > 0 ? (
          <div className={classes.pdLR16}>
            <HistoryTable
              t={t}
              history={_.map(transactionHistory, (t) => ({
                date: getDateTime(moment.utc(t.createdDT * 1000)),
                amount: t.amount,
                method: t.brand && t.last4 ? `${t.brand} ***${t.last4}` : "",
                details: t.detail,
              }))}
            />
          </div>
        ) : (
          <>
            {mobile && (
              <div style={{ paddingLeft: 16 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
            {!mobile && (
              <div>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("No transactions yet_")}
                </Typography>{" "}
              </div>
            )}
          </>
        ))}
      {/* Transaction history - inApp*/}
      {subscriptionInfo && inApp && (
        <div className={classes.noTransTo}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
            dangerouslySetInnerHTML={{ __html: t("No transactions to_") }}
          ></Typography>
        </div>
      )}
      {/* Modal */}
      {openCancelModal && (
        <SubCancelModal
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
          onClickNegative={() => setOpenCancelModal(false)}
          onClickPositive={() => {
            setOpenCancelModal(false);
            // setNextFleetPlan(false);
            // setCancelFleetPlan(true);
            dispatch(cancelSubscription());
          }}
          loading={type === cancelSubscription.type && loading}
        />
      )}
      {openChangeModal && (
        <SubChangeModal
          open={openChangeModal}
          onClose={() => {
            setOpenChangeModal(false);
          }}
          onClickNegative={() => setOpenChangeModal(false)}
          onClickPositive={() => {
            loadSubscriptionInfo();
            setOpenChangeModal(false);
          }}
        />
      )}
      <div>
        {openManageModal && (
          <SubMgrModal
            open={openManageModal}
            onClose={() => setOpenManageModal(false)}
            onClickNegative={() => setOpenManageModal(false)}
            onClickPositive={() => {
              setOpenManageModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
