import React, { ChangeEvent, useState } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
} from "redux-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import validator from "./validator";
import { FormControlLabel, FormHelperText } from "@material-ui/core";
import {
  Button,
  CheckBox,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { TFunction } from "i18next";
import { RootState } from "../features/store";
import { ISignupForm } from "../types";
import { USER } from "../features/User/slice";

interface FieldProps {
  label?: string;
  helperText?: string;
  value?: string;
  t: TFunction;
}

const schema = yup.object().shape({
  firstName: yup.string().trim().required("Enter first name"),
  lastName: yup.string().trim().required("Enter last name"),
  tos: yup
    .boolean()
    .oneOf([true], "Please agree to_")
    .required("Please agree to_"),
});

const renderEmailField = ({
  label,
  input,
  helperText,
  meta: { touched, invalid, error },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && invalid}
      helperText={(touched && t(error)) || helperText}
      {...input}
      {...custom}
    />
  );
};
const CheckboxFormControlLabel = withStyles({
  root: {
    marginLeft: -5,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
    "&:first-child": {
      marginBottom: 6,
    },
  },
  label: {
    padding: "3px 0 0 2px",
  },
})(FormControlLabel);

const renderCheckField = ({
  label,
  input,
  meta: { touched, invalid, error },
  t,
}: WrappedFieldProps & FieldProps) => {
  return (
    <div>
      <CheckboxFormControlLabel
        control={
          <CheckBox
            name={input.name}
            color="primary"
            value={input.value}
            onChange={input.onChange}
            checked={input.value ? true : false}
          />
        }
        label={label}
      />
      {touched && error && (
        <FormHelperText
          style={{ color: LightColors.secondary["11"], marginLeft: 28 }}
        >
          {touched && t(error)}
        </FormHelperText>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  agreeDiv: {
    display: "flex",
    flexDirection: "column",
  },
}));

const SocialSignin = (props: InjectedFormProps<ISignupForm, {}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const { loading } = useSelector((state: RootState) => state[USER]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 30) {
      setFirstName(value);
      setLastName(value);
    }
  };

  const handleSignup = () => {
    dispatch(submit("SocialSigninForm"));
  };

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("SocialSigninForm"));
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleEnterkey}>
      <div className={classes.marginB3}>
        <Field
          name="firstName"
          label={t("First name")}
          component={renderEmailField}
          onChange={handleNameChange}
          value={firstName}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 30);
          }}
          t={t}
          autoFocus
        />
      </div>
      <div className={classes.marginB3}>
        <Field
          name="lastName"
          label={t("Last name")}
          component={renderEmailField}
          onChange={handleNameChange}
          value={lastName}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 30);
          }}
          t={t}
        />
      </div>
      <div className={classes.marginB3}>
        <Field
          disabled
          name="email"
          label={t("Email")}
          component={renderEmailField}
          t={t}
        />
      </div>

      <Button
        fullWidth
        color="primary"
        onClick={handleSignup}
        loading={loading}
        style={{ marginBottom: 20 }}
      >
        {t("Sign up")}
      </Button>

      <div className={classes.agreeDiv}>
        <Field
          name="tos"
          value="terms"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("I agree to the_")}{" "}
              <a href="/" style={{ color: LightColors.primary["7"] }}>
                {t("Terms of service")}
              </a>
              ,{" "}
              <a href="/" style={{ color: LightColors.primary["7"] }}>
                {t("Privacy policy")}
              </a>
              ,{" "}
              <a href="/" style={{ color: LightColors.primary["7"] }}>
                {t("Information We Collect_")}
              </a>{" "}
              {t("And")}{" "}
              <a href="/" style={{ color: LightColors.primary["7"] }}>
                {t("Terms of Location-Based_")}
              </a>
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />

        <Field
          name="subscribe"
          value="Subscribe"
          label={
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Subscribe to newsletter")}
            </Typography>
          }
          component={renderCheckField}
          t={t}
        />
      </div>
    </form>
  );
};

const SocialSigninForm = reduxForm<ISignupForm, {}>({
  form: "SocialSigninForm",
  asyncValidate: validator(schema),
})(SocialSignin);

export default SocialSigninForm;
