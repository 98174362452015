import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  IconButton,
  LightColors,
  RadioButton,
  Typography,
} from "@thingsw/pitta-design-system";
import { Selector } from "@thingsw/pitta-design-system/dist/components/Selector";
import { DateTimeSelector } from "@thingsw/pitta-design-system/dist/components/DateTimeSelector";
import { Webviewer } from "../../contants/Breakpoints";
import { FirmwareFormatModal } from "./FirmwareFormatModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  CAMERA,
  loadFirmwareSettings,
  TabNameInfo1,
  TrueFalsetype,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import _ from "lodash";
import moment from "moment";
import TimeZone from "../../contants/TimeZone";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DiscardChangeModal } from "./DiscardChangeModal";
import { Location } from "history";
import { Prompt, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 144,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  borderBox: {
    width: "100%",
    minWidth: 288,
    height: 48,
    display: "flex",
    justifyContent: "flex-start",
    padding: "9px 11px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-end",
      ...(theme.direction === "rtl"
        ? { padding: "9px 295px 9px 0px" }
        : { padding: "9px 0px 9px 295px" }),
      flexDirection: "column",
      maxWidth: 672,
    },
  },

  borderNoneBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "9px 11px",
    minWidth: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      maxHeight: 48,
      borderRadius: 4,
      padding: "9px 10px",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  select: {
    paddingTop: 9,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flex: "1",
      maxWidth: 360,
      paddingTop: 0,
    }, // maxWidth: 256,
    // [theme.breakpoints.up(Webviewer.mobile)]: {
    //   minWidth: 360,
    // },
  },

  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  mgB0: {
    marginBottom: 9,
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      ...(theme.direction === "rtl"
        ? { paddingRight: 0, marginRight: 0 }
        : { marginLeft: 0 }),
    },
    "&.MuiFormControlLabel-root": {
      ...(theme.direction === "rtl" ? { paddingRight: 0 } : { marginLeft: 0 }),
    },
  },
  w226: {
    minWidth: 226,
  },
  ArrowBackPd: {
    ...(theme.direction === "rtl"
      ? { paddingRight: 0, paddingLeft: 7 }
      : { paddingLeft: 0, paddingRight: 7 }),
  },
  ArrowBack: {
    maxWidth: "100%",
    maxHeight: 21,
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));

interface TimeFirmwarePanelProps {
  onClose: () => void;
}

export const TimeFirmwarePanel = ({ onClose }: TimeFirmwarePanelProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveSettingModal, setOpenSaveSettingModal] = useState(false);
  const { camera, firmware, firmwareConfig, loading } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const [current, setCurrent] = useState<TabNameInfo1>();
  const [currentTab, setCurrentTab] = useState<TabNameInfo1>();
  const [disabledChange, setDisabledChange] = useState(true);
  const [day, setDay] = useState<moment.Moment>(moment());
  const [timeRadioValue, setTimeRadioValue] = useState("0");
  const [targetLocation, setTargetLocation] = useState<Location>();

  const settings = useMemo(() => {
    return firmwareConfig?.Time;
  }, [firmwareConfig?.Time]);

  useEffect(() => {
    if (!loading) {
      setOpenSaveSettingModal(false);
    }
  }, [loading]);

  useEffect(() => {
    if (!disabledChange) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        // 표준에 따라 기본 동작 방지
        console.log("unload");
        event.preventDefault();
        return (event.returnValue = "");
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [disabledChange]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings());
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      if (currentTab) {
        const section = currentTab.section_info as TabNameInfo1;
        setCurrent(section);
        if (section.SetTime) {
          setDay(moment(section.SetTime, "YYYYMMDDHHmm"));
        }
        if (section.GpsSync === "0") {
          setTimeRadioValue("1");
        } else {
          setTimeRadioValue("0");
        } //TimeSet || GpsSync 저장 후 다시 로드 했을 때 저장한 키 값이 나오게 하기 위한 식 //
      }
    }
  }, [firmware]);

  const handleChange = (key: keyof TabNameInfo1, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo1));
  };

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab1"
      );
      setCurrentTab(currentTab?.section_info as TabNameInfo1);
      const predicts = _.keys(currentTab?.section_info as TabNameInfo1).map(
        (k) => {
          const key = k as keyof TabNameInfo1;
          return (
            (currentTab?.section_info as TabNameInfo1)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [current, firmware]);

  const handleUpdate = () => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab1"
        ),
        update_date: undefined,
      };
      const timeSetting =
        timeRadioValue === "0"
          ? { GpsSync: "1" as TrueFalsetype, SetTime: "" }
          : {
              GpsSync: "0" as TrueFalsetype,
              SetTime: day.format("YYYYMMDDHHmm"),
            };
      updated.cloud_settings = [
        {
          section_info: { ...current, TimeSet: "1", ...timeSetting },
          section_name: "Tab1",
        },
        ...updated.cloud_settings,
      ];
      // console.log("updated", updated);
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  return (
    <>
      <Prompt
        when={!disabledChange && !targetLocation}
        message={(location) => {
          setTargetLocation(location);
          setOpenDiscardModal(true);
          return false;
        }}
      />
      <div
        className={classes.ArrowBack}
        onClick={() => {
          if (
            currentTab?.TimeSet !== current?.TimeSet ||
            currentTab?.GpsSync !== current?.GpsSync
          ) {
            setOpenDiscardModal(true);
          } else {
            onClose();
          }
        }}
      >
        <IconButton className={classes.ArrowBackPd}>
          <ArrowBackIcon></ArrowBackIcon>
        </IconButton>
        <Typography category="Default" variant="H6">
          {t("Time")}
        </Typography>
      </div>
      <div className={classes.bigBox}>
        <FormControl className={classes.mgB0}>
          <RadioGroup
            value={timeRadioValue}
            onChange={(e) => {
              setTimeRadioValue(e.target.value);
              if (e.target.value === "0") {
                setCurrent((c) => {
                  return c && { ...c, GpsSync: "1", SetTime: "" };
                });
              } else if (e.target.value === "1") {
                setCurrent((c) => {
                  return (
                    c && {
                      ...c,
                      SetTime: day.format("YYYYMMDDhhmm"),
                      GpsSync: "0",
                    }
                  );
                });
              }
            }}
          >
            {settings?.["TimeSet"] && (
              <div
                className={classes.borderNoneBox}
                style={{
                  borderBottom: `1px solid ${LightColors.primary["6"]}`,
                }}
              >
                <div style={{ minWidth: 200 }}>
                  <RadioButton
                    value="1"
                    labelClassName={classes.mgB0}
                    style={{ padding: 3 }}
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Manual time settings")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.w226}>
                  <DateTimeSelector
                    day={day}
                    onChangeDay={(e) => {
                      setDay(e);
                      setCurrent((c) => {
                        return c && { ...c, SetTime: e.toString() };
                      });
                    }}
                    dense
                    mobile={mobile}
                    disabled={timeRadioValue === "0"}
                  ></DateTimeSelector>
                </div>
              </div>
            )}
            {settings?.["GpsSync"] && (
              <>
                <div className={classes.borderNoneBox}>
                  <div style={{ minWidth: 180 }}>
                    <RadioButton
                      value="0"
                      labelClassName={classes.mgB0}
                      style={{ padding: 3 }}
                      label={
                        <Typography
                          category="Default"
                          variant="Body"
                          style={{ minWidth: 130 }}
                        >
                          {t("Sync with GPS_")}
                        </Typography>
                      }
                    />{" "}
                  </div>
                  {/* GMT 목록 확인 후 수정 예정 */}
                  <div className={classes.select}>
                    <div>
                      <Selector
                        t={t}
                        menuScrollTime
                        keyValue={current?.TimeZone}
                        disabled={timeRadioValue === "1"}
                        dense
                        mobile={mobile}
                        onChange={(e) => {
                          setCurrent((c) => {
                            return c && { ...c, TimeZone: e.key };
                          });
                        }}
                        items={_.chain(TimeZone)
                          .keys()
                          .sortBy((k) => parseInt(k))
                          .map((k) => ({ key: k, value: TimeZone[k] }))
                          .value()}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.borderBox} style={{ border: "none" }}>
                  <div style={{ minWidth: 180 }}>
                    <FormControlLabel
                      className={classes.mgB0}
                      control={
                        <CheckBox
                          disabled={timeRadioValue === "1"}
                          checked={current?.Daylight === "1"}
                          onChange={(e) => {
                            handleChange("Daylight", e.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography category="Default" variant="Body">
                          {t("Daylight saving time")}
                        </Typography>
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonSize}
          onClick={() => setOpenSaveSettingModal(true)}
          disabled={disabledChange}
        >
          {t("Save")}
        </Button>
        {openSaveSettingModal && (
          <FirmwareFormatModal
            loading={loading}
            open={openSaveSettingModal}
            onClose={() => setOpenSaveSettingModal(false)}
            onClickNegative={() => setOpenSaveSettingModal(false)}
            onClickPositive={() => {
              handleUpdate();
            }}
          />
        )}
        {openDiscardModal && (
          <DiscardChangeModal
            open={openDiscardModal}
            onClose={() => {
              setTargetLocation(undefined);
              setOpenDiscardModal(false);
            }}
            onClickNegative={() => {
              setTargetLocation(undefined);
              setOpenDiscardModal(false);
            }}
            onClickPositive={() => {
              if (targetLocation) {
                history.push(targetLocation.pathname);
              } else {
                onClose();
              }
              setTargetLocation(undefined);

              setOpenDiscardModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};
