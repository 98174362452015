import axios from "axios";
import {
  API_GATEWAY_URI,
  LOGIN_SERVER_URI,
  API_SERVER_URI,
} from "../contants/Server";
import {
  ILogin,
  ISignup,
  IUserConfirm,
  IUploadImage,
  IChangeName,
  ISetNotification,
} from "../features/User/slice";
import crypto from "crypto";
import { IPermissions } from "../features/Member/slice";

export const logout = (email: string, userToken: string) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/userLogout.php?email=${email}&userToken=${userToken}&tokenType=web`
  );
};

export const userLogin = (body: ILogin) => {
  return axios.post(`${LOGIN_SERVER_URI}/userLogin`, body);
};

export const getProfile = (email: string, userToken: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/GetProfile?email=${email}&userToken=${userToken}&tokenType=web`
  );
};

export const getMailAddress = (email: string) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/initPasswd.php?email=${email}&mobile_lang=kor`
  );
};

export const createUser = (user: ISignup) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserAdd`, user);
};

export const userConfirm = (user: IUserConfirm) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserConfirm`, user);
};

export const uploadImage = (
  email: string,
  userToken: string,
  tokenType: string,
  payload: IUploadImage
) => {
  const params = new FormData();
  params.append("email", email);
  params.append("userToken", userToken);
  params.append("tokenType", tokenType);
  params.append("file", payload.file);
  params.append(
    "fileName",
    crypto.createHash("md5").update(payload.fileName).digest("hex")
  );
  return axios.post(`${API_GATEWAY_URI}/Account/UploadProfile`, params, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

export const changeName = (
  email: string,
  user_token: string,
  body: IChangeName
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/ChangeUserName`, {
    ...body,
    email,
    user_token,
    tokenType: "web",
  });
};

export const changePasswd = (
  email: string,
  old_passwd: string,
  new_passwd: string
) => {
  const parmas = new FormData();
  parmas.append("email", email);
  parmas.append("old_passwd", old_passwd);
  parmas.append("new_passwd", new_passwd);
  parmas.append("tokenType", "web");
  return axios.post(`${API_SERVER_URI}/BCS/userChangePasswd.php`, parmas, {
    headers: {
      "Content-type": "x-www-form-urlencoded",
    },
  });
};

export const deleteAccountMail = (
  email: string,
  user_token: string,
  passwd: string,
  tokenType: string
) => {
  const parmas = new FormData();
  parmas.append("email", email);
  parmas.append("old_passwd", user_token);
  parmas.append("new_passwd", passwd);
  parmas.append("tokenType", tokenType);
  return axios.post(`${API_SERVER_URI}/BCS/accountDelete.php`, parmas, {
    headers: {
      "Content-type": "x-www-form-urlencoded",
    },
  });
};

export const userUsage = (email: string, userToken: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserUsageInfo?email=${email}&user_token=${userToken}&tokenType=web`
  );
};

export const getUserSetting = (email: string, userToken: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/GetUserSettings?email=${email}&user_token=${userToken}&tokenType=web`
  );
};

export const updateUserSetting = (
  email: string,
  userToken: string,
  mapType: string,
  velocityUnit: string
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/PostUserSettings`, {
    email,
    user_token: userToken,
    tokenType: "web",
    mapType,
    velocityUnit,
  });
};

export const setUserNotification = (
  email: string,
  userToken: string,
  mobile_uuid: string,
  body: ISetNotification
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/alarmDetailSet.php?email=${email}&user_token=${userToken}&mobile_uuid=${mobile_uuid}&device_alarm=${body.device_alarm}&notice_alarm=${body.notice_alarm}&system_alarm=${body.system_alarm}&tokenType=web`
  );
};

export const getUserNotification = (
  email: string,
  userToken: string,
  mobile_uuid: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/alarmDetailGet.php?email=${email}&user_token=${userToken}&mobile_uuid=${mobile_uuid}&tokenType=web`
  );
};

export const getUserPermissions = (
  email: string,
  userToken: string,
  targetEmail: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserRoleTargetInfo?email=${email}&user_token=${userToken}&tokenType=web&targetEmail=${targetEmail}`
  );
};

export const updateUserPermissions = (
  email: string,
  user_token: string,
  roleSetEmail: string,
  permissions: IPermissions
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserRoleSet`, {
    email,
    user_token,
    roleSetEmail,
    permissions,
    tokenType: "web",
  });
};

export const linkSocialAccount = (
  email: string,
  passwd: string,
  oauthToken: string,
  signupType: string
) => {
  return axios.put(`${API_GATEWAY_URI}/Account/PutUserLinkage`, {
    email,
    passwd,
    oauthToken,
    signupType,
  });
};

export const doDeleteUser = (email: string, user_token: string) => {
  return axios.post(`${API_GATEWAY_URI}/Account/PostUserDeleteWithEmail`, {
    email,
    user_token,
    tokenType: "web",
  });
};

export const loadUserPermissions = (email: string, user_token: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserRole?email=${email}&user_token=${user_token}&tokenType=web`
  );
};

// export const getAccountSubscription = (email: string, userToken: string) => {
//   return axios.get(
//     `${API_GATEWAY_URI}/Payment/GetSubscrInfo?email=${email}&user_token=${userToken}&tokenType=web`
//   );
// };
