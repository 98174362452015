import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Webviewer } from "../../contants/Breakpoints";
import { RootState } from "../../features/store";
import { USER, loadUsageInfo } from "../../features/User/slice";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import moment from "moment";
import clsx from "clsx";
import { PAYMENT } from "../../features/Payment/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflowY: "auto",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(3, 0),
    },
  },
  boxGroupDiv: {
    display: "flex",
    // justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  boxDiv: {
    display: "flex",
    //justifyContent: "center",
    flexDirection: "column",
    width: 288,
    height: 230,
    backgroundColor: LightColors.primary["0"],
    border: `1px solid #D4D4D5`,
    boxSizing: `border-box`,
    borderRadius: 4,
    margin: theme.spacing(0, 0, 2),
    paddingTop: theme.spacing(2),
    "&:last-child": {
      height: 206,
      marginBottom: 0,
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 359,
      height: 250,
      alignItems: "baseline",

      ...(theme.direction === "rtl"
        ? {
            margin: theme.spacing(0, 0, 2, 4),
            padding: theme.spacing(3, 4, 0, 0),
          }
        : {
            margin: theme.spacing(0, 4, 2, 0),
            padding: theme.spacing(3, 0, 0, 4),
          }),

      "&:last-child": {
        height: 250,
        marginBottom: theme.spacing(2),
        ...(theme.direction === "rtl"
          ? { paddingRight: 0 }
          : { paddingLeft: 0 }),
      },
    },
    [theme.breakpoints.up("lg")]: {
      "&:last-child": {
        ...(theme.direction === "rtl" ? { marginLeft: 0 } : { marginRight: 0 }),
      },
    },
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
    // width: 240,
  },
  textDivMargin: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(4) }
        : { marginLeft: theme.spacing(4) }),
    },
  },
  marginText: {
    lineHeight: "21px",
  },
  noteDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      alignItems: "baseline",
    },
  },
  // 박스 내부
  usageDiv: {
    display: "flex",
    width: 240,
    alignItems: "baseline",
    margin: theme.spacing(3, 0, 4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(2.5, 0, 4.5, 0),
    },
  },
  // 마지막 박스 내부
  spaceDiv: {
    display: "flex",
    width: 240,
    alignItems: "baseline",
    margin: theme.spacing(3, 0, 4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(5.5, 0, 4.5, 0),
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(4) }
        : { marginLeft: theme.spacing(4) }),
    },
  },
  spaceDivText: {
    fontSize: "46px !important",
    lineHeight: "53.91px !important",
    "&:last-child": {
      fontSize: "30px !important",
      lineHeight: "35.16px !important",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      fontSize: "56px !important",
      lineHeight: "65.63px !important",
      "&:last-child": {
        fontSize: "40px !important",
        lineHeight: "46.88px !important",
      },
    },
  },
  // 마지막 박스 하단 graph
  graphDiv: {
    display: "flex",
    width: "100%",
    height: 55,
    position: "relative",
  },
  usageGradient: {
    display: "flex",
    position: "absolute",
    height: 53,
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #D0EBF9 100%)`,
    maxWidth: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 359,
    },
  },
  usage: {
    position: "absolute",
    top: "19px",
    left: "16px",
  },
  totalStorage: {
    position: "absolute",
    top: "19px",
    right: "16px",
  },
  gradientUnderline: {
    position: "absolute",
    height: 3,
    top: "52px",
    background: LightColors.primary[7],
    borderRadius: "0px 0px 0px 3px",
    maxWidth: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 359,
    },
  },
  explainTypo: {
    lineHeight: "21px",
    color: LightColors.primary[2],
  },
  arrowIcon: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(2) }
      : { marginLeft: theme.spacing(2) }),
  },
  MgL24: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginLeft: theme.spacing(3),
    },
  },
}));

export const UsagePanel = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;

  const [date, setDate] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [storageUsed, setStorageUsed] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);

  const [storageUsedRatio, setStorageUsedRatio] = useState(0);

  const userUsage = useSelector((state: RootState) => state[USER].usageInfo);
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const freePlan = useMemo(() => {
    return subscriptionInfo && subscriptionInfo?.servicePlanID === 1;
  }, [subscriptionInfo]);

  const loginInfo = useSelector((state: RootState) => state[USER].loginInfo);

  useEffect(() => {
    if (userUsage) {
      const duration = moment.duration(userUsage.liveViewDayUsage, "seconds");
      setDate({
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });

      setStorageUsedRatio(
        (userUsage.cloudStorageUsage / userUsage.LimitCloudStroage) * 100
      );
      setTotalStorage(userUsage.LimitCloudStroage / 1024 / 1024 / 1024);
      setStorageUsed(userUsage.cloudStorageUsage / 1024 / 1024 / 1024);
    }
  }, [userUsage]);

  useEffect(() => {
    if (loginInfo?.userType === "Master" || loginInfo?.userType === "Etc") {
      dispatch(loadUsageInfo());
    }
  }, [dispatch, loginInfo?.userType]);

  return (
    <div className={classes.root}>
      <div className={classes.boxGroupDiv}>
        <div className={classes.boxDiv}>
          <div className={classes.MgL24}>
            <div className={classes.textDiv}>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="BodyBold"
              >
                {t("Live view")}
              </Typography>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="Body"
              >
                {t(`Limit is reset daily`)}
              </Typography>
            </div>
            <div className={classes.usageDiv} style={{ marginBottom: 8 }}>
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "53.91px" : "66px",
                }}
                category="Default"
                variant="BodyBold"
              >
                {date.hours}
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "30px" : "40px",
                  lineHeight: mobile ? "35.16px" : "46.88px",
                }}
                category="Default"
                variant="BodyBold"
              >
                h
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "35.16px" : "46.88px",
                }}
                category="Default"
                variant="BodyBold"
              >
                :
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "53.91px" : "66px",
                }}
                category="Default"
                variant="BodyBold"
              >
                {date.minutes}
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "30px" : "40px",
                  lineHeight: mobile ? "35.16px" : "46.88px",
                }}
                category="Default"
                variant="BodyBold"
              >
                m
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "35.16px" : "46.88px",
                }}
                category="Default"
                variant="BodyBold"
              >
                :
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "53.91px" : "66px",
                }}
                category="Default"
                variant="BodyBold"
              >
                {date.seconds}
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "30px" : "40px",
                  lineHeight: mobile ? "35.16px" : "46.88px",
                }}
                category="Default"
                variant="BodyBold"
              >
                s
              </Typography>
            </div>{" "}
            <div className={classes.textDiv}>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["2"]}
              >
                {freePlan ? t("of 10 minutes") : t("of Unlimited")}
              </Typography>
            </div>
            <div className={classes.graphDiv}></div>
          </div>
        </div>
        <div className={classes.boxDiv}>
          <div className={classes.MgL24}>
            <div className={classes.textDiv}>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="BodyBold"
              >
                {mobile
                  ? t("Video plays and downloads")
                  : t("Video playbacks and downloads")}
              </Typography>

              <Typography
                className={classes.marginText}
                category="Default"
                variant="Body"
              >
                {t(`Limit is reset monthly`)}
              </Typography>
            </div>
            <div
              className={classes.usageDiv}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "53.91px" : "65.63px",
                }}
                category="Default"
                variant="BodyBold"
              >
                {userUsage?.remoteVideoPlayBackAndDownLoad ?? 0}
              </Typography>
              <div className={classes.textDiv} style={{ marginTop: 8 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                >
                  {freePlan ? t("of 100 times") : t("of Unlimited")}
                </Typography>
              </div>
            </div>
            <div className={classes.graphDiv}></div>
          </div>
        </div>
        <div className={classes.boxDiv}>
          <div className={classes.MgL24}>
            <div className={clsx(classes.textDiv, classes.textDivMargin)}>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="BodyBold"
              >
                {t(`Space available`)}
              </Typography>
            </div>
            <div className={classes.spaceDiv}>
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "53.91px" : "65.63px",
                }}
                category="Default"
                variant="BodyBold"
              >
                {(totalStorage - storageUsed).toFixed(2)}
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "30px" : "40px",
                  lineHeight: mobile ? "35.63px" : "46.88px",
                }}
                category="Default"
                variant="BodyBold"
              >
                {"GB"}
              </Typography>

              <div className={classes.arrowIcon}>
                <ArrowDownwardIcon
                  style={{ color: LightColors.secondary[11] }}
                />
              </div>
            </div>
          </div>
          <div className={classes.graphDiv}>
            <div
              className={classes.usageGradient}
              style={{
                width: `${storageUsedRatio}%`,
              }}
            ></div>
            <div className={classes.usage}>
              <Typography category="Default" variant="BodyBold">
                {storageUsedRatio.toFixed(2)}
              </Typography>
              <Typography category="Default" variant="Small">
                %
              </Typography>
            </div>
            <div className={classes.totalStorage}>
              <Typography category="Default" variant="BodyBold">
                {totalStorage}
              </Typography>
              <Typography category="Default" variant="Small">
                {"GB"}
              </Typography>
            </div>
            <div
              className={classes.gradientUnderline}
              style={{
                width: `${storageUsedRatio}%`,
              }}
            ></div>
          </div>
        </div>
      </div>
      {/* 피그마,시나리오에서 삭제된 것 같아 주석 처리  */}
      {/* <div className={classes.noteDiv}>
        <Typography
          className={classes.explainTypo}
          category="Default"
          variant="Small"
        >
          {t(
            `Please note, that we don’t provide usage stats for Cloud Lite service.`
          )}
        </Typography>
      </div> */}
    </div>
  );
};
