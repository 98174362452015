import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILatLng } from "../../components/maps/GoogleMap";

export interface IGeofence {
  fenceId: string;
  fenceName: string;
  fenceType: number;
  psn: string[];
  setShape: { Color: string; Transparency: number };
  alarm: string[];
  polygon?: ILatLng[];
  polyline?: ILatLng[];
  circle?: [{ lat: number; lng: number; radius: number }];
  rectangle?: ILatLng[];
}

interface IState {
  loading: boolean;
  type?: string;
  geofences: IGeofence[];
}

let initialState: IState = {
  loading: false,
  geofences: [],
};

const slice = createSlice({
  name: "geofence",
  initialState,
  reducers: {
    clearGeofence: (state) => {
      state.loading = false;
      state.type = undefined;
      state.geofences = [];
    },
    loadGeofences: (state, action: PayloadAction<undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadGeofences: (state, { payload }: PayloadAction<IGeofence[]>) => {
      state.loading = false;
      state.geofences = payload;
    },
    addGeofence: (state, action: PayloadAction<IGeofence>) => {
      state.loading = true;
      state.type = action.type;
    },
    updateGeofence: (state, action: PayloadAction<IGeofence>) => {
      state.loading = true;
      state.type = action.type;
    },
    successRequestGeofence: (state) => {
      state.loading = false;
    },
    deleteGeofence: (
      state,
      action: PayloadAction<{ fenceId: string; fenceName: string }>
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
  },
});

export const {
  clearGeofence,
  loadGeofences,
  successLoadGeofences,
  addGeofence,
  updateGeofence,
  successRequestGeofence,
  deleteGeofence,
  clearLoading,
} = slice.actions;
export const GEOFENCE = slice.name;
export default slice.reducer;
