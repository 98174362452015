import { MODEL_KEY } from "./Firmwares";

export const DMC100_MODELS: MODEL_KEY[] = ["DR750X-3CH Plus"];
export const DMC200_MODELS: MODEL_KEY[] = [
  "DR750X LTE Plus",
  "DR750X Plus",
  "DR900X Plus",
  "BlackVue 7",
  "BlackVue 9",
  "BlackVue 7 Box",
  "BlackVue 7 LTE",
  "BlackVue 9 LTE",
];
// export const DMC200_MODELS: MODEL_KEY[] = []; // DMS출시전
export const MODELS_1CH: MODEL_KEY[] = [
  "DR750S-1CH",
  "DR900S-1CH",
  "DR750G-1CH",
  "DR750X-1CH",
  "DR900X-1CH",
  // "DR590X-1CH",
  "DR750G-1CH Pro",
];

export const MODELS_2CH: MODEL_KEY[] = [
  "DR750S-2CH",
  "DR900S-2CH",
  "DR750G-2CH",
  "DR750-2CH LTE",
  "DR750X-2CH",
  "DR900X-2CH",
  // "DR590X-2CH",
  "DR750G-2CH Pro",
  "DR750X Plus",
  // "DR750LTE Plus",
  "DR900X Plus",
  "DR750G Pro",
  "DR750X LTE Plus",
  // "DR750K LTE",
  "BlackVue 7",
  "BlackVue 7 LTE",
  "BlackVue 7G Pro",
  "BlackVue 9",
  "BlackVue 9 LTE",
];

export const MODELS_3CH: MODEL_KEY[] = ["DR750X-3CH Plus", "BlackVue 7 Box"];
export const LOW_VOLTAGE_MODELS: MODEL_KEY[] = [
  "DR750X-1CH",
  "DR900X-1CH",
  // "DR590X-1CH",
  "DR750X-2CH",
  "DR900X-2CH",
  // "DR590X-2CH",
  "DR750X Plus",
  "DR900X Plus",
  "DR750X-3CH Plus",

  "BlackVue 7",
  "BlackVue 7 Box",
  "BlackVue 7 LTE",
  "BlackVue 7G Pro",
  "BlackVue 9",
  "BlackVue 9 LTE",
];

export const HDR_RESTARTING_MODEL: MODEL_KEY[] = [
  "DR900S-1CH",
  "DR900S-2CH",
  "DR900X-1CH",
  "DR900X-2CH",
  "DR750G-1CH Pro",
  "DR750G-2CH Pro",
  "DR750G Pro",
];

export const UnableAddCameraPSN4 = [
  "D65G",
  "DR65",
  "D65S",
  "DR6S",
  "59X1",
  "59X2",
];

export const PSN650 = ["D65G", "DR65", "D65S", "DR6S"];
