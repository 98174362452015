import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Fab,
  LightColors,
  MarkerCluster,
  Menu,
  MobileMenu,
  Tooltip,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";

import CheckIcon from "@material-ui/icons/Check";

import { TopRightControl } from "./TopRightControl";
import {
  exitFullscreen,
  isFullscreen,
  requestFullscreen,
} from "../../utils/GoogleMap";
import {
  GPS,
  IDriveInfo,
  ITrackData,
  ITrackInfo,
  loadCluster,
  loadGPSTrackingData,
} from "../../features/GPS/slice";
import { RootState } from "../../features/store";
import { DrawingMode, EventAbbrToEventFull, IGPSLocation } from "../../types";

import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Webviewer } from "../../contants/Breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EventItem from "@thingsw/pitta-design-system/dist/components/EventItem";
import { GOOGLE_API_KEY } from "../../contants/GoogleMap";

const MOBILE_BOUNDS_PADDING = { bottom: 50, left: 50, right: 50, top: 50 };
const PC_BOUNDS_PADDING = { bottom: 250, left: 400, right: 100, top: 250 };
// const PC_TRACK_BOUNDS_PADDING = { bottom: 100, left: 100, right: 0, top: 100 };

export interface ILatLng {
  lat: number;
  lng: number;
}

export interface ILatLngBounds {
  east: number;
  west: number;
  north: number;
  south: number;
}

export interface IGeometry {
  coords?: ILatLng[];
  center?: ILatLng;
  radius?: number;
  bounds?: ILatLngBounds;
}

export interface IInfoWindow {
  drive_no: number;
  index: number;
  data: ITrackData;
  sid: number;
  selectedSid?: number;
  last?: boolean;
}

export const INITIAL_MAP_LOCATION = {
  lat: 39.123098,
  lng: -101.008655,
};

interface GoogleMapProps {
  onGoogleApiLoaded?: (maps: {
    map: any;
    maps: any;
    ref: Element | null;
  }) => void;
  publicIcon?: boolean;
  fullscreenIcon?: boolean;
  trackMarker?: boolean;
  location?: IGPSLocation;
  children?: React.ReactNode;
  // tracks?: (ITrackInfo & { label: string })[];
  drives?: IDriveInfo[];
  infoWindow?: IInfoWindow;
  onDownload?: (data: ITrackData) => void;
  onPlay?: (data: ITrackData) => void;
  onClickMap?: (e: any) => void;
  onSetInfoWindow?: (infoWindow?: IInfoWindow) => void;
  onUpdateGeometry?: (object: IGeometry) => void;
  onStartDrawing?: () => void;
  drawingMode?: DrawingMode;
  drawingColor?: string;
  drawingOpacity?: number;
  geometry?: IGeometry | null;
  psn?: string;
  selectedTrack?: ITrackInfo;
  onSelectTrack?: (track: ITrackInfo) => void;
  mode: "location" | "gps-tracking" | "geofence";
}

const useStyles = makeStyles((theme: Theme) => ({
  topControlPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
  },
  zoomControlDiv: {
    position: "absolute",
    bottom: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
  },
  zoomControlBtn: {
    borderRadius: 8,
    width: 30,
    height: 30,
    color: LightColors.primary["3"],
    minHeight: 30,
  },
  gpsFixBtn: {
    marginBottom: theme.spacing(0.5),
  },
  zoomInBtn: {
    borderBottom: 0,
    borderRadius: theme.spacing(1, 1, 0, 0),
    boxShadow: "none",
  },
  zoomOutBtn: {
    borderTop: 0,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: "none",
  },
  tnpDiv: {
    ...(theme.direction === "rtl" ? { paddingRight: 44 } : { paddingLeft: 44 }),
  },
  appIcon: {
    fontSize: 15,
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  directionMakerIcon: {
    width: 20,
    height: 20,
    zIndex: 1,
  },
  markerIcon: {
    cursor: "pointer",
    transform: "translate(-17px, -32px)",
    width: 34,
    height: 38,
    zIndex: 2,
  },
  selectedMarkerIcon: {
    cursor: "pointer",
    transform: "translate(-19px, -38px)",
    width: 38,
    height: 42,
  },
  startMarkerIcon: {
    zIndex: 2,
    transform: "translate(-12px, -12px)",
    filter:
      "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.14)), drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12)), drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2))",
  },
  infoWindowDiv: {
    width: 300,
    backgroundColor: LightColors.primary["0"],
    borderRadius: 4,
    border: `1px sloid ${LightColors.primary["6"]}`,
  },
  parkingDiv: {
    maxHeight: 421,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  infoWindowCenter: {
    transform: "translate(-150px, calc(-100% - 40px))",
  },
  inforParkingTitle: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
}));
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const GoogleMap = (props: GoogleMapProps) => {
  const {
    onGoogleApiLoaded,
    publicIcon,
    fullscreenIcon,
    trackMarker,
    location,
    children,
    // tracks,
    drives,
    infoWindow,
    drawingMode,
    drawingColor,
    drawingOpacity,
    geometry,
    onDownload,
    onPlay,
    onSetInfoWindow,
    onUpdateGeometry,
    onStartDrawing,
    psn,
    selectedTrack,
    onSelectTrack,
    mode,
  } = props;
  // useTraceUpdate(props);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const anchorRef = useRef<HTMLButtonElement>(null);

  const { clusters, tracks, interval } = useSelector(
    (state: RootState) => state[GPS]
  );

  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [openLayer, setOpenLayer] = useState(false);
  const [mapMode, setMapMode] = useState<"map" | "satellite">("map");
  const [markers, setMarkers] = useState<{ [key: string]: any }>({});
  // const [gMarkers, setGMarkers] = useState<any[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<IInfoWindow>();
  const [drawingManager, setDrawingManager] = useState<any>();
  const [drawingObject, setDrawingObject] = useState<any>();
  const [bounds, setBounds] = useState<ILatLngBounds>();
  const [trackBoundsUpdate, setTrackBoundsUpdate] = useState(true);
  const enableTrackLoad = useRef(true);
  const prevInterval = usePrevious(interval);
  const prevSelectedTrack = usePrevious(selectedTrack) as
    | ITrackInfo
    | undefined;
  const gMarkers = useRef<any[]>([]);

  const loadTracks = useCallback(
    (psn: string, bounds?: ILatLngBounds) => {
      const drive_no_list = _.map(drives, (d) => d.drive_no);
      const cancel = new AbortController();

      dispatch(
        loadGPSTrackingData({
          psn: psn,
          drive_no_list,
          cancel,
          bounds,
        })
      );

      return cancel;
    },
    [dispatch, drives]
  );

  useEffect(() => {
    if (mode === "gps-tracking") {
      if (psn && drives && bounds && map && enableTrackLoad.current) {
        if (
          (prevInterval === undefined && interval === undefined) ||
          (prevInterval !== undefined && interval !== undefined)
        ) {
          let cancel: AbortController;
          if (!interval) {
            cancel = loadTracks(psn);
            // } else if (interval > 1) {
          } else {
            cancel = loadTracks(psn, bounds);
          }

          return () => {
            cancel.abort();
          };
        }
      } else if (!drives) {
        // dispatch(clearGPSTrackingData());
        setTrackBoundsUpdate(true);
      }
    }
  }, [
    dispatch,
    loadTracks,
    mode,
    drives,
    psn,
    bounds,
    map,
    interval,
    prevInterval,
  ]);

  useEffect(() => {
    if (drawingManager) {
      drawingManager.setOptions({
        drawingControl: false,
        polygonOptions: {
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        },
        polylineOptions: {
          geodesic: true,
          strokeColor: drawingColor,
          strokeWeight: 3,
          strokeOpacity: 1.0,
          editable: true,
          suppressUndo: true,
        },
        circleOptions: {
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        },
        rectangleOptions: {
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        },
      });
    }
  }, [drawingColor, drawingOpacity, drawingManager]);

  useEffect(() => {
    if (drawingObject) {
      if (
        drawingMode === "polygon" ||
        drawingMode === "circle" ||
        drawingMode === "rectangle"
      ) {
        drawingObject.setOptions({
          // paths: coords,
          strokeColor: drawingColor,
          strokeWeight: 3,
          fillColor: drawingColor,
          fillOpacity: drawingOpacity,
          editable: true,
          suppressUndo: true,
        });
      } else if (drawingMode === "polyline") {
        drawingObject.setOptions({
          geodesic: true,
          strokeColor: drawingColor,
          strokeWeight: 3,
          strokeOpacity: 1.0,
          editable: true,
          suppressUndo: true,
        });
      }
    }
  }, [drawingMode, drawingObject, drawingColor, drawingOpacity]);

  const updateEventPoly = useCallback(
    (polygon: any) => {
      return [
        maps.event.addListener(polygon.getPath(), "insert_at", (e: any) => {
          onUpdateGeometry?.({
            coords: _.map(polygon.getPath().getArray(), (p) => ({
              lat: p.lat(),
              lng: p.lng(),
            })),
          });
        }),
        maps.event.addListener(polygon.getPath(), "set_at", (e: any) => {
          onUpdateGeometry?.({
            coords: _.map(polygon.getPath().getArray(), (p) => ({
              lat: p.lat(),
              lng: p.lng(),
            })),
          });
        }),
        maps.event.addListener(polygon.getPath(), "remove_at", (e: any) => {
          onUpdateGeometry?.({
            coords: _.map(polygon.getPath().getArray(), (p) => ({
              lat: p.lat(),
              lng: p.lng(),
            })),
          });
        }),
      ];
    },
    [onUpdateGeometry, maps]
  );

  const updateEventCircle = useCallback(
    (circle: any) => {
      return [
        maps.event.addListener(circle, "center_changed", () => {
          onUpdateGeometry?.({
            center: {
              lat: circle.getCenter().lat(),
              lng: circle.getCenter().lng(),
            },
            radius: circle.getRadius(),
          });
        }),
        maps.event.addListener(circle, "radius_changed", () => {
          onUpdateGeometry?.({
            center: {
              lat: circle.getCenter().lat(),
              lng: circle.getCenter().lng(),
            },
            radius: circle.getRadius(),
          });
        }),
      ];
    },
    [onUpdateGeometry, maps]
  );

  const updateEventRect = useCallback(
    (rect: any) => {
      return [
        maps.event.addListener(rect, "bounds_changed", () => {
          const ne = rect.getBounds().getNorthEast();
          const sw = rect.getBounds().getSouthWest();
          onUpdateGeometry?.({
            bounds: {
              north: ne.lat(),
              east: ne.lng(),
              south: sw.lat(),
              west: sw.lng(),
            },
          });
        }),
      ];
    },
    [onUpdateGeometry, maps]
  );

  useEffect(() => {
    if (drawingObject) {
      if (geometry === null) {
        drawingObject.setMap(null);
        setDrawingObject(undefined);
        return;
      }
      if (drawingMode === "polygon" || drawingMode === "polyline") {
        if (geometry && geometry.coords && geometry.coords.length > 0) {
          drawingObject.setPath(geometry.coords);
        }
        const events = updateEventPoly(drawingObject);
        return () => {
          _.forEach(events, (e) => e.remove());
        };
      } else if (drawingMode === "circle") {
        if (geometry && geometry.center && geometry.radius) {
          drawingObject.setCenter(geometry.center);
          drawingObject.setRadius(geometry.radius);
        }
        const events = updateEventCircle(drawingObject);
        return () => {
          _.forEach(events, (e) => e.remove());
        };
      } else if (drawingMode === "rectangle") {
        if (geometry && geometry.bounds) {
          drawingObject.setBounds(geometry.bounds);
        }
        const events = updateEventRect(drawingObject);
        return () => {
          _.forEach(events, (e) => e.remove());
        };
      }
    }
  }, [
    updateEventPoly,
    updateEventCircle,
    updateEventRect,
    drawingMode,
    drawingObject,
    geometry,
  ]);

  useEffect(() => {
    let minLat = 999,
      minLng = 999,
      maxLat = -999,
      maxLng = -999;
    console.log("geometry", geometry, drawingObject, drawingMode);
    if (geometry && !drawingObject) {
      if (drawingMode === "polygon") {
        if (geometry && geometry.coords && geometry.coords.length > 0) {
          _.forEach(geometry.coords, (coord) => {
            minLat = Math.min(minLat, coord.lat);
            minLng = Math.min(minLng, coord.lng);
            maxLat = Math.max(maxLat, coord.lat);
            maxLng = Math.max(maxLng, coord.lng);
          });
          const obj = new maps.Polygon({
            paths: geometry.coords,
            strokeColor: drawingColor,
            strokeWeight: 3,
            fillColor: drawingColor,
            fillOpacity: drawingOpacity,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventPoly(obj);
          setDrawingObject(obj);
          map.fitBounds(
            {
              east: maxLng,
              north: maxLat,
              west: minLng,
              south: minLat,
            },
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      } else if (drawingMode === "polyline") {
        if (geometry && geometry.coords && geometry.coords.length > 0) {
          _.forEach(geometry.coords, (coord) => {
            minLat = Math.min(minLat, coord.lat);
            minLng = Math.min(minLng, coord.lng);
            maxLat = Math.max(maxLat, coord.lat);
            maxLng = Math.max(maxLng, coord.lng);
          });
          const obj = new maps.Polyline({
            path: geometry.coords,
            geodesic: true,
            strokeColor: drawingColor,
            strokeWeight: 3,
            strokeOpacity: 1.0,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventPoly(obj);
          setDrawingObject(obj);
          map.fitBounds(
            {
              east: maxLng,
              north: maxLat,
              west: minLng,
              south: minLat,
            },
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      } else if (drawingMode === "circle") {
        if (geometry && geometry.center && geometry.radius) {
          const obj = new maps.Circle({
            center: geometry.center,
            radius: geometry.radius,
            strokeColor: drawingColor,
            strokeWeight: 3,
            fillColor: drawingColor,
            fillOpacity: drawingOpacity,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventCircle(obj);
          setDrawingObject(obj);

          map.fitBounds(
            obj.getBounds(),
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      } else if (drawingMode === "rectangle") {
        if (geometry && geometry.bounds) {
          const obj = new maps.Rectangle({
            bounds: geometry.bounds,
            strokeColor: drawingColor,
            strokeWeight: 3,
            fillColor: drawingColor,
            fillOpacity: drawingOpacity,
            editable: true,
            suppressUndo: true,
          });
          obj.setMap(map);
          const events = updateEventCircle(obj);
          setDrawingObject(obj);

          map.fitBounds(
            geometry.bounds,
            mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
          );
          return () => {
            _.forEach(events, (e) => e.remove());
          };
        }
      }
    }
  }, [
    updateEventPoly,
    updateEventCircle,
    updateEventRect,
    drawingMode,
    drawingObject,
    geometry,
    drawingColor,
    drawingOpacity,
    maps,
    map,
    mobile,
  ]);

  useEffect(() => {
    if (drawingObject) {
      if (drawingMode === "polygon" || drawingMode === "polyline") {
        onUpdateGeometry?.({
          coords: _.map(drawingObject.getPath().getArray(), (p) => ({
            lat: p.lat(),
            lng: p.lng(),
          })),
        });
      } else if (drawingMode === "circle") {
        onUpdateGeometry?.({
          center: {
            lat: drawingObject.getCenter().lat(),
            lng: drawingObject.getCenter().lng(),
          },
          radius: drawingObject.getRadius(),
        });
      } else if (drawingMode === "rectangle") {
        const ne = drawingObject.getBounds().getNorthEast();
        const sw = drawingObject.getBounds().getSouthWest();
        onUpdateGeometry?.({
          bounds: {
            north: ne.lat(),
            east: ne.lng(),
            south: sw.lat(),
            west: sw.lng(),
          },
        });
      }
    }
  }, [onUpdateGeometry, drawingObject, drawingMode]);

  useEffect(() => {
    if (drawingMode && map && maps && geometry === null) {
      // console.log("aaa");
      const dm = new maps.drawing.DrawingManager({
        drawingControl: false,
        polygonOptions: {
          editable: true,
        },
      });
      dm.setMap(map);
      dm.setDrawingMode(drawingMode);

      const event = maps.event.addListener(dm, "overlaycomplete", (e: any) => {
        // console.log("overlaycomplete", e);
        onStartDrawing?.();
        setDrawingObject(e.overlay);
        dm.setMap(null);
      });

      setDrawingManager(dm);

      return () => {
        // console.log("useEffect return - aaa");
        maps.event.removeListener(event);
        dm.setMap(null);
        setDrawingManager(undefined);
      };
    }
  }, [map, maps, onStartDrawing, drawingMode, geometry]);

  useEffect(() => {
    if (infoWindow) {
      setSelectedMarker(infoWindow);
    }
  }, [infoWindow]);

  useEffect(() => {
    if (mode === "gps-tracking" && map && maps && tracks) {
      const ms = _.chain(tracks)
        .map((track, tindx) => {
          return _.map(track.data, (d, indx) => {
            if (indx === 0) {
              const m = new maps.Marker({
                position: { lat: d.loc[1], lng: d.loc[0] },
                map: map,
                zIndex: 2,
                clickable: true,
                icon: {
                  anchor: new maps.Point(14, 14),
                  size: new maps.Size(28, 28),
                  scaledSize: new maps.Size(28, 28),
                  url: `data:image/svg+xml;utf-8,
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(%23filter0_ddd)">
                  <circle cx="14" cy="14" r="10" fill="%23D81A26" stroke="white" stroke-width="2"/>
                  <text text-anchor="middle" alignment-baseline="middle"  fill="%23ffffff" x="14" y="15" font-size="9" font-family="rotobo">A${tindx}</text>
                  </g>
                  <defs>
                  <filter id="filter0_ddd" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="1.5"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dy="1"/>
                  <feGaussianBlur stdDeviation="0.5"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                  <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="0.5"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
                  <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                  </filter>
                  </defs>
                  </svg>`,
                },
              });
              m.addListener("click", () => {
                const info = {
                  drive_no: track.drive_no,
                  index: indx,
                  data: d,
                  sid: d.sid,
                  selectedSid: d.sid,
                };
                setSelectedMarker(info);
                onSetInfoWindow?.(info);
              });
              return m;
            } else if (indx !== track.data.length - 1) {
              const nextD = track.data[indx + 1];
              const heading = maps.geometry.spherical.computeHeading(
                new maps.LatLng(d.loc[1], d.loc[0]),
                new maps.LatLng(nextD.loc[1], nextD.loc[0])
              );

              const m = new maps.Marker({
                position: { lat: d.loc[1], lng: d.loc[0] },
                map: map,
                zIndex: 1,
                icon: {
                  anchor: new maps.Point(10, 10),
                  size: new maps.Size(20, 20),
                  scaledSize: new maps.Size(20, 20),
                  // url: `data:image/svg+xml;utf-8,
                  // <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  //   <g filter="url(%23filter0_ddd)" transform="rotate(${heading},10,10)">
                  //   <path d="M4 16.2632L10 3L16 16.2632L15.2 17L10 15L4.8 17L4 16.2632Z" fill="%230095E0"/>
                  //   <path d="M10.1795 14.5333L10 14.4643L9.82051 14.5333L4.91033 16.4219L4.60444 16.1401L10 4.2131L15.3956 16.1401L15.0897 16.4219L10.1795 14.5333Z" stroke="white"/>
                  //   </g>
                  //   <defs>
                  //   <filter id="filter0_ddd" x="1" y="0" width="18" height="20" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  //   <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  //   <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  //   <feOffset/>
                  //   <feGaussianBlur stdDeviation="1.5"/>
                  //   <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                  //   <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  //   <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  //   <feOffset dy="1"/>
                  //   <feGaussianBlur stdDeviation="0.5"/>
                  //   <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                  //   <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                  //   <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  //   <feOffset/>
                  //   <feGaussianBlur stdDeviation="0.5"/>
                  //   <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
                  //   <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
                  //   <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
                  //   </filter>
                  //   </defs>
                  // </svg>`,
                  url: `data:image/svg+xml;utf-8,
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g transform="rotate(${heading},10,10)">
                    <path d="M4 16.2632L10 3L16 16.2632L15.2 17L10 15L4.8 17L4 16.2632Z" fill="%230095E0"/>
                    <path d="M10.1795 14.5333L10 14.4643L9.82051 14.5333L4.91033 16.4219L4.60444 16.1401L10 4.2131L15.3956 16.1401L15.0897 16.4219L10.1795 14.5333Z" stroke="white"/>
                    </g>
                  </svg>`,
                },
              });
              m.addListener("click", () => {
                const info = {
                  drive_no: track.drive_no,
                  index: indx,
                  data: d,
                  sid: d.sid,
                  selectedSid: d.sid,
                };
                setSelectedMarker(info);
                onSetInfoWindow?.(info);
              });
              return m;
            }
          });
        })
        .flattenDeep()
        .compact()
        .value();
      return () => {
        _.forEach(ms, (m) => m.setMap(null));
      };
    }
  }, [onSetInfoWindow, mode, map, maps, tracks]);

  useEffect(() => {
    if (mode === "gps-tracking" && map && maps) {
      const m = _.find(
        gMarkers.current,
        (m) => m.object.sid === selectedMarker?.sid
      );
      if (m) {
        const d = m.object as ITrackData;
        m.prevIcon = m.getIcon();
        if (selectedMarker?.last) {
          m.setIcon({
            anchor: new maps.Point(19, 38),
            size: new maps.Size(38, 42),
            scaledSize: new maps.Size(38, 42),
            url: `data:image/svg+xml;utf-8,
            <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(%23filter0_d)">
            <path d="M32.5 17.7143C32.5 23.4606 28.7508 28.3588 23.5031 30.1757C22.8482 30.4025 22.3353 30.9237 21.9187 31.4855C21.513 32.0325 21.1524 32.6838 20.821 33.2823L20.7849 33.3475C20.4332 33.9822 20.1176 34.5436 19.7904 34.9497C19.4543 35.3668 19.2007 35.5 19 35.5C18.7997 35.5 18.5487 35.3681 18.2162 34.9528C17.8919 34.5479 17.5801 33.9882 17.2306 33.3537L17.2044 33.3061C16.8723 32.7031 16.5096 32.0444 16.0975 31.4906C15.6777 30.9265 15.1601 30.4053 14.4969 30.1757C9.24924 28.3588 5.5 23.4606 5.5 17.7143C5.5 10.4259 11.5344 4.5 19 4.5C26.4656 4.5 32.5 10.4259 32.5 17.7143Z" fill="%23D81A26" stroke="white"/>
            <ellipse cx="19" cy="17.7143" rx="5.83333" ry="5.71429" fill="%2313131C" fill-opacity="0.45"/>
            </g>
            <defs>
            <filter id="filter0_d" x="0" y="0" width="38" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.847059 0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0.45 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            </defs>
            </svg>`,
          });
        } else if (d.mode === "E") {
          m.setIcon({
            anchor: new maps.Point(19, 38),
            size: new maps.Size(38, 42),
            scaledSize: new maps.Size(38, 42),
            url: `data:image/svg+xml;utf-8,
            <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(%23filter0_d)">
            <path d="M32.5 17.7143C32.5 23.4606 28.7508 28.3588 23.5031 30.1757C22.8482 30.4025 22.3353 30.9237 21.9187 31.4855C21.513 32.0326 21.1524 32.6838 20.8209 33.2824C20.8089 33.3042 20.7969 33.3259 20.7849 33.3475C20.4332 33.9822 20.1176 34.5436 19.7904 34.9497C19.4543 35.3668 19.2007 35.5 19 35.5C18.7997 35.5 18.5487 35.3681 18.2162 34.9528C17.8919 34.5479 17.5801 33.9882 17.2306 33.3537L17.2044 33.3061C16.8723 32.7031 16.5096 32.0444 16.0975 31.4906C15.6777 30.9265 15.1601 30.4053 14.4969 30.1757C9.24924 28.3588 5.5 23.4606 5.5 17.7143C5.5 10.4259 11.5344 4.5 19 4.5C26.4656 4.5 32.5 10.4259 32.5 17.7143Z" fill="%23D81A26" stroke="white"/>
            <path d="M16.6535 12.8576C16.3204 12.6499 15.8889 12.8894 15.8889 13.2819V22.1468C15.8889 22.5392 16.3204 22.7787 16.6535 22.5711L23.7638 18.1386C24.0778 17.9429 24.0778 17.4857 23.7638 17.29L16.6535 12.8576Z" fill="white"/>
            </g>
            <defs>
            <filter id="filter0_d" x="0" y="0" width="38" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.847059 0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0.45 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            </defs>
            </svg>`,
          });
        } else if (d.mode === "P") {
          if (!d.pevents) {
            m.setIcon({
              anchor: new maps.Point(19, 38),
              size: new maps.Size(38, 42),
              scaledSize: new maps.Size(38, 42),
              url: `data:image/svg+xml;utf-8,
              <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(%23filter0_d)">
              <path d="M32.5 17.7143C32.5 23.4606 28.7508 28.3588 23.5031 30.1757C22.8482 30.4025 22.3353 30.9237 21.9187 31.4855C21.513 32.0326 21.1524 32.6838 20.8209 33.2824C20.8089 33.3042 20.7969 33.3259 20.7849 33.3475C20.4332 33.9822 20.1176 34.5436 19.7904 34.9497C19.4543 35.3668 19.2007 35.5 19 35.5C18.7997 35.5 18.5487 35.3681 18.2162 34.9528C17.8919 34.5479 17.5801 33.9882 17.2306 33.3537L17.2044 33.3061C16.8723 32.7031 16.5096 32.0444 16.0975 31.4906C15.6777 30.9265 15.1601 30.4053 14.4969 30.1757C9.24924 28.3588 5.5 23.4606 5.5 17.7143C5.5 10.4259 11.5344 4.5 19 4.5C26.4656 4.5 32.5 10.4259 32.5 17.7143Z" fill="%230095E0" stroke="white"/>
              <path d="M20.2153 11.5715H15.7917V22.7144H18.3194V19.0001H20.2153C22.307 19.0001 24.0069 17.3349 24.0069 15.2858C24.0069 13.2368 22.307 11.5715 20.2153 11.5715ZM20.3417 16.5239H18.3194V14.0477H20.3417C21.0368 14.0477 21.6055 14.6049 21.6055 15.2858C21.6055 15.9668 21.0368 16.5239 20.3417 16.5239Z" fill="white"/>
              </g>
              <defs>
              <filter id="filter0_d" x="0" y="0" width="38" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="2.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.584314 0 0 0 0 0.878431 0 0 0 0.45 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
              </defs>
              </svg>`,
            });
          } else {
            m.setIcon({
              anchor: new maps.Point(19, 38),
              size: new maps.Size(38, 42),
              scaledSize: new maps.Size(38, 42),
              url: `data:image/svg+xml;utf-8,
              <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(%23filter0_d)">
              <path d="M32.5 17.7143C32.5 23.4606 28.7508 28.3588 23.5031 30.1757C22.8482 30.4025 22.3353 30.9237 21.9187 31.4855C21.513 32.0326 21.1524 32.6838 20.8209 33.2824C20.8089 33.3042 20.7969 33.3259 20.7849 33.3475C20.4332 33.9822 20.1176 34.5436 19.7904 34.9497C19.4543 35.3668 19.2007 35.5 19 35.5C18.7997 35.5 18.5487 35.3681 18.2162 34.9528C17.8919 34.5479 17.5801 33.9882 17.2306 33.3537L17.2044 33.3061C16.8723 32.7031 16.5096 32.0444 16.0975 31.4906C15.6777 30.9265 15.1601 30.4053 14.4969 30.1757C9.24924 28.3588 5.5 23.4606 5.5 17.7143C5.5 10.4259 11.5344 4.5 19 4.5C26.4656 4.5 32.5 10.4259 32.5 17.7143Z" fill="%230095E0" stroke="white"/>
              <path d="M20.2153 11.5715H15.7917V22.7144H18.3194V19.0001H20.2153C22.307 19.0001 24.0069 17.3349 24.0069 15.2858C24.0069 13.2368 22.307 11.5715 20.2153 11.5715ZM20.3417 16.5239H18.3194V14.0477H20.3417C21.0368 14.0477 21.6055 14.6049 21.6055 15.2858C21.6055 15.9668 21.0368 16.5239 20.3417 16.5239Z" fill="white"/>
              <circle cx="29" cy="8" r="3.375" fill="%23D81A26" stroke="white" stroke-width="0.75"/>
              </g>
              <defs>
              <filter id="filter0_d" x="0" y="0" width="38" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="2.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.584314 0 0 0 0 0.878431 0 0 0 0.45 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
              </defs>
              </svg>`,
            });
          }
        }
      } else {
        _.forEach(gMarkers.current, (mm) => {
          if (mm.prevIcon) {
            mm.setIcon(mm.prevIcon);
            mm.prevIcon = undefined;
          }
        });
      }
      // console.log("selectedMarker", gMarkers.current);
    }
  }, [mode, map, maps, selectedMarker, tracks]);

  useEffect(() => {
    if (mode === "gps-tracking" && map && maps && tracks) {
      const ms = _.chain(tracks)
        .map((track) => {
          return _.map(track.data, (d, indx) => {
            if (d.hide) {
            } else if (d.mode === "E") {
              const m = new maps.Marker({
                position: { lat: d.loc[1], lng: d.loc[0] },
                zIndex: 3,
                map: map,
                icon: {
                  anchor: new maps.Point(17, 32),
                  size: new maps.Size(34, 38),
                  scaledSize: new maps.Size(34, 38),
                  url: `data:image/svg+xml;utf-8,
                  <svg width="34" height="38" viewBox="0 0 34 38" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(%23filter0_d)">
                  <path d="M28.5 16C28.5 21.0064 25.3006 25.2668 20.8334 26.8458C20.2503 27.0518 19.8004 27.5224 19.4415 28.0165C19.0913 28.4986 18.7805 29.0716 18.497 29.5942C18.4864 29.6137 18.4758 29.6332 18.4653 29.6526C18.1632 30.2093 17.8952 30.6954 17.6187 31.0457C17.3321 31.4088 17.1337 31.5 17 31.5C16.8667 31.5 16.6705 31.41 16.387 31.0486C16.1131 30.6993 15.8483 30.2148 15.548 29.6581L15.5247 29.615C15.2407 29.0885 14.9281 28.509 14.5724 28.0209C14.2105 27.5245 13.7566 27.0543 13.1666 26.8458C8.69938 25.2668 5.5 21.0064 5.5 16C5.5 9.64873 10.6487 4.5 17 4.5C23.3513 4.5 28.5 9.64873 28.5 16Z" fill="%23D81A26" stroke="white"/>
                  <path d="M15.1018 11.8224C14.7689 11.6106 14.3333 11.8497 14.3333 12.2442V19.7559C14.3333 20.1504 14.7689 20.3895 15.1018 20.1777L21.0038 16.4219C21.3125 16.2254 21.3125 15.7747 21.0038 15.5782L15.1018 11.8224Z" fill="white"/>
                  </g>
                  <defs>
                  <filter id="filter0_d" x="0" y="0" width="34" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dy="1"/>
                  <feGaussianBlur stdDeviation="2.5"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.847059 0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0.45 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                  </filter>
                  </defs>
                  </svg>`,
                },
              });

              m.object = d;
              m.addListener("click", () => {
                const info = {
                  drive_no: track.drive_no,
                  sid: d.sid,
                  index: indx,
                  data: d,
                  selectedSid: d.sid,
                };
                setSelectedMarker(info);
                onSetInfoWindow?.(info);
              });
              return m;
            } else if (d.mode === "P") {
              if (!d.pevents) {
                const m = new maps.Marker({
                  position: { lat: d.loc[1], lng: d.loc[0] },
                  zIndex: 3,
                  map: map,
                  icon: {
                    anchor: new maps.Point(17, 32),
                    size: new maps.Size(34, 38),
                    scaledSize: new maps.Size(34, 38),
                    url: `data:image/svg+xml;utf-8,
                    <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(%23filter0_d)">
                    <path d="M28.5 16C28.5 21.0064 25.3006 25.2668 20.8334 26.8458C20.2503 27.0518 19.8004 27.5224 19.4415 28.0165C19.0913 28.4986 18.7805 29.0716 18.497 29.5942C18.4864 29.6137 18.4758 29.6332 18.4653 29.6526C18.1632 30.2093 17.8952 30.6954 17.6187 31.0457C17.3321 31.4088 17.1337 31.5 17 31.5C16.8667 31.5 16.6705 31.41 16.387 31.0486C16.1131 30.6993 15.8483 30.2148 15.548 29.6581L15.5247 29.615C15.2407 29.0885 14.9281 28.509 14.5724 28.0209C14.2105 27.5245 13.7566 27.0543 13.1666 26.8458C8.69938 25.2668 5.5 21.0064 5.5 16C5.5 9.64873 10.6487 4.5 17 4.5C23.3513 4.5 28.5 9.64873 28.5 16Z" fill="%230095E0" stroke="white"/>
                    <path d="M18.0417 10.625H14.25V20.375H16.4167V17.125H18.0417C19.8346 17.125 21.2917 15.6679 21.2917 13.875C21.2917 12.0821 19.8346 10.625 18.0417 10.625ZM18.15 14.9583H16.4167V12.7917H18.15C18.7458 12.7917 19.2333 13.2792 19.2333 13.875C19.2333 14.4708 18.7458 14.9583 18.15 14.9583Z" fill="white"/>
                    </g>
                    <defs>
                    <filter id="filter0_d" x="0" y="0" width="34" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.584314 0 0 0 0 0.878431 0 0 0 0.45 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    </defs>
                    </svg>`,
                  },
                });

                m.object = d;
                m.addListener("click", () => {
                  const info = {
                    drive_no: track.drive_no,
                    sid: d.sid,
                    index: indx,
                    data: d,
                    selectedSid: d.sid,
                  };
                  setSelectedMarker(info);
                  onSetInfoWindow?.(info);
                });
                return m;
              } else {
                const m = new maps.Marker({
                  position: { lat: d.loc[1], lng: d.loc[0] },
                  zIndex: 3,
                  map: map,
                  icon: {
                    anchor: new maps.Point(17, 32),
                    size: new maps.Size(34, 38),
                    scaledSize: new maps.Size(34, 38),
                    url: `data:image/svg+xml;utf-8,
                    <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(%23filter0_d)">
                    <path d="M28.5 16C28.5 21.0064 25.3006 25.2668 20.8334 26.8458C20.2503 27.0518 19.8004 27.5224 19.4415 28.0165C19.0913 28.4986 18.7805 29.0716 18.497 29.5942C18.4864 29.6137 18.4758 29.6332 18.4653 29.6526C18.1632 30.2093 17.8952 30.6954 17.6187 31.0457C17.3321 31.4088 17.1337 31.5 17 31.5C16.8667 31.5 16.6705 31.41 16.387 31.0486C16.1131 30.6993 15.8483 30.2148 15.548 29.6581L15.5247 29.615C15.2407 29.0885 14.9281 28.509 14.5724 28.0209C14.2105 27.5245 13.7566 27.0543 13.1666 26.8458C8.69938 25.2668 5.5 21.0064 5.5 16C5.5 9.64873 10.6487 4.5 17 4.5C23.3513 4.5 28.5 9.64873 28.5 16Z" fill="%230095E0" stroke="white"/>
                    <path d="M18.0417 10.625H14.25V20.375H16.4167V17.125H18.0417C19.8346 17.125 21.2917 15.6679 21.2917 13.875C21.2917 12.0821 19.8346 10.625 18.0417 10.625ZM18.15 14.9583H16.4167V12.7917H18.15C18.7458 12.7917 19.2333 13.2792 19.2333 13.875C19.2333 14.4708 18.7458 14.9583 18.15 14.9583Z" fill="white"/>
                    <circle cx="25" cy="8" r="3.375" fill="%23D81A26" stroke="white" stroke-width="0.75"/>
                    </g>
                    <defs>
                    <filter id="filter0_d" x="0" y="0" width="34" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.584314 0 0 0 0 0.878431 0 0 0 0.45 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    </defs>
                    </svg>`,
                  },
                });

                m.object = d;
                m.addListener("click", () => {
                  const info = {
                    drive_no: track.drive_no,
                    sid: d.sid,
                    index: indx,
                    data: d,
                    selectedSid: d.sid,
                  };
                  setSelectedMarker(info);
                  onSetInfoWindow?.(info);
                });
                return m;
              }
            } else if (indx === track.data.length - 1) {
              const m = new maps.Marker({
                position: { lat: d.loc[1], lng: d.loc[0] },
                zIndex: 3,
                map: map,
                icon: {
                  anchor: new maps.Point(17, 32),
                  size: new maps.Size(34, 38),
                  scaledSize: new maps.Size(34, 38),
                  url: `data:image/svg+xml;utf-8,
                  <svg width="34" height="38" viewBox="0 0 34 38" xmlns="http://www.w3.org/2000/svg" >
                    <g filter="url(%23filter0_d)">
                    <path d="M28.5 16C28.5 21.0064 25.3006 25.2668 20.8334 26.8458C20.2503 27.0518 19.8004 27.5224 19.4415 28.0165C19.0913 28.4986 18.7805 29.0716 18.497 29.5942C18.4864 29.6137 18.4758 29.6332 18.4653 29.6526C18.1632 30.2093 17.8952 30.6954 17.6187 31.0457C17.3321 31.4088 17.1337 31.5 17 31.5C16.8667 31.5 16.6705 31.41 16.387 31.0486C16.1131 30.6993 15.8483 30.2148 15.548 29.6581L15.5247 29.615C15.2407 29.0885 14.9281 28.509 14.5724 28.0209C14.2105 27.5245 13.7566 27.0543 13.1666 26.8458C8.69938 25.2668 5.5 21.0064 5.5 16C5.5 9.64873 10.6487 4.5 17 4.5C23.3513 4.5 28.5 9.64873 28.5 16Z" fill="%23D81A26" stroke="white"/>
                    <circle cx="17" cy="16" r="5" fill="%2313131C" fill-opacity="0.45"/>
                    </g>
                    <defs>
                    <filter id="filter0_d" x="0" y="0" width="34" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.847059 0 0 0 0 0.101961 0 0 0 0 0.14902 0 0 0 0.45 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    </defs>
                  </svg>`,
                },
              });

              m.object = d;
              m.addListener("click", () => {
                const info = {
                  drive_no: track.drive_no,
                  sid: d.sid,
                  index: indx,
                  data: d,
                  last: true,
                  selectedSid: d.sid,
                };
                setSelectedMarker(info);
                onSetInfoWindow?.(info);
              });
              return m;
            }
          });
        })
        .flattenDeep()
        .compact()
        .value();

      gMarkers.current = ms;
      // console.log("update", ms, gMarkers.current);
      // _.forEach(ms, (m) => m.setMap(map));
      // _.forEach(gMarkers.current, (m) => m.setMap(null));
      // gMarkers.current = ms;

      return () => {
        _.forEach(ms, (m) => m.setMap(null));
      };
    }
  }, [
    classes.startMarkerIcon,
    classes.markerIcon,
    classes.directionMakerIcon,
    classes.selectedMarkerIcon,
    map,
    maps,
    tracks,
    onSetInfoWindow,
    mode,
  ]);

  useEffect(() => {
    // console.log("update track bounds", map, maps, tracks, trackBoundsUpdate);
    if (
      mode === "gps-tracking" &&
      map &&
      maps &&
      interval &&
      tracks &&
      tracks.length > 0
    ) {
      let minLat = 999;
      let minLng = 999;
      let maxLat = -999;
      let maxLng = -999;

      setTrackBoundsUpdate(false);
      const polylines = _.chain(tracks)
        .map((track) => {
          let pathMinLat = 999;
          let pathMinLng = 999;
          let pathMaxLat = -999;
          let pathMaxLng = -999;
          const selected = track.drive_no === selectedTrack?.drive_no;
          const connected: boolean[] = [];
          for (let i = 0; i < track.data.length - 1; i++) {
            if (
              interval < 5 &&
              track.data[i + 1].vdate.diff(track.data[i].vdate, "s") > 180
            ) {
              connected.push(false);
            } else {
              connected.push(true);
            }
          }
          connected.push(true);
          const indexList: {
            sindx: number;
            eindx: number;
            connected: boolean;
          }[] = [];
          let sindex = 0;
          for (let i = 0; i < connected.length - 1; i++) {
            if (connected[i] !== connected[i + 1]) {
              indexList.push({
                sindx: sindex,
                eindx: i + 1,
                connected: connected[i],
              });
              sindex = i + 1;
            }
          }
          indexList.push({
            sindx: sindex,
            eindx: connected.length - 1,
            connected: true,
          });

          const p = _.map(indexList, (info) => {
            const pathsCoords = _.chain(track.data)
              .slice(info.sindx, info.eindx + 1)
              .map((d) => {
                pathMinLat = Math.min(pathMinLat, d.loc[1]);
                pathMinLng = Math.min(pathMinLng, d.loc[0]);
                pathMaxLat = Math.max(pathMaxLat, d.loc[1]);
                pathMaxLng = Math.max(pathMaxLng, d.loc[0]);
                return {
                  lat: d.loc[1],
                  lng: d.loc[0],
                };
              })
              .value();

            minLat = Math.min(minLat, pathMinLat);
            minLng = Math.min(minLng, pathMinLng);
            maxLat = Math.max(maxLat, pathMaxLat);
            maxLng = Math.max(maxLng, pathMaxLng);

            if (info.connected) {
              const path2 = new maps.Polyline({
                path: pathsCoords,
                geodesic: true,
                strokeWeight: selected ? 8 : 4,
                strokeColor: LightColors.primary["0"],
                strokeOpacity: 1.0,
                zIndex: 1,
                clickable: true,
              });
              path2.setMap(map);
              path2.addListener("click", () => {
                onSelectTrack?.(track);
              });
              const path1 = new maps.Polyline({
                path: pathsCoords,
                geodesic: true,
                strokeWeight: selected ? 6 : 3,
                strokeColor: LightColors.primary["1"],
                strokeOpacity: 1,
                zIndex: 2,
                clickable: true,
              });
              path1.setMap(map);
              path1.addListener("click", () => {
                onSelectTrack?.(track);
              });
              return [path1, path2];
            } else {
              const path2 = new maps.Polyline({
                path: pathsCoords,
                geodesic: true,
                strokeWeight: 8,
                strokeOpacity: 0,
                icons: [
                  {
                    icon: {
                      path: "M 0,-1 0,1",
                      strokeOpacity: 1,
                      scale: selected ? 8 : 4,
                      strokeColor: LightColors.primary["0"],
                    },
                    offset: "0",
                    repeat: selected ? "30px" : "15px",
                  },
                ],
                zIndex: 1,
                clickable: true,
              });
              path2.setMap(map);
              path2.addListener("click", () => {
                onSelectTrack?.(track);
              });
              const path1 = new maps.Polyline({
                path: pathsCoords,
                geodesic: true,
                strokeColor: LightColors.primary["1"],
                strokeOpacity: 0,
                icons: [
                  {
                    icon: {
                      path: "M 0,-1 0,1",
                      strokeOpacity: 0.85,
                      scale: selected ? 6 : 3,
                    },
                    offset: "0",
                    repeat: selected ? "30px" : "15px",
                  },
                ],
                zIndex: 2,
                clickable: true,
              });
              path1.setMap(map);
              path1.addListener("click", () => {
                onSelectTrack?.(track);
              });
              return [path1, path2];
            }
          });

          if (
            selected &&
            selectedTrack?.drive_no !== prevSelectedTrack?.drive_no
          ) {
            enableTrackLoad.current = false;
            map.fitBounds(
              {
                east: pathMaxLng,
                north: pathMaxLat,
                west: pathMinLng,
                south: pathMinLat,
              }
              // mobile ? MOBILE_BOUNDS_PADDING : PC_TRACK_BOUNDS_PADDING
            );
          }
          return p;
        })
        .flattenDeep()
        .value();

      if (trackBoundsUpdate) {
        map.fitBounds(
          {
            east: maxLng,
            north: maxLat,
            west: minLng,
            south: minLat,
          },
          mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
        );
      }
      return () => {
        _.forEach(polylines, (line) => line.setMap(null));
      };
    }
  }, [
    onSelectTrack,
    map,
    maps,
    mobile,
    tracks,
    interval,
    trackBoundsUpdate,
    selectedTrack,
    mode,
    prevSelectedTrack?.drive_no,
  ]);

  useEffect(() => {
    if (map && maps) {
      const idleListener = map.addListener("idle", () => {
        const newBounds = map.getBounds().toJSON();
        if (!_.isEqual(bounds, newBounds)) {
          setBounds(newBounds);
        }
        enableTrackLoad.current = true;
      });
      const zoomListener = map.addListener("zoom_changed", () => {
        if (map.initialZoom === true) {
          const boundsListener = map.addListener("bounds_changed", () => {
            if (map.getZoom() > 15) {
              // Change max/min zoom here
              map.setZoom(15);
              map.initialZoom = false;
            }
            //@ts-ignore
            maps.event.removeListener(boundsListener);
          });
        }
      });

      const clickListener = map.addListener("click", () => {
        setSelectedMarker(undefined);
        onSetInfoWindow?.(undefined);
      });
      return () => {
        //@ts-ignore
        maps.event.removeListener(zoomListener);
        maps.event.removeListener(clickListener);
        maps.event.removeListener(idleListener);
      };
    }
  }, [onSetInfoWindow, map, maps, bounds]);

  useEffect(() => {
    if (map && trackMarker && location) {
      map.setCenter({ lat: location.lat, lng: location.lng });
    }
  }, [trackMarker, location, map]);

  useEffect(() => {
    if (clusters && map && publicIcon) {
      let minLat = 999,
        minLng = 999,
        maxLat = -999,
        maxLng = -999;
      for (let indx in clusters["zone count"].info) {
        const zone = clusters["zone count"].info[indx];
        const lat = parseFloat(zone.lat);
        const lng = parseFloat(zone.lon);
        const cnt = parseInt(zone.count);
        minLat = Math.min(minLat, lat);
        minLng = Math.min(minLng, lng);
        maxLat = Math.max(maxLat, lat);
        maxLng = Math.max(maxLng, lng);
        setMarkers((ms) => ({
          ...ms,
          [`zone-${indx}`]: (
            <MarkerCluster
              key={`zone-${indx}`}
              lat={lat}
              lng={lng}
              size={cnt < 100 ? "small" : "medium"}
              variant="outlined"
              label={zone.count}
            />
          ),
        }));
      }
      for (let indx in clusters.myzone.info) {
        const zone = clusters.myzone.info[indx];
        const lat = parseFloat(zone.lat);
        const lng = parseFloat(zone.lon);
        const cnt = parseInt(zone.count);
        minLat = Math.min(minLat, lat);
        minLng = Math.min(minLng, lng);
        maxLat = Math.max(maxLat, lat);
        maxLng = Math.max(maxLng, lng);
        setMarkers((ms) => ({
          ...ms,
          [`myzone-${indx}`]: (
            <MarkerCluster
              key={`myzone-${indx}`}
              lat={lat}
              lng={lng}
              size={cnt < 100 ? "small" : "medium"}
              variant="outlined"
              label={zone.count}
            />
          ),
        }));
      }

      map.fitBounds({
        east: maxLng,
        north: maxLat,
        west: minLng,
        south: minLat,
      });
    }
  }, [map, clusters, publicIcon]);

  const handleDashcamPublic = useCallback(() => {
    dispatch(loadCluster());
  }, [dispatch]);

  const handleFullscreen = useCallback(() => {
    const elementToSendFullscreen = map.getDiv().firstChild as HTMLElement;
    if (isFullscreen(elementToSendFullscreen)) {
      exitFullscreen(elementToSendFullscreen);
    } else {
      requestFullscreen(elementToSendFullscreen);
    }
  }, [map]);

  const renderMapTopRightMenu = useCallback(() => {
    return (
      <div className={classes.topControlPane}>
        <TopRightControl
          ref={anchorRef}
          onFullscreen={fullscreenIcon ? handleFullscreen : undefined}
          onLayers={() => setOpenLayer((o) => !o)}
          onDashcamPublic={publicIcon ? handleDashcamPublic : undefined}
        />
        {!mobile && (
          <Menu
            open={openLayer}
            onClickAway={() => setOpenLayer(false)}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            modifiers={{ offset: { enabled: true, offset: "0, 4px" } }}
          >
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "satellite",
              })}
              startIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "map",
              })}
              startIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </Menu>
        )}
        {mobile && (
          <MobileMenu open={openLayer} onClose={() => setOpenLayer(false)}>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </MobileMenu>
        )}
      </div>
    );
  }, [
    classes.topControlPane,
    classes.tnpDiv,
    classes.appIcon,
    classes.mobileMemuItem,
    map,
    fullscreenIcon,
    handleFullscreen,
    publicIcon,
    handleDashcamPublic,
    mobile,
    openLayer,
    mapMode,
    t,
  ]);

  const renderZoomPanel = useCallback(() => {
    return (
      <div className={classes.zoomControlDiv}>
        <Tooltip title={t("My location") ?? "My location"}>
          <Fab
            size="small"
            variant="rounded"
            className={clsx(classes.zoomControlBtn, classes.gpsFixBtn)}
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position: GeolocationPosition) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };
                    map.setCenter(pos);
                  },
                  () => {}
                );
              }
            }}
          >
            <GpsFixedIcon fontSize="small" />
          </Fab>
        </Tooltip>
        {!mobile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 8,
              boxShadow:
                "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Tooltip title={t("Zoom in") ?? "Zoom in"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomInBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() + 1);
                }}
              >
                <AddIcon fontSize="small" />
              </Fab>
            </Tooltip>
            <Tooltip title={t("Zoom out") ?? "Zoom out"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomOutBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() - 1);
                }}
              >
                <RemoveIcon fontSize="small" />
              </Fab>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }, [
    classes.zoomOutBtn,
    classes.zoomInBtn,
    classes.zoomControlDiv,
    classes.zoomControlBtn,
    classes.gpsFixBtn,
    t,
    map,
    mobile,
  ]);

  useEffect(() => {
    const data = selectedMarker?.data;
    if (data && map) {
      // console.log("boundsss");
      // map.fitBounds(
      //   {
      //     east: data.loc[0],
      //     north: data.loc[1],
      //     west: data.loc[0],
      //     south: data.loc[1],
      //   },
      //   mobile ? MOBILE_BOUNDS_PADDING : PC_INFO_BOUNDS_PADDING
      // );
      enableTrackLoad.current = false;
      map.setCenter({ lat: data.loc[1], lng: data.loc[0] });
    }
  }, [map, mobile, selectedMarker]);

  const makeEventItem = useCallback(
    (data: ITrackData, noBorder?: boolean) => {
      let eventString = "";
      if (data.mode === "E") {
        if (data.mtype === 2) {
          eventString = t("G-force detected");
        } else if (data.mtype === 1) {
          eventString = t("Overspeed detected");
        }
      }
      console.log(data);
      return (
        <EventItem
          className={clsx(classes.infoWindowDiv, {
            [classes.infoWindowCenter]: !noBorder,
          })}
          heading={data.vdate.format("MMM DD YYYY, h:mm:ss A")}
          speed={`${Math.round(data.avg_speed)} km/h`}
          fileType={EventAbbrToEventFull[data.mode]}
          content={eventString}
          content2={`${data.loc[0]}, ${data.loc[1]}`}
          contentAlt={`${t("Altitude :")} ${data.alt}m`}
          hoverCard
          noBorder
          thumbnail={data.thm}
          zoomBtn
          lat={data.loc[1]}
          lng={data.loc[0]}
          play={data.mp4_playable === 1}
          onPlay={() => {
            console.log("onPlay");
            if (data) {
              onPlay?.(data);
            }
          }}
          onZoom={() => {
            if (map) {
              map.fitBounds(
                {
                  east: data.loc[0],
                  north: data.loc[1],
                  west: data.loc[0],
                  south: data.loc[1],
                },
                mobile ? MOBILE_BOUNDS_PADDING : PC_BOUNDS_PADDING
              );
              enableTrackLoad.current = false;
            }
          }}
          onDownload={async () => {
            if (data) {
              onDownload?.(data);
            }
          }}
        />
      );
    },
    [
      t,
      onDownload,
      onPlay,
      classes.infoWindowDiv,
      classes.infoWindowCenter,
      map,
      mobile,
    ]
  );

  const renderInfoWindow = useCallback(() => {
    const data = selectedMarker?.data;
    if (data && map) {
      if (!data.pevents) {
        return makeEventItem(data);
      } else {
        return (
          <div
            //@ts-ignore
            lat={data.loc[1]}
            //@ts-ignore
            lng={data.loc[0]}
            className={clsx(
              classes.infoWindowDiv,
              classes.infoWindowCenter,
              classes.parkingDiv
            )}
          >
            <div className={classes.inforParkingTitle}>
              <Typography category="Default" variant="SmallBold">
                {t("Parking events")} · {data.pevents.length}
              </Typography>
            </div>
            <div style={{ overflowY: "auto" }}>
              {_.map(data.pevents, (event) => (
                <div key={`event-list-${event.sid}`}>
                  {makeEventItem(event, true)}
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
    return undefined;
  }, [
    t,
    classes.inforParkingTitle,
    classes.parkingDiv,
    classes.infoWindowDiv,
    classes.infoWindowCenter,
    map,
    makeEventItem,
    selectedMarker,
  ]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <GoogleMapReact
        style={{
          width: "100%",
          height: "100%",
          //@ts-ignore
          position: "relative",
          touchAction: "none",
        }}
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
          libraries: ["geometry", "drawing"],
        }}
        defaultCenter={{
          lat: 36.64348507313869,
          lng: 125.97305899734482,
        }}
        defaultZoom={4}
        options={{ disableDefaultUI: true }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ maps, map, ref }) => {
          setMap(map);
          setMaps(maps);
          onGoogleApiLoaded?.({ maps, map, ref });
        }}
        // onChange={(value) => console.log(value)}
      >
        {_.values(markers)}
        {children}
        {selectedMarker && renderInfoWindow()}
      </GoogleMapReact>
      {renderMapTopRightMenu()}
      {renderZoomPanel()}
    </div>
  );
};
