import axios from "axios";
import { API_GATEWAY_URI, API_SERVER_URI } from "../contants/Server";
import { IDelUploadList } from "../features/VOD/slice";

export const getVODList = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  userToken: string,
  psn: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/vod_list?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const getVODToken = (
  email: string,
  userToken: string,
  psn: string,
  filename: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/vodPlayReq.php?email=${email}&user_token=${userToken}&psn=${psn}&filename=${filename}&token_type=web`
  );
};

export const getThumbnail = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  userToken: string,
  psn: string,
  filename: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/vod_file?email=${email}&user_token=${userToken}&psn=${psn}&filename=${filename}&tokenType=web`,
    { responseType: "arraybuffer" }
  );
};

export const getVOD = (
  lb_server_name: string,
  lb_http_port: string,
  email: string,
  userToken: string,
  psn: string,
  filename: string,
  vod_token: string
) => {
  return axios.get(
    `https://${lb_server_name}:${lb_http_port}/proc/vod_file?email=${email}&user_token=${userToken}&psn=${psn}&filename=${filename}&vod_token=${vod_token}&tokenType=web`,
    { responseType: "arraybuffer" }
  );
};

export const getCloudVODList = (
  email: string,
  userToken: string,
  psn: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/s3FileList.php?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const getCloudVODFile = (
  email: string,
  userToken: string,
  psn: string,
  filename: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/userS3PresignedUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&filename=${filename}&tokenType=web`
  );
};

export const deleteCloudVODFile = (
  email: string,
  userToken: string,
  psn: string,
  filenames: string[],
  vodCount?: number
) => {
  return axios.post(
    `${API_SERVER_URI}/BCS/multiDcf.php`,
    {
      email,
      user_token: userToken,
      psn,
      s3_mfd_info: {
        type: "select",
        filelist: filenames,
      },
      tokenType: "web",
      vodCount,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export const getEventVODList = (
  email: string,
  userToken: string,
  psn: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/evtLrGetList.php?email=${email}&user_token=${userToken}&psn=${psn}&tokenType=web`
  );
};

export const getEventVODFile = (
  email: string,
  userToken: string,
  psn: string,
  rid: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
  );
};

export const deleteEventVODFile = (
  email: string,
  userToken: string,
  psn: string,
  rids: string[]
) => {
  // return axios.get(
  //   `${API_SERVER_URI}/BCS/evtLrDelFile.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
  // );
  return axios.delete(`${API_GATEWAY_URI}/CloudService/DeleteEventUpload`, {
    data: {
      email,
      userToken,
      tokenType: "web",
      psn,
      rid: rids,
    },
  });
};

export const moveEventVODFile = (
  email: string,
  userToken: string,
  psn: string,
  rids: string[]
) => {
  // return axios.put(`${API_GATEWAY_URI}/CloudService/MoveEventUpload`, {
  //   email,
  //   userToken,
  //   psn,
  //   rid: rids,
  //   tokenType: "web",
  // });
  return axios.put(`${API_GATEWAY_URI}/CloudService/MoveEventUpload`, {
    email,
    userToken,
    tokenType: "web",
    psn,
    rid: rids,
  });
};

export const uploadVODFile = (
  upload_server: string,
  upload_port: string,
  email: string,
  userToken: string,
  psn: string,
  filename: string
) => {
  return axios.post(
    `https://${upload_server}:${upload_port}/BCS/upload_req`,
    {
      email,
      user_token: userToken,
      psn,
      filename,
      token_type: "web",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getVODUploadList = (
  upload_server: string,
  upload_port: string,
  email: string,
  userToken: string,
  psn: string
) => {
  // if (process.env.NODE_ENV === "development") {
  //   return axios.post(
  //     `/upload/BCS/queue_list`,
  //     {
  //       email,
  //       user_token: userToken,
  //       psn,
  //       token_type: "web",
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );
  // }
  return axios.post(
    `https://${upload_server}:${upload_port}/BCS/queue_list`,
    {
      email,
      user_token: userToken,
      psn,
      token_type: "web",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteVODUploadList = (
  upload_server: string,
  upload_port: string,
  email: string,
  userToken: string,
  psn: string,
  removelist: IDelUploadList[]
) => {
  return axios.post(
    `https://${upload_server}:${upload_port}/BCS/queue_delete`,
    {
      email,
      user_token: userToken,
      psn,
      token_type: "web",
      removelist,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
