import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  makeStyles,
  Modal,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { WebviewerMenu } from "../components/WebviewerMenu";
import { WebviewerHeader } from "../components/WebviewerHeader";
import clsx from "clsx";
import {
  Avatars,
  Button,
  GooglePlay,
  IconButton,
  LightColors,
  LogoOnly,
  Toast,
  Typography,
  Modal as ModalPricing,
} from "@thingsw/pitta-design-system";
import { Webviewer } from "../contants/Breakpoints";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { closeToast, TOAST } from "../features/Toast/slice";
import { useTranslation } from "react-i18next";
import { WebviewerMenuList } from "../components/WebviewerMenuList";

import {
  loadUserNotification,
  loadUserPermissions,
  logout,
  USER,
} from "../features/User/slice";
import { PAYMENT } from "../features/Payment/slice";
import { getPlanFromServiceID, PlanType } from "../utils/Service";

import CloseIcon from "@material-ui/icons/Close";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import LanguageIcon from "@material-ui/icons/Language";
import AppleIcon from "@material-ui/icons/Apple";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Languages from "../contants/Languages";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { LangMenuItems } from "../components/LangMenuItems";
import { clearError, ERROR } from "../features/Error/slice";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { disconnectWebPush, loadPushToken } from "../features/PushEvent/slice";
import { CAMERA, loadCameras } from "../features/Camera/slice";
import { ERROR_TIMEOUT } from "../contants/Common";
import { Pricing } from "../components/Pricing";
import { UserNames } from "../features/Member/slice";
import { setPermission } from "../features/Permission/slice";
import {
  changeDirection,
  setFontFamily,
  setMenuOpen,
  THEME,
} from "../features/Theme/slice";
import WebPushNotification from "../components/WebPushNotification";
import "simplebar/src/simplebar.css";
import { ScrollBar } from "../components/ScrollBar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(var(--vh, 1vh) * 100)",
    backgroundColor: LightColors.primary["0"],
    transition: theme.transitions.create("margin"),
    // padding: theme.spacing(0, 4),
    // marginTop: theme.spacing(7),
  },
  rootOpen: {
    ...(theme.direction === "rtl" ? { marginRight: 235 } : { marginLeft: 235 }),
    // maxWidth: 1920 - 235,
  },
  rootClose: {
    ...(theme.direction === "rtl" ? { marginRight: 73 } : { marginLeft: 73 }),
    maxWidth: 1920 - 73,
  },
  rootMapOpen: {
    maxWidth: "calc(100vw - 235px)",
  },
  rootMapClose: {
    maxWidth: "calc(100vw - 73px)",
  },
  mobileDrawerPaper: {
    width: "100%",
  },
  mobileMenuDiv: {
    backgroundColor: LightColors.primary["0"],
    width: "100%",
    height: "calc(var(--vh, 1vh) * 100)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    transition: theme.transitions.create("transform"),
  },
  langMobileDiv: {
    justifyContent: "flex-start",
  },
  mainMenuClose: {
    transform: "translateX(-100%)",
  },
  langDivClose: {
    transform: "translateX(100%)",
  },
  langDivOpen: {
    transform: "translateX(0)",
  },
  mobileDrawerDiv: {
    // width: "100%",
    flex: 1,
    overflowY: "auto",
  },
  mobileLogoDiv: {
    display: "flex",
    minHeight: 56,
    padding: theme.spacing(1.25, 2),
    borderBottom: `1px solid  ${LightColors.primary["6"]}`,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  userInfoDiv: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(2) }
      : { marginLeft: theme.spacing(2) }),
  },
  menuListDiv: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid  ${LightColors.primary["6"]}`,
  },
  upgradeBtn: {
    margin: theme.spacing(2),
  },
  btnDiv: {
    padding: theme.spacing(2, 3),
  },
  menuBtnDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.75, 0),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:active": {
      color: LightColors.primary["8"],
    },
  },
  appIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.875) }
      : { marginRight: theme.spacing(1.875) }),
  },
  langIcon: {
    // fontSize: 18,
    padding: theme.spacing(0, 0.25),
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.875) }
      : { marginRight: theme.spacing(1.875) }),
  },
  textOnly: {
    margin: theme.spacing(0, 4.75),
    color: LightColors.primary["3"],
  },
  backBtn: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  modalContent: { padding: 0 },
}));

export interface LocationHistory {
  to: string;
  from: string;
}

export interface ScreenDefaultProps {
  openMenu: boolean;
  error?: string;
  plan?: PlanType;
  headerDivRef?: HTMLDivElement;
}

export interface PermissionProps {
  uploadVodPerm: boolean;
  downloadVodPerm: boolean;
  deleteVodPerm: boolean;
  moveToCloudVodPerm: boolean;
  cameraVodPerm: boolean;
  cloudVodPerm: boolean;

  fotaCamPerm: boolean;
  settingsCamPerm: boolean;
  renameCamPerm: boolean;
  deleteCamPerm: boolean;
  rebootCamPerm: boolean;

  gpsTrackingPerm: boolean;
  geofencePerm: boolean;
  reportPerm: boolean;
  liveviewPerm: boolean;
  multiLiveviewPerm: boolean;

  privacyPerm: boolean;
  pushNotificationsPerm: boolean;
  eventAutoUploadPerm: boolean;
  firmwareSettingPerm: boolean;

  downloadCSVReportPerm: boolean;

  moreTabPerm: boolean;
}

const withViewerTemplate =
  (WrappedComponent: React.FC<any>) => (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const theme = useTheme() as Theme;
    const defaultMobile = useMediaQuery(
      theme.breakpoints.down(Webviewer.mobile)
    );
    const geofenceMobile = useMediaQuery(
      theme.breakpoints.down(Webviewer.geofenceMobile)
    );
    const history = useHistory();

    const headerDivRef = useRef<HTMLDivElement>(null);

    const {
      userProfile,
      permissions: perm,
      userNotification,
    } = useSelector((state: RootState) => state[USER]);
    const { subscriptionInfo } = useSelector(
      (state: RootState) => state[PAYMENT]
    );
    const { open, message, option } = useSelector(
      (state: RootState) => state[TOAST]
    );
    const { camera } = useSelector((state: RootState) => state[CAMERA]);
    const { error } = useSelector((state: RootState) => state[ERROR]);
    const { menuOpen } = useSelector((state: RootState) => state[THEME]);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [openLangMenu, setOpenLangMenu] = useState(false);
    const [withMap, setWithMap] = useState(false);
    const [openPricing, setOpenPricing] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [planName, setPlanName] = useState<string>();
    const [pathname, setPathname] = useState<string>();

    useEffect(() => {
      if (subscriptionInfo) {
        setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
      }
    }, [subscriptionInfo]);

    useEffect(() => {
      if (location.pathname.startsWith("/geofences")) {
        setMobile(geofenceMobile);
      } else {
        setMobile(defaultMobile);
      }
    }, [defaultMobile, geofenceMobile, location.pathname]);

    useEffect(() => {
      setPathname(location.pathname);
      if (pathname) {
        setOpenPricing(false);
      }
    }, [location.pathname, pathname]);

    const permissions = useMemo(() => {
      return {
        uploadVodPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.cloudUploadManual) ||
          subscriptionInfo?.servicePlanID === 1,
        downloadVodPerm:
          _.includes(["Master", "SubMaster"], userProfile?.userType) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        deleteVodPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.fileDelete) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        moveToCloudVodPerm:
          _.includes(["Master"], userProfile?.userType) ||
          (userProfile?.userType === "SubMaster" && perm?.fileMove) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        cameraVodPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.myBlackVueFilePlay) ||
          (userProfile?.userType === "User" && perm?.myBlackVueFilePlay) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        cloudVodPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.cloudFilePlay) ||
          (userProfile?.userType === "User" && perm?.cloudFilePlay) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        fotaCamPerm:
          _.includes(["Master", "SubMaster"], userProfile?.userType) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        settingsCamPerm:
          // _.includes(["Master", "SubMaster"], userProfile?.userType) ||
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" &&
            (perm?.privacy ||
              perm?.eventAutoUpload ||
              perm?.pushNotifications ||
              perm?.firmwareSettings)) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        renameCamPerm:
          _.includes(["Master", "SubMaster"], userProfile?.userType) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        deleteCamPerm:
          _.includes(["Master"], userProfile?.userType) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",

        rebootCamPerm:
          _.includes(["Master"], userProfile?.userType) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",

        // Playback more Tab 권한 추가

        moreTabPerm:
          _.includes(["Master", "SubMaster"], userProfile?.userType) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",

        //

        gpsTrackingPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.gpsTracking) ||
          planName === "Smart plan",
        geofencePerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.geoFence),
        reportPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.driveReports) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        liveviewPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.liveView !== -1) ||
          (userProfile?.userType === "User" && perm?.liveView !== -1) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        // 멀티 라이브뷰 권한
        multiLiveviewPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.liveView !== -1) ||
          (userProfile?.userType === "User" && perm?.liveView !== -1),
        privacyPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.privacy) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        pushNotificationsPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.pushNotifications) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        eventAutoUploadPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.eventAutoUpload) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        firmwareSettingPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.firmwareSettings) ||
          subscriptionInfo?.servicePlanID === 1 ||
          planName === "Smart plan" ||
          planName === "Basic plan",
        downloadCSVReportPerm:
          userProfile?.userType === "Master" ||
          (userProfile?.userType === "SubMaster" && perm?.driveReports),
      } as PermissionProps;
    }, [
      perm?.cloudFilePlay,
      perm?.cloudUploadManual,
      perm?.driveReports,
      perm?.eventAutoUpload,
      perm?.fileDelete,
      perm?.fileMove,
      perm?.firmwareSettings,
      perm?.geoFence,
      perm?.gpsTracking,
      perm?.liveView,
      perm?.myBlackVueFilePlay,
      perm?.privacy,
      perm?.pushNotifications,
      planName,
      subscriptionInfo?.servicePlanID,
      userProfile?.userType,
    ]);

    useEffect(() => {
      dispatch(setPermission(permissions));
    }, [dispatch, permissions]);

    useEffect(() => {
      if (userNotification && userNotification.device_alarm === "on") {
        dispatch(loadPushToken());

        return () => {
          console.log("disconnectWebPush");
          dispatch(disconnectWebPush());
        };
      }
    }, [dispatch, userNotification]);

    useEffect(() => {
      dispatch(loadCameras());
      dispatch(loadUserPermissions());
      dispatch(loadUserNotification());
    }, [dispatch]);

    useEffect(() => {
      dispatch(clearError());
      const regex = new RegExp("^/cameras/([A-za-z0-9]+)/gps-tracking");
      if (
        _.includes(
          [
            "/cameras",
            "/gps-tracking",
            "/geofences",
            "/geofences/add-geofence",
          ],
          location.pathname
        ) ||
        !!location.pathname.match(regex)
      ) {
        setWithMap(true);
      } else {
        setWithMap(false);
      }
    }, [dispatch, location]);

    const plan = useMemo(
      () =>
        subscriptionInfo &&
        getPlanFromServiceID(subscriptionInfo.servicePlanID),
      [subscriptionInfo]
    );

    const shownError = useMemo(() => {
      const regex1 = new RegExp("^/cameras/([A-za-z0-9]+)/playback");
      const regex2 = new RegExp("^/cameras/([A-za-z0-9]+)/live-view");
      if (
        !!location.pathname.match(regex1) ||
        !!location.pathname.match(regex2)
      ) {
        return error ?? camera?.active === "off" ? "e" : undefined;
      }
      return error;
    }, [camera?.active, error, location.pathname]);

    useEffect(() => {
      if (error) {
        const timerId = setTimeout(() => {
          dispatch(clearError());
        }, ERROR_TIMEOUT);
        return () => {
          clearTimeout(timerId);
        };
      }
    }, [dispatch, error]);

    const upgradeBtnMarkup = useMemo(() => {
      if (
        subscriptionInfo &&
        (_.includes([1, 1004, 1005], subscriptionInfo.servicePlanID) ||
          (subscriptionInfo.servicePlanID >= 10001 &&
            subscriptionInfo.servicePlanID <= 10003))
      ) {
        return (
          <div className={classes.upgradeBtn}>
            <Button
              color="primary"
              fullWidth
              startIcon={<CardGiftcardIcon />}
              onClick={() => {
                setOpenPricing(true);
              }}
            >
              {t("Upgrade Plan")}
            </Button>
          </div>
        );
      }
    }, [classes.upgradeBtn, subscriptionInfo, t]);

    const toggleMenuOpen = useCallback(() => {
      dispatch(setMenuOpen(!menuOpen));
    }, [dispatch, menuOpen]);

    return (
      <div style={{ backgroundColor: LightColors.primary["0"] }}>
        <ScrollBar style={{ height: "calc(var(--vh, 1vh) * 100)" }}>
          {!mobile && (
            <WebviewerMenu
              open={menuOpen}
              onToggleMenu={() => toggleMenuOpen()}
            />
          )}
          <div
            className={clsx(classes.root, {
              [classes.rootOpen]: menuOpen && !mobile,
              [classes.rootMapOpen]: menuOpen && withMap && !mobile,
              [classes.rootClose]: !menuOpen && !mobile,
              [classes.rootMapClose]: !menuOpen && withMap && !mobile,
            })}
          >
            <WebviewerHeader
              ref={headerDivRef}
              openMenu={menuOpen}
              error={error}
              mobile={mobile}
              onToggleMenu={
                mobile
                  ? () => setMobileMenuOpen((o) => !o)
                  : () => toggleMenuOpen()
              }
            />
            <WrappedComponent
              {...props}
              {...permissions}
              openMenu={menuOpen}
              error={shownError}
              plan={plan}
              headerDivRef={headerDivRef.current}
            />
          </div>
        </ScrollBar>

        <Toast
          open={open}
          message={(message && t(message, option)) ?? ""}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            dispatch(closeToast());
          }}
        />
        {mobile && (
          <Modal
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
            // onOpen={onOpen}
            classes={{ paper: classes.mobileDrawerPaper }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={mobileMenuOpen} timeout={{ enter: 150, exit: 250 }}>
              <div style={{ position: "relative" }}>
                <div
                  dir={theme.direction}
                  className={clsx(classes.mobileMenuDiv, {
                    [classes.mainMenuClose]: openLangMenu,
                  })}
                >
                  <div
                    className={clsx(
                      classes.mobileLogoDiv,
                      classes.spaceBetween
                    )}
                  >
                    <LogoOnly width={36} height={36} />
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div
                    className={classes.mobileLogoDiv}
                    onClick={() => {
                      history.push("/account");
                      setMobileMenuOpen(false);
                    }}
                  >
                    <Avatars
                      name={userProfile?.lastName}
                      imgSrc={userProfile?.profilePath}
                    />
                    <div className={classes.userInfoDiv}>
                      <Typography
                        category="Default"
                        variant="Body"
                        htmlColor={LightColors.primary["1"]}
                      >
                        {userProfile?.firstName} {userProfile?.lastName}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {userProfile?.email}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {`${
                          subscriptionInfo &&
                          t(
                            getPlanFromServiceID(subscriptionInfo.servicePlanID)
                          )
                        }  ${
                          subscriptionInfo &&
                          getPlanFromServiceID(
                            subscriptionInfo.servicePlanID
                          ) === "Fleet plan" &&
                          userProfile?.userType
                            ? `· ${t(UserNames[userProfile?.userType])}`
                            : ""
                        }`}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.mobileDrawerDiv}>
                    <div className={classes.menuListDiv}>
                      <WebviewerMenuList
                        open={true}
                        onClose={() => setMobileMenuOpen(false)}
                      />
                    </div>
                    <div className={classes.btnDiv}>
                      <div
                        className={classes.menuBtnDiv}
                        onClick={() => setOpenLangMenu(true)}
                      >
                        <LanguageIcon className={classes.langIcon} />

                        <Typography category="Default" variant="Body">
                          {Languages[i18n.language]}
                        </Typography>
                      </div>
                      <div
                        className={classes.menuBtnDiv}
                        onClick={() => {
                          window.open(
                            "https://apps.apple.com/kr/app/blackvue/id1049209637",
                            "_blank"
                          );
                        }}
                      >
                        <AppleIcon className={classes.appIcon} />

                        <Typography category="Default" variant="Body">
                          {t("iOS")}
                        </Typography>
                      </div>
                      <div
                        className={classes.menuBtnDiv}
                        onClick={() => {
                          window.open(
                            "https://play.google.com/store/apps/details?id=comb.blackvuec",
                            "_blank"
                          );
                        }}
                      >
                        <GooglePlay className={classes.appIcon} />

                        <Typography category="Default" variant="Body">
                          {t("Android")}
                        </Typography>
                      </div>

                      <div
                        className={clsx(classes.menuBtnDiv, classes.textOnly)}
                        onClick={() => {
                          window.open(
                            "https://blackvue.com/warranty-terms-conditions/",
                            "_blank"
                          );
                        }}
                      >
                        <Typography category="Default" variant="Body">
                          {t("Terms & Policies")}
                        </Typography>
                      </div>

                      <div
                        className={clsx(classes.menuBtnDiv, classes.textOnly)}
                        onClick={() => {
                          dispatch(logout());
                        }}
                      >
                        <Typography category="Default" variant="Body">
                          {t("Log out")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {upgradeBtnMarkup}
                </div>

                <div
                  className={clsx(
                    classes.mobileMenuDiv,
                    classes.langMobileDiv,
                    {
                      [classes.langDivClose]: !openLangMenu,
                      [classes.langDivOpen]: openLangMenu,
                    }
                  )}
                >
                  <div
                    className={classes.mobileLogoDiv}
                    style={{ alignItems: "center" }}
                  >
                    <IconButton
                      className={classes.backBtn}
                      onClick={() => setOpenLangMenu(false)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography category="Default" variant="H6">
                      {t("Language")}
                    </Typography>
                  </div>
                  <div style={{ overflowY: "auto" }}>
                    <LangMenuItems
                      mode="webviewer"
                      mobile={mobile}
                      language={i18n.language}
                      onChangeLanguage={(evt, lang) => {
                        i18n.changeLanguage(lang);
                        if (lang === "ar") {
                          dispatch(changeDirection("rtl"));
                          dispatch(setFontFamily("Noto Naskh Arabic UI"));
                        } else {
                          switch (lang) {
                            case "ko":
                              dispatch(setFontFamily("Noto Sans KR"));
                              break;
                            case "ja":
                              dispatch(setFontFamily("Noto Sans JP"));
                              break;
                            case "sc":
                              dispatch(setFontFamily("Noto Sans SC"));
                              break;
                            case "th":
                              dispatch(setFontFamily("Noto Sans Thai UI"));
                              break;
                            default:
                              dispatch(setFontFamily("Roboto"));
                              break;
                          }
                          dispatch(changeDirection("ltr"));
                        }
                        localStorage.setItem("pitta-webviewer-pref-lang", lang);
                        setOpenLangMenu(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        )}
        <ModalPricing
          open={openPricing}
          content={<Pricing modal onClose={() => setOpenPricing(false)} />}
          close
          onClose={() => {
            setOpenPricing(false);
          }}
          fullSize
          contentClassName={clsx(mobile && classes.modalContent)}
        />
        <WebPushNotification />
      </div>
    );
  };

export default withViewerTemplate;
