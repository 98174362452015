import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  ISelectorItem,
  Selector,
} from "@thingsw/pitta-design-system/dist/components/Selector";

import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
  getFormValues,
  change,
} from "redux-form";
import { useTranslation } from "react-i18next";

import {
  FormControlLabel,
  FormHelperText,
  useMediaQuery,
} from "@material-ui/core";
import {
  Button,
  CheckBox,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { USER } from "../features/User/slice";
import { TFunction } from "i18next";
import { RootState } from "../features/store";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import LockIcon from "@material-ui/icons/Lock";
import clsx from "clsx";
import { Webviewer } from "../contants/Breakpoints";
import _ from "lodash";
import {
  CreateTokenCardData,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";
import Countries from "../contants/Countries";
import { FLEET_EXTRA, FLEET_PRICE, MAX_CAM_COUNT } from "../contants/Price";
import { PAYMENT } from "../features/Payment/slice";

interface FieldProps {
  label?: string;
  helperText?: string;
  value?: string;
  t: TFunction;
  referralChecked?: boolean;
  items?: ISelectorItem[];
  type?: string;
}

interface SelectFieldProps {
  onChange?: (item: ISelectorItem) => void;
}

const renderEmailField = ({
  label,
  input,
  helperText,
  meta: { touched, invalid, error, ...restMeta },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && invalid}
      helperText={(touched && t(error)) || helperText}
      {...input}
      {...custom}
    />
  );
};
const CheckboxFormControlLabel = withStyles({
  root: {
    marginLeft: -6,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    padding: "3px 0 0 2px",
  },
})(FormControlLabel);

const renderCheckField = ({
  label,
  input,
  referralChecked,
  meta: { touched, invalid, error },
  t,
}: WrappedFieldProps & FieldProps) => {
  return (
    <div>
      <CheckboxFormControlLabel
        control={
          <CheckBox
            name={input.name}
            color="primary"
            value={input.value}
            checked={referralChecked}
            onChange={input.onChange}
          />
        }
        label={label}
      />
      {touched && error && (
        <FormHelperText
          style={{ color: LightColors.secondary["11"], marginLeft: 28 }}
        >
          {touched && t(error)}
        </FormHelperText>
      )}
    </div>
  );
};

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error, invalid },
  helperText,
  t,
  children,
  items,
  ...custom
}: any & SelectFieldProps) => {
  return (
    <Selector
      menuScrollTime
      keyValue={input.value.key ?? "country"}
      helperText={(touched && t(error)) || helperText}
      onChange={input.onChange}
      error={touched && invalid}
      items={items}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginB1: {
    marginBottom: theme.spacing(1),
  },
  marginB12: {
    marginBottom: theme.spacing(1.5),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginT3: {
    marginTop: theme.spacing(3),
  },
  paddingT1: {
    paddingTop: theme.spacing(1),
  },
  paddingT2: {
    paddingTop: theme.spacing(2),
  },
  paddingB1: {
    paddingBottom: theme.spacing(1),
  },
  paddingB2: {
    paddingBottom: theme.spacing(2),
  },
  formControlLabel: {
    padding: "3px 0 0 2px",
  },
  cardNumDiv: {
    border: `1px solid ${LightColors.primary["4"]}`,
    padding: "9px 13px 9px 16px",
    borderRadius: 4,
  },
  cardNumDivError: {
    borderColor: LightColors.secondary["11"],
  },
  addressDetailDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  regionDiv: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  referralDiv: {
    marginBottom: theme.spacing(1) + 1,
  },
  input50per: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 211,
    },
  },
  closeInput: {
    marginTop: 21,
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    marginLeft: theme.spacing(4) - 1,
    padding: theme.spacing(2),
    backgroundColor: `${LightColors.primary["6"]}73`,
    borderRadius: 4,
    [theme.breakpoints.down(Webviewer.mobile)]: {
      margin: theme.spacing(3, 0),
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 320,
    },
  },
  helperTextDiv: {
    margin: "3px 16px 0px",
  },
}));

interface BillInfoProps {
  loading?: boolean;
  onUpdateCardElement: (
    strip: Stripe,
    cardElement: StripeCardElement,
    referralCode: string,
    cameras: string
  ) => void;
  mode?: "billing" | "change";
}

const BillInfo = (
  props: InjectedFormProps<CreateTokenCardData, BillInfoProps> & BillInfoProps
) => {
  const { loading, mode, onUpdateCardElement, handleSubmit } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { cameraCnt } = useSelector((state: RootState) => state[USER]);
  const [camCnt, setCamCnt] = useState<number>(cameraCnt ? cameraCnt : 1);

  const [, /*disabledChange*/ setDisabledChange] = useState(true);
  const [referralChecked, setReferralChecked] = useState(false);

  const totalPrice = useMemo(
    () => (FLEET_PRICE + (camCnt - 1) * FLEET_EXTRA).toFixed(2),
    [camCnt]
  );

  const { freetrial } = useSelector((state: RootState) => state[PAYMENT]);

  const stripe = useStripe();
  const elements = useElements();
  const state = useSelector((state: RootState) => state);
  const { cameraNum } = (getFormValues("BillInfoForm")(state) as
    | { cameraNum: string }
    | undefined) ?? { cameraNum: "1" };
  const { referralCode } = (getFormValues("BillInfoForm")(state) as
    | { referralCode: string }
    | undefined) ?? { referralCode: "" };

  useEffect(() => {
    dispatch(change("BillInfoForm", "cameraNum", cameraCnt ? cameraCnt : 1));
  }, [cameraCnt, dispatch]);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      onUpdateCardElement(stripe, cardElement, cameraNum, referralCode);
    }
  }, [stripe, elements, cameraNum, referralCode, onUpdateCardElement]);

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("BillInfoForm"));
    }
  };

  const handleReferralInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setReferralChecked(true);
      } else if (!e.target.checked) {
        setReferralChecked(false);
      }
    },
    []
  );

  const handleCamNumInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let cnt = 1;
      if (e.target.value !== "") {
        const value = Number.parseInt(e.target.value);
        cnt = Math.min(Math.max(1, value), MAX_CAM_COUNT);
      }
      setCamCnt(cnt);
      dispatch(change("BillInfoForm", "cameraNum", cnt));
      setDisabledChange(false);
    },
    [dispatch]
  );

  const renderCardField = useCallback(
    ({ meta: { touched, invalid, error } }: WrappedFieldProps) => {
      return (
        <div>
          <div
            className={clsx(classes.cardNumDiv, {
              [classes.cardNumDivError]: error,
            })}
          >
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    "::placeholder": {
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 400,
                      fontFamily: "Roboto",
                    },
                  },
                },
              }}
            />
          </div>
          {error && (
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.secondary["11"]}
              className={classes.helperTextDiv}
            >
              {t(error)}
            </Typography>
          )}
        </div>
      );
    },
    [classes.cardNumDiv, classes.cardNumDivError, classes.helperTextDiv, t]
  );

  const totalPriceMarkup = useMemo(() => {
    if (freetrial?.trialUsed) {
      return (
        <div
          className={clsx(
            classes.flexBetween,
            mobile ? classes.paddingT1 : classes.paddingT2
          )}
        >
          <Typography category="Default" variant="SmallBold">
            {t("Total")}
          </Typography>
          <Typography
            category="Default"
            variant="SmallBold"
            style={{ textAlign: "right" }}
          >
            {totalPrice} USD/mo
          </Typography>
        </div>
      );
    }
    return (
      <>
        <div
          className={clsx(
            classes.flexBetween,
            mobile ? classes.paddingT1 : classes.paddingT2
          )}
        >
          <Typography category="Default" variant="Small">
            {t("Total after 1 month trial")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            style={{ textAlign: "right" }}
          >
            {totalPrice} USD/mo
          </Typography>
        </div>
        <div
          className={clsx(
            classes.flexBetween,
            mobile ? classes.paddingT1 : classes.paddingT2
          )}
        >
          <Typography category="Default" variant="SmallBold">
            {t("Due today")}
          </Typography>
          <Typography
            category="Default"
            variant="SmallBold"
            style={{ textAlign: "right" }}
          >
            0.00 USD
          </Typography>
        </div>
      </>
    );
  }, [
    classes.flexBetween,
    classes.paddingT1,
    classes.paddingT2,
    freetrial?.trialUsed,
    mobile,
    t,
    totalPrice,
  ]);

  return (
    <>
      <form onSubmit={handleSubmit} onKeyDown={handleEnterkey}>
        <div className={classes.marginB3}>
          <Field
            name="name"
            label={t("Cardholder name")}
            component={renderEmailField}
            t={t}
            autoFocus
          />
        </div>
        <div className={clsx(classes.marginB3)}>
          <Field name="cardNumber" component={renderCardField} />
        </div>
        <div className={classes.marginB3}>
          <Field
            name="address_country"
            label={t("Country")}
            component={renderSelectField}
            items={_.map(Countries, (val, key) => ({
              key: key,
              value: val,
            }))}
            t={t}
          />
        </div>
        <div className={classes.marginB3}>
          <Field
            name="address_line1"
            label={t("Address")}
            component={renderEmailField}
            t={t}
          />
        </div>
        <div className={classes.marginB3}>
          <Field
            name="address_city"
            label={t("City")}
            component={renderEmailField}
            t={t}
          />
        </div>

        <div className={clsx(classes.marginB3, classes.addressDetailDiv)}>
          <div className={classes.regionDiv}>
            <Field
              name="address_state"
              label={t("State/Region")}
              component={renderEmailField}
              t={t}
            />
          </div>
          <div>
            <Field
              name="address_zip"
              label={t("ZIP/Postal")}
              component={renderEmailField}
              t={t}
            />
          </div>
        </div>
        {mode !== "change" && (
          <div className={clsx(classes.marginB12, classes.input50per)}>
            <Field
              type="numeric"
              count
              helperTextNoWrap
              name="cameraNum"
              label={t("Cameras")}
              component={renderEmailField}
              value={camCnt}
              PlusButtonDis={camCnt === MAX_CAM_COUNT}
              MinusButtonDis={camCnt === 1}
              onPlus={() => {
                dispatch(change("BillInfoForm", "cameraNum", camCnt + 1));
                setCamCnt((c) => Math.min(c + 1, MAX_CAM_COUNT));
                setDisabledChange(false);
              }}
              onMinus={() => {
                console.log("minus", camCnt);
                dispatch(change("BillInfoForm", "cameraNum", camCnt - 1));
                setCamCnt((c) => Math.max(c - 1, 1));
                setDisabledChange(false);
              }}
              onChange={handleCamNumInput}
              t={t}
              helperText={t("available on your_", { a: MAX_CAM_COUNT })}
            />
          </div>
        )}

        {mode !== "change" && (
          <>
            <div className={clsx(referralChecked && classes.referralDiv)}>
              <Field
                name="ReferralCheck"
                value="ReferralCheck"
                checked={referralChecked}
                onChange={handleReferralInput}
                label={
                  <Typography category="Default" variant="Body">
                    {t("Referral code")}
                  </Typography>
                }
                component={renderCheckField}
                t={t}
              />
            </div>
            {referralChecked && (
              <div className={classes.input50per}>
                <Field name="referralCode" component={renderEmailField} t={t} />
              </div>
            )}

            <Button
              fullWidth
              color="primary"
              type="submit"
              loading={loading}
              startIcon={freetrial?.trialUsed ? "" : <LockIcon />}
              className={clsx(
                classes.marginT3,
                !referralChecked && classes.closeInput
              )}
            >
              {/* {t("Try 1 month free")} */}
              {freetrial?.trialUsed ? t("subscribe") : t("Try 1 month free")}
            </Button>
          </>
        )}
      </form>

      {/* fleetPlan 설명 */}
      {mode !== "change" && (
        <div className={classes.fleetPlan}>
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["1"]}
            className={clsx(classes.marginB1, mobile && classes.marginB2)}
          >
            {t("Fleet Plan")}{" "}
            {freetrial?.trialUsed ? "" : ` · ${t("1 month free trial_")}`}
          </Typography>
          {!mobile && (
            <div className={classes.marginB3}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("BlackVue Fleet Tracking_")}.{" "}
                <Typography category="Default" variant="SmallBold">
                  {t("Web subscription only")}
                </Typography>
                .
              </Typography>
            </div>
          )}

          <div>
            <div
              className={clsx(
                classes.flexBetween,
                mobile ? classes.paddingB1 : classes.paddingB2
              )}
            >
              <Typography category="Default" variant="Small">
                {t("Subscription")}
              </Typography>
              <Typography
                category="Default"
                variant="Small"
                style={{ textAlign: "right" }}
              >
                {FLEET_PRICE} USD/mo
              </Typography>
            </div>
            <div
              className={classes.flexBetween}
              style={{ paddingBottom: mobile ? 7 : 15 }}
            >
              <Typography category="Default" variant="Small">
                {t("Extra cameras")}
              </Typography>
              <Typography
                category="Default"
                variant="Small"
                style={{ textAlign: "right" }}
              >
                {/* {((camCnt - 1) * FLEET_EXTRA).toFixed(2)} USD/mo */}
                {camCnt - 1} × {FLEET_EXTRA.toFixed(2)} USD/mo
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                borderBottom: `1px solid ${LightColors.primary["5"]}`,
              }}
            ></div>
            {totalPriceMarkup}
          </div>
        </div>
      )}
    </>
  );
};

const BillingForm = reduxForm<CreateTokenCardData, BillInfoProps>({
  form: "BillInfoForm",
  // asyncValidate: validator(schema),
  touchOnBlur: false,
})(BillInfo);

export default BillingForm;
