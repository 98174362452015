import { makeStyles, Theme } from "@material-ui/core";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { UpgradePlanPanel } from "../components/UpgradePlanPanel";
import { deleteMembers, IMembersInfo, MEMBER } from "../features/Member/slice";
import { RootState } from "../features/store";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { InviteMembersScreen } from "../screens/InviteMembersScreen";
import { MemberDetailScreen } from "../screens/MemberDetailScreen";
import { MembersScreen } from "../screens/MembersScreen";

const useStyles = makeStyles((theme: Theme) => ({
  deleteContent: {
    paddingTop: 18,
  },
  modalWrap: {
    margin: 16,
  },
}));
export const MemberRouter = (props: ScreenDefaultProps) => {
  const classes = useStyles();
  const { plan } = props;
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { type, loading } = useSelector((state: RootState) => state[MEMBER]);

  const [currentMember, setCurrentMember] = useState<IMembersInfo>();
  const [currentMembers, setCurrentMembers] = useState<IMembersInfo[]>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAllDeleteModal, setOpenAllDeleteModal] = useState(false);

  useEffect(() => {
    if (type === deleteMembers.type && !loading) {
      setOpenDeleteModal(false);
      setOpenAllDeleteModal(false);
    }
  }, [loading, type]);

  const handleDelete = () => {
    if (currentMember) {
      dispatch(
        deleteMembers([
          {
            deleteEmail: currentMember.email,
            deleteEmailUserType: currentMember.role,
          },
        ])
      );
    } else if (currentMembers) {
      dispatch(
        deleteMembers(
          _.map(currentMembers, (m) => ({
            deleteEmail: m.email,
            deleteEmailUserType: m.role,
          }))
        )
      );
    }
  };

  const handleDeleteMember = (member: IMembersInfo) => {
    setCurrentMember(member);
    setCurrentMembers(undefined);
    setOpenDeleteModal(true);
  };

  const handleDeleteMembers = (members: IMembersInfo[]) => {
    setCurrentMember(undefined);
    setCurrentMembers(members);
    setOpenAllDeleteModal(true);
  };

  if (plan === "Fleet plan") {
    return (
      <>
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <MembersScreen
                {...props}
                onDeleteMember={handleDeleteMember}
                onDeleteMembers={handleDeleteMembers}
              />
            )}
          />
          <Route
            path={`${path}/invite-member`}
            render={() => <InviteMembersScreen {...props} />}
          />
          <Route
            path={`${path}/:email`}
            render={() => (
              <MemberDetailScreen
                {...props}
                onDeleteMember={handleDeleteMember}
              />
            )}
          />
        </Switch>

        <Modal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onClickNegative={() => setOpenDeleteModal(false)}
          onClickPositive={() => handleDelete()}
          heading="Delete"
          close={true}
          content={
            <Typography
              category="Default"
              variant="Body"
              style={{ wordBreak: "break-word" }}
            >
              {currentMember && currentMember.role === "SubMaster"
                ? `${t("Are you sure_remove admin2")}`
                : `${t("Are you sure_remove driver")}`}
              <br />
              <Typography category="Default" variant="BodyBold">
                {currentMember &&
                  `${currentMember.firstName} ${currentMember.lastName} (${currentMember.email})?`}
              </Typography>
            </Typography>
          }
          contentClassName={classes.deleteContent}
          className={classes.modalWrap}
          LButton="Cancel"
          RButton="Delete"
          Secondary={true}
          loading={loading}
          member={true}
        />
        <Modal
          open={openAllDeleteModal}
          onClose={() => setOpenAllDeleteModal(false)}
          onClickNegative={() => setOpenAllDeleteModal(false)}
          onClickPositive={() => handleDelete()}
          heading="Delete"
          close={true}
          content={
            <Typography category="Default" variant="Body">
              {t("Are you sure_delete members")}
            </Typography>
          }
          contentClassName={classes.deleteContent}
          className={classes.modalWrap}
          LButton="Cancel"
          RButton="Delete"
          Secondary={true}
          loading={loading}
          member={true}
        />
      </>
    );
  }

  if (plan === "Free plan" || plan === "Basic plan" || plan === "Smart plan") {
    return <UpgradePlanPanel plan={plan} />;
  }

  return <div></div>;
};
