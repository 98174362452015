import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

export interface Ifreetrial {
  trialUsed: boolean;
}

export interface ITransaction {
  createdDT: number;
  amount: number;
  brand: string;
  last4: string;
  detail: string;
}

export interface ICustomerInfo {
  brand: string;
  cardName: string;
  expMonth: number;
  expYear: number;
  last4: string;
  quantity: number;
}

export interface IUpcommingInfo {
  canceled: false;
  currency: string;
  nextBilling: number; // UTC
  total: number;
}

export interface IWebSubscriptionInfo {
  customer: ICustomerInfo;
  upcomming: IUpcommingInfo;
}

export interface ISubscriptionInfo {
  cameraLimit: number;
  cancelDT: moment.Moment;
  cancelled: number;
  customerID: string;
  endOfMonthDT: moment.Moment;
  masterEmail: string;
  paymentMethod: string;
  registered: boolean;
  servicePlanID: number;
  servicePlanName: string;
  startOfMonthDT: moment.Moment;
  tradeType: string;
  userEmail: string;
}

interface IState {
  loading: boolean;
  subscriptionInfo?: ISubscriptionInfo;
  webSubscriptionInfo?: IWebSubscriptionInfo;
  transactionHistory: ITransaction[];
  type?: string;
  freetrial?: Ifreetrial;
}

let initialState: IState = {
  loading: false,
  transactionHistory: [],
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearPayment: (state) => {
      state.loading = false;
      state.subscriptionInfo = undefined;
      state.webSubscriptionInfo = undefined;
      state.transactionHistory = [];
      state.type = undefined;
    },
    loadSubscription: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    getFreetrialState: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successGetFreetrialState: (
      state,
      { payload }: PayloadAction<Ifreetrial>
    ) => {
      state.loading = false;
      state.freetrial = payload;
    },
    successLoadSubscription: (
      state,
      { payload }: PayloadAction<ISubscriptionInfo>
    ) => {
      state.loading = false;
      state.subscriptionInfo = payload;
    },
    loadWebSubscription: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadWebSubscription: (
      state,
      { payload }: PayloadAction<IWebSubscriptionInfo>
    ) => {
      state.loading = false;
      state.webSubscriptionInfo = payload;
    },
    loadTransactions: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadTransactions: (
      state,
      { payload }: PayloadAction<ITransaction[]>
    ) => {
      state.loading = false;
      state.transactionHistory = payload;
    },
    cancelSubscription: (state, action: PayloadAction<string | undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    successRequest: (state) => {
      state.loading = false;
    },
  },
});

export const {
  clearPayment,
  loadSubscription,
  successLoadSubscription,
  loadWebSubscription,
  successLoadWebSubscription,
  loadTransactions,
  successLoadTransactions,
  cancelSubscription,
  successRequest,
  getFreetrialState,
  successGetFreetrialState,
} = slice.actions;
export const PAYMENT = slice.name;
export default slice.reducer;
