export function getTextHeight(
  text: string,
  className: string,
  offset: number = 0
): number {
  const measure = document.getElementById("measure-layer");
  const element = document.createElement("div");
  element.classList.add(className);
  element.innerHTML = text;

  measure?.appendChild(element);
  const height = element.offsetHeight;
  measure?.removeChild(element);
  return height - offset;
}
