import React, { useCallback, useEffect, useState } from "react";
import {
  FormControlLabel,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  LightColors,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import InfoIcon from "@material-ui/icons/Info";
import { Webviewer } from "../../contants/Breakpoints";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import {
  CAMERA,
  IEmailNotiSettings,
  OnOffType,
  loadNotification,
  updateNotification,
} from "../../features/Camera/slice";
import _ from "lodash";
import {
  DMC100_MODELS,
  DMC200_MODELS,
  LOW_VOLTAGE_MODELS,
} from "../../contants/Models";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 4,
    alignItems: "center",
    paddingLeft: 10,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      display: "flex",
      alignItems: "center",
      paddingLeft: 10,
      marginBottom: 32,
      border: `1px solid ${LightColors.primary["6"]}`,
    },
  },

  bigBox: {
    maxWidth: "100%",
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      borderRadius: 4,
      marginBottom: 32,
    },
  },

  boderBottomBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    justifyContent: "space-between",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      display: "flex",
      alignItems: "center",
      paddingLeft: 10,
      justifyContent: "space-between",
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
  },
  boderNoneBox: {
    maxWidth: "100%",
    minHeight: 48,
    display: "flex",
    borderRadius: 4,
    alignItems: "center",
    padding: "0 2px 0 10px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      minHeight: 48,
      display: "flex",
      alignItems: "center",
      padding: "0 2px 0 10px",
    },
  },
  mgNone: {
    margin: 0,
  },
  buttonMargin: {
    marginBottom: 16,
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
  },
  title: {
    ...(theme.direction === "rtl" ? { paddingRight: 6 } : { paddingLeft: 6 }),
  },
  infoIcon: {
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },
}));

interface NotiPushScreenProps {
  openMenu?: boolean;
}

export const PushNotificationPanel = ({ openMenu }: NotiPushScreenProps) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { camera, firmwareConfig } = useSelector(
    (state: RootState) => state[CAMERA]
  );

  const phoneSettings = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.PhoneSettings
  );
  const phoneSend = useSelector(
    (state: RootState) => state[CAMERA].notificationSettings?.PhoneSend
  );

  const [current, setCurrent] = useState<IEmailNotiSettings>();
  const [push, setPush] = useState<OnOffType>("off");

  useEffect(() => {
    if (phoneSend && phoneSettings) {
      setCurrent(phoneSettings);
      setPush(phoneSend);
    }
  }, [phoneSend, phoneSettings]);

  const { loading, type } = useSelector((state: RootState) => state[CAMERA]);
  const [disableSave, setDisableSave] = useState(true);

  const getBoolean = useCallback((onOff?: OnOffType) => {
    return onOff === "on" ? true : false;
  }, []);

  useEffect(() => {
    dispatch(loadNotification());
  }, [dispatch]);

  useEffect(() => {
    const predicts = _.keys(current).map((k) => {
      const key = k as keyof IEmailNotiSettings;
      return (phoneSettings?.[key] ?? "off") === current?.[key];
    });
    setDisableSave(_.every(predicts) && phoneSend === push);
  }, [current, phoneSend, phoneSettings, push]);

  const handleChange = useCallback(
    (key: keyof IEmailNotiSettings, checked: boolean) => {
      setCurrent(
        (c) =>
          ({
            ...c,
            [key]: checked ? "on" : "off",
          } as IEmailNotiSettings)
      );
    },
    []
  );

  console.log(current);

  return (
    <>
      <div>
        <div className={classes.box}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                color="primary"
                checked={getBoolean(push)}
                onChange={(e) => setPush(e.target.checked ? "on" : "off")}
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("Push Notifications")}
              </Typography>
            }
          />
        </div>
        {push === "on" && (
          <div className={classes.bigBox}>
            <div className={classes.boderBottomBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_MANUAL)}
                    onChange={(e) => {
                      handleChange("ALARM_MANUAL", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Manual recording")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <Typography
                category="Default"
                variant="BodyBold"
                className={classes.title}
              >
                {t("Normal event recording")}
              </Typography>
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_EVENT)}
                    onChange={(e) =>
                      handleChange("ALARM_EVENT", e.target.checked)
                    }
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Impact detection")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_SPEED)}
                    onChange={(e) =>
                      handleChange("ALARM_SPEED", e.target.checked)
                    }
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Overspeed")}
                  </Typography>
                }
              />
            </div>

            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_ACCELERATION)}
                    onChange={(e) => {
                      handleChange("ALARM_ACCELERATION", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {/* osd 키값 참고 */}
                    {t("Acceleration")}
                  </Typography>
                }
              />
            </div>

            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_HARSHBRAKING)}
                    onChange={(e) =>
                      handleChange("ALARM_HARSHBRAKING", e.target.checked)
                    }
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Hard braking")}
                  </Typography>
                }
              />
            </div>

            <div className={classes.boderBottomBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_SHARPTURN)}
                    onChange={(e) =>
                      handleChange("ALARM_SHARPTURN", e.target.checked)
                    }
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {/* osd 키값 참고 */}
                    {t("Sharp turn")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <Typography
                category="Default"
                variant="BodyBold"
                className={classes.title}
              >
                {t("Parking event recording")}
              </Typography>
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_PARK_EVENT)}
                    onChange={(e) =>
                      handleChange("ALARM_PARK_EVENT", e.target.checked)
                    }
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Impact detection")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_PARK_MOTION)}
                    onChange={(e) =>
                      handleChange("ALARM_PARK_MOTION", e.target.checked)
                    }
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Motion detection")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderBottomBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={
                      getBoolean(current?.ALARM_PARK_IN) &&
                      getBoolean(current?.ALARM_PARK_OUT)
                    }
                    onChange={(e) => {
                      handleChange("ALARM_PARK_IN", e.target.checked);
                      handleChange("ALARM_PARK_OUT", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Parking mode enter_")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <Typography
                category="Default"
                variant="BodyBold"
                className={classes.title}
              >
                {t("Geo Fencing")}
              </Typography>
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_GEOFENCE_ENTER)}
                    onChange={(e) => {
                      handleChange("ALARM_GEOFENCE_ENTER", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Entering")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_GEOFENCE_EXIT)}
                    onChange={(e) => {
                      handleChange("ALARM_GEOFENCE_EXIT", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Exiting")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderBottomBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_GEOFENCE_PASS)}
                    onChange={(e) => {
                      handleChange("ALARM_GEOFENCE_PASS", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Passing")}
                  </Typography>
                }
              />
            </div>
            {((_.includes(DMC100_MODELS, camera?.model) &&
              firmwareConfig?.["System"]?.["DMS"]) ||
              (_.includes(DMC200_MODELS, camera?.model) &&
                firmwareConfig?.["DMS"])) && (
              <>
                <div
                  className={classes.boderNoneBox}
                  style={{ justifyContent: "space-between", padding: "0 10px" }}
                >
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    className={classes.title}
                  >
                    {t("Driver monitoring system (DMS)")}
                  </Typography>
                  <Tooltip
                    widthLarge
                    title={
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography category="Default" variant="CaptionBold">
                          {t("Every events of DMC200_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="CaptionBold">
                          {t("Detected")}
                        </Typography>
                        <Typography category="Default" variant="Caption">
                          {t("A detected event occurs_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="CaptionBold">
                          {t("Unetected")}
                        </Typography>
                        <Typography category="Default" variant="Caption">
                          {t("An undetected event occurs_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="CaptionBold">
                          {t("Drowsy")}
                        </Typography>
                        <Typography category="Default" variant="Caption">
                          {t("When driver’s eyes close_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="CaptionBold">
                          {t("Distracted")}
                        </Typography>
                        <Typography
                          category="Default"
                          variant="Caption"
                          dangerouslySetInnerHTML={{
                            __html: t("Distracted occurs_"),
                          }}
                        />

                        <br />
                        <Typography category="Default" variant="CaptionBold">
                          {t("Hand distraction")}
                        </Typography>
                        <Typography category="Default" variant="Caption">
                          {t("Hand distraction event occurs_")}
                        </Typography>
                        <br />
                        <Typography category="Default" variant="CaptionBold">
                          {t("Mask off")}
                        </Typography>
                        <Typography
                          category="Default"
                          variant="Caption"
                          dangerouslySetInnerHTML={{
                            __html: t("Mask off event occurs_"),
                          }}
                        />
                      </div>
                    }
                    placement="right"
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
                <div className={classes.boderNoneBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_UNDETECTED)}
                        onChange={(e) => {
                          handleChange("ALARM_UNDETECTED", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Undetected")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.boderNoneBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_DETECTED)}
                        onChange={(e) => {
                          handleChange("ALARM_DETECTED", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Detected")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.boderNoneBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_DROWSY)}
                        onChange={(e) => {
                          handleChange("ALARM_DROWSY", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Drowsy")}
                      </Typography>
                    }
                  />
                </div>
                <div
                  className={clsx({
                    [classes.boderBottomBox]: _.includes(
                      DMC100_MODELS,
                      camera?.model
                    ),
                    [classes.boderNoneBox]: _.includes(
                      DMC200_MODELS,
                      camera?.model
                    ),
                  })}
                >
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_DISTRACTED)}
                        onChange={(e) => {
                          handleChange("ALARM_DISTRACTED", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Distracted")}
                      </Typography>
                    }
                  />
                </div>
              </>
            )}
            {_.includes(DMC200_MODELS, camera?.model) && (
              <>
                <div className={classes.boderNoneBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={
                          getBoolean(current?.ALARM_CALLING) &&
                          getBoolean(current?.ALARM_SMOKING)
                        }
                        onChange={(e) => {
                          handleChange("ALARM_CALLING", e.target.checked);
                          handleChange("ALARM_SMOKING", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Hand distraction")}
                      </Typography>
                    }
                  />
                </div>
                <div className={classes.boderBottomBox}>
                  <FormControlLabel
                    className={classes.mgNone}
                    control={
                      <CheckBox
                        color="primary"
                        checked={getBoolean(current?.ALARM_MASK_OFF)}
                        onChange={(e) => {
                          handleChange("ALARM_MASK_OFF", e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Mask off")}
                      </Typography>
                    }
                  />
                </div>
              </>
            )}

            {_.includes(LOW_VOLTAGE_MODELS, camera?.model) && (
              <div className={classes.boderBottomBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      color="primary"
                      checked={getBoolean(current?.ALARM_LOW_VOLTAGE)}
                      onChange={(e) => {
                        handleChange("ALARM_LOW_VOLTAGE", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Low voltage warning")}
                    </Typography>
                  }
                />
              </div>
            )}
            <div className={classes.boderBottomBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={
                      getBoolean(current?.DEVICE_CONNECT) &&
                      getBoolean(current?.DEVICE_DISCONNECT)
                    }
                    onChange={(e) => {
                      handleChange("DEVICE_CONNECT", e.target.checked);
                      handleChange("DEVICE_DISCONNECT", e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Cloud connectivity")}
                  </Typography>
                }
              />
            </div>
            <div className={classes.boderNoneBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    color="primary"
                    checked={getBoolean(current?.ALARM_CLOUDSTORAGE)}
                    onChange={(e) =>
                      handleChange("ALARM_CLOUDSTORAGE", e.target.checked)
                    }
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Cloud storage")}
                  </Typography>
                }
              />
            </div>
          </div>
        )}
        <div className={classes.buttonMargin}>
          <Button
            variant="contained"
            color="primary"
            disabled={disableSave}
            fullWidth={mobile}
            loading={
              loading &&
              (type === loadNotification.type ||
                type === updateNotification.type)
            }
            onClick={() => {
              if (current) {
                dispatch(
                  updateNotification({
                    setType: "phone",
                    settings: current,
                    send: push,
                  })
                );
              }
            }}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </>
  );
};
