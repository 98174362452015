export let MAINTENANCE_SERVER_URI: string;
if (process.env.NODE_ENV === "development") {
  MAINTENANCE_SERVER_URI = "/blackvuesrc3/maintenance/maintenance_dev.json";
} else if (process.env.REACT_APP_ENV === "qa") {
  MAINTENANCE_SERVER_URI =
    "https://blackvuesrc3.blackvuecloud.com/maintenance/maintenance_dev.json";
} else if (process.env.REACT_APP_ENV === "staging") {
  MAINTENANCE_SERVER_URI =
    "https://blackvuesrc3.blackvuecloud.com/maintenance/maintenance_stage.json";
} else if (process.env.REACT_APP_ENV === "production") {
  MAINTENANCE_SERVER_URI =
    "https://blackvuesrc3.blackvuecloud.com/maintenance/maintenance.json";
}

export let LOGIN_SERVER_URI: string;
if (process.env.NODE_ENV === "development") {
  LOGIN_SERVER_URI = "/lambda";
} else if (process.env.REACT_APP_ENV === "qa") {
  LOGIN_SERVER_URI =
    "https://ucxw6yl3zk.execute-api.ap-northeast-2.amazonaws.com/dev";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  LOGIN_SERVER_URI =
    "https://mcybl18i03.execute-api.ap-northeast-2.amazonaws.com/prod";
}

export let SIMCARD_URI: string;
if (process.env.NODE_ENV === "development") {
  SIMCARD_URI = "http://localhost:3001";
} else if (process.env.REACT_APP_ENV === "qa") {
  SIMCARD_URI = "https://sim-dev.blackvuecloud.com";
} else if (process.env.REACT_APP_ENV === "staging") {
  SIMCARD_URI = "https://sim-stage.blackvuecloud.com";
} else if (process.env.REACT_APP_ENV === "production") {
  SIMCARD_URI = "https://sim.blackvuecloud.com";
}

export let API_SERVER_URI: string;
if (process.env.NODE_ENV === "development") {
  API_SERVER_URI = "/api";
} else if (process.env.REACT_APP_ENV === "qa") {
  API_SERVER_URI = "https://alpha1.blackvuecloud.com";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  API_SERVER_URI = "https://api.blackvuecloud.com";
}

export let API_GATEWAY_URI: string;
if (process.env.NODE_ENV === "development") {
  API_GATEWAY_URI = "/gateway";
} else if (process.env.REACT_APP_ENV === "qa") {
  API_GATEWAY_URI = "https://alpha-gateway.blackvuecloud.com";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  API_GATEWAY_URI = "https://gateway.blackvuecloud.com";
}

export let FIRMWARE_INFO_URI: string;
if (process.env.NODE_ENV === "development") {
  FIRMWARE_INFO_URI = "/blackvuesrc3/fw_folder/firmware_info_test.json";
} else if (process.env.REACT_APP_ENV === "production") {
  FIRMWARE_INFO_URI =
    "https://blackvuesrc3.blackvuecloud.com/fw_folder/firmware_info.json";
} else {
  FIRMWARE_INFO_URI =
    "https://blackvuesrc3.blackvuecloud.com/fw_folder/firmware_info_test.json";
}

export let WS_SERVER_URI: string;
if (process.env.REACT_APP_ENV === "qa") {
  WS_SERVER_URI = "wss://alpha-webpush.blackvuecloud.com:15673/ws/";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  WS_SERVER_URI = "wss://webpush.blackvuecloud.com:15673/ws/";
}

export let STRIPE_OS_TYPE: string;
if (process.env.NODE_ENV === "development") {
  STRIPE_OS_TYPE = "stripe_sandbox";
} else if (process.env.REACT_APP_ENV === "qa") {
  STRIPE_OS_TYPE = "stripe_sandbox";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  STRIPE_OS_TYPE = "stripe";
}

export let STRIPE_API_KEY: string;
if (process.env.NODE_ENV === "development") {
  STRIPE_API_KEY = "pk_test_30Ah19N51uj4ErDcR3beBiZU00ydngcn0Z";
} else if (process.env.REACT_APP_ENV === "qa") {
  STRIPE_API_KEY = "pk_test_30Ah19N51uj4ErDcR3beBiZU00ydngcn0Z";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  STRIPE_API_KEY = "pk_live_sQMEB5KXacXq1HLdJv36FDd100ZGI7aNZm";
}

export const SERVER_MAINTENANCE_NOTICE_URI = "https://blackvue.com/blog/";

export const getS3URI = (presignedURL: string) => {
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_ENV === "qa") {
      return presignedURL.replace(
        "https://s3.ap-northeast-2.amazonaws.com",
        "/s3"
      );
    } else if (process.env.REACT_APP_ENV === "production") {
      return presignedURL.replace(
        "https://s3-ap-southeast-1.amazonaws.com",
        "/s3-prod"
      );
    }
  }
  return presignedURL;
};

export const getLbURI = (server_name: string, port: string) => {
  let uri = `https://${server_name}:${port}`;
  if (process.env.NODE_ENV === "development") {
    if (port === "11001") {
      uri = "/lb1";
    } else if (port === "11002") {
      uri = "/lb2";
    } else if (port === "11003") {
      uri = "/lb3";
    } else if (port === "11004") {
      uri = "/lb4";
    }
  }
  return uri;
};

export const getFotaURI = (uri: string) => {
  if (process.env.NODE_ENV === "development") {
    return uri.replace("https://fota.blackvuecloud.com", "/fota");
  }
  return uri;
};
