import { IFirmwareConfig } from ".";

export const DR900X_1CH = {
  default: {
    Time: {
      GpsSync: {},
      TimeSet: {},
    },
    Video: {
      ImageSetting: {
        options: [{ key: "0", value: "4K UHD @30" }],
      },
      VCodecType: {},
      VideoQuality: {
        options: [
          { key: "0", value: "Highest(Extreme)" },
          { key: "1", value: "Highest" },
          { key: "2", value: "High" },
          { key: "3", value: "Normal" },
        ],
      },
      NightVision: {
        options: [
          { key: "1", value: "Always" },
          { key: "2", value: "Parking mode only" },
        ],
      },
      FrontBrightness: {},
    },

    Recording: {
      NormalRecord: {},
      AutoParking: {},
      VoiceRecord: {},
      DateDisplay: {},
      SpeedUnit: {},
      UseGpsInfo: {},
      RecordTime: {
        options: [
          {
            key: "1",
            value: "1 min",
          },
        ],
      },
      LockEvent: {},
      overWriteLock: {},
      FrontRotate: {},
    },
    Gsensor: {
      Normal: {},
      Parking: {},
    },
    MotionDetection: {
      MOTIONSENSOR: {},
    },
    System: {
      LED: {
        RECLED: {},
        NORMALLED: {},
        PARKINGLED: {},
        LTELED: {},
        WifiLED: {},
      },
      PSENSOR: {}, //
      VoiceGuidance: {
        STARTVOICE: {},
        NORMALSTARTVOICE: {},
        EVENTSTARTVOICE: {},
        CHANGERECORDMODEVOICE: {},
        ENDVOICE: {},
        SPEEDALERTVOICE: {},
        PARKINGEVENTVOICE: {},
        CLOUDVOICE: {},
        CHANGECONFIGVOICE: {},
        SHARPTURNVOICE: {},
        HARSHBRAKINGVOICE: {},
        ACCELERATIONVOICE: {},
      },
      VOLUME: {},
      ScheduledReboot: {},
      SpeedAlert: {},
      BatteryProtection: {},
      UserTextOverlay: {},
    },
    Wifi: {
      LoginCredentials: {},
      WifiBand: {},
      WifiSleepMode: {},
    },
    Cloud: { CloudService: {}, sta1: {}, sta2: {}, sta3: {} },
    FirmwareLanguage: [
      "English",
      "Japan",
      "China",
      "German",
      "French",
      "Thai",
      "Idn",
      "Vietnam",
      "Spain",
      "Italian",
      "Dutch",
      "Polish",
      "Norwegian",
      "Finnish",
      "Arabic",
    ],
  } as IFirmwareConfig,
  1.008: {
    System: {
      EventRev: {},
    },
  } as IFirmwareConfig,
  1.01: {
    System: {
      Seamless: {},
    },
  } as IFirmwareConfig,
};
