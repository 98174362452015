import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";

import { Webviewer } from "../../contants/Breakpoints";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  TabNameInfo5,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import _ from "lodash";

import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  boderBottomBox: {
    minWidth: 288,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { paddingRight: 10 } : { paddingLeft: 10 }),
    justifyContent: "space-between",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
    },
  },
  boderNoneBox84: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0 16px",
    minWidth: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      maxWidth: 672,
    },
  },
  mgNone: {
    margin: 0,
  },
  buttonSize: {
    width: "100%",
    marginBottom: 16,
    marginTop: theme.spacing(4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  input: {
    minWidth: 256,
    paddingRight: 0,
    paddingBottom: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 265,
      maxWidth: 265,
    },
  },
  accordionRoot: {
    boxShadow: "none",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&.Mui-expanded": {
      margin: 0,
    },
    "&:before": {
      backgroundColor: LightColors.primary["6"],
    },
    "&:last-child": {
      borderRadius: 0,
    },
  },
  accordionSummary: {
    "&.Mui-expanded": {
      minHeight: 0,
    },
    "&>.Mui-expanded": {
      margin: 0,
      ...(theme.direction === "rtl"
        ? { marginLeft: -12 }
        : { marginRight: -12 }),
    },
  },
  accordionDetail: {
    padding: 0,
  },
  mgR16: {
    marginRight: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 16 } : { marginRight: 16 }),
    },
  },
  bottomText: {
    marginTop: theme.spacing(2),
  },
  disabled: {
    color: LightColors.primary["1"],
    opacity: 0.5,
    "&:hover": {
      color: LightColors.primary["1"],
      opacity: 0.5,
      cursor: "default",
    },
  },
  padding: {
    padding: theme.spacing(0, 2, 1.375, 2),
  },
  hotspotWrap: {
    width: "100%",
  },
}));

export const CloudFirmwarePanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { t } = useTranslation();

  const { camera, firmwareConfig, firmware } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const cameraInfo = useSelector((state: RootState) =>
    _.find(
      state[CAMERA].cameraList?.DeviceListInfo,
      (cam) => cam.device.psn === camera?.psn
    )
  );
  const [disabledChange, setDisabledChange] = useState(true);
  const [current, setCurrent] = useState<TabNameInfo5>();
  const [value, setValue] = useState<number>();

  const settings = useMemo(() => {
    return firmwareConfig?.Cloud;
  }, [firmwareConfig]);

  const settingsWifi = useMemo(() => {
    return firmwareConfig?.Wifi;
  }, [firmwareConfig]);

  useEffect(() => {
    if (cameraInfo?.device.active === "on") {
      if (
        current?.sta_ssid === cameraInfo?.device.cur_ssid &&
        cameraInfo.device.cur_ssid !== ""
      ) {
        setValue(0);
      } else if (
        current?.sta2_ssid === cameraInfo?.device.cur_ssid &&
        cameraInfo.device.cur_ssid !== ""
      ) {
        setValue(1);
      } else if (
        current?.sta3_ssid === cameraInfo?.device.cur_ssid &&
        cameraInfo.device.cur_ssid !== ""
      ) {
        setValue(2);
      }
    }
  }, [
    cameraInfo?.device.active,
    cameraInfo?.device.cur_ssid,
    current?.sta2_ssid,
    current?.sta3_ssid,
    current?.sta_ssid,
  ]);

  const connectText = useMemo(() => {
    return (
      <div style={{ display: "flex" }}>
        <Typography
          category="Default"
          variant="SmallBold"
          htmlColor={LightColors.primary["2"]}
          className={classes.padding}
        >
          {t("Currently connected Hotspot")}
        </Typography>
      </div>
    );
  }, [classes.padding, t]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Cloud"
      );
      if (currentTab) {
        setCurrent(currentTab.section_info as TabNameInfo5);
      }
    }
  }, [firmware]);

  const handleChange = (key: keyof TabNameInfo5, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo5));
  };

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Cloud"
      );

      const predicts = _.keys(currentTab?.section_info as TabNameInfo5).map(
        (k) => {
          const key = k as keyof TabNameInfo5;
          return (
            (currentTab?.section_info as TabNameInfo5)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(_.every(predicts));
    }
  }, [firmware, current]);

  const handleUpdate = () => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Cloud"
        ),
      };
      updated.cloud_settings = [
        ...updated.cloud_settings,
        { section_info: current, section_name: "Cloud" },
      ];
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  return (
    <>
      <div>
        <div className={classes.bigBox}>
          {settings?.["CloudService"] && (
            <div className={classes.boderBottomBox}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={current?.CloudService === "1" ?? false}
                    onChange={(e) => {
                      handleChange("CloudService", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Cloud service")}
                  </Typography>
                }
              />
            </div>
          )}

          {settings?.["sta1"] && (
            <Accordion className={classes.accordionRoot}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography category="Default" variant="BodyBold">
                  {t("Hotspot 1")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                <div className={classes.hotspotWrap}>
                  {current?.CloudService === "1"
                    ? value === 0 && connectText
                    : ""}
                  <div
                    className={clsx(classes.boderNoneBox84, {
                      [classes.disabled]:
                        value === 0 && current?.CloudService === "1",
                    })}
                  >
                    <div className={classes.mgR16}>
                      <Typography variant="Body" category="Default">
                        {value === 0 && !mobile
                          ? t("Hotspot name (SSID)")
                          : t("SSID")}
                      </Typography>
                      <Input
                        disabled={value === 0 && current?.CloudService === "1"}
                        value={current?.sta_ssid ?? ""}
                        onChange={(e) => {
                          if (e.target.value.length <= 32) {
                            setCurrent((c) => {
                              return c && { ...c, sta_ssid: e.target.value };
                            });
                          }
                        }}
                        dense
                        className={classes.input}
                      ></Input>
                    </div>
                    <div>
                      <Typography variant="Body" category="Default">
                        {t("Password")}
                      </Typography>
                      <Input
                        disabled={value === 0 && current?.CloudService === "1"}
                        onChange={(e) => {
                          if (e.target.value.length <= 32) {
                            setCurrent((c) => {
                              return c && { ...c, sta_pw: e.target.value };
                            });
                          }
                        }}
                        value={current?.sta_pw ?? ""}
                        dense
                        password
                        className={classes.input}
                      ></Input>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          {settings?.["sta2"] && (
            <Accordion className={classes.accordionRoot}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography category="Default" variant="BodyBold">
                  {t("Hotspot 2")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                <div className={classes.hotspotWrap}>
                  {current?.CloudService === "1"
                    ? value === 1 && connectText
                    : ""}
                  <div
                    className={clsx(classes.boderNoneBox84, {
                      [classes.disabled]:
                        value === 1 && current?.CloudService === "1",
                    })}
                  >
                    <div className={classes.mgR16}>
                      <Typography variant="Body" category="Default">
                        {value === 1 && !mobile
                          ? t("Hotspot name (SSID)")
                          : t("SSID")}
                      </Typography>
                      <Input
                        disabled={value === 1 && current?.CloudService === "1"}
                        onChange={(e) => {
                          if (e.target.value.length <= 32) {
                            setCurrent((c) => {
                              return c && { ...c, sta2_ssid: e.target.value };
                            });
                          }
                        }}
                        value={current?.sta2_ssid ?? ""}
                        dense
                        className={classes.input}
                      ></Input>
                    </div>
                    <div>
                      <Typography variant="Body" category="Default">
                        {t("Password")}
                      </Typography>
                      <Input
                        disabled={value === 1 && current?.CloudService === "1"}
                        onChange={(e) => {
                          if (e.target.value.length <= 32) {
                            setCurrent((c) => {
                              return c && { ...c, sta2_pw: e.target.value };
                            });
                          }
                        }}
                        value={current?.sta2_pw ?? ""}
                        dense
                        password
                        className={classes.input}
                      ></Input>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          {settings?.["sta3"] && (
            <Accordion
              className={classes.accordionRoot}
              style={{ borderBottom: "none" }}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography category="Default" variant="BodyBold">
                  {t("Hotspot 3")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                <div className={classes.hotspotWrap}>
                  {current?.CloudService === "1"
                    ? value === 2 && connectText
                    : ""}
                  <div
                    className={clsx(classes.boderNoneBox84, {
                      [classes.disabled]:
                        value === 2 && current?.CloudService === "1",
                    })}
                  >
                    <div className={classes.mgR16}>
                      <Typography variant="Body" category="Default">
                        {value === 2 && !mobile
                          ? t("Hotspot name (SSID)")
                          : t("SSID")}
                      </Typography>
                      <Input
                        disabled={value === 2 && current?.CloudService === "1"}
                        onChange={(e) => {
                          if (e.target.value.length <= 32) {
                            setCurrent((c) => {
                              return c && { ...c, sta3_ssid: e.target.value };
                            });
                          }
                        }}
                        value={current?.sta3_ssid ?? ""}
                        dense
                        className={classes.input}
                      ></Input>
                    </div>
                    <div>
                      <Typography variant="Body" category="Default">
                        {t("Password")}
                      </Typography>
                      <Input
                        disabled={value === 2 && current?.CloudService === "1"}
                        onChange={(e) => {
                          if (e.target.value.length <= 32) {
                            setCurrent((c) => {
                              return c && { ...c, sta3_pw: e.target.value };
                            });
                          }
                        }}
                        value={current?.sta3_pw ?? ""}
                        dense
                        password
                        className={classes.input}
                      ></Input>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        {!settingsWifi?.["WifiBand"] && (
          <div className={classes.bottomText}>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
            >
              {t("The BlackVue dashcam_")}
            </Typography>
          </div>
        )}

        <div>
          <Button
            disabled={disabledChange}
            variant="contained"
            color="primary"
            className={classes.buttonSize}
            onClick={handleUpdate}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </>
  );
};
