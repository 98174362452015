import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListPaging } from "../../types";

export type UserType = "Etc" | "Master" | "SubMaster" | "User";
export type RoleType = "SubMaster" | "User";
export type StatusCode = "P" | "A" | "E";

export type StatusName = {
  [key in StatusCode]: string;
};

export type UserName = {
  [key in UserType]: string;
};

export type RoleName = {
  [key in RoleType]: string;
};

export const StatusNames: StatusName = {
  P: "Pending",
  A: "Accepted",
  E: "Expired",
};

export const UserNames: UserName = {
  SubMaster: "Admin",
  User: "Driver",
  Master: "Master",
  Etc: "",
};

export const RoleNames: RoleName = {
  SubMaster: "Admin",
  User: "Driver",
};

export interface IPermissions {
  assignDashcamToGroup: boolean;
  assignSubMasterToGroup: boolean;
  assignUserToDashcam: boolean;
  changeDashcamName: boolean;
  changeGroupName: boolean;
  cloudFilePlay: boolean;
  cloudStorageUsage: number;
  cloudUploadManual: boolean;
  dashcamInformation: boolean;
  deleteDashcam: boolean;
  deleteDashcamFromGroup: boolean;
  deleteGroup: boolean;
  deleteSubMaster: boolean;
  deleteSubMasterFromGroup: boolean;
  deleteUser: boolean;
  deleteUserFromDashcam: boolean;
  driveReports: boolean;
  eventAutoUpload: boolean;
  fileDelete: boolean;
  firmwareSettings: boolean;
  firmwareUpdate: boolean;
  geoFence: boolean;
  gpsTracking: boolean;
  inquirySubMasterPermissions: boolean;
  inviteSubMaster: boolean;
  inviteUser: boolean;
  listDashcam: boolean;
  liveView: number;
  membersInquiry: boolean;
  myBlackVueFilePlay: boolean;
  privacy: boolean;
  pushNotifications: boolean;
  registerDashcam: boolean;
  registerGroup: boolean;
  setSubMasterPermissions: boolean;
  vodDownload: number;
  eldHosCompliance: boolean;
  setPushNotification: boolean;
  setEmailNotification: boolean;
  fileMove: boolean;
}

export interface IMember {
  email: string;
  user_token: string;
  guestEmail: string;
  userType?: UserType;
}

export interface IMembersInfo {
  email: string;
  role: RoleType;
  groupName: string;
  dateInvited: string;
  mailStatus: StatusCode;
  firstName?: string;
  lastName?: string;
  profileImg: string;
  hostEmail: string;
  hostfirstName?: string;
  hostlastName?: string;
  hostRole: UserType;
}

export interface IMemberList {
  inviteMemberCount?: number;
  inviteMember?: IMembersInfo[];
}

interface IState {
  loading: boolean;
  memberList?: IMemberList;
  members?: IMemberList;
  permissions?: IPermissions;
  type?: string;
  currentPage?: ListPaging;
}

let initialState: IState = {
  loading: false,
  memberList: undefined,
  members: undefined,
};

const slice = createSlice({
  name: "member",
  initialState,
  reducers: {
    resetMember: (state) => {
      state.loading = false;
      state.memberList = undefined;
      state.members = undefined;
      state.permissions = undefined;
      state.type = undefined;
      state.currentPage = undefined;
    },
    loadMembers: (state) => {
      state.loading = true;
    },
    successLoadMembers: (state, { payload }: PayloadAction<IMemberList>) => {
      state.loading = false;
      state.members = payload;
    },
    loadMemberPaging: (state, action: PayloadAction<ListPaging>) => {
      state.loading = true;
      state.currentPage = action.payload;
    },
    successLoadMemberPaging: (
      state,
      { payload }: PayloadAction<IMemberList>
    ) => {
      state.loading = false;
      state.memberList = payload;
    },
    invite: (state, _action: PayloadAction<IMember>) => {
      state.loading = true;
    },
    deleteMembers: (
      state,
      action: PayloadAction<
        {
          deleteEmail: string;
          deleteEmailUserType: RoleType;
        }[]
      >
    ) => {
      state.loading = true;
      state.type = action.type;
    },
    successInvite: (state) => {
      state.loading = false;
    },
    successDeleteMembers: (state) => {
      state.loading = false;
    },
    loadUserPermissions: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    successLoadUserPermissions: (
      state,
      { payload }: PayloadAction<IPermissions>
    ) => {
      state.loading = false;
      state.permissions = payload;
    },
    updateUserPermissions: (
      state,
      action: PayloadAction<{ roleSetEmail: string; permissions: IPermissions }>
    ) => {
      state.loading = true;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
  },
});

export const {
  resetMember,
  loadMembers,
  successLoadMembers,
  deleteMembers,
  loadMemberPaging,
  successLoadMemberPaging,
  invite,
  successInvite,
  successDeleteMembers,
  loadUserPermissions,
  successLoadUserPermissions,
  updateUserPermissions,
  clearLoading,
} = slice.actions;
export const MEMBER = slice.name;
export default slice.reducer;
