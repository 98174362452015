import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Container, LogoFull, LogoOnly } from "@thingsw/pitta-design-system";

import { AppBar } from "./AppBar";
import { Toolbar } from "./Toolbar";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { Webviewer } from "../contants/Breakpoints";

const useStyles = makeStyles((theme: Theme) => ({
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  grow: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerText: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    // border: 0,
  },
}));

export const ResetHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  return (
    <AppBar color="default" className={classes.appBar}>
      <Container>
        <Toolbar>
          {/* mobile */}
          <Link to="/" className={classes.vcenter}>
            {!mobile && <LogoFull width={166} height={29} />}
            {mobile && <LogoOnly width={36} height={36} />}
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
