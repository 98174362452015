import { FormControlLabel, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  CheckBox,
  LightColors,
  Selector,
  Sliders,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Webviewer } from "../../contants/Breakpoints";
import DsmSensitivity from "../../contants/DsmSensitivity";
import { DMC100_MODELS, DMC200_MODELS } from "../../contants/Models";
import {
  CAMERA,
  TabNameInfo3,
  TrueFalsetype,
} from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme: Theme) => ({
  boderNoneBox: {
    width: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { padding: "0 10px 0 16px" }
      : { padding: "0 16px 0 10px" }),
    justifyContent: "space-between",
    // "&:last-child": {
    //   borderBottom: `1px solid ${LightColors.primary["6"]}`,
    // },
  },
  Swid192: {
    maxWidth: 192,
    padding: "12px 0",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  sliderText: {
    ...(theme.direction === "rtl" ? { marginRight: 25 } : { marginLeft: 25 }),
  },
  mgNone: {
    margin: 0,
  },
  Swid98: {
    maxWidth: 98,
    padding: "12px 0",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  wid224: {
    minWidth: 230,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 280,
      ...(theme.direction === "rtl"
        ? { paddingRight: 22 }
        : { paddingLeft: 22 }),
    },
  },
  boderBottomBox: {
    width: "100%",
    minWidth: 288,
    minHeight: 48,
    display: "flex",
    ...(theme.direction === "rtl"
      ? { paddingLeft: 16, paddingRight: 10 }
      : { paddingLeft: 10, paddingRight: 16 }),

    justifyContent: "center",
    flexDirection: "column",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  flexDirectionRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& > div:first-child": {
      ...(theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }),
    },
  },
  formLabelOneLine: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  infoIcon: {
    color: LightColors.primary["3"],
    "&:hover, &:active": {
      color: LightColors.primary["1"],
    },
  },
}));

interface DMSPanelProps {
  tabInfo3: TabNameInfo3;
  onChange: (key: keyof TabNameInfo3, value: boolean | string) => void;
}

export const DMSPanel = ({ tabInfo3, onChange }: DMSPanelProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera } = useSelector((state: RootState) => state[CAMERA]);

  const getBoolean = useCallback((TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  }, []);

  const dmc200 = useMemo(
    () => _.includes(DMC200_MODELS, camera?.model),
    [camera?.model]
  );
  const dmc100 = useMemo(
    () => _.includes(DMC100_MODELS, camera?.model),
    [camera?.model]
  );

  useEffect(() => {
    if (dmc200) {
      tabInfo3["DsmDetectBox"] === undefined && onChange("DsmDetectBox", "0");
      tabInfo3["DsmUndetected"] === undefined && onChange("DsmUndetected", "1");
      tabInfo3["DsmDrowsy"] === undefined && onChange("DsmDrowsy", "1");
      tabInfo3["DsmDistracted"] === undefined && onChange("DsmDistracted", "1");
      tabInfo3["DsmCalling"] === undefined && onChange("DsmCalling", "1");
      tabInfo3["DsmMaskOff"] === undefined && onChange("DsmMaskOff", "1");
      tabInfo3["DsmSmoking"] === undefined && onChange("DsmSmoking", "1");
      tabInfo3["DsmParkingMode"] === undefined &&
        onChange("DsmParkingMode", "1");
      tabInfo3["DsmLed"] === undefined && onChange("DsmLed", "1");
      tabInfo3["DsmSensitivity"] === undefined &&
        onChange("DsmSensitivity", "2");
      tabInfo3["DsmVolume"] === undefined && onChange("DsmVolume", "2");
      tabInfo3["DsmRotate"] === undefined && onChange("DsmRotate", "0");
    }
  }, [dmc200, onChange, tabInfo3]);

  return (
    <>
      {dmc100 && (
        <>
          <div className={classes.boderNoneBox} style={{ paddingLeft: 16 }}>
            <Typography category="Default" variant="BodyBold">
              {t("DMS")}
            </Typography>
          </div>
          <div className={classes.boderNoneBox} style={{ paddingLeft: 16 }}>
            <div>
              <Typography category="Default" variant="Body">
                {t("Driver detection")}
              </Typography>
            </div>
            <div className={classes.Swid192}>
              <Selector
                t={t}
                keyValue={tabInfo3.DsmSensingSpeed}
                dense
                mobile={mobile}
                onChange={(e) => {
                  onChange("DsmSensingSpeed", e.key);
                }}
                items={[
                  {
                    key: "0",
                    value: "During driving only",
                  },
                  {
                    key: "1",
                    value: "Always",
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}

      <div
        className={clsx({
          [classes.boderNoneBox]: dmc100,
          [classes.boderBottomBox]: dmc200,
        })}
      >
        <FormControlLabel
          className={classes.mgNone}
          control={
            <CheckBox
              checked={getBoolean(tabInfo3.DsmDetectBox) ?? "0"}
              onChange={(e) => {
                onChange("DsmDetectBox", e.target.checked);
              }}
              color="primary"
            />
          }
          label={
            <Typography category="Default" variant="Body">
              {t("Driver detection box")}
            </Typography>
          }
        />
      </div>

      {dmc200 && (
        <>
          <div className={classes.boderNoneBox} style={{ paddingLeft: 16 }}>
            <Typography category="Default" variant="BodyBold">
              {t("DMS detection")}
            </Typography>
            <Tooltip
              widthLarge
              title={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography category="Default" variant="CaptionBold">
                    {t("Every events of DMC200_")}
                  </Typography>
                  <br />
                  <Typography category="Default" variant="CaptionBold">
                    {t("Drowsy")}
                  </Typography>
                  <Typography category="Default" variant="Caption">
                    {t("When driver’s eyes close_")}
                  </Typography>
                  <br />
                  <Typography category="Default" variant="CaptionBold">
                    {t("Distracted")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    dangerouslySetInnerHTML={{
                      __html: t("Distracted occurs_"),
                    }}
                  />
                  <br />

                  <Typography category="Default" variant="CaptionBold">
                    {t("Unetected")}
                  </Typography>
                  <Typography category="Default" variant="Caption">
                    {t("An undetected event occurs_")}
                  </Typography>
                  <br />

                  <Typography category="Default" variant="CaptionBold">
                    {t("Hand distraction")}
                  </Typography>
                  <Typography category="Default" variant="Caption">
                    {t("Hand distraction event occurs_")}
                  </Typography>
                  <br />
                  <Typography category="Default" variant="CaptionBold">
                    {t("Mask off")}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    dangerouslySetInnerHTML={{
                      __html: t("Mask off event occurs_"),
                    }}
                  />
                </div>
              }
              placement="right"
            >
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(tabInfo3.DsmDrowsy ?? "1")}
                  onChange={(e) => {
                    onChange("DsmDrowsy", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Drowsy")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(tabInfo3.DsmDistracted ?? "1")}
                  onChange={(e) => {
                    onChange("DsmDistracted", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Distracted")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(tabInfo3.DsmUndetected ?? "1")}
                  onChange={(e) => {
                    onChange("DsmUndetected", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Undetected")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={
                    getBoolean(tabInfo3.DsmCalling ?? "1") &&
                    getBoolean(tabInfo3.DsmSmoking ?? "1")
                  }
                  onChange={(e) => {
                    onChange("DsmCalling", e.target.checked);
                    onChange("DsmSmoking", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Hand distraction")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(tabInfo3.DsmMaskOff ?? "1")}
                  onChange={(e) => {
                    onChange("DsmMaskOff", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Mask")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={clsx(classes.mgNone, classes.formLabelOneLine)}
              control={
                <CheckBox
                  checked={getBoolean(tabInfo3.DsmParkingMode ?? "1")}
                  onChange={(e) => {
                    onChange("DsmParkingMode", e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("DMS camera recording_")}
                </Typography>
              }
            />
          </div>
        </>
      )}
      <div
        className={clsx({
          [classes.boderNoneBox]: dmc100,
          [classes.boderBottomBox]: dmc200,
        })}
      >
        <FormControlLabel
          className={classes.mgNone}
          control={
            <CheckBox
              checked={getBoolean(tabInfo3.DsmLed ?? "1")}
              onChange={(e) => {
                onChange("DsmLed", e.target.checked);
              }}
              color="primary"
            />
          }
          label={
            <Typography category="Default" variant="Body">
              {t("LED")}
            </Typography>
          }
        />
      </div>
      <div
        className={clsx({
          [classes.boderNoneBox]: dmc100,
          [classes.boderBottomBox]: dmc200,
          [classes.flexDirectionRow]: dmc200,
        })}
        style={{ paddingLeft: 16 }}
      >
        <div>
          <Typography category="Default" variant="Body">
            {t("Sensitivity")}
          </Typography>
        </div>
        <div
          className={classes.Swid98}
          style={{ maxWidth: 177, alignItems: "center" }}
        >
          <div style={{ padding: "0 16px" }}>
            <Selector
              t={t}
              keyValue={(() => {
                if (tabInfo3.DsmSensitivity) return tabInfo3.DsmSensitivity;
                if (_.includes(DMC200_MODELS, camera?.model)) return "2";
                if (_.includes(DMC100_MODELS, camera?.model)) return "1";
              })()}
              dense
              mobile={mobile}
              onChange={(e) => {
                onChange("DsmSensitivity", e.key);
              }}
              items={_.map(DsmSensitivity, (val, key) => {
                return {
                  key: key,
                  value: val,
                };
              })}
            />
          </div>

          <Tooltip
            widthLarge
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography category="Default" variant="CaptionBold">
                  {t("DMS event detection sensitivity")}
                </Typography>
                <br />

                <Typography category="Default" variant="Caption">
                  * {t("Only drowsy and distracted include_")}
                </Typography>
                <br />

                <Typography category="Default" variant="CaptionBold">
                  {t("Drowsy")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Caption"
                  dangerouslySetInnerHTML={{
                    __html: t("Drowsy sensitivity_"),
                  }}
                />
                <br />

                <Typography category="Default" variant="CaptionBold">
                  {t("Distracted")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Caption"
                  dangerouslySetInnerHTML={{
                    __html: t("Distracted sensitivity_"),
                  }}
                />
              </div>
            }
            placement="right"
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        </div>
      </div>
      <div
        className={clsx({
          [classes.boderNoneBox]: dmc100 || dmc200,
          [classes.flexDirectionRow]: dmc200,
        })}
        style={{ paddingLeft: 16 }}
      >
        <div>
          <Typography category="Default" variant="Body">
            {t("Volume")}
          </Typography>
        </div>
        <div className={classes.wid224}>
          <Sliders
            max={dmc200 ? 3 : 4}
            value={parseInt(tabInfo3.DsmVolume ?? "2")}
            onChange={(e, value) => {
              const v = value as number;
              onChange("DsmVolume", v.toString());
            }}
          ></Sliders>
          <Typography
            category="Default"
            variant="Small"
            className={classes.sliderText}
          >
            {tabInfo3.DsmVolume ?? "2"}
          </Typography>
        </div>
      </div>

      {dmc100 && (
        <div className={classes.boderBottomBox}>
          <FormControlLabel
            className={classes.mgNone}
            control={
              <CheckBox
                checked={getBoolean(tabInfo3.DsmRotate)}
                onChange={(e) => {
                  onChange("DsmRotate", e.target.checked);
                }}
                color="primary"
              />
            }
            label={
              <Typography category="Default" variant="Body">
                {t("DMS camera rotation")}
              </Typography>
            }
          />
        </div>
      )}
    </>
  );
};
