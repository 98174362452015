////////////////////////////////////////////////////////////////////////////////////////
// 리액트 기능 임포트
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
// -- 반응형 규격 정보
import { Webviewer } from "../contants/Breakpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  ICameraInfo,
  requestFota,
  loadCameras,
  clearFwUpdateInfo,
} from "../features/Camera/slice";
import { FwStatusModal } from "../components/FwStatusModal";
import axios from "axios";
import { IFirmwareInfo } from "../types";
import { RootState } from "../features/store";
import _ from "lodash";
import { FwAbbr } from "../contants/FirmwareUpdate";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import clsx from "clsx";
import { DMC200CameraRemoteFirmwareUpdate } from "../components/DMC200CameraRemoteFirmwareUpdate";

////////////////////////////////////////////////////////////////////////////////////////
// CSS 스타일
const useStyles = makeStyles((theme: Theme) => ({
  // 1. 루트
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 56px)",
    // overflowY: "auto",
  },
  // 모바일 전용 카메라이름 DIV
  camName: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "none",
    },
  },
  // 1-1. 폼
  formDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxWidth: 672,
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(4),
      padding: 0,
    },
    "& #update": {
      background: "rgba(233, 233, 234, 0.45)",
      marginBottom: theme.spacing(3),
    },
  },
  errorFormDiv: {
    marginTop: theme.spacing(16.875),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: theme.spacing(12.625),
    },
  },
  // 1-1-1. 리스트 DIV
  listDiv: {
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
  listDiv2: {
    boxSizing: "border-box",
    padding: "16px 0px 8px 0px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 16px 8px 16px",
    },
  },
  // 1-1-1-1. ul / li 스타일
  ul: {
    margin: 0,
    padding: "0px 1px",
    "& li": {
      listStylePosition: "outside",
      listStyleType: "none",
      marginTop: theme.spacing(0.625),
      marginBottom: theme.spacing(1),
      textIndent: "4px",
      marginLeft: theme.spacing(1.125),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        textIndent: "2px",
        marginLeft: theme.spacing(1.375),
      },
    },
  },
  // 1-1-1-1-1. li > span 마커 스타일 (들여쓰기)
  liSpan: {
    margin: "0 0 0 -20px",
  },
  // 1-1-1-1-2. li 논마커 스타일 (들여쓰기)
  emptyMark: {
    textIndent: "0px !important",
    marginLeft: "0px !important",
  },
  // 1-1-2.업데이트 버튼
  updateBtn: {
    whiteSpace: "nowrap",
    width: "100%",
    height: "36px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
      width: "151px",
    },
  },
  // 1-1-2-1.버튼 텍스트 사이즈 (font 규격 외)
  btnTxt: {
    height: "20px",
  },
}));

interface CameraFirmwareUpdateScreenProps {
  psn: string;
}

////////////////////////////////////////////////////////////////////////////////////////
// 실제 컴포넌트
export const DMC200FirmwareUpdateScreen = (
  props: CameraFirmwareUpdateScreenProps & ScreenDefaultProps
) => {
  const { psn, error } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const cameras = useSelector((state: RootState) => state[CAMERA].cameraList);
  const { firmwares } = useSelector((state: RootState) => state[CAMERA]);

  const [requestedDmc200, setRequestedDmc200] = useState<boolean | undefined>(
    false
  );
  const [isNew, setIsNew] = useState<boolean>();
  const [firmware, setFirmware] = useState<IFirmwareInfo>();
  const [dmcFirmware, setDmcFirmware] = useState<IFirmwareInfo>();
  const [camera, setCamera] = useState<ICameraInfo>();
  const [, /*releaseNote*/ setReleaseNote] = useState<string>();
  const [requested, setRequested] = useState(false);
  const [fwStatus, setFwStatus] = useState<string>("init");
  const fwUpdateInfo = useSelector(
    (state: RootState) => state[CAMERA].fwUpdateInfos[0]
  );
  useEffect(() => {
    return () => {
      dispatch(clearFwUpdateInfo());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fwUpdateInfo) {
      setFwStatus(fwUpdateInfo.update_status);
    }
  }, [dispatch, fwUpdateInfo]);

  useEffect(() => {
    if (fwStatus === "success") {
      dispatch(loadCameras());
    }
  }, [dispatch, fwStatus]);

  useEffect(() => {
    const getReleaseNote = async (fw: IFirmwareInfo) => {
      const url =
        process.env.NODE_ENV === "development"
          ? fw.release_note.replace("http://fota.blackvuecloud.com", "/fota")
          : fw.release_note.replace("http://", "https://");

      const note = await axios.get(url);
      // eslint-disable-next-line no-control-regex
      setReleaseNote(note.data.replace(new RegExp("\n", "g"), "<br/>"));
    };

    if (firmware) {
      getReleaseNote(firmware);
    }
  }, [firmware]);

  useEffect(() => {
    if (cameras) {
      const found = _.find(
        cameras.DeviceListInfo,
        (dev) => dev.device.psn === psn
      )?.device;
      setCamera((c) => {
        if (c?.psn !== found?.psn) {
          return found;
        } else if (c?.active !== found?.active || c?.fw_ver !== found?.fw_ver) {
          return found;
        }
        return c;
      });
    }
  }, [psn, cameras]);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    if (camera) {
      const model = FwAbbr[camera.model];
      const fws = _.find(firmwares, (f) => f.model === model);
      if (fws) {
        const info = _.find(fws.info, (info) =>
          _.includes(info.lang, camera.lang)
        );

        if (info) {
          setFirmware(info);
          if (parseFloat(info.version) <= parseFloat(camera.fw_ver)) {
            setIsNew(false);
          } else {
            setIsNew(true);
          }
        }
      }
      const dmcFws = _.find(firmwares, (f) => f.model === "DMC200");
      if (dmcFws) {
        const info = _.find(dmcFws.info, (info) =>
          _.includes(info.lang, camera.lang)
        );

        if (info) {
          setDmcFirmware(info);
        }
      }
    }
  }, [camera, firmwares]);

  const handleUpdate = useCallback(
    (targetFw: IFirmwareInfo, dmc?: boolean) => {
      if (camera) {
        setRequested(true);
        setRequestedDmc200(dmc);
        dispatch(
          requestFota({
            psn: camera.psn,
            fw_model: dmc ? camera.dms_type ?? "" : camera.model,
            now_fw_version: dmc ? camera.dms_version ?? "" : camera.fw_ver,
            new_fw_version: targetFw.version,
            fota_url: targetFw.file,
            file_size: targetFw.file_size,
            lang: camera.lang ?? "English",
            checksum: targetFw.md5,
          })
        );
      }
    },
    [camera, dispatch]
  );

  return (
    <div className={classes.root}>
      <div className={clsx(classes.formDiv, error && classes.errorFormDiv)}>
        {camera && firmware && dmcFirmware && (
          <DMC200CameraRemoteFirmwareUpdate
            camera={camera}
            firmware={firmware}
            newFw={isNew}
            dmcFirmware={dmcFirmware}
            onUpdate={handleUpdate}
          />
        )}
      </div>
      {camera && requested && firmware && (
        <FwStatusModal
          camera={camera}
          firmware={firmware}
          dmc200={requestedDmc200}
        />
      )}
    </div>
  );
};
