const TimeZone: { [key: string]: string } = {
  "-1100": "GMT-11:00",
  "-1000": "GMT-10:00",
  "-930": "GMT-09:30",
  "-900": "GMT-09:00",
  "-800": "GMT-08:00",
  "-700": "GMT-07:00",
  "-600": "GMT-06:00",
  "-500": "GMT-05:00",
  "-400": "GMT-04:00",
  "-300": "GMT-03:00",
  "-200": "GMT-02:00",
  "-230": "GMT-02:30",
  "-100": "GMT-01:00",
  "000": "GMT+00:00",
  "100": "GMT+01:00",
  "200": "GMT+02:00",
  "300": "GMT+03:00",
  "400": "GMT+04:00",
  "430": "GMT+04:30",
  "500": "GMT+05:00",
  "530": "GMT+05:30",
  "600": "GMT+06:00",
  "630": "GMT+06:30",
  "700": "GMT+07:00",
  "800": "GMT+08:00",
  "830": "GMT+08:30",
  "900": "GMT+09:00",
  "930": "GMT+09:30",
  "1000": "GMT+10:00",
  "1030": "GMT+10:30",
  "1100": "GMT+11:00",
  "1200": "GMT+12:00",
  "1300": "GMT+13:00",
};

export const TimeZoneList: { [key: string]: string } = {
  ACDT: "Australian Central Daylight Saving Time",
  ACST: "Australian Central Standard Time",
  ACT: "Acre Time",
  ACWST: "Australian Central Western Standard Time",
  ADT: "Atlantic Daylight Time",
  AEDT: "Australian Eastern Daylight Saving Time",
  AEST: "Australian Eastern Standard Time",
  AET: "Australian Eastern Time",
  AFT: "Afghanistan Time",
  AKDT: "Alaska Daylight Time",
  AKST: "Alaska Standard Time",
  ALMT: "Alma-Ata Time",
  AMST: "Amazon Summer Time",
  AMT: "Amazon Time",
  ANAT: "Anadyr Time",
  AQTT: "Aqtobe Time",
  ART: "Argentina Time",
  AST: "Arabia Standard Time",
  AWST: "Australian Western Standard Time",
  AZOST: "Azores Summer Time",
  AZOT: "Azores Standard Time",
  AZT: "Azerbaijan Time",
  BNT: "Brunei Time",
  BIOT: "British Indian Ocean Time",
  BIT: "Baker Island Time",
  BOT: "Bolivia Time",
  BRST: "Brasília Summer Time",
  BRT: "Brasília Time",
  BST: "Bangladesh Standard Time",
  BTT: "Bhutan Time",
  CAT: "Central Africa Time",
  CCT: "Cocos Islands Time",
  CDT: "Central Daylight Time",
  CEST: "Central European Summer Time",
  CET: "Central European Time",
  CHADT: "Chatham Daylight Time",
  CHAST: "Chatham Standard Time",
  CHOT: "Choibalsan Standard Time",
  CHOST: "Choibalsan Summer Time",
  CHST: "Chamorro Standard Time",
  CHUT: "Chuuk Time",
  CIST: "Clipperton Island Standard Time",
  CKT: "Cook Island Time",
  CLST: "Chile Summer Time",
  CLT: "Chile Standard Time",
  COST: "Colombia Summer Time",
  COT: "Colombia Time",
  CST: "Central Standard Time",
  CT: "Central Time",
  CVT: "Cape Verde Time",
  CWST: "Central Western Standard Time",
  CXT: "Christmas Island Time",
  DAVT: "Davis Time",
  DDUT: "Dumont d'Urville Time",
  DFT: "AIX-specific equivalent of Central European Time",
  EASST: "Easter Island Summer Time",
  EAST: "Easter Island Standard Time",
  EAT: "East Africa Time",
  ECT: "Eastern Caribbean Time",
  EDT: "Eastern Daylight Time",
  EEST: "Eastern European Summer Time",
  EET: "Eastern European Time",
  EGST: "Eastern Greenland Summer Time",
  EGT: "Eastern Greenland Time",
  EST: "Eastern Standard Time",
  ET: "Eastern Time",
  FET: "Further-eastern European Time",
  FJT: "Fiji Time",
  FKST: "Falkland Islands Summer Time",
  FKT: "Falkland Islands Time",
  FNT: "Fernando de Noronha Time",
  GALT: "Galápagos Time",
  GAMT: "Gambier Islands Time",
  GET: "Georgia Standard Time",
  GFT: "French Guiana Time",
  GILT: "Gilbert Island Time",
  GIT: "Gambier Island Time",
  GMT: "Greenwich Mean Time",
  GST: "South Georgia and the South Sandwich Islands Time",
  GYT: "Guyana Time",
  HDT: "Hawaii–Aleutian Daylight Time",
  HAEC: "Heure Avancée d'Europe Centrale French-language name for CEST",
  HST: "Hawaii–Aleutian Standard Time",
  HKT: "Hong Kong Time",
  HMT: "Heard and McDonald Islands Time",
  HOVST: "Hovd Summer Time",
  HOVT: "Hovd Time",
  ICT: "Indochina Time",
  IDLW: "International Day Line West time zone",
  IDT: "Israel Daylight Time",
  IOT: "Indian Ocean Time",
  IRDT: "Iran Daylight Time",
  IRKT: "Irkutsk Time",
  IRST: "Iran Standard Time",
  IST: "Indian Standard Time",
  JST: "Japan Standard Time",
  KALT: "Kaliningrad Time",
  KGT: "Kyrgyzstan Time",
  KOST: "Kosrae Time",
  KRAT: "Krasnoyarsk Time",
  KST: "Korea Standard Time",
  LHST: "Lord Howe Standard Time",
  LINT: "Line Islands Time",
  MAGT: "Magadan Time",
  MART: "Marquesas Islands Time",
  MAWT: "Mawson Station Time",
  MDT: "Mountain Daylight Time",
  MET: "Middle European Time",
  MEST: "Middle European Summer Time",
  MHT: "Marshall Islands Time",
  MIST: "Macquarie Island Station Time",
  MIT: "Marquesas Islands Time",
  MMT: "Myanmar Standard Time",
  MSK: "Moscow Time",
  MST: "Malaysia Standard Time",
  MUT: "Mauritius Time",
  MVT: "Maldives Time",
  MYT: "Malaysia Time",
  NCT: "New Caledonia Time",
  NDT: "Newfoundland Daylight Time",
  NFT: "Norfolk Island Time",
  NOVT: "Novosibirsk Time",
  NPT: "Nepal Time",
  NST: "Newfoundland Standard Time",
  NT: "Newfoundland Time",
  NUT: "Niue Time",
  NZDT: "New Zealand Daylight Time",
  NZST: "New Zealand Standard Time",
  OMST: "Omsk Time",
  ORAT: "Oral Time",
  PDT: "Pacific Daylight Time",
  PET: "Peru Time",
  PETT: "Kamchatka Time",
  PGT: "Papua New Guinea Time",
  PHOT: "Phoenix Island Time",
  PHT: "Philippine Time",
  PHST: "Philippine Standard Time",
  PKT: "Pakistan Standard Time",
  PMDT: "Saint Pierre and Miquelon Daylight Time",
  PMST: "Saint Pierre and Miquelon Standard Time",
  PONT: "Pohnpei Standard Time",
  PST: "Pacific Standard Time",
  PWT: "Palau Time",
  PYST: "Paraguay Summer Time",
  PYT: "Paraguay Time",
  RET: "Réunion Time",
  ROTT: "Rothera Research Station Time",
  SAKT: "Sakhalin Island Time",
  SAMT: "Samara Time",
  SAST: "South African Standard Time",
  SBT: "Solomon Islands Time",
  SCT: "Seychelles Time",
  SDT: "Samoa Daylight Time",
  SGT: "Singapore Time",
  SLST: "Sri Lanka Standard Time",
  SRET: "Srednekolymsk Time",
  SRT: "Suriname Time",
  SST: "Samoa Standard Time",
  SYOT: "Showa Station Time",
  TAHT: "Tahiti Time",
  THA: "Thailand Standard Time",
  TFT: "French Southern and Antarctic Time",
  TJT: "Tajikistan Time",
  TKT: "Tokelau Time",
  TLT: "Timor Leste Time",
  TMT: "Turkmenistan Time",
  TRT: "Turkey Time",
  TOT: "Tonga Time",
  TVT: "Tuvalu Time",
  ULAST: "Ulaanbaatar Summer Time",
  ULAT: "Ulaanbaatar Standard Time",
  UTC: "Coordinated Universal Time",
  UYST: "Uruguay Summer Time",
  UYT: "Uruguay Standard Time",
  UZT: "Uzbekistan Time",
  VET: "Venezuelan Standard Time",
  VLAT: "Vladivostok Time",
  VOLT: "Volgograd Time",
  VOST: "Vostok Station Time",
  VUT: "Vanuatu Time",
  WAKT: "Wake Island Time",
  WAST: "West Africa Summer Time",
  WAT: "West Africa Time",
  WEST: "Western European Summer Time",
  WET: "Western European Time",
  WIB: "Western Indonesian Time",
  WIT: "Eastern Indonesian Time",
  WITA: "Central Indonesia Time",
  WGST: "West Greenland Summer Time",
  WGT: "West Greenland Time",
  WST: "Western Standard Time",
  YAKT: "Yakutsk Time",
  YEKT: "Yekaterinburg Time",
};

export default TimeZone;
