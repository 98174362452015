import { DeviceFirmwareConfig } from ".";

export const DR750G_PRO: DeviceFirmwareConfig = {
  default: {
    Time: {
      GpsSync: {},
      TimeSet: {},
    },
    Video: {
      ImageSetting: {
        options: [{ key: "0", value: "2K QHD@30fps + FHD@30fps" }],
      },
      VideoQuality: {
        options: [
          { key: "0", value: "Highest(Extreme)" },
          { key: "1", value: "Highest" },
          { key: "2", value: "High" },
          { key: "3", value: "Normal" },
        ],
      },
      NightVision: {
        options: [
          { key: "1", value: "Always" },
          { key: "2", value: "Parking mode only" },
        ],
      },
      FrontBrightness: {},
      RearBrightness: {},
    },
    Recording: {
      NormalRecord: {},
      AutoParking: {},
      RearParkingMode: {
        explain: "ON: front and_",
      },
      UseGpsInfo: {},
      VoiceRecord: {},
      DateDisplay: {},
      SpeedUnit: {},
      RecordTime: {
        options: [
          {
            key: "1",
            value: "1 min",
          },
        ],
      },
      LockEvent: {},
      FrontRotate: {},
      RearRotate: {
        options: [
          {
            key: "0",
            value: "Default",
          },
          {
            key: "1",
            value: "Rotate video 180°",
          },
          {
            key: "2",
            value: "Mirror video",
          },
        ],
      },
    },
    Gsensor: {
      Normal: {},
      Parking: {},
    },
    MotionDetection: {
      MOTIONSENSOR: {},
    },
    System: {
      LED: {
        RECLED: {},
        NORMALLED: {},
        PARKINGLED: {},
        RearLED: {},
        LTELED: {},
        WifiLED: {},
      },
      PSENSOR: {},
      VoiceGuidance: {
        STARTVOICE: {},
        NORMALSTARTVOICE: {},
        EVENTSTARTVOICE: {},
        CHANGERECORDMODEVOICE: {},
        ENDVOICE: {},
        SPEEDALERTVOICE: {},
        PARKINGEVENTVOICE: {},
        CLOUDVOICE: {},
        CHANGECONFIGVOICE: {},
        SHARPTURNVOICE: {},
        HARSHBRAKINGVOICE: {},
        ACCELERATIONVOICE: {},
      },
      VOLUME: {},
      ScheduledReboot: {},
      SpeedAlert: {},
      ADAS: {},
      BatteryProtection: {},
      UserTextOverlay: {},
    },
    Wifi: {
      LoginCredentials: {},
      WifiSleepMode: {},
    },
    Cloud: { CloudService: {}, sta1: {}, sta2: {}, sta3: {} },
    FirmwareLanguage: ["English"],
  },
  1.004: {
    System: {
      EventRev: {},
    },
  },
  1.005: {
    Video: {
      NightVision: undefined,
      FrontBrightness: undefined,
      RearBrightness: undefined,
    },
    System: {
      LED: {
        RECLED: {},
        NORMALLED: {},
        PARKINGLED: {},
        RearLED: {},
        BTLED: {},
        LTELED: {},
        WifiLED: {},
      },
      BatteryProtection: undefined,
      BatteryProtectionRev: {
        minVolt: 12,
        maxVolt: 12.5,
        defaultVolt: 12,
        minVoltHeavy: 23.2,
        maxVoltHeavy: 24,
        defaultVoltHeavy: 23.2,
        defaultTime: "600",
        minTime: "100",
        maxTime: "4800",
        // Rev버전으로 업데이트 직후 기존 설정값은 0~3으로 저장되어 있는데,
        // 이것을 Rev용 값으로 변경해주는 테이블
        LowvoltageVolt: {
          "1": "1200",
          "2": "1220",
          "3": "1250",
        },
        LowvoltageVoltHeavy: {
          "1": "2320",
          "2": "2360",
          "3": "2400",
        },
      },
      Seamless: {},
    },
  },
};
