import {
  DashcamSettings,
  LightColors,
  MobileMenu,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import React from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ICameraInfo } from "../features/Camera/slice";

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: LightColors.secondary["11"],
  },
  deleteIcon: {
    display: "block",
    fill: LightColors.primary["1"],
  },

  newDiv: {
    backgroundColor: LightColors.secondary["11"],
    borderRadius: 12,
    width: 43,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
}));

interface MobileCameraMenuProps {
  open: boolean;
  camera?: ICameraInfo;
  newFw?: boolean;
  onClose: () => void;
  onRename?: (() => void) | null;
  onSettings?: (() => void) | null;
  onUpdate?: (() => void) | null;
  onDelete?: (() => void) | null;
  onRebootCamera?: (() => void) | null;
}

export const MobileCameraMenu = ({
  open,
  camera,
  newFw,
  onClose,
  onRename,
  onSettings,
  onUpdate,
  onDelete,
  onRebootCamera,
}: MobileCameraMenuProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MobileMenu open={open} onClose={onClose}>
      {onRename !== undefined && (
        <WebMenuItem
          disabled={onRename === null}
          startIcon={<EditIcon fontSize="small" />}
          onClick={onRename === null ? undefined : onRename}
        >
          {t("Rename")}
        </WebMenuItem>
      )}
      {onSettings !== undefined && (
        <WebMenuItem
          disabled={onSettings === null}
          startIcon={<SettingsIcon fontSize="small" />}
          onClick={onSettings === null ? undefined : onSettings}
        >
          {t("Settings")}
        </WebMenuItem>
      )}
      {onUpdate !== undefined && (
        <WebMenuItem
          disabled={onUpdate === null}
          startIcon={
            <DashcamSettings fontSize="small" className={classes.deleteIcon} />
          }
          endIcon={
            newFw ? (
              <div className={classes.newDiv}>
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["0"]}
                >
                  NEW
                </Typography>
              </div>
            ) : undefined
          }
          onClick={onUpdate === null ? undefined : onUpdate}
        >
          {t("Remote firmware update")}
        </WebMenuItem>
      )}
      {onRebootCamera !== undefined && (
        <WebMenuItem
          startIcon={<RefreshIcon fontSize="small" />}
          disabled={onRebootCamera === null || camera?.active === "off"}
          onClick={onRebootCamera === null ? undefined : onRebootCamera}
        >
          {t("Reboot Camera")}
        </WebMenuItem>
      )}

      {onDelete !== undefined && (
        <WebMenuItem
          disabled={onDelete === null}
          className={classes.delete}
          startIcon={
            <DeleteIcon
              fontSize="small"
              htmlColor={LightColors.secondary["11"]}
            />
          }
          onClick={onDelete === null ? undefined : onDelete}
        >
          {t("Delete")}
        </WebMenuItem>
      )}
    </MobileMenu>
  );
};
