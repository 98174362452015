import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  CheckBox,
  Fonts,
  LightColors,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/styles";

import { Webviewer } from "../../contants/Breakpoints";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import SearchIcon from "@material-ui/icons/Search";
import { FormControlLabel, useMediaQuery } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableContainer from "@material-ui/core/TableContainer";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import {
  DashCamList,
  GROUP,
  groupAddCameras,
} from "../../features/Group/slice";
import { ICameraList } from "../../features/Camera/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { NoResults } from "../NoResults";

const TableHeaderCell = withStyles((theme: Theme) => ({
  head: {
    padding: "8px 13px",
    color: LightColors.primary["2"],
    ...Fonts.Default.Small,
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),
  },
}))(MuiTableCell);

const TableCell = withStyles((theme: Theme) => ({
  body: {
    padding: "8px 13px 9px",

    color: LightColors.primary["1"],
    ...Fonts.Default.Body,
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),
    "&:last-child": {
      borderBottom: "none",
    },
  },
}))(MuiTableCell);

const TableBodyRow = withStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: "100%",
    borderRadius: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      borderRadius: 4,
    },
  },
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: LightColors.primary["0"],
    overflow: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: "auto",
    },
  },

  buttonRoot: {
    padding: 6,
    marginRight: -6,
  },
  searchBtn: {
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.375),

    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginBottom: theme.spacing(0.375),
      width: "100%",
      justifyContent: "flex-end",
      marginRight: 0,
    },
  },
  searchIcon: {
    "& svg": {
      fontSize: "1.125rem!important",
    },
  },
  groupNameText: {
    marginBottom: theme.spacing(2),
  },
  adminTablewrap: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginBottom: 2,
    },
  },
  modalContent: {
    marginTop: -2,
    marginBottom: -1,
  },
  modalBottom: {
    justifyContent: "space-between",
    padding: theme.spacing(2.125, 2, 1.5),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0.75, 3, 3),
    },
  },
  modalContentWrap: {
    display: "block",
    // justifyContent: "center",
    padding: theme.spacing(2.25, 3, 1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.5, 3, 1.25),
    },
  },
  noCandidateDiv: {
    display: "flex",
    flexDirection: "column",
  },
  noCandidateTextDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(7.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },
  noCandidateTextInnerDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      flexDirection: "column",
      width: 346,
    },
  },
  noCameraDiv: {
    margin: theme.spacing(3, 0),
    display: "flex",
    justifyContent: "center",
    // textAlign: "center",
  },
  noCameraTextDiv: {
    display: "flex",
    flexDirection: "column",
  },
  noCandidateDetailDiv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    "& > span:first-child": {
      marginLeft: 0,
    },
    "& > span": {
      marginLeft: theme.spacing(1),
    },
  },
  checkbox: {
    ...(theme.direction === "rtl" ? { marginLeft: 1 } : { marginRight: 1 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 10 } : { marginRight: 10 }),
    },
  },
  allCheckDiv: {
    ...(theme.direction === "rtl" && { marginRight: 0 }),
  },
}));

interface GroupAddCameraModalProps {
  open: boolean;
  group?: DashCamList;
  camera?: ICameraList;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

export const GroupAddCameraModal = ({
  open,
  group,
  camera,
  onClose,
  onClickNegative,
  onClickPositive,
}: GroupAddCameraModalProps) => {
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();

  const { loading, type } = useSelector((state: RootState) => state[GROUP]);

  const classes = useStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const [allChecked, setAllChecked] = React.useState<
    "all" | "intermediate" | "none"
  >("none");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [searchKey, setSearchKey] = React.useState("");

  const newSelecteds = _.difference(
    _.map(camera?.DeviceListInfo, (n) => n.device.psn),
    _.map(group?.DashCamUser, (d) => d.PSN)
  );
  const buttonsRef = useRef<RefObject<HTMLButtonElement>[]>(
    _.map(camera, () => createRef<HTMLButtonElement>())
  );

  //checkBox
  useEffect(() => {
    if (selected.length === 0) {
      setAllChecked("none");
    } else if (selected.length < newSelecteds.length) {
      setAllChecked("intermediate");
    } else if (selected.length === newSelecteds.length) {
      setAllChecked("all");
    }
  }, [selected.length, newSelecteds.length]);

  const handleSelectAllClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [newSelecteds]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>, psn: string) => {
      const selectedIndex = selected.indexOf(psn);
      if (selectedIndex > -1) {
        setSelected(_.filter(selected, (e) => e !== psn));
      } else {
        setSelected([...selected, psn]);
      }
    },
    [selected]
  );

  const isSelected = useCallback(
    (psn: string) => {
      return selected.indexOf(psn) !== -1;
    },
    [selected]
  );

  const handleAddCamera = (group: DashCamList) => {
    _.map(selected, (PSN) => ({
      PSN,
      GroupName: group.GroupName,
      GroupID: group.GroupID,
    }));
    dispatch(
      groupAddCameras(
        _.map(selected, (PSN) => ({
          PSN,
          GroupName: group.GroupName,
          GroupID: group.GroupID,
        }))
      )
    );
    setSelected([]);
    setAllChecked("none");
  };

  const candidateCams = useMemo(() => {
    return _.chain(camera?.DeviceListInfo)
      .filter((dev) => !dev.groupManagementID)
      .map((dev) => dev.device)
      .value();
  }, [camera?.DeviceListInfo]);

  const filteredCameras = useMemo(() => {
    return _.filter(
      candidateCams,
      (camera) =>
        camera.dev_name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
        camera.model.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
    );
  }, [candidateCams, searchKey]);

  const noCandidatesMarkup = useMemo(() => {
    return (
      <div className={classes.noCandidateDiv}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {group?.GroupName}
        </Typography>
        <div className={classes.noCandidateTextDiv}>
          <div className={classes.noCandidateTextInnerDiv}>
            <Typography
              category="Default"
              variant="H6"
              htmlColor={LightColors.primary["3"]}
              className={classes.noCameraDiv}
            >
              {t("No cameras available")}
            </Typography>
            <div className={classes.noCameraTextDiv}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["3"]}
                style={{ marginBottom: theme.spacing(1) }}
              >
                {t("Most likely reasons_")}
              </Typography>
              <div className={classes.noCandidateDetailDiv}>
                <Typography
                  category="Default"
                  variant="SmallBold"
                  htmlColor={LightColors.primary["3"]}
                >
                  • {t("All cameras were_")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["3"]}
                >
                  {t("You can remove_")}
                </Typography>
              </div>
              <div className={classes.noCandidateDetailDiv}>
                <Typography
                  category="Default"
                  variant="SmallBold"
                  htmlColor={LightColors.primary["3"]}
                >
                  • {t("No cameras were_")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["3"]}
                >
                  {t("You can add_cameras")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    classes.noCameraDiv,
    classes.noCameraTextDiv,
    classes.noCandidateDetailDiv,
    classes.noCandidateDiv,
    classes.noCandidateTextDiv,
    classes.noCandidateTextInnerDiv,
    group?.GroupName,
    t,
    theme,
  ]);

  const cameraListMarkup = useMemo(() => {
    return (
      <div className={classes.modalContent}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
          className={classes.groupNameText}
        >
          {group?.GroupName}
        </Typography>
        <Input
          placeholder={t("Camera")}
          startIcon={<SearchIcon style={{ color: LightColors.primary["3"] }} />}
          startIconClassName={classes.searchIcon}
          className={classes.searchBtn}
          dense
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          autoFocus
        />
        {filteredCameras.length === 0 && (
          <div style={{ marginTop: mobile ? 140 : 0 }}>
            <NoResults groupCamera />
          </div>
        )}
        {filteredCameras.length > 0 && (
          <MuiTableContainer className={classes.adminTablewrap}>
            <MuiTable aria-label="simple table">
              <MuiTableHead>
                <MuiTableRow>
                  <TableHeaderCell>
                    <FormControlLabel
                      className={classes.allCheckDiv}
                      control={
                        <CheckBox
                          key={`groupCamera`}
                          color="primary"
                          indeterminate={allChecked === "intermediate"}
                          checked={allChecked === "all"}
                          onChange={handleSelectAllClick}
                          className={classes.checkbox}
                          // style={{ marginRight: mobile ? 1 : 10 }}
                        />
                      }
                      label={
                        <Typography
                          category="Default"
                          variant="Small"
                          htmlColor={LightColors.primary["2"]}
                        >
                          {t("Cameras")}
                        </Typography>
                      }
                    />
                  </TableHeaderCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {_.map(filteredCameras, (camera, index) => {
                  const isItemSelected = isSelected(camera.psn);
                  return (
                    <TableBodyRow key={`groupCamera-${index}`}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <CheckBox
                              className={classes.checkbox}
                              color="primary"
                              key={camera.psn}
                              ref={buttonsRef.current[index]}
                              checked={isItemSelected}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClick(e, camera.psn);
                              }}
                            />
                          }
                          label={camera.dev_name}
                        />
                      </TableCell>
                    </TableBodyRow>
                  );
                })}
              </MuiTableBody>
            </MuiTable>
          </MuiTableContainer>
        )}
      </div>
    );
  }, [
    allChecked,
    classes.adminTablewrap,
    classes.allCheckDiv,
    classes.checkbox,
    classes.groupNameText,
    classes.modalContent,
    classes.searchBtn,
    classes.searchIcon,
    filteredCameras,
    group?.GroupName,
    handleClick,
    handleSelectAllClick,
    isSelected,
    mobile,
    searchKey,
    t,
  ]);

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={(e) => {
        if (candidateCams.length === 0) {
          onClose?.(e);
        } else {
          group && handleAddCamera(group);
        }
      }}
      heading={t("Add camera")}
      actionClassName={classes.modalBottom}
      contentClassName={classes.modalContentWrap}
      content={
        <>
          {candidateCams.length === 0 && noCandidatesMarkup}
          {candidateCams.length > 0 && cameraListMarkup}
        </>
      }
      LButton={candidateCams.length === 0 ? undefined : t("Cancel")}
      RButton={candidateCams.length === 0 ? t("OK") : t("Add")}
      loading={loading && type === groupAddCameras.type}
      close
      btnCenter
      btnNoMargin={candidateCams.length === 0 && true}
      fullSize={mobile}
      RButtonDisabled={selected.length === 0 && candidateCams.length !== 0}
      selectedNum={
        candidateCams.length !== 0 &&
        `${selected.length}/${candidateCams.length}`
      }
    />
  );
};
