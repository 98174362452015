import { IFirmwareConfig } from ".";

export const BlackVue_9_LTE = {
  default: {
    Time: {
      GpsSync: {},
      TimeSet: {},
    },
    Video: {
      ImageSetting: {
        options: [{ key: "0", value: "4K UHD@30 + FHD@30" }],
      },
      VideoQuality: {
        options: [
          { key: "0", value: "Highest(Extreme)" },
          { key: "1", value: "Highest" },
          { key: "2", value: "High" },
          { key: "3", value: "Normal" },
        ],
      },
    },

    Recording: {
      NormalRecord: {},
      AutoParking: {},
      RearParkingMode: {
        explain: "ON: front and_",
      },
      VoiceRecord: {},
      DateDisplay: {},
      SpeedUnit: {},
      UseGpsInfo: {},
      RecordTime: {
        options: [
          {
            key: "1",
            value: "1 min",
          },
        ],
      },
      LockEvent: {},
      FrontRotate: {},
      RearRotate: {
        options: [
          {
            key: "0",
            value: "Default",
          },
          {
            key: "1",
            value: "Rotate video 180°",
          },
          {
            key: "2",
            value: "Mirror video",
          },
        ],
      },
    },
    Gsensor: {
      Normal: {},
      Parking: {},
    },
    MotionDetection: {
      MOTIONSENSOR: {},
    },
    System: {
      LED: {
        RECLED: {},
        NORMALLED: {},
        PARKINGLED: {},
        RearLED: {},
        WifiLED: {},
        BTLED: {},
        LTELED: {},
      },
      PSENSOR: {},
      VoiceGuidance: {
        STARTVOICE: {},
        NORMALSTARTVOICE: {},
        EVENTSTARTVOICE: {},
        CHANGERECORDMODEVOICE: {},
        ENDVOICE: {},
        SPEEDALERTVOICE: {},
        PARKINGEVENTVOICE: {},
        CLOUDVOICE: {},
        CHANGECONFIGVOICE: {},
        SHARPTURNVOICE: {},
        HARSHBRAKINGVOICE: {},
        ACCELERATIONVOICE: {},
      },
      VOLUME: {},
      ScheduledReboot: {},
      SpeedAlert: {},
      BatteryProtectionRev: {
        minVolt: 11.8,
        maxVolt: 12.5,
        defaultVolt: 12,
        minVoltHeavy: 22.8,
        maxVoltHeavy: 24,
        defaultVoltHeavy: 23.2,
        defaultTime: "1200",
        minTime: "100",
        maxTime: "4800",
        // Rev버전으로 업데이트 직후 기존 설정값은 0~3으로 저장되어 있는데,
        // 이것을 Rev용 값으로 변경해주는 테이블
        LowvoltageVolt: {
          "1": "1180",
          "2": "1200",
          "3": "1250",
        },
        LowvoltageVoltHeavy: {
          "1": "2280",
          "2": "2320",
          "3": "2400",
        },
      },
      UserTextOverlay: {},
      EventRev: {},
      Seamless: {},
    },
    Wifi: {
      LoginCredentials: {},
      WifiBand: {},
    },
    Cloud: { CloudService: {}, sta1: {}, sta2: {}, sta3: {} },
    FirmwareLanguage: [
      "English",
      "Japan",
      "China",
      "German",
      "French",
      "Thai",
      "Idn",
      "Vietnam",
      "Spain",
      "Italian",
      "Dutch",
      "Polish",
      "Norwegian",
      "Finnish",
      "Arabic",
      "Korea",
    ],
    DMS: {},
  } as IFirmwareConfig,
};
