import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../store";
import { IUserLoginInfo, USER } from "../User/slice";
import {
  IEventRequest,
  ILatestEvent,
  LatestEventOrig,
  loadEvent,
  loadLatestEvent,
  successLoadEvent,
  successLoadLatestEvent,
} from "./slice";
import * as Api from "../../apis";
import { RESULT_CODE } from "../../types";
import _ from "lodash";
import moment from "moment";
import { CAMERA } from "../Camera/slice";

function* handleLoadEvent({ payload }: PayloadAction<IEventRequest>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { cameraList } = yield select((state: RootState) => state[CAMERA]);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getEvents,
      email,
      loginInfo.user_token,
      payload.psn,
      payload.msg_code,
      payload.startDate,
      payload.endDate
    );
    const {
      resultcode,
      response: { AlarmData },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: {
        AlarmData: LatestEventOrig[];
      };
    };
    if (resultcode === "BC_ERR_OK") {
      const item: ILatestEvent[] = _.chain(AlarmData)
        .groupBy("psn")
        .map((values, key) => {
          const cam = _.find(
            cameraList?.DeviceListInfo,
            (dev) => dev.device.psn === key
          )?.device;
          if (cam) {
            const plus = cam.interval > 0;
            const interval = Math.abs(cam.interval);
            const hour = Math.floor(interval / 60);
            const min = interval - hour * 60;
            return _.map(values, (v) => {
              const dateString = v.vdate.replace(
                "Z",
                `${plus ? "+" : "-"}${hour.toString().padStart(2, "0")}:${min
                  .toString()
                  .padStart(2, "0")}`
              );
              const vdate = moment(dateString);
              return {
                ...v,
                vdate,
              };
            });
          } else {
            return _.map(values, (v) => ({ ...v, vdate: moment(v.vdate) }));
          }
        })
        .flattenDeep()
        .sortBy((ev) => moment(ev.vdate))
        .reverse()
        .value();
      yield put(successLoadEvent(item));
    }
  } catch (err) {}
}

function* handleLoadLatestEvent({ payload }: PayloadAction<string[]>) {
  try {
    const { cameraList } = yield select((state: RootState) => state[CAMERA]);
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.getLatestEvents,
      email,
      loginInfo.user_token,
      payload
    );
    const {
      resultcode,
      response: { AlarmData },
    } = resp.data as {
      resultcode: RESULT_CODE;
      response: {
        AlarmData: LatestEventOrig[];
      };
    };
    if (resultcode === "BC_ERR_OK") {
      const item: ILatestEvent[] = _.chain(AlarmData)
        .groupBy("psn")
        .map((values, key) => {
          const cam = _.find(
            cameraList?.DeviceListInfo,
            (dev) => dev.device.psn === key
          )?.device;
          if (cam) {
            const plus = cam.interval > 0;
            const interval = Math.abs(cam.interval);
            const hour = Math.floor(interval / 60);
            const min = interval - hour * 60;
            return _.map(values, (v) => {
              const dateString = v.vdate.replace(
                "Z",
                `${plus ? "+" : "-"}${hour.toString().padStart(2, "0")}:${min
                  .toString()
                  .padStart(2, "0")}`
              );
              const vdate = moment(dateString);
              return {
                ...v,
                vdate,
              };
            });
          } else {
            return _.map(values, (v) => ({ ...v, vdate: moment(v.vdate) }));
          }
        })
        .flattenDeep()
        .sortBy((ev) => moment(ev.vdate))
        .reverse()
        .value();
      yield put(successLoadLatestEvent(item));
    }
  } catch (err) {}
}

export function* watchEvent() {
  yield takeLatest(loadEvent, handleLoadEvent);
  yield takeLatest(loadLatestEvent, handleLoadLatestEvent);
}
