import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Fab,
  LightColors,
  CameraTable,
  Typography,
  Modal,
} from "@thingsw/pitta-design-system";

import AddIcon from "@material-ui/icons/Add";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
import SearchIcon from "@material-ui/icons/Search";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import Input from "@thingsw/pitta-design-system/dist/components/Input";

import { EmptyItems } from "../components/EmptyItems";

import { Webviewer } from "../contants/Breakpoints";
import clsx from "clsx";
import { CameraListPanel } from "../components/cameras/CameraListPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  clearCamera,
  clearPublicCamera,
  ICameraInfo,
  loadMyCemrasLocation,
  loadPublicCamera,
  resetLocationLoaded,
} from "../features/Camera/slice";
import { RootState } from "../features/store";
import { MobileDrawer } from "../components/MobileDrawer";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { GROUP, loadGroups } from "../features/Group/slice";
import { LocationMap } from "../components/maps/LocationMap";
import {
  AvailableFw,
  AvailableFws,
  IGPSLocation,
  ILatLngBounds,
} from "../types";
import { SubMgrModal } from "../components/accounts/SubMgrModal";
import { USER } from "../features/User/slice";
import { loadWebSubscription, PAYMENT } from "../features/Payment/slice";
import { PermissionProps } from "../hoc/withViewerTemplate";
import { NoResults } from "../components/NoResults";
import { CameraMenu } from "../components/cameras/CameraMenu";
import { MobileCameraMenu } from "../components/MobileCameraMenu";
import { GEOFENCE, loadGeofences } from "../features/Geofence/slice";
import { LiveViewModal } from "../components/modals/LiveViewModal";
import { Onboarding } from "../components/onboarding/Onboarding";
import { OnboardingStep1 } from "../components/onboarding/OnboardingStep1";
import { OnboardingStep2 } from "../components/onboarding/OnboardingStep2";
import { OnboardingStep3 } from "../components/onboarding/OnboardingStep3";
import { OnboardingStep4 } from "../components/onboarding/OnboardingStep4";
import { OnboardingStep5 } from "../components/onboarding/OnboardingStep5";
import { OnboardingStep6 } from "../components/onboarding/OnboardingStep6";
import { openToast } from "../features/Toast/slice";
import { OnboardingMobile } from "../components/onboarding/OnboardingMobile";
import { PSN650 } from "../contants/Models";
import { PricingModal } from "../components/modals/PricingModal";
import { findNewFw } from "../utils/Firmware";

interface CameraScreeProps {
  openMenu: boolean;
  error?: string;
  onRename?: (cam: ICameraInfo) => void;
  onDelete?: (cam: ICameraInfo) => void;
  onRebootCamera?: (cam: ICameraInfo) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(var(--vh, 1vh) * 100 - 56px - 165px)",
    marginTop: (props: CameraScreeProps) => (props.error ? 165 + 127 : 221),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: CameraScreeProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
          : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
      marginTop: (props: CameraScreeProps) => (props.error ? 68 + 85 : 68 + 56),
    },
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 165,
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      height: 68,
      padding: theme.spacing(0, 4),
    },
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "fit-content",
      flexDirection: "row",
    },
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 21 } : { marginRight: 21 }),
    },
  },
  searchBtnOpen: {
    width: "100%",
    marginRight: 0,
  },
  camCntText: {
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin:
        theme.direction === "rtl"
          ? theme.spacing(0, 3, 0, 0)
          : theme.spacing(0, 0, 0, 3),
    },
  },
  dotText: {
    margin: "0 3px",
  },
  body: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    marginBottom: 56,
    overflow: "auto",
    zIndex: 100,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },
  alignStart: {
    alignItems: "flex-start",
  },
  directionRow: {
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  hide: {
    display: "none",
  },
  fabMargin: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.5) }
      : { marginLeft: theme.spacing(0.5) }),
  },
  tableDiv: {
    width: "100%",
    minWidth: 1141,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 0,
      padding: theme.spacing(2, 4),
    },
  },
  listPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
    height: "calc(100% - 32px)",
    margin: theme.spacing(2),
    display: "flex",
    overflow: "visible",
  },
  subHeaderDiv: {
    position: "fixed",
    top: (props: CameraScreeProps) => (props.error ? 85 : 56),
    ...(theme.direction === "rtl"
      ? {
          left: 0,
          right: 73,
        }
      : {
          right: 0,
          left: 73,
        }),
    zIndex: 90,
    backgroundColor: LightColors.primary["0"],
  },
  subHeaderDivOpen: {
    ...(theme.direction === "rtl" ? { right: 235 } : { left: 235 }),
  },
  subHeaderMobile: {
    top: (props: CameraScreeProps) => (props.error ? 127 : 56),
    ...(theme.direction === "rtl" ? { right: 0 } : { left: 0 }),
  },
  mapDiv: {
    width: "100%",
    height: "calc(var(--vh, 1vh) * 100 - 56px - 165px - 56px)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: CameraScreeProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
          : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
    },
  },
  modalRoot: {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
  },
  modalContentDiv: {
    padding: theme.spacing(4, 0),
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
  modalTitle: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  modalContent: {
    padding: theme.spacing(1.5, 3),
  },
  btnGroup: {
    width: "fit-content",
  },
}));

export const CameraScreen = (props: CameraScreeProps & PermissionProps) => {
  const {
    openMenu,
    onRename,
    onDelete,
    onRebootCamera,
    error,
    ...permissions
  } = props;
  const {
    settingsCamPerm,
    renameCamPerm,
    deleteCamPerm,
    fotaCamPerm,
    rebootCamPerm,
  } = permissions;
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ mode?: "map" | "list" }>();

  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const mapRef = useRef<HTMLDivElement>(null);

  const { geofences } = useSelector((state: RootState) => state[GEOFENCE]);
  const { userProfile, email } = useSelector((state: RootState) => state[USER]);
  const { cameraList, firmwares, publicCams, isLocationLoaded, publicCamera } =
    useSelector((state: RootState) => state[CAMERA]);
  const { groupsList } = useSelector((state: RootState) => state[GROUP]);
  const { subscriptionInfo, webSubscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [moreCam, setMoreCam] = useState<ICameraInfo>();
  const [anchorRef, setAnchorRef] = useState<
    React.RefObject<HTMLElement> | undefined
  >();

  const [openPricing, setOpenPricing] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [mode, setMode] = useState<"map" | "list">("map");
  const [openSearch, setOpenSearch] = useState(false);
  const [openPanel, setOpenPanel] = useState(true);
  const [nSlot, setNSlot] = useState(0);
  const [nReal, setNReal] = useState(0);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [openCancelFleetModal, setOpenCancelFleetModal] = useState(false);
  const [map, setMap] = useState<any>();
  const [locations, setLocations] = useState<IGPSLocation[]>([]);
  const [bounds, setBounds] = useState<ILatLngBounds>();
  const [searchKey, setSearchKey] = useState("");
  const [filteredCams, setFilteredCams] = useState<ICameraInfo[]>();
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const [liveCam, setLiveCam] = useState<ICameraInfo>();
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [publicCam, setPublicCam] = useState(false);
  const [step, setStep] = useState(0);
  const [openInvalidModal, setOpenInvalidModal] = useState(false);

  const B2BFleetOrPlus = useMemo(() => {
    return (
      subscriptionInfo &&
      (subscriptionInfo?.servicePlanID === 1001 ||
        subscriptionInfo?.servicePlanID === 1998 ||
        subscriptionInfo?.servicePlanID === 1999 ||
        (subscriptionInfo?.servicePlanID >= 4001 &&
          subscriptionInfo?.servicePlanID <= 4029) ||
        subscriptionInfo?.servicePlanID === 1999 ||
        (subscriptionInfo?.servicePlanID >= 30001 &&
          subscriptionInfo?.servicePlanID <= 30999) ||
        (subscriptionInfo.servicePlanID >= 50001 &&
          subscriptionInfo.servicePlanID <= 50999) ||
        (subscriptionInfo.servicePlanID >= 20001 &&
          subscriptionInfo.servicePlanID <= 20999))
    );
  }, [subscriptionInfo]);

  const [availableNewFws, setAvailableNewFws] = useState<AvailableFws>({});

  useEffect(() => {
    const tourString = localStorage.getItem("pitta-webviewer-tour");
    const tour = JSON.parse(tourString ?? "[]");
    if (!_.includes(tour, email)) {
      let link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding.png";
      link.imageSrcset = "/images/onboarding.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding1.png";
      link.imageSrcset = "/images/onboarding1.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding2.png";
      link.imageSrcset = "/images/onboarding2.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding3.png";
      link.imageSrcset = "/images/onboarding3.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding4.png";
      link.imageSrcset = "/images/onboarding4.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding5.png";
      link.imageSrcset = "/images/onboarding5.png 1x";
      document.head.appendChild(link);
      link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/images/onboarding6.png";
      link.imageSrcset = "/images/onboarding6.png 1x";
      document.head.appendChild(link);
      setStep(0);
    } else {
      setStep(-1);
    }
  }, [email]);

  useEffect(() => {
    return () => {
      setOpenLiveModal(false);
      setLiveCam(undefined);
    };
  }, []);

  useEffect(() => {
    if (publicCamera) {
      setLiveCam(publicCamera);
      setOpenLiveModal(true);
    }
  }, [publicCamera]);

  useEffect(() => {
    setMode(location.state?.mode ?? "map");
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (
      params.get("from") === "sim" &&
      subscriptionInfo?.servicePlanName === "free"
    ) {
      setOpenUpgrade(true);
    }
  }, [location, subscriptionInfo]);

  useEffect(() => {
    if (permissions.geofencePerm) {
      dispatch(loadGeofences());
    }
  }, [dispatch, permissions.geofencePerm]);

  useEffect(() => {
    if (cameraList) {
      setAvailableNewFws(
        _.reduce(
          cameraList.DeviceListInfo,
          (result, dev) => {
            return {
              ...result,
              [dev.device.psn]: findNewFw(dev.device, firmwares),
            };
          },
          {}
        )
      );
    }
  }, [cameraList, firmwares]);

  useEffect(() => {
    setLocations([]);
  }, []);

  useEffect(() => {
    if (mobile) {
      if (mode === "map") {
        const body = document.getElementById("body-container");
        body?.setAttribute("style", "overflow:hidden;");

        return () => {
          body?.setAttribute("style", "overflow:auto;");
        };
      }
    }
  }, [mode, mobile]);

  useEffect(() => {
    dispatch(loadGroups());
    dispatch(clearCamera());
    dispatch(loadWebSubscription());
    return () => {
      dispatch(resetLocationLoaded());
      dispatch(clearPublicCamera());
    };
  }, [dispatch]);

  useEffect(() => {
    if (map && cameraList) {
      const loc = _.chain(cameraList.DeviceListInfo)
        .map((cam) => {
          if (cam.device.latitude && cam.device.longitude) {
            const lat = parseFloat(cam.device.latitude);
            const lng = parseFloat(cam.device.longitude);
            return {
              lat,
              lng,
              heading: parseFloat(cam.device.dir_angle ?? "0"),
              psn: cam.device.psn,
              name: cam.device.dev_name,
              speed: cam.device.speed,
              mode: cam.device.mode,
              model: cam.device.model,
              active: cam.device.active,
            } as IGPSLocation;
          }
          return undefined;
        })
        .compact()
        .value();

      setLocations(loc);
    }
  }, [cameraList, map]);

  useEffect(() => {
    if (!isLocationLoaded) {
      const cancel = new AbortController();
      dispatch(loadMyCemrasLocation({ cancel }));
      return () => {
        // setInitLoadLocations(false);
        cancel.abort();
      };
    } else if (bounds) {
      const cancel = new AbortController();
      dispatch(loadMyCemrasLocation({ bounds, cancel }));
      return () => {
        cancel.abort();
      };
    }
  }, [dispatch, bounds, isLocationLoaded]);

  useEffect(() => {
    if (subscriptionInfo) {
      setNSlot(subscriptionInfo.cameraLimit);
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (cameraList) {
      setNReal(cameraList.deviceCount ?? 0);
    }
  }, [cameraList]);

  const handleScreenTypeChange = useCallback((value: number) => {
    if (value === 0) {
      setMode("map");
    } else if (value === 1) {
      setMode("list");
    }
  }, []);

  const listMarkup = useMemo(() => {
    const cams = _.map(cameraList?.DeviceListInfo, (camera) => {
      const group = _.find(groupsList?.DashCamList, (g) => {
        return !!_.find(g.DashCamUser, (cam) => cam.PSN === camera.device.psn);
      });
      const users = _.find(
        group?.DashCamUser,
        (cam) => cam.PSN === camera.device.psn
      )?.Users;
      const user = _.first(users);

      const ExistName = _.find(users, (user) => {
        if (user.FirstName !== undefined) {
          return true;
        } else {
          return false;
        }
      });

      const driverName =
        user?.Email && user?.Status === "A"
          ? `${user.LastName} ${user.FirstName}${
              users && users.length > 1 ? ` +${users.length - 1}` : ``
            }`
          : user?.Email && ExistName
          ? `${ExistName.LastName} ${ExistName.FirstName}${
              users && users.length > 1 ? ` +${users.length - 1}` : ``
            }`
          : `–`;
      return { ...camera.device, groupName: group?.GroupName, driverName };
    });

    const filteredCams = _.chain(cams)
      .filter(
        (cam) =>
          cam.dev_name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
          cam.model.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
      )
      .map((cam) => {
        const newFw = _.find(availableNewFws, (v, k) => k === cam.psn);
        return { ...cam, newFw: !!(newFw?.blackvue || newFw?.dmc200) };
      })
      .value();
    if (cams.length > 0) {
      if (filteredCams.length > 0) {
        return (
          <div className={classes.tableDiv}>
            <CameraTable
              onClick={(cam) => {
                if (cam.valid === "invalid") {
                  setOpenInvalidModal(true);
                } else {
                  history.push(`/cameras/${cam.psn}/playback`);
                }
              }}
              onClickMore={(cam, anchor) => {
                setAnchorRef(anchor);
                setMoreCam(cam);
                setOpenCamMenu((o) => !o);
              }}
              cameras={filteredCams}
              t={t}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoResults />
          </div>
        );
      }
    }

    return <EmptyItems variant={mobile ? "small" : "default"} />;
  }, [
    cameraList?.DeviceListInfo,
    mobile,
    groupsList?.DashCamList,
    searchKey,
    availableNewFws,
    classes.tableDiv,
    t,
    history,
  ]);

  const handleFOTA = useCallback(
    (cam: ICameraInfo, fw?: AvailableFw) => {
      if (fw) {
        if (cam && cam.active === "on") {
          if (fw.dmc200) {
            history.push(`/cameras/${cam.psn}/fota-dmc200`);
          } else if (fw.blackvue) {
            history.push(`/cameras/${cam.psn}/fota`);
          }
        }
      } else {
        dispatch(openToast({ message: "Your firmware is_" }));
      }
    },
    [dispatch, history]
  );

  const cameraPanelMarkup = useMemo(() => {
    return (
      <div className={classes.listPane}>
        <CameraListPanel
          {...permissions}
          availableNewFws={availableNewFws}
          open={openPanel}
          onUpdateFilteredCams={setFilteredCams}
          onRename={onRename}
          onDelete={onDelete}
          onRebootCamera={onRebootCamera}
          onClick={(cam) =>
            setCurrentCam((c) => (c?.psn === cam?.psn ? c : cam))
          }
          onUpdate={handleFOTA}
        />
        <Fab
          size="small"
          variant="rounded"
          className={clsx({ [classes.fabMargin]: openPanel })}
          onClick={() => setOpenPanel((o) => !o)}
        >
          {(theme.direction === "rtl") !== openPanel ? (
            <ArrowLeftIcon />
          ) : (
            <ArrowRightIcon />
          )}
        </Fab>
      </div>
    );
  }, [
    classes.listPane,
    classes.fabMargin,
    permissions,
    availableNewFws,
    openPanel,
    onRename,
    onDelete,
    onRebootCamera,
    handleFOTA,
    theme.direction,
  ]);

  const mobileCameraListPanelMarkup = useMemo(() => {
    return (
      <MobileDrawer initMode="camera" mode="camera">
        <CameraListPanel
          {...permissions}
          availableNewFws={availableNewFws}
          open
          mobile
          onUpdateFilteredCams={setFilteredCams}
          onRename={onRename}
          onDelete={onDelete}
          onRebootCamera={onRebootCamera}
          onClick={(cam) => {
            setCurrentCam((c) => (c?.psn === cam?.psn ? c : cam));
            if (cam) {
              const openEvent = new CustomEvent("drawer:open");
              document.dispatchEvent(openEvent);
            }
          }}
          onUpdate={handleFOTA}
        />
      </MobileDrawer>
    );
  }, [
    availableNewFws,
    handleFOTA,
    onDelete,
    onRebootCamera,
    onRename,
    permissions,
  ]);

  const handleGoogleApiLoaded = useCallback(({ map }: any) => {
    setMap(map);
  }, []);

  const manageBtnMarkup = useMemo(() => {
    if (userProfile?.userType === "Master") {
      const needToCancel =
        subscriptionInfo &&
        subscriptionInfo.servicePlanID >= 4001 &&
        subscriptionInfo.servicePlanID <= 4029;
      return (
        <div className={classes.camCntText}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            {t("n cameras", { n: nReal })} / {t("n slots", { n: nSlot })}
          </Typography>
          {B2BFleetOrPlus &&
            webSubscriptionInfo &&
            !webSubscriptionInfo.upcomming.canceled && (
              <>
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={LightColors.primary["2"]}
                  className={classes.dotText}
                >
                  ·
                </Typography>

                <Typography
                  category="Default"
                  variant="Body"
                  onClick={() => {
                    if (needToCancel) {
                      setOpenCancelFleetModal(true);
                    } else {
                      setOpenManageModal(true);
                    }
                  }}
                  color="primary"
                  style={{ cursor: "pointer" }}
                  htmlColor={LightColors.primary["7"]}
                >
                  {t("Manage")}
                </Typography>
              </>
            )}
        </div>
      );
    }
  }, [
    B2BFleetOrPlus,
    classes.camCntText,
    classes.dotText,
    nReal,
    nSlot,
    subscriptionInfo,
    t,
    userProfile?.userType,
    webSubscriptionInfo,
  ]);

  const handleCloseTour = useCallback(() => {
    setStep(-1);

    const tourString = localStorage.getItem("pitta-webviewer-tour");
    const tour = JSON.parse(tourString ?? "[]");
    localStorage.setItem(
      "pitta-webviewer-tour",
      JSON.stringify([...tour, email])
    );
  }, [email]);

  const handleNext = useCallback(() => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
      setStep(6);
    } else if (step === 6) {
      handleCloseTour();
    }
  }, [handleCloseTour, step]);

  const handleBack = useCallback(() => {
    if (step === 1) {
      setStep(0);
    } else if (step === 2) {
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    } else if (step === 4) {
      setStep(3);
    } else if (step === 5) {
      setStep(4);
    } else if (step === 6) {
      setStep(5);
    }
  }, [step]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <div
        className={clsx(classes.subHeaderDiv, {
          [classes.subHeaderMobile]: mobile,
          [classes.subHeaderDivOpen]: openMenu,
        })}
      >
        <div className={classes.subHeader}>
          <div
            className={clsx(classes.btnDiv, { [classes.alignStart]: mobile })}
          >
            <Button
              startIcon={<AddIcon />}
              color="primary"
              fullWidth={mobile}
              disabled={
                _.includes(["User", "SubMaster"], userProfile?.userType) ||
                nSlot <= nReal
              }
              onClick={() => {
                history.push("/cameras/add-camera-sim", { mode });
              }}
            >
              {t("Add camera")}
            </Button>
            {manageBtnMarkup}
          </div>
          <div
            className={clsx(classes.btnDiv, {
              [classes.directionRow]: mobile,
              [classes.justifyBetween]: mobile && mode === "list",
            })}
          >
            {mode === "list" && (
              <Input
                placeholder={t("search")}
                startIcon={<SearchIcon />}
                className={clsx(classes.searchBtn, {
                  [classes.searchBtnOpen]: mobile && openSearch,
                })}
                search
                dense
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                onOpenSearch={() => setOpenSearch(true)}
                onCloseSearch={() => setOpenSearch(false)}
                inputID="cameraSearch"
              />
            )}
            <ButtonGroup
              value={mode === "map" ? 0 : 1}
              onChange={handleScreenTypeChange}
              className={clsx(classes.btnGroup, {
                [classes.hide]: mobile && openSearch,
              })}
            >
              <Button startIcon={<MapIcon />}>{t("Map")}</Button>
              <Button startIcon={<ListIcon />}>{t("List")}</Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div className={classes.body}>
        {mode === "list" && listMarkup}
        {mode === "map" && (
          <div className={classes.mapDiv}>
            <LocationMap
              publicIcon
              fullscreenIcon
              disableAutoZoom
              mapRef={mapRef}
              geofences={geofences}
              center={
                currentCam &&
                currentCam.active === "on" &&
                currentCam.latitude &&
                currentCam.longitude
                  ? {
                      lat: parseFloat(currentCam.latitude),
                      lng: parseFloat(currentCam.longitude),
                      // zoom: 15,
                    }
                  : undefined
              }
              filteredCams={filteredCams}
              locations={locations}
              publicCams={publicCams}
              onGoogleApiLoaded={handleGoogleApiLoaded}
              onUpdateBounds={setBounds}
              onLiveView={(psn, isPublic) => {
                console.log("onLiveView", psn, isPublic);
                setPublicCam(isPublic);
                if (isPublic) {
                  dispatch(loadPublicCamera(psn));
                } else {
                  const cam = _.find(
                    cameraList?.DeviceListInfo,
                    (dev) => dev.device.psn === psn
                  )?.device;
                  if (cam) {
                    setLiveCam(cam);
                    setOpenLiveModal(true);
                  }
                }
              }}
            />
          </div>
        )}
        {mode === "map" && !mobile && cameraPanelMarkup}
        {mode === "map" && mobile && mobileCameraListPanelMarkup}
      </div>

      {openManageModal && (
        <SubMgrModal
          open={openManageModal}
          onClose={() => setOpenManageModal(false)}
          onClickNegative={() => setOpenManageModal(false)}
          onClickPositive={() => {
            setOpenManageModal(false);
          }}
        />
      )}
      <Modal
        open={openCancelFleetModal}
        close
        onClose={() => setOpenCancelFleetModal(false)}
        heading={t("Cancel your inapp_")}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: t("You have cancel_").replace(
                // eslint-disable-next-line no-control-regex
                new RegExp("\n", "g"),
                "<br/>"
              ),
            }}
          />
        }
        RButton={t("OK")}
        actionClassName={classes.modalBottom}
        className={classes.modalWrap}
      />

      {!mobile && (
        <CameraMenu
          open={openCamMenu}
          camera={moreCam}
          anchorRef={anchorRef}
          placement="bottom-end"
          onClickAway={() => setOpenCamMenu(false)}
          onRename={
            renameCamPerm
              ? () => {
                  moreCam && onRename?.(moreCam);
                }
              : null
          }
          onSettings={
            settingsCamPerm
              ? () => {
                  moreCam && history.push(`/cameras/${moreCam.psn}/settings`);
                }
              : null
          }
          onUpdate={
            fotaCamPerm && !_.includes(PSN650, moreCam?.psn.substr(0, 4))
              ? () => {
                  if (moreCam && moreCam.active === "on") {
                    const isNew = !!_.find(
                      availableNewFws,
                      (v, k) => k === moreCam.psn
                    );
                    if (isNew) {
                      history.push(`/cameras/${moreCam.psn}/fota`);
                    } else {
                      dispatch(openToast({ message: "Your firmware is_" }));
                      setOpenCamMenu(false);
                    }
                  }
                }
              : null
          }
          onRebootCamera={
            rebootCamPerm
              ? () => {
                  moreCam && onRebootCamera?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
          onDelete={
            deleteCamPerm
              ? () => {
                  moreCam && onDelete?.(moreCam);
                }
              : null
          }
        />
      )}
      {mobile && (
        <MobileCameraMenu
          open={openCamMenu}
          onClose={() => setOpenCamMenu(false)}
          onRename={
            renameCamPerm
              ? () => {
                  moreCam && onRename?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
          onSettings={
            settingsCamPerm
              ? () => {
                  moreCam && history.push(`/cameras/${moreCam.psn}/settings`);
                }
              : null
          }
          onUpdate={
            fotaCamPerm && !_.includes(PSN650, moreCam?.psn.substr(0, 4))
              ? () => {
                  if (moreCam && moreCam.active === "on") {
                    const isNew = !!_.find(
                      availableNewFws,
                      (v, k) => k === moreCam.psn
                    );
                    if (isNew) {
                      history.push(`/cameras/${moreCam.psn}/fota`);
                    } else {
                      dispatch(openToast({ message: "Your firmware is_" }));
                      setOpenCamMenu(false);
                    }
                  }
                }
              : null
          }
          onRebootCamera={
            rebootCamPerm
              ? () => {
                  moreCam && onRebootCamera?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
          onDelete={
            deleteCamPerm
              ? () => {
                  moreCam && onDelete?.(moreCam);
                  setOpenCamMenu(false);
                }
              : null
          }
        />
      )}
      <LiveViewModal
        open={openLiveModal}
        camera={liveCam}
        isPublic={publicCam}
        onClose={() => {
          setOpenLiveModal(false);
          setLiveCam(undefined);
          dispatch(clearPublicCamera());
        }}
        container={mapRef.current}
      />

      {step === 0 && !mobile && (
        <Onboarding
          open={step === 0}
          onStartTour={handleNext}
          onClose={handleCloseTour}
        />
      )}

      {step === 1 && !mobile && (
        <OnboardingStep1
          open={step === 1}
          onNext={handleNext}
          onClose={handleCloseTour}
        />
      )}
      {step === 2 && !mobile && (
        <OnboardingStep2
          open={step === 2}
          onNext={handleNext}
          onClose={handleCloseTour}
        />
      )}
      {step === 3 && !mobile && (
        <OnboardingStep3
          open={step === 3}
          onNext={handleNext}
          onClose={handleCloseTour}
        />
      )}
      {step === 4 && !mobile && (
        <OnboardingStep4
          open={step === 4}
          onNext={handleNext}
          onClose={handleCloseTour}
        />
      )}
      {step === 5 && !mobile && (
        <OnboardingStep5
          open={step === 5}
          onNext={handleNext}
          onClose={handleCloseTour}
        />
      )}
      {step === 6 && !mobile && (
        <OnboardingStep6 open={step === 6} onClose={handleCloseTour} />
      )}
      {mobile && step !== -1 && (
        <OnboardingMobile
          step={step}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleCloseTour}
        />
      )}
      <Modal
        open={openInvalidModal}
        onClickPositive={() => {
          setOpenInvalidModal(false);
        }}
        heading={t("To access the_")}
        content={<></>}
        RButton={t("OK")}
        titleClassName={classes.modalTitle}
        contentClassName={classes.modalContent}
        className={classes.modalWrap}
      />
      <Modal
        open={openUpgrade}
        close
        mobile={mobile}
        heading={t("Upgrade for more_")}
        content={t("Upgrading the cloud_")}
        RButton={t("Explore Cloud plans")}
        LButton={t("Later")}
        onClickNegative={() => setOpenUpgrade(false)}
        onClickPositive={() => {
          setOpenUpgrade(false);
          setOpenPricing(true);
        }}
        onClose={() => setOpenUpgrade(false)}
      />
      <PricingModal open={openPricing} onClose={() => setOpenPricing(false)} />
    </div>
  );
};
