import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormControlLabel,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Button,
  CheckBox,
  ICameraInfo,
  LightColors,
  Modal,
  Sliders,
  Typography,
} from "@thingsw/pitta-design-system";
import { Webviewer } from "../../contants/Breakpoints";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  CAMERA,
  loadFirmwareSettings,
  TabNameInfo3,
  TrueFalsetype,
  updateFirmwareSettings,
} from "../../features/Camera/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import _ from "lodash";
import { Selector } from "@thingsw/pitta-design-system/dist/components/Selector";
import ScheduledRebootTime from "../../contants/ScheduledRebootTime";
import LowvoltageTime from "../../contants/LowvoltageTime";
import LowvoltageVolt, {
  LowvoltageVolt_GPRO,
} from "../../contants/LowvoltageVolt";
import clsx from "clsx";
import ADASLDWS from "../../contants/ADASLDWS";
import { ISystemConfig } from "../../contants/Firmwares";
import { DMSPanel } from "./DMSPanel";

const useStyles = makeStyles((theme: Theme) => ({
  bigBox: {
    width: "100%",
    minWidth: 288,
    border: `1px solid ${LightColors.primary["6"]}`,
    // minHeight: 1353,
    borderRadius: 4,
    marginBottom: 32,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "100%",
      maxWidth: 672,
      // minHeight: 1171,
    },
  },
  boderBottomBox: {
    width: "100%",
    minWidth: 288,
    minHeight: 48,
    display: "flex",
    ...(theme.direction === "rtl"
      ? { paddingLeft: 16, paddingRight: 10 }
      : { paddingLeft: 10, paddingRight: 16 }),

    justifyContent: "center",
    flexDirection: "column",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 672,
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  boderNoneBox: {
    width: "100%",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { padding: "0 10px 0 16px" }
      : { padding: "0 16px 0 10px" }),
    justifyContent: "space-between",
    "&:last-child": {
      borderBottom: `1px solid ${LightColors.primary["6"]}`,
    },
  },
  boderBottomBoxSpace: {
    width: "100%",
    minWidth: 288,
    minHeight: 82,
    display: "flex",
    padding: "0 16px",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 48,
      maxWidth: 672,
    },
  },
  BatteryBox: {
    width: "100%",
    minWidth: 288,
    minHeight: 82,
    display: "flex",
    padding: "0 16px 0 42px",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 48,
      maxWidth: 672,
    },
  },
  boxSpaceNoneCenter: {
    width: "100%",
    maxWidth: 672,
    minHeight: 48,
    display: "flex",
    padding: "0 16px",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "space-between",
      flexDirection: "row",
    },
  },
  mgNone: {
    margin: 0,
  },

  buttonSize: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
    },
  },
  wid224: {
    minWidth: 250,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 280,
      ...(theme.direction === "rtl"
        ? { paddingRight: 22 }
        : { paddingLeft: 22 }),
    },
  },
  inputStyle: {
    minwidth: 256,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 342,
      marginTop: 6,
    },
  },
  inputValueStyle: {
    width: "94%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 12,
    marginTop: 3,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 6,
    },
  },
  SflexEnd: {
    display: "flex",
    padding: "12px 0",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  Width270: {
    minWidth: 260,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 270,
    },
  },
  Swid100: {
    maxWidth: 100,
    padding: "12px 0",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  Swid98: {
    maxWidth: 98,
    padding: "12px 0",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  Swid116: {
    maxWidth: 116,
    padding: "12px 0",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  Swid192: {
    maxWidth: 192,
    padding: "12px 0",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  SpdT12: {
    paddingTop: 12,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 0,
    },
  },
  flex: {
    display: "flex",
  },
  flex1: {
    display: "flex",
    flex: 1,
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 6,
  },
  mgR8: {
    ...(theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }),
    maxWidth: 115,
  },
  title: {
    ...(theme.direction === "rtl" ? { paddingRight: 16 } : { paddingLeft: 16 }),
  },
  sliderText: {
    ...(theme.direction === "rtl" ? { marginRight: 25 } : { marginLeft: 25 }),
  },
}));

export const SystemFirmwarePanel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const {
    camera: cam,
    firmware,
    firmwareConfig,
    cameraList,
  } = useSelector((state: RootState) => state[CAMERA]);
  const [current, setCurrent] = useState<TabNameInfo3>();

  const [batteryPRevChanged, setBatteryPRevChanged] = useState(false);
  const [batteryHRevChanged, setBatteryHRevChanged] = useState(false);
  const [cutoffTimerRevChanged, setCutoffTimerRevChanged] = useState(false);
  const [selectedField, setSelectedField] = useState<keyof TabNameInfo3>();
  const [selectedValue, setSelectedValue] = useState<any>();
  const [openBatteryModal, setOpenBatteryModal] = useState(false);
  const [disabledChange, setDisabledChange] = useState(true);
  const [camera, setCamera] = useState<ICameraInfo>();
  const settings = useMemo(() => {
    return firmwareConfig?.System;
  }, [firmwareConfig?.System]);

  const PSENSORMODEL = useMemo(
    () =>
      _.includes(
        ["DR900S-1CH", "DR900S-2CH", "DR750S-1CH", "DR750S-2CH"],
        camera?.model
      ),
    [camera?.model]
  );

  const DR750GPRO = useMemo(
    () =>
      _.includes(
        ["DR750G Pro", "DR750G-1CH Pro", "DR750G-2CH Pro"],
        camera?.model
      ),
    [camera?.model]
  );

  const FILE_LOCKED = useMemo(
    () => _.includes(["DR750G Pro", "BlackVue 7G Pro"], camera?.model),
    [camera?.model]
  );

  const SigmaStar_MODEL = useMemo(
    () =>
      _.includes(
        ["BlackVue 7", "BlackVue 7G Pro", "BlackVue 9"],
        camera?.model
      ),
    [camera?.model]
  );

  const openBatteryLowVolt = useMemo(() => {
    if (DR750GPRO && !batteryPRevChanged) {
      return false;
    } else {
      return undefined;
    }
  }, [DR750GPRO, batteryPRevChanged]);

  const openCutoff = useMemo(() => {
    if (DR750GPRO && !cutoffTimerRevChanged) {
      return false;
    } else {
      return undefined;
    }
  }, [DR750GPRO, cutoffTimerRevChanged]);

  const DR7500GPRO_BT_ORDER = useMemo(() => {
    const fwVer = parseFloat(camera?.fw_ver ?? "1.000");
    return fwVer >= 1.005 && camera?.model === "DR750G Pro";
  }, [camera?.fw_ver, camera?.model]);

  const PSENSOROptions = useMemo(() => {
    if (PSENSORMODEL) {
      return [
        {
          key: "0",
          value: "Voice recording on/off",
        },
        {
          key: "1",
          value: "Manual recording",
        },
      ];
    } else if (DR750GPRO) {
      return [
        {
          key: "1",
          value: "Manual recording (File locked)",
        },
        {
          key: "2",
          value: "Voice recording on/off",
        },
      ];
    } else {
      return [
        {
          key: "1",
          value: "Manual recording",
        },
        {
          key: "2",
          value: "Voice recording on/off",
        },
      ];
    }
  }, [PSENSORMODEL, DR750GPRO]);

  const TSENSOROptions = useMemo(() => {
    if (FILE_LOCKED) {
      return [
        {
          key: "1",
          value: "Manual recording (File locked)",
        },
      ];
    } else {
      return [
        {
          key: "1",
          value: "Manual recording",
        },
      ];
    }
  }, [FILE_LOCKED]);

  useEffect(() => {
    const dev = _.find(
      cameraList?.DeviceListInfo,
      (dev) => dev.device.psn === cam?.psn
    )?.device;
    setCamera((c) => {
      if (c?.psn === dev?.psn) {
        return c;
      } else {
        return dev;
      }
    });
  }, [cam?.psn, cameraList]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings());
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );

      if (currentTab) {
        const section = _.cloneDeep(currentTab.section_info) as TabNameInfo3;
        if (settings?.BatteryProtectionRev) {
          const battRev = settings.BatteryProtectionRev;
          switch (section.LowvoltageTime) {
            case "1":
              section.LowvoltageTime = "600";
              break;
            case "2":
              section.LowvoltageTime = "1200";
              break;
            case "3":
              section.LowvoltageTime = "2400";
              break;
            case "4":
              section.LowvoltageTime = "4800";
              break;
          }

          if (section.LowvoltageVolt !== "0") {
            if (_.includes(["1", "2", "3"], section.LowvoltageVolt)) {
              const volt = section.LowvoltageVolt;
              section.LowvoltageVolt = battRev.LowvoltageVolt[volt];
              section.LowvoltageVoltHeavy = battRev.LowvoltageVoltHeavy[volt];
            }

            let lowVal = parseInt(section.LowvoltageVolt) / 100;
            if (lowVal > battRev.maxVolt || lowVal < battRev.minVolt) {
              lowVal = battRev.defaultVolt;
            }
            section.LowvoltageVolt = (lowVal * 100).toString();
          }

          if (section.LowvoltageVoltHeavy) {
            let lowHeavyVal = parseInt(section.LowvoltageVoltHeavy) / 100;

            if (
              lowHeavyVal > battRev.maxVoltHeavy ||
              lowHeavyVal < battRev.minVoltHeavy
            ) {
              lowHeavyVal = battRev.defaultVoltHeavy;
            }
            section.LowvoltageVoltHeavy = (lowHeavyVal * 100).toString();
          }
        }
        setCurrent(section);
      }
    }
  }, [firmware, settings?.BatteryProtectionRev]);

  const getBoolean = useCallback((TrueFalse?: TrueFalsetype) => {
    return TrueFalse === "1" ? true : false;
  }, []);

  const handleChange = useCallback(
    (key: keyof TabNameInfo3, value: boolean | string) => {
      if (typeof value === "boolean") {
        setCurrent((c) => ({ ...c, [key]: value ? "1" : "0" } as TabNameInfo3));
      } else if (typeof value === "string") {
        setCurrent((c) => ({ ...c, [key]: value } as TabNameInfo3));
      }
    },
    []
  );

  const handleChangePSENSOR = (key: keyof TabNameInfo3, checked: boolean) => {
    setCurrent(
      (c) => ({ ...c, [key]: checked ? "2" || "1" : "0" } as TabNameInfo3)
    );
  };

  const handleChangeTSENSOR = (key: keyof TabNameInfo3, checked: boolean) => {
    setCurrent((c) => ({ ...c, [key]: checked ? "1" : "0" } as TabNameInfo3));
  };

  //900s / 750s 모델만 다른 모델과 상이한 키를 갖고 있음
  const handleChangePSENSOR_MODEL = (
    key: keyof TabNameInfo3,
    checked: boolean
  ) => {
    setCurrent(
      (c) => ({ ...c, [key]: checked ? "0" || "1" : "2" } as TabNameInfo3)
    );
  };

  const handleChangeLowVolt = useCallback(
    (key: keyof TabNameInfo3, checked: boolean) => {
      setCurrent((c) => ({ ...c, [key]: checked ? "2" : "0" } as TabNameInfo3));
    },
    []
  );

  const handleChangeCutoffTimerRev = useCallback(
    (key: keyof TabNameInfo3, checked: boolean) => {
      if (DR750GPRO) {
        setOpenBatteryModal(true);
      }
      const val = settings?.BatteryProtectionRev?.defaultTime ?? "1200";
      setCurrent(
        (c) =>
          ({
            ...c,
            [key]: checked ? val : "0",
          } as TabNameInfo3)
      );
    },
    [DR750GPRO, settings?.BatteryProtectionRev?.defaultTime]
  );

  const handleChangeLowVoltRev = useCallback(
    (key: keyof TabNameInfo3, checked: boolean) => {
      if (DR750GPRO) {
        setOpenBatteryModal(true);
      }
      const dVolt = (
        (settings?.BatteryProtectionRev?.defaultVolt ?? 12) * 100
      ).toString();
      setCurrent(
        (c) =>
          ({
            ...c,
            [key]: checked ? dVolt : "0",
          } as TabNameInfo3)
      );
    },
    [DR750GPRO, settings]
  );

  const handleChangeSpeed = (key: keyof TabNameInfo3, checked: boolean) => {
    setCurrent(
      (c) => ({ ...c, [key]: checked ? "0" || "1" : "2" } as TabNameInfo3)
    );
  };

  useEffect(() => {
    if (firmware) {
      const currentTab = _.find(
        firmware.cloud_settings,
        (c) => c.section_name === "Tab3"
      );
      const predicts = _.keys(currentTab?.section_info as TabNameInfo3).map(
        (k) => {
          const key = k as keyof TabNameInfo3;
          return (
            (currentTab?.section_info as TabNameInfo3)[key] === current?.[key]
          );
        }
      );
      setDisabledChange(
        _.every(predicts) ||
          current?.kmLimit === "" ||
          current?.mileLimit === ""
      );
    }
  }, [firmware, current]);

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (current) {
        const { value } = e.target;
        const regex = new RegExp(/((?![a-zA-Z0-9:;'/\\+-_()$#]).)/gm);
        // eslint-disable-next-line no-useless-escape
        const regex2 = /[\[\]\=?^,.<>@]/gi;

        const test = regex.test(value);
        const test2 = regex2.test(value);
        if (value.length <= 20) {
          if (!test && !test2) {
            setCurrent((c) => {
              return c && { ...c, userString: value };
            });
          }
        }
      }
    },
    [current]
  );

  const handleSpeed = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (current) {
        const { value } = e.target;
        const regex = new RegExp(/((?![0-9]).)/gm);

        const test = regex.test(value);
        if (!test) {
          if (
            current?.SpeedAlert === "0" &&
            ((parseInt(e.target.value) <= 299 &&
              parseInt(e.target.value) >= 0) ||
              e.target.value === "")
          ) {
            setCurrent((c) => {
              return (
                c && {
                  ...c,
                  kmLimit: value,
                }
              );
            });
          } else if (
            current?.SpeedAlert === "1" &&
            ((parseInt(e.target.value) <= 199 &&
              parseInt(e.target.value) >= 0) ||
              e.target.value === "")
          ) {
            setCurrent((c) => {
              return (
                c && {
                  ...c,
                  mileLimit: value,
                }
              );
            });
          }
        }
      }
    },
    [current]
  );

  const handleUpdate = () => {
    if (current && camera && firmware) {
      const updated = {
        ...firmware,
        cloud_settings: _.filter(
          firmware.cloud_settings,
          (c) => c.section_name !== "Tab3"
        ),
      };
      updated.cloud_settings = [
        { section_info: current, section_name: "Tab3" },
        ...updated.cloud_settings,
      ];
      dispatch(updateFirmwareSettings({ firmware: updated }));
    }
  };

  /* REV 부터 적용되는 신규 Battery protection UI */
  const batteryRevMarkup = useMemo(() => {
    if (settings?.["BatteryProtectionRev"]) {
      console.log("LowvoltageVolt", current?.LowvoltageVolt);
      const batteryProtectionRev = settings.BatteryProtectionRev;
      const volt =
        current?.LowvoltageVolt !== undefined && current?.LowvoltageVolt !== "0"
          ? _.round(parseFloat(current.LowvoltageVolt) / 100, 1)
          : batteryProtectionRev.defaultVolt;

      const voltHeavy =
        current?.LowvoltageVoltHeavy !== undefined &&
        current?.LowvoltageVoltHeavy !== "0"
          ? _.round(parseFloat(current.LowvoltageVoltHeavy) / 100, 1)
          : batteryProtectionRev.defaultVoltHeavy;
      let voltTime =
        current?.LowvoltageTime !== undefined && current?.LowvoltageTime !== "0"
          ? current.LowvoltageTime
          : batteryProtectionRev.defaultTime;
      const len = voltTime.length;
      voltTime = voltTime.substring(0, len - 2) + ":00";

      return (
        <>
          <div className={classes.boderNoneBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={current?.Battery !== "0"}
                  onChange={(e) => {
                    if (DR750GPRO) {
                      setOpenBatteryModal(true);
                      setSelectedField("Battery");
                      setSelectedValue(e.target.checked);
                    } else {
                      handleChange("Battery", e.target.checked);
                    }
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="BodyBold">
                  {t("Battery protection")}
                </Typography>
              }
            />
          </div>
          <div className={classes.boderBottomBox} style={{ border: "none" }}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  disabled={current?.Battery !== "1"}
                  checked={current?.LowvoltageVolt !== "0"}
                  onChange={(e) => {
                    if (DR750GPRO) {
                      setOpenBatteryModal(true);
                      setSelectedField("LowvoltageVolt");
                      setSelectedValue(e.target.checked);
                    } else {
                      handleChangeLowVoltRev(
                        "LowvoltageVolt",
                        e.target.checked
                      );
                    }
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Low voltage cut-off")}
                </Typography>
              }
            />
          </div>
          <div className={classes.BatteryBox}>
            <div>
              <Typography category="Default" variant="Body">
                {t("Passenger vehicle")}
              </Typography>
            </div>
            <div className={classes.flexEnd}>
              <div className={classes.Swid116} style={{ maxWidth: 187 }}>
                <Input
                  dense
                  countDense
                  readOnly
                  disabled={
                    current?.LowvoltageVolt === "0" || current?.Battery !== "1"
                  }
                  PlusButtonDis={
                    current?.LowvoltageVolt === "0" ||
                    current?.Battery !== "1" ||
                    volt.toFixed(1) === batteryProtectionRev.maxVolt.toFixed(1)
                  }
                  MinusButtonDis={
                    current?.LowvoltageVolt === "0" ||
                    current?.Battery !== "1" ||
                    volt.toFixed(1) === batteryProtectionRev.minVolt.toFixed(1)
                  }
                  defaultText={"/12.5 V"}
                  onPlus={() => {
                    if (current?.LowvoltageVolt) {
                      if (DR750GPRO && !batteryPRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageVolt");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageVolt = (
                          _.round(volt + 0.1, 1) * 100
                        ).toString();
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  onMinus={() => {
                    if (current?.LowvoltageVolt) {
                      if (DR750GPRO && !batteryPRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageVolt");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageVolt = (
                          _.round(volt - 0.1, 1) * 100
                        ).toString();
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (current?.LowvoltageVolt !== undefined) {
                      if (DR750GPRO && !batteryPRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageVolt");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageVolt = e.target.value;
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  value={volt.toFixed(1)}
                />
              </div>
            </div>
          </div>
          <div className={classes.BatteryBox}>
            <div>
              <Typography category="Default" variant="Body">
                {t("Heavy vehicle")}
              </Typography>
            </div>
            <div className={classes.flexEnd}>
              <div className={classes.Swid116} style={{ maxWidth: 174 }}>
                <Input
                  dense
                  countDense
                  readOnly
                  disabled={
                    current?.LowvoltageVolt === "0" || current?.Battery !== "1"
                  }
                  PlusButtonDis={
                    current?.LowvoltageVolt === "0" ||
                    current?.Battery !== "1" ||
                    voltHeavy.toFixed(1) ===
                      batteryProtectionRev.maxVoltHeavy.toFixed(1)
                  }
                  MinusButtonDis={
                    current?.LowvoltageVolt === "0" ||
                    current?.Battery !== "1" ||
                    voltHeavy.toFixed(1) ===
                      batteryProtectionRev.minVoltHeavy.toFixed(1)
                  }
                  onPlus={() => {
                    if (current?.LowvoltageVoltHeavy) {
                      if (DR750GPRO && !batteryHRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageVoltHeavy");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageVoltHeavy = (
                          _.round(voltHeavy + 0.1, 1) * 100
                        ).toString();
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  onMinus={() => {
                    if (current?.LowvoltageVoltHeavy) {
                      if (DR750GPRO && !batteryHRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageVoltHeavy");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageVoltHeavy = (
                          _.round(voltHeavy - 0.1, 1) * 100
                        ).toString();
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (current?.LowvoltageVoltHeavy !== undefined) {
                      if (DR750GPRO && !batteryHRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageVoltHeavy");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageVoltHeavy = e.target.value;
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  defaultText={"/24 V"}
                  value={voltHeavy.toFixed(1)}
                />
              </div>
            </div>
          </div>
          <div className={classes.boderBottomBox}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  disabled={current?.Battery !== "1"}
                  checked={current?.LowvoltageTime !== "0"}
                  onChange={(e) => {
                    if (DR750GPRO) {
                      setOpenBatteryModal(true);
                      setSelectedField("LowvoltageTime");
                      setSelectedValue(e.target.checked);
                    } else {
                      handleChangeCutoffTimerRev(
                        "LowvoltageTime",
                        e.target.checked
                      );
                    }
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t("Cut-off timer")}
                </Typography>
              }
            />
            <div className={classes.flexEnd}>
              <div className={classes.Swid98} style={{ maxWidth: 204 }}>
                <Input
                  readOnly
                  disabled={
                    current?.LowvoltageTime === "0" || current?.Battery !== "1"
                  }
                  PlusButtonDis={
                    current?.LowvoltageTime === "0" ||
                    current?.Battery !== "1" ||
                    current?.LowvoltageTime === batteryProtectionRev.maxTime
                  }
                  MinusButtonDis={
                    current?.LowvoltageTime === "0" ||
                    current?.Battery !== "1" ||
                    current?.LowvoltageTime === batteryProtectionRev.minTime
                  }
                  dense
                  countDense
                  onPlus={() => {
                    const val = voltTime.replace(":", "");
                    if (current?.LowvoltageTime) {
                      if (DR750GPRO && !cutoffTimerRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageTime");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageTime = (
                          parseInt(val) + 100
                        ).toString();
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  onMinus={() => {
                    const val = voltTime.replace(":", "");
                    if (current?.LowvoltageTime) {
                      if (DR750GPRO && !cutoffTimerRevChanged) {
                        setOpenBatteryModal(true);
                        setSelectedField("LowvoltageTime");
                        setSelectedValue(undefined);
                      } else {
                        current.LowvoltageTime = (
                          parseInt(val) - 100
                        ).toString();
                        setCurrent({ ...current });
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (current?.LowvoltageTime !== undefined) {
                      setCutoffTimerRevChanged(true);
                      current.LowvoltageTime = e.target.value;
                      setCurrent({ ...current });
                    }
                  }}
                  defaultText={"/48:00 H"}
                  value={voltTime}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  }, [
    DR750GPRO,
    batteryHRevChanged,
    batteryPRevChanged,
    classes.BatteryBox,
    classes.Swid116,
    classes.Swid98,
    classes.boderBottomBox,
    classes.boderNoneBox,
    classes.flexEnd,
    classes.mgNone,
    current,
    cutoffTimerRevChanged,
    handleChange,
    handleChangeCutoffTimerRev,
    handleChangeLowVoltRev,
    settings,
    t,
  ]);

  const eventRevMarkup = useMemo(() => {
    if (settings?.["EventRev"]) {
      return (
        <>
          <div
            className={clsx(classes.boderBottomBox, classes.SpdT12)}
            style={{ paddingTop: 6, paddingBottom: 6 }}
          >
            <div>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={current?.AccelEvent === "1"}
                    onChange={(e) => {
                      handleChange("AccelEvent", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Acceleration")}
                  </Typography>
                }
              />

              <div style={{ marginLeft: 32 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Hard acceleration event_")}
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={clsx(classes.boderBottomBox, classes.SpdT12)}
            style={{ paddingTop: 6, paddingBottom: 6 }}
          >
            <div>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={current?.HarshEvent === "1"}
                    onChange={(e) => {
                      handleChange("HarshEvent", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Hard braking")}
                  </Typography>
                }
              />

              <div style={{ marginLeft: 32 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Hard braking event_")}
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={clsx(classes.boderBottomBox, classes.SpdT12)}
            style={{ paddingTop: 6, paddingBottom: 6 }}
          >
            <div>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={current?.SharpEvent === "1"}
                    onChange={(e) => {
                      handleChange("SharpEvent", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {/* osd 키값 참고 */}
                    {t("Sharp turn")}
                  </Typography>
                }
              />

              <div style={{ marginLeft: 32 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Hard cornering event_")}
                </Typography>
              </div>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  }, [
    classes.SpdT12,
    classes.boderBottomBox,
    classes.mgNone,
    current?.AccelEvent,
    current?.HarshEvent,
    current?.SharpEvent,
    handleChange,
    settings,
    t,
  ]);

  const renderSimpleCheckBox = useCallback(
    (
      label: string,
      key1: keyof ISystemConfig,
      key: keyof TabNameInfo3,
      options?: { className?: string; checkFn?: () => boolean }
    ) => {
      const set = settings as any;
      if (set[key1][key]) {
        return (
          <div className={clsx(classes.boderNoneBox, options?.className)}>
            <FormControlLabel
              className={classes.mgNone}
              control={
                <CheckBox
                  checked={getBoolean(current?.[key] as TrueFalsetype)}
                  onChange={(e) => {
                    handleChange(key, e.target.checked);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography category="Default" variant="Body">
                  {t(label)}
                </Typography>
              }
            />
          </div>
        );
      }
      return undefined;
    },
    [
      settings,
      classes.boderNoneBox,
      classes.mgNone,
      getBoolean,
      current,
      t,
      handleChange,
    ]
  );
  return (
    <>
      <div>
        <div className={classes.bigBox}>
          {settings?.["LED"] && (
            <div>
              <div className={clsx(classes.boderNoneBox, classes.title)}>
                <Typography category="Default" variant="BodyBold">
                  {t("LED")}
                </Typography>
              </div>
              {renderSimpleCheckBox("Recording status", "LED", "RECLED")}
              {renderSimpleCheckBox(
                "Front security (Normal)",
                "LED",
                "NORMALLED"
              )}
              {renderSimpleCheckBox(
                "Front security (Parking)",
                "LED",
                "PARKINGLED"
              )}
              {renderSimpleCheckBox("Rear security", "LED", "RearLED")}
              {renderSimpleCheckBox("Bluetooth (Parking)", "LED", "BTLED")}
              {renderSimpleCheckBox("Interior security", "LED", "InteriorLED")}

              {!DR7500GPRO_BT_ORDER &&
                renderSimpleCheckBox("LTE (Parking)", "LED", "LTELED")}
              {renderSimpleCheckBox("Wi-Fi (Parking)", "LED", "WifiLED")}
              {DR7500GPRO_BT_ORDER &&
                renderSimpleCheckBox("LTE (Parking)", "LED", "LTELED")}
            </div>
          )}
          {settings?.["PSENSOR"] && (
            <div className={clsx(classes.boderBottomBox, classes.SpdT12)}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={
                      PSENSORMODEL
                        ? current?.PSENSOR === "0" || current?.PSENSOR === "1"
                        : current?.PSENSOR === "1" || current?.PSENSOR === "2"
                    }
                    onChange={(e) => {
                      if (PSENSORMODEL) {
                        handleChangePSENSOR_MODEL("PSENSOR", e.target.checked);
                      } else if (SigmaStar_MODEL) {
                        handleChangeTSENSOR("PSENSOR", e.target.checked);
                      } else {
                        handleChangePSENSOR("PSENSOR", e.target.checked);
                      }
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {SigmaStar_MODEL
                      ? t("Touch sensor")
                      : t("Proximity sensor")}
                  </Typography>
                }
              />
              <div className={classes.SflexEnd}>
                <div className={clsx(FILE_LOCKED && classes.Width270)}>
                  <Selector
                    t={t}
                    disabled={
                      PSENSORMODEL
                        ? current?.PSENSOR === "2"
                        : current?.PSENSOR === "0"
                    }
                    keyValue={current?.PSENSOR}
                    dense
                    menuScrollTime
                    mobile={mobile}
                    onChange={(e) => {
                      setCurrent((c) => {
                        return c && { ...c, PSENSOR: e.key };
                      });
                    }}
                    items={SigmaStar_MODEL ? TSENSOROptions : PSENSOROptions}
                  />
                </div>
              </div>
            </div>
          )}

          {settings?.["VoiceGuidance"] && (
            <div>
              <div className={classes.boderNoneBox} style={{ paddingLeft: 16 }}>
                <Typography category="Default" variant="BodyBold">
                  {t("Voice guidance")}
                </Typography>
              </div>
              {renderSimpleCheckBox("Power on", "VoiceGuidance", "STARTVOICE")}
              {renderSimpleCheckBox(
                "Starting normal recording",
                "VoiceGuidance",
                "NORMALSTARTVOICE"
              )}
              {renderSimpleCheckBox(
                "Start event recording (beep)",
                "VoiceGuidance",
                "EVENTSTARTVOICE"
              )}
              {renderSimpleCheckBox(
                "Changing recording mode",
                "VoiceGuidance",
                "CHANGERECORDMODEVOICE"
              )}
              {renderSimpleCheckBox(
                "Settings have been changed",
                "VoiceGuidance",
                "CHANGECONFIGVOICE"
              )}
              {renderSimpleCheckBox("Power off", "VoiceGuidance", "ENDVOICE")}
              {renderSimpleCheckBox(
                "Speed alert (beep)",
                "VoiceGuidance",
                "SPEEDALERTVOICE"
              )}
              {renderSimpleCheckBox(
                "Hard acceleration alert (beep)",
                "VoiceGuidance",
                "ACCELERATIONVOICE"
              )}
              {renderSimpleCheckBox(
                "Hard braking alert (beep)",
                "VoiceGuidance",
                "HARSHBRAKINGVOICE"
              )}
              {renderSimpleCheckBox(
                "Hard cornering alert (beep)",
                "VoiceGuidance",
                "SHARPTURNVOICE"
              )}
              {renderSimpleCheckBox(
                "Impact detected in_",
                "VoiceGuidance",
                "PARKINGEVENTVOICE"
              )}
              {renderSimpleCheckBox(
                "Cloud related",
                "VoiceGuidance",
                "CLOUDVOICE"
              )}
            </div>
          )}
          {settings?.["VOLUME"] && (
            <div className={classes.boderBottomBoxSpace}>
              <div>
                <Typography category="Default" variant="Body">
                  {t("Volume")}
                </Typography>
              </div>
              <div className={classes.wid224}>
                <Sliders
                  max={5}
                  value={parseInt(current?.VOLUME ?? "0")}
                  onChange={(e, value) => {
                    const v = value as number;
                    setCurrent((c) => {
                      return (
                        c && {
                          ...c,
                          VOLUME: v.toString(),
                        }
                      );
                    });
                  }}
                ></Sliders>
                <Typography
                  category="Default"
                  variant="Small"
                  className={classes.sliderText}
                >
                  {current && parseInt(current.VOLUME)}
                </Typography>
              </div>
            </div>
          )}
          {settings?.["ScheduledReboot"] && (
            <div className={clsx(classes.boderBottomBox, classes.SpdT12)}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={getBoolean(current?.ScheduledReboot)}
                    onChange={(e) => {
                      handleChange("ScheduledReboot", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Scheduled reboots")}
                  </Typography>
                }
              />
              <div className={classes.flexEnd}>
                <div className={classes.Swid100}>
                  <Selector
                    t={t}
                    menuScrollTime
                    disabled={current?.ScheduledReboot !== "1"}
                    dense
                    mobile={mobile}
                    keyValue={current?.ScheduledRebootTime}
                    onChange={(e) => {
                      setCurrent((c) => {
                        return c && { ...c, ScheduledRebootTime: e.key };
                      });
                    }}
                    items={_.map(ScheduledRebootTime, (val, key) => {
                      return {
                        key: key,
                        value: val,
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          )}

          {settings?.["SpeedAlert"] && (
            <div className={clsx(classes.boderBottomBox, classes.SpdT12)}>
              <FormControlLabel
                className={classes.mgNone}
                control={
                  <CheckBox
                    checked={
                      current?.SpeedAlert === "1" || current?.SpeedAlert === "0"
                    }
                    onChange={(e) => {
                      handleChangeSpeed("SpeedAlert", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography category="Default" variant="Body">
                    {t("Speed alert")}
                  </Typography>
                }
              />

              <div className={classes.flexEnd}>
                <div className={classes.flex}>
                  <Input
                    disabled={current?.SpeedAlert === "2"}
                    dense
                    defaultText={
                      current?.SpeedAlert === "1" ? "of 200" : "of 300"
                    }
                    className={classes.mgR8}
                    value={
                      current?.SpeedAlert === "1"
                        ? current?.mileLimit
                        : current?.kmLimit
                    }
                    onChange={handleSpeed}
                  ></Input>
                  <div className={classes.Swid98}>
                    <Selector
                      t={t}
                      keyValue={current?.SpeedAlert}
                      disabled={current?.SpeedAlert === "2"}
                      dense
                      mobile={mobile}
                      onChange={(e) => {
                        setCurrent((c) => {
                          return c && { ...c, SpeedAlert: e.key };
                        });
                      }}
                      items={[
                        {
                          key: "0",
                          value: "Km/h",
                        },
                        {
                          key: "1",
                          value: "MPH",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ADAS */}
          {settings?.["ADAS"] && (
            <>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      checked={getBoolean(current?.ADASSetting)}
                      onChange={(e) => {
                        handleChange("ADASSetting", e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="BodyBold">
                      {t("ADAS")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      disabled={current?.ADASSetting === "0"}
                      checked={current?.ADASLDWS !== "0"}
                      onChange={(e) => {
                        handleChange("ADASLDWS", e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("LDWS")}
                    </Typography>
                  }
                />
                <div style={{ maxWidth: 117 }}>
                  <Selector
                    t={t}
                    disabled={
                      current?.ADASSetting === "0" || current?.ADASLDWS === "0"
                    }
                    keyValue={current?.ADASLDWS}
                    onChange={(e) => {
                      setCurrent((c) => {
                        return c && { ...c, ADASLDWS: e.key };
                      });
                    }}
                    dense
                    mobile={mobile}
                    items={_.map(ADASLDWS, (val, key) => {
                      return {
                        key: key,
                        value: val,
                      };
                    })}
                  />
                </div>
              </div>
              <div className={classes.boderBottomBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      disabled={current?.ADASSetting === "0"}
                      checked={getBoolean(current?.ADASFVSA)}
                      onChange={(e) => {
                        handleChange("ADASFVSA", e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("FVSA")}
                    </Typography>
                  }
                />
              </div>
            </>
          )}

          {eventRevMarkup}

          {/* 기존 Battery protection UI */}
          {settings?.["BatteryProtection"] && (
            <>
              <div className={classes.boderNoneBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      checked={current?.Battery !== "0"}
                      onChange={(e) => {
                        if (DR750GPRO) {
                          setOpenBatteryModal(true);
                          setSelectedField("Battery");
                          setSelectedValue(e.target.checked);
                        } else {
                          handleChange("Battery", e.target.checked);
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="BodyBold">
                      {t("Battery protection")}
                    </Typography>
                  }
                />
              </div>

              <div
                className={classes.boderBottomBox}
                style={{ border: "none" }}
              >
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      disabled={current?.Battery === "0"}
                      checked={current?.LowvoltageVolt !== "0"}
                      onChange={(e) => {
                        if (DR750GPRO) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVolt");
                          setSelectedValue(e.target.checked);
                        } else {
                          handleChangeLowVolt(
                            "LowvoltageVolt",
                            e.target.checked
                          );
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Low voltage cut-off")}
                    </Typography>
                  }
                />
                <div className={classes.flexEnd}>
                  <div className={classes.Swid116} style={{ maxWidth: 161 }}>
                    <Selector
                      t={t}
                      keyValue={
                        current?.LowvoltageVolt === "0"
                          ? "2"
                          : current?.LowvoltageVolt
                      }
                      disabled={
                        current?.LowvoltageVolt === "0" ||
                        current?.Battery === "0"
                      }
                      open={openBatteryLowVolt}
                      onClick={() => {
                        if (DR750GPRO && !batteryPRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageVolt");
                          setSelectedValue(undefined);
                        }
                      }}
                      onChange={(e) => {
                        setCurrent((c) => {
                          return c && { ...c, LowvoltageVolt: e.key };
                        });
                      }}
                      dense
                      mobile={mobile}
                      items={
                        DR750GPRO
                          ? _.map(LowvoltageVolt_GPRO, (val, key) => {
                              return {
                                key: key,
                                value: val,
                              };
                            })
                          : _.map(LowvoltageVolt, (val, key) => {
                              return {
                                key: key,
                                value: val,
                              };
                            })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={classes.boderBottomBox}>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      checked={current?.LowvoltageTime !== "0"}
                      disabled={current?.Battery === "0"}
                      onChange={(e) => {
                        if (DR750GPRO) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageTime");
                          setSelectedValue(e.target.checked);
                        } else {
                          handleChange("LowvoltageTime", e.target.checked);
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Cut-off timer")}
                    </Typography>
                  }
                />
                <div className={classes.flexEnd}>
                  <div className={classes.Swid98}>
                    <Selector
                      t={t}
                      keyValue={current?.LowvoltageTime}
                      disabled={
                        current?.LowvoltageTime === "0" ||
                        current?.Battery === "0"
                      }
                      open={openCutoff}
                      onClick={() => {
                        if (DR750GPRO && !cutoffTimerRevChanged) {
                          setOpenBatteryModal(true);
                          setSelectedField("LowvoltageTime");
                          setSelectedValue(undefined);
                        }
                      }}
                      onChange={(e) => {
                        setCutoffTimerRevChanged(true);
                        setCurrent((c) => {
                          return c && { ...c, LowvoltageTime: e.key };
                        });
                        console.log(e);
                      }}
                      dense
                      mobile={mobile}
                      items={_.map(LowvoltageTime, (val, key) => {
                        return {
                          key: key,
                          value: val,
                        };
                      })}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* 2021.09.03 REV 부터 적용되는 신규 Battery protection UI */}
          {batteryRevMarkup}
          {/* DMS */}
          {settings?.["DMS"] && current && (
            <DMSPanel tabInfo3={current} onChange={handleChange} />
          )}

          {settings?.["Seamless"] && (
            <div
              className={clsx(classes.boderBottomBox, classes.SpdT12)}
              style={{ paddingTop: 6, paddingBottom: 6 }}
            >
              <div>
                <FormControlLabel
                  className={classes.mgNone}
                  control={
                    <CheckBox
                      checked={current?.BTPair === "1"}
                      onChange={(e) => {
                        handleChange("BTPair", e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="Body">
                      {t("Seamless pairing")}
                    </Typography>
                  }
                />

                <div style={{ marginLeft: 32 }}>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {t("When OFF, connecting_")}
                  </Typography>
                </div>
              </div>
            </div>
          )}

          {settings?.["UserTextOverlay"] && (
            <div className={classes.boxSpaceNoneCenter}>
              <div style={{ minWidth: 120, display: "flex" }}>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{
                    margin: "12px 0",
                    [theme.breakpoints.up(Webviewer.mobile)]: {
                      marginBottom: 0,
                      width: 118,
                    },
                  }}
                >
                  {t("User text overlay")}
                </Typography>
              </div>
              <div>
                <div className={classes.inputStyle}>
                  <Input
                    dense
                    placeholder={t("Enter your text")}
                    onChange={handleInput}
                    value={current?.userString}
                    helperText={
                      <div className={classes.spaceBetween}>
                        <div className={classes.flex1}>{t("A-Z, a-z_")}</div>
                        <div className={classes.flex}>
                          {current?.userString.length ?? 0} / 20
                        </div>
                      </div>
                    }
                  ></Input>
                </div>
              </div>
            </div>
          )}
        </div>

        <Button
          className={classes.buttonSize}
          variant="contained"
          color="primary"
          disabled={disabledChange}
          onClick={handleUpdate}
        >
          {t("Save")}
        </Button>
      </div>
      <Modal
        open={openBatteryModal}
        heading={t("Warning")}
        content={
          <Typography
            category="Default"
            variant="Body"
            dangerouslySetInnerHTML={{
              __html: t("Adjusting the cut-off voltage_").replaceAll(
                "\n",
                "<br/>"
              ),
            }}
          />
          // <Typography category="Default" variant="Body">
          //   {t(`Adjusting the cut-off voltage_`)}
          // </Typography>
        }
        LButton={t("Cancel")}
        RButton={t("Continue")}
        Secondary
        onClickNegative={() => setOpenBatteryModal(false)}
        onClickPositive={() => {
          if (selectedField === "Battery") {
            handleChange(selectedField, selectedValue);
          } else if (selectedField === "LowvoltageVolt") {
            if (selectedValue !== undefined) {
              if (settings?.["BatteryProtectionRev"]) {
                handleChangeLowVoltRev(selectedField, selectedValue);
              } else {
                handleChangeLowVolt(selectedField, selectedValue);
              }
            } else {
              setBatteryPRevChanged(true);
            }
          } else if (selectedField === "LowvoltageTime") {
            if (selectedValue !== undefined) {
              if (settings?.["BatteryProtectionRev"]) {
                handleChangeCutoffTimerRev(selectedField, selectedValue);
              } else {
                handleChange(selectedField, selectedValue);
              }
            } else {
              setCutoffTimerRevChanged(true);
            }
          } else if (selectedField === "LowvoltageVoltHeavy") {
            setBatteryHRevChanged(true);
          }
          setOpenBatteryModal(false);
        }}
      />
    </>
  );
};
