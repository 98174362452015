import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  LightColors,
  Tab,
  Tabs,
  Typography,
  Tooltip,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { PlaybackPanel } from "../components/cameras/PlaybackPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  CAMERA,
  ICameraInfo,
  loadCamera,
  loadCameras,
  loadFirmwareSettings,
} from "../features/Camera/slice";
import { RootState } from "../features/store";
import clsx from "clsx";
import { Webviewer } from "../contants/Breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MobileCameraMenu } from "../components/MobileCameraMenu";
import { CameraSettingPanel } from "../components/cameras/CameraSettingPanel";
import { useHistory, useRouteMatch } from "react-router-dom";
import { LiveViewPanel } from "../components/cameras/LiveViewPanel";
import { ReportPanel } from "../components/cameras/ReportPanel";
import { GPSTrackingPanel } from "../components/gps-tracking/GPSTrackingPanel";
import { CameraMenu } from "../components/cameras/CameraMenu";
import { FileUploadModal } from "../components/FileUploadModal";
import { PermissionProps, ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { loadVODUploadList } from "../features/VOD/slice";
import _ from "lodash";
import { UPLOADING_QUEUE_UPDATE_INTERVAL } from "../contants/Common";
import { PSN650 } from "../contants/Models";
import { USER } from "../features/User/slice";
import { AvailableFw } from "../types";

export type ScreenMode =
  | "playback"
  | "live-view"
  | "settings"
  | "gps-tracking"
  | "report";

export interface CameraPlaybackScreenProps {
  mode: ScreenMode;
  psn: string;
  onRename?: (camera: ICameraInfo) => void;
  onDelete?: (camera: ICameraInfo) => void;
  onUpdate?: (camera: ICameraInfo) => void;
  onRebootCamera?: (camera: ICameraInfo) => void;
}

const ScreenModeToIndex: { [key in ScreenMode]: number } = {
  playback: 0,
  "live-view": 1,
  "gps-tracking": 2,
  report: 3,
  settings: 4,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "calc(var(--vh, 1vh) * 100 - 56px - 165px)",
    marginTop: (props: ScreenDefaultProps) =>
      48 + (props.headerDivRef?.offsetHeight ?? 56),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: ScreenDefaultProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 52px)"
          : "calc(var(--vh, 1vh) * 100 - 56px - 52px)",
      // margin: theme.spacing(5, 4, 2, 4),
      marginTop: (props: ScreenDefaultProps) =>
        props.error ? 48 + 85 : 48 + 56,
    },
  },
  tabDiv: {
    backgroundColor: LightColors.primary["0"],
    top: (props: ScreenDefaultProps) => props.headerDivRef?.offsetHeight ?? 56,
    ...(theme.direction === "rtl"
      ? { left: 0, right: 0 }
      : { left: 235, right: 0 }),
    position: "fixed",
    zIndex: 90,
    height: 53 + 29,
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 4),
      height: 53,
      ...(theme.direction === "rtl"
        ? { left: 0, right: 235 }
        : { left: 235, right: 0 }),
    },
  },
  tabScroll: {
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflowX: "unset",
      width: "100%",
    },
  },
  tab: {
    display: "flex",
    // justifyContent: "space-between",
    minWidth: 570,
    width: "100%",
    height: 52,
    alignItems: "flex-end",
  },
  tabDivClosed: {
    left: 73,
  },
  tabDivMobile: {
    left: 0,
    flexDirection: "column",
    alignItems: "unset",
  },
  tabItem: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: 6,
    whiteSpace: "nowrap",
  },
  mobileCamNameDiv: {
    display: "flex",
    paddingTop: theme.spacing(1),
    justifyContent: "center",
  },
}));

export const CameraPlaybackScreen = (
  props: CameraPlaybackScreenProps & PermissionProps & ScreenDefaultProps
) => {
  const {
    mode,
    psn,
    openMenu,
    onRename,
    onDelete,
    onUpdate,
    onRebootCamera,
    ...permissions
  } = props;

  const {
    gpsTrackingPerm,
    reportPerm,
    settingsCamPerm,
    renameCamPerm,
    fotaCamPerm,
    deleteCamPerm,
    liveviewPerm,
    rebootCamPerm,
    moreTabPerm,
  } = permissions;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const match = useRouteMatch<{ psn: string }>("/cameras/:psn");

  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );
  const camera = useSelector((state: RootState) => state[CAMERA].camera);

  const [value, setValue] = useState(ScreenModeToIndex[mode]);
  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const [openLiveviewTooltip, setOpenLiveviewTooltip] = React.useState(false);
  const [openGpsTooltip, setOpenGpsTooltip] = React.useState(false);
  const [openReportTooltip, setOpenReportTooltip] = React.useState(false);
  const [openSettingsTooltip, setOpenSettingsTooltip] = React.useState(false);
  const [openMoreTabTooltip, setOpenMoreTabTooltip] = React.useState(false);

  useEffect(() => {
    const body = document.getElementById("body-container");
    body?.setAttribute("style", "");
  });

  useEffect(() => {
    const cam = _.chain(cameraList?.DeviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === psn)
      .value();
    setCurrentCam((c) => {
      if (c?.psn !== cam?.psn) {
        return cam;
      } else if (c?.active !== cam?.active) {
        return cam;
      }
      return c;
    });
  }, [psn, cameraList?.DeviceListInfo]);

  useEffect(() => {
    if (camera) {
      dispatch(loadFirmwareSettings());
    }
  }, [dispatch, camera]);

  useEffect(() => {
    if (currentCam) {
      dispatch(loadFirmwareSettings());
    }
  }, [currentCam, dispatch]);

  useEffect(() => {
    function tick() {
      if (currentCam) {
        dispatch(loadVODUploadList(currentCam));
      }
    }
    if (currentCam && currentCam.active === "on") {
      let id = setInterval(tick, UPLOADING_QUEUE_UPDATE_INTERVAL);
      tick();
      return () => clearInterval(id);
    }
  }, [dispatch, currentCam]);

  useEffect(() => {
    dispatch(loadCamera(psn));
  }, [dispatch, psn]);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    setValue(ScreenModeToIndex[mode]);
  }, [mode]);

  const handleRename = useMemo(() => {
    return renameCamPerm
      ? () => {
          setOpenCamMenu(false);
          currentCam && onRename?.(currentCam);
          // setCurrentCam(camera?.dev_name);
        }
      : null;
  }, [currentCam, onRename, renameCamPerm]);

  const handleRebbotCam = useMemo(() => {
    return rebootCamPerm
      ? () => {
          currentCam && onRebootCamera?.(currentCam);
          setOpenCamMenu(false);
        }
      : null;
  }, [currentCam, onRebootCamera, rebootCamPerm]);

  const handleFOTA: (() => void) | null = useMemo(() => {
    return fotaCamPerm && !_.includes(PSN650, currentCam?.psn.substr(0, 4))
      ? (fw?: AvailableFw) => {
          if (fw) {
            if (currentCam && currentCam.active === "on") {
              if (fw.dmc200) {
                history.push(`/cameras/${currentCam.psn}/fota-dmc200`);
              } else if (fw.blackvue) {
                history.push(`/cameras/${currentCam.psn}/fota`);
              }
            }
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [currentCam, fotaCamPerm, history]);

  const handleDelete = useMemo(() => {
    return deleteCamPerm
      ? () => {
          setOpenCamMenu(false);
          currentCam && onDelete?.(currentCam);
        }
      : null;
  }, [currentCam, deleteCamPerm, onDelete]);

  const handleLiveTooltipHover = () => {
    if (!liveviewPerm) {
      setOpenLiveviewTooltip(true);
    }
  };

  const handleGpsTooltipHover = () => {
    if (!gpsTrackingPerm && !mobile) {
      setOpenGpsTooltip(true);
    }
  };
  const handleReportTooltipHover = () => {
    if (!reportPerm) {
      setOpenReportTooltip(true);
    }
  };
  const handleSettingsTooltipHover = () => {
    if (!settingsCamPerm) {
      setOpenSettingsTooltip(true);
    }
  };
  const handleMoreTabTooltipHover = () => {
    if (!moreTabPerm) {
      setOpenMoreTabTooltip(true);
    } else {
      return openMoreTabTooltip;
    }
  };

  return (
    <div className={classes.root} dir={theme.direction}>
      <div
        className={clsx(classes.tabDiv, {
          [classes.tabDivClosed]: !openMenu,
          [classes.tabDivMobile]: mobile,
        })}
      >
        {mobile && (
          <div className={classes.mobileCamNameDiv}>
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={LightColors.primary["2"]}
            >
              {camera?.dev_name}
            </Typography>
          </div>
        )}

        <div className={classes.tabScroll}>
          <div className={clsx(classes.tab)}>
            <Tabs value={value}>
              <Tab
                className={classes.tabItem}
                label={t("Playback")}
                onClick={() =>
                  history.push(`/cameras/${match?.params.psn}/playback`)
                }
              />

              <Tooltip
                open={openLiveviewTooltip}
                title={t("No permission") ?? "No permission"}
                placement="bottom"
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
              >
                <div
                  onMouseOver={handleLiveTooltipHover}
                  onMouseOut={() => {
                    setOpenLiveviewTooltip(false);
                  }}
                >
                  <Tab
                    className={classes.tabItem}
                    label={t("Live view")}
                    disabled={currentCam?.active === "off" || !liveviewPerm}
                    onClick={() =>
                      history.push(`/cameras/${match?.params.psn}/live-view`, {
                        clicked: true,
                      })
                    }
                  />
                </div>
              </Tooltip>
              <Tooltip
                open={openGpsTooltip}
                title={
                  <Typography category="Default" variant="Caption">
                    {userProfile?.userType === "User" ||
                    userProfile?.userType === "SubMaster"
                      ? t("No permission")
                      : t("Fleet Plan only_")}
                  </Typography>
                }
                placement="bottom"
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
              >
                <div
                  onMouseOver={handleGpsTooltipHover}
                  onMouseOut={() => {
                    setOpenGpsTooltip(false);
                  }}
                >
                  <Tab
                    className={classes.tabItem}
                    disabled={!gpsTrackingPerm}
                    label={t("GPS tracking")}
                    onClick={() =>
                      history.push(`/cameras/${match?.params.psn}/gps-tracking`)
                    }
                  />
                </div>
              </Tooltip>
              <Tooltip
                open={openReportTooltip}
                title={t("No permission") ?? "No permission"}
                placement="bottom"
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
              >
                <div
                  onMouseOver={handleReportTooltipHover}
                  onMouseOut={() => {
                    setOpenReportTooltip(false);
                  }}
                >
                  <Tab
                    className={classes.tabItem}
                    disabled={
                      !reportPerm ||
                      _.includes(PSN650, currentCam?.psn.substr(0, 4))
                    }
                    label={t("Report")}
                    onClick={() =>
                      history.push(`/cameras/${match?.params.psn}/report`)
                    }
                  />
                </div>
              </Tooltip>
              <Tooltip
                open={openSettingsTooltip}
                title={t("No permission") ?? "No permission"}
                placement="bottom"
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: "0, -12px",
                    },
                    flip: {
                      enabled: false,
                    },
                  },
                }}
              >
                <div
                  onMouseOver={handleSettingsTooltipHover}
                  onMouseOut={() => {
                    setOpenSettingsTooltip(false);
                  }}
                >
                  <Tab
                    className={classes.tabItem}
                    disabled={!settingsCamPerm}
                    label={t("Settings")}
                    onClick={() =>
                      history.push(`/cameras/${match?.params.psn}/settings`)
                    }
                  />
                </div>
              </Tooltip>
            </Tabs>

            <Tooltip
              disableTouchListener={mobile}
              placement={moreTabPerm ? "top" : "bottom"}
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: moreTabPerm ? "0, -20px" : "0, -12px",
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }}
              title={
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["0"]}
                >
                  {moreTabPerm ? t("More") : t("No permission")}
                </Typography>
              }
            >
              <div
                onMouseOver={handleMoreTabTooltipHover}
                onMouseOut={() => {
                  setOpenMoreTabTooltip(false);
                }}
              >
                <IconButton
                  ref={anchorRef}
                  onClick={() => setOpenCamMenu(true)}
                  disabled={!moreTabPerm}
                  style={{ padding: 6 }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

      <div
        style={mobile ? { height: "100%", paddingTop: 30 } : { height: "100%" }}
      >
        {value === 0 && <PlaybackPanel openMenu={openMenu} {...permissions} />}
        {value === 1 && currentCam && (
          <LiveViewPanel camera={currentCam} {...permissions} />
        )}
        {value === 2 && gpsTrackingPerm && (
          <GPSTrackingPanel plan={props.plan} openMenu={props.openMenu} />
        )}
        {value === 3 && reportPerm && (
          <ReportPanel openMenu={openMenu} {...permissions} />
        )}
        {value === 4 && settingsCamPerm && (
          <CameraSettingPanel {...permissions} />
        )}
      </div>
      {!mobile && (
        <CameraMenu
          open={openCamMenu}
          camera={currentCam}
          anchorRef={anchorRef}
          placement="bottom-start"
          onClickAway={() => setOpenCamMenu(false)}
          onRename={handleRename}
          onUpdate={handleFOTA}
          onDelete={handleDelete}
          onRebootCamera={handleRebbotCam}
        />
      )}
      {mobile && (
        <MobileCameraMenu
          open={openCamMenu}
          onClose={() => setOpenCamMenu(false)}
          onRename={handleRename}
          onUpdate={handleFOTA}
          onDelete={handleDelete}
          onRebootCamera={handleRebbotCam}
        />
      )}
      <FileUploadModal />
    </div>
  );
};
