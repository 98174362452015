import axios from "axios";
import _ from "lodash";
import { ILatLngBounds } from "../components/maps/GoogleMap";
import { API_SERVER_URI } from "../contants/Server";

export const getMyCameraLocations = (
  gpsServer: string,
  gpsPort: number,
  email: string,
  userToken: string,
  cancel: AbortController,
  bounds?: ILatLngBounds
) => {
  // let url = `https://${gpsServer}:${gpsPort}/BCS/gps_zone.php?email=${email}&user_token=${userToken}&tokenType=web&sw=${bounds.south}_${bounds.west}5&ne=${bounds.north}_${bounds.east}`
  let url = `https://${gpsServer}:${gpsPort}/BCS/gps_zone.php?email=${email}&user_token=${userToken}&tokenType=web`;
  if (bounds) {
    url += `&sw=${bounds.south}_${bounds.west}5&ne=${bounds.north}_${bounds.east}`;
  }
  return fetch(url, { signal: cancel.signal });
};

export const getPublicCameraLocations = (
  gpsServer: string,
  gpsPort: number,
  bounds: ILatLngBounds,
  cancel: AbortController
) => {
  return fetch(
    `https://${gpsServer}:${gpsPort}/BCS/free_gps_zone.php?sw=${bounds.south}_${bounds.west}5&ne=${bounds.north}_${bounds.east}`,
    { signal: cancel.signal }
  );
};

export const getMultiCamLocations = (
  gpsServer: string,
  gpsPort: number,
  email: string,
  psns: string[],
  userToken: string,
  cancel: AbortController
) => {
  return fetch(`https://${gpsServer}:${gpsPort}/BCS/gps_psns.php`, {
    method: "POST",
    body: JSON.stringify({
      email,
      user_token: userToken,
      tokenType: "web",
      psns,
    }),
    signal: cancel.signal,
  });
};

export const getGPSDriveData = (
  email: string,
  userToken: string,
  psn: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/getDriveData.php?email=${email}&user_token=${userToken}&psn=${psn}&token_type=web`
  );
};

export const getGPSTrackingData = (
  email: string,
  userToken: string,
  psn: string,
  drive_no_list: number[],
  cancel: AbortController,
  bounds?: ILatLngBounds
) => {
  const sw = bounds ? `&sw=${bounds.west}_${bounds.south}` : "";
  const ne = bounds ? `&ne=${bounds.east}_${bounds.north}` : "";
  return fetch(
    `${API_SERVER_URI}/BCS/getTrackData.php?email=${email}&user_token=${userToken}&psn=${psn}&drive_no_list=${_.join(
      drive_no_list,
      ","
    )}&token_type=web${sw}${ne}`,
    { signal: cancel.signal }
  );
};

export const getGeofenceAlerts = (
  email: string,
  userToken: string,
  psn: string,
  drive_no_list: number[]
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/getFenceLogData.php?email=${email}&user_token=${userToken}&token_type=web&psn=${psn}&drive_no_list=${_.join(
      drive_no_list,
      ","
    )}`
  );
};
