import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Container,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MinimalFooter } from "../components/MinimalFooter";
import { ResetHeader } from "../components/ResetHeader";
import { Webviewer } from "../contants/Breakpoints";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    marginTop: 58,
    minHeight: "calc(100vh - 58px)",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  link: {
    cursor: "pointer",
    color: LightColors.primary["7"],
    "&:hover, &:active": {
      color: LightColors.primary["8"],
    },
  },
}));
export const E404Screen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  return (
    <React.Fragment>
      <ResetHeader />

      <div className={classes.root}>
        <Container className={classes.body}>
          <div className={classes.textDiv}>
            <Typography
              category="Default"
              variant={mobile ? "H2" : "H1"}
              className={classes.marginB2}
            >
              {t("404: Page not found")}
            </Typography>
            <div>
              <Typography category="Default" variant="Body">
                {t("Check the web address_")}
              </Typography>{" "}
              <Typography
                category="Default"
                variant="Body"
                className={classes.link}
                onClick={() => history.replace("/")}
              >
                {t("home page")}
              </Typography>
              {"."}
            </div>
          </div>
        </Container>
        <MinimalFooter />
      </div>
    </React.Fragment>
  );
};
