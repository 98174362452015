import { makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import React, { ChangeEvent, useCallback, useState } from "react";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
} from "redux-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { IResetPasswordForm } from "../types";
import validator from "./validator";

interface FieldProps {
  label?: string;
}

const schema = yup.object().shape({
  newPassword: yup.string().required("Enter password"),
  password: yup.string().required("Enter password"),
});

const renderPasswdField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  newPassword: {
    marginBottom: theme.spacing(3),
    "& p": {
      lineHeight: "16px",
    },
  },
  passwdConfirm: {
    marginBottom: theme.spacing(3) + 3,
    "& p": {
      margin: 0,
    },
  },
}));

const ResetPasswd = (props: InjectedFormProps<IResetPasswordForm, {}>) => {
  const classes = useStyles();
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const [input, setInput] = useState({
    newPassword: "",
  });
  const { newPassword } = input;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleCheckLength = useCallback(() => {
    const checkSym = /[!@#$%^&*]/;
    const checkLetter = /[a-zA-Z]/;
    const checkNum = /[0-9]/;
    if (newPassword.length === 0) {
      return "";
    } else if (
      newPassword.length >= 6 &&
      ((checkSym.test(newPassword) && checkLetter.test(newPassword)) ||
        (checkSym.test(newPassword) && checkNum.test(newPassword)) ||
        (checkLetter.test(newPassword) && checkNum.test(newPassword)))
    ) {
      return "success";
    } else {
      return "fail";
    }
  }, [newPassword]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.newPassword}>
        <Field
          name="newPassword"
          onChange={handleChange}
          value={newPassword}
          label={t("New password")}
          password={true}
          createPW={true}
          mode={handleCheckLength()}
          helperText={t(
            "6 characters with a mix of letters, numbers and symbols"
          )}
          component={renderPasswdField}
        />
      </div>
      <div className={classes.passwdConfirm}>
        <Field
          name="password"
          label={t("Confirm new password")}
          password={true}
          component={renderPasswdField}
        />
      </div>
    </form>
  );
};

const ResetPasswdForm = reduxForm<IResetPasswordForm, {}>({
  form: "ResetForm",
  asyncValidate: validator(schema),
})(ResetPasswd);

export default ResetPasswdForm;
