import { makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import React from "react";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
} from "redux-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { IChangePasswdForm } from "../types";
import validator from "./validator";
import { useDispatch } from "react-redux";
import { Webviewer } from "../contants/Breakpoints";
import { TFunction } from "i18next";

interface FieldProps {
  label?: string;
  helperText?: string;
  t: TFunction;
}

const schema = yup.object().shape({
  old_passwd: yup.string().required("Enter the current password"),
  new_passwd: yup
    .string()
    .min(6, "more than 5 characters required")
    .test("password-check", "New password is_", function (value) {
      const { old_passwd } = this.parent;
      return value !== old_passwd;
    })
    .required("Enter a new password"),
  password: yup
    .string()
    .test("password-match", "Password doesn’t match", function (value) {
      const { new_passwd } = this.parent;
      return value === new_passwd;
    })
    .test("password-check", "New password is_", function (value) {
      const { old_passwd } = this.parent;
      return value !== old_passwd;
    })
    .min(6, "more than 5 characters required")
    .required("Enter a new password"),
});

const renderPasswdField = ({
  label,
  input,
  helperText,
  t,
  meta: { touched, invalid, error },
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && invalid}
      helperText={(touched && t(error)) || helperText}
      {...input}
      {...custom}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formDiv: {
    width: 352,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: 288,
    },
  },
  currentPassword: {
    marginTop: theme.spacing(2) + 2,
  },
  newPassword: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& p": {
      lineHeight: "16px",
    },
  },
  passwdConfirm: {
    marginBottom: theme.spacing(2),
    "& p": {
      lineHeight: "16px",
    },
  },
}));

const ChangePasswd = (props: InjectedFormProps<IChangePasswdForm, {}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { t } = useTranslation();

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("ChangePasswdForm"));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={handleEnterkey}
      className={classes.formDiv}
    >
      <div className={classes.currentPassword}>
        <Field
          name="old_passwd"
          label={t("Current password")}
          password={true}
          component={renderPasswdField}
          tabIndex={1}
          t={t}
          autoFocus
        />
      </div>
      <div className={classes.newPassword}>
        <Field
          name="new_passwd"
          label={t("New password")}
          password={true}
          helperText={t(
            "6 characters with a mix of letters, numbers and symbols"
          )}
          component={renderPasswdField}
          tabIndex={2}
          t={t}
        />
      </div>
      <div className={classes.passwdConfirm}>
        <Field
          name="password"
          label={t("Confirm new password")}
          password={true}
          component={renderPasswdField}
          tabIndex={3}
          t={t}
        />
      </div>
    </form>
  );
};

const ChangePasswdForm = reduxForm<IChangePasswdForm, {}>({
  form: "ChangePasswdForm",
  asyncValidate: validator(schema),
})(ChangePasswd);

export default ChangePasswdForm;
