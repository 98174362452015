import _ from "lodash";
import { FIRMWARE_SETTINGS, MODEL_KEY } from "../contants/Firmwares";
import { FwAbbr } from "../contants/FirmwareUpdate";
import { ICameraInfo } from "../features/Camera/slice";
import { IFirmware, IFirmwareInfo } from "../types";

export const getFirmwareConfig = (camera: ICameraInfo) => {
  const fwVersion = parseFloat(camera.fw_ver);
  console.log(camera);
  const config = FIRMWARE_SETTINGS[camera.model as MODEL_KEY];
  const defaultConfig = config.default;
  const configs = _.filter(config, (c, key) => {
    const fw = parseFloat(key);
    return !isNaN(fw) && fw <= fwVersion;
  });

  // console.log("getFirmwareConfig", defaultConfig, configs);

  return _.reduce(
    configs,
    (result, conf) => {
      return _.mergeWith(result, conf, (objValue, srcValue) => {
        return _.assignIn(objValue, srcValue);
      });
    },
    _.cloneDeep(defaultConfig)
  );
};

export const findNewFw = (camera: ICameraInfo, firmwares: IFirmware[]) => {
  const model = FwAbbr[camera.model];
  const fws = _.find(firmwares, (f) => f.model === model);
  let blackvue: IFirmwareInfo | undefined = undefined;
  if (fws) {
    const info = _.find(fws.info, (info) => _.includes(info.lang, camera.lang));
    if (info && parseFloat(info.version) > parseFloat(camera.fw_ver)) {
      blackvue = info;
    }
  }
  let dmc200: IFirmwareInfo | undefined = undefined;
  // if (camera.dms_type === "DMC100")
  if (camera.dms_type === "DMC200") {
    const fwsDmc = _.find(firmwares, (f) => f.model === "DMC200");
    if (fwsDmc) {
      const info = _.find(fwsDmc.info, (info) =>
        _.includes(info.lang, camera.lang)
      );

      if (
        info &&
        camera.dms_version &&
        parseFloat(info.version) > parseFloat(camera.dms_version)
      ) {
        dmc200 = info;
      }
    }
  }
  if (blackvue || dmc200) {
    return { blackvue, dmc200 };
  }
  return undefined;
};
