import { DeviceFirmwareConfig } from ".";

export const DR750G_2CH_PRO: DeviceFirmwareConfig = {
  default: {
    Time: {
      GpsSync: {},
      TimeSet: {},
    },
    Video: {
      ImageSetting: {
        options: [{ key: "0", value: "2K QHD@30fps + FHD@30fps" }],
      },
      VideoQuality: {
        options: [
          { key: "0", value: "Highest(Extreme)" },
          { key: "1", value: "Highest" },
          { key: "2", value: "High" },
          { key: "3", value: "Normal" },
        ],
      },
      NightVision: {
        options: [
          { key: "1", value: "Always" },
          { key: "2", value: "Parking mode only" },
        ],
      },
      FrontBrightness: {},
      RearBrightness: {},
    },
    Recording: {
      NormalRecord: {},
      AutoParking: {},
      RearParkingMode: {
        explain: "ON: front and_",
      },
      UseGpsInfo: {},
      VoiceRecord: {},
      DateDisplay: {},
      SpeedUnit: {},
      RecordTime: {
        options: [
          {
            key: "1",
            value: "1 min",
          },
        ],
      },
      LockEvent: {},
      FrontRotate: {},
      RearRotate: {
        options: [
          {
            key: "0",
            value: "Default",
          },
          {
            key: "1",
            value: "Rotate video 180°",
          },
          {
            key: "2",
            value: "Mirror video",
          },
        ],
      },
    },
    Gsensor: {
      Normal: {},
      Parking: {},
    },
    MotionDetection: {
      MOTIONSENSOR: {},
    },
    System: {
      LED: {
        RECLED: {},
        NORMALLED: {},
        PARKINGLED: {},
        RearLED: {},
        LTELED: {},
        WifiLED: {},
      },
      PSENSOR: {},
      VoiceGuidance: {
        STARTVOICE: {},
        NORMALSTARTVOICE: {},
        EVENTSTARTVOICE: {},
        CHANGERECORDMODEVOICE: {},
        ENDVOICE: {},
        SPEEDALERTVOICE: {},
        PARKINGEVENTVOICE: {},
        CLOUDVOICE: {},
        CHANGECONFIGVOICE: {},
        SHARPTURNVOICE: {},
        HARSHBRAKINGVOICE: {},
        ACCELERATIONVOICE: {},
      },
      VOLUME: {},
      ScheduledReboot: {},
      SpeedAlert: {},
      ADAS: {},
      BatteryProtection: {},
      UserTextOverlay: {},
    },
    Wifi: {
      LoginCredentials: {},
      WifiSleepMode: {},
    },
    Cloud: { CloudService: {}, sta1: {}, sta2: {}, sta3: {} },
    FirmwareLanguage: ["English"],
  },
  1.004: {
    System: {
      EventRev: {},
    },
  },
  1.005: {
    System: {
      Seamless: {},
    },
  },
};
