import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PeriodRequest {
  psn: string;
  startTime: string;
  endTime: string;
}

export interface MonthRequest {
  psn: string;
  month: number;
}

export type EventType =
  | "Drowsy"
  | "Distracted"
  | "Calling"
  | "Smoking"
  | "Overspeed"
  | "SharpTurn"
  | "HarshBraking"
  | "Acceleration";

export interface IDMSEventStat {
  event: EventType;
  value: number;
}

export interface IMonthlyReport {
  day: number;
  traveled: number;
  totalEvents: number;
  eventsPerDrivingDay: { [key in EventType]: number };
}
export interface IDailyReport {
  hour: number;
  traveled: number;
  totalEvents: number;
  eventsPerDrivingHour: { [key in EventType]: number };
}

export interface IReportScore {
  month: number;
  value: number;
}

export interface IGeofenceDuration {
  name: string;
  duration: number;
}

export interface IDailyLog {
  psn: string;
  driver_info: string;
  duration: number;
  driving_distance: number;
  driving_duration: number;
  stop_count: number;
  stop_duration: number;
  no_gps_count: number;
  no_gps_duration: number;
  speed_over_count: number;
  max_speed: number;
  avg_speed: number;
  normal_event_count: number;
  parking_event_count: number;
  parking_mode_count: number;
  parking_mode_duration: number;
  drowsy_count: number;
  distracted_count: number;
  undetected_count: number;
  detected_count: number;
  calling_count: number;
  mask_off_count: number;
  smoking_count: number;
  sharp_turn_count: number;
  harsh_braking_count: number;
  acceleration_count: number;
  geofencing_enter_count: number;
  geofencing_exit_count: number;
  geofencing_in_duration: IGeofenceDuration[];
}

interface IState {
  loading: boolean;
  monthly: IMonthlyReport[];
  daily: IDailyReport[];
  monthlyScores: IReportScore[];
  dailyLogs: IDailyLog[];
  shownAlert: boolean;
}

let initialState: IState = {
  loading: false,
  monthly: [],
  daily: [],
  monthlyScores: [],
  dailyLogs: [],
  shownAlert: false,
};

const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setShownAlert: (state, action: PayloadAction<boolean>) => {
      state.shownAlert = action.payload;
    },
    clearReport: (state) => {
      state.loading = false;
      state.monthly = [];
      state.daily = [];
      state.monthlyScores = [];
      state.dailyLogs = [];
    },
    loadMonthlyReport: (state, _action: PayloadAction<PeriodRequest>) => {
      state.loading = true;
    },
    successLoadMonthlyReport: (
      state,
      { payload }: PayloadAction<IMonthlyReport[]>
    ) => {
      state.loading = false;
      state.monthly = payload;
    },
    loadDailyReport: (state, _action: PayloadAction<PeriodRequest>) => {
      state.loading = true;
    },
    successLoadDailyReport: (
      state,
      { payload }: PayloadAction<IDailyReport[]>
    ) => {
      state.loading = false;
      state.daily = payload;
    },
    loadMonthlyScore: (state, _action: PayloadAction<MonthRequest>) => {
      state.loading = true;
    },
    successLoadMonthlyScore: (
      state,
      { payload }: PayloadAction<IReportScore[]>
    ) => {
      state.loading = false;
      state.monthlyScores = payload;
    },
    loadDailyLog: (state, _action: PayloadAction<PeriodRequest>) => {
      state.loading = true;
    },
    successLoadDailyLog: (state, { payload }: PayloadAction<IDailyLog[]>) => {
      state.loading = false;
      state.dailyLogs = payload;
    },
  },
});

export const {
  setShownAlert,
  clearReport,
  loadMonthlyReport,
  successLoadMonthlyReport,
  loadDailyReport,
  successLoadDailyReport,
  loadMonthlyScore,
  successLoadMonthlyScore,
  loadDailyLog,
  successLoadDailyLog,
} = slice.actions;
export const REPORT = slice.name;
export default slice.reducer;
