export const APPSTORE = "https://apps.apple.com/kr/app/blackvue/id1049209637";
export const GOOGLEPLAY =
  "https://play.google.com/store/apps/details?id=comb.blackvuec";
export const MACAPP = "https://www.blackvue.com/downloads/#app-viewer";
export const WINDOWAPP = "https://www.blackvue.com/downloads/#app-viewer";
export const Firmware = "https://blackvue.com/downloads/";
export const TUTORIAL =
  // "https://helpcenter.blackvue.com/hc/en-us/sections/205396547-BlackVue-Cloud-Service";
  "https://cloudmanual.blackvue.com/docs/blackvueweb/";
export const HELPCENTER =
  "https://helpcenter.blackvue.com/hc/en-us?_ga=2.50642985.1622374351.1606961554-1684053571.1604299594&mobile_site=false";
export const HELPCENTER_MOBILE =
  "https://helpcenter.blackvue.com/hc/en-us?mobile_site=true";
export const Privacy_Policy =
  "https://blackvue.com/warranty-terms-conditions/?tab=cloud-privacy-policy";
export const TermsConditions =
  "https://blackvue.com/warranty-terms-conditions/?tab=cloud-terms-of-service";
export const TermsOfLocation =
  "https://blackvue.com/warranty-terms-conditions/?tab=terms-of-location-based-service";
export const InformationWe =
  "https://blackvue.com/warranty-terms-conditions/?tab=information-we-collect-and-why";
export const CloudConnectionSettings =
  // "https://cloudmanual.blackvue.com/docs/blackvueapp/change-cloud-connection-settings/";
  "https://cloudmanual.blackvue.com/docs/blackvueapp/add-camera/#0-toc-title";
export const BlackVueCloud = "https://www.blackvuecloud.com";
