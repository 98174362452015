import { DeviceFirmwareConfig } from ".";

export const DR750X_1CH: DeviceFirmwareConfig = {
  default: {
    Time: {
      GpsSync: {},
      TimeSet: {},
    },
    Video: {
      ImageSetting: {
        options: [{ key: "0", value: "Full HD @60fps" }],
      },
      VideoQuality: {
        options: [
          { key: "0", value: "Highest(Extreme)" },
          { key: "1", value: "Highest" },
          { key: "2", value: "High" },
          { key: "3", value: "Normal" },
        ],
      },
      NightVision: {
        options: [
          { key: "1", value: "Always" },
          { key: "2", value: "Parking mode only" },
        ],
      },
      FrontBrightness: {},
    },

    Recording: {
      NormalRecord: {},
      AutoParking: {},
      VoiceRecord: {},
      DateDisplay: {},
      SpeedUnit: {},
      UseGpsInfo: {},
      RecordTime: {
        options: [
          {
            key: "1",
            value: "1 min",
          },
        ],
      },
      LockEvent: {},
      FrontRotate: {},
    },
    Gsensor: {
      Normal: {},
      Parking: {},
    },
    MotionDetection: {
      MOTIONSENSOR: {},
    },
    System: {
      LED: {
        RECLED: {},
        NORMALLED: {},
        PARKINGLED: {},
        LTELED: {}, //문서에는 있지만 값이 넘어오지 않음
        WifiLED: {},
      },
      PSENSOR: {},
      VoiceGuidance: {
        STARTVOICE: {},
        NORMALSTARTVOICE: {},
        EVENTSTARTVOICE: {},
        CHANGERECORDMODEVOICE: {},
        ENDVOICE: {},
        SPEEDALERTVOICE: {},
        PARKINGEVENTVOICE: {},
        CLOUDVOICE: {},
        CHANGECONFIGVOICE: {},
        SHARPTURNVOICE: {},
        HARSHBRAKINGVOICE: {},
        ACCELERATIONVOICE: {},
      },
      VOLUME: {},
      ScheduledReboot: {},
      SpeedAlert: {},
      BatteryProtection: {},
      UserTextOverlay: {},
    },
    Wifi: {
      LoginCredentials: {},
      WifiSleepMode: {},
    },
    Cloud: { CloudService: {}, sta1: {}, sta2: {}, sta3: {} },
    FirmwareLanguage: [
      "English",
      "Japan",
      "China",
      "German",
      "French",
      "Thai",
      "Idn",
      "Vietnam",
      "Spain",
      "Italian",
      "Dutch",
      "Polish",
      "Norwegian",
      "Finnish",
    ],
  },
  1.008: {
    System: {
      EventRev: {},
    },
  },
  1.009: {
    System: {
      Seamless: {},
    },
  },
};
