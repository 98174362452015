import React, { useCallback, useEffect, useMemo } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  Avatars,
  CheckBox,
  Fonts,
  LightColors,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/styles";

import { Webviewer } from "../../contants/Breakpoints";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import SearchIcon from "@material-ui/icons/Search";
import { FormControlLabel, useMediaQuery } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableContainer from "@material-ui/core/TableContainer";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableRow from "@material-ui/core/TableRow";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import _ from "lodash";
import {
  addUser,
  DashCamList,
  GROUP,
  IDashCamInfo,
  IGroupUserList,
  loadGroupUser,
} from "../../features/Group/slice";
import { useDispatch, useSelector } from "react-redux";
import { StatusColors, StatusNames } from "../../types";
import { RootState } from "../../features/store";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NoResults } from "../NoResults";

const ADMIN_HEADERS = ["Name, Email", "Status"];

const TableHeaderCell = withStyles((theme: Theme) => ({
  head: {
    padding: "12px 12px 11px",
    "&:first-child": {
      padding: "12px 8px 11px 5px",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: "12px 8px 11px",
      },
    },
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),
    color: LightColors.primary["2"],
    ...Fonts.Default.Small,
  },
}))(MuiTableCell);

const TableCell = withStyles((theme: Theme) => ({
  head: {
    padding: 5,
  },
  body: {
    padding: theme.spacing(1.5) - 1,

    color: LightColors.primary["1"],
    ...Fonts.Default.Body,
    border: "none",
  },
}))(MuiTableCell);

const TableBodyRow = withStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: "none",
    },
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),
  },
}))(MuiTableRow);

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: "100%",
    borderRadius: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      borderRadius: 4,
    },
  },
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: LightColors.primary["0"],
    overflow: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: "auto",
    },
  },

  buttonRoot: {
    padding: 6,
    marginRight: -6,
  },
  searchBtn: {
    justifyContent: "flex-start",
    marginTop: theme.spacing(2) - 1,
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: "100%",
      justifyContent: "flex-end",
      marginRight: 0,
      marginBottom: theme.spacing(0.25),
    },
  },
  searchIcon: {
    "& svg": {
      fontSize: "1.125rem!important",
    },
  },
  groupNameText: {
    marginBottom: theme.spacing(2),
  },
  adminTablewrap: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    paddingLeft: 1,
  },
  avatarDiv: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  row: {
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginBottom: 2,
    },
  },
  infoDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: -5,
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
  },
  tdName: {
    minWidth: 382,
    width: 388,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  tdStatus: {
    verticalAlign: "top",
    minWidth: 86,
    width: 104,
  },

  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100% - 70px)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 225,
      height: "100%",
    },
  },
  modalBottom: {
    justifyContent: "space-between",
    padding: theme.spacing(2.125, 2, 1.5),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.125, 3, 3),
    },
  },
  modalContentWrap: {
    padding: theme.spacing(2.25, 3, 1),
    display: "block",
    height: "calc(100vh - 55px - 65px)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: "100%",
    },
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 469,
    },
  },
  contentWrap: {
    height: "100%",
  },
  statusIcon: {
    marginTop: 1,
    ...(theme.direction === "rtl" ? { marginRight: 5.5 } : { marginLeft: 5.5 }),
  },
  checkbox: {
    ...(theme.direction === "rtl" ? { marginLeft: 16 } : { marginRight: 16 }),
  },
  checkDiv: {
    ...(theme.direction === "rtl" && { marginRight: 0 }),
  },
}));

interface GroupAddUserModalProps {
  open: boolean;
  group?: DashCamList;
  user?: IGroupUserList;
  camera?: IDashCamInfo;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

export const GroupAddUserModal = ({
  open,
  group,
  user,
  camera,
  onClose,
  onClickNegative,
  onClickPositive,
}: GroupAddUserModalProps) => {
  const maxUserNum = 3;
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { loading, type } = useSelector((state: RootState) => state[GROUP]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [searchKey, setSearchKey] = React.useState("");

  useEffect(() => {
    dispatch(loadGroupUser());
  }, [dispatch]);

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>, email: string) => {
      const selectedIndex = selected.indexOf(email);
      if (selectedIndex > -1) {
        setSelected(_.filter(selected, (e) => e !== email));
      } else {
        setSelected([...selected, email]);
      }
    },
    [selected]
  );

  const isSelected = useCallback(
    (email: string) => {
      return selected.indexOf(email) !== -1;
    },
    [selected]
  );

  const handleAddUser = (group: DashCamList) => {
    if (camera) {
      dispatch(
        addUser({
          PSN: camera?.PSN,
          GroupID: group.GroupID,
          UserEmail: selected,
        })
      );
    }
  };

  const filteredUsers = useMemo(() => {
    return _.filter(
      user?.Users,
      (user) =>
        user.Email.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
        user.FirstName.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
        user.LastName.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
    );
  }, [searchKey, user?.Users]);

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      loading={loading && type === addUser.type}
      onClickNegative={onClickNegative}
      onClickPositive={(e) => {
        group && handleAddUser(group);
      }}
      heading={t("Add user")}
      contentClassName={classes.modalContentWrap}
      actionClassName={classes.modalBottom}
      className={classes.modalWrap}
      content={
        <div className={classes.contentWrap}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
            className={classes.groupNameText}
          >
            {`${camera?.DashCamName} · ${group?.GroupName}`}
          </Typography>
          <Input
            placeholder={t("Name or email")}
            startIcon={
              <SearchIcon style={{ color: LightColors.primary["3"] }} />
            }
            startIconClassName={classes.searchIcon}
            className={classes.searchBtn}
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            dense
            autoFocus
          />

          {loading && (
            <div className={classes.loadingDiv}>
              <CircularProgress size={48} thickness={6} color="primary" />
            </div>
          )}

          {!loading && filteredUsers.length === 0 && searchKey && <NoResults />}
          {!loading && filteredUsers.length > 0 && (
            <MuiTableContainer className={classes.adminTablewrap}>
              <MuiTable aria-label="simple table">
                <MuiTableHead>
                  <MuiTableRow>
                    {_.map(ADMIN_HEADERS, (header) => (
                      <TableHeaderCell>{t(header)}</TableHeaderCell>
                    ))}
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {_.map(filteredUsers, (user, index) => {
                    const isItemSelected = isSelected(user.Email);
                    return (
                      <>
                        <TableBodyRow>
                          <TableCell className={classes.tdName}>
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                className={classes.checkDiv}
                                control={
                                  <CheckBox
                                    disabled={
                                      camera &&
                                      _.filter(camera.Users, (u) => u.Email)
                                        .length +
                                        selected.length >=
                                        maxUserNum &&
                                      !_.includes(selected, user.Email)
                                    }
                                    className={classes.checkbox}
                                    // style={{ marginRight: 16 }}
                                    color="primary"
                                    key={`user-${index}`}
                                    checked={isItemSelected}
                                    onClick={(e) => {
                                      handleClick(e, user.Email);
                                    }}
                                  />
                                }
                                label={
                                  <div className={classes.infoDiv}>
                                    <div className={classes.avatarDiv}>
                                      <Avatars
                                        imgSrc={user.profilePath}
                                        name={user.LastName}
                                      ></Avatars>
                                    </div>
                                    <div>
                                      <div className={classes.row}>
                                        {user.FirstName && user.LastName ? (
                                          <Typography
                                            category="Default"
                                            variant="Body"
                                            htmlColor={LightColors.primary["1"]}
                                          >
                                            {`${user.FirstName} ${user.LastName}`}
                                          </Typography>
                                        ) : (
                                          "–"
                                        )}
                                      </div>

                                      <div className={classes.row}>
                                        <Typography
                                          category="Default"
                                          variant="Caption"
                                          htmlColor={LightColors.primary["2"]}
                                        >
                                          {user.Email}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                }
                              />
                            </div>
                          </TableCell>
                          <TableCell className={classes.tdStatus}>
                            <div className={classes.statusDiv}>
                              <FiberManualRecordIcon
                                style={{
                                  color: `${StatusColors[user.Status]}`,
                                  fontSize: 15,
                                }}
                              />
                              <Typography
                                category="Default"
                                variant="Body"
                                className={classes.statusIcon}
                              >
                                {StatusNames[user.Status]}
                              </Typography>
                            </div>
                          </TableCell>
                        </TableBodyRow>
                      </>
                    );
                  })}
                </MuiTableBody>
              </MuiTable>
            </MuiTableContainer>
          )}
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Add")}
      close
      fullSize={mobile && true}
      RButtonDisabled={selected.length === 0}
      btnCenter
      selectedNum={`${
        ((camera &&
          camera.Users &&
          _.filter(camera.Users, (u) => u.Email).length) ??
          0) + selected.length
      }/${maxUserNum}`}
    />
  );
};
