import { makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import React, { useCallback } from "react";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
  clearSubmitErrors,
} from "redux-form";
import { TFunction, useTranslation } from "react-i18next";
import * as yup from "yup";
import { ILoginForm } from "../types";
import validator from "./validator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { ERROR } from "../features/Error/slice";

interface FieldProps {
  label?: string;
  error?: string;
  t: TFunction;
  helperText?: string;
}

const schema = yup.object().shape({
  password: yup.string().required("Enter password"),
});

const renderEmailField = ({
  label,
  input,
  error,
  helperText,
  t,
  meta: { touched, invalid, error: metaError, ...restMeta },
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && (!!error || !!metaError)}
      helperText={touched ? t(error ?? metaError ?? helperText) : undefined}
      {...input}
      {...custom}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  email: {
    marginBottom: theme.spacing(3),
  },
}));

const Login = (
  props: InjectedFormProps<ILoginForm, { disabled?: boolean ; autoFocus?:boolean }> & {
    disabled?: boolean;
    autoFocus?:boolean;
  }
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { initialValues, handleSubmit } = props;
  const { email } = initialValues ?? {};
  const { t } = useTranslation();
  const { error } = useSelector((state: RootState) => state[ERROR]);

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("LoginForm"));
    }
  };

  const resetError = useCallback(() => {
    dispatch(clearSubmitErrors("LoginForm"));
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleEnterkey}>
      <div className={classes.email}>
        <Field
          type="email"
          name="email"
          label={t("Email")}
          value={email}
          component={renderEmailField}
          readOnly={props.disabled}
          disabled={props.disabled}
          onFocus={() => resetError()}
          t={t}
          autoFocus
        />
      </div>
      <div>
        <Field
          name="password"
          label={t("Password")}
          password={true}
          component={renderEmailField}
          error={error && t(error)}
          t={t}
          autoFocus={props.autoFocus}
        />
      </div>
    </form>
  );
};

const LoginForm = reduxForm<ILoginForm, { disabled?: boolean ; autoFocus?:boolean; }>({
  form: "LoginForm",
  asyncValidate: validator(schema),
  enableReinitialize: true,
  destroyOnUnmount: false,
  persistentSubmitErrors: true,
})(Login);

export default LoginForm;
