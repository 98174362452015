import { DR750_2CH_LTE } from "./DR750_2CH_LTE";
import { DR900X_2CH } from "./DR900X_2CH";
import { DR900S_1CH } from "./DR900S_1CH";
import { DR750S_1CH } from "./DR750S_1CH";
import { DR750S_2CH } from "./DR750S_2CH";
import { DR900S_2CH } from "./DR900S_2CH";
import { DR750G_1CH } from "./DR750G_1CH";
import { DR750G_2CH } from "./DR750G_2CH";
import { DR750X_1CH } from "./DR750X_1CH";
import { DR750X_2CH } from "./DR750X_2CH";
import { DR900X_1CH } from "./DR900X_1CH";
import { DR750G_1CH_PRO } from "./DR750G_1CH_PRO";
import { DR750G_2CH_PRO } from "./DR750G_2CH_PRO";
import { DR750X_Plus } from "./DR750X_Plus";
import { DR900X_Plus } from "./DR900X_Plus";
import { DR750G_PRO } from "./DR750G_PRO";
import { DR750X_3CH_Plus } from "./DR750X_3CH_Plus";
import { DR750X_LTE_Plus } from "./DR750X_LTE_Plus";
import { BlackVue_7_2CH } from "./BlackVue_7_2CH";
import { BlackVue_7G_PRO } from "./BlackVue_7G_PRO";
import { BlackVue_9_2CH } from "./BlackVue_9_2CH";
import { BlackVue_7_Box } from "./BlackVue_7_Box";
import { BlackVue_7_LTE } from "./BlackVue_7_LTE";
import { BlackVue_9_LTE } from "./BlackVue_9_LTE";

interface IConfigOption {
  key: string;
  value: string;
}

export interface ITimeConfig {
  GpsSync: object;
  TimeSet: object;
}

export interface IVideoConfig {
  ImageSetting?: { options: IConfigOption[] };
  VideoQuality?: { options: IConfigOption[]; options4k?: IConfigOption[] };
  NightVision?: { options: IConfigOption[]; disabled?: boolean };
  VCodecType?: object;
  FrontBrightness?: object;
  RearBrightness?: object;
}

export interface IRecordingConfig {
  NormalRecord: object;
  AutoParking: object;
  RearParkingMode?: {
    explain?: string;
  };
  InteriorParkingMode?: object;
  VoiceRecord: object;
  DateDisplay: object;
  SpeedUnit: object;
  UseGpsInfo: object;
  RecordTime: {
    options: IConfigOption[];
    options4k?: IConfigOption[];
  };
  LockEvent: object;
  FrontRotate: object;
  RearRotate?: {
    options: IConfigOption[];
  };
  InteriorRotate?: object;
}

export interface IGSensorConfig {
  Normal: object;
  Parking: object;
}

export interface IMotionDetectionConfig {
  MOTIONSENSOR?: object;
}

export interface ISystemConfig {
  LED?: {
    RECLED: object;
    NORMALLED: object;
    PARKINGLED: object;
    RearLED?: object;
    WifiLED: object;
    InteriorLED?: object;
    LTELED?: object;
    BTLED?: object;
  };
  PSENSOR?: object;
  VoiceGuidance?: {
    STARTVOICE: object;
    NORMALSTARTVOICE: object;
    EVENTSTARTVOICE: object;
    CHANGERECORDMODEVOICE: object;
    ENDVOICE: object;
    SPEEDALERTVOICE: object;
    ACCELERATIONVOICE?: object;
    HARSHBRAKINGVOICE?: object;
    SHARPTURNVOICE?: object;
    CHANGECONFIGVOICE: object;
    PARKINGEVENTVOICE: object;
    CLOUDVOICE: object;
  };
  VOLUME?: object;
  ScheduledReboot?: object;
  SpeedAlert?: object;
  ADAS?: object;
  BatteryProtection?: object;
  BatteryProtectionRev?: {
    minVolt: number;
    maxVolt: number;
    defaultVolt: number;
    minVoltHeavy: number;
    maxVoltHeavy: number;
    defaultVoltHeavy: number;
    defaultTime: string;
    minTime: string;
    maxTime: string;
    LowvoltageVolt: { [key: string]: string };
    LowvoltageVoltHeavy: { [key: string]: string };
  };
  LowvoltageVolt?: object;
  LowvoltageTime?: object;
  UserTextOverlay?: object;
  EventRev?: object;
  DMS?: object;
  Seamless?: object;
}

export interface IWifiConfig {
  LoginCredentials: object;
  WifiSleepMode?: object;
  onstart_LTE?: object;
  onstart?: object;
  WifiBand?: object;
}

export interface ICloudConfig {
  CloudService: object;
  sta1: object;
  sta2: object;
  sta3: object;
}

type FirmwareLanguage =
  | "English"
  | "Japan"
  | "China"
  | "German"
  | "French"
  | "Thai"
  | "Idn"
  | "Vietnam"
  | "Spain"
  | "Italian"
  | "Dutch"
  | "Polish"
  | "Norwegian"
  | "Finnish"
  | "Arabic"
  | "Korea";

export type FirmwareVersion = "default" | number;
export type DeviceFirmwareConfig = {
  [key in FirmwareVersion]: IFirmwareConfig;
};

export interface IFirmwareConfig {
  Time?: ITimeConfig;
  Video?: IVideoConfig;
  Recording?: IRecordingConfig;
  Gsensor?: IGSensorConfig;
  MotionDetection?: IMotionDetectionConfig;
  System?: ISystemConfig;
  Wifi?: IWifiConfig;
  Cloud?: ICloudConfig;
  FirmwareLanguage?: FirmwareLanguage[];
  DMS?: object;
}

export type SETTING_TABS =
  | "Recording"
  | "Time"
  | "Video"
  | "Gsensor"
  | "MotionDetection"
  | "Cloud"
  | "FirmwareLanguage"
  | "System"
  | "Wifi";

export type FW_VERSION = "default" | number;
export type MODEL_KEY =
  | "DR750-2CH LTE"
  | "DR750S-1CH"
  | "DR750S-2CH"
  | "DR750G-1CH"
  | "DR750G-2CH"
  | "DR750G Pro"
  | "DR750G-1CH Pro"
  | "DR750G-2CH Pro"
  | "DR750X-1CH"
  | "DR750X-2CH"
  | "DR750X Plus"
  | "DR750X-3CH Plus"
  | "DR750X LTE Plus"
  // | "DR750K LTE"
  | "DR900S-1CH"
  | "DR900S-2CH"
  | "DR900X-1CH"
  | "DR900X-2CH"
  | "DR900X Plus"
  | "BlackVue 7"
  | "BlackVue 7G Pro"
  | "BlackVue 9"
  | "BlackVue 7 Box"
  | "BlackVue 7 LTE"
  | "BlackVue 9 LTE";

export const SUPPORT_REBOOT_FW: { [key in MODEL_KEY]: number } = {
  "DR750X Plus": 1.003,
  "DR750-2CH LTE": 1.008,
  "DR900X-1CH": 1.008,
  "DR900X-2CH": 1.008,
  "DR900S-1CH": 1.014,
  "DR900S-2CH": 1.014,
  "DR750X-1CH": 1.008,
  "DR750X-2CH": 1.008,
  "DR750S-1CH": 1.019,
  "DR750S-2CH": 1.019,
  "DR750G-1CH": 1.004,
  "DR750G-2CH": 1.004,
  "DR750G-1CH Pro": 1.004,
  "DR750G-2CH Pro": 1.004,
  "DR750G Pro": 1.004,
  // "DR750K LTE": 1.0,
  "DR750X LTE Plus": 1.0,
  "DR900X Plus": 1.0,
  "DR750X-3CH Plus": 0,
  "BlackVue 7": 0,
  "BlackVue 7G Pro": 0,
  "BlackVue 9": 0,
  "BlackVue 7 Box": 0,
  "BlackVue 7 LTE": 0,
  "BlackVue 9 LTE": 0,
};

export const FIRMWARE_SETTINGS: {
  [key in MODEL_KEY]: { [key in FW_VERSION]: IFirmwareConfig };
} = {
  "DR750-2CH LTE": DR750_2CH_LTE,
  //750S
  "DR750S-1CH": DR750S_1CH,
  "DR750S-2CH": DR750S_2CH,

  //750G
  "DR750G-1CH": DR750G_1CH,
  "DR750G-2CH": DR750G_2CH,
  "DR750G Pro": DR750G_PRO,
  "DR750G-1CH Pro": DR750G_1CH_PRO,
  "DR750G-2CH Pro": DR750G_2CH_PRO,
  //750X
  "DR750X-1CH": DR750X_1CH,
  "DR750X-2CH": DR750X_2CH,
  "DR750X Plus": DR750X_Plus,
  "DR750X-3CH Plus": DR750X_3CH_Plus,
  "DR750X LTE Plus": DR750X_LTE_Plus,
  // "DR750K LTE": DR750K_LTE,
  //900S
  "DR900S-1CH": DR900S_1CH,
  "DR900S-2CH": DR900S_2CH,
  //900X
  "DR900X-1CH": DR900X_1CH,
  "DR900X-2CH": DR900X_2CH,
  "DR900X Plus": DR900X_Plus,
  "BlackVue 7": BlackVue_7_2CH,
  "BlackVue 7G Pro": BlackVue_7G_PRO,
  "BlackVue 9": BlackVue_9_2CH,
  "BlackVue 7 Box": BlackVue_7_Box,
  "BlackVue 7 LTE": BlackVue_7_LTE,
  "BlackVue 9 LTE": BlackVue_9_LTE,
};
