import { FileTypeName, LightColors } from "@thingsw/pitta-design-system";
import { OnOffType } from "./features/Camera/slice";
import { ITrackData } from "./features/GPS/slice";

export type UserType = "Master" | "SubMaster" | "User";
export type VideoQualityType = "original" | "low";
// import moment from "moment-timezone";

export const Front = 1;
export const Rear = 2;
export const Interior = 3;
export type EventTypeAbbr = "N" | "E" | "P" | "M";
export type Direction = typeof Front | typeof Rear | typeof Interior;
export type DrawingMode = "polygon" | "polyline" | "circle" | "rectangle";
export type SNS_LINKAGE =
  | "LINKAGE"
  | "NOTHING_LINKAGE"
  | "BLACKVUE_LINKAGE"
  | "ANOTHER_LINKAGE";

export const FenceTypeCode: { [key in DrawingMode]: number } = {
  polygon: 1,
  polyline: 4,
  circle: 3,
  rectangle: 2,
};

export const FenceTypeName: { [key: number]: DrawingMode } = {
  1: "polygon",
  2: "rectangle",
  3: "circle",
  4: "polyline",
};

export const EventAbbrToEventFull: { [key in EventTypeAbbr]: FileTypeName } = {
  N: "Normal",
  E: "Event",
  P: "Parking",
  M: "Manual",
};

export const MCODE_TO_TEXT: { [key: number]: string } = {
  0: "Event",
  1: "Overspeed",
  2: "Driving impact",
  3: "Hard acceleration",
  4: "Hard braking",
  5: "Hard cornering",
  6: "Drowsy",
  7: "Distracted",
  8: "Detected",
  // 9: "Calling",
  // 10: "Smoking",
  9: "Hand distraction",
  10: "Hand distraction",
  11: "Undetected",
  12: "Mask off",
  13: "Geofence-enter",
  14: "Geofence-exit",
  15: "Geofence-pass",
};

export interface ILatLng {
  lat: number;
  lng: number;
}

export interface ILatLngBounds {
  east: number;
  west: number;
  north: number;
  south: number;
}

export interface IGeometry {
  type: DrawingMode;
  fenceId?: string;
  coords?: ILatLng[];
  center?: ILatLng;
  radius?: number;
  bounds?: ILatLngBounds;
  color?: string;
  opacity?: number;
}

export interface IInfoWindow {
  drive_no: number;
  index: number;
  data: ITrackData;
  sid: number;
  last?: boolean;
}

export interface ListPaging {
  startIndex: number;
  endIndex: number;
  ordering: 0 | 1; // 0: newest, 1: oldest
  searchKey?: string;
}

export interface IAccel {
  timestamp: number;
  x: number;
  y: number;
  z: number;
}

export interface User {
  username: string;
  role: Role;
}

export interface Role {
  id: number;
  name: string;
  description: string;
  type: string;
  // created_by: moment.Moment;
  // updated_by: moment.Moment;
}

export interface ILoginForm {
  email: string;
  password: string;
}

export interface IMemberForm {
  email: string;
  user_token: string;
  guestEmail: string;
  userType?: UserType;
}
export interface ISendMailForm {
  email: string;
}

export interface IResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

export interface ISignupForm {
  firstName: string;
  lastName: string;
  email: string;
  passwd: string;
  tos: boolean;
  subscribe: boolean;
}

// export interface IBillingForm {
//   cameras: string;
//   referralCode: string;
// }

export interface IConfirmForm {
  inputs: string[];
}

export interface IChangeNameForm {
  firstName: string;
  lastName: string;
}

export interface IChangePasswdForm {
  old_passwd: string;
  new_passwd: string;
}
export interface IGPSLocation {
  time?: number;
  lat: number;
  lng: number;
  heading: number;
  psn: string;
  name?: string;
  speed?: string;
  mode?: string;
  model?: string;
  share_video?: OnOffType;
  active: OnOffType;
}

export type RESULT_CODE =
  | "BC_ERR_AUTHENTICATION"
  | "BC_ERR_SIGNATURE"
  | "BC_ERR_INVALID_DATA"
  | "BC_ERR_AUTHENTICATION"
  | "BC_ERR_INVALID_PARAMETER"
  | "BC_ERR_NEED_TO_CONFIRM"
  | "BC_ERR_BLACK_LIST"
  | "BC_ERR_SERVER"
  | "BC_ERR_OK"
  | "BC_ERR_DUPLICATED"
  | "BC_ERR_ALREADY_EXIST_FILE"
  | "BC_ERR_ALREADY_EXIST_S3"
  | "BC_ERR_NOTBLACKVUELINKAGE"
  | "BC_ERR_LIMIT_EXCEEDED"
  | "BC_ERR_UNAVAILABLE"
  | "BC_ERR_INTERNAL_ERROR"
  | "401"
  | "BC_ERR_CARD_DECLINED"
  | 400;

export interface ReferenceObject {
  clientHeight: number;
  clientWidth: number;
  referenceNode?: Node;

  getBoundingClientRect(): ClientRect;
}

export interface IGroupNameForm {
  groupNameList: string;
}

export type StatusCode = "P" | "A" | "E";

export type StatusName = {
  [key in StatusCode]: string;
};

export const StatusColors: { [key in StatusCode]: string } = {
  P: LightColors.secondary["17"],
  A: LightColors.secondary["15"],
  E: LightColors.secondary["11"],
};

export const StatusNames: StatusName = {
  P: "Pending",
  A: "Accepted",
  E: "Expired",
};
export interface IFirmwareInfo {
  lang: string[];
  version: string;
  file: string;
  file_size: number;
  release_note: string;
  release_date: string;
  md5: string;
}

export interface IFirmware {
  model: string;
  cloud_fota_version: string;
  info: IFirmwareInfo[];
}

export interface IPayment {
  step: "payload" | "verify";
  itemID: string;
  referralCode: string;
  cameraCount: number;
  stripeToken: string;
  payload?: string;
}

export interface AvailableFws {
  [key: string]: AvailableFw;
}

export interface AvailableFw {
  blackvue?: IFirmwareInfo;
  dmc200?: IFirmwareInfo;
}
