import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type FONT_FAMILY =
  | "Roboto"
  | "Noto Sans KR"
  | "Noto Sans JP"
  | "Noto Sans SC"
  | "Noto Sans Thai UI"
  | "Noto Naskh Arabic UI";

interface IState {
  direction: "rtl" | "ltr";
  menuOpen: boolean;
  fontFamily: FONT_FAMILY;
}

let initialState: IState = {
  direction: "ltr",
  menuOpen: true,
  fontFamily: "Roboto",
};

const slice = createSlice({
  name: "Theme",
  initialState,
  reducers: {
    changeDirection: (state, { payload }: PayloadAction<"rtl" | "ltr">) => {
      state.direction = payload;
    },
    setMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.menuOpen = payload;
    },
    setFontFamily: (state, { payload }: PayloadAction<FONT_FAMILY>) => {
      state.fontFamily = payload;
    },
  },
});

export const { changeDirection, setMenuOpen, setFontFamily } = slice.actions;
export const THEME = slice.name;
export default slice.reducer;
