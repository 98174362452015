import React, { useCallback, useEffect } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  Field,
  submit,
  getFormValues,
} from "redux-form";
// -- 다국어 지원 텍스트 컴포넌트
import { useTranslation } from "react-i18next";

import { IGroupNameForm } from "../types";
import { useMediaQuery } from "@material-ui/core";
// 피타 디자인 시스템
import { Button, LightColors, Typography } from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
// -- 반응형 규격 정보
import { Webviewer } from "../contants/Breakpoints";

import { useHistory } from "react-router-dom";
import { RootState } from "../features/store";
import { GROUP } from "../features/Group/slice";
import { loadUserProfile, USER } from "../features/User/slice";

import { TFunction } from "i18next";
import * as yup from "yup";
import validator from "./validator";

interface FieldProps {
  label?: string;
  t: TFunction;
}

const renderNameField = ({
  label,
  input,
  t,
  meta: { touched, invalid, error },
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input
      label={label}
      error={touched && invalid}
      helperText={touched && t(error)}
      {...input}
      {...custom}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // 1-1. 폼
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 437,
      marginTop: theme.spacing(16.25),
      padding: 0,
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginTop: theme.spacing(0.6),
      marginLeft: theme.spacing(0),
    },
  },
  // 1-1-1. 타이틀 타이포
  titleTypo: {
    marginBottom: "40px",
    marginTop: "2px",
  },
  // 1-1-2. 인풋 DIV
  inputDiv: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginTop: theme.spacing(0.1),
    },
  },
  // 1-1-3. 카운트 타이포 DIV
  cntDiv: {
    display: "flex",
    marginTop: "3px",
    marginBottom: "24px",
    justifyContent: "flex-end",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginTop: "4px",
    },
  },
  // 1-1-4. 추가, 삭제 버튼
  btnDiv: {
    display: "flex",
    maxWidth: 208,
    [theme.breakpoints.down(Webviewer.mobile)]: {
      maxWidth: "100%",
      display: "block",
    },
  },
  // 1-1-4-1. 추가 버튼
  sendBtn: {
    whiteSpace: "nowrap",
    width: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginLeft: theme.spacing(2) }
        : { marginRight: theme.spacing(2) }),
      marginBottom: 0,
    },
  },
  // 1-1-4-2. 삭제 버튼
  cancelBtn: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: "100%",
    },
  },
}));

const schema = yup.object().shape({
  groupNameList: yup
    .string()
    .trim()
    .required("Enter group name")
    .min(1, "The name must_")
    .max(30, "The name must_")
    .test("unallowed-check", "Unallowed character detected", (val) => {
      return (
        (val?.indexOf('"') ?? -1) === -1 && (val?.indexOf("\\") ?? -1) === -1
      );
    }),
});

const AddGroup = (props: InjectedFormProps<IGroupNameForm, {}>) => {
  // 변수 영역
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const state = useSelector((state: RootState) => state);
  const { loading } = useSelector((state: RootState) => state[GROUP]);
  const { userProfile } = useSelector((state: RootState) => state[USER]);
  const { groupNameList } = (getFormValues("AddGroupForm")(state) as
    | { groupNameList: string }
    | undefined) ?? { groupNameList: "" };

  useEffect(() => {
    dispatch(loadUserProfile());
  }, [dispatch]);

  // 함수 영역
  const handleAddGroup = () => {
    dispatch(submit("AddGroupForm"));
  };

  const handleBack = () => {
    history.replace("/groups");
  };

  const getDisableCheck = useCallback(() => {
    const blankText = groupNameList.match(/\s/g)?.length;
    if (userProfile && userProfile.userType !== "Master") {
      return true;
    } else if (blankText === groupNameList.length) {
      return true;
    } else if (groupNameList.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [groupNameList, userProfile]);

  return (
    <form className={classes.formDiv} onSubmit={handleSubmit}>
      {/*타이틀 타이포*/}
      <div className={classes.titleTypo}>
        <Typography category="Default" variant={mobile ? "H2" : "H1"}>
          {t("Add group")}
        </Typography>
      </div>
      {/*그룹명 인풋*/}
      <div className={classes.inputDiv}>
        <Field
          t={t}
          name="groupNameList"
          // value={groupNameList}
          label={t("Group Name")}
          component={renderNameField}
          // onInput={(e: ChangeEvent<HTMLInputElement>) => {
          //   e.target.value = e.target.value.slice(0, 30);
          // }}
          autoFocus
        />
      </div>
      {/*카운트 타이포*/}
      <div className={classes.cntDiv}>
        <Typography
          category="Default"
          variant="Caption"
          htmlColor={LightColors.primary["2"]}
        >
          {groupNameList.length}/30
        </Typography>
      </div>
      {/*추가, 취소 버튼*/}
      <div className={classes.btnDiv}>
        <Button
          color="primary"
          className={classes.sendBtn}
          onClick={handleAddGroup}
          loading={loading}
          disabled={getDisableCheck()}
        >
          {t("Add")}
        </Button>
        <Button
          className={classes.cancelBtn}
          variant="outlined"
          color="primary"
          onClick={handleBack}
        >
          {t("Cancel")}
        </Button>
      </div>
    </form>
  );
};

const AddGroupForm = reduxForm<IGroupNameForm, {}>({
  form: "AddGroupForm",
  asyncValidate: validator(schema),
  enableReinitialize: true,
})(AddGroup);

export default AddGroupForm;
