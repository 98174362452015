import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";

//////////////////////////////////////////////////////////
// Empty Camera를 바탕으로 작업됨.
// GroupsScreen 스크린에서 사용
//////////////////////////////////////////////////////////

interface EmptyGroupsProps {
  variant?: "default" | "small";
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ variant }: EmptyGroupsProps) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...(variant === "small"
      ? { margin: "auto 0px" }
      : { margin: theme.spacing(2, 0) }),
    flexGrow: 1,
  }),
  noItemDiv: ({ variant }: EmptyGroupsProps) =>
    variant === "small"
      ? {}
      : {
          marginTop: "18.75vh",
        },
  noCamText: ({ variant }: EmptyGroupsProps) =>
    variant === "small"
      ? {
          marginTop: theme.spacing(0.5),
        }
      : {
          marginTop: theme.spacing(1.4),
          marginBottom: theme.spacing(1),
        },
  noItemIcon: ({ variant }: EmptyGroupsProps) =>
    // 모바일 대응 코드 수정
    variant === "small"
      ? {
          width: 140,
          height: 140,
          marginBottom: 5,
        }
      : {
          width: 200,
          height: 200,
        },
  textCenter: ({ variant }: EmptyGroupsProps) =>
    // 모바일 대응 코드 수정
    variant === "small"
      ? {
          textAlign: "center",
          width: 300,
        }
      : {
          textAlign: "center",
        },
}));

export const EmptyGroups = (props: EmptyGroupsProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { variant } = props;

  return (
    <div className={classes.root}>
      <ReactSVG
        src="/images/noItem.svg"
        className={classes.noItemDiv}
        beforeInjection={(svg) => {
          console.log(classes.noItemIcon);
          svg.classList.add(..._.split(classes.noItemIcon, " "));
        }}
      />
      <Typography
        category="Default"
        variant={variant === "small" ? "BodyBold" : "H6"}
        className={classes.noCamText}
        htmlColor={LightColors.primary["2"]}
      >
        {" "}
        {t("no groups yet")}
      </Typography>
      <Typography
        category="Default"
        variant={variant === "small" ? "Small" : "Body"}
        className={classes.textCenter}
        htmlColor={LightColors.primary["2"]}
      >
        {t("groups will show_")}.
      </Typography>
    </div>
  );
};
