import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import smoothscroll from "smoothscroll-polyfill";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import createStore from "./features/store";
import { resources } from "./i18n/config";

const { store, persistor } = createStore();

smoothscroll.polyfill();

if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
  });

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
