import { PayloadAction } from "@reduxjs/toolkit";
import { goBack, push } from "connected-react-router";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as Api from "../../apis";
import { ROWS_PER_PAGE } from "../../contants/List";
import { ListPaging, RESULT_CODE } from "../../types";
import { setError } from "../Error/slice";
import { RootState } from "../store";
import { openToast } from "../Toast/slice";
import { IUserLoginInfo, logout, USER } from "../User/slice";
import {
  clearLoading,
  deleteMembers,
  IMember,
  IMemberList,
  invite,
  IPermissions,
  loadMemberPaging,
  loadMembers,
  loadUserPermissions,
  MEMBER,
  resetMember,
  RoleType,
  successInvite,
  successLoadMemberPaging,
  successLoadMembers,
  successLoadUserPermissions,
  updateUserPermissions,
} from "./slice";

function* handleInviteMember({ payload }: PayloadAction<IMember>) {
  try {
    const res = yield call(Api.inviteMember, payload);
    const { resultcode, message } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successInvite());
      yield put(openToast({ message: "Invite sent" }));
      yield put(goBack());
    } else if (
      resultcode === "BC_ERR_DUPLICATED" &&
      message === "email is already exist"
    ) {
      yield put(setError("An account is_"));
      yield put(clearLoading());
    }
  } catch (err) {}
}

function* handleLoadMember() {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(Api.getMemberList, email, loginInfo.user_token);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IMemberList;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadMembers(response));
    } else if (resultcode === "BC_ERR_AUTHENTICATION") {
      yield put(logout());
    } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
      yield put(setError("Invalid parameter"));
      yield put(clearLoading());
    }
  } catch (err) {
    yield put(setError(err.message));
    yield put(clearLoading());
  }
}

function* handleLoadMemberPaging({ payload }: PayloadAction<ListPaging>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const loginInfo = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const res = yield call(
      Api.getMemberList,
      email,
      loginInfo.user_token,
      payload
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IMemberList;
    };
    if (response) {
      if (resultcode === "BC_ERR_OK") {
        yield put(successLoadMemberPaging(response));
      } else if (resultcode === "BC_ERR_AUTHENTICATION") {
        yield put(logout());
      } else if (resultcode === "BC_ERR_INVALID_PARAMETER") {
        yield put(setError("Invalid parameter"));
        yield put(clearLoading());
      }
    } else {
      yield put(resetMember());
    }
  } catch (err) {
    yield put(setError(err.message));
    yield put(clearLoading());
  }
}

function* handleDeleteMember({
  payload,
}: PayloadAction<{ deleteEmail: string; deleteEmailUserType: RoleType }[]>) {
  try {
    const { currentPage, memberList } = yield select(
      (state: RootState) => state[MEMBER]
    );
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    let success = false;
    for (const user of payload) {
      const res = yield call(
        Api.removeMember,
        user.deleteEmail,
        user.deleteEmailUserType,
        email,
        user_token
      );
      const { resultcode } = res.data as {
        resultcode: RESULT_CODE;
        message: string;
      };
      if (resultcode === "BC_ERR_OK") {
        if (payload.length > 1) {
          yield put(openToast({ message: "Members deleted" }));
          // success = true;
        } else if (payload.length === 1) {
          if (user.deleteEmailUserType === "SubMaster") {
            yield put(openToast({ message: "Admin deleted" }));
          } else {
            yield put(openToast({ message: "Driver deleted" }));
          }
        }
        yield put(clearLoading());
        yield put(loadMemberPaging(currentPage));
        success = true;
      }
    }
    if (success) {
      const cnt = memberList?.inviteMemberCount ?? 1;
      let request = currentPage ?? {
        startIndex: 1,
        endIndex: ROWS_PER_PAGE,
        ordering: 0,
      };
      if (!(request.startIndex <= cnt && cnt <= request.endIndex)) {
        request = {
          startIndex: request.startIndex - ROWS_PER_PAGE,
          endIndex: request.endIndex - ROWS_PER_PAGE,
          ordering: currentPage.ordering,
        };
      }
      yield put(loadMemberPaging(request));
      // yield put(successDeleteMembers());
      yield put(push("/members"));
    }
  } catch (err) {}
}

function* handleLoadUserPermissions({ payload }: PayloadAction<string>) {
  try {
    console.log("handleLoadUserPermissions");
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(Api.getUserPermissions, email, user_token, payload);

    const { resultcode, response } = resp.data as {
      resultcode: RESULT_CODE;
      response: { permissions: IPermissions };
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadUserPermissions(response.permissions));
    }
  } catch (err) {
    console.error(err);
    yield put(setError(err.message));
    yield put(clearLoading());
  }
}

function* handleUpdateUserPermissions({
  payload,
}: PayloadAction<{ roleSetEmail: string; permissions: IPermissions }>) {
  try {
    const email = yield select((state: RootState) => state[USER].email);
    const { user_token } = (yield select(
      (state: RootState) => state[USER].loginInfo
    )) as IUserLoginInfo;
    const resp = yield call(
      Api.updateUserPermissions,
      email,
      user_token,
      payload.roleSetEmail,
      payload.permissions
    );
    const { resultcode } = resp.data as {
      resultcode: RESULT_CODE;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(openToast({ message: "Permissions updated" }));
      yield put(successLoadUserPermissions(payload.permissions));
    }
  } catch (err) {
    console.error(err);
    yield put(setError(err.message));
    yield put(clearLoading());
  }
}

export function* watchMember() {
  yield takeLatest(invite, handleInviteMember);
  yield takeLatest(loadMembers, handleLoadMember);
  yield takeLatest(loadMemberPaging, handleLoadMemberPaging);
  yield takeLatest(deleteMembers, handleDeleteMember);
  yield takeLatest(loadUserPermissions, handleLoadUserPermissions);
  yield takeLatest(updateUserPermissions, handleUpdateUserPermissions);
}
