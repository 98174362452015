import axios from "axios";
import { IGeofence } from "../features/Geofence/slice";

export const getGeofences = (
  server: string,
  port: number,
  email: string,
  userToken: string
) => {
  if (process.env.NODE_ENV === "development") {
    return axios.post(
      `/geofence/geo_fence/getFenceList.php`,
      {
        email,
        user_token: userToken,
        tokenType: "web",
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  } else {
    return axios.post(
      `https://${server}:${port}/geo_fence/getFenceList.php`,
      {
        email,
        user_token: userToken,
        tokenType: "web",
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
};

export const addGeofence = (
  server: string,
  port: number,
  email: string,
  userToken: string,
  body: IGeofence
) => {
  if (process.env.NODE_ENV === "development") {
    return axios.post(
      `/geofence/geo_fence/updateGeoFence.php`,
      {
        email,
        user_token: userToken,
        tokenType: "web",
        ...body,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  } else {
    return axios.post(
      `https://${server}:${port}/geo_fence/updateGeoFence.php`,
      {
        email,
        user_token: userToken,
        tokenType: "web",
        ...body,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
};

export const deleteGeofence = (
  server: string,
  port: number,
  email: string,
  userToken: string,
  fenceId: string,
  fenceName: string
) => {
  if (process.env.NODE_ENV === "development") {
    return axios.post(
      `/geofence/geo_fence/deleteGeoFence.php`,
      {
        email,
        user_token: userToken,
        tokenType: "web",
        fenceId,
        fenceName,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  } else {
    return axios.post(
      `https://${server}:${port}/geo_fence/deleteGeoFence.php`,
      {
        email,
        user_token: userToken,
        tokenType: "web",
        fenceId,
        fenceName,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
};
