import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  LightColors,
  Menu,
  MobileMenu,
  Tooltip,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import CheckIcon from "@material-ui/icons/Check";
import RefreshIcon from "@material-ui/icons/Refresh";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FlipIcon from "@material-ui/icons/Flip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";

import { RootState } from "../../features/store";
import { ICameraInfo } from "../../features/Camera/slice";
import { VolumeSlider } from "./VolumeSlider";
import {
  exitFullscreen,
  isFullscreen,
  requestFullscreen,
} from "../../utils/GoogleMap";
import screenfull from "screenfull";
import { updateLiveviewUsage, USER } from "../../features/User/slice";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Webviewer } from "../../contants/Breakpoints";
import { API_GATEWAY_URI, getLbURI } from "../../contants/Server";
import { getDirection } from "../../utils/VOD";
import axios from "axios";
import _ from "lodash";
import { openToast } from "../../features/Toast/slice";
import { Direction, Front, Interior, Rear } from "../../types";
import { MODELS_2CH, MODELS_3CH } from "../../contants/Models";
import Hls from "hls.js";
import { PAYMENT } from "../../features/Payment/slice";
import * as KVSWebRTC from "amazon-kinesis-video-streams-webrtc";
import AWS from "aws-sdk";
import { sleep } from "../../utils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  videoDiv: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    paddingTop:
      "max(350px, calc(100vh - 108px - 188px - 24px - 24px - 49px - 16px))",
  },
  multiViewDiv: {
    // paddingTop: "220px",
    paddingTop: "34vh",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: `${LightColors.primary["1"]}4D`,
  },
  addCameraDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: LightColors.primary["6"],
    border: `1px solid ${LightColors.primary["4"]}`,
    zIndex: 2,
  },
  addCameraBtn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: LightColors.primary["7"],
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    "user-select": "none",
    /* Non-prefixed version, currently
       supported by Chrome, Edge, Opera and Firefox */
    cursor: "pointer",
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:active": {
      color: LightColors.primary["8"],
    },
  },
  videoHFlip: {
    "-webkit-transform": "scale(-1, 1)",
    transform: "scale(-1, 1)",
  },
  videoVFlip: {
    "-webkit-transform": "scale(1, -1)",
    transform: "scale(1, -1)",
  },
  videoFlip: {
    "-webkit-transform": "scale(-1, -1)",
    transform: "scale(-1, -1)",
  },
  ctrlContDiv: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    padding: theme.spacing(0, 1),
    zIndex: 1,
  },
  blackvueIcon: {
    width: 160,
    height: 29,
    fill: LightColors.primary["0"],
  },
  circularLoading: {
    color: LightColors.primary["0"],
  },
  overlayDiv: {
    background:
      "linear-gradient(180deg, rgba(19, 19, 28, 0) 66.22%, rgba(19, 19, 28, 0.75) 95.5%)",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  ctrlDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
  },
  ctrlIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: LightColors.primary["0"],
    fill: LightColors.primary["0"],
    "&>svg": {
      color: LightColors.primary["0"],
      fill: LightColors.primary["0"],
    },
    "&.Mui-disabled": {
      color: `${LightColors.primary["0"]}40`,
      fill: `${LightColors.primary["0"]}40`,
    },
  },
  volumeCtrlDiv: {
    width: 52,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  playerMenuDiv: {
    backgroundColor: LightColors.primary["1"],
    color: LightColors.primary["0"],
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",

    "& svg": {
      color: LightColors.primary["0"],
      fill: LightColors.primary["0"],
    },
  },
  webMenuDiv: {
    "&:hover": {
      backgroundColor: LightColors.primary["2"],
    },
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  menuDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 80,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: `${LightColors.primary["1"]}73`,
    color: LightColors.primary["0"],
    padding: theme.spacing(1.125, 1),
    "& svg": {
      fill: LightColors.primary["0"],
    },
    textShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
  },
  dirDiv: {
    display: "flex",
    alignItems: "center",
    width: 62,
    cursor: "pointer",
  },
  selectedOverlay: {
    border: `3px solid ${LightColors.secondary["17"]}`,
  },
  videoFullScreen: {
    paddingTop: "calc(var(--vh, 1vh) * 100)",
  },
  mobilePlayCtrlDiv: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${LightColors.primary["1"]}73`,
  },
  mobilePlayCtrl: {
    display: "flex",
    minWidth: 216,
    justifyContent: "center",
    alignItems: "center",
  },
  disabledCtrlIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    "&>svg": {
      color: `${LightColors.primary["0"]}40`,
      fill: `${LightColors.primary["0"]}40`,
    },
    display: "flex",
  },
  uncheckedMenu: {
    paddingLeft: 44,
  },
}));

interface LiveViewPlayerProps {
  direction: 1 | 2 | 3;
  camera?: ICameraInfo;
  main?: boolean;
  multiView?: boolean;
  disabled?: boolean;
  isPublic?: boolean;
  // windows chrome에서 click action없이 mute안된 상태로 영상재생하면 재생안됨
  // https://developer.chrome.com/blog/autoplay/
  clicked?: boolean;
  onAddCamera?: () => void;
  onMenuOpen?: () => void;
  menuAnchorRef?: React.RefObject<HTMLDivElement>;
  selected?: boolean;
  onClose?: () => void;
  onChannelChange: (dir: Direction) => void;
}

const CLOUD_NATIVE = true;
// AWS Credentials
const accessKeyId = "AKIARY735FLTCJZZES2Q";
const secretAccessKey = "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";

const region = "ap-northeast-2";

export const LiveViewPlayer = (props: LiveViewPlayerProps) => {
  const {
    direction,
    camera,
    main,
    multiView,
    disabled,
    menuAnchorRef,
    selected,
    isPublic,
    onAddCamera,
    onClose,
    onChannelChange,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const prevDirectionRef = useRef(direction);
  //remteView
  const remoteView = useRef<HTMLVideoElement>(null);
  const fullScreenRef = useRef<any>(null);
  const videoRef = useRef<HTMLDivElement>(null);
  const volumeAnchorRef = useRef<HTMLButtonElement>(null);
  const flipAnchorRef = useRef<HTMLButtonElement>(null);

  const usedTimeRef = useRef(0);

  const { email, loginInfo, usageInfo } = useSelector(
    (state: RootState) => state[USER]
  );
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const [dir /*, setDir*/] = useState(direction);
  const [openFlipMenu, setOpenFlipMenu] = useState(false);
  const [openVolumeMenu, setOpenVolumeMenu] = useState(false);
  const [hFlip, setHFlip] = useState(false);
  const [vFlip, setVFlip] = useState(false);
  const [loading, setLoading] = useState(true);
  // click 이벤트가 발생한 경우, default volume으로 아닌 경우 mute
  const [volume, setVolume] = useState(multiView ? 0 : 50);
  const [preVolume, setPreVolume] = useState(50);
  const [fullscreen, setFullscreen] = useState(false);
  const [refresh /*, setRefresh*/] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [hlsInst, setHlsInst] = useState<Hls>();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [disableVolume, setDisableVolume] = useState(false);

  const [playing, setPlaying] = useState(false);

  //webrtc 연결용
  const peerConnection = useRef<RTCPeerConnection | null>(null);
  const signalingClient = useRef<KVSWebRTC.SignalingClient | null>(null);
  const localView = useRef<HTMLVideoElement | null>(null);
  const localStream = useRef<MediaStream | null>(null);
  const remoteStream = useRef<MediaStream | null>(null);

  const kinesisVideoClient = useMemo(() => {
    return new AWS.KinesisVideo({
      region,
      accessKeyId,
      secretAccessKey,
      correctClockSkew: true,
    });
  }, []);

  // Free 계정 & 라이브뷰 제한까지 사용하면 Loading = false
  useEffect(() => {
    if (subscriptionInfo?.servicePlanName === "free") {
      if (usageInfo && usageInfo.liveViewDayUsage >= usageInfo.LimitLiveView) {
        setLoading(false);
      }
    }
  }, [subscriptionInfo?.servicePlanName, usageInfo]);

  useEffect(() => {
    if (camera && isPublic) {
      // 공유된 카메라의 share_audio가 on이 아니면 mute시키고 volume controller disable
      if (camera.share_audio !== "on") {
        setVolume(0);
        setDisableVolume(true);
      }
    }
  }, [camera, isPublic]);

  useEffect(() => {
    return () => {
      if (camera && usedTimeRef.current > 0) {
        dispatch(
          updateLiveviewUsage({
            psn: camera.psn,
            usage: Math.round(usedTimeRef.current),
          })
        );
      }
    };
  }, [camera, dispatch]);

  useEffect(() => {
    const updateUsedTime = () => {
      usedTimeRef.current = usedTimeRef.current + 0.2;
      if (usageInfo && usageInfo.LimitLiveView > 0 && camera) {
        if (
          usageInfo.liveViewDayUsage + usedTimeRef.current >=
          usageInfo.LimitLiveView
        ) {
          remoteView.current?.pause();
          hlsInst?.stopLoad();
          // hlsInst?.detachMedia();
          // setHlsInst(undefined);
          setPlaying(false);
          setLoading(false);
          if (usedTimeRef.current > 0) {
            dispatch(
              updateLiveviewUsage({
                psn: camera.psn,
                usage: Math.round(usedTimeRef.current),
              })
            );
          }
          usedTimeRef.current = 0;
        }
      }
    };
    if (playing) {
      const timer = setInterval(updateUsedTime, 200);
      return () => {
        clearInterval(timer);
      };
    }
  }, [camera, dispatch, hlsInst, playing, usageInfo]);

  useEffect(() => {
    const changeDirection = async (cam: ICameraInfo, cdir: 1 | 2 | 3) => {
      const server_name = getLbURI(cam.lb_server_name, cam.lb_http_port);
      try {
        await axios.get(
          `${server_name}/proc/live_direction?email=${email}&user_token=${loginInfo?.user_token}&psn=${cam.psn}&direction=${cdir}&tokenType=web`
        );
        prevDirectionRef.current = direction;
        onChannelChange(direction);
      } catch (err) {
        if (err.response?.status === 421) {
          dispatch(openToast({ message: "Camera is unplugged" }));
        } else {
          dispatch(openToast({ message: "Please check the connection_" }));
        }
        onChannelChange(prevDirectionRef.current);
      }
    };
    if (camera && prevDirectionRef.current !== direction) {
      console.log("requested");
      changeDirection(camera, direction);
    }
  }, [
    camera,
    direction,
    dispatch,
    email,
    loginInfo?.user_token,
    onChannelChange,
  ]);

  useEffect(() => {
    function exitHandler() {
      //@ts-ignore
      setFullscreen(screenfull.isFullscreen);
      setOpenTooltip(false);
    }
    if (screenfull.isEnabled) {
      screenfull.on("change", exitHandler);

      return () => {
        //@ts-ignore
        screenfull.off("change", exitHandler);
      };
    }
  }, []);

  const directionMenuMarkup = useMemo(() => {
    if (camera) {
      return _.compact([
        <WebMenuItem
          key="direction-front"
          startIcon={
            !mobile && direction === Front && <CheckIcon fontSize="small" />
          }
          endIcon={
            mobile && direction === Front && <CheckIcon fontSize="small" />
          }
          className={clsx({
            [classes.uncheckedMenu]: direction !== Front && !mobile,
            [classes.mobileMemuItem]: mobile,
          })}
          onClick={() => {
            setOpenMenu(false);
            onChannelChange(Front);
          }}
        >
          {t("Front")}
        </WebMenuItem>,
        _.includes([...MODELS_2CH, ...MODELS_3CH], camera.model) && (
          <WebMenuItem
            key="direction-rear"
            startIcon={
              !mobile && direction === Rear && <CheckIcon fontSize="small" />
            }
            endIcon={
              mobile && direction === Rear && <CheckIcon fontSize="small" />
            }
            className={clsx({
              [classes.uncheckedMenu]: direction !== Rear && !mobile,
              [classes.mobileMemuItem]: mobile,
            })}
            onClick={() => {
              setOpenMenu(false);
              onChannelChange(Rear);
            }}
          >
            {t("Rear")}
          </WebMenuItem>
        ),
        _.includes(MODELS_3CH, camera.model) && (
          <WebMenuItem
            key="direction-interior"
            startIcon={
              !mobile &&
              direction === Interior && <CheckIcon fontSize="small" />
            }
            endIcon={
              mobile && direction === Interior && <CheckIcon fontSize="small" />
            }
            className={clsx({
              [classes.uncheckedMenu]: direction !== Interior && !mobile,
              [classes.mobileMemuItem]: mobile,
            })}
            onClick={() => {
              setOpenMenu(false);
              onChannelChange(Interior);
            }}
          >
            {t("Interior")}
          </WebMenuItem>
        ),
      ]);
    }
  }, [
    camera,
    classes.mobileMemuItem,
    classes.uncheckedMenu,
    direction,
    mobile,
    onChannelChange,
    t,
  ]);

  const handleFullscreen = () => {
    if (fullScreenRef.current) {
      if (isFullscreen(fullScreenRef.current)) {
        setFullscreen(false);
        exitFullscreen(fullScreenRef.current);
        setOpenTooltip(false);
      } else {
        requestFullscreen(fullScreenRef.current);
        setFullscreen(true);
      }
    }
  };

  useEffect(() => {
    if (remoteView.current) {
      if (multiView) {
        if (selected) {
          remoteView.current.volume = volume / 100;
        } else {
          remoteView.current.volume = 0;
        }
      } else {
        remoteView.current.volume = volume / 100;
      }
    }
    console.log("clicked", selected, volume, remoteView.current?.volume);
  }, [multiView, selected, volume]);

  useEffect(() => {
    if (multiView) {
      setVolume(0);
      setPreVolume(50);
    }
  }, [multiView]);

  const startPlay = useCallback(async () => {
    if (camera && remoteView.current && email && loginInfo && !disabled) {
      const server_name = getLbURI(camera.lb_server_name, camera.lb_http_port);
      const videoSrc = `${server_name}/proc/hls_live.m3u8?email=${email}&user_token=${loginInfo.user_token}&tokenType=web&psn=${camera.psn}&direction=${dir}`;
      const video = remoteView.current;

      if (Hls.isSupported()) {
        var hls = new Hls();
        setHlsInst(hls);
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        const match = /\/hls\/[\w-]{5,}\/\d+.ts/g;
        try {
          _.reduce(
            _.range(0, 20),
            async (prevPromise, indx) => {
              try {
                await prevPromise;
                return new Promise(async (resolve, reject) => {
                  const resp = await axios.get(videoSrc);
                  const matched = match.exec(resp.data);
                  if (!matched) {
                    setTimeout(() => resolve(), 1000);
                  } else {
                    video.src = videoSrc;

                    video.addEventListener("loadedmetadata", function () {
                      video.play();
                    });
                    reject();
                  }
                });
              } catch (err) {
                return Promise.reject();
              }
            },
            Promise.resolve()
          );
        } catch (err) {
          console.log("err", err);
        }
      }
      video.addEventListener("waiting", function () {
        // console.log("waiting");
        setLoading(true);
        setPlaying(false);
      });
      video.addEventListener("playing", function () {
        setLoading(false);
        // console.log("playing");
        setPlaying(true);
      });
    }
  }, [camera, email, loginInfo, dir, disabled]);

  const getEndpoints = useCallback(
    async (kvsVideo: AWS.KinesisVideo, channelName: string) => {
      // Get signaling channel ARN
      const describeSignalingChannelResponse = await kvsVideo
        .describeSignalingChannel({
          ChannelName: channelName,
        })
        .promise();
      if (!describeSignalingChannelResponse.ChannelInfo) return;
      const channelARN =
        describeSignalingChannelResponse.ChannelInfo.ChannelARN;

      console.log("[VIEWER] Channel ARN: ", channelARN);
      if (!channelARN) return;

      const getSignalingChannelEndpointResponse = await kvsVideo
        .getSignalingChannelEndpoint({
          ChannelARN: channelARN,
          SingleMasterChannelEndpointConfiguration: {
            Protocols: ["WSS", "HTTPS"],
            Role: KVSWebRTC.Role.VIEWER,
          },
        })
        .promise();

      const endpointsByProtocol = _.reduce<
        AWS.KinesisVideo.ResourceEndpointListItem,
        { [key: string]: string }
      >(
        getSignalingChannelEndpointResponse.ResourceEndpointList,
        (endpoints, endpoint) => {
          if (endpoint.Protocol && endpoint.ResourceEndpoint) {
            endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          }
          return endpoints;
        },
        {}
      );
      console.log("[VIEWER] Endpoints: ", endpointsByProtocol);

      const kinesisVideoSignalingChannelsClient =
        new AWS.KinesisVideoSignalingChannels({
          region,
          accessKeyId,
          secretAccessKey,
          endpoint: endpointsByProtocol.HTTPS,
          correctClockSkew: true,
        });

      const getIceServerConfigResponse =
        await kinesisVideoSignalingChannelsClient
          .getIceServerConfig({
            ChannelARN: channelARN,
          })
          .promise();

      const iceServers: RTCIceServer[] = [
        // { urls: [`stun:stun.kinesisvideo.${region}.amazonaws.com:443`] },
      ];

      _.forEach(getIceServerConfigResponse.IceServerList, (iceServer) => {
        if (iceServer.Uris && iceServer.Username && iceServer.Password) {
          iceServers.push({
            urls: iceServer.Uris,
            username: iceServer.Username,
            credential: iceServer.Password,
          });
        }
      });

      console.log("[VIEWER] ICE servers: ", iceServers);
      signalingClient.current = new KVSWebRTC.SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        role: KVSWebRTC.Role.VIEWER,
        region,
        clientId: Math.random().toString(36).substring(2).toUpperCase(),
        credentials: {
          accessKeyId,
          secretAccessKey,
        },
        systemClockOffset: kvsVideo.config.systemClockOffset,
      });

      peerConnection.current = new RTCPeerConnection({
        iceServers,
        iceTransportPolicy: "relay",
      });

      // Once the signaling channel connection is open, connect to the webcam and create an offer to send to the master
      signalingClient.current?.on("open", async () => {
        console.log("[VIEWER] Connected to signaling service");
        // Get a stream from the webcam, add it to the peer connection, and display it in the local view
        try {
          localStream.current = await navigator.mediaDevices.getUserMedia({
            // video: { width: { ideal: 640 }, height: { ideal: 480 } },
            video: false,
            audio: true,
          });
          localStream.current?.getTracks().forEach((track: any) => {
            if (localStream.current) {
              peerConnection.current?.addTrack(track, localStream.current);
            }
          });

          if (localView.current) {
            localView.current.srcObject = localStream.current;
          }
        } catch (e) {
          // Could not find webcam
          console.error("[VIEWER] Could not find webcam");
          return;
        }

        console.log("[VIEWER] Creating SDP offer");
        // Create an SDP offer and send it to the master
        const offer = await peerConnection.current?.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        });
        await peerConnection.current?.setLocalDescription(offer);
        if (peerConnection.current?.localDescription) {
          console.log("[VIEWER] Sending SDP offer");
          signalingClient.current?.sendSdpOffer(
            peerConnection.current.localDescription
          );
        }
        console.log("[VIEWER] Generating ICE candidates");
      });

      // When the SDP answer is received back from the master, add it to the peer connection.
      signalingClient.current?.on("sdpAnswer", async (answer) => {
        console.log("[VIEWER] Received SDP answer");
        await peerConnection.current?.setRemoteDescription(answer);
      });

      // When an ICE candidate is received from the master, add it to the peer connection.
      signalingClient.current?.on("iceCandidate", (candidate) => {
        console.log("[VIEWER] Received ICE candidate");
        peerConnection.current?.addIceCandidate(candidate);
      });

      signalingClient.current?.on("close", () => {
        console.log("[VIEWER] Disconnected from signaling channel");
        // Handle client closures
      });

      signalingClient.current?.on("error", (error) => {
        console.error("[VIEWER] Signaling client error: ", error);
        // Handle client errors
      });
      // Send any ICE candidates generated by the peer connection to the other peer
      peerConnection.current?.addEventListener(
        "icecandidate",
        ({ candidate }) => {
          if (candidate) {
            console.log("[VIEWER] Generated ICE candidate");
            console.log("[VIEWER] Sending ICE candidate");
            signalingClient.current?.sendIceCandidate(candidate);
          } else {
            // No more ICE candidates will be generated

            console.log("[VIEWER] All ICE candidates have been generated");
            if (peerConnection.current?.localDescription) {
              console.log("[VIEWER] Sending SDP offer");
              signalingClient.current?.sendSdpOffer(
                peerConnection.current.localDescription
              );
            }
          }
        }
      );

      // As remote tracks are received, add them to the remote view
      peerConnection.current?.addEventListener("track", (event) => {
        console.log("[VIEWER] Received remote track");
        if (remoteView.current && !remoteView.current.srcObject) {
          remoteStream.current = event.streams[0];
          remoteView.current.srcObject = event.streams[0];
        }
      });
      // setEndpointsByProtocol(result);
      console.log("[VIEWER] Starting viewer connection");
      signalingClient.current?.open();
    },
    []
  );

  const startCNPlay = useCallback(async () => {
    try {
      console.log(
        "play",
        camera?.psn,
        email,
        kinesisVideoClient,
        loginInfo?.user_token
      );
      const resp = await axios.post(`${API_GATEWAY_URI}/IoT/devicecommand`, {
        command: "PlayLiveView",
        email,
        user_token: loginInfo?.user_token,
        tokenType: "web",
        psn: camera?.psn,
      });
      let body = resp.data;
      if (typeof body === "string") {
        body = JSON.parse(body);
      }
      if (body.resultcode === "BC_ERR_OK") {
        const streamName = body.streamname;
        await sleep(3000);
        getEndpoints(kinesisVideoClient, streamName);
      }
      // getEndpoints(kinesisVideoClient, "75XPS3K1E00041_live");
      // console.log("startCNPlay", body);
    } catch (e: any) {
      console.error(e.message);
    }
  }, [
    camera?.psn,
    email,
    getEndpoints,
    kinesisVideoClient,
    loginInfo?.user_token,
  ]);

  useEffect(() => {
    const p = remoteView.current;
    const local = localView.current;
    const disconnectCNWebRTC = async () => {
      const resp = await axios.post(`${API_GATEWAY_URI}/IoT/devicecommand`, {
        command: "StopLiveView",
        email,
        user_token: loginInfo?.user_token,
        tokenType: "web",
        psn: camera?.psn,
      });
      console.log("disconnectCNWebRTC", resp.data);
    };
    return () => {
      p?.pause();
      // webrtc 정리
      if (signalingClient.current) {
        signalingClient.current.close();
        signalingClient.current = null;
        disconnectCNWebRTC();
      }

      if (peerConnection.current) {
        peerConnection.current.close();
        peerConnection.current = null;
      }

      if (localStream.current) {
        localStream.current.getTracks().forEach((track) => track.stop());
        localStream.current = null;
      }

      if (remoteStream.current) {
        remoteStream.current.getTracks().forEach((track) => track.stop());
        remoteStream.current = null;
      }

      if (local) {
        local.srcObject = null;
      }

      if (p) {
        p.srcObject = null;
      }
    };
  }, [camera?.psn, email, loginInfo?.user_token]);

  useEffect(() => {
    if (CLOUD_NATIVE) {
      startCNPlay();
    }
  }, [startCNPlay]);

  useEffect(() => {
    if (!CLOUD_NATIVE) {
      startPlay();
    }
  }, [startPlay]);

  useEffect(() => {
    if (hlsInst) {
      const hls = hlsInst;
      return () => {
        hls.destroy();
      };
    }
  }, [hlsInst]);

  const renderCtrlIcon = useCallback(
    (icon: React.ReactNode, onClick?: () => void, disabled?: boolean) => {
      return disabled ? (
        <span className={classes.disabledCtrlIcon}>{icon}</span>
      ) : (
        <IconButton
          className={classes.ctrlIcon}
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
          }}
        >
          {icon}
        </IconButton>
      );
    },
    [classes.ctrlIcon, classes.disabledCtrlIcon]
  );

  return (
    <div ref={videoRef} className={classes.root}>
      <div
        ref={fullScreenRef}
        className={clsx(classes.videoDiv, {
          [classes.multiViewDiv]: multiView,
          [classes.videoFullScreen]: fullscreen,
        })}
      >
        <div
          className={clsx(classes.video, {
            [classes.videoHFlip]: !vFlip && hFlip,
            [classes.videoVFlip]: vFlip && !hFlip,
            [classes.videoFlip]: vFlip && hFlip,
          })}
        >
          <video
            style={{ width: "100%", height: "100%" }}
            ref={remoteView}
            className="video-js"
            // playsInline
            // webkit-playsinline
            autoPlay
            playsInline
            // controls
            muted
            onPlay={() => setLoading(false)}
            onError={(e) => console.log("video error", e)}
          />
          <video
            ref={localView}
            autoPlay
            playsInline
            controls
            muted
            style={{ width: 0, height: 0 }}
          />
        </div>
        <div
          className={clsx(classes.overlayDiv, {
            [classes.selectedOverlay]: selected && !fullscreen,
          })}
        >
          {(multiView || fullscreen) && camera && (
            <div className={clsx(classes.menuDiv)}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={
                  main && !fullscreen ? LightColors.secondary["17"] : undefined
                }
              >
                {camera && `${camera.dev_name} (${camera.model})`}
                {main && !fullscreen && ` · ${t("Main")}`}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!isPublic && (
                  <div
                    className={clsx(classes.dirDiv)}
                    ref={menuAnchorRef}
                    onClick={() => setOpenMenu(true)}
                  >
                    <Typography category="Default" variant="BodyBold">
                      {t(getDirection(direction))}
                    </Typography>
                    <ExpandMoreIcon fontSize="small" />
                  </div>
                )}
                {!main && !fullscreen && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </div>
          )}
        </div>
        {loading && (
          <div className={classes.video}>
            <CircularProgress
              className={classes.circularLoading}
              size={48}
              thickness={5}
            />
          </div>
        )}

        {refresh && (
          <div className={classes.mobilePlayCtrlDiv}>
            <div className={classes.mobilePlayCtrl}>
              {renderCtrlIcon(
                <RefreshIcon style={{ fontSize: "3rem" }} />,
                startPlay
              )}
            </div>
          </div>
        )}
        <div className={classes.ctrlContDiv}>
          <div className={classes.ctrlDiv}>
            {!mobile && (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onMouseEnter={() => !disableVolume && setOpenVolumeMenu(true)}
                onMouseLeave={() => !disableVolume && setOpenVolumeMenu(false)}
              >
                {volume === 0 ? (
                  <Tooltip
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("UnMute")}
                      </Typography>
                    }
                    placement="top"
                  >
                    <IconButton
                      className={classes.ctrlIcon}
                      ref={volumeAnchorRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        setVolume(preVolume);
                      }}
                    >
                      <VolumeOffIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("Mute")}
                      </Typography>
                    }
                    placement="top"
                  >
                    <IconButton
                      className={classes.ctrlIcon}
                      ref={volumeAnchorRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPreVolume(volume);
                        setVolume(0);
                      }}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {openVolumeMenu && (
                  <div className={classes.volumeCtrlDiv}>
                    <VolumeSlider
                      value={volume}
                      onChange={(e, newVal) => setVolume(newVal as number)}
                    />
                  </div>
                )}
              </div>
            )}
            {mobile && <div></div>}
            <div>
              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {t("Flip")}
                  </Typography>
                }
                placement="top"
              >
                <IconButton
                  className={classes.ctrlIcon}
                  ref={flipAnchorRef}
                  onClick={() => setOpenFlipMenu((f) => !f)}
                >
                  <FlipIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                disableTouchListener={mobile}
                open={openTooltip}
                onOpen={() => setOpenTooltip(true)}
                onClose={() => setOpenTooltip(false)}
                title={
                  <Typography category="Default" variant="Caption">
                    {fullscreen ? t("Exit fullscreen") : t("Fullscreen")}
                  </Typography>
                }
                placement="top"
              >
                <IconButton
                  className={classes.ctrlIcon}
                  onClick={handleFullscreen}
                >
                  {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>

        {!main && !camera && (
          <div className={classes.addCameraDiv}>
            <div
              className={classes.addCameraBtn}
              onClick={() => onAddCamera?.()}
            >
              <AddCircleOutlineIcon style={{ marginBottom: 8 }} />
              <Typography category="Default" variant="Body">
                {t("Add camera")}
              </Typography>
            </div>
          </div>
        )}
        {mobile && (
          <MobileMenu
            open={openFlipMenu}
            onClose={() => setOpenFlipMenu(false)}
            container={fullScreenRef.current}
          >
            <WebMenuItem
              className={clsx(classes.mobileMemuItem)}
              onClick={() => {
                setOpenFlipMenu(false);
                setHFlip((f) => !f);
              }}
            >
              {t("Flip horizontally")}
            </WebMenuItem>

            <WebMenuItem
              className={clsx(classes.mobileMemuItem)}
              onClick={() => {
                setOpenFlipMenu(false);
                setVFlip((f) => !f);
              }}
            >
              {t("Flip vertically")}
            </WebMenuItem>
          </MobileMenu>
        )}
        {!mobile && (
          <Menu
            style={{ zIndex: 2147483647 }}
            paperClasses={{ root: classes.playerMenuDiv }}
            open={openFlipMenu}
            anchorEl={flipAnchorRef.current}
            onClickAway={() => setOpenFlipMenu(false)}
            placement="top"
          >
            <WebMenuItem
              className={clsx(classes.webMenuDiv)}
              onClick={() => {
                setOpenFlipMenu(false);
                setHFlip((f) => !f);
              }}
            >
              {t("Flip horizontally")}
            </WebMenuItem>

            <WebMenuItem
              className={clsx(classes.webMenuDiv)}
              onClick={() => {
                setOpenFlipMenu(false);
                setVFlip((f) => !f);
              }}
            >
              {t("Flip vertically")}
            </WebMenuItem>
          </Menu>
        )}
        {mobile && (
          <MobileMenu
            open={openMenu}
            container={fullScreenRef.current}
            onClose={() => setOpenMenu(false)}
          >
            {directionMenuMarkup}
          </MobileMenu>
        )}
        {!mobile && (
          <Menu
            style={{ zIndex: 2147483647 }}
            open={openMenu}
            anchorEl={menuAnchorRef?.current}
            onClickAway={() => setOpenMenu(false)}
            placement="bottom-end"
          >
            {directionMenuMarkup}
          </Menu>
        )}
      </div>
    </div>
  );
};
