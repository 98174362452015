import React from "react";
import { CameraRouter } from "./CameraRouter";
import { AccountRouter } from "./AccountRouter";
import { GroupRouter } from "./GroupRouter";
import { MemberRouter } from "./MemberRouter";
import { GeofenceRouter } from "./GeofenceRouter";
import { GPSTrackingRouter } from "./GPSTrackingRouter";
import { ReportRouter } from "./ReportRouter";
import AuthorizedRoute from "../components/AuthorizedRoute";
import withViewerTemplate from "../hoc/withViewerTemplate";

const WebviewerRouter = withViewerTemplate((props: any) => {
  return (
    <>
      <AuthorizedRoute path="/cameras">
        <CameraRouter {...props} />
      </AuthorizedRoute>
      <AuthorizedRoute path="/members">
        <MemberRouter {...props} />
      </AuthorizedRoute>
      <AuthorizedRoute path="/account">
        <AccountRouter {...props} />
      </AuthorizedRoute>
      <AuthorizedRoute path="/groups">
        <GroupRouter {...props} />
      </AuthorizedRoute>
      <AuthorizedRoute path="/geofences">
        <GeofenceRouter {...props} />
      </AuthorizedRoute>
      <AuthorizedRoute path="/gps-tracking">
        <GPSTrackingRouter {...props} />
      </AuthorizedRoute>
      <AuthorizedRoute path="/reports">
        <ReportRouter {...props} />
      </AuthorizedRoute>
    </>
  );
});

export default WebviewerRouter;
