import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
} from "redux-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TFunction } from "i18next";
import * as yup from "yup";
import { IChangeNameForm } from "../types";
import { Webviewer } from "../contants/Breakpoints";
import validator from "./validator";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { RootState } from "../features/store";
import _ from "lodash";
import { USER } from "../features/User/slice";
import { FormHelperText } from "@material-ui/core";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(1, "The name must_")
    .max(30, "The name must_")
    .test("unallowed-check", "Unallowed character detected", (val) => {
      return (
        (val?.indexOf('"') ?? -1) === -1 && (val?.indexOf("\\") ?? -1) === -1
      );
    }),
  lastName: yup
    .string()
    .trim()
    .min(1, "The name must_")
    .max(30, "The name must_")
    .test("unallowed-check", "Unallowed character detected", (val) => {
      return (
        (val?.indexOf('"') ?? -1) === -1 && (val?.indexOf("\\") ?? -1) === -1
      );
    }),
});

interface FieldProps {
  label?: string;
  value?: string;
  helperText?: string;
  t: TFunction;
}

const renderEmailField = ({
  label,
  input,
  helperText,
  meta: { touched, invalid, error },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  return (
    <Input label={label} error={touched && error} {...input} {...custom} />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formDiv: {
    width: 352,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: 288,
    },
  },
  marginB21: {
    marginBottom: theme.spacing(3),
    "&:last-child": {
      marginBottom: theme.spacing(2),
    },
  },
  cntDiv: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "3px 16px 24px 16px",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginTop: "4px",
    },
  },
  errStyle: {
    display: "flex",
    alignItems: "center",
  },
}));

const ChangeName = (props: InjectedFormProps<IChangeNameForm, {}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const [firstError, setFirstError] = useState<string>();
  const [lastError, setLastError] = useState<string>();
  const { userProfile } = useSelector((state: RootState) => state[USER]);
  const [firstName, setFirstName] = useState(userProfile?.firstName ?? "");
  const [lastName, setLastName] = useState(userProfile?.lastName ?? "");

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("ChangeNameForm"));
    }
  };

  const handleFirstNameChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setFirstName(value);
    let error: { [key: string]: string } = {};
    try {
      await schema.validate({ firstName: value }, { abortEarly: false });
    } catch (err) {
      error = _.reduce(
        err.inner,
        (r, i) => ({ ...r, [i.path]: i.message }),
        {} as { [key: string]: string }
      );
    } finally {
      setFirstError(error["firstName"]);
    }
  };
  const handleLastNameChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setLastName(value);
    let error: { [key: string]: string } = {};
    try {
      await schema.validate({ lastName: value }, { abortEarly: false });
    } catch (err) {
      error = _.reduce(
        err.inner,
        (r, i) => ({ ...r, [i.path]: i.message }),
        {} as { [key: string]: string }
      );
    } finally {
      setLastError(error["lastName"]);
    }
  };

  return (
    <form
      className={classes.formDiv}
      onSubmit={handleSubmit}
      onKeyDown={handleEnterkey}
    >
      <div>
        <Field
          name="firstName"
          label={t("First name")}
          onChange={handleFirstNameChange}
          component={renderEmailField}
          value={firstName}
          error={!!firstError}
          autoFocus
          t={t}
        />
      </div>
      <div
        className={classes.cntDiv}
        style={{ justifyContent: firstError && "space-between" }}
      >
        {firstError && (
          <FormHelperText
            style={{
              color: LightColors.secondary["11"],
              marginTop: 0,
            }}
          >
            {t(firstError)}
          </FormHelperText>
        )}
        <Typography
          category="Default"
          variant="Caption"
          htmlColor={LightColors.primary["2"]}
          className={classes.errStyle}
        >
          {firstName.length}/30
        </Typography>
      </div>
      <div>
        <Field
          name="lastName"
          label={t("Last name")}
          component={renderEmailField}
          onChange={handleLastNameChange}
          value={lastName}
          error={!!lastError}
          t={t}
        />
      </div>
      <div
        className={classes.cntDiv}
        style={{ justifyContent: lastError && "space-between" }}
      >
        {lastError && (
          <FormHelperText
            style={{
              color: LightColors.secondary["11"],
              marginTop: 0,
            }}
          >
            {t(lastError)}
          </FormHelperText>
        )}
        <Typography
          category="Default"
          variant="Caption"
          htmlColor={LightColors.primary["2"]}
          className={classes.errStyle}
        >
          {lastName.length}/30
        </Typography>
      </div>
    </form>
  );
};

const ChangeNameForm = reduxForm<IChangeNameForm, {}>({
  form: "ChangeNameForm",
  asyncValidate: validator(schema),
})(ChangeName);

export default ChangeNameForm;
