import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import React from "react";
import { ReportPanel } from "../components/cameras/ReportPanel";
import { Webviewer } from "../contants/Breakpoints";
import { PermissionProps, ScreenDefaultProps } from "../hoc/withViewerTemplate";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: (props: ScreenDefaultProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px)"
        : "calc(var(--vh, 1vh) * 100 - 56px)",
    marginTop: (props: ScreenDefaultProps) => (props.error ? 127 : 56),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
    },
  },
  body: {
    // padding: theme.spacing(0, 4),
    marginBottom: 0,
    overflowY: "auto",
  },
}));

export const ReportScreen = (props: ScreenDefaultProps & PermissionProps) => {
  const classes = useStyles(props);
  const theme = useTheme() as Theme;

  return (
    <div className={classes.root} dir={theme.direction}>
      <div className={classes.body}>
        <ReportPanel selectCam {...props} />
      </div>
    </div>
  );
};
