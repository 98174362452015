import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { UpgradePlanPanel } from "../components/UpgradePlanPanel";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { GPSTrackingScreen } from "../screens/GPSTrackingScreen";

export const GPSTrackingRouter = (props: ScreenDefaultProps) => {
  const { plan } = props;
  const { path } = useRouteMatch();

  if (plan === "Fleet plan") {
    return (
      <Switch>
        <Route
          exact
          path={path}
          render={() => <GPSTrackingScreen {...props} />}
        />
      </Switch>
    );
  }

  if (plan === "Free plan" || plan === "Basic plan" || plan === "Smart plan") {
    return <UpgradePlanPanel plan={plan} />;
  }
  return <div></div>;
};
