import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import React from "react";
import { GPSTrackingPanel } from "../components/gps-tracking/GPSTrackingPanel";
import { Webviewer } from "../contants/Breakpoints";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(var(--vh, 1vh) * 100 - 56px)",
    marginTop: (props: ScreenDefaultProps) => (props.error ? 127 : 56),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: (props: ScreenDefaultProps) =>
        props.error
          ? "calc(var(--vh, 1vh) * 100 - 85px - 16px)"
          : "calc(var(--vh, 1vh) * 100 - 56px)",

      marginTop: (props: ScreenDefaultProps) => (props.error ? 85 + 16 : 56),
    },
  },
}));

export const GPSTrackingScreen = (props: ScreenDefaultProps) => {
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  return (
    <div className={classes.root} dir={theme.direction}>
      <GPSTrackingPanel {...props} mode="fleet" />
    </div>
  );
};
