import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  LightColors,
  Typography,
  CameraInfo,
  DashcamInvalid,
  DashcamConnectivity,
  GPSTracking,
  CheckBox,
  LiveView,
  Button,
  Modal,
  Tooltip,
  CellularStrong,
  CellularWeak,
  ConnectToCloud,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import WifiIcon from "@material-ui/icons/Wifi";
import GroupIcon from "@material-ui/icons/Group";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from "@material-ui/icons/Warning";

import { EmptyItems } from "../EmptyItems";
import { RootState } from "../../features/store";
import { CAMERA, ICameraInfo, loadCameras } from "../../features/Camera/slice";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { MobileCameraMenu } from "../MobileCameraMenu";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { Webviewer } from "../../contants/Breakpoints";
import { CameraMenu } from "./CameraMenu";
import { GROUP } from "../../features/Group/slice";
import {
  EVENT,
  ILatestEvent,
  loadLatestEvent,
} from "../../features/Event/slice";
import { LatestEvent } from "./LatestEvent";
import { VideoPlayerModal } from "./VideoPlayerModal";
import { PermissionProps } from "../../hoc/withViewerTemplate";
import { NoResults } from "../NoResults";
import { PAYMENT } from "../../features/Payment/slice";
import { getPlanFromServiceID } from "../../utils/Service";
import { MODEL_KEY, SUPPORT_REBOOT_FW } from "../../contants/Firmwares";
import "simplebar/src/simplebar.css";
import { ScrollBar } from "../ScrollBar";
import { PSN650 } from "../../contants/Models";
import { loadUsageInfo, USER } from "../../features/User/slice";
import { ExceedModal } from "../modals/ExceedModal";
import { isFree100Check } from "../../utils/isFree100Check";
import moment from "moment";
import { CloudConnectionSettings } from "../../contants/Links";
import { SIMCARD_URI } from "../../contants/Server";
import { AvailableFw, AvailableFws } from "../../types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create(["width", "height"]),
    backgroundColor: LightColors.primary["0"],
    borderRadius: theme.spacing(0.5),
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
  },
  rootOpen: {
    width: 320,
    height: "100%",
  },
  rootClose: {
    width: 0,
  },
  rootMobile: {
    width: "100%",
  },
  header: {
    display: "flex",
    minHeight: 56,
    padding: theme.spacing(0, 1.5, 0, 0.75),
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
  },
  headerOpen: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  headerSearch: {
    zIndex: 100,
  },
  iconBtn: {
    padding: 0,
    color: LightColors.primary["3"],
    marginRight: theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
  },
  noCameraDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 165,
    },
  },
  camDiv: {
    height: "calc(100% - 56px)",
    padding: theme.spacing(1, 0),
    overflowY: "auto",
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  listPane: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    transition: theme.transitions.create("transform"),
  },
  rootPanel: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    transition: theme.transitions.create("transform"),
    position: "relative",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  listDivHide: {
    transform: "translateX(-100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-320px)",
    },
  },
  detailDivHide: {
    transform: "translateX(100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(320px)",
    },
  },
  icon: {
    fontSize: 18,
    display: "block",
  },
  smallIcon: {
    fontSize: 12,
    display: "block",
    padding: theme.spacing(0.25, 0.375),
    width: 18,
    height: 16,
    marginRight: theme.spacing(1),
  },
  iconActive: {
    fill: LightColors.primary["7"],
  },
  iconInactive: {
    fill: LightColors.primary["3"],
  },
  lteIcon: {
    display: "block",
    fontSize: 18,
  },
  detailDiv: {
    margin: theme.spacing(2, 0),
  },
  detailLineDiv: {
    display: "flex",
    alignItems: "center",
    margin:
      theme.direction === "rtl"
        ? theme.spacing(0, 0, 1, 2)
        : theme.spacing(0, 2, 1, 0),
    "&:last-child": {
      marginBottom: 0,
    },
    overflowWrap: "anywhere",
  },
  detailIconDiv: {
    display: "flex",
    justifyContent: "center",
    minWidth: theme.spacing(7),
  },
  link: {
    cursor: "pointer",
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
    "&:visited": {
      color: LightColors.primary["7"],
    },
  },
  disableLink: {
    "&:hover": {
      color: LightColors.primary["7"],
    },
  },
  menuTitleDiv: {
    minHeight: 56,
    flex: 1,
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  menuLineDiv: {
    display: "flex",
    alignItems: "center",
    minHeight: theme.spacing(7),
    cursor: "pointer",
  },
  disableMenuLineDiv: {
    opacity: 0.35,
    cursor: "not-allowed",
    "& span": {
      color: LightColors.primary["3"],
    },
    "&:hover span": {
      color: LightColors.primary["3"],
    },
  },
  menuDiv: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  backBtn: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  screenIcon: {
    display: "block",
  },
  screenBtnDiv: {
    width: 75,
    // height: 72,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    "user-select": "none",
    /* Non-prefixed version, currently
       supported by Chrome, Edge, Opera and Firefox */
    "&:hover div": {
      backgroundColor: LightColors.primary["10"],
    },
    "&:active div": {
      backgroundColor: LightColors.primary["9"],
    },
    color: LightColors.primary["3"],
    "&:hover": {
      color: LightColors.primary["8"],
    },
  },
  disableScreenBtnDiv: {
    cursor: "auto",
    opacity: 0.35,
    "&:hover div": {
      backgroundColor: LightColors.primary["0"],
    },
    "&:active div": {
      backgroundColor: LightColors.primary["0"],
    },
    "&:hover": {
      color: LightColors.primary["3"],
    },
  },
  btnIconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 48,
    height: 48,
    borderRadius: "50%",
    border: `1px solid ${LightColors.primary["7"]}`,
    marginBottom: theme.spacing(1),
  },
  screenMenuDiv: {
    display: "flex",
    padding: theme.spacing(2, 0.5),
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    justifyContent: "space-between",
  },
  eventListDiv: {
    padding:
      theme.direction === "rtl"
        ? theme.spacing(2, 2, 2, 0)
        : theme.spacing(2, 0, 2, 2),
  },
  newDiv: {
    backgroundColor: LightColors.secondary["11"],
    borderRadius: 12,
    width: 43,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
  delete: {
    color: LightColors.secondary["11"],
  },
  deleteIcon: {
    display: "block",
    fill: LightColors.primary["1"],
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    justifyContent: "flex-start",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      justifyContent: "flex-end",
    },
    backgroundColor: "white",
  },
  searchBtnOpen: {
    width: "100%",
    marginRight: 0,
  },
  groupDiv: {
    display: "flex",
    padding: theme.spacing(0.75, 0, 0.25, 0),
    margin: theme.spacing(0.5, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  filterGroupDiv: {
    padding: theme.spacing(0.75, 2),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: LightColors.primary["10"],
    },
  },
  filterCamCntDiv: {
    margin: theme.spacing(0, 3.25),
  },
  formDiv: {
    margin: theme.spacing(0, -0.5),
  },
  cameraTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  cameraTitleWrap: {
    zIndex: 1,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    transition: theme.transitions.create("width"),
    ...(theme.direction === "rtl"
      ? { paddingRight: theme.spacing(1.25) }
      : { paddingLeft: theme.spacing(1.25) }),

    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(5.5) }
      : { marginRight: theme.spacing(5.5) }),
  },
  enter: {
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  icon30px: {
    fontSize: 30,
  },
  eventBorderDiv: {
    marginLeft: theme.spacing(5),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  noResultDiv: {
    marginTop: "36vh",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: "29.5vh",
    },
  },
  filterBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    "& button:first-child": {
      margin: theme.spacing(0, 2),
    },
  },
  activeOffDiv: {
    margin: theme.spacing(2, 2, 0),
    backgroundColor: LightColors.secondary["18"],
    borderRadius: 4,
    padding: theme.spacing(1),
    display: "flex",
  },
  modalTitle: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  modalContent: {
    padding: theme.spacing(1.5, 3),
  },
  modalWrap: {
    margin: theme.spacing(2),
  },
  errorModalWrap: {
    margin: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 429,
    },
  },
  errorModalAction: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 2.625, 3),
    },
  },
  searchDiv: {
    position: "absolute",
    ...(theme.direction === "rtl" ? { left: 16 } : { right: 16 }),
    backgroundColor: LightColors.primary["0"],
  },
  searchDivOpen: {
    zIndex: 2,
    width: "calc(100% - 29px)",
    height: "100%",
    display: "flex",
    alignContent: "center",
  },
  cloudConnectModalDiv: {
    margin: 0,
  },
  cloudConnectAppDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 2,
      marginTop: 21,
    },
  },
  appDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
    },
  },
  appStoreDiv: {
    margin: theme.spacing(3, 0, 3.5, 1.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
      marginBottom: theme.spacing(6.5),
    },
  },
  imgCSSDiv: {
    margin: theme.spacing(5.375, 0, 6, 1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(6.125, 0, 6, 0),
    },
  },
  bodyHeight: {
    flex: 1,
  },
  connectModalContent: {
    padding: theme.spacing(2, 2.75),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.375, 0, 3.25, 3),
    },
  },
}));

interface CameraListPanelProps {
  open: boolean;
  mobile?: boolean;
  className?: string;
  onRename?: (camera: ICameraInfo) => void;
  onSettings?: (camera: ICameraInfo) => void;
  onUpdate?: (camera: ICameraInfo, fw?: AvailableFw) => void;
  onDelete?: (camera: ICameraInfo) => void;
  onRebootCamera?: (camera: ICameraInfo) => void;
  onClick?: (camera: ICameraInfo | undefined) => void;
  onUpdateFilteredCams?: (cameras: ICameraInfo[]) => void;
  availableNewFws: AvailableFws;
}

export const CameraListPanel = (
  props: CameraListPanelProps & PermissionProps
) => {
  const {
    open,
    className,
    mobile,
    onRename,
    onSettings,
    onUpdate,
    onDelete,
    onRebootCamera,
    onClick,
    onUpdateFilteredCams,
    settingsCamPerm,
    fotaCamPerm,
    renameCamPerm,
    deleteCamPerm,
    gpsTrackingPerm,
    liveviewPerm,
    reportPerm,
    availableNewFws,
  } = props;
  // useTraceUpdate(props);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { latestEvents } = useSelector((state: RootState) => state[EVENT]);

  const { loading, cameraList, type } = useSelector(
    (state: RootState) => state[CAMERA]
  );
  const { groupsList } = useSelector((state: RootState) => state[GROUP]);
  const userProfile = useSelector(
    (state: RootState) => state[USER].userProfile
  );
  const buttonsRef = useRef<{ [key: string]: RefObject<HTMLButtonElement> }>(
    {}
  );

  const [anchorRef, setAnchorRef] = useState<
    RefObject<HTMLButtonElement> | undefined
  >();
  const [openSearch, setOpenSearch] = useState(false);
  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentCam, setCurrentCam] = useState<ICameraInfo>();
  const [currentEvent, setCurrentEvent] = useState<ILatestEvent>();
  // const [currentPSN, setCurrentPSN] = useState<string>();
  const [searchKey, setSearchKey] = useState("");
  const [moreCam, setMoreCam] = useState<ICameraInfo>();
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [openInvalidModal, setOpenInvalidModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [totalGroups, setTotalGroups] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [filterSelectedGroups, setFilterSelectedGroup] = useState<string[]>([]);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openExceedModal, setOpenExceedModal] = useState(false);
  const [openCloudConnectionModal, setOpenCloudConnectionModal] =
    useState(false);

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const userUsage = useSelector((state: RootState) => state[USER].usageInfo);

  useEffect(() => {
    dispatch(loadUsageInfo());
  }, [dispatch]);

  const free100Check = useMemo(() => {
    if (subscriptionInfo && userUsage) {
      return isFree100Check(subscriptionInfo, userUsage);
    }
  }, [subscriptionInfo, userUsage]);
  const { email, loginInfo } = useSelector((state: RootState) => state[USER]);

  useEffect(() => {
    const handleRefresh = () => {
      if (currentCam) {
        localStorage.setItem("currentCamPsn", currentCam.psn);
      }
    };
    window.addEventListener("beforeunload", handleRefresh);
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [currentCam]);

  useEffect(() => {
    const psn = localStorage.getItem("currentCamPsn");
    if (psn) {
      const found = _.find(
        cameraList?.DeviceListInfo,
        (dev) => dev.device.psn === psn
      )?.device;
      if (found) {
        setCurrentCam(found);

        localStorage.removeItem("currentCamPsn");
      }
    }
  }, [cameraList?.DeviceListInfo]);

  useEffect(() => {
    const grps = [
      "notInGroup",
      ..._.map(groupsList?.DashCamList, (g) => g.GroupID),
    ];
    setTotalGroups(grps);
    setSelectedGroups(grps);
    setFilterSelectedGroup(grps);
  }, [groupsList]);

  useEffect(() => {
    if (currentCam) {
      dispatch(loadLatestEvent([currentCam.psn]));
    }
  }, [dispatch, currentCam]);

  useEffect(() => {
    if (cameraList) {
      buttonsRef.current = _.reduce(
        cameraList.DeviceListInfo,
        (result, cam) => {
          return {
            ...result,
            [cam.device.psn]: createRef<HTMLButtonElement>(),
          };
        },
        {}
      );
    }
  }, [cameraList]);

  const getDashcamIcon = useCallback(
    (camera: ICameraInfo) => {
      if (camera.valid === "invalid") {
        return (
          <DashcamInvalid
            className={clsx(classes.icon, classes.iconInactive)}
          />
        );
      } else if (camera.active === "on") {
        return (
          <DashcamConnectivity
            className={clsx(classes.icon, classes.iconActive)}
          />
        );
      }
      return (
        <DashcamConnectivity
          className={clsx(classes.icon, classes.iconInactive)}
        />
      );
    },
    [classes]
  );

  const getConnectivity = useCallback(
    (camera: ICameraInfo) => {
      if (
        camera.communication_identifier === "Wifi" &&
        camera.active === "on"
      ) {
        return <WifiIcon className={clsx(classes.icon, classes.iconActive)} />;
      } else if (
        camera.communication_identifier === "Cat4" &&
        camera.active === "on"
      ) {
        if (camera.sensitivity_level === 0) {
          return <CellularStrong className={classes.lteIcon} />;
        } else if (camera.sensitivity_level === 1) {
          return <CellularWeak className={classes.lteIcon} />;
        }
      }
    },
    [classes]
  );

  const renderCamDetail = useCallback(() => {
    const group = _.find(
      groupsList?.DashCamList,
      (gr) => !!_.find(gr.DashCamUser, (dc) => dc.PSN === currentCam?.psn)
    );
    return currentCam ? (
      <div className={classes.detailDiv}>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}>
            {getDashcamIcon(currentCam)}
          </div>
          <div>
            <Typography category="Default" variant="H5">
              {currentCam?.dev_name}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}>
            {getConnectivity(currentCam)}
          </div>
          <div>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {currentCam.model}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}></div>
          <div>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {`S/N: ${currentCam.psn}`}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}></div>
          <div>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {`Firmware v.${currentCam.fw_ver}`}
            </Typography>
          </div>
        </div>
        <div className={classes.detailLineDiv}>
          <div className={classes.detailIconDiv}>
            <GroupIcon className={clsx(classes.icon, classes.iconInactive)} />
          </div>
          <div className={classes.enter}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {`${t("Group")}: ${group ? group.GroupName : "none"}`}
            </Typography>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.detailDiv}></div>
    );
  }, [t, classes, getConnectivity, getDashcamIcon, currentCam, groupsList]);

  const renderFilterDiv = useCallback(() => {
    const notInGroup = _.filter(cameraList?.DeviceListInfo, (camera) => {
      return !_.find(groupsList?.DashCamList, (g) => {
        return _.find(g.DashCamUser, (d) => d.PSN === camera.device.psn);
      });
    });

    return (
      <div
        className={clsx(classes.listPane, {
          [classes.detailDivHide]: !openFilter,
        })}
      >
        <div
          className={clsx(classes.header, {
            [classes.headerOpen]: open,
          })}
        >
          <IconButton
            onClick={() => setOpenFilter(false)}
            className={classes.backBtn}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography category="Default" variant="H6">
            {t("Filter")}
          </Typography>
        </div>
        <div className={classes.camDiv}>
          <div
            className={classes.filterGroupDiv}
            onClick={() => {
              const exists = _.includes(filterSelectedGroups, "notInGroup");
              if (exists) {
                setFilterSelectedGroup((groups) => [
                  ..._.filter(groups, (gr) => gr !== "notInGroup"),
                ]);
              } else {
                setFilterSelectedGroup((groups) => [...groups, "notInGroup"]);
              }
            }}
          >
            <div className={classes.formDiv}>
              <CheckBox
                color="primary"
                checked={_.includes(filterSelectedGroups, "notInGroup")}
              />

              <Typography category="Default" variant="Body">
                {t("Not in a group")}
              </Typography>
            </div>
            <div className={classes.filterCamCntDiv}>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                {t("n cameras", { n: notInGroup.length })}
              </Typography>
            </div>
          </div>
          {_.chain(groupsList?.DashCamList)
            .filter((g) => !!g.DashCamUser && g.DashCamUser.length > 0)
            .map((g, indx) => {
              return (
                <div
                  key={`filter-group-${indx}`}
                  className={classes.filterGroupDiv}
                  onClick={() => {
                    const exists = _.includes(filterSelectedGroups, g.GroupID);
                    if (exists) {
                      setFilterSelectedGroup((groups) => [
                        ..._.filter(groups, (gr) => gr !== g.GroupID),
                      ]);
                    } else {
                      setFilterSelectedGroup((groups) => [
                        ...groups,
                        g.GroupID,
                      ]);
                    }
                  }}
                >
                  <div className={classes.formDiv}>
                    <CheckBox
                      color="primary"
                      checked={_.includes(filterSelectedGroups, g.GroupID)}
                    />
                    <Typography category="Default" variant="Body">
                      {g.GroupName}
                    </Typography>
                  </div>
                  <div className={classes.filterCamCntDiv}>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("n cameras", { n: g.DashCamUser?.length ?? 0 })}
                    </Typography>
                  </div>
                </div>
              );
            })
            .value()}
        </div>
        <div className={classes.filterBtnDiv}>
          <Button
            variant="outlined"
            color="primary"
            disabled={filterSelectedGroups.length === totalGroups.length}
            onClick={() => setFilterSelectedGroup(totalGroups)}
          >
            {t("Clear")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              filterSelectedGroups.length === 0 ||
              _.isEqual(filterSelectedGroups, selectedGroups)
            }
            onClick={() => {
              setSelectedGroups(filterSelectedGroups);
              setOpenFilter(false);
            }}
          >
            {t("OK")}
          </Button>
        </div>
      </div>
    );
  }, [
    cameraList?.DeviceListInfo,
    classes.listPane,
    classes.detailDivHide,
    classes.header,
    classes.headerOpen,
    classes.backBtn,
    classes.camDiv,
    classes.filterGroupDiv,
    classes.formDiv,
    classes.filterCamCntDiv,
    classes.filterBtnDiv,
    openFilter,
    open,
    t,
    filterSelectedGroups,
    groupsList?.DashCamList,
    selectedGroups,
    totalGroups,
  ]);

  const renderMenu = useCallback(() => {
    return (
      <div>
        <div className={classes.screenMenuDiv}>
          <div
            className={classes.screenBtnDiv}
            onClick={() =>
              currentCam && history.push(`/cameras/${currentCam.psn}/playback`)
            }
          >
            <div className={classes.btnIconDiv}>
              <PlaylistPlayIcon
                className={clsx(
                  classes.screenIcon,
                  classes.icon30px,
                  classes.iconActive
                )}
                style={{ margin: "1px 0 0 3px" }}
              />
            </div>
            <Typography category="Default" variant="Caption">
              {i18n.language === "ja" ? t("Playback") : "Playback"}
            </Typography>
          </div>
          <div
            className={clsx(classes.screenBtnDiv, {
              [classes.disableScreenBtnDiv]:
                !liveviewPerm || !currentCam || currentCam.active === "off",
            })}
            onClick={
              liveviewPerm && currentCam && currentCam.active === "on"
                ? () =>
                    history.push(`/cameras/${currentCam.psn}/live-view`, {
                      clicked: true,
                    })
                : undefined
            }
          >
            <div className={classes.btnIconDiv}>
              <LiveView
                className={clsx(classes.screenIcon, classes.iconActive)}
                style={{ margin: "0 0 0 2px" }}
              />
            </div>
            <Typography category="Default" variant="Caption">
              {i18n.language === "ja" ? t("Live view") : "Live view"}
            </Typography>
          </div>
          <div
            className={clsx(classes.screenBtnDiv, {
              [classes.disableScreenBtnDiv]: !gpsTrackingPerm,
            })}
            onClick={
              gpsTrackingPerm
                ? () =>
                    currentCam &&
                    history.push(`/cameras/${currentCam.psn}/gps-tracking`)
                : undefined
            }
          >
            {gpsTrackingPerm ? (
              <div className={classes.btnIconDiv}>
                <GPSTracking
                  className={clsx(classes.screenIcon, classes.iconActive)}
                />
              </div>
            ) : (
              <Tooltip
                disableTouchListener={mobile}
                title={
                  <Typography category="Default" variant="Caption">
                    {userProfile?.userType === "User" ||
                    userProfile?.userType === "SubMaster"
                      ? t("No permission")
                      : t("Fleet Plan only_")}
                  </Typography>
                }
                placement="bottom"
              >
                <div className={classes.btnIconDiv}>
                  <GPSTracking
                    className={clsx(classes.screenIcon, classes.iconActive)}
                  />
                </div>
              </Tooltip>
            )}
            <Typography
              category="Default"
              variant="Caption"
              style={{ textAlign: "center" }}
            >
              {i18n.language === "ja" ? t("GPS tracking") : "GPS tracking"}
            </Typography>
          </div>
          <div
            className={clsx(classes.screenBtnDiv, {
              [classes.disableScreenBtnDiv]:
                !reportPerm || _.includes(PSN650, currentCam?.psn.substr(0, 4)),
            })}
            onClick={
              reportPerm && !_.includes(PSN650, currentCam?.psn.substr(0, 4))
                ? () =>
                    currentCam &&
                    history.push(`/cameras/${currentCam.psn}/report`)
                : undefined
            }
          >
            <div className={classes.btnIconDiv}>
              <AssignmentIcon
                className={clsx(classes.screenIcon, classes.iconActive)}
              />
            </div>
            <Typography category="Default" variant="Caption">
              {i18n.language === "ja" ? t("Report") : "Report"}
            </Typography>
          </div>
        </div>
      </div>
    );
  }, [
    classes.screenMenuDiv,
    classes.screenBtnDiv,
    classes.btnIconDiv,
    classes.screenIcon,
    classes.icon30px,
    classes.iconActive,
    classes.disableScreenBtnDiv,
    i18n.language,
    t,
    liveviewPerm,
    currentCam,
    gpsTrackingPerm,
    mobile,
    userProfile?.userType,
    reportPerm,
    history,
  ]);

  const renderEventList = useMemo(() => {
    return (
      <div className={classes.eventListDiv}>
        <Typography category="Default" variant="BodyBold">
          {t("Latest events")}
        </Typography>
        <div>
          {_.map(latestEvents, (l, i) => {
            return (
              <React.Fragment key={`latest-event-${i}`}>
                <LatestEvent
                  event={l}
                  onClick={(evt) => {
                    if (currentCam && currentCam.active === "off") {
                      setOpenErrorModal(true);
                    } else if (free100Check) {
                      setOpenExceedModal(true);
                    } else {
                      setCurrentEvent(evt);
                      setOpenPlayerModal(true);
                    }
                  }}
                />
                {i < latestEvents.length - 1 && (
                  <div className={classes.eventBorderDiv} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }, [
    classes.eventListDiv,
    classes.eventBorderDiv,
    t,
    latestEvents,
    currentCam,
    free100Check,
  ]);

  const videoModal = useMemo(() => {
    return (
      currentCam && (
        <VideoPlayerModal
          mode={0}
          open={openPlayerModal}
          camera={currentCam}
          event={currentEvent}
          onClose={() => setOpenPlayerModal(false)}
        />
      )
    );
  }, [currentCam, currentEvent, openPlayerModal]);

  const handleRename: (() => void) | null = useMemo(() => {
    return renameCamPerm
      ? () => {
          if (moreCam) {
            onRename?.(moreCam);
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [moreCam, onRename, renameCamPerm]);

  const handleDelete: (() => void) | null = useMemo(() => {
    return deleteCamPerm
      ? () => {
          if (moreCam) {
            onDelete?.(moreCam);
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [deleteCamPerm, moreCam, onDelete]);

  const handleSettings: (() => void) | null = useMemo(() => {
    return settingsCamPerm
      ? () => {
          if (moreCam) {
            onSettings?.(moreCam);
            history.push(`/cameras/${moreCam.psn}/settings`);
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [history, moreCam, onSettings, settingsCamPerm]);

  const handleFOTA: (() => void) | null = useMemo(() => {
    return fotaCamPerm
      ? () => {
          if (moreCam) {
            const isNew = _.find(availableNewFws, (v, k) => k === moreCam.psn);
            onUpdate?.(moreCam, isNew);
          }
          setOpenCamMenu(false);
        }
      : null;
  }, [availableNewFws, fotaCamPerm, moreCam, onUpdate]);

  const handleRebbotCam: (() => void) | undefined = useMemo(() => {
    if (moreCam) {
      const valid =
        SUPPORT_REBOOT_FW[moreCam.model as MODEL_KEY] <=
        parseFloat(moreCam.fw_ver);
      return valid
        ? () => {
            if (moreCam) {
              onRebootCamera?.(moreCam);
            }
            setOpenCamMenu(false);
          }
        : undefined;
    }
  }, [moreCam, onRebootCamera]);

  useEffect(() => {
    if (
      cameraList &&
      cameraList.DeviceListInfo &&
      cameraList.DeviceListInfo.length > 0 &&
      open &&
      !(loading && type === loadCameras.type)
    ) {
      const shownCams: ICameraInfo[] = [];
      _.includes(selectedGroups, "notInGroup") &&
        _.chain(cameraList.DeviceListInfo)
          .forEach((camera, indx) => {
            let show = false;
            if (searchKey) {
              show =
                camera.device.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                camera.device.model
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1;
            } else {
              show = true;
            }
            if (show) {
              const group = _.find(groupsList?.DashCamList, (g) => {
                return _.find(
                  g.DashCamUser,
                  (d) => d.PSN === camera.device.psn
                );
              });
              show = !group;
            }
            if (show) {
              shownCams.push(camera.device);
            }
          })
          .value();
      _.chain(groupsList?.DashCamList)
        .filter((g) => _.includes(selectedGroups, g.GroupID))
        .filter((g) => {
          if (searchKey) {
            return !!_.find(g.DashCamUser, (d) => {
              const cam = _.find(
                cameraList.DeviceListInfo,
                (dev) => dev.device.psn === d.PSN
              );
              return (
                cam &&
                (cam.device.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                  cam.device.model
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1)
              );
            });
          } else {
            return true;
          }
        })
        .forEach((group, indx) => {
          _.map(group.DashCamUser, (cam) => {
            const camInfo = _.find(
              cameraList.DeviceListInfo,
              (dev) => dev.device.psn === cam.PSN
            )?.device;
            let show = false;
            if (searchKey && camInfo) {
              show =
                camInfo.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                camInfo.model
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1;
            } else {
              show = true;
            }
            if (camInfo && show) {
              shownCams.push(camInfo);
            }
          });
        })
        .value();

      onUpdateFilteredCams?.(shownCams);
    }
  }, [
    cameraList,
    groupsList?.DashCamList,
    loading,
    onUpdateFilteredCams,
    open,
    searchKey,
    selectedGroups,
    type,
  ]);

  const cameraListMarkup = useMemo(() => {
    if (
      cameraList &&
      cameraList.DeviceListInfo &&
      cameraList.DeviceListInfo.length > 0 &&
      open &&
      !(loading && type === loadCameras.type)
    ) {
      const notInGroup = _.includes(selectedGroups, "notInGroup")
        ? _.chain(cameraList.DeviceListInfo)
            .map((camera, indx) => {
              let show = false;
              if (searchKey) {
                show =
                  camera.device.dev_name
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                  camera.device.model
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1;
              } else {
                show = true;
              }
              if (show) {
                const group = _.find(groupsList?.DashCamList, (g) => {
                  return _.find(
                    g.DashCamUser,
                    (d) => d.PSN === camera.device.psn
                  );
                });
                show = !group;
              }

              const fw = _.find(
                availableNewFws,
                (v, k) => k === camera.device.psn
              );
              return show ? (
                <CameraInfo
                  key={camera.device.psn}
                  more
                  newFw={!!(fw?.blackvue || fw?.dmc200)}
                  camera={camera.device}
                  ref={buttonsRef.current[camera.device.psn]}
                  onClick={() => {
                    console.log("onClick", camera.device);
                    if (camera.device.valid === "invalid") {
                      setOpenInvalidModal(true);
                    } else {
                      onClick?.(camera.device);
                      // setCurrentPSN(camera.device.psn);
                      setCurrentCam(camera.device);
                    }
                  }}
                  onMore={(e, psn) => {
                    e.stopPropagation();
                    e.preventDefault();
                    console.log("setMoreCam1");
                    setMoreCam(camera.device);
                    setAnchorRef(buttonsRef.current[camera.device.psn]);
                    if (moreCam === camera.device) {
                      setOpenCamMenu((o) => !o);
                    } else {
                      setOpenCamMenu(true);
                    }
                  }}
                />
              ) : undefined;
            })
            .compact()
            .value()
        : [];
      const inGroup = _.chain(groupsList?.DashCamList)
        .filter((g) => _.includes(selectedGroups, g.GroupID))
        .filter((g) => {
          if (searchKey) {
            return !!_.find(g.DashCamUser, (d) => {
              const cam = _.find(
                cameraList.DeviceListInfo,
                (dev) => dev.device.psn === d.PSN
              );
              return (
                cam &&
                (cam.device.dev_name
                  .toLowerCase()
                  .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                  cam.device.model
                    .toLowerCase()
                    .indexOf(searchKey.trim().toLowerCase()) > -1)
              );
            });
          } else {
            return true;
          }
        })
        .map((group, indx) => {
          return (
            group.DashCamUser &&
            group.DashCamUser.length > 0 && (
              <div key={`group-${indx}`}>
                <div className={classes.groupDiv}>
                  <GroupIcon
                    className={clsx(classes.smallIcon)}
                    htmlColor={LightColors.primary["3"]}
                  />
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["3"]}
                  >
                    {group.GroupName}
                  </Typography>
                </div>
                {_.map(group.DashCamUser, (cam) => {
                  const camInfo = _.find(
                    cameraList.DeviceListInfo,
                    (dev) => dev.device.psn === cam.PSN
                  )?.device;
                  let show = false;
                  if (searchKey && camInfo) {
                    show =
                      camInfo.dev_name
                        .toLowerCase()
                        .indexOf(searchKey.trim().toLowerCase()) > -1 ||
                      camInfo.model
                        .toLowerCase()
                        .indexOf(searchKey.trim().toLowerCase()) > -1;
                  } else {
                    show = true;
                  }
                  const fw = _.find(
                    availableNewFws,
                    (v, k) => k === camInfo?.psn
                  );
                  return (
                    camInfo &&
                    show && (
                      <CameraInfo
                        key={camInfo.psn}
                        newFw={!!(fw?.blackvue || fw?.dmc200)}
                        more
                        camera={camInfo}
                        ref={buttonsRef.current[camInfo.psn]}
                        onClick={() => {
                          if (camInfo.valid === "invalid") {
                            setOpenInvalidModal(true);
                          } else {
                            onClick?.(camInfo);
                            // setCurrentPSN(camInfo.psn);
                            setCurrentCam(camInfo);
                          }
                        }}
                        onMore={(e, psn) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setMoreCam(camInfo);
                          setAnchorRef(buttonsRef.current[camInfo.psn]);
                          if (moreCam === camInfo) {
                            setOpenCamMenu((o) => !o);
                          } else {
                            setOpenCamMenu(true);
                          }
                        }}
                      />
                    )
                  );
                })}
              </div>
            )
          );
        })
        .value();

      return notInGroup.length > 0 || inGroup.length > 0 ? (
        <div className={classes.camDiv}>
          <ScrollBar>
            {notInGroup}
            {inGroup}
          </ScrollBar>
        </div>
      ) : (
        <div className={classes.noResultDiv}>
          <NoResults />
        </div>
      );
    }
  }, [
    availableNewFws,
    cameraList,
    classes.camDiv,
    classes.groupDiv,
    classes.noResultDiv,
    classes.smallIcon,
    groupsList?.DashCamList,
    loading,
    moreCam,
    onClick,
    open,
    searchKey,
    selectedGroups,
    type,
  ]);

  const cameraMenuMarkup = useMemo(() => {
    return (
      <CameraMenu
        placement={"bottom-start"}
        open={openCamMenu}
        camera={moreCam}
        anchorRef={anchorRef}
        onClickAway={() => setOpenCamMenu(false)}
        onRename={handleRename}
        onSettings={handleSettings}
        onUpdate={
          fotaCamPerm &&
          moreCam?.active === "on" &&
          !_.includes(PSN650, moreCam?.psn.substr(0, 4))
            ? handleFOTA
            : null
        }
        onDelete={handleDelete}
        onRebootCamera={handleRebbotCam}
      />
    );
  }, [
    anchorRef,
    fotaCamPerm,
    handleDelete,
    handleFOTA,
    handleRebbotCam,
    handleRename,
    handleSettings,
    moreCam,
    openCamMenu,
  ]);

  const alertMessageMarkup = useMemo(() => {
    const reg_date = moment(currentCam?.reg_date);
    const login_date = moment(currentCam?.login_date);
    return (
      <div className={classes.activeOffDiv}>
        <WarningIcon htmlColor={LightColors.secondary["17"]} />
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.secondary["17"]}
          style={{ margin: "0 8px" }}
        >
          {reg_date.isSame(login_date) ? (
            <Trans
              t={t}
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <span
                    className={classes.link}
                    onClick={() => setOpenCloudConnectionModal(true)}
                  ></span>
                ),
              }}
            >
              Some functionality is disabled_
            </Trans>
          ) : (
            t("Not connected to_2")
          )}
        </Typography>
      </div>
    );
  }, [classes.activeOffDiv, classes.link, currentCam, t]);

  const mobileCameraMenuMarkup = useMemo(() => {
    const fw = _.find(availableNewFws, (v, k) => k === moreCam?.psn);
    return (
      <MobileCameraMenu
        camera={moreCam}
        open={openCamMenu}
        newFw={!!(fw?.blackvue || fw?.dmc200)}
        onClose={() => setOpenCamMenu(false)}
        onRename={handleRename}
        onSettings={handleSettings}
        onUpdate={
          fotaCamPerm &&
          moreCam?.active === "on" &&
          !_.includes(PSN650, moreCam?.psn.substr(0, 4))
            ? handleFOTA
            : null
        }
        onDelete={handleDelete}
        onRebootCamera={handleRebbotCam}
      />
    );
  }, [
    availableNewFws,
    fotaCamPerm,
    handleDelete,
    handleFOTA,
    handleRebbotCam,
    handleRename,
    handleSettings,
    moreCam,
    openCamMenu,
  ]);

  return (
    <>
      <div
        className={clsx(
          classes.root,
          {
            [classes.rootOpen]: open,
            [classes.rootClose]: !open,
            [classes.rootMobile]: mobile,
          },
          className
        )}
      >
        <div className={clsx(classes.rootPanel)}>
          <div
            className={clsx(classes.listPane, {
              [classes.listDivHide]: currentCam || openFilter,
            })}
          >
            <div
              className={clsx(classes.header, {
                [classes.headerOpen]: open,
                [classes.headerSearch]: openSearch,
              })}
              style={{
                justifyContent: "space-between",
              }}
            >
              <div
                className={clsx(classes.cameraTitleWrap, {
                  // [classes.cameraTitleWrapOpen]: openSearch,
                })}
              >
                <div className={classes.cameraTitleDiv}>
                  <Typography category="Default" variant="H6">
                    {t("Cameras")}
                  </Typography>
                  {!mobile &&
                    subscriptionInfo &&
                    getPlanFromServiceID(subscriptionInfo.servicePlanID) ===
                      "Fleet plan" && (
                      <Tooltip
                        open={openTooltip}
                        onOpen={() => setOpenTooltip(true)}
                        onClose={() => setOpenTooltip(false)}
                        disableTouchListener={mobile}
                        placement="top"
                        PopperProps={{
                          modifiers: {
                            offset: {
                              enabled: true,
                              offset: "0, -15px",
                            },
                            flip: {
                              enabled: false,
                            },
                          },
                        }}
                        title={
                          <Typography
                            category="Default"
                            variant="Caption"
                            htmlColor={LightColors.primary["0"]}
                          >
                            {t("Filter")}
                          </Typography>
                        }
                      >
                        <IconButton
                          className={classes.iconBtn}
                          onClick={() => {
                            setOpenTooltip(false);
                            setOpenFilter(true);
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <FilterListIcon style={{ display: "block" }} />
                            {selectedGroups.length !==
                              (groupsList?.DashCamList?.length ?? 0) + 1 && (
                              <div
                                style={{
                                  width: 6,
                                  height: 6,
                                  borderRadius: 3,
                                  backgroundColor: LightColors.secondary["11"],
                                  position: "absolute",
                                  top: -3,
                                  right: -3,
                                }}
                              />
                            )}
                          </div>
                        </IconButton>
                      </Tooltip>
                    )}
                </div>
              </div>

              <div
                className={clsx(classes.searchDiv, {
                  [classes.searchDivOpen]: openSearch,
                })}
              >
                <Input
                  inputID="cameraSearch"
                  SecondaryColor
                  placeholder={t("Search cameras")}
                  startIcon={
                    <Tooltip
                      disableTouchListener={mobile}
                      placement="top"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0, -12px",
                          },
                          flip: {
                            enabled: false,
                          },
                        },
                      }}
                      title={
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["0"]}
                        >
                          {t("search")}
                        </Typography>
                      }
                    >
                      <SearchIcon />
                    </Tooltip>
                  }
                  className={clsx(classes.searchBtn, {
                    [classes.searchBtnOpen]: openSearch,
                  })}
                  onChange={(e) => setSearchKey(e.target.value)}
                  value={searchKey}
                  search
                  dense
                  variant="standard"
                  onOpenSearch={() => {
                    const openEvent = new CustomEvent("drawer:open");
                    document.dispatchEvent(openEvent);
                    setOpenSearch(true);
                  }}
                  onCloseSearch={() => {
                    setSearchKey("");
                    setOpenSearch(false);
                  }}
                />
              </div>
            </div>
            {loading && type === loadCameras.type && (
              <div className={classes.loadingDiv}>
                <CircularProgress size={48} thickness={6} color="primary" />
              </div>
            )}
            {!(
              cameraList &&
              cameraList.DeviceListInfo &&
              cameraList.DeviceListInfo?.length > 0
            ) &&
              open &&
              !(loading && type === loadCameras.type) && (
                <div className={classes.noCameraDiv}>
                  <EmptyItems variant="small" mode="camera" />
                </div>
              )}
            {cameraListMarkup}
          </div>
          <div
            className={clsx(classes.listPane, {
              [classes.detailDivHide]: !currentCam,
            })}
          >
            <div
              className={clsx(classes.header, {
                [classes.headerOpen]: open,
              })}
            >
              <IconButton
                onClick={() => {
                  setCurrentCam(undefined);
                  onClick?.(undefined);
                }}
                className={classes.backBtn}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography category="Default" variant="H6">
                {t("Cameras")}
              </Typography>
            </div>
            <div style={{ overflow: "auto" }}>
              <ScrollBar>
                {currentCam?.active === "off" && alertMessageMarkup}
                {renderCamDetail()}
                {renderMenu()}
                {renderEventList}
              </ScrollBar>
            </div>
          </div>
          {renderFilterDiv()}
        </div>
      </div>

      {!mobile && cameraMenuMarkup}

      {mobile && mobileCameraMenuMarkup}

      {openPlayerModal && currentEvent && videoModal}

      <Modal
        open={openInvalidModal}
        onClickPositive={() => {
          setOpenInvalidModal(false);
        }}
        heading={t("To access the_")}
        content={<></>}
        RButton={t("OK")}
        titleClassName={classes.modalTitle}
        contentClassName={classes.modalContent}
        className={classes.modalWrap}
      />
      <Modal
        open={openErrorModal}
        content={t("Cloud disconnected. Failed_")}
        onClose={() => setOpenErrorModal(false)}
        RButton={t("OK")}
        onClickPositive={() => {
          setOpenErrorModal(false);
        }}
        className={classes.errorModalWrap}
        actionClassName={classes.errorModalAction}
      />
      <ExceedModal
        open={openExceedModal}
        onClose={() => setOpenExceedModal(false)}
        onClickPositive={() => setOpenExceedModal(false)}
      />
      <Modal
        open={openCloudConnectionModal}
        contentClassName={classes.connectModalContent}
        onClose={() => {
          setOpenCloudConnectionModal(false);
        }}
        onClickPositive={() => {
          setOpenCloudConnectionModal(false);
        }}
        close
        RButton={mobile ? t("OK") : ""}
        fullSize={mobile}
        heading={t("How to connect_")}
        content={
          <ScrollBar
            style={{
              height: "100%",
              overflowX: "hidden",
              flex: 1,
              maxHeight: "calc(100vh - 250px)",
            }}
          >
            <ConnectToCloud
              bodyClassName={classes.cloudConnectModalDiv}
              firstTitle={t("Download BlackVue application")}
              firstContent={t("*If you are_", {
                a: `<a
        href=${`${SIMCARD_URI}?email=${email}&user_token=${loginInfo?.user_token}&token_type=web`}
        target="_blank"
        rel="noreferrer"
        style=color:${LightColors.primary["7"]}; > BlackVue SIM</a>`,
                b: "and add SIM card instead",
              })}
              secondTitle={t("Place your phone_", {
                a: `<a
        href=${CloudConnectionSettings}
        target="_blank"
        rel="noreferrer"
        style=color:${LightColors.primary["7"]}; > connecting camera</a>`,
              })}
              mobile={mobile}
              appCSS={classes.appDiv}
              appStoreCSS={classes.appStoreDiv}
              imgCSS={classes.imgCSSDiv}
            />
          </ScrollBar>
        }
      />
    </>
  );
};
