export const Webviewer = {
  menuHide: 1140,
  reportMobile: 1348,
  reportMobileClose: 1185,
  reportMobileView: 879,
  playbackMobile: 1104,
  playbackMobileClose: 935,
  mobile: 956,
  geofenceMobile: 956,
};
