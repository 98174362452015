import React, { useRef } from "react";
import {
  GeofenceCircle,
  GeofencePolygon,
  GeofencePolyline,
  GeofenceRectangle,
  IconButton,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IGeofence } from "../../features/Geofence/slice";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";

export interface GeofenceListItemProps {
  geofence: IGeofence;
  onClick: () => void;
  onClickMore: (ref: React.RefObject<HTMLButtonElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: "pointer",
    padding: theme.spacing(0.75, 0.75, 0.75, 1),
    "&:hover": {
      backgroundColor: LightColors.primary["10"],
    },
  },
  geofenceTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  geofenceTitle: {
    display: "flex",
    alignItems: "center",
  },
  shapeIcon: {
    width: 18,
    height: 24,
    margin: theme.spacing(0, 1),
  },
  camDiv: { margin: theme.spacing(0.25, 4.5) },
}));

export const GeofenceListItem = ({
  geofence,
  onClick,
  onClickMore,
}: GeofenceListItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const moreDivRef = useRef<HTMLButtonElement>(null);
  return (
    <div
      className={classes.root}
      dir={theme.direction}
      onClick={(e) => onClick()}
    >
      <div className={classes.geofenceTitleDiv}>
        <div className={classes.geofenceTitle}>
          {geofence.fenceType === 1 && (
            <GeofencePolygon
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          {geofence.fenceType === 2 && (
            <GeofenceRectangle
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          {geofence.fenceType === 3 && (
            <GeofenceCircle
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          {geofence.fenceType === 4 && (
            <GeofencePolyline
              className={classes.shapeIcon}
              htmlColor={geofence.setShape.Color}
              stroke={geofence.setShape.Color}
            />
          )}
          <Typography category="Default" variant="Body">
            {geofence.fenceName}
          </Typography>
        </div>
        <IconButton
          style={{ padding: 0 }}
          ref={moreDivRef}
          onClick={(e) => {
            e.stopPropagation();
            onClickMore(moreDivRef);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
      <div className={classes.camDiv}>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {t("n cameras", { n: geofence.psn.length })}
        </Typography>
      </div>
    </div>
  );
};
