import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";

import { useSelector } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core";

import { MinimalFooter } from "../components/MinimalFooter";
import { ResetHeader } from "../components/ResetHeader";
import { USER } from "../features/User/slice";
import { RootState } from "../features/store";
import ResetPasswordForm from "../forms/ResetPasswordForm";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 58px)",
    // paddingTop: 114,
    alignItems: "center",
    padding: theme.spacing(3, 1, 0, 1),
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 438,
    flexGrow: 1,
    marginTop: 90,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "288px !important",
      padding: 0,
      marginTop: 0,
    },
  },
  marginB4: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  marginB3: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      fontSize: `28px !important`,
    },
  },
  marginB3Changed: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
      fontSize: `28px !important`,
    },
  },
}));

export const PasswordChangeScreen = () => {
  const [changed, setChanged] = useState(false);
  const [mainHeader, setMainHeader] = useState("Reset password");

  const classes = useStyles();
  const history = useHistory();
  //const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loading } = useSelector((state: RootState) => state[USER]);

  const handleChangePassword = () => {
    setMainHeader("Password changed");
    setChanged(true);
  };

  //const handleChangePasswordSubmit = (login: ILoginForm) => {};

  return (
    <React.Fragment>
      <ResetHeader />
      {/* Body */}
      <div className={classes.root}>
        <Container className={classes.body}>
          <div className={classes.formDiv}>
            <Typography
              category="Default"
              variant="H1"
              className={clsx({
                [classes.marginB3]: !changed,
                [classes.marginB3Changed]: changed,
              })}
            >
              {t(mainHeader)}
            </Typography>
            {changed ? (
              <div>
                <div style={{ marginBottom: 24 }}>
                  <Typography category="Default" variant="Body">
                    You can now log in with your new password.
                  </Typography>
                </div>
                <Button
                  fullWidth
                  color="primary"
                  onClick={() => {
                    history.push("/login");
                  }}
                  loading={loading}
                >
                  {t("Back to login")}
                </Button>
              </div>
            ) : (
              <div>
                <div>
                  <ResetPasswordForm />
                </div>
                <div>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={handleChangePassword}
                    loading={loading}
                  >
                    {t("Reset password")}
                  </Button>
                </div>
              </div>
            )}
          </div>
          <MinimalFooter />
        </Container>
      </div>
    </React.Fragment>
  );
};
