import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import {
  Button,
  IconButton,
  LightColors,
  MobileMenu,
  Typography,
  WebMenuItem,
  Modal,
  Tooltip,
  Avatars,
  Fonts,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Webviewer } from "../contants/Breakpoints";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { GroupAdminCard } from "../components/groups/GroupAdminCard";
import { CamerasCard } from "../components/CamerasCard";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import {
  addAdmin,
  addUser,
  DashCamList,
  deleteAdmin,
  deleteCamera,
  GROUP,
  groupAddCameras,
  IDashCamInfo,
  IUserInfo,
  loadGroups,
} from "../features/Group/slice";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { GroupAddAdminModal } from "../components/groups/GroupAddAdminModal";
import { GroupAddCameraModal } from "../components/groups/GroupAddCameraModal";
import { CAMERA, loadCameras } from "../features/Camera/slice";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import SearchIcon from "@material-ui/icons/Search";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { CheckBox } from "@thingsw/pitta-design-system";
import { GroupAddUserModal } from "../components/groups/GroupAddUserModal";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { StatusColors, StatusNames } from "../types";
import { USER } from "../features/User/slice";
import { PermissionDrawer } from "../components/PermissionDrawer";
import { NoResults } from "../components/NoResults";
import { loadMembers } from "../features/Member/slice";

export interface GroupsDetailScreenProps {
  openMenu: boolean;
  groupName?: string;
  onRename?: (group: DashCamList) => void;
  onDelete?: (group: DashCamList) => void;
  onDelUser?: (
    group: DashCamList,
    currentCam: IDashCamInfo,
    user: IUserInfo
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "calc(100vh - 56px)",
    marginTop: 56,
  },
  subHeader: {
    transition: theme.transitions.create("left"),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: LightColors.primary["0"],
    position: "fixed",
    top: 56,
    left: 0,
    right: 0,
    padding: theme.spacing(2) - 1,
    zIndex: 96,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { right: 73 } : { left: 73 }),
      height: 49,
      padding: theme.spacing(2, 3.875, 0),
    },
  },
  subHeaderOpen: {
    ...(theme.direction === "rtl" ? { right: 235 } : { left: 235 }),
  },
  btnDiv: {
    transition: theme.transitions.create("width"),
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    marginRight: -2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      flexDirection: "row",
      marginTop: theme.spacing(0.5),
    },
  },
  iconBtn: {
    color: LightColors.primary["1"],
    padding: theme.spacing(0.375),
  },
  editIcon: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.25) }
      : { marginRight: theme.spacing(1.25) }),
  },
  moreIcon: {
    marginBottom: theme.spacing(0.5),
  },
  body: {
    width: "100%",
    flexGrow: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: 49,
    paddingTop: theme.spacing(4) + 1,
    padding: theme.spacing(0, 2),
    overflowY: "auto",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 4),
      marginTop: 68,
      paddingTop: 9,
    },
  },
  infoWrapDiv: {
    maxWidth: 672,
    "&:first-child": {
      marginBottom: theme.spacing(5) - 1,
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },
  infoHeaderDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: theme.spacing(0.75),
    },
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0.75, 2),
    },
  },
  accordionRoot: {
    boxShadow: "none",
    "&.Mui-expanded": {
      margin: 0,
    },
    "&:before": {
      backgroundColor: LightColors.primary["0"],
    },
  },
  accordionSummary: {
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    ...(theme.direction === "rtl"
      ? { padding: "8px 10px 9px 12px" }
      : { padding: "8px 12px 9px 10px" }),
    minHeight: 64,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: "auto",
    },
    "&.Mui-expanded": {
      minHeight: 64,
      [theme.breakpoints.up(Webviewer.mobile)]: {
        minHeight: "auto",
      },
    },
  },
  accordionContent: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionExpandIcon: {
    padding: 3,
    ...(theme.direction === "rtl"
      ? { marginLeft: 0, marginRight: 10 }
      : { marginRight: 0, marginLeft: 10 }),
  },
  accordionDetail: {
    flexDirection: "column",
    backgroundColor: "#F8F8F8",
    padding: "0px 12px 0px 12px",
  },
  ListWrap: {
    padding: 0,
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 4,
    marginTop: -2,
    marginBottom: theme.spacing(2),
  },
  cameraListItem: {
    display: "flex",
    justifyContent: "space-between",
    ...(theme.direction === "rtl"
      ? { padding: "5px 9px 6px 15px" }
      : { padding: "5px 15px 6px 9px" }),
    minHeight: 48,
  },
  cameraDelModal: {
    maxWidth: 448,
    minHeight: 196,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: "auto",
    },
  },
  userItem: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 56,
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  statusDiv: {
    display: "flex",
    marginRight: theme.spacing(1),
    minWidth: 15,
  },
  infoDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: 14,
  },
  closeIconBtn: {
    color: LightColors.primary["3"],
    padding: theme.spacing(0.375),
  },
  spacing: {
    marginTop: 0,
    paddingBottom: 3,
  },
  cameraInfoHeader: {
    paddingBottom: 4,
  },

  cameraOpenSearch: {
    marginBottom: theme.spacing(1) + 1,
  },
  cameraSearch: {
    marginBottom: theme.spacing(2) - 1,
  },
  checkBoxCamDiv: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  avatarDiv: {
    marginRight: 0,
    marginBottom: 14,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 32 } : { marginRight: 32 }),
      marginBottom: 0,
    },
  },
  removeBtnDiv: {
    display: "flex",
  },
  PermissionsBtn: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-start",
    },
  },
  adminStatusDiv: {
    display: "flex",
    alignItems: "center",
    marginRight: 6,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: -2,
    },
  },
  adminInfoDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 23,
    },
  },
  adminInfoWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${LightColors.primary["5"]}`,
    padding: 24,
    borderRadius: 4,
    marginTop: 7,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  adminTextInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      alignItems: "flex-start",
    },
  },
  PermissionsBtnWrap: {
    display: "flex",
    justifyContent: "center",
    marginTop: 25,
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      justifyContent: "flex-start",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  deleteAdminModal: {
    maxWidth: 448,
    minHeight: 219,
  },
  deleteAdminContent: {
    padding: theme.spacing(2.25, 3, 3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2.25, 3),
    },
  },
  deleteCamModal: {
    padding: theme.spacing(2.25, 3, 1),
  },
  addBtn: {
    ...(theme.direction === "rtl" ? { marginRight: 21 } : { marginLeft: 21 }),
  },
  checkboxMargin: {
    ...(theme.direction === "rtl" ? { marginLeft: 7 } : { marginRight: 7 }),
  },
  arrowIcon: {
    ...(theme.direction === "rtl" ? { marginRight: 5 } : { marginLeft: 5 }),
  },
}));

export const GroupsDetailScreen = (props: GroupsDetailScreenProps) => {
  const { onRename, onDelete, onDelUser } = props;
  const maxUserNum = 3;
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const dispatch = useDispatch();
  const groupsList = useSelector(
    (state: RootState) => state[GROUP].groupsList?.DashCamList
  );
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const { loading, type, usersList } = useSelector(
    (state: RootState) => state[GROUP]
  );

  let { groupID } = useParams<{ groupID: string }>();

  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [openCameraModal, setOpenCameraModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openDelCamModal, setOpenDelCamModal] = useState(false);
  const [openDelAdminModal, setOpenDelAdminModal] = useState(false);
  const [, /*clickMenu*/ setClickMenu] = React.useState(false);
  const [group, setGroup] = useState<DashCamList>();
  const { email } = useSelector((state: RootState) => state[USER]);
  const [openSearch, setOpenSearch] = useState(false);
  //DashCam List
  const [openList, setOpenList] = React.useState(true);
  //Check
  const [allChecked, setAllChecked] = React.useState<
    "all" | "intermediate" | "none"
  >("none");
  // 선택 된 checkbox 배열
  const [selected, setSelected] = React.useState<string[]>([]);
  const newSelecteds = useMemo(
    () => _.map(group?.DashCamUser, (n) => n.PSN),
    [group]
  );
  const existedPSN = useMemo(
    () => _.intersection(selected, newSelecteds),
    [selected, newSelecteds]
  );
  const buttonsRef = useRef<RefObject<HTMLButtonElement>[]>(
    _.map(group?.DashCamUser, () => createRef<HTMLButtonElement>())
  );

  const [currentCam, setCurrentCam] = React.useState<IDashCamInfo>();
  const [openAccordions, setOpenAccordions] = useState<boolean[]>([]);
  const [searchKey, setSearchKey] = useState("");

  const [currentPSN, setCurrentPSN] = useState<string>();

  const changeUserCam = useMemo(
    () => _.find(group?.DashCamUser, (dev) => dev.PSN === currentPSN),
    [group?.DashCamUser, currentPSN]
  );
  const changeUserNum = useMemo(
    () => _.compact(_.map(changeUserCam?.Users, _.iteratee("Email"))).length,
    [changeUserCam?.Users]
  );
  const [preUserNum, setPreUserNum] = useState<number>();
  useEffect(() => {
    if (!loading) {
      setCurrentPSN(
        _.find(
          cameraList?.DeviceListInfo,
          (dev) => dev.device.psn === currentCam?.PSN
        )?.device?.psn
      );
    }
  }, [cameraList?.DeviceListInfo, currentCam?.PSN, loading]);
  useEffect(() => {
    if (group) {
      if (openList === false && preUserNum !== undefined) {
        if (preUserNum < changeUserNum) {
          setOpenAccordions(_.map(group.DashCamUser, () => false));
        } else if (preUserNum === 0 && changeUserNum === 0) {
          setOpenAccordions(_.map(group.DashCamUser, () => false));
        }
      } else {
        setOpenList(true);
        setOpenAccordions(_.map(group.DashCamUser, () => true));
      }
    }
  }, [group, changeUserNum, preUserNum, currentPSN, openList]);

  //close modal
  useEffect(() => {
    if (!loading && type === addUser.type) {
      setOpenUserModal(false);
    }
    if (!loading && type === groupAddCameras.type) {
      setOpenCameraModal(false);
    }
    if (!loading && type === deleteAdmin.type) {
      setOpenDelAdminModal(false);
    }
    if (!loading && type === addAdmin.type) {
      setOpenAdminModal(false);
    }
  }, [loading, type]);

  // close modal
  useEffect(() => {
    if (!loading) {
      setOpenDelCamModal(false);
    }
  }, [loading]);

  //해당 그룹 찾기
  useEffect(() => {
    if (groupsList) {
      setGroup(_.find(groupsList, (g) => g.GroupID === groupID));
    }
  }, [groupsList, groupID]);

  useEffect(() => {
    dispatch(loadGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadMembers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  //checkBox click
  useEffect(() => {
    if (existedPSN.length === 0) {
      setAllChecked("none");
    } else if (existedPSN.length < newSelecteds.length) {
      setAllChecked("intermediate");
    } else if (existedPSN.length === newSelecteds.length) {
      setAllChecked("all");
    }
  }, [existedPSN.length, newSelecteds.length]);

  const handleSelectAllClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [newSelecteds]
  );

  const handlecheckClick = useCallback(
    (e: React.MouseEvent<unknown>, psn: string) => {
      const selectedIndex = selected.indexOf(psn);
      if (selectedIndex > -1) {
        setSelected(_.filter(selected, (e) => e !== psn));
      } else {
        setSelected([...selected, psn]);
      }
    },
    [selected]
  );

  const isSelected = useCallback(
    (psn: string) => {
      return selected.indexOf(psn) !== -1;
    },
    [selected]
  );

  const handleClick = () => {
    setClickMenu((prevOpen) => !prevOpen);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickModal = () => {
    setOpenAdminModal((o) => !o);
  };

  const handleClickCameraModal = () => {
    setOpenCameraModal((o) => !o);
  };

  const handleListClick = () => {
    setOpenList(!openList);
  };

  const handleDelete = (grp: DashCamList) => {
    dispatch(
      deleteCamera(
        _.map(existedPSN, (PSN) => ({
          PSN,
          GroupName: grp.GroupName,
          GroupID: grp.GroupID,
        }))
      )
    );
    setSelected([]);
    setAllChecked("none");
  };

  const handleDelAdmin = (grp: DashCamList) => {
    dispatch(
      deleteAdmin({
        guestEmail: grp.SubMasterEmail,
        groupManagementID: grp.GroupID,
      })
    );
  };

  const dashcamMarkup = useMemo(() => {
    const shown = _.map(group?.DashCamUser, (d) => {
      let show = false;
      let open = false;
      if (searchKey) {
        open =
          _.chain(d.Users)
            .map((u) => {
              return (
                u.Email?.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
                u.FirstName?.toLowerCase().indexOf(searchKey.toLowerCase()) >
                  -1 ||
                u.LastName?.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
              );
            })
            .compact()
            .value().length > 0;
        show = show || open;
      } else {
        show = true;
      }
      return show;
    });
    if (_.compact(shown).length === 0) {
      return <NoResults />;
    }

    return _.chain(group?.DashCamUser)
      .map((d, index) => {
        const isItemSelected = isSelected(d.PSN);

        let show = false;
        let open = false;
        if (searchKey) {
          open =
            _.chain(d.Users)
              .map((u) => {
                return (
                  u.Email?.toLowerCase().indexOf(searchKey.toLowerCase()) >
                    -1 ||
                  u.FirstName?.toLowerCase().indexOf(searchKey.toLowerCase()) >
                    -1 ||
                  u.LastName?.toLowerCase().indexOf(searchKey.toLowerCase()) >
                    -1
                );
              })
              .compact()
              .value().length > 0;
          show = show || open;
        } else {
          show = true;
        }

        const userAddBtnMarkup =
          d.Users?.length === maxUserNum ? (
            <Tooltip
              title={
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["0"]}
                >
                  {t("You’ve reached_")}
                </Typography>
              }
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "0, -10px",
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }}
              placement="top"
            >
              <span>
                <IconButton
                  disabled
                  style={{
                    padding: 3,
                  }}
                >
                  <PersonAddIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <IconButton
              style={{
                padding: 3,
              }}
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                setOpenUserModal(true);
                setCurrentCam(d);
                const currentNum = _.compact(
                  _.map(d.Users, _.iteratee("Email"))
                ).length;
                if (currentNum > 0) {
                  setPreUserNum(d.Users.length);
                } else if (currentNum === 0) {
                  setPreUserNum(0);
                }
              }}
            >
              <PersonAddIcon />
            </IconButton>
          );

        return show ? (
          <Accordion
            className={classes.accordionRoot}
            key={`camera-list${index}`}
            expanded={(open || openAccordions[index]) ?? false}
            onChange={() => {
              openAccordions[index] = !openAccordions[index];
              setOpenAccordions([...openAccordions]);
            }}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummary,
                content: classes.accordionContent,
                expandIcon: classes.accordionExpandIcon,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <div
                className={clsx(classes.row, {
                  [classes.checkBoxCamDiv]: mobile,
                })}
              >
                <div className={classes.row}>
                  <CheckBox
                    className={classes.checkboxMargin}
                    color="primary"
                    key={d.PSN}
                    ref={buttonsRef.current[index]}
                    checked={isItemSelected}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlecheckClick(e, d.PSN);
                    }}
                  />
                  <Typography
                    category="Default"
                    variant={mobile ? "Small" : "Body"}
                  >
                    {d.DashCamName}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {mobile && <div style={{ minWidth: 36 }}></div>}

                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["2"]}
                    style={{ marginLeft: mobile ? 0 : "9px" }}
                  >
                    {t("n of n users", {
                      a: _.filter(d.Users, (u) => u.Email).length,
                      b: maxUserNum,
                    })}
                  </Typography>
                </div>
              </div>

              {/* user 추가 아이콘 버튼 */}
              {userAddBtnMarkup}
            </AccordionSummary>
            {/* dashCam에 할당 된 user 표시 */}
            <AccordionDetails className={classes.accordionDetail}>
              {_.chain(d.Users)
                .filter(
                  (u) =>
                    u.Email?.toLowerCase().indexOf(searchKey.toLowerCase()) >
                      -1 ||
                    u.FirstName?.toLowerCase().indexOf(
                      searchKey.toLowerCase()
                    ) > -1 ||
                    u.LastName?.toLowerCase().indexOf(searchKey.toLowerCase()) >
                      -1
                )
                .map((user, idx) => {
                  const statusColor =
                    user.Status === "E"
                      ? LightColors.secondary["11"]
                      : LightColors.primary["1"];
                  return user.Email ? (
                    <div key={`user-list${idx}`} className={classes.userItem}>
                      <div className={classes.infoDiv}>
                        <div>
                          <div className={classes.row}>
                            <div className={classes.statusDiv}>
                              {user.Status === "E" && (
                                <Tooltip
                                  disableTouchListener={mobile}
                                  title={
                                    <Typography
                                      category="Default"
                                      variant="Caption"
                                      htmlColor={LightColors.primary["0"]}
                                    >
                                      {t("Expired")}
                                    </Typography>
                                  }
                                  PopperProps={{
                                    modifiers: {
                                      offset: {
                                        enabled: true,
                                        offset: "0, -14px",
                                      },
                                      flip: {
                                        enabled: false,
                                      },
                                    },
                                  }}
                                  placement="top"
                                >
                                  <FiberManualRecordIcon
                                    style={{
                                      color: LightColors.secondary["11"],
                                      fontSize: 15,
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                            <Typography
                              category="Default"
                              variant="Small"
                              htmlColor={statusColor}
                            >
                              {user.FirstName && user.LastName
                                ? `${user.FirstName} ${user.LastName}`
                                : "–"}
                            </Typography>
                          </div>
                          <div className={classes.row}>
                            <Typography
                              category="Default"
                              variant="Caption"
                              htmlColor={statusColor}
                              style={{ marginLeft: 23 }}
                            >
                              {user.Email}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      {/* 유저 삭제 버튼 */}
                      <IconButton
                        className={classes.closeIconBtn}
                        onClick={() => {
                          group && onDelUser?.(group, d, user);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ) : undefined;
                })
                .value()}
            </AccordionDetails>
          </Accordion>
        ) : undefined;
      })
      .compact()
      .value();
  }, [
    classes.accordionContent,
    classes.accordionDetail,
    classes.accordionExpandIcon,
    classes.accordionRoot,
    classes.accordionSummary,
    classes.checkBoxCamDiv,
    classes.checkboxMargin,
    classes.closeIconBtn,
    classes.infoDiv,
    classes.row,
    classes.statusDiv,
    classes.userItem,
    group,
    handlecheckClick,
    isSelected,
    mobile,
    onDelUser,
    openAccordions,
    searchKey,
    t,
  ]);

  return (
    <>
      {group && (
        <div className={classes.root} dir={theme.direction}>
          {/* 화면 상단 메뉴 - 그룹 rename, delete */}
          <div
            className={clsx(classes.subHeader, {
              [classes.subHeaderOpen]: props.openMenu && !mobile,
            })}
          >
            <div>
              <Typography category="Default" variant="H2">
                {group.GroupName}
              </Typography>
            </div>
            <div className={classes.btnDiv}>
              {mobile ? (
                <IconButton
                  className={clsx(classes.iconBtn, classes.moreIcon)}
                  onClick={handleClick}
                  disabled={group.SubMasterEmail === email}
                >
                  <MoreVertIcon />
                </IconButton>
              ) : (
                <>
                  <Tooltip
                    placement="top"
                    PopperProps={{
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: "0, -11px",
                        },
                        flip: {
                          enabled: false,
                        },
                      },
                    }}
                    disableTouchListener={mobile}
                    title={
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["0"]}
                      >
                        {t("Rename")}
                      </Typography>
                    }
                  >
                    <IconButton
                      className={clsx(classes.iconBtn, classes.editIcon)}
                      onClick={() => groupsList && onRename?.(group)}
                      disabled={group.SubMasterEmail === email}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    PopperProps={{
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: "0, -11px",
                        },
                        flip: {
                          enabled: false,
                        },
                      },
                    }}
                    disableTouchListener={mobile}
                    title={
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["0"]}
                      >
                        {t("Delete")}
                      </Typography>
                    }
                  >
                    <IconButton
                      className={classes.iconBtn}
                      onClick={() => groupsList && onDelete?.(group)}
                      disabled={group.SubMasterEmail === email}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {/* 모바일의 경우 ...클릭 시 하단에서 메뉴바 등장 */}
              {mobile && (
                <MobileMenu open={open} onClose={() => setOpen(false)}>
                  <WebMenuItem
                    className={classes.mobileMemuItem}
                    onClick={() => {
                      groupsList && onRename?.(group);
                      setOpen(false);
                    }}
                    startIcon={
                      <EditIcon
                        fontSize="default"
                        htmlColor={LightColors.secondary["1"]}
                        style={{ marginRight: 4 }}
                      />
                    }
                  >
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={LightColors.secondary["1"]}
                    >
                      {t("Rename")}
                    </Typography>
                  </WebMenuItem>
                  <WebMenuItem
                    className={classes.mobileMemuItem}
                    onClick={() => {
                      groupsList && onDelete?.(group);
                      setOpen(false);
                    }}
                    startIcon={
                      <DeleteIcon
                        fontSize="default"
                        htmlColor={LightColors.secondary["11"]}
                        style={{ marginRight: 4 }}
                      />
                    }
                  >
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={LightColors.secondary["11"]}
                    >
                      {t("Delete")}
                    </Typography>
                  </WebMenuItem>
                </MobileMenu>
              )}
            </div>
          </div>

          <div className={classes.body}>
            {/* group admin 정보 */}
            <div className={classes.infoWrapDiv}>
              <div className={classes.infoHeaderDiv}>
                <div>
                  <Typography
                    category="Default"
                    variant={mobile ? "BodyBold" : "H6"}
                  >
                    {t("Admin")}
                  </Typography>
                </div>

                {!group.SubMasterEmail && (
                  <div className={classes.btnDiv}>
                    <Button
                      style={{
                        minWidth: mobile ? 96 : 87,
                        paddingRight: mobile ? 26 : 16,
                        paddingLeft: mobile ? 14 : 16,
                      }}
                      startIcon={<AddIcon />}
                      variant="text"
                      color="primary"
                      onClick={handleClickModal}
                    >
                      {t("Add")}
                    </Button>
                  </div>
                )}
              </div>
              {/* group내 admin이 존재하는 경우 */}
              {group.SubMasterEmail ? (
                <div className={classes.adminInfoWrap}>
                  <div>
                    <div className={classes.adminInfoDiv}>
                      <div className={classes.avatarDiv}>
                        <Avatars
                          size="large"
                          name={group.SubMasterLastName}
                          style={{ ...Fonts.Default.H1 }}
                        ></Avatars>
                      </div>
                      {/* admin 이름+이메일+상태 */}
                      <div className={classes.adminTextInfo}>
                        <div
                          className={classes.row}
                          style={{ marginTop: 2, marginBottom: 3 }}
                        >
                          {group.SubMasterFirstName &&
                          group.SubMasterLastName ? (
                            <Typography category="Default" variant="H6">
                              {`${group.SubMasterFirstName} ${group.SubMasterLastName}`}
                            </Typography>
                          ) : (
                            "–"
                          )}
                        </div>

                        <div
                          className={classes.row}
                          style={{ marginBottom: 4 }}
                        >
                          <Typography category="Default" variant="Body">
                            {group.SubMasterEmail}
                          </Typography>
                        </div>
                        <div className={classes.row}>
                          <div className={classes.adminStatusDiv}>
                            <FiberManualRecordIcon
                              style={{
                                color: `${StatusColors[group.SubMasterStatus]}`,
                                fontSize: 15,
                                marginRight: mobile ? "2px" : 0,
                              }}
                            />
                            <Typography
                              category="Default"
                              variant="Body"
                              htmlColor={StatusColors[group.SubMasterStatus]}
                            >
                              {t(StatusNames[group.SubMasterStatus])}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* permission 버튼 */}
                    <div className={classes.PermissionsBtnWrap}>
                      {!mobile && (
                        <div
                          style={{
                            minWidth: 80,
                            display: "flex",
                            marginRight: 30,
                          }}
                        ></div>
                      )}

                      <div className={classes.PermissionsBtn}>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={group.SubMasterStatus !== "A"}
                          onClick={() => setOpenDrawer(true)}
                        >
                          {t("Permissions")}
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* remove 버튼 */}
                  <div className={classes.removeBtnDiv}>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => setOpenDelAdminModal(true)}
                      style={{ padding: 0, minWidth: 50, minHeight: 21 }}
                      disabled={group.SubMasterEmail === email}
                    >
                      <Typography category="Default" variant="Small">
                        {t("Remove")}
                      </Typography>
                    </Button>
                  </div>
                </div>
              ) : (
                <GroupAdminCard />
              )}
            </div>
            {/* group내 할당 된 카메라 정보 */}
            <div className={classes.infoWrapDiv}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className={clsx(
                    classes.infoHeaderDiv,
                    classes.cameraInfoHeader
                  )}
                >
                  {/* 상단 제목 및 search, Add 버튼 */}
                  <div>
                    <Typography
                      category="Default"
                      variant={mobile ? "BodyBold" : "H6"}
                    >
                      {t("Cameras")}
                    </Typography>
                  </div>
                  <div
                    className={clsx(classes.btnDiv, {
                      [classes.spacing]: group.DashCamUser && !mobile,
                    })}
                  >
                    {group.DashCamUser && !mobile && (
                      <Input
                        add
                        placeholder={t("search")}
                        startIcon={<SearchIcon />}
                        onChange={(e) => setSearchKey(e.target.value)}
                        value={searchKey}
                        search
                        dense
                        onOpenSearch={() => setOpenSearch(true)}
                        onCloseSearch={() => {
                          setSearchKey("");
                          setOpenSearch(false);
                        }}
                      />
                    )}

                    <Button
                      style={{
                        minWidth: mobile ? 96 : 87,
                        paddingRight: mobile ? 26 : 16,
                        paddingLeft: mobile ? 14 : 16,
                      }}
                      // className={classes.addBtn}
                      startIcon={<AddIcon />}
                      variant="text"
                      color="primary"
                      onClick={handleClickCameraModal}
                      disabled={group.SubMasterEmail === email}
                    >
                      {t("Add")}
                    </Button>
                  </div>
                </div>
                {/* 할당된 dashCam이 있는 경우 + mobile인 경우*/}
                <div>
                  {group.DashCamUser && mobile && (
                    <Input
                      className={clsx(classes.cameraOpenSearch, {
                        [classes.cameraOpenSearch]: mobile && openSearch,
                      })}
                      placeholder={t("search")}
                      startIcon={<SearchIcon />}
                      onChange={(e) => setSearchKey(e.target.value)}
                      value={searchKey}
                      search
                      dense
                      onOpenSearch={() => setOpenSearch(true)}
                      onCloseSearch={() => {
                        setSearchKey("");
                        setOpenSearch(false);
                      }}
                    />
                  )}
                </div>
              </div>

              {/* 할당된 dashCam이 있는 경우 +  web인 경우*/}
              {group.DashCamUser ? (
                <div className={classes.ListWrap}>
                  <div
                    onClick={handleListClick}
                    className={classes.cameraListItem}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckBox
                        className={classes.checkboxMargin}
                        key={`cameraList`}
                        color="primary"
                        indeterminate={allChecked === "intermediate"}
                        checked={allChecked === "all"}
                        onChange={handleSelectAllClick}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      {existedPSN.length > 0 ? (
                        <div
                          style={{
                            color: LightColors.secondary["11"],
                            display: "flex",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDelCamModal((open) => !open);
                          }}
                        >
                          <CloseIcon style={{ padding: 3, marginRight: 8 }} />
                          <Typography category="Default" variant="Body">
                            {t("Remove All/n", { a: `${existedPSN.length}` })}
                          </Typography>
                        </div>
                      ) : (
                        <Typography category="Default" variant="Body">
                          {t("All")}
                        </Typography>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        color: LightColors.primary["7"],
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        category="Default"
                        variant="Small"
                        style={{
                          display: "flex",
                        }}
                      >
                        {/* 아코디언 열고 닫기 */}
                        {openList ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={() => {
                              setOpenAccordions(
                                _.map(openAccordions, () => false)
                              );
                              setPreUserNum(changeUserNum);
                            }}
                          >
                            {t("Collapse all")}
                            <ExpandLess className={classes.arrowIcon} />
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={() => {
                              setOpenAccordions(
                                _.map(openAccordions, () => true)
                              );
                            }}
                          >
                            {t("Expand all")}
                            <ExpandMore className={classes.arrowIcon} />
                          </div>
                        )}
                      </Typography>
                    </div>
                  </div>

                  {dashcamMarkup}
                </div>
              ) : (
                <CamerasCard />
              )}
            </div>
          </div>

          {/* Modal */}

          {/* add admin modal */}
          {openAdminModal && (
            <GroupAddAdminModal
              open={openAdminModal}
              group={group}
              user={usersList}
              onClose={() => setOpenAdminModal(false)}
              onClickNegative={() => setOpenAdminModal(false)}
            />
          )}

          {/* add camera modal */}
          {openCameraModal && (
            <GroupAddCameraModal
              open={openCameraModal}
              group={group}
              camera={cameraList}
              onClose={() => setOpenCameraModal(false)}
              onClickNegative={() => setOpenCameraModal(false)}
              onClickPositive={() => setOpenCameraModal(false)}
            />
          )}

          {/* add user modal */}
          {openUserModal && (
            <GroupAddUserModal
              open={openUserModal}
              group={group}
              user={usersList}
              camera={currentCam}
              onClose={() => setOpenUserModal(false)}
              onClickNegative={() => setOpenUserModal(false)}
              onClickPositive={() => setOpenUserModal(false)}
            />
          )}

          {/* delete camera modal */}
          {openDelCamModal && (
            <Modal
              className={classes.cameraDelModal}
              open={openDelCamModal}
              mobile={mobile}
              loading={loading && type === deleteCamera.type}
              onClose={() => setOpenDelCamModal(false)}
              onClickNegative={() => setOpenDelCamModal(false)}
              onClickPositive={() => {
                group && handleDelete(group);
              }}
              heading={t("Remove")}
              contentClassName={classes.deleteCamModal}
              close
              content={
                <Typography category="Default" variant="Body">
                  {t("Are you sure_remove")}
                </Typography>
              }
              LButton={t("Cancel")}
              RButton={t("Remove")}
              Secondary
            />
          )}

          {/* delete admin modal */}
          {openDelAdminModal && (
            <Modal
              className={classes.deleteAdminModal}
              open={openDelAdminModal}
              mobile={mobile}
              loading={loading && type === deleteAdmin.type}
              onClose={() => setOpenDelAdminModal(false)}
              onClickNegative={() => setOpenDelAdminModal(false)}
              onClickPositive={() => {
                group && handleDelAdmin(group);
              }}
              heading={t("Remove")}
              close
              contentClassName={classes.deleteAdminContent}
              content={
                <Typography
                  category="Default"
                  variant="Body"
                  dangerouslySetInnerHTML={{
                    __html: t("Are you sure_remove admin", {
                      a:
                        group.SubMasterFirstName && group.SubMasterLastName
                          ? `<strong>${group.SubMasterFirstName} ${group.SubMasterLastName}</strong>`
                          : "",
                      b: `<strong>${group.SubMasterEmail}</strong>`,
                    }),
                  }}
                ></Typography>
              }
              LButton={t("Cancel")}
              RButton={t("Remove")}
              Secondary
            />
          )}
        </div>
      )}
      {group?.SubMasterEmail && (
        <PermissionDrawer
          email={group.SubMasterEmail}
          openDrawer={openDrawer}
          onClose={() => setOpenDrawer(false)}
        />
      )}
    </>
  );
};
