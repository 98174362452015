import axios from "axios";
import { detect } from "detect-browser";
import { API_SERVER_URI } from "../contants/Server";
import { Direction } from "../types";

export const doDownload = async (linkUrl: string, filename?: string) => {
  console.log("doDownload", linkUrl);

  return new Promise<void>((resolve, reject) => {
    const browser = detect();
    if (browser?.name === "ios" || browser?.name === "crios") {
      window.location.href = linkUrl;
    } else {
      const link = document.createElement("a");
      link.href = linkUrl;
      if (filename) {
        link.download = filename;
      }

      link.target = "_blank";
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        // document.body.removeChild(link);
        resolve();
      }, 0);
    }
  });

  // await axios({
  //   url: linkUrl,
  //   method: "GET",
  //   responseType: "blob",
  //   onDownloadProgress: (progressEvent) => {
  //     let percentCompleted = Math.round(
  //       (progressEvent.loaded * 100) / progressEvent.total
  //     );
  //     // you can use this to show user percentage of file downloaded
  //     console.log("onDownloadProgress", percentCompleted);
  //   },
  // });
};

export const getDirection = (dir: Direction) => {
  if (dir === 2) {
    return "Rear";
  } else if (dir === 3) {
    return "Interior";
  }
  return "Front";
};

export const getEventLink = async (
  email: string,
  userToken: string,
  psn: string,
  rid: string
) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${userToken}&psn=${psn}&rid=${rid}&token_type=web`
  );
};
