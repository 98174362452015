import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  LightColors,
  Link,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormAsyncErrors, isDirty, stopSubmit, submit } from "redux-form";
import crypto from "crypto";
import { useTranslation } from "react-i18next";

import { Webviewer } from "../../contants/Breakpoints";
import { RootState } from "../../features/store";
import {
  changePasswd,
  changeName,
  IChangeName,
  IChangePasswd,
  uploadImage,
  USER,
  clearActionType,
} from "../../features/User/slice";
import ChangePasswdForm from "../../forms/ChangePasswordForm";
import ChangeNameForm from "../../forms/ChangeNameForm";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import imageCompression from "browser-image-compression";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%",
    overflowY: "auto",
  },
  profDiv: {
    flexDirection: "column",
    padding: theme.spacing(2, 2, 3, 2),
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      flexDirection: "unset",
      padding: theme.spacing(3, 0, 2, 0),
      width: "auto",
    },
  },
  photoArea: {
    backgroundColor: LightColors.primary[7],
    width: 117,
    height: 117,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { marginLeft: 85 } : { marginRight: 85 }),
    },
  },
  btnDiv: {
    display: "flex",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      flexDirection: "column",
    },
  },
  btn: {
    marginBottom: "0 !important",
    height: 36,
    width: 288,
    "&:last-child": {
      marginTop: theme.spacing(2),
    },
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 96,
      "&:last-child": {
        margin: 0,
      },
    },
  },
  pwBtn: {
    marginBottom: "0 !important",
    width: 161,
    height: 36,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
    "&:last-child": {
      width: 96,
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: 288,
      margin: `0 0 16px !important`,
      "&:last-child": {
        width: 288,
      },
    },
  },
  photo: {
    width: 117,
    height: 117,
  },
  camArea: {
    position: "absolute",
    width: 117,
    height: 32,
    bottom: 0,
    backgroundColor: `rgba(19, 19, 28, 0.45);`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&>.MuiSvgIcon-root": {
      color: LightColors.primary[0],
    },
  },
  camBtn: {
    minWidth: 30,
    minHeight: 30,
    padding: 0,
  },
  camIcon: {
    fontSize: "24px !important",
    color: LightColors.primary[0],
  },
  frameDiv: {
    padding: theme.spacing(4, 0),
    display: "flex",
    flexDirection: "column",
    width: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 0,
      padding: 0,
      width: "unset",
    },
  },
  name: {
    display: "flex",
    flexDirection: "column",
  },
  group: {
    marginTop: theme.spacing(4),
    display: "flex",
  },
  groupRole: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  groupAdmin: {
    display: "flex",
    flexDirection: "column",
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  groupDriver: {
    display: "flex",
    flexDirection: "column",
  },
  email: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  passwd: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  marginText: {
    display: "flex",
    margin: `8px 0`,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  delete: {
    marginTop: theme.spacing(4),
  },
}));

export const AccountProfilePanel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { userProfile, type, loading, pwChangeError } = useSelector(
    (state: RootState) => state[USER]
  );

  const state = useSelector((state: RootState) => state);

  const [hidePhoto, setHidePhoto] = useState(false);
  const [photo, setPhoto] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);
  const [showChangePasswd, setShowChangePasswd] = useState(false);
  // const [openPwDiv, setOpenPwDiv] = useState(true);
  const [pwLast, setPwLast] = useState("-- -- , --");
  const [, /*rols*/ setRoles] = useState<
    "Master" | "SubMaster" | "User" | "Etc"
  >("Etc");
  const [userType, setUserType] = useState<"Owner" | "Admin" | undefined>(
    undefined
  );
  const { oauthToken } = useSelector((state: RootState) => state[USER]);

  useEffect(() => {
    if (pwChangeError) {
      dispatch(
        stopSubmit("ChangePasswdForm", {
          old_passwd: `${t("Please enter_password")}`,
        })
      );
    }
  }, [dispatch, pwChangeError, t]);

  useEffect(() => {
    if (!loading) {
      if (type === changeName.type) {
        setShowChangeName(false);
      } else if (type === changePasswd.type) {
        if (!pwChangeError) {
          setShowChangePasswd(false);
        }
      }
      dispatch(clearActionType());
    }
  }, [t, dispatch, loading, type, pwChangeError]);

  useEffect(() => {
    if (userProfile?.pwLastChangedDT !== undefined) {
      const pwDate = new Date(
        String(userProfile?.pwLastChangedDT.split(" ")[0])
      )
        .toDateString()
        .split(" ");
      setPwLast(t(`Last changed ${pwDate[1]} ${pwDate[2]}, ${pwDate[3]}`));
    }
    if (userProfile?.profilePath !== undefined) {
      setPhoto(true);
    }
    if (userProfile?.userType === "Master") {
      setRoles("Master");
      setUserType("Owner");
    } else if (userProfile?.userType === "SubMaster") {
      setRoles("Master");
      setUserType("Admin");
    } else if (userProfile?.userType === "User") {
      setRoles("User");
    } else {
      setRoles("Etc");
    }
  }, [
    t,
    userProfile?.profilePath,
    userProfile?.pwLastChangedDT,
    userProfile?.userType,
    userType,
  ]);

  const handleChangeImage = useCallback(
    async (event: any) => {
      const img = event.target.files[0];
      const options = {
        maxSizeMB: 0.97,
        maxWidthOrHeight: 500,
      };

      try {
        const compressdFile = await imageCompression(img, options);

        dispatch(
          uploadImage({
            file: compressdFile,
            fileName: compressdFile.name,
          })
        );
      } catch (err) {
        console.log(`compress error`);
      }
      setPhoto(true);
    },
    [dispatch]
  );

  const renderName = useCallback(() => {
    return (
      <div>
        <Typography
          className={classes.marginText}
          category="Default"
          variant="Body"
        >
          {userProfile?.firstName} {userProfile?.lastName}
        </Typography>
        <Link
          category="Default"
          variant="Body"
          to="#"
          onClick={() => {
            setShowChangeName(true);
          }}
        >
          {t("Change")}
        </Link>
      </div>
    );
  }, [classes.marginText, t, userProfile?.firstName, userProfile?.lastName]);

  const renderChangeName = useCallback(() => {
    const handleResetName = () => {
      dispatch(submit("ChangeNameForm"));
    };

    const handleChangeNameSubmit = (data: IChangeName) => {
      dispatch(
        changeName({
          firstName: data.firstName,
          lastName: data.lastName,
        })
      );
    };

    return (
      <div>
        <ChangeNameForm
          onSubmit={handleChangeNameSubmit}
          initialValues={{
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
          }}
        />
        <div className={classes.btnDiv}>
          <Button
            className={classes.btn}
            fullWidth
            color="primary"
            disabled={!isDirty("ChangeNameForm")(state)}
            onClick={handleResetName}
            style={{ marginBottom: 20 }}
            loading={loading && type === changeName.type}
          >
            {t("Save")}
          </Button>
          <Button
            className={classes.btn}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowChangeName(false);
            }}
            style={{ marginBottom: 20 }}
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    );
  }, [
    t,
    dispatch,
    classes.btn,
    classes.btnDiv,
    state,
    userProfile?.firstName,
    userProfile?.lastName,
    loading,
    type,
  ]);

  const renderPasswd = useCallback(() => {
    return (
      <div>
        <Typography
          className={classes.marginText}
          category="Default"
          variant="Body"
        >
          {pwLast}
        </Typography>
        <Link
          category="Default"
          variant="Body"
          to="#"
          onClick={() => {
            setShowChangePasswd(true);
          }}
        >
          {t("Change password")}
        </Link>
      </div>
    );
  }, [classes.marginText, pwLast, t]);

  const renderChangePasswd = useCallback(() => {
    const handleResetPaswwd = () => {
      dispatch(submit("ChangePasswdForm"));
    };

    const handleChangePasswdSubmit = (data: IChangePasswd) => {
      dispatch(
        changePasswd({
          old_passwd: crypto
            .createHash("sha256")
            .update(data.old_passwd)
            .digest("hex"),
          new_passwd: crypto
            .createHash("sha256")
            .update(data.new_passwd)
            .digest("hex"),
        })
      );
    };

    return (
      <div>
        <ChangePasswdForm onSubmit={handleChangePasswdSubmit} />
        <div className={classes.btnDiv}>
          <Button
            className={classes.pwBtn}
            fullWidth
            color="primary"
            onClick={handleResetPaswwd}
            style={{ marginBottom: 20 }}
            disabled={
              !isDirty("ChangePasswdForm")(state) ||
              !!getFormAsyncErrors("ChangePasswdForm")(state)
            }
            loading={loading && type === changePasswd.type}
          >
            {mobile ? t("Change") : t("Change password")}
          </Button>
          <Button
            className={classes.pwBtn}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowChangePasswd(false);
            }}
            style={{ marginBottom: 20 }}
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    );
  }, [
    classes.btnDiv,
    classes.pwBtn,
    state,
    loading,
    type,
    mobile,
    t,
    dispatch,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.profDiv}>
        <div className={classes.photoArea}>
          {!photo || hidePhoto ? (
            <Typography
              category="Default"
              variant="H1"
              style={{ color: LightColors.primary[0] }}
            >
              {userProfile?.email[0].toUpperCase()}
            </Typography>
          ) : (
            <img
              className={classes.photo}
              src={userProfile?.profilePath}
              onError={() => setHidePhoto(true)}
              alt="profile"
            />
          )}
          <div className={classes.camArea}>
            <input
              hidden
              id="input"
              type="file"
              accept="image/jpeg, image/png"
              onChange={handleChangeImage}
            />
            <IconButton
              className={classes.camBtn}
              onClick={() => {
                document.getElementById("input")?.click();
              }}
            >
              <CameraAltIcon className={classes.camIcon} />
            </IconButton>
          </div>
        </div>
        <div className={classes.frameDiv}>
          <div className={classes.name}>
            <Typography category="Default" variant="H6">
              {t("Name")}
            </Typography>
            {showChangeName ? renderChangeName() : renderName()}
          </div>
          {userProfile?.userType === "Master" ||
          userProfile?.userType === "SubMaster" ? (
            <div className={classes.group}>
              <div className={classes.groupRole}>
                <Typography category="Default" variant="H6">
                  {t("Role")}
                </Typography>
                <Typography
                  className={classes.marginText}
                  category="Default"
                  variant="Body"
                >
                  {userType ? t(userType) : userType}
                </Typography>
              </div>
              {userProfile?.userType === "Master" ? (
                <div className={classes.groupAdmin}>
                  <Typography category="Default" variant="H6">
                    {t("Submasters")}
                  </Typography>
                  <Typography
                    className={classes.marginText}
                    category="Default"
                    variant="Body"
                  >
                    {userProfile?.subMaster}
                  </Typography>
                </div>
              ) : (
                <></>
              )}
              <div className={classes.groupDriver}>
                <Typography category="Default" variant="H6">
                  {t("Users")}
                </Typography>
                <Typography
                  className={classes.marginText}
                  category="Default"
                  variant="Body"
                >
                  {userProfile?.user}
                </Typography>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={classes.email}>
            <Typography category="Default" variant="H6">
              {t("Email address")}
            </Typography>
            <Typography
              className={classes.marginText}
              category="Default"
              variant="Body"
            >
              {userProfile?.email}
            </Typography>
          </div>

          {!oauthToken && userProfile?.pwLastChangedDT && (
            <div className={classes.passwd}>
              <Typography category="Default" variant="H6">
                {t("Password")}
              </Typography>
              {showChangePasswd ? renderChangePasswd() : renderPasswd()}
            </div>
          )}

          <div className={classes.delete}>
            <Link
              category="Default"
              color={"dangerous"}
              variant="Body"
              to="/account/delete"
            >
              {t("Delete account")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
