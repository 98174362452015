import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import firebase from "firebase/app";
import "firebase/auth";

import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { LightColors, theme } from "@thingsw/pitta-design-system";
import { FirebaseAuthProvider } from "@react-firebase/auth";

import { LandingScreen } from "../screens/LandingScreen";
import { PricingScreen } from "../screens/PricingScreen";
import { LoginScreen } from "../screens/LoginScreen";
import { DownloadsScreen } from "../screens/DownloadsScreen";
import { CompatibleDashcamsScreen } from "../screens/CompatibleDashcamsScreen";
import { PasswordResetScreen } from "../screens/PasswordResetScreen";
import { PasswordChangeScreen } from "../screens/PasswordChangeScreen";
import { SignupScreen } from "../screens/SignupScreen";
import AuthorizedRoute from "../components/AuthorizedRoute";
import { history, RootState } from "../features/store";
import { DeleteAccountConfirmScreen } from "../screens/DeleteAccountConfirmScreen";
import { DeleteAccountCheckScreen } from "../screens/DeleteAccountCheckScreen";
import { useDispatch, useSelector } from "react-redux";
import { THEME } from "../features/Theme/slice";
import { VerifyAccountScreen } from "../screens/VerifyAccountScreen";
import { config } from "../contants/Firebase";
import { BillingScreen } from "../screens/BillingScreen";
import { DeleteAccountScreen } from "../screens/DeleteAccountScreen";
import { SocialSigninScreen } from "../screens/SocialSigninScreen";
import _ from "lodash";
import { E500Screen } from "../screens/E500Screen";
import { E404Screen } from "../screens/E404Screen";
import axios from "axios";
import { setError } from "../features/Error/slice";
import DefaultRoute from "../components/DefaultRoute";
import WebviewerRouter from "../routers/WebviewerRouter";
import { USER } from "../features/User/slice";
import { MaintenanceRouter } from "../routers/MaintenanceRouter";
import { CameraSimAddScreen } from "../screens/CameraSimAddScreen";
import { CameraChooseConnectScreen } from "../screens/CameraChooseConnectScreen";
import { ConnectToCloudScreen } from "../screens/ConnectToCloudScreen";
import { SubscriptionSuccessScreen } from "../screens/SubscriptionSuccessScreen";
import { SubscriptionFailScreen } from "../screens/SubscriptionFailScreen";

const IGNORE_REQUESTS = [
  "/proc/vod_file",
  "/BCS/queue_list",
  "/BCS/gps_zone.php",
  "/DashCam/GetDeviceList",
  "https://fota.blackvuecloud.com",
  "/BCS/vodPlayReq.php",
  "/DashCam/GetDeviceList",
  "/Account/UserUsageInfo",
  "/Account/PostUserInfo",
  "/fw_folder/firmware_info.json",
];

function App() {
  const dispatch = useDispatch();
  history.listen((location, action) => {
    if (!_.includes(["#cloud", "#fleet"], location.hash)) {
      window.scrollTo(0, 0);
    }
  });

  const { direction, fontFamily } = useSelector(
    (state: RootState) => state[THEME]
  );
  const { loginInfo } = useSelector((state: RootState) => state[USER]);

  useEffect(() => {
    const onRejected = (error: any) => {
      const axiosConfig = error.config;
      if (axiosConfig) {
        const len = _.chain(IGNORE_REQUESTS)
          .map((req) => axiosConfig.url.indexOf(req) > -1)
          .compact()
          .value().length;
        if (len > 0) {
          return Promise.reject(error);
        }
      }
      if (
        error.code === "ECONNABORTED" ||
        error.message === "Network Error" ||
        error.response?.status === 408
      ) {
        dispatch(setError("An error occurred_again"));
      }
      return Promise.reject(error);
    };
    axios.defaults.timeout = 30 * 1000;
    const interceptorId = axios.interceptors.response.use(
      undefined,
      onRejected
    );
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [dispatch]);

  return (
    <ThemeProvider
      theme={theme({
        fontFamily,
        dir: direction,
        Colors: LightColors,
        breakpoints: {
          values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
        },
      })}
    >
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <CssBaseline />
        <ConnectedRouter history={history}>
          <Switch>
            <DefaultRoute path="/" exact component={LandingScreen} />
            <DefaultRoute path="/Err500" component={E500Screen} />
            <DefaultRoute path="/pricing" component={PricingScreen} />
            <DefaultRoute path="/downloads" component={DownloadsScreen} />
            <DefaultRoute
              path="/compatible-dashcams"
              component={CompatibleDashcamsScreen}
            />
            <DefaultRoute path="/login" component={LoginScreen} />
            <DefaultRoute path="/reset" component={PasswordResetScreen} />
            <DefaultRoute path="/change" component={PasswordChangeScreen} />
            <DefaultRoute path="/socialSignin" component={SocialSigninScreen} />
            <DefaultRoute path="/signup" component={SignupScreen} />
            <DefaultRoute
              path="/verify-account"
              component={VerifyAccountScreen}
            />
            <DefaultRoute path="/billing" component={BillingScreen} />
            <DefaultRoute
              path="/subscription-success"
              component={SubscriptionSuccessScreen}
            />
            <DefaultRoute
              path="/subscription-fail"
              component={SubscriptionFailScreen}
            />

            <DefaultRoute path="/app/maintenance">
              <MaintenanceRouter />
            </DefaultRoute>

            <AuthorizedRoute
              path="/cameras/add-camera-sim"
              component={CameraSimAddScreen}
            />
            <AuthorizedRoute
              path="/cameras/choose-connection"
              component={CameraChooseConnectScreen}
            />
            <AuthorizedRoute
              path="/cameras/connect-to-cloud"
              component={ConnectToCloudScreen}
            />
            <AuthorizedRoute
              exact
              path={`/account/delete`}
              component={DeleteAccountScreen}
            />

            <DefaultRoute
              path="/delete-check"
              component={DeleteAccountCheckScreen}
            />
            <DefaultRoute
              path="/delete-confirm"
              component={DeleteAccountConfirmScreen}
            />

            {loginInfo && <WebviewerRouter />}

            <DefaultRoute component={E404Screen} />
          </Switch>
        </ConnectedRouter>
      </FirebaseAuthProvider>
    </ThemeProvider>
  );
}

export default App;
