import axios from "axios";
import parser from "ua-parser-js";
import { API_SERVER_URI } from "../contants/Server";

export const getPushToken = (
  email: string,
  userToken: string,
  mobile_uuid: string,
  mobile_lang: string
) => {
  const ua = parser(navigator.userAgent);
  return axios.get(
    `${API_SERVER_URI}/BCS/alarmRegister.php?email=${email}&user_token=${userToken}&tokenType=web&mobile_os_type=web&mobile_uuid=${mobile_uuid}&mobile_lang=${mobile_lang}&mobile_os_ver=1.00&mobile_name=${ua.browser.name}`
  );
};
