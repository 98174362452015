import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PermissionProps } from "../../hoc/withViewerTemplate";

interface IState {
  permission: PermissionProps;
}

const initialState: IState = {
  permission: {
    rebootCamPerm: false,
    uploadVodPerm: false,
    downloadVodPerm: false,
    deleteVodPerm: false,
    moveToCloudVodPerm: false,
    cameraVodPerm: false,
    cloudVodPerm: false,

    fotaCamPerm: false,
    settingsCamPerm: false,
    renameCamPerm: false,
    deleteCamPerm: false,

    gpsTrackingPerm: false,
    geofencePerm: false,
    reportPerm: false,
    liveviewPerm: false,
    multiLiveviewPerm: false,

    privacyPerm: false,
    pushNotificationsPerm: false,
    eventAutoUploadPerm: false,
    firmwareSettingPerm: false,

    downloadCSVReportPerm: false,

    // Playback more Tab 권한 추가
    moreTabPerm: false,
  },
};

const slice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    clearPermissions: (state) => {
      state.permission = {
        rebootCamPerm: false,
        uploadVodPerm: false,
        downloadVodPerm: false,
        deleteVodPerm: false,
        moveToCloudVodPerm: false,
        cameraVodPerm: false,
        cloudVodPerm: false,

        fotaCamPerm: false,
        settingsCamPerm: false,
        renameCamPerm: false,
        deleteCamPerm: false,

        gpsTrackingPerm: false,
        geofencePerm: false,
        reportPerm: false,
        liveviewPerm: false,
        multiLiveviewPerm: false,

        privacyPerm: false,
        pushNotificationsPerm: false,
        eventAutoUploadPerm: false,
        firmwareSettingPerm: false,

        downloadCSVReportPerm: false,

        // Playback more Tab 권한 추가
        moreTabPerm: false,
      };
    },
    setPermission: (state, { payload }: PayloadAction<PermissionProps>) => {
      state.permission = payload;
    },
  },
});

export const { clearPermissions, setPermission } = slice.actions;
export const PERMISSION = slice.name;
export default slice.reducer;
