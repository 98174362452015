// 기본 리액트 기능 임포트
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
// -- 다국어 지원 텍스트 컴포넌트
import { useTranslation } from "react-i18next";
// -- 버튼
import {
  Button,
  LightColors,
  Menu,
  Tooltip,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
// -- 아이콘
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
// -- 반응형 규격 정보
import { Webviewer } from "../contants/Breakpoints";
// - clsx 기능
import clsx from "clsx";
// -- 빈 그룹 안내문 컴포넌트
import { EmptyGroups } from "../components/EmptyGroups";
// -- 인풋 컴포넌트
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { RootState } from "../features/store";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGroup,
  GROUP,
  IGroupInfo,
  loadGroups,
  loadGroupsPaging,
} from "../features/Group/slice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { USER } from "../features/User/slice";
import { GroupsTable } from "@thingsw/pitta-design-system/dist/components/GroupsTable";
import _ from "lodash";
import { ROWS_PER_PAGE } from "../contants/List";
import { ScreenDefaultProps } from "../hoc/withViewerTemplate";
import { CameraMenu } from "../components/cameras/CameraMenu";
import { MobileCameraMenu } from "../components/MobileCameraMenu";
import { NoResults } from "../components/NoResults";

interface GroupsScreenProps {
  onRename?: (group: any) => void;
  onDelete?: (group: any) => void;
  onSelectDelete?: (group: any[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  // 1. 루트
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "auto",
    height: (props: ScreenDefaultProps) =>
      props.error
        ? "calc(var(--vh, 1vh) * 100 - 85px - 68px)"
        : "calc(var(--vh, 1vh) * 100 - 56px - 68px)",
    marginTop: (props: ScreenDefaultProps) =>
      props.error ? 68 + 127 : 68 + 56,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: (props: ScreenDefaultProps) =>
        props.error ? 68 + 85 : 68 + 56,
    },
  },
  subHeaderDiv: {
    backgroundColor: LightColors.primary["0"],
    top: (props: ScreenDefaultProps) => (props.error ? 127 : 56),
    ...(theme.direction === "rtl"
      ? { left: 0, right: 0 }
      : { left: 235, right: 0 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
      margin: theme.spacing(0, 4),
      ...(theme.direction === "rtl"
        ? { left: 0, right: 235 }
        : { left: 235, right: 0 }),
    },
    position: "fixed",
    zIndex: 90,
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  subHeaderDivClosed: {
    left: 73,
  },
  subHeaderDivMobile: {
    left: 0,
  },
  // 1-1 서브헤더
  subHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 68,
    backgroundColor: LightColors.primary["0"],
    top: (props: ScreenDefaultProps) => (props.error ? 85 : 56),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      flexDirection: "row",
      // marginBottom: "56px",
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(0, 2, 0, 2.875) }
        : { padding: theme.spacing(0, 2.875, 0, 2) }),
      // padding: theme.spacing(0, 2, 0, 2),
    },
  },
  subHeaderOpenInput: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
  // 1-1-1. Add 버튼
  btnDiv: {
    transition: theme.transitions.create("width"),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      flexDirection: "row",
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      alignItems: "flex-start",
    },
  },
  searchBtnOpen: {
    width: "100%",
  },
  body: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    marginTop: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflowY: "hidden",
    },
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    width: "auto",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: "100%",
      marginRight: 0,
    },
  },
  webSearchOpen: {
    marginRight: 0,
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  loadingDiv: {
    display: "flex",
    // @ts-ignore
    // eslint-disable-next-line no-dupe-keys
    display: "-webkit-flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    height: "100%",
  },
  tableDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2, 4),
      justifyContent: "unset",
    },
  },
  paginationDiv: {
    display: "flex",
    justifyContent: "flex-end",
    minHeight: 30,
    marginTop: theme.spacing(0.625),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 0,
    },
  },
  pageSortBtn: {
    minHeight: 24,
    minWidth: 69,
    padding: 0,
    marginRight: theme.spacing(1.625),
  },
  pageArrowBtn: {
    padding: 3,
    color: LightColors.primary["1"],
  },
  addBtn: {
    minWidth: 133,
    margin: theme.spacing(2, 0),
  },
  hidden: {
    display: "none",
  },
  mobileSearchOpen: {
    width: "100%",
    marginRight: 0,
  },
  searchIcon: {
    minWidth: 30,
    minHeight: 30,
  },
}));

export const GroupsScreen = (props: GroupsScreenProps & ScreenDefaultProps) => {
  const { openMenu, onRename, onDelete, onSelectDelete } = props;
  const classes = useStyles(props);
  // 다국어 컴포넌트 활성화
  const { t } = useTranslation();
  // 페이지 값 전달
  const history = useHistory();

  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const { type, loading } = useSelector((state: RootState) => state[GROUP]);
  // const groupsList = useSelector((state: RootState) => state[GROUP].groupsList);
  const groupsList = useSelector(
    (state: RootState) => state[GROUP].groupsListPaging
  );
  const { loginInfo, userProfile } = useSelector(
    (state: RootState) => state[USER]
  );

  // 쿼리 내용에 따라 트루.펄즈 반환 (화면의 브레이크 포인트가 Webview.mobile 보다 작은가?)
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const [openCamMenu, setOpenCamMenu] = useState(false);
  const [moreGroup, setMoreGroup] = useState<any>();
  const [anchorMoreRef, setAnchorMoreRef] = useState<
    React.RefObject<HTMLElement> | undefined
  >();

  const [openSearch, setOpenSearch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [, /*clickMenu*/ setClickMenu] = React.useState(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [searchedKey, setSearchedKey] = useState("");
  const [list, setList] = useState<IGroupInfo[]>([]);

  const requestGroups = useCallback(
    (key?: string) => {
      if (key) {
        setSearchedKey(key);
      }
      dispatch(
        loadGroupsPaging({
          startIndex: 1 + currentPage * ROWS_PER_PAGE,
          endIndex: (currentPage + 1) * ROWS_PER_PAGE,
          ordering: sortOrder === "desc" ? 0 : 1,
          searchKey: key ? encodeURIComponent(key) : key,
        })
      );
    },
    [dispatch, currentPage, sortOrder]
  );

  useEffect(() => {
    if (groupsList) {
      setMaxPage(Math.floor((groupsList.groupCount ?? 0) / ROWS_PER_PAGE));
      setList(
        _.filter(groupsList.groupListInfo, (grp) =>
          searchedKey
            ? grp.groupName.toLowerCase().indexOf(searchedKey.toLowerCase()) >
              -1
            : true
        )
      );
    }
  }, [groupsList, searchedKey]);

  useEffect(() => {
    dispatch(loadGroups());
  }, [dispatch, list]);

  useEffect(() => {
    requestGroups();
  }, [requestGroups]);

  useEffect(() => {
    if (type === deleteGroup.type && !loading) {
      setOpenCamMenu(false);
    }
  }, [loading, type]);

  const handlePageSort = () => {
    setClickMenu((prevOpen) => !prevOpen);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
    setClickMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
      setClickMenu(false);
    }
  }

  const getAddBtnStatus = useCallback(() => {
    if (loginInfo && loginInfo.userType === "SubMaster") {
      return true;
    }
  }, [loginInfo]);

  const groupListMarkup = useMemo(() => {
    if (loading) {
      return (
        <div className={classes.loadingDiv}>
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    }

    if (list.length === 0) {
      if (searchKey) {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NoResults />
          </div>
        );
      } else {
        return <EmptyGroups variant={mobile ? "small" : "default"} />;
      }
    }

    return (
      <div className={classes.tableDiv}>
        <div style={{ overflowX: "auto" }}>
          <GroupsTable
            t={t}
            groups={list}
            onClick={(g) => {
              const group = g as IGroupInfo;
              history.push(`/groups/${group.groupManagementID}`);
            }}
            onDeleteAll={onSelectDelete}
            disableDelete={userProfile?.userType !== "Master"}
            onDisabled={userProfile?.userType === "SubMaster"}
            //disableRename={userProfile?.userType !== "Master"}
            onClickMore={(group, anchor) => {
              setMoreGroup(group);
              setAnchorMoreRef(anchor);
              if (moreGroup === group) {
                setOpenCamMenu((o) => !o);
              } else {
                setOpenCamMenu(true);
              }
            }}
          />
        </div>

        <div className={classes.paginationDiv}>
          <Button
            variant="text"
            ref={anchorRef}
            onClick={handlePageSort}
            className={classes.pageSortBtn}
          >
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["3"]}
            >
              {t("n of n", {
                a: `${1 + currentPage * ROWS_PER_PAGE} - ${Math.min(
                  (currentPage + 1) * ROWS_PER_PAGE,
                  groupsList?.groupCount ?? 0
                )}`,
                b: groupsList?.groupCount ?? 0,
              })}
            </Typography>
          </Button>
          <Menu
            open={open}
            anchorEl={anchorRef.current}
            onClickAway={handleClose}
            onKeyDown={handleListKeyDown}
            placement="bottom-start"
            modifiers={{ preventOverflow: { enabled: false } }}
          >
            <WebMenuItem onClick={() => setSortOrder("desc")}>
              <Typography category="Default" variant="Body">
                {t("Newest")}
              </Typography>
            </WebMenuItem>
            <WebMenuItem onClick={() => setSortOrder("asc")}>
              <Typography category="Default" variant="Body">
                {t("Oldest")}
              </Typography>
            </WebMenuItem>
          </Menu>
          <IconButton
            disabled={currentPage === 0}
            className={classes.pageArrowBtn}
            style={{ marginRight: 10 }}
            onClick={() => setCurrentPage((p) => Math.max(p - 1, 0))}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            disabled={currentPage === maxPage}
            className={classes.pageArrowBtn}
            onClick={() => setCurrentPage((p) => Math.min(p + 1, maxPage))}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>
    );
  }, [
    classes.loadingDiv,
    classes.pageArrowBtn,
    classes.pageSortBtn,
    classes.paginationDiv,
    classes.tableDiv,
    currentPage,
    groupsList?.groupCount,
    history,
    list,
    loading,
    maxPage,
    mobile,
    moreGroup,
    onSelectDelete,
    open,
    searchKey,
    t,
    userProfile?.userType,
  ]);

  return (
    <div className={classes.root} dir={theme.direction}>
      <div
        className={clsx(classes.subHeaderDiv, {
          [classes.subHeaderDivClosed]: !openMenu,
          [classes.subHeaderDivMobile]: mobile,
        })}
      >
        <div
          className={clsx(
            classes.subHeader,
            openSearch && classes.subHeaderOpenInput
          )}
        >
          <div className={clsx(classes.btnDiv)}>
            <Button
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => history.push("/groups/add-group")}
              disabled={getAddBtnStatus()}
              className={clsx(classes.addBtn, {
                [classes.hidden]: mobile && openSearch,
              })}
            >
              {t("Add group")}
            </Button>
          </div>
          <div
            className={clsx(classes.btnDiv, {
              [classes.searchBtnOpen]: openSearch && mobile,
            })}
          >
            <Input
              inputID="groupSearch"
              placeholder={t("Search group name")}
              startIcon={
                <Tooltip
                  disableTouchListener={mobile}
                  placement="top"
                  PopperProps={{
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "0, -12px",
                      },
                      flip: {
                        enabled: false,
                      },
                    },
                  }}
                  title={
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["0"]}
                    >
                      {t("search")}
                    </Typography>
                  }
                >
                  <SearchIcon />
                </Tooltip>
              }
              className={clsx(classes.searchBtn, classes.searchIcon, {
                [classes.mobileSearchOpen]: mobile,
                [classes.webSearchOpen]: openSearch,
              })}
              onSearch={() => {
                requestGroups(searchKey.trim());
              }}
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
              search
              dense
              onOpenSearch={() => setOpenSearch(true)}
              onCloseSearch={() => {
                setSearchKey("");
                setSearchedKey("");
                setOpenSearch(false);
                requestGroups("");
              }}
            />
          </div>
        </div>
      </div>

      <div className={classes.body}>{groupListMarkup}</div>

      {!mobile && (
        <CameraMenu
          open={openCamMenu}
          anchorRef={anchorMoreRef}
          placement="bottom-end"
          onClickAway={() => setOpenCamMenu(false)}
          onDelete={
            userProfile?.userType !== "Master"
              ? null
              : () => moreGroup && onDelete?.(moreGroup)
          }
          onRename={
            userProfile?.userType !== "Master"
              ? null
              : () => moreGroup && onRename?.(moreGroup)
          }
        />
      )}

      {mobile && (
        <MobileCameraMenu
          open={openCamMenu}
          onClose={() => setOpenCamMenu(false)}
          onDelete={
            userProfile?.userType !== "Master"
              ? null
              : () => {
                  moreGroup && onDelete?.(moreGroup);
                  setOpenCamMenu(false);
                }
          }
          onRename={
            userProfile?.userType !== "Master"
              ? null
              : () => {
                  moreGroup && onRename?.(moreGroup);
                  setOpenCamMenu(false);
                }
          }
        />
      )}
    </div>
  );
};
