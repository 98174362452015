import React from "react";
import { Modal } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Webviewer } from "../../contants/Breakpoints";

interface ExceedModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalTitle: {
    minHeight: 16,
    padding: `18px 24px 0 24px`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: `13px 24px 0 13px` }
        : { padding: `13px 13px 0 24px` }),
    },
  },
  exceedModalRoot: {
    minWidth: 288,
  },
  exceedModalContentDiv: {
    padding: theme.spacing(3),
  },
}));

export const ExceedModal = ({
  open,
  onClose,
  onClickPositive,
}: ExceedModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      open={open}
      close
      onClose={onClose}
      onClickPositive={onClickPositive}
      heading={"  "}
      content={t("Exceeded video play_")}
      RButton={t("OK")}
      titleClassName={classes.modalTitle}
      className={classes.exceedModalRoot}
      contentClassName={classes.exceedModalContentDiv}
    />
  );
};
