import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GoogleMapReact from "google-map-react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  EventCard,
  Fab,
  LightColors,
  MarkerCluster,
  Menu,
  MobileMenu,
  Tooltip,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";

import CheckIcon from "@material-ui/icons/Check";

import { TopRightControl } from "./TopRightControl";
import {
  convertGeofenceToGeometries,
  exitFullscreen,
  getTextWidth,
  isFullscreen,
  renderGeofences,
  requestFullscreen,
} from "../../utils/GoogleMap";
import { RootState } from "../../features/store";
import { IGPSLocation, IInfoWindow, ILatLngBounds } from "../../types";

import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Webviewer } from "../../contants/Breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PUSH_EVENT } from "../../features/PushEvent/slice";
import {
  CAMERA,
  ICameraInfo,
  IPublicCamera,
} from "../../features/Camera/slice";
import { INITIAL_MAP_LOCATION } from "./GoogleMap";
import { IGeofence } from "../../features/Geofence/slice";
import { GOOGLE_API_KEY } from "../../contants/GoogleMap";
import { loadUserSettings, USER } from "../../features/User/slice";

const useStyles = makeStyles((theme: Theme) => ({
  topControlPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
  },
  zoomControlDiv: {
    position: "absolute",
    bottom: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
  },
  zoomControlBtn: {
    borderRadius: 8,
    width: 30,
    height: 30,
    color: LightColors.primary["3"],
    minHeight: 30,
  },
  gpsFixBtn: {
    marginBottom: theme.spacing(0.5),
  },
  zoomInBtn: {
    borderBottom: 0,
    borderRadius: theme.spacing(1, 1, 0, 0),
    boxShadow: "none",
  },
  zoomOutBtn: {
    borderTop: 0,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: "none",
  },
  tnpDiv: {
    ...(theme.direction === "rtl" ? { paddingRight: 44 } : { paddingLeft: 44 }),
  },
  appIcon: {
    fontSize: 15,
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
  directionMakerIcon: {
    width: 20,
    height: 20,
    zIndex: 1,
  },
  markerIcon: {
    cursor: "pointer",
    transform: "translate(-17px, -32px)",
    width: 34,
    height: 38,
    zIndex: 2,
  },
  selectedMarkerIcon: {
    cursor: "pointer",
    transform: "translate(-19px, -38px)",
    width: 38,
    height: 42,
  },
  startMarkerIcon: {
    zIndex: 2,
    transform: "translate(-12px, -12px)",
    filter:
      "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.14)), drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12)), drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2))",
  },
  infoWindowDiv: {
    width: 300,
    backgroundColor: LightColors.primary["0"],
    borderRadius: 4,
    border: `1px sloid ${LightColors.primary["6"]}`,
  },
  privateCamDiv: {
    width: 120,
    padding: theme.spacing(1),
    backgroundColor: LightColors.primary["0"],
    borderRadius: 4,
    border: `1px sloid ${LightColors.primary["6"]}`,
    display: "flex",
    alignItems: "center",
  },
  privateCamWindowCenter: {
    transform: "translate(-70px, calc(-100% - 20px))",
  },
  parkingDiv: {
    maxHeight: 421,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  infoWindowCenter: {
    transform: "translate(-150px, calc(-100% - 40px))",
  },
  inforParkingTitle: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  textTransform: {
    textTransform: "none",
  },
}));

interface LocationMapProps {
  onGoogleApiLoaded?: (maps: {
    map: any;
    maps: any;
    ref: Element | null;
  }) => void;
  publicIcon?: boolean;
  fullscreenIcon?: boolean;
  trackMarker?: boolean;
  location?: IGPSLocation;
  locations?: IGPSLocation[];
  children?: React.ReactNode;
  showHeading?: boolean;
  disableAutoZoom?: boolean;
  noMarker?: boolean;
  center?: { lat: number; lng: number; zoom?: number };
  mode?: "default" | "liveview";
  onSetInfoWindow?: (infoWindow?: IInfoWindow) => void;
  onUpdateBounds?: (bounds: ILatLngBounds) => void;
  publicCams?: IPublicCamera[];
  filteredCams?: ICameraInfo[];
  onLiveView?: (psn: string, isPublic: boolean) => void;
  geofences?: IGeofence[];
  mapRef?: React.RefObject<HTMLDivElement>;
}

export const LocationMap = (props: LocationMapProps) => {
  const {
    onGoogleApiLoaded,
    publicIcon,
    fullscreenIcon,
    trackMarker,
    location,
    locations,
    children,
    showHeading,
    center,
    disableAutoZoom,
    noMarker,
    mode,
    publicCams,
    filteredCams,
    onSetInfoWindow,
    onUpdateBounds,
    onLiveView,
    geofences,
    mapRef,
  } = props;
  // useTraceUpdate(props);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const anchorRef = useRef<HTMLButtonElement>(null);
  const mapDivRef = useRef<HTMLDivElement>(null);

  const { clusters } = useSelector((state: RootState) => state[CAMERA]);
  const { webPushEvent } = useSelector((state: RootState) => state[PUSH_EVENT]);
  const { userSettings } = useSelector((state: RootState) => state[USER]);

  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [openLayer, setOpenLayer] = useState(false);
  const [mapMode, setMapMode] = useState<"map" | "satellite">("map");
  const [markers, setMarkers] = useState<{ [key: string]: any }>({});
  // const [gMarkers, setGMarkers] = useState<any[]>([]);
  const [bounds, setBounds] = useState<ILatLngBounds>();
  const [selectedMarker, setSelectedMarker] = useState<any>();
  const [privateCamMarker, setPrivateCamMarker] = useState<any>();
  const [activePublic, setActivePublic] = useState(true);

  const prevLocation = useRef<IGPSLocation>();
  const locationMarker = useRef<any>();
  const gMarkers = useRef<any[]>([]);
  const publicMarkers = useRef<any[]>([]);
  const prevBounds = useRef<any>();

  const [fullscreen, setfullscreen] = useState(false);
  const [initMap, setInitMap] = useState(false);

  const geometries = useMemo(() => {
    if (geofences) {
      return convertGeofenceToGeometries(geofences);
    } else {
      return [];
    }
  }, [geofences]);

  useEffect(() => {
    dispatch(loadUserSettings());
  }, [dispatch]);

  useEffect(() => {
    if (map && maps && geometries && geometries.length > 0) {
      const objects = renderGeofences(map, maps, geometries, mobile, false);
      return () => {
        _.forEach(objects, (o) => o.setMap(null));
      };
    }
  }, [geometries, map, maps, mobile]);

  const getSVGIcon = useCallback(
    (loc: IGPSLocation, isEvent: boolean, isPublic?: boolean) => {
      let nameLabel = "";
      if (loc.name) {
        let name = loc.name ?? "";
        let textWidth = getTextWidth(name, "11px Roboto") + 16;
        if (textWidth > 100) {
          do {
            name = name.substring(0, name.length - 2);

            textWidth = getTextWidth(name, "11px Roboto");
          } while (textWidth > 84);
          name = name + "...";
          textWidth = getTextWidth(name, "11px Roboto") + 16;
        }
        const speed = parseFloat(loc.speed ?? "0");
        const speedValue =
          loc.active === "off" || speed <= 0
            ? `0 km/h`
            : userSettings?.velocityUnit === "0"
            ? `${Math.floor(speed * 1.852)} km/h`
            : `${Math.floor(speed * 1.150779)} MPH`;
        const speedText =
          loc.speed &&
          loc.mode === "0" &&
          `<text x="53" y="59" font-family="Roboto" font-size="9" text-anchor="middle" fill="${LightColors.primary[
            "2"
          ].replace("#", "%23")}" >${speedValue}</text>`;
        const speedWidth = getTextWidth(speedValue, "9px Roboto") + 16;
        if (textWidth < speedWidth) {
          textWidth = Math.min(speedWidth, 100);
        }

        nameLabel = `<g filter="url(%23filter0_ddd)">
        <rect x="${
          (106 - textWidth) / 2
        }" y="35" width="${textWidth}" height="${
          loc.mode === "0" ? 29 : 19
        }" rx="4" ry="4" fill="white" />
        </g>
        <text x="53" y="48" font-family="Roboto" font-size="11" text-anchor="middle" fill="${LightColors.primary[
          "1"
        ].replace("#", "%23")}" >${name}</text>
        ${speedText}`;
      }

      const heading = loc.heading;
      let markerColor = LightColors.primary["7"].replace("#", "%23");
      let markerStroke = LightColors.primary["0"].replace("#", "%23");
      if (isEvent) {
        markerColor = LightColors.secondary["11"].replace("#", "%23");
      }
      if (isPublic) {
        markerColor = LightColors.primary["0"].replace("#", "%23");
        markerStroke = LightColors.primary["7"].replace("#", "%23");
      }
      if (loc.active === "off") {
        markerColor = LightColors.primary["3"].replace("#", "%23");
      }

      let camSvg = `<rect x="12.5" y="4.5" width="15" height="9" rx="2.5" fill="%2313131C" stroke="white"/>
                    <circle cx="18" cy="9" r="1.5" fill="%2313131C" stroke="white"/>
                    <circle cx="26" cy="6" r="2.5" fill="%23D81A26" stroke="white"/>`;
      if ((isPublic && loc.share_video !== "on") || loc.active === "off") {
        camSvg = "";
      }

      let markerSvg = `<path d="M11.4688 23.0938C12.6979 22.5938 13.875 22.3438 15 22.3438C16.125 22.3438 17.2917 22.5938 18.5 23.0938C19.7292 23.5729 20.3438 24.2083 20.3438 25V26.3438H9.65625V25C9.65625 24.2083 10.2604 23.5729 11.4688 23.0938ZM16.875 20.2188C16.3542 20.7396 15.7292 21 15 21C14.2708 21 13.6458 20.7396 13.125 20.2188C12.6042 19.6979 12.3438 19.0729 12.3438 18.3438C12.3438 17.6146 12.6042 16.9896 13.125 16.4688C13.6458 15.9271 14.2708 15.6562 15 15.6562C15.7292 15.6562 16.3542 15.9271 16.875 16.4688C17.3958 16.9896 17.6562 17.6146 17.6562 18.3438C17.6562 19.0729 17.3958 19.6979 16.875 20.2188Z" fill="${markerStroke}"/>`;
      if (loc.mode === "1" && loc.active === "on") {
        markerSvg = `<path d="M16.2109 20.4258C16.5208 20.4258 16.7852 20.3073 17.0039 20.0703C17.2409 19.8333 17.3594 19.5599 17.3594 19.25C17.3594 18.9401 17.2409 18.6667 17.0039 18.4297C16.7852 18.1927 16.5208 18.0742 16.2109 18.0742H14.3242V20.4258H16.2109ZM16.0742 15.75C17.0404 15.75 17.8607 16.0964 18.5352 16.7891C19.2279 17.4635 19.5742 18.2839 19.5742 19.25C19.5742 20.2161 19.2279 21.0456 18.5352 21.7383C17.8607 22.4128 17.0404 22.75 16.0742 22.75H14.3242V26.25H12V15.75H16.0742Z" fill="${markerStroke}"/>`;
      }
      let directionMarkup = ``;
      if (loc.mode === "0" && showHeading) {
        directionMarkup = `<g transform="translate(-4, -16)">
                    <g  transform="rotate(${heading}, 19, 38)">
                      <path opacity="0.85" d="M19 40L9.00003 34.5L3.01998e-05 4.30733e-06L38 3.8147e-06L29 34.5L19 40Z" fill="url(%23paint0_linear)"/> 
                    </g>
                  </g>`;
      }

      const width = loc.name || !isPublic ? 106 : 40;
      const height = loc.name || !isPublic ? 88 : 44;
      const vMinX = (106 - width) / 2;
      const vMinY = (88 - height) / 2;
      //    <svg width="106" height="88" viewBox="0 0 106 88" fill="none" xmlns="http://www.w3.org/2000/svg">

      return `data:image/svg+xml;utf-8,
    <svg width="${width}" height="${height}" viewBox="${vMinX} ${vMinY} ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0, 20)">
        ${nameLabel}
        <g transform="translate(38, 0)">
          ${directionMarkup}
          <g filter="url(%23filter1_ddd)">
            <circle cx="15" cy="21" r="11.5" fill="${markerColor}" stroke="${markerStroke}"/>
            <g>
              ${markerSvg}
            </g>
            ${camSvg}
          </g>
        </g>
      </g>
      
      <defs>
      <filter id="filter0_ddd" x="0" y="32" width="106" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
      </filter>
      <filter id="filter1_ddd" x="0" y="0" width="32" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="0.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="0.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
        <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
      </filter>
      <clipPath id="clip0">
      <rect width="24" height="24" fill="white" transform="translate(3 9)"/>
      </clipPath>

      <linearGradient id="paint0_linear" x1="19" y1="40" x2="19" y2="-6.66667" gradientUnits="userSpaceOnUse">
      <stop offset="0.238239" stop-color="%230095E0"/>
      <stop offset="0.833168" stop-color="%230095E0" stop-opacity="0"/>
      </linearGradient>
      </defs>
    </svg>`;
    },
    [showHeading, userSettings?.velocityUnit]
  );

  useEffect(() => {
    if (bounds) {
      onUpdateBounds?.(bounds);
    }
  }, [bounds, onUpdateBounds]);

  useEffect(() => {
    if (mode === "liveview" && center && map) {
      const { lat, lng } = center;
      if (lat && lng) {
        map.setCenter({ lat, lng });
      }
    } else if (center && map && prevBounds.current === undefined) {
      //메인 지도 화면에서 카메라 선택시 이동하는 로직
      prevBounds.current = map.getBounds();
      const { zoom, lat, lng } = center;
      if (lat && lng) {
        map.setCenter({ lat, lng });
      }
      if (zoom && map.getZoom() <= zoom) {
        map.setZoom(zoom);
      }
    } else if (map && center === undefined && prevBounds.current) {
      //메인 지도 화면에서 상세보기에서 빠져나오는 경우
      // map.fitBounds(prevBounds.current);
      prevBounds.current = undefined;
    }
  }, [center, map, mode]);

  useEffect(() => {
    function exitHandler() {
      if (
        !document.fullscreenElement &&
        //@ts-ignore
        !document.webkitIsFullScreen &&
        //@ts-ignore
        !document.mozFullScreen &&
        //@ts-ignore
        !document.msFullscreenElement
      ) {
        ///fire your event
        setfullscreen(false);
      }
    }
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  useEffect(() => {
    if (map && mode !== "liveview" && maps && activePublic && !clusters) {
      _.forEach(publicCams, (cam) => {
        if (cam.active === "off") {
          return;
        }
        const loc = {
          lat: cam.lat,
          lng: cam.lng,
          heading: 0,
          psn: cam.psn,
          name: cam.dev_name,
          speed: cam.speed,
          share_video: cam.share_video,
          mode: cam.mode,
          active: cam.active,
        };
        const current = publicMarkers.current;
        const found = _.find(current, (marker) => marker.psn === cam.psn);
        const width = loc.name ? 106 : 40;
        const height = loc.name ? 67 : 33;
        const anchorX = loc.name ? 53 : 20;
        const anchorY = loc.name ? 32 : 16;
        if (found) {
          found.setPosition({ lat: cam.lat, lng: cam.lng });
          found.setIcon({
            // anchor: new maps.Point(53, 32),
            // size: new maps.Size(106, 67),
            // scaledSize: new maps.Size(106, 67),
            anchor: new maps.Point(anchorX, anchorY),
            size: new maps.Size(width, height),
            scaledSize: new maps.Size(width, height),
            url: getSVGIcon(loc, false, true),
          });
        } else {
          const marker = new maps.Marker({
            position: { lat: cam.lat, lng: cam.lng },
            map: map,
            zIndex: 1,
            icon: {
              anchor: new maps.Point(anchorX, anchorY),
              size: new maps.Size(width, height),
              scaledSize: new maps.Size(width, height),
              url: getSVGIcon(loc, false, true),
            },
          });
          marker.psn = cam.psn;
          if (loc.active === "on") {
            if (loc.share_video === "on") {
              marker.addListener("click", () => {
                onLiveView?.(marker.psn, true);
              });
            } else {
              marker.addListener("click", () => {
                setPrivateCamMarker(marker);
              });
            }
          }
          publicMarkers.current.push(marker);
        }
      });
    } else {
      _.forEach(publicMarkers.current, (m) => m.setMap(null));
      publicMarkers.current = [];
    }
  }, [
    activePublic,
    clusters,
    getSVGIcon,
    map,
    maps,
    mode,
    onLiveView,
    publicCams,
  ]);

  useEffect(() => {
    if (map && maps) {
      const initZoom = mode === "liveview" ? 15 : 17;
      const idleListener = map.addListener("idle", () => {
        const newBounds = map.getBounds().toJSON();
        if (!_.isEqual(bounds, newBounds)) {
          setBounds(newBounds);
        }
      });
      const zoomListener = map.addListener("zoom_changed", () => {
        if (map.initialZoom === true) {
          const boundsListener = map.addListener("bounds_changed", () => {
            console.log("bounds_changed");
            if (map.getZoom() > initZoom) {
              // Change max/min zoom here
              map.setZoom(initZoom);
              map.initialZoom = false;
            }
            //@ts-ignore
            maps.event.removeListener(boundsListener);
          });
        }
      });

      const clickListener = map.addListener("click", () => {
        onSetInfoWindow?.(undefined);
        setPrivateCamMarker(undefined);
      });
      return () => {
        //@ts-ignore
        maps.event.removeListener(zoomListener);
        maps.event.removeListener(clickListener);
        maps.event.removeListener(idleListener);
      };
    }
  }, [onSetInfoWindow, map, maps, bounds, mode]);

  useEffect(() => {
    if (!location) {
      locationMarker.current?.setMap(null);
      locationMarker.current = undefined;
      prevLocation.current = undefined;
    }
  }, [location]);

  useEffect(() => {
    if (map && maps && trackMarker && location) {
      if (prevLocation.current) {
        map.setCenter({ lat: location.lat, lng: location.lng });
      } else {
        map.initialZoom = true;

        const { lat, lng } = location;
        map.fitBounds({
          east: lng,
          north: lat,
          west: lng,
          south: lat,
        });
      }
      if (!noMarker) {
        if (locationMarker.current) {
          locationMarker.current?.setPosition(location);
          locationMarker.current?.setIcon({
            anchor: new maps.Point(53, 32),
            size: new maps.Size(106, 67),
            scaledSize: new maps.Size(106, 67),
            url: getSVGIcon(location, false),
          });
        } else {
          locationMarker.current = new maps.Marker({
            position: location,
            map: map,
            icon: {
              anchor: new maps.Point(53, 32),
              size: new maps.Size(106, 67),
              scaledSize: new maps.Size(106, 67),
              url: getSVGIcon(location, false),
            },
          });

          locationMarker.current.setMap(map);
        }
      }
      prevLocation.current = location;
    }
  }, [trackMarker, location, map, maps, getSVGIcon, noMarker]);

  useEffect(() => {
    if (clusters && map && mode !== "liveview" && publicIcon) {
      let minLat = 999,
        minLng = 999,
        maxLat = -999,
        maxLng = -999;
      let ms: {} = {};
      if (activePublic) {
        for (let indx in clusters["zone count"]?.info ?? []) {
          const zone = clusters["zone count"]?.info[indx];
          if (zone) {
            const lat = parseFloat(zone.lat);
            const lng = parseFloat(zone.lng);
            const cnt = parseInt(zone.count);
            minLat = Math.min(minLat, lat);
            minLng = Math.min(minLng, lng);
            maxLat = Math.max(maxLat, lat);
            maxLng = Math.max(maxLng, lng);
            ms = {
              ...ms,
              [`zone-${indx}`]: (
                <MarkerCluster
                  key={`zone-${indx}`}
                  lat={lat}
                  lng={lng}
                  size={cnt < 100 ? "small" : "medium"}
                  variant="outlined"
                  label={zone.count}
                  onClick={() => {
                    map.setCenter({ lat, lng });
                    map.setZoom(map.getZoom() + 1);
                  }}
                />
              ),
            };
          }
        }
      }
      for (let indx in clusters.myzone?.info ?? []) {
        const zone = clusters.myzone?.info[indx];
        if (zone) {
          const lat = parseFloat(zone.lat);
          const lng = parseFloat(zone.lng);
          const cnt = parseInt(zone.count);
          minLat = Math.min(minLat, lat);
          minLng = Math.min(minLng, lng);
          maxLat = Math.max(maxLat, lat);
          maxLng = Math.max(maxLng, lng);
          ms = {
            ...ms,
            [`myzone-${indx}`]: (
              <MarkerCluster
                key={`myzone-${indx}`}
                lat={lat}
                lng={lng}
                size={cnt < 100 ? "small" : "medium"}
                variant="default"
                label={zone.count}
                style={{ zIndex: 10 }}
                onClick={() => {
                  map.setCenter({ lat, lng });
                  map.setZoom(map.getZoom() + 1);
                }}
              />
            ),
          };
        }
      }
      setMarkers(ms);
    } else {
      setMarkers({});
    }
  }, [map, clusters, publicIcon, activePublic, mode]);

  useEffect(() => {
    return () => {
      _.forEach(gMarkers.current, (c) => c.setMap(null));
      gMarkers.current = [];
      _.forEach(publicMarkers.current, (c) => c.setMap(null));
      publicMarkers.current = [];
    };
  }, []);

  useEffect(() => {
    if (!activePublic) {
      _.forEach(publicMarkers.current, (c) => c.setMap(null));
      publicMarkers.current = [];
    }
  }, [activePublic]);

  useEffect(() => {
    if (webPushEvent && maps) {
      const current = gMarkers.current;
      const found = _.find(current, (loc) => loc.psn === webPushEvent.psn);
      let isEvent = true;
      if (found) {
        found.event = webPushEvent;
        found.setClickable(true);
        found.addListener("click", () => {
          onLiveView?.(found.psn, false);
          //팝업 열어주는 코드
          // setSelectedMarker(found);
        });
        const timerId = setInterval(() => {
          found.setIcon({
            anchor: new maps.Point(53, 32),
            size: new maps.Size(106, 67),
            scaledSize: new maps.Size(106, 67),
            url: getSVGIcon(found.loc, isEvent),
          });
          isEvent = !isEvent;
        }, 500);
        return () => {
          clearInterval(timerId);
        };
        // setSelectedMarker(found);
      }
    } else {
      // setSelectedMarker(undefined);
    }
  }, [getSVGIcon, maps, onLiveView, webPushEvent]);

  useEffect(() => {
    if (locations && map && maps && (!clusters || mode === "liveview")) {
      const current = gMarkers.current;
      const psns = _.map(locations, (loc) => loc.psn);
      const filteredPsns = _.map(filteredCams, (cam) => cam.psn);
      const removed = _.filter(current, (loc) => {
        const l = _.find(locations, (ll) => ll.psn === loc.psn);
        if (l) {
          return (
            !_.includes(psns, loc.psn) ||
            (filteredCams && !_.includes(filteredPsns, loc.psn)) ||
            l.lat === 0 ||
            l.lng === 0
          );
        }
        return true;
      });
      const updated = _.filter(current, (loc) => {
        const l = _.find(locations, (ll) => ll.psn === loc.psn);
        if (l) {
          return (
            _.includes(psns, loc.psn) &&
            (!filteredCams || _.includes(filteredPsns, loc.psn)) &&
            !(l.lat === 0 || l.lng === 0)
          );
        }
        return false;
      });

      _.forEach(removed, (r) => r.setMap(null));

      let needToUpdate = false;
      const ms = _.chain(locations)
        .filter(
          (loc) =>
            (filteredCams ? _.includes(filteredPsns, loc.psn) : true) &&
            !(loc.lat === 0 || loc.lng === 0)
        )
        .map((loc) => {
          const found = _.find(updated, (c) => c.psn === loc.psn);
          const isPublic = !!_.find(publicCams, (c) => c.psn === loc.psn);
          // const isEvent = webPushEvent?.psn === loc.psn;
          const withName = loc.name;
          const width = withName ? 106 : 40;
          const height = withName ? 67 : 33;
          const anchorX = withName ? 53 : 20;
          const anchorY = withName ? 32 : 16;
          if (found) {
            found.setPosition({ lat: loc.lat, lng: loc.lng });
            found.setIcon({
              // anchor: new maps.Point(53, 32),
              // size: new maps.Size(106, 67),
              // scaledSize: new maps.Size(106, 67),

              anchor: new maps.Point(anchorX, anchorY),
              size: new maps.Size(width, height),
              scaledSize: new maps.Size(width, height),
              url: getSVGIcon(loc, false, isPublic),
            });
            // if (!isEvent) {
            //   found.setClickable(false);
            //   // found.setAnimation(null);
            //   maps.event.clearListeners(found, "click");
            //   found.event = undefined;
            // }
            return found;
          } else {
            needToUpdate = true;
            const marker = new maps.Marker({
              position: { lat: loc.lat, lng: loc.lng },
              map: map,
              zIndex: 1,
              icon: {
                // anchor: new maps.Point(53, 32),
                // size: new maps.Size(106, 67),
                // scaledSize: new maps.Size(106, 67),

                anchor: new maps.Point(anchorX, anchorY),
                size: new maps.Size(width, height),
                scaledSize: new maps.Size(width, height),
                url: getSVGIcon(loc, false, isPublic),
              },
            });
            marker.loc = loc;
            marker.psn = loc.psn;
            marker.model = loc.model;
            if (loc.active === "on") {
              marker.addListener("click", () => {
                onLiveView?.(marker.psn, false);
              });
            }
            return marker;
          }
        })
        .value();
      if (
        needToUpdate &&
        (!disableAutoZoom ||
          (mode === "liveview" && locations.length === 1) ||
          !initMap)
      ) {
        let minLat = 999,
          minLng = 999,
          maxLat = -999,
          maxLng = -999;
        _.forEach(ms, (m) => {
          const lat = m.getPosition().lat();
          const lng = m.getPosition().lng();
          minLat = Math.min(lat, minLat);
          minLng = Math.min(lng, minLng);
          maxLat = Math.max(lat, maxLat);
          maxLng = Math.max(lng, maxLng);
        });

        map.initialZoom = true;
        map.fitBounds({
          east: maxLng,
          north: maxLat,
          west: minLng,
          south: minLat,
        });
        setInitMap(true);
      }
      gMarkers.current = ms;
    } else {
      const current = gMarkers.current;
      _.forEach(current, (m) => m.setMap(null));
      gMarkers.current = [];
    }
  }, [
    clusters,
    disableAutoZoom,
    filteredCams,
    getSVGIcon,
    initMap,
    locations,
    map,
    maps,
    mode,
    onLiveView,
    publicCams,
    webPushEvent,
  ]);

  const handleDashcamPublic = useCallback(() => {
    // dispatch(loadCluster());
    setActivePublic((a) => !a);
  }, []);

  const handleFullscreen = useCallback(() => {
    let elementToSendFullscreen: HTMLDivElement | null =
      mapRef?.current ?? mapDivRef.current;
    if (elementToSendFullscreen) {
      if (isFullscreen(elementToSendFullscreen)) {
        exitFullscreen(elementToSendFullscreen);
        setfullscreen(false);
      } else {
        requestFullscreen(elementToSendFullscreen);
        setfullscreen(true);
      }
    }
  }, [mapRef]);

  const renderMapTopRightMenu = useCallback(() => {
    return (
      <div className={classes.topControlPane}>
        <TopRightControl
          ref={anchorRef}
          onFullscreen={fullscreenIcon ? handleFullscreen : undefined}
          onLayers={() => setOpenLayer((o) => !o)}
          onDashcamPublic={publicIcon ? handleDashcamPublic : undefined}
          fullscreen={fullscreen}
          activePublic={activePublic}
        />
        {!mobile && (
          <Menu
            open={openLayer}
            onClickAway={() => setOpenLayer(false)}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            modifiers={{
              offset: {
                enabled: true,
                offset: "0, 4px",
              },
              preventOverflow: {
                enabled: false,
              },
            }}
          >
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "satellite",
              })}
              startIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "map",
              })}
              startIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </Menu>
        )}
        {mobile && (
          <MobileMenu
            open={openLayer}
            onClose={() => setOpenLayer(false)}
            container={anchorRef.current}
            classes={{ root: classes.textTransform }}
          >
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map?.setMapTypeId("roadmap");
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map?.setMapTypeId("satellite");
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </MobileMenu>
        )}
      </div>
    );
  }, [
    classes.topControlPane,
    classes.tnpDiv,
    classes.appIcon,
    classes.textTransform,
    classes.mobileMemuItem,
    fullscreenIcon,
    handleFullscreen,
    publicIcon,
    handleDashcamPublic,
    fullscreen,
    activePublic,
    mobile,
    openLayer,
    mapMode,
    t,
    map,
  ]);

  const renderZoomPanel = useCallback(() => {
    return (
      <div className={classes.zoomControlDiv}>
        <Tooltip
          placement="left"
          disableTouchListener={mobile}
          title={t("My location") ?? "My location"}
        >
          <Fab
            size="small"
            variant="rounded"
            className={clsx(classes.zoomControlBtn, classes.gpsFixBtn)}
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position: GeolocationPosition) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };
                    map.setCenter(pos);
                  },
                  () => {}
                );
              }
            }}
          >
            <GpsFixedIcon fontSize="small" />
          </Fab>
        </Tooltip>
        {!mobile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 8,
              boxShadow:
                "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Tooltip title={t("Zoom in") ?? "Zoom in"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomInBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() + 1);
                }}
              >
                <AddIcon fontSize="small" />
              </Fab>
            </Tooltip>
            <Tooltip title={t("Zoom out") ?? "Zoom out"}>
              <Fab
                size="small"
                variant="rounded"
                className={clsx(classes.zoomControlBtn, classes.zoomOutBtn)}
                onClick={() => {
                  map.setZoom(map.getZoom() - 1);
                }}
              >
                <RemoveIcon fontSize="small" />
              </Fab>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }, [
    classes.zoomControlDiv,
    classes.zoomControlBtn,
    classes.gpsFixBtn,
    classes.zoomInBtn,
    classes.zoomOutBtn,
    mobile,
    t,
    map,
  ]);

  const infoWindow = useMemo(() => {
    if (selectedMarker && selectedMarker.event) {
      const latLng = selectedMarker.getPosition();
      console.log("renderInfoWindow", latLng, selectedMarker.event);
      return (
        <div
          //@ts-ignore
          lat={latLng.lat()}
          //@ts-ignore
          lng={latLng.lng()}
          className={clsx(
            classes.infoWindowDiv,
            classes.infoWindowCenter,
            classes.parkingDiv
          )}
        >
          <EventCard
            event={selectedMarker.event}
            fileType="Event"
            model={selectedMarker.model}
            onClose={() => setSelectedMarker(undefined)}
          />
        </div>
      );
    }
    return undefined;
  }, [
    classes.infoWindowCenter,
    classes.infoWindowDiv,
    classes.parkingDiv,
    selectedMarker,
  ]);

  const privateCamWindow = useMemo(() => {
    if (privateCamMarker) {
      const found = _.find(
        publicCams,
        (cam) => cam.psn === privateCamMarker.psn
      );
      console.log("found", found);
      if (found) {
        return (
          <div
            //@ts-ignore
            lat={found.lat}
            //@ts-ignore
            lng={found.lng}
            className={clsx(
              classes.parkingDiv,
              classes.privateCamWindowCenter,
              classes.privateCamDiv
            )}
          >
            {t("This camera is publicly_")}
          </div>
        );
      }
    }
    return undefined;
  }, [
    classes.parkingDiv,
    classes.privateCamDiv,
    classes.privateCamWindowCenter,
    privateCamMarker,
    publicCams,
    t,
  ]);

  return (
    <div
      ref={fullscreen ? mapRef ?? mapDivRef : mapDivRef}
      style={{ width: "100%", height: "100%" }}
    >
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <GoogleMapReact
          style={{
            width: "100%",
            height: "100%",
            //@ts-ignore
            position: "relative",
            touchAction: "none",
          }}
          bootstrapURLKeys={{
            key: GOOGLE_API_KEY,
            libraries: ["geometry", "drawing"],
          }}
          defaultCenter={INITIAL_MAP_LOCATION}
          defaultZoom={4}
          options={{ disableDefaultUI: true }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ maps, map, ref }) => {
            setMap(map);
            setMaps(maps);
            onGoogleApiLoaded?.({ maps, map, ref });
          }}
          // onChange={(value) => console.log(value)}
        >
          {_.values(markers)}
          {children}
          {selectedMarker && infoWindow}
          {privateCamMarker && privateCamWindow}
        </GoogleMapReact>
        {renderMapTopRightMenu()}
        {renderZoomPanel()}
      </div>
    </div>
  );
};
