import React, { useState } from "react";
import { LightColors, Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { Webviewer } from "../../contants/Breakpoints";
import { TextField } from "@material-ui/core";

interface SubCancelModalProps {
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: (reason?: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 560,
    },
  },
  mgB32: {
    marginBottom: 32,
  },
  inputH: {
    minHeight: 88,
  },
  textArea: {
    width: "100%",
    "&>.MuiOutlinedInput-multiline": {
      padding: "10px 16px",
      maxHeight: 88,
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: `${LightColors.primary["4"]}`,
      },
    },
  },

  textLength: {
    width: "94%",
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 3,
    paddingBottom: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 0,
    },
  },

  focused: {
    "& > fieldset.MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px!important",
      borderColor: `${LightColors.primary["7"]}!important`,
    },
  },
}));
export const SubCancelModal = ({
  open,
  loading,
  onClose,
  onClickNegative,
  onClickPositive,
}: SubCancelModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const [textLeng, setTextLeng] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 280) {
      setTextLeng(value);
    }
  };
  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={() => onClickPositive?.(textLeng)}
      className={classes.root}
      heading={t("Cancel Fleet Plan")}
      close={true}
      fullSizeSub={mobile}
      content={
        <div>
          <div className={classes.mgB32}>
            <Typography category="Default" variant="Body">
              {t("This will cancel_2")}
            </Typography>
          </div>
          <div>
            <TextField
              className={classes.textArea}
              style={{ minWidth: mobile ? 288 : "" }}
              id="outlined-textarea"
              placeholder="Reason for cancellation (optional)"
              multiline
              variant="outlined"
              rows={4}
              value={textLeng}
              onChange={handleChange}
              name="textLeng"
              InputProps={{
                classes: {
                  focused: classes.focused,
                },
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classes.textLength}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                style={{ justifyContent: "flex-end" }}
              >
                {textLeng.length}/280
              </Typography>
            </div>
          </div>
        </div>
      }
      LButton={t("Not now")}
      RButton={t("Confirm")}
      loading={loading}
      Secondary={false}
    />
  );
};
